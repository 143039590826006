import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'fury-dynamic-form-table',
  templateUrl: './dynamic-form-table.component.html',
  styleUrls: ['./dynamic-form-table.component.scss'],
})
export class DynamicFormTableComponent implements OnInit {
  @Input() colHeaders: any;
  @Input() dynamicForm: FormGroup;
  @Input() disableOnView: boolean;
  @Output() tableFormData = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForm();
    this.createTable(this.colHeaders, this.disableOnView);
    this.onChangeEvent()
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.colHeaders || changes.disableOnView) {
      this.createTable(
        changes?.colHeaders?.currentValue || this.colHeaders,
        changes?.disableOnView?.currentValue || this.disableOnView
      );
      this.onChangeEvent()
    }
  }

  private initializeForm(): void {
    this.dynamicForm = this.formBuilder.group({});
  }

  private createTable(key: any, status: boolean): void {
    if (!key) return;
    
    this.colHeaders = key;
    this.colHeaders.forEach((keyItem: any) => {
      this.dynamicForm?.addControl(keyItem.field, this.formBuilder.control({ value: keyItem.value || '', disabled: status }));
      if (!status) {
        this.dynamicForm?.controls[keyItem.field].enable();
      }
    });
  }

  onChangeEvent(): void {
    // const filteredObj = {};
    // for (const [key, value] of Object.entries(this.dynamicForm.value)) {
    //   if (value !== '') {
    //     filteredObj[key] = value;
    //   }
    // }
    if(this.dynamicForm){
      this.tableFormData.emit(this.dynamicForm.value);
    }
  }

  resetForm(): void {
    this.dynamicForm.reset();  
  }
}
