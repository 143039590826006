<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div class="fury-list-table">
  <div class="fury-list-header" *ngIf="!hideHeader" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
    fxLayoutGap.gt-xs="24px">
    <div class="fury-list-name" [ngClass]="selectedLanguage=='French'?'french_gridheader':'english_gridheader'">{{
      name}}
      <!-- <span *ngIf="componentName=='setupcustodianList'||componentName=='custodianListLegacy'"
        [ngClass]="isSetup == 'true'? 'setup_done custodian_theme_btn': themeToggle ? 'custodian_theme_btn' : 'custodian_theme_btn account_theme_btn'">
        <div class="button b2" id="button-13">
          <input type="checkbox" class="checkbox" [(ngModel)]="themeToggle" matTooltipPosition="left"
            matTooltip="{{(themeToggle?'switch_back_legacy':'switch_back_rapid')|translate }}"
            (click)="setActiveTheme(themeToggle?'seamlessLegacy':'seamlessRapid')">
          <div class="custodian_knobs">
            <span id="custodian_circle"></span>
          </div>
          <div class="custodian_layer"></div>
        </div>
      </span> -->

    </div>



    <div *ngIf="name!='Workflow' && name!='Flux de travail'"
      [ngClass]="selectedLanguage=='French'?'french_gridheader_tab':''" fxLayout="row" fxLayoutAlign="end" fxFlex>
      <mat-icon *ngIf="name =='Data Scheduler' || name=='Planificateur de donn?es' || name=='Endpoint Scheduler' || name=='Planificateur de point de terminaison'" class="fury-filter-input-icon">
        search</mat-icon>

      <input *ngIf="name =='Data Scheduler' || name=='Planificateur de donn?es' || name=='Endpoint Scheduler' || name=='Planificateur de point de terminaison'" fxFlex class="fury-filter-input"
        #filter [(ngModel)]="searchValue" placeholder="{{'search'|translate}}&hellip;">
      <!-- <div class="switch-field" *ngIf="isSmartTable">
        <input type="radio" id="radio-one" name="switch-one" value="true" [(ngModel)]="myFlagForSlideToggle"
          (change)="sendMessage()" checked />
        <label for="radio-one">{{'smart_view'|translate}}</label>
        <input type="radio" id="radio-two" name="switch-one" value="false" [(ngModel)]="myFlagForSlideToggle"
          (change)="sendMessage()" />
        <label for="radio-two">{{'detailed_view'|translate}}</label>
      </div> -->
    </div>

    <div
    [ngClass]="selectedLanguage=='French'?'french_gridheader_tab':''" fxLayout="row" fxLayoutAlign="end" fxFlex>
    <mat-icon *ngIf="componentName=='rapidendpointschdulerinput'" class="fury-filter-input-icon">
      search</mat-icon>

    <input *ngIf="componentName=='rapidendpointschdulerinput'" fxFlex class="fury-filter-input"
      #filter [(ngModel)]="searchValue" placeholder="{{'search'|translate}}&hellip;">
  </div>
    <ng-content select=".actions"></ng-content>
  </div>

  <mat-menu #columnFilter="matMenu" yPosition="below" xPosition="before">
    <button class="checkbox-item mat-menu-item" *ngFor="let column of columns"
      (click)="toggleColumnVisibility(column, $event)">
      <mat-checkbox [(ngModel)]="column.visible" class="checkbox" #checkbox (click)="$event.stopPropagation()">
        {{ column.name }}
      </mat-checkbox>
    </button>
  </mat-menu>

  <ng-content></ng-content>
</div>