/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { bool } from 'aws-sdk/clients/signer';
import { apiConstants } from '../../../../src/app/constants/apiConstants';
import { SetupPercentageService } from '../services/commonServices/setup-percentage.service';

@Component({
  selector: 'checkbox-renderer',
  templateUrl: './checkbox-renderer.component.html',
  styleUrls: ['./checkbox-renderer.component.scss']
})
export class CheckboxRenderer implements OnDestroy {
  
  public params: any;
  public workflowMappedValue: boolean;
  workflowData;
  @Input() componentName: any;
  constructor(private http: HttpClient, private setuppercentage: SetupPercentageService,) {

    this.setuppercentage.getSelectAllMappingData().subscribe(Data => {
      this.params.value = this.params?.data.seamlessTaxCode>0? Data:false;
    });
  }

  agInit(params: any): void {
    this.params = params;
    // if(this.params.api.componentName == "workflowMapped"){
    //   this.workflowMappedValue = params.data.enable;
    // }
  }

  checkedHandler(event) {
      let checked = event.target.checked;
      let colId = this.params.column.colId;
      let checkValue;
      // this.params.node.setDataValue(colId, checked);
      if (this.params.api.componentName == "chartofAccountTaxMappingDetails") {
        this.params.data['mappingVerification'] = event.target.checked;
        this.http.put<any>('coaTaxClassification/update', this.params.data).subscribe(response => {
        })
      }
  
      if(this.params.api.componentName == "sysAdmSeqConfiguration"){
        if(event.target.checked == true){
          checkValue = "yes";
        }else{
          checkValue = "no";
        }
        this.http.get<any>('sequenceConfiguration/lockWorkflowExecution?workflowId=' + this.params.data.id+'&check='+checkValue).subscribe(response => {
        })
      }
      if(this.params.api.componentName == "workflowMapped"){
        this.http.get<any>('systemManager/workflowUserMapping/removeWorkflowMapping?workflowId=' + this.params.data.workflowId+'&enterpriseId=' + this.params.data.enterpriseId +'&enable='+event.target.checked).subscribe(response => {
        })
      }
  }

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
}
}
