<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && updateClientCheck &&(moduleName != ('enterprise') && moduleName != ('K1Merging')&& moduleName != ('taxAdjusted') && moduleName != ('inputConfiguration') && moduleName!=('tradeUpload') && moduleName != ('user') && moduleName != ('documentManagement')) && moduleName != 'sourceDestTradeTransferTaxLotInfo' && moduleName != 'sequentialProcessStatusReview' && moduleName != 'transferTradeRawDataHistory'&& moduleName != 'RapidTransferTradeRawDataHistory' && moduleName != 'transferTradeRowData' && moduleName != 'batch' && moduleName != 'scheduleTaskInfo' && moduleName !='financialDataIntegration' && moduleName != ('workflowStatus') && (componentName != 'accountSummaryReconcile' && componentName != 'rapidAccountSummaryReconcile' && componentName !='calculateTaxAllocation' && (moduleName != 'capitalActivityDisableAutoUpdate' && moduleName != 'capitalActivityRedemptionActivityUpdate' && moduleName !='capitalActivitySubscriptionActivityUpdate' && moduleName !='csdBankTransactionsPendingForReview')) && !data.isEditing"
    (click)="viewRowData($event)">visibility</span>

<span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf="getDeleteFunctionName && displayDeleteButton && tradeDeleteView && (csdEnterpriseId == null) &&  moduleName == 'capitalActivity' && !data.isEditing"
    (click)="deleteSingleRow($event)">delete</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName && getEditFunctionName &&  !jePosted
    && tradeDeleteView && updateClientCheck && !addOnTaxUpdateCheck
    &&(moduleName != ('enterprise') && moduleName != ('inputConfiguration') 
    && moduleName!=('tradeUpload') && moduleName != ('user') 
    && moduleName != ('documentManagement')) && moduleName != 'sourceDestTradeTransferTaxLotInfo' 
    && moduleName != 'sequentialProcessStatusReview' && moduleName != 'transferTradeRawDataHistory'
    && moduleName != 'RapidTransferTradeRawDataHistory' && moduleName != 'transferTradeRowData' 
    && moduleName != 'batch' && moduleName != 'scheduleTaskInfo'  && moduleName != 'capitalActivity'
    && moduleName !='financialDataIntegration' && moduleName != ('workflowStatus') 
    && (componentName != 'accountSummaryReconcile' && componentName != 'rapidAccountSummaryReconcile' 
    && componentName !='calculateTaxAllocation') && (componentName != 'disableAutoUpdateViaAllocationUpdate' && componentName != 'redemptionActivityUpdate' && componentName != 'subscriptionActivityUpdate') && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>

<!-- OE-668 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName && getEditFunctionName && tradeDeleteView && updateClientCheck && !addOnTaxUpdateCheck && isShowEditBtn &&  componentName == 'rapidCapitalActivities' && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- OE-668 editable grid edit btn -->

<!-- OE-664 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName && getEditFunctionName && tradeDeleteView && isShowEditBtn &&  (componentName == 'disableAutoUpdateViaAllocationUpdate' || componentName == 'redemptionActivityUpdate' ||  componentName == 'subscriptionActivityUpdate') && !data.isEditing"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- OE-664 editable grid edit btn -->

<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && moduleName == ('financialDataIntegration') && status=='Pending' && !data.isEditing"
    (click)="viewRowData($event)">visibility</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName && getEditFunctionName && tradeDeleteView && moduleName == ('financialDataIntegration') && status=='Pending' && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- se-493 editable grid edit btn -->

<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && moduleName == ('enterprise') && status!='init' && !data.isEditing"
    (click)="viewRowData($event)">visibility</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName && getEditFunctionName && tradeDeleteView && moduleName == ('enterprise') && status!='init' && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- se-493 editable grid edit btn -->

<span class="material-icons-outlined agvisibility"
    *ngIf="((componentName == 'accountSummaryReconcile' || componentName == 'rapidAccountSummaryReconcile') && isMemoEntryCheck == true ) && !data.isEditing"
    (click)="setMemoEntry()">visibility</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="getEditFunctionName && ((componentName == 'accountSummaryReconcile' || componentName == 'rapidAccountSummaryReconcile') && isMemoEntryCheck == true ) && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- se-493 editable grid edit btn -->

<span class="material-icons-outlined agomsvisibility" *ngIf="omsEdit && !data.isEditing" (click)="viewRowData($event)"
    matTooltipPosition="right" matTooltip="{{'edit'|translate}}">visibility</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="omsEdit && getEditFunctionName && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- se-493 editable grid edit btn -->

<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'user' && !data.isEditing"
    (click)="viewRowData($event)">visibility</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="moduleName == 'user' && getEditFunctionName && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- se-493 editable grid edit btn -->

<span class="material-icons-outlined agvisibility"
    *ngIf="getViewFunctionName &&  tradeDeleteView && moduleName == ('enterprise') && status=='init' && !data.isEditing"
    (click)="viewRowData($event)">edit</span>

<span class="material-icons-outlined agvisibility" *ngIf="(moduleName == 'batch') && updateUserCheck && !data.isEditing"
    (click)="updateBatch($event)" matTooltip="{{'update'|translate}}">edit</span>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility"
    *ngIf="(getOnlyEditFunctionName && getEditFunctionName) && !data.isEditing && (csdEnterpriseId == null)"
    [ngStyle]="params.api.isEditing && {cursor: 'not-allowed'}" (click)="!params.api.isEditing ? onEdit($event) : ''"
    matTooltip="{{'edit'|translate}}">edit</span>
<!-- se-493 editable grid edit btn -->

<span class="material-icons-outlined agfile_download" *ngIf="getDownloadFunctionName && !data.isEditing"
    (click)="downloadFile($event)">file_download</span>

<span class="material-icons-outlined rundailyprocess" *ngIf="exicuteBtn && !data.isEditing"
    (click)="runDailyProcessingCompliance($event)">play_arrow</span>

<span *ngIf="moduleName =='accountSummary' && !data.isEditing" matTooltip="{{'Play'|translate}}"
    class="material-icons-outlined agvisibility" (click)="refreshGLBalance('play')">play_circle_outline</span>

<span *ngIf="moduleName =='accountSummary' && !data.isEditing" matTooltip="{{'refresh'|translate}}"
    class="material-icons-outlined agvisibility" (click)="refreshGLBalance('refresh')">refresh</span>

<!-- Auth:VG Download Buttom Docs -->
<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'documentManagement' && !data.isEditing"
    (click)="downloadRowDoc($event)">cloud_download</span>

<span class="material-icons-outlined agvisibility agpayments"
    *ngIf="moduleName == 'paymentsPayable' && !isPaymentActive && !data.isEditing" (click)="updatePayment($event)"
    matTooltip="{{'make_payments'|translate}}">add_card</span>

<!-- calculateTaxAllocationStatus ExportBtn-->
<span class="material-icons-outlined agvisibility"
    *ngIf="componentName == 'calculateTaxAllocationStatus' && !data.isEditing && authList.indexOf('SEAMLESS_TAXATION_TAXALLOCATION_EXPORTFILTER')>-1 "
    (click)="calculateExportDoc()" matTooltip="{{'Export Data'|translate}}">file_download</span>
<!-- calculateTaxAllocationStatus ExportBtn-->

<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'batch' && !data.isEditing"
    (click)="batchScduleDetail2($event)" matTooltip="{{'schedule'|translate}}">schedule</span>

<span class="material-icons-outlined agvisibility" *ngIf="moduleName == 'scheduleTaskInfo' && !data.isEditing"
    (click)="batchScduleDetail($event)" matTooltip="{{'schedule'|translate}}">schedule</span>

<span title="Play" *ngIf="!isActive && moduleName == 'scheduleTaskInfo' && !data.isEditing"
    class="material-icons-outlined agvisibility" (click)="updateSchdule($event)">play_circle_filled</span>

<span title="Stop" *ngIf="isActive && moduleName == 'scheduleTaskInfo' && !data.isEditing"
    class="material-icons-outlined agvisibility" (click)="updateSchdule($event)">stop_circle</span>

<span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf="getDeleteFunctionName && displayDeleteButton && tradeDeleteView && (csdEnterpriseId == null) &&  (moduleName != ('inputConfiguration') && moduleName != ('sourceDestTradeTransferTaxLotInfo') &&  moduleName != ('user') && moduleName != ('manageJobSchedule') && moduleName != 'capitalActivity'
&& moduleName != ('reportingPortalUser')  && moduleName != ('reportNotificationStatus') && moduleName != ('workflowStatus') ) && componentName != 'rapidOmslist' && componentName !='calculateTaxAllocation' && componentName !='calculateTaxAllocationStatus' && ((componentName == 'accountSummaryReconcile' || componentName == 'rapidAccountSummaryReconcile')? isMemoEntryCheck == true:true ) && !data.isEditing"
    (click)="deleteSingleRow($event)">delete</span>

<span class="material-icons-outlined agclear" matTooltip="{{'delete'|translate}}"
    *ngIf=" moduleName == 'manageJobSchedule' && !data.isEditing" (click)="deleteData($event)">delete</span>

<span *ngIf="moduleName == 'manageJobSchedule' && playPauseButtonDis && !data.isEditing"
    matTooltip="{{'Play'|translate}}" class="material-icons-outlined agvisibility"
    (click)="playPauseSchedule('resume')">play_circle_outline</span>

<span *ngIf="moduleName == 'manageJobSchedule' && !playPauseButtonDis && !data.isEditing"
    matTooltip="{{'Pause'|translate}}" class="material-icons-outlined agvisibility"
    (click)="playPauseSchedule('pause')">pause_circle_outline</span>

<button *ngIf=" moduleName == 'workflowStatus' && !data.isEditing" class="material-icons-outlined agclear"
    [disabled]="deleteButtonClick || rowDisabled"
    [ngStyle]="( deleteButtonClick || rowDisabled) && {'color': 'red',cursor: 'not-allowed','top':'6px'}"
    style="top:6px;color: red; cursor: pointer; background-color: transparent; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;"
    (click)="deleteUploadData($event)"><span matTooltip="{{'delete'|translate}}">delete</span>
</button>

<!--Toggle icon for Grid Auth:vg-->
<mat-slide-toggle class="material-icons-outlined" *ngIf="moduleName == 'inputConfiguration' && !data.isEditing"
    (change)="userActionfun($event)" [disabled]="userAction" [checked]="rowToggle" matTooltipPosition="above"
    matTooltip="{{rowToggle?'Enabled':'Disabled'}}"></mat-slide-toggle>

<div class="material-icons-outlined aggrid_view" *ngIf="omsApproveList && !data.isEditing"
    (click)="findByIdAllocated($event)" matTooltipPosition="right" matTooltip="{{'allocated_trade_list'|translate}}">
    grid_view
</div>

<div class="material-icons-outlined agedit_note" *ngIf="omsEditList && !data.isEditing"
    (click)="updateApproveOrder($event)" matTooltip="{{'update'|translate}}"> edit_note
</div>

<div class="material-icons-outlined agomscancel"
    *ngIf="omsCancel && (componentName != 'rapidOmslist' ||  (getDeleteFunctionName && componentName == 'rapidOmslist')) && !data.isEditing"
    (click)="deleteSingleRow($event)" matTooltipPosition="right" matTooltip="{{'cancel'|translate}}">clear </div>

<div class="pi pi-times-circle agdeleteall" *ngIf="omsDeleteAllo && !data.isEditing"
    (click)="cancelApproveOrder($event)" matTooltip="{{'cancel'|translate}}"> </div>

<!-- se-493 editable grid edit btn -->
<span class="material-icons-outlined agvisibility" *ngIf="data.isEditing" (click)="onSave($event)"
    matTooltip="{{'save'|translate}}">save</span>

<span class="material-icons-outlined agvisibility" *ngIf="data.isEditing" (click)="onCancel($event)"
    matTooltip="{{'cancel'|translate}}">clear</span>

<span class="material-icons-outlined agvisibility " matTooltip="{{'add_tag'|translate}}"
    *ngIf="getViewFunctionName && !data.isEditing && authList.indexOf('SEAMLESS_SETTINGS_INVESTORSETTINGS_ALLOCATIONPROGRAM_CREATE')>-1 && (componentName == 'setupInvestorsList' || componentName=='rapidInvestors')"
    (click)="viewRowDatarun($event)">sell</span>
<!-- se-493 editable grid edit btn -->