/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fadeInRightAnimation } from 'src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/@fury/animations/fade-in-up.animation';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { Observable, Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'fury-merge-pdf',
  templateUrl: './merge-pdf.component.html',
  styleUrls: ['./merge-pdf.component.scss'],
  animations: [fadeInUpAnimation, fadeInRightAnimation],
})
export class MergePdfComponent implements OnInit {
  public fileData: any = [];
  public dragDrop: boolean;
  public formValid: boolean;
  public uploadedData: any = [];
  public singleFileName;
  public myFiles: any = [];
  UploadDisabled: boolean = false;
  public mergeFile;
  filename: any;
  totalSize: any = 0
  @ViewChild("fileUpload") fileUpload: any;
  public onDragDrop$ = new Subject<CdkDragDrop<Array<any>>>();

  constructor(public translate: TranslateService, private http: HttpClient,
    private messageService: GlobalMessageService, private cookieService: CookieService) { }



  ngOnInit(): void {
  }



  onFileSelected(evt) {
    this.uploadedData = []

    this.dragDrop = false;
    for (let file of evt.files) {
      this.uploadedData.push(file);
    }
    this.UploadDisabled = false
    const fsize = this.uploadedData.reduce((total, file) => total = total + file.size, 0)
    this.totalSize = (fsize / (1024 ** 2)).toFixed(2);
    this.fileUpload.clear();
  }



  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.uploadedData, event.previousIndex, event.currentIndex);
  }
  onUpload(evt) {
    console.log('ff')
  }

  changeFileName(obj) {
    var fileNameArr = obj.fileName.split('.');
    var actualFileName = fileNameArr.slice(0, fileNameArr.length - 1).join('.');
    return actualFileName + "." + fileNameArr[fileNameArr.length - 1];
  }

  uploadDocument() {
    this.UploadDisabled = true
    this.singleFileName = ''
    let sizeValid = this.filevalidation(this.uploadedData)
    if (sizeValid) {
      this.messageService.add({ sticky: true, severity: "error", summary: this.translate.instant("file_size"), detail: this.translate.instant("file_size_validation") });
      this.UploadDisabled = false
      return
    }
    const formData = new FormData();

    formData.append("fileName", 'test');
    for (const file of this.uploadedData) {
      formData.append("files", file);
    }
    this.mergePdfFiles(formData).subscribe(res => {
      this.UploadDisabled = false
      if (res && res.code == 1) {
        this.uploadedData = []
        const source = `data:application/pdf;base64,${res.file}`;
        const link = document.createElement("a");
        let fileName = res.fileName
        link.href = source;
        link.download = `${fileName}`
        link.click()
        this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('confirmed'), detail:  res.message  });
      } else {
        this.messageService.add({ sticky: true, severity: "error", summary: this.translate.instant("error"), detail: res.message });

      }
    })

  }

  mergePdfFiles(data): Observable<any> {
    return this.http.post<any>('pdfFileMergerController/mergePdfFiles', data);
  }

  clear(evt) {
    this.fileUpload.clear();
  }

  filevalidation(files) {
    const fsize = files.reduce((total, file) => total = total + file.size, 0)

    const file = Math.round((fsize / 1024));
    // The size of the file.
    if (file > 9216) {
      return true
    }
    return false
  }

  deleteFile(file) {
    let index;
    this.uploadedData.forEach((item, ind) => {
      if (item.name == file.name) {
        index = ind
      }
    });
    this.uploadedData.splice(index, 1)
  }
}
