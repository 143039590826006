/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { Component, OnInit } from '@angular/core';
import { fadeInRightAnimation } from '../../../../../src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../../../../src/@fury/animations/fade-in-up.animation';
import { DataService } from 'src/app/shared/services/commonServices/data.service';

@Component({
  selector: 'fury-svg-spinner',
  templateUrl: './svg-spinner.component.html',
  styleUrls: ['./svg-spinner.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class SvgSpinnerComponent implements OnInit {

  isBlueSpinner:string;
  constructor(public dataService: DataService) { }

  ngOnInit(): void {
   this.dataService.getThemeData().subscribe(res=>{
    if(res && res == "fury-dark" ) {
      this.isBlueSpinner = 'assets/img/loading_spinner.gif'
    }
    else {
      this.isBlueSpinner = 'assets/img/spinner.gif'
    }
   })
    
  }

}
