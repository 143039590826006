/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import moment from 'moment';
import { GridComponent } from '../../../../../../../src/app/shared/custom-components/grid/grid.component';
import { GridService } from '../../../../../../../src/app/shared/services/gridServices/grid.service';

@Component({
  selector: 'fury-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public componentName: string = 'usersList';
  public gridOptions: GridOptions;
  public enableRangeSelection: Boolean = true;
  public enableCharts: Boolean = true;
  public columnDefs: any;
  public rowData: any[] = [];
  public groupSelectsChildren: Boolean = false;
  public suppressRowClickSelection: Boolean = true;
  public animateRows: Boolean = true;
  public rowSelection: String = "multiple";
  @ViewChild(GridComponent) gridComponent: GridComponent;
  columns = [
    { name: 'User Status', property: 'id', gridProperty: ['id']  },
    { name: 'FirstName', property: 'firstName',gridProperty: ['firstName']  },
    { name: 'Last Name', property: 'lastName', gridProperty: ['lastName']  },
    { name: 'Telephone Number', property: 'telephone_no',gridProperty: ['telephone_no']  },
    { name: 'User Name', property: 'username',gridProperty: ['username']  },
  ];
  status: any;
  display: boolean;
  displayStatus: boolean;
  selectedRows: any;


  ngOnInit(): void {
  }
  constructor(private gridService: GridService) { }

  allUsersRowEventCompliance(val) {
    this.status = val.status;
    if (this.status != 'inactive') {
      this.display = true;
    }
    else {
      this.displayStatus = true;
    }
    this.selectedRows = val;
  }

  confirmationAllUsersStatus(userData) {
    delete this.selectedRows['CustaccountNumber'];
    delete this.selectedRows['finalSequence'];
    delete this.selectedRows['inputMode'];
    this.selectedRows['status'] = userData;
    this.selectedRows['updatedOn']=moment(new Date()).utc().format() 

    this.gridService.updateAllUserStatus(this.selectedRows).subscribe(
      res => {
        this.gridComponent.getGridData();
        this.display = false
        this.displayStatus=false;
      })
  }

}
