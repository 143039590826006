
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiConstants } from '../../../../../src/app/constants/apiConstants';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
 @Component({
   selector: 'fury-batch-session-button',
   templateUrl: './batch-session-button.component.html',
   styleUrls: ['./batch-session-button.component.scss']
 })
 export class BatchSessionButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  private rowData: any;
  moduleName: any;
  attachment: any;
  attachmentVendor: any;

  constructor(
    public translate: TranslateService,
 
    private router: Router, 
  ) { }

  agInit(params: ICellRendererAngularComp): void {

    this.params = params;
    this.rowData = this.params.data;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
   
  }



  batchSessionList(event)
  {
   
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.batchSessionCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      
      this.params.batchSessionCompliance(params);
    }
    this.router.navigate(["/trades/batch-session"]);

  }
}

