/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp..
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. may be obtained
* by visitng www.formidium.com.
******************************************************************************/

import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import Docxtemplater from "docxtemplater";
import HTMLModule from "docxtemplater-html-module";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DynamicFormComponent } from "src/app/shared/dynamic-form/dynamic-form.component";
import { GenerateFsService } from "./generate-fs.service";
import { CommonService } from "src/app/shared/services/commonServices/common.service";
import { GlobalMessageService } from "src/app/shared/services/commonServices/global-message.service";
import { environment } from "src/environments/environment";
import { LocalStoreService } from "src/app/shared/services/commonServices/local-store.service";
import _ from "lodash";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GridService } from "src/app/shared/services/gridServices/grid.service";
import { FSButtonService } from "../fs-config/fs-config-entry/fs-button.service";
import { CookieService } from 'ngx-cookie-service';

const api_url = environment['api_url'];
//docx file generate
function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
@Component({
  selector: 'fury-fs-generate',
  templateUrl: './fs-generate.component.html',
  styleUrls: ['./fs-generate.component.scss'],
  providers: [FSButtonService]
})
export class FsGenerateComponent implements OnInit {
  public authList = JSON.parse(this.localService.getItem('fss'))['authList'];
  public heading: String = "Financial statement";
  public componentName: String = 'fsReport';
  public formBasic: boolean = false;
  public formValid: boolean = true;
  public fsReportData: any;
  public formData = {};
  public currentUser: any;
  @ViewChild(DynamicFormComponent) formEl;
  public fundList: any[];
  public fsConfigData: any = {};
  public fundId: any;
  public objectKeys = Object.keys;
  public startDate: any;
  public endDate: any;
  public rowData: any;
  configId: any;
  public fundGroupName: string;

  constructor(
    private fsService: GenerateFsService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private messageService: GlobalMessageService,
    public translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private localService: LocalStoreService,
    private gridService: GridService,
    private fSButtonService: FSButtonService,
    private cookieService: CookieService,
    private dialogRef: MatDialogRef<FsGenerateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }
  /**
   * Function that process all after view initialization events 
   * @Name    ngOnInit
   * @author   Ashok Kumar
   * @return   {Void}
   */
  ngOnInit(): void {
    this.rowData = this.data[0];
    if (this.rowData) {
      this.fundId = this.rowData?.fundId;
      let startDate = moment(this.rowData['startDate'], 'YYYY-MM-DD').format("YYYY-MM-DD");
      let endDate = moment(this.rowData['endDate'], 'YYYY-MM-DD').format("YYYY-MM-DD");
      this.startDate = startDate;
      this.endDate = endDate;
      this.configId = this.rowData.configId;
      this.fundGroupName = this.rowData?.fundGroupName
    }
    // this.route.queryParams.subscribe(params => {
    //   if (params && params.fundId) {
    //     this.fundId = params.fundId;
    //     this.startDate = params.startDate;
    //     this.endDate = params.endDate;
    //   }
    // });
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.getformControls();
  }

  /**
   * Function that provides form data for FS generate
   * @Name    getformControls
   * @author  Ashok Kumar
   * @return  {Void}
   */
  getformControls(): void {
    this.fsService.getformControlsDetail().then(response => {
      this.fsReportData = response;
      this.fundList = this.fsService.fundList;
      if (this.fundId) {
        this.fsReportData[0].value = { id: this.fundId };
        this.fsReportData[0].disabled = true;
      }
      if (this.startDate) {
        this.fsReportData[2].value = this.startDate;
        this.fsReportData[2].disabled = true;
      }
      if (this.endDate) {
        this.fsReportData[3].value = this.endDate;
        this.fsReportData[3].disabled = true;
      }
      if (this.fundGroupName) {
        this.fsReportData[0].style = 'display: none';
        this.fsReportData[1].style = 'display: block';
        this.fsReportData[1].value = this.fundGroupName;
        this.fsReportData[1].disabled = true;
      }
      if (this.fundId || this.fundGroupName) {
        let startDate = this.startDate || moment(this.fsReportData[2].value, 'YYYY-MM-DD').format("YYYY-MM-DD");
        let endDate = this.endDate || moment(this.fsReportData[3].value, 'YYYY-MM-DD').format("YYYY-MM-DD");
        this.fsService.getFsConfig(this.fundId, endDate, startDate, this.configId, this.fundGroupName).subscribe((response) => {
          if (response) {
            this.fsConfigData = response || {};
            this.getTemplate();
          } else {
            this.fsConfigData = {};
            this.formValid = true;
            this.messageService.add({ sticky: true, severity: 'warn', summary: this.translate.instant('warning'), detail: this.translate.instant('fund_configuration_not_exist') });
          }
        });
      }
      this.formBasic = true;
    });
  }

  /**
   * Function that provides form validation
   * @Name    formValidation
   * @author   Ashok Kumar
   * @param    {boolean} event validation flag
   * @return   {Void}
   */
  formValidation(event: boolean): void {
    this.formValid = !event;
    if (!this.formValid) {
      this.formValid = this.formEl.form.status === "INVALID" ? true : false;
    }
  }

  getTemplate() {
    if (this.fsConfigData?.configId && this.fsConfigData.template != "Default Template") {
      this.fSButtonService.getTemplateList({ configId: this.fsConfigData?.configId }).subscribe((response) => {
        let fsTemplateList = response || [];
        let fsTemplate = {};
        if (fsTemplateList.length) {
          fsTemplate = fsTemplateList[0];
          this.gridService.downloadFile("commonDocument/downloadDocument", fsTemplate, (cbs) => {
            let docxTemplateBlob = cbs;
            this.generateReport(docxTemplateBlob);
          }, (cbe) => {
            this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('something_wrong_please_try_again') });
          })
        } else {
          this.generateReport();
        }
      });
    } else {
      this.generateReport();
    }
  }

  /**
   * Function that create docx base template
   * @Name    generateReport
   * @author   Ashok Kumar
   * @return   {Void}
   */
  generateReport(teamplate?: any): void {
    if (this.fsConfigData && Object.keys(this.fsConfigData).length) {
      Object.assign(this.formData, this.formEl.form.getRawValue());
      let tempData = {};
      Object.assign(tempData, this.fsConfigData);
      let dateformat = this.fsConfigData['dateformat'] || "MMMM D, YYYY";
      let formatType = "";
      let isUpperCase = false;
      let isLowerCase = false;
      if (this.fsConfigData['dateformat'] && (this.fsConfigData['dateformat'].includes("toUpperCase") || this.fsConfigData['dateformat'].includes("toLowerCase"))) {
        formatType = this.fsConfigData['dateformat'].split(".")[1];
        if (formatType == "toUpperCase()") {
          isUpperCase = true;
        } else {
          isLowerCase = true;
        }
        dateformat = this.fsConfigData['dateformat'].split(".")[0];
      }
      tempData['startDate'] = moment(this.formData['startDate']).format(dateformat);
      tempData['endDate'] = moment(this.formData['endDate']).format(dateformat);
      tempData['fundNameHeader'] =  tempData['fundName'] ?  tempData['fundName'].toUpperCase() : '';
      tempData['currencySymbol'] = '$';
      //from config
      tempData['dateOfCommencedOperations'] = this.fsConfigData['dateOfCommencedOperations'] ? moment(this.fsConfigData['dateOfCommencedOperations']).format(dateformat) : 'XXXX';
      tempData['incorporationDate'] = this.fsConfigData['incorporationDate'] ? moment(this.fsConfigData['incorporationDate']).format(dateformat) : 'XXXX';
      tempData['effectiveDate'] = this.fsConfigData['effectiveDate'] ? moment(this.fsConfigData['effectiveDate']).format(dateformat) : 'XXXX';
      tempData['subsequentEventsPeriodFrom'] = this.fsConfigData['subsequentEventsPeriodFrom'] ? moment(this.fsConfigData['subsequentEventsPeriodFrom']).format(dateformat) : 'XXXX';
      tempData['subsequentEventsPeriodThrough'] = this.fsConfigData['subsequentEventsPeriodThrough'] ? moment(this.fsConfigData['subsequentEventsPeriodThrough']).format(dateformat) : 'XXXX';
      if (isUpperCase) {
        tempData['startDate'] = tempData['startDate'].toUpperCase();
        tempData['endDate'] = tempData['endDate'].toUpperCase();
        tempData['dateOfCommencedOperations'] = tempData['dateOfCommencedOperations'].toUpperCase();
        tempData['incorporationDate'] = tempData['incorporationDate'].toUpperCase();
        tempData['effectiveDate'] = tempData['effectiveDate'].toUpperCase();
        tempData['subsequentEventsPeriodFrom'] = tempData['subsequentEventsPeriodFrom'].toUpperCase();
        tempData['subsequentEventsPeriodThrough'] = tempData['subsequentEventsPeriodThrough'].toUpperCase();
      }
      if (isLowerCase) {
        tempData['startDate'] = tempData['startDate'].toLowerCase();
        tempData['endDate'] = tempData['endDate'].toLowerCase();
        tempData['dateOfCommencedOperations'] = tempData['dateOfCommencedOperations'].toLowerCase();
        tempData['incorporationDate'] = tempData['incorporationDate'].toLowerCase();
        tempData['effectiveDate'] = tempData['effectiveDate'].toLowerCase();
        tempData['subsequentEventsPeriodFrom'] = tempData['subsequentEventsPeriodFrom'].toLowerCase();
        tempData['subsequentEventsPeriodThrough'] = tempData['subsequentEventsPeriodThrough'].toLowerCase();
      }
      if (tempData['dateOfCommencedOperations'] != 'XXXX' && (new Date(this.fsConfigData['dateOfCommencedOperations']).getTime() >= new Date(this.formData['startDate']).getTime())) {
        tempData['endDateHeader'] = 'period from ' + tempData['dateOfCommencedOperations'] + ' (commencement of operations)' + ' through ' + tempData['endDate'];
        tempData['endDateCHeader'] = 'period from ' + tempData['dateOfCommencedOperations'] + '\n(commencement of operations)' + '\nthrough ' + tempData['endDate'];
        tempData['period'] = 'period';
      } else {
        tempData['endDateHeader'] = 'year ended ' + tempData['endDate'];
        tempData['endDateCHeader'] = 'year ended ' + tempData['endDate'];
        tempData['period'] = 'year';
      }
      tempData['yearEnded'] = this.fsService.dateDifference(tempData['startDate'], tempData['endDate'])
      tempData['businessPlanInterval'] = this.fsService.calculateNearestBusinessPlanInterval(tempData['startDate'], tempData['endDate']);
      tempData['periodDate'] = tempData['endDateHeader'];
      tempData['capitalClause'] = tempData['capitalClause'] || "Partners' capital";
      tempData['investorClause'] = tempData['investorClause'] || "Limited partners";
      tempData['capitalClauseU'] = tempData['capitalClause'].toUpperCase();
      tempData['capitalClauseL'] = tempData['capitalClause'].toLowerCase();
      tempData['investorClauseL'] = tempData['investorClause'].toLowerCase();
      tempData['subscriptionYear'] = this.fsConfigData['subscriptionYear'] ? moment(this.fsConfigData['subscriptionYear']).format("YYYY") : 'XXXX';
      tempData['gpFormationDate'] = tempData['gpFormationDate'] ? moment(tempData['gpFormationDate']).format(dateformat) : '';
      tempData['yearEndedU'] = tempData['yearEnded'].toUpperCase();
      tempData['endDateU'] = tempData['endDate'].toUpperCase();
      tempData['minimumRedemptionAmount'] = tempData['minimumRedemptionAmount'] ? Number(tempData['minimumRedemptionAmount']) : '';
      tempData['managementFeesAnnualRate'] = tempData['managementFeesAnnualRate'] ? this.fsService.formatNumber(tempData['managementFeesAnnualRate']).toString() : '';

      //fs config keys normalization
      Object.keys(tempData).forEach(key => {
        if (typeof tempData[key] === 'number') {
          // tempData[key] = tempData[key] ? this.commonService.negativeConverter(this.commonService.numberformatFS(tempData[key])) : 'XXXX'; 
          tempData[key] = this.commonService.numberformatFS(Math.abs(tempData[key])); //FS:- FS-1050
        } else {
          tempData[key] = tempData[key] || 'XXXX';
        }
      });
      //update dynamic notes with fs config values
      Object.keys(tempData).forEach(key => {
        if ((typeof tempData[key] === 'string' || tempData[key] instanceof String) && tempData[key].indexOf("{") >= 0) {
          Object.keys(tempData).forEach(key1 => {
            let dataKey = "{" + key1 + "}";
            if (tempData[key].indexOf(dataKey) >= 0) {
              var regExp = new RegExp(dataKey, 'g');
              let value: any = tempData[key1];
              tempData[key] = tempData[key].replace(regExp, value);
            }
          });
        }
      });
      //notes config
      tempData['notesToAccount'] = tempData['notesToAccount'] || [];
      tempData['notesToAccount'].forEach(parent => {
        this.updateNotesToAccount(parent, tempData);
      });
      tempData['notesToAccount'] = tempData['notesToAccount'].filter(notes => { return notes.isSelected });
      tempData['notesToAccount'].forEach(parent => {
        parent.parentSelected = parent.isSelected;//check if note selected
        parent.parentContent = parent.contentArray;
        parent.parentContentShow = parent.content.length ? true : false;

        if (parent && parent.children) {//parent
          // filterSelectedChilds
          parent.children = parent.children.filter(childnotes => { return childnotes.isSelected });
          parent.children.forEach(child => {
            child.childSelected = child.isSelected;//check if note selected
            child.childContent = child.contentArray;
            child.childContentShow = child.content.length ? true : false;
            if (child && child.children) {//child
              // filter selected notes
              child['grandChild'] = child.children.filter(grandChildnotes => { return grandChildnotes.isSelected });
              child['grandChild'].forEach(grandChild => {
                grandChild.grandChildSelected = grandChild.isSelected;//check if note selected
                grandChild.grandChildContent = grandChild.contentArray;
                grandChild.grandChildContentShow = grandChild.content.length ? true : false;
                if (grandChild && grandChild.children) {//grandchild
                  //filter selected notes
                  grandChild['grandGrandChild'] = grandChild.children.filter(grandGrandChildnotes => { return grandGrandChildnotes.isSelected });
                  grandChild['grandGrandChild'].forEach(grandGrandChild => {//grandGrandchild
                    grandGrandChild.grandGrandChildSelected = grandGrandChild.isSelected;//check if note selected
                    grandGrandChild.grandGrandChildContent = grandGrandChild.contentArray;
                    grandGrandChild.grandGrandChildContentShow = grandGrandChild.content.length ? true : false;
                  });
                }
              });
            }
          });
        }
      });

      //docx generate
      let data = tempData;
      let templateUrl = "assets/fs/FS_automation_default_java_html.docx";
      loadFile(templateUrl, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(teamplate || content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, modules: [new HTMLModule({
          ignoreUnknownTags: true,
          ignoreCssErrors:true
        })] });
        doc.setData(data);
        try {
          // render the document
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }
          console.log(JSON.stringify({ error: error }, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        // Output the document using Data-URI
        // let fileName = tempData['fundName'] + '_Financial Statement_' + tempData['endDate'] + '.docx';
        let fileName = 'javatemplate.docx';
        // saveAs(out, fileName);
        const file = new File([out], fileName, {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        //download workbooks
        this.downloadZip(file, fileName, tempData['notesToAccount']);
      }.bind(this));
    } else {
      this.formValid = true;
      this.fsConfigData = {};
      this.messageService.add({ sticky: true, severity: 'warn', summary: this.translate.instant('warning'), detail: this.translate.instant('fund_configuration_not_exist') });
    }
  }

  /**
   * Function that process docx template and generate FS zip output file
   * @Name    downloadZip
   * @author   Ashok Kumar
   * @param    {File} docxData template file
   * @param    {string} name file name
   * @param    {Array} notesToAccount notes list
   * @return   {Void}
   */
  downloadZip(docxData: File, name: string, notesToAccount: Array<any>): void {
    let startDate = moment(this.formData['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
    let endDate = moment(this.formData['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
    let reqData = {
      fundId: this.formData?.['fund']?.id || null,
      paragraphImageKey: [
        "asc_820_table_image",
        "asc_820_level_3_table_image",
        "asc_815_table_image",
        "asc_815_ii_table_image",
        "fihi_table_image",
        "custody_note_table_image",
        "fihi_xirr_table_image",
        "share_register_table_image",
        "share_capital_roll_forward_table_image",
        "investment_in_master_table_image"
      ],
      startDate: startDate,
      endDate: endDate,
      notesToAccount: notesToAccount,
      configId: this.configId,
      fundGroupName: this.fundGroupName
    };
    var access_token = this.cookieService.get('access_token');
    const formData = new FormData();
    formData.append('file', docxData, docxData.name);
    formData.append('data', JSON.stringify(reqData));
    let url = api_url + 'financialStatement1/downloadReport';
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'arraybuffer';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response) {
          this.dialogRef.close();
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "confirmed", detail: 'Something went wrong with download, please try again.' });
        } else {
          var fileName = name + ".zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          this.dialogRef.close();
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "Error", detail: 'Something went wrong with download, please try again.' });
        this.dialogRef.close();
      }
    })
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    request.send(formData);
  }

  /**
   * Function that process notes with seamless data
   * @Name    updateNotesToAccount
   * @author   Ashok Kumar
   * @param    {File} node note/sub note
   * @param    {string} fsConfigData seamless data
   * @return   {Void}
   */
  updateNotesToAccount(node: object, fsConfigData: object): void {
    Object.keys(fsConfigData).forEach(key => {
      fsConfigData[key] = fsConfigData[key] || '';
      if ((typeof node['content'] === 'string' || node['content'] instanceof String) && node['content'].indexOf("{") >= 0) {
        Object.keys(fsConfigData).forEach(key1 => {
          let dataKey = "{" + key1 + "}";
          if (node['content'].indexOf(dataKey) >= 0) {
            var regExp = new RegExp(dataKey, 'g');
            let value: any = fsConfigData[key1];
            node['content'] = node['content'].replace(regExp, value);
          }
          node['title'] = node['titleHtml'] || node['title'];
          if (node['title'].indexOf(dataKey) >= 0) {
            var regExp = new RegExp(dataKey, 'g');
            let value: any = fsConfigData[key1];
            node['title'] = node['title'].replace(regExp, value);
            node['titleHtml'] = node['title'].replace(regExp, value);
          }
        });
      }
    });
    if (node['title']) {
      node['title'] = this.fsService.convertToPlain(node['title'])
    }
    if (node['titleHtml']) {
      
      node['titleHtml'] = this.fsService.convertClassToInlineStyle(node['titleHtml']);
      let modifiedHtml = this.fsService.removeStyleAttributeFromHtml(node['titleHtml']);
      node['styleKey'] = this.fsService.getHtmlTags(modifiedHtml) || [];
    }
    node['titleHtml'] = this.fsService.cleanHTML((node['titleHtml'] || node['title'] || ""))
    const styles = this.fsService.getStylesFromHtmlString(node['titleHtml']);
    node['titleFontSize'] = styles.length && styles[0]["font-size"] ? Number(styles[0]["font-size"].replace("pt", "")) : 10;
    node['contentString'] = this.fsService.convertToPlain(node['content'])

    if (node['content'] && node['content'].indexOf('\n\n') >= 0) {
      let content = node['content'].split('\n\n');
      node['contentArray'] = content;
    } else if (node['content']) {
      let content = node['content'];
      node['contentArray'] = [content];
    }
    if (node['contentArray']) {
      let tempContentArray = this.fsService.cleanHTML(node['contentArray'][0])
      node['contentArray'] = [tempContentArray]
      // if (tempContentArray.indexOf('<br>') >= 0) {
      //   node['contentArray'] = [this.fsService.getFormatedContentArrays(tempContentArray).replace(/\\\"/g, '"').replace(/&quot;/g, '')]
      // } else {
      //   node['contentArray'] = [tempContentArray]
      // }
    }
    if (node['children']) {
      node['children'].forEach(childNode => {
        this.updateNotesToAccount(childNode, fsConfigData);
      });
    }
  }


  /**
   * Function that process form change events
   * @Name    setOnChange
   * @author   Ashok Kumar
   * @param    {object} evt form change event
   * @return   {Void}
   */
  setOnChange(evt: object): void {
    Object.assign(this.formData, this.formEl.form.getRawValue());
    if ((evt['id'] === "fund" || evt['id'] === "startDate" || evt['id'] === "endDate") && this.formData?.['fund'] && this.formData['fund']?.id && this.formData['startDate'] && this.formData['endDate']) {
      let startDate = moment(this.formData['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      let endDate = moment(this.formData['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      this.fsConfigData = {};
      this.fsService.getFsConfig(this.formData?.['fund']?.id, endDate, startDate, this.configId, this.fundGroupName).subscribe((response) => {
        if (response) {
          this.fsConfigData = response || {};
        } else {
          this.fsConfigData = {};
          this.formValid = true;
          this.messageService.add({ sticky: true, severity: 'warn', summary: this.translate.instant('warning'), detail: this.translate.instant('fund_configuration_not_exist') });
        }
      })
    }

    if (evt['id'] === "startDate") {
      let endDate = moment(this.formData['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      let startDate = moment(this.formData['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      if (endDate < startDate) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('audit_report_as_of_date_should_be_greater_than_period_from_date') });
        this.formEl.form.controls["startDate"].setValue(null);
      }
    }

    if (evt['id'] === "endDate") {
      let endDate = moment(this.formData['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      let startDate = moment(this.formData['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      if (endDate < startDate) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('audit_report_as_of_date_should_be_greater_than_period_from_date') });
        this.formEl.form.controls["endDate"].setValue(null);
      }
    }
  }

  /**
   * Function that process after init dom events
   * @Name    ngAfterViewChecked
   * @author   Ashok Kumar
   * @return   {Void}
   */
  ngAfterViewChecked(): void {
    if (this.formEl) {
      this.formValid = this.formEl.form.status === "INVALID" ? true : false;
    }
    this.changeDetectorRef.detectChanges();
  }

  close(value) {
    this.dialogRef.close();
    this.formEl.form.reset();
  }
}
