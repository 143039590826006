/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { TranslateService } from '@ngx-translate/core';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

@Component({
  selector: 'fury-grdbtn-row-edit',
  templateUrl: './grdbtn-row-edit.component.html',
  styleUrls: ['./grdbtn-row-edit.component.scss']
})
export class GrdbtnRowEditComponent {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  public workflowID: any;
  public comment: any;
  public tempDataId: any;
  public colHeader: any;
  public previewData: any;
  public disabled:boolean;
  public tooltipTxt: any;
  public tooltipMsg: any;
  public moduleName: any;
  
 

  constructor(private http: HttpClient,
    public translate: TranslateService,
    private messageService: GlobalMessageService) { }


  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
    
    if (this.moduleName) {
      this.tooltipMsg = this.moduleName;
      this.colHeader = this.params.colDef ? this.params.colDef.headerName : null;
      this.workflowID = this.params.data.id ? this.params.data.id : null;
      this.comment = this.params.data.comments ? this.params.data.comments : null;
    }
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('agfocus_body');
    
  }

  updateComment(event) {
      if (this.workflowID) {
        if(this.comment){
        var reqObj = { "id": this.workflowID, "comments": this.comment }
        this.http.post<any>('alteryx/integrityCheck/update',reqObj,this.httpOptions).subscribe(response => {
          if (response) {
             this.messageService.add({sticky:true,   severity: 'success', summary: response.status, detail: response.message });
          }else{
            this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('request_failed'), detail: this.translate.instant('something_went_wrong') });
          }
        })
     }  
   }
 }

}
