
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { apiConstants } from 'src/app/constants/apiConstants';
import { LocalStoreService } from '../../services/commonServices/local-store.service';

@Component({
  selector: 'fury-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent  {
  public params: any;
  public rowData: any;
  public moduleName: any;
  public documentLevel: boolean;
  public toolTipDescriptions: string;
  public currentUser: any;
  public isdAdhocFundAttachment: boolean;

  constructor( private localService: LocalStoreService) { }

  agInit(params: ICellRendererAngularComp): void {
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.isdAdhocFundAttachment = this.currentUser.authList.indexOf('SEAMLESS_ISD_ADHOC_FUND_SPECIFIC_ATTACHMENT') > -1 ? true : false;
    

    this.params = params;
    this.rowData = this.params.data;
   this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
   this.documentLevel =(this.params.data.documentLevel == 'investorLevel' ||  this.params.data.documentLevel == 'notificationOnly') ? true :false

  this.toolTipDescriptions =this.params.data.documentLevel == 'investorLevel' ? 'Please Select Fund Specific Document' : '';
  }

  fileuploadConfirm(evt)
  {
    if(this.documentLevel){
      return;
    }
    let selectedNode = this.params.node;
   let selectedData = selectedNode.data;
     if (this.params.clientApprovalFileUpload instanceof Function) {
       const params = {
         event: event,
         rowData: selectedData
       }
      
       this.params.clientApprovalFileUpload(params);
     }
  }


}
