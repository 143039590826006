/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInRightAnimation } from '../../animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../animations/fade-in-up.animation';
import { apiConstants } from 'src/app/constants/apiConstants';
import { CommonService } from 'src/app/shared/services/commonServices/common.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';

@Component({
  selector: 'fury-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  animations: [fadeInUpAnimation, fadeInRightAnimation]
})
export class BreadcrumbsComponent implements OnInit {

  data = [{
    field: 'Asset Class',
    desc: 'Select security class trade belong to i.e. equity, futures, Cryptocurrencies, Options etc.',
  },
  {
    field: 'Ticker',
    desc: 'An identifier unique to each security.',
  },
  {
    field: 'Transaction type',
    desc: 'Actions pertatining to the transaction whether buy, sell, shortsell etc... ',
  },
  {
    field: 'Asset Management company',
    desc: 'No description required',
  },
  {
    field: 'Trade Date',
    desc: 'Date on which the trade was made or entered in',
  },
  {
    field: 'Currency',
    desc: 'Select the currency the trade is entered in USD, EUR,etc.',
  },
  {
    field: 'Broker Account',
    desc: 'No description required',
  },
  {
    field: 'Fund',
    desc: 'No description required',
  },
  {
    field: 'Qty',
    desc: 'Enter the quantity/volume of traded quantity',
  },
  {
    field: 'Trade Price',
    desc: 'Price at which trade was made or entered in',
  },
  {
    field: 'Trade Status',
    desc: 'Pre Allocation : Allocate qty in diff accounts  Post Allocation : Enter the trade for an account',
  },
  ];
  data1 = [{
    field: 'Commission',
    desc: 'Fees charged on trading',
  },
  {
    field: 'Commission currency',
    desc: 'Select the currency for the commission charged',
  },
  {
    field: 'Exchange fee currency',
    desc: 'Currency in which the fees has been charged',
  },

  ];
  data2 = [{
    field: 'Transaction Hash',
    desc: 'Unique string of characters given to crypto transactions.',
  },

  ];
  displayedColumns = ['field', 'desc'];
  // displayedColumns1 = ['FEE Details', 'Details of various fees charged on trading'];
  // displayedColumns2 = ['Other Details', 'Other Description'];
  @Input() current: string;
  @Input() currentNew: string;
  @Input() isShow: any;
  @Input() componentName: any;
  @Input() module: any;
  @Input() crumbs: any[] = [];
  @Input() crumbNew: any[] = [];
  @Input() cardName: any[] = [];
  @Input() breadRoute: string;
  @Input() breadRouteNew: string;
  @Input() breadRouteUpload: string;
  @Input() parentHeading: string;

  displayBasic: boolean = false;
  public roleAs: any = JSON.parse(this.localStoreService.getItem('fss'))['ROLE']
  public authList: any = JSON.parse(this.localStoreService.getItem('fss'))['authList']
  dataSource: any;
  modalHeading: string;
  videoUrls: any;
  videoshow: boolean = false;

  constructor(private router: Router, private commonService: CommonService, private localStoreService: LocalStoreService,) {
    this.breadRoute = "/" + this.router?.url?.split('/', 2)[1];
    let routerUrl = this.router?.url?.split('/')[2]?.split('-');
    let routeUrl = routerUrl?.join('-');
    this.breadRouteNew = this.breadRoute + "/" + routeUrl;
    this.breadRouteUpload = this.breadRoute + "/" + routeUrl + "/upload";
  }

  ngOnInit() {
    this.currentNew = this.currentNew === "" || this.currentNew === undefined ? undefined : this.currentNew
    if (this.componentName == "sourceDestTradeTransferTaxLotInfo") {
      this.breadRouteNew = "/trades/transfer-trade-history";
    } else if (this.componentName == "checkerPendingRequest") {
      this.breadRouteNew = "/manager/report-delivery-process";
    }

    if (this.cardName[0] == undefined) {
      this.cardName = [];
    }
    else {
      this.cardName;
    }
  }
  toggleDropdown1() {
    this.modalHeading = this.current + ' Instructions';
    let module = this.module;
    this.commonService.showPopupApis(module).subscribe((res) => {
      if (res[0]) {
        this.videoUrls = res;
        this.videoshow = true;
      }
    })
    this.displayBasic = true;

  }
  hideDialogBox() {
    this.displayBasic = false;
  }

}
