import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { AuthInterceptorService } from './shared/interceptor/auth-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { PrimeNgModule } from './shared/primeNg.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { GlobalHttpInterceptorService } from './shared/interceptor/global-error-handling.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIdleKeepaliveModule } from 'ng-idle-keepalive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SvgSpinnerComponent } from './shared/custom-components/svg-spinner/svg-spinner.component';
import { GlobalErrorHandler } from './shared/globalErrorHandler';
import { ReportingPortalComponent } from './views/reporting-portal/reporting-portal.component';
import { pipesModule } from './shared/pipes/pipes.module';

import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';
import {RippleModule} from 'primeng/ripple';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { GoogleAuthenticatorModule } from './pages/authentication/MFA/google-authenticator/google-authenticator.module';


// export function HttpLoaderFactory(httpClient: HttpClient) {
//   return new MultiTranslateHttpLoader(httpClient, [
//       {prefix: "./assets/i18n/", suffix: ".json"},
//       //{prefix: "buttonName/getButtonNameJSON/", suffix: " "},
//   ]);
// }
declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }
@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    // SharedModule,
    MatCheckboxModule,
    PrimeNgModule,
    NgHttpLoaderModule.forRoot(),
    Ng2SearchPipeModule,
    // Fury Core Modules
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,
    NgIdleKeepaliveModule.forRoot(),
    // TreeTableModule,
    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,
    pipesModule,
    // For Global Message Service
     ToastModule,
     ButtonModule,
     RippleModule,
     GoogleAuthenticatorModule,
    // Register a Service Worker (optional)
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // })
  ],
  declarations: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA ],
  entryComponents: [
    SvgSpinnerComponent
  ],
  bootstrap: [AppComponent],
  providers: [BnNgIdleService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
  
     { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class AppModule {
}
