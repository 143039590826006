/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";


import { Observable, forkJoin } from 'rxjs';
import { LocalStoreService } from '../../services/commonServices/local-store.service';

@Injectable({
  providedIn: 'root'
})
export class GridFilterDropdownService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  currentUser: any;
  @Input() componentName: any;

  constructor(
    private http: HttpClient,
    private localService : LocalStoreService
  ) { }




  fetchMultipleApiBasic() {
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    let req = {
      enterpriseId: this.currentUser['enterpriseId'],
      page: 0,
      type: "ReferenceModule",
    }
    let req2 = {
      enterpriseId: this.currentUser['enterpriseId'],
      page: 0,
      type: "ReferenceTaxationModule",
    }
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
    let response2 = this.http.get<any>('brokerAccountV2/fetchAllCustodianAccountNumberByEntperise', this.httpOptions);
    let response3 = this.http.post<any>('lookupV2/getLookupValuesByTypeAndEnterprise', req);
    let response4 = this.http.post<any>('lookupV2/getLookupValuesByTypeAndEnterprise', req2);
    return forkJoin([response1, response2,response3,response4]);
  }
  
  fetchFundList(){
    return this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
  }
  fetchBrokerList(id): Observable<any> {
    return this.http.get<any>('brokerAccountV2/filterByFundId', { params: id });

  }
  fetchCustodianList(data): Observable<any> {
    return this.http.post<any>('brokerAccountV2/getMultipleAccountNumber/', data);
  }

  filterByFundIdList(data): Observable<any> {
    return this.http.post<any>('brokerAccountV2/filterByFundIdList', data);
  }
  cryptoMultipleApiBasic(){
    let response1 = this.http.get<any>('api/clients/allUsersAlteryx?enterpriseId=' + this.currentUser['enterpriseId'] + '&region='+'NonUS' );
   let response2 = this.http.get<any>( 'api/clients/enterpriseId/' + this.currentUser['enterpriseId'] );
   let response3 = this.http.get<any>('api/clients/enterpriseId/' + this.currentUser['enterpriseId'] + "/NonUS");
   let response4 = this.http.get<any>('api/clients/allUsersAlteryx?enterpriseId=' + this.currentUser['enterpriseId'] +'&region='+'US' );
    return forkJoin([response1, response2, response3,response4]);
  }
  budgetMultipleApiBasic() {
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
    let response2 = this.http.get<any>('reBudgetDetails/getAllAccountName', this.httpOptions);
    return forkJoin([response1, response2]);
  }
  getBrokerDetails(data: any): Observable<any> {
    return this.http.post('brokerAccountV2/getMultipleAccountNumber', data);
  }
  refreshCurrentOpenTaxlotByGivenDateRange(url, data, cbs, cbe) {
    this.http.post<any>(url, data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  dataMonitoringMultipleApiBasic() {
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
   let enterpriseId = this.currentUser['enterpriseId']
    //let response1 = this.http.get<any>('api/eventMonitoring/getAllEnterpriseName', this.httpOptions);
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise');
    let response2 = this.http.get<any>('api/eventMonitoring/getAllExchangeByEnterpriseId/' + enterpriseId);
    let response3 = this.http.get<any>('api/eventMonitoring/clientDetails?enterpriseId=' + enterpriseId);
    return forkJoin([response1, response2, response3]);
  }


  onchangeEnterPriseName(enterpriseId) {

    let response1 = this.http.get<any>('api/eventMonitoring/getAllFundName/' + enterpriseId);
    let response2 = this.http.get<any>('api/eventMonitoring/getAllExchangeByEnterpriseId/' + enterpriseId);
    let response3 = this.http.get<any>('api/eventMonitoring/clientDetails?enterpriseId=' + enterpriseId);
    return forkJoin([response1, response2, response3]);
  }
  onchangeFundName(fundId) {
    let response1 = this.http.get<any>('api/eventMonitoring/getAllExchangeByFundId/' + fundId);
    let response2 = this.http.get<any>('api/eventMonitoring/clientDetails?fundId=' + fundId);
    return forkJoin([response1,response2]);
  }

  onchangeExchangeName(fundId,clientId) {
    let response = this.http.get<any>('api/eventMonitoring/clientDetails?fundId=' + fundId  +'&clientId=' + clientId);
    return forkJoin([response]);
  }
  
  getclientDetails(clientId) {
    return this.http.get<any>('api/eventMonitoring/clientDetails?clientId=' + clientId); 
  }
  getclientDetailbyfund(fundId) {
    return this.http.get<any>('api/eventMonitoring/clientDetails?fundId=' + fundId); 
  }
}







