<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row"
  fxLayoutAlign="start center">
  <button mat-mini-fab color="primary" [ngClass]="{'active':isOpen}" (click)="toggleDropdown()" [class.open]="isOpen"
    class="button toolbar-btn-mini zoom-in-zoom-out" fxFlex="grow" mat-button type="button"
    matTooltip="{{'create'|translate}}">

    <span class="material-icons-outlined toolbar-button"> add</span>
  </button>

  <div [class.open]="isOpen" class="dropdown viewpop createpop popupvw newmodalui-create">
    <fury-card>

      <fury-card-content class="dropdown-card-content view-modal-header">
        <fury-scrollbar class="dropdown-content">
          <div fxLayout="column">
            <ng-container>
              <div class="createpopup">
                <div class="popuporow" *ngIf="createData && !isRapidUser()">
                  <ng-container *ngFor="let module of modules">
                    <div [ngClass]="'col-' +module.column" class="popupwid-toolbar">
                      <div class="heading">
                        <h3>{{module.name|translate}}</h3>

                      </div>

                      <div [ngClass]="module.column>1?'list':''">
                        <div class="subhead-bg"
                          *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                          <div class="subheading gray-bg">{{'favourites'|translate}}</div>

                          <ul class="gray-bg">
                            <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                              <li
                                *ngIf="currentUser.authList.indexOf(favComponentJsonData.authCreate)>-1 || (roleAs == 'SystemManager' && favComponentJsonData.authCreate =='')">
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name !='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'>{{favComponentJsonData.component_name
                                  | translate | titlecase}}</a>
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name =='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'
                                  [queryParams]="{action:'create',subComponent:favComponentJsonData.subComponent}">{{favComponentJsonData.component_name
                                  | translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i>
                                  </span>
                                </div>
                              </li>
                            </ng-container>
                          </ul>
                        </div>

                        <div class="subheading"
                          *ngIf="(favComponentJson[module.name] && favComponentJson[module.name].length) != (createData[module.name] && createData[module.name].length)">
                          {{'_all'|translate}}</div>
                        <ul>
                          <ng-container *ngFor="let trade of createData[module.name] let i = index">
                            <li [ngStyle]="{'display':trade.isFav ? 'none' :(module.column>1?'inline-flex':'flex')  }"
                              *ngIf="currentUser.authList.indexOf(trade.authCreate)>-1 || (roleAs == 'SystemManager' && trade.authCreate =='')">
                              <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                                *ngIf="module.name !='usermanagement'&&trade.component_name !='amc_im_gp'&&trade.component_name !='ror_configuration'&&trade.component_name !='setup_je_transfer_fund_level'"
                                [routerLink]='[trade.createRouterlink]'>{{trade.component_name|translate|titlecase}}
                              </a>
                              <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                                *ngIf=" module.name =='usermanagement'||trade.component_name =='amc_im_gp'||trade.component_name =='ror_configuration'||trade.component_name =='setup_je_transfer_fund_level'"
                                [routerLink]='[trade.createRouterlink]'
                                [queryParams]="{action:'create',subComponent:trade.subComponent}">{{trade.component_name|translate}}
                              </a>
                              <div class="iconset ">
                                <span
                                  [ngClass]="trade.isFav ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                    (click)="addToFavourite(trade,module.name,$event)"> star </i></span>
                              </div>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </div>
                  </ng-container>

                </div>
              </div>
              <div class="moodal-view-toolbar" *ngIf="createData && isRapidUser()">
                <div class="popup-view-toolbar">
                  <ng-container *ngFor="let module of modules ; let i = index">
                    <div class="setup-content-view" *ngIf="i<3">
                      <div class="heading">
                        <h3>{{module.name|translate}}</h3>
                        <div class="subhead-bg"
                          *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                          <div class="subheading gray-bg">{{'favourites'|translate}}</div>

                          <ul class="gray-bg">
                            <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                              <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.authCreate)>-1">
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name !='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'>{{favComponentJsonData.component_name
                                  | translate}}</a>
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name =='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'
                                  [queryParams]="{action:'create',subComponent:favComponentJsonData.subComponent}">{{favComponentJsonData.component_name
                                  | translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i>
                                  </span>
                                </div>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                        <h4
                          *ngIf="(favComponentJson[module.name] && favComponentJson[module.name].length) != (createData[module.name] && createData[module.name].length)">
                          {{'_all'|translate}}</h4>
                      </div>
                      <ul>
                        <ng-container *ngFor="let trade of createData[module.name]">
                          <li [ngStyle]="{'display':trade.isFav ? 'none' :(module.column>1?'inline-flex':'flex')  }"
                            [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                            *ngIf="currentUser.authList.indexOf(trade.authCreate)>-1 || (roleAs == 'SystemManager' && trade.authCreate =='')">
                            <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                              *ngIf="module.name !='usermanagement'&&trade.component_name !='amc_im_gp'&&trade.component_name !='ror_configuration'&&trade.component_name !='setup_je_transfer_fund_level'"
                              [routerLink]='[trade.createRouterlink]'>{{trade.component_name|translate|titlecase}}
                            </a>
                            <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                              *ngIf=" module.name =='usermanagement'||trade.component_name =='amc_im_gp'||trade.component_name =='ror_configuration'||trade.component_name =='setup_je_transfer_fund_level'"
                              [routerLink]='[trade.createRouterlink]'
                              [queryParams]="{action:'create',subComponent:trade.subComponent}">{{trade.component_name|translate}}
                            </a>
                            <div class="iconset ">
                              <span
                                [ngClass]="trade.isFav ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                  (click)="addToFavourite(trade,module.name,$event)"> star </i></span>
                            </div>
                          </li>


                        </ng-container>
                      </ul>
                    </div>
                  </ng-container>

                </div>
                <div class="popup-view-toolbar1">
                  <ng-container *ngFor="let module of modules ; let i = index">
                    <div class="setup-content-view" *ngIf="i>2 && i<5">

                      <div class="heading">
                        <h3>{{module.name|translate}}</h3>
                        <div class="subhead-bg"
                          *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                          <div class="subheading gray-bg">{{'favourites'|translate}}</div>

                          <ul class="gray-bg">
                            <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                              <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.authCreate)>-1">
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name !='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'>{{favComponentJsonData.component_name
                                  | translate }}</a>
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name =='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'
                                  [queryParams]="{action:'create',subComponent:favComponentJsonData.subComponent}">{{favComponentJsonData.component_name
                                  | translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i>
                                  </span>
                                </div>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                        <h4
                          *ngIf="(favComponentJson[module.name] && favComponentJson[module.name].length) != (createData[module.name] && createData[module.name].length)">
                          {{'_all'|translate}}</h4>
                      </div>
                      <ul>
                        <ng-container *ngFor="let trade of createData[module.name]">
                          <li [ngStyle]="{'display':trade.isFav ? 'none' :(module.column>1?'inline-flex':'flex')  }"
                            [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                            *ngIf="currentUser.authList.indexOf(trade.authCreate)>-1 || (roleAs == 'SystemManager' && trade.authCreate =='')">
                            <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                              *ngIf="module.name !='usermanagement'&&trade.component_name !='amc_im_gp'&&trade.component_name !='ror_configuration'&&trade.component_name !='setup_je_transfer_fund_level'"
                              [routerLink]='[trade.createRouterlink]'>{{trade.component_name| translate}}
                            </a>
                            <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                              *ngIf=" module.name =='usermanagement'||trade.component_name =='amc_im_gp'||trade.component_name =='ror_configuration'||trade.component_name =='setup_je_transfer_fund_level'"
                              [routerLink]='[trade.createRouterlink]'
                              [queryParams]="{action:'create',subComponent:trade.subComponent}">{{trade.component_name|translate}}
                            </a>
                            <div class="iconset ">
                              <span
                                [ngClass]="trade.isFav ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                  (click)="addToFavourite(trade,module.name,$event)"> star </i></span>
                            </div>
                          </li>


                        </ng-container>
                      </ul>
                    </div>
                  </ng-container>
                </div>
                <div class="popup-view-toolbar2">
                  <ng-container *ngFor="let module of modules ; let i = index">
                    <div class="setup-content-view" *ngIf="i>4">

                      <div class="heading">
                        <h3>{{module.name|translate}}</h3>
                        <div class="subhead-bg"
                          *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                          <div class="subheading gray-bg">{{'favourites'|translate}}</div>

                          <ul class="gray-bg">
                            <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                              <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.authCreate)>-1">
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name !='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'>{{favComponentJsonData.component_name
                                  | translate}}</a>
                                <a (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  *ngIf=" module.name =='usermanagement'"
                                  [routerLink]='[favComponentJsonData.createRouterlink]'
                                  [queryParams]="{action:'create',subComponent:favComponentJsonData.subComponent}">{{favComponentJsonData.component_name
                                  | translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i>
                                  </span>
                                </div>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                        <h4
                          *ngIf="(favComponentJson[module.name] && favComponentJson[module.name].length) != (createData[module.name] && createData[module.name].length)">
                          {{'_all'|translate}}</h4>
                      </div>
                      <ul>
                        <ng-container *ngFor="let trade of createData[module.name]">
                          <li [ngStyle]="{'display':trade.isFav ? 'none' :(module.column>1?'inline-flex':'flex')  }"
                            [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                            *ngIf="currentUser.authList.indexOf(trade.authCreate)>-1 || (roleAs == 'SystemManager' && trade.authCreate =='')">
                            <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                              *ngIf="module.name !='usermanagement'&&trade.component_name !='amc_im_gp'&&trade.component_name !='ror_configuration'&&trade.component_name !='setup_je_transfer_fund_level'"
                              [routerLink]='[trade.createRouterlink]'>{{trade.component_name| translate}}
                            </a>
                            <a (click)="toggleDropdown(trade)" [class.open]="isOpen"
                              *ngIf=" module.name =='usermanagement'||trade.component_name =='amc_im_gp'||trade.component_name =='ror_configuration'||trade.component_name =='setup_je_transfer_fund_level'"
                              [routerLink]='[trade.createRouterlink]'
                              [queryParams]="{action:'create',subComponent:trade.subComponent}">{{trade.component_name|translate}}
                            </a>
                            <div class="iconset ">
                              <span
                                [ngClass]="trade.isFav ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                  (click)="addToFavourite(trade,module.name,$event)"> star </i></span>
                            </div>
                          </li>


                        </ng-container>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>

            </ng-container>
          </div>
        </fury-scrollbar>
      </fury-card-content>
    </fury-card>
  </div>
</div>