<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row"
  fxLayoutAlign="start center">
  <button mat-mini-fab color="primary" [ngClass]="{'active':isOpen}" (click)="toggleDropdown()" [class.open]="isOpen" class="button toolbar-btn-mini zoom-in-zoom-out" fxFlex="grow"
    mat-button type="button" matTooltip="{{'view'|translate}}">
    <span class="toolbar-button material-icons-outlined">visibility</span>
  </button>
  <div [class.open]="isOpen" class="dropdown viewpop popupvw newmodalui-view">
    <fury-card>
      <fury-card-content class="dropdown-card-content view-modal-header">
        <fury-scrollbar class="dropdown-content">
          <div fxLayout="column">

            <div class="createpopup">
              <div class="popuporow" *ngIf="viewData && !isRapidUser()">
                <ng-container *ngFor="let module of modules">
                  <div [ngClass]="'col-' +module.column" class="popupwid-toolbar">
                    <div class="heading">
                      <h3>{{module.name|translate}}</h3>

                    </div>

                    <div [ngClass]="module.column>1?'list':''">
                      <div class="subhead-bg"
                        *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                        <div class="subheading gray-bg">{{'favourites'|translate}}</div>

                        <ul class="gray-bg">
                          <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                            <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.auth)>-1 ">
                              <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                              <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                              <div class="iconset d-block">
                                <span class="material-icons-outlined yellow"> <i
                                    (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                              </div>
                            </li>
                            <li
                              *ngIf="(currentUser.authList.indexOf(favComponentJsonData.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP'))|| (roleAs == 'SystemManager' && favComponentJsonData.authView =='')">
                              <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                |translate}}</a>
                              <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                |translate}}</a>
                              <div class="iconset d-block">
                                <span class="material-icons-outlined yellow"> <i
                                    (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                              </div>
                            </li>
                          </ng-container>
                        </ul>
                      </div>

                      <div class="subheading"
                        *ngIf="(favComponentJson[module.name] && favComponentJson[module.name].length) != (viewData[module.name] && viewData[module.name].length)">
                        {{'_all'|translate}}</div>
                      <ul>
                        <ng-container *ngFor="let item of viewData[module.name]">
                          <li *ngIf="currentUser.authList.indexOf(item.auth)>-1 "
                            [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }">
                            <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                              [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                              (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                              |translate}}</a>
                            <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                              [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                              [class.open]="isOpen">{{item.component_name |translate}}</a>
                            <div class="iconset ">
                              <span
                                [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                  (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                            </div>
                          </li>
                          <li
                            [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }"
                            *ngIf="(currentUser.authList.indexOf(item.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP')) || (roleAs == 'SystemManager' && item.authView =='') ">
                            <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                              [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                              (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                              |translate}}</a>
                            <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                              [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                              [class.open]="isOpen">{{item.component_name |translate}}</a>
                            <div class="iconset ">
                              <span
                                [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                  (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="moodal-view-toolbar" *ngIf="viewData && isRapidUser()">
              <div class="popup-view-toolbar">
                <ng-container *ngFor="let module of modules ; let i = index">
                <div class="setup-content-view"  *ngIf="i<3">
                  <div class="heading">
                    <h3>{{module.name|translate}}</h3>                   
                    <div class="subhead-bg"
                        *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                        <div class="subheading gray-bg">{{'favourites'|translate}}</div>

                        <ul class="gray-bg">
                          <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                            <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.auth)>-1"  >
                              <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                              <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                              <div class="iconset d-block">
                                <span class="material-icons-outlined yellow"> <i
                                    (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                              </div>
                            </li>
                            <li
                              *ngIf="(currentUser.authList.indexOf(favComponentJsonData.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP'))|| (roleAs == 'SystemManager' && favComponentJsonData.authView =='')">
                              <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                |translate}}</a>
                              <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                |translate}}</a>
                              <div class="iconset d-block">
                                <span class="material-icons-outlined yellow"> <i
                                    (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                              </div>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    <h4>{{'_all'|translate}}</h4>
                  </div>
                  <ul>
                    <ng-container *ngFor="let item of viewData[module.name]">
                      <li *ngIf="currentUser.authList.indexOf(item.auth)>-1 " [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                        [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }">
                        <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                          [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                          (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                          |translate}}</a>
                        <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                          [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                          [class.open]="isOpen">{{item.component_name |translate}}</a>
                        <div class="iconset ">
                          <span
                            [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                              (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                        </div>
                      </li>
                      <li [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                        [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }"
                          *ngIf="(currentUser.authList.indexOf(item.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP')) || (roleAs == 'SystemManager' && item.authView =='') ">
                        <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                          [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                          (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                          |translate}}</a>
                        <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                          [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                          [class.open]="isOpen">{{item.component_name |translate}}</a>
                        <div class="iconset ">
                          <span
                            [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                              (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </ng-container>
                
              </div>
              <div class="popup-view-toolbar1">
                <ng-container *ngFor="let module of modules ; let i = index">
                  <div class="setup-content-view"  *ngIf="i>2 && i<5">
                    
                    <div class="heading">
                      <h3>{{module.name|translate}}</h3>                   
                      <div class="subhead-bg"
                          *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                          <div class="subheading gray-bg">{{'favourites'|translate}}</div>
  
                          <ul class="gray-bg">
                            <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                              <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.auth)>-1"  >
                                <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                  [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                  [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                                <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                  (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                                </div>
                              </li>
                              <li
                              *ngIf="(currentUser.authList.indexOf(favComponentJsonData.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP'))|| (roleAs == 'SystemManager' && favComponentJsonData.authView =='')">
                                <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                  [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                  [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                  |translate}}</a>
                                <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                  (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                  |translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                                </div>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      <h4>{{'_all'|translate}}</h4>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of viewData[module.name]">
                        <li *ngIf="currentUser.authList.indexOf(item.auth)>-1 " [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                          [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }">
                          <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                            (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                            |translate}}</a>
                          <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                            [class.open]="isOpen">{{item.component_name |translate}}</a>
                          <div class="iconset ">
                            <span
                              [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                          </div>
                        </li>
                        <li
                          [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }" [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                          *ngIf="(currentUser.authList.indexOf(item.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP')) || (roleAs == 'SystemManager' && item.authView =='') ">
                          <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                            (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                            |translate}}</a>
                          <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                            [class.open]="isOpen">{{item.component_name |translate}}</a>
                          <div class="iconset ">
                            <span
                              [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </div>
              <div class="popup-view-toolbar2">
                <ng-container *ngFor="let module of modules ; let i = index">
                  <div class="setup-content-view"  *ngIf="i>4">
                    
                    <div class="heading">
                      <h3>{{module.name|translate}}</h3>                   
                      <div class="subhead-bg"
                          *ngIf="favComponentJson && favComponentJson[module.name] && favComponentJson[module.name].length">
                          <div class="subheading gray-bg">{{'favourites'|translate}}</div>
  
                          <ul class="gray-bg">
                            <ng-container *ngFor="let favComponentJsonData of favComponentJson[module.name]">
                              <li *ngIf="currentUser.authList.indexOf(favComponentJsonData.auth)>-1"  >
                                <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                  [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                  [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                                <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                  (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name|translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                                </div>
                              </li>
                              <li
                              *ngIf="(currentUser.authList.indexOf(favComponentJsonData.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP'))|| (roleAs == 'SystemManager' && favComponentJsonData.authView =='')">
                                <a *ngIf="favComponentJsonData?.component_name =='fetch_exchange_rate' || favComponentJsonData?.component_name == 'fetch_market_data' "
                                  [queryParams]="viewQueryParam" (click)="toggleDropdown(favComponentJsonData)"
                                  [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                  |translate}}</a>
                                <a *ngIf="favComponentJsonData?.component_name !='fetch_exchange_rate' && favComponentJsonData?.component_name !='fetch_market_data' "
                                  (click)="toggleDropdown(favComponentJsonData)" [class.open]="isOpen"
                                  [routerLink]='[favComponentJsonData.viewRouterlink]'>{{favComponentJsonData.component_name
                                  |translate}}</a>
                                <div class="iconset d-block">
                                  <span class="material-icons-outlined yellow"> <i
                                      (click)="addToFavourite(favComponentJsonData,module.name,$event)"> star </i> </span>
                                </div>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      <h4>{{'_all'|translate}}</h4>
                    </div>
                    <ul>
                      <ng-container *ngFor="let item of viewData[module.name]">
                        <li *ngIf="currentUser.authList.indexOf(item.auth)>-1 " [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                          [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }">
                          <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                            (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                            |translate}}</a>
                          <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                            [class.open]="isOpen">{{item.component_name |translate}}</a>
                          <div class="iconset ">
                            <span
                              [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                          </div>
                        </li>
                        <li [ngStyle]="{'display':item.isFavourite ? 'none' : (module.column>1?'inline-flex':'flex') }"
                          [ngClass]="module.column>1?'toolbar-modal-twopartli':'toolbar-modal-onepartli'"
                          *ngIf="(currentUser.authList.indexOf(item.authView)>-1 && currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP')) || (roleAs == 'SystemManager' && item.authView =='') ">
                          <a *ngIf="item.component_name =='fetch_exchange_rate' || item.component_name == 'fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' [queryParams]="viewQueryParam"
                            (click)="toggleDropdown(item)" [class.open]="isOpen">{{item.component_name
                            |translate}}</a>
                          <a *ngIf="item.component_name !='fetch_exchange_rate' && item.component_name !='fetch_market_data' "
                            [routerLink]='[item.viewRouterlink]' (click)="toggleDropdown(item)"
                            [class.open]="isOpen">{{item.component_name |translate}}</a>
                          <div class="iconset ">
                            <span
                              [ngClass]="item.isFavourite ? 'material-icons-outlined yellow' : 'material-icons-outlined gray'"><i
                                (click)="addToFavourite(item,module.name,$event)"> star </i></span>
                          </div>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

        </fury-scrollbar>
      </fury-card-content>

    </fury-card>
  </div>
</div>
