import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { ThemeService } from '../@fury/services/theme.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { Spinkit } from 'ng-http-loader';
import { LocalStoreService } from './shared/services/commonServices/local-store.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './shared/services/commonServices/auth.service';
import { MatSpinner } from '@angular/material/progress-spinner';
import { Keepalive } from 'ng-idle-keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from 'ng-idle-core';
import { SvgSpinnerComponent } from './shared/custom-components/svg-spinner/svg-spinner.component';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './shared/services/commonServices/common.service';
import { Subscription } from 'rxjs';
import { GlobalMessageService } from './shared/services/commonServices/global-message.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import jwt_decode from "jwt-decode";
import { LocalCookieService } from './shared/services/commonServices/localCookieService';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  cookiesChange: any;
  silentSignInChange: any;
  spinnerStyle = Spinkit;
  public matSpinner = MatSpinner;
  public awesomeComponent = SvgSpinnerComponent;

  public enterpriseComponent = [
    {
      name: 'dashboard',
      routeOrFunction: '/dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD'
      // auth: 'SEAMLESS_SIDEMENU_SETUP'
    },
    {
      name: 'dashboard2',
      routeOrFunction: '/new-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD_TWO'
    },
    {
      name: 'setup',
      routeOrFunction: '/setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: ''
      // auth: 'SEAMLESS_SIDEMENU_SETUP'
    },
    // {
    //   name: 'user_management',
    //   routeOrFunction: '/user-management',
    //   position: 15,
    //   viewBox: '0 0 50 50',
    //   class: 'people_alt',
    //   auth: 'SEAMLESS_SIDEMENU_USERMANAGEMENT'
    //   // classImg: 'imgSetup',
    //   // imgSrc: '../assets/img/setup.png'
    // },
    {
      name: 'customization',
      routeOrFunction: '/customization',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'miscellaneous_services',
      auth: 'SEAMLESS_SIDEMENU_CUSTOMIZATION'
      // classImg: 'imgSetup',
      // imgSrc: '../assets/img/setup.png'
    },

    {
      name: 'taxSetup',
      routeOrFunction: '/taxSetup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_TAX_SETUP'
    },
    // {
    //   name: 'user_management',
    //   routeOrFunction: '/user-management',
    //   position: 15,
    //   viewBox: '0 0 50 50',
    //   class: 'forward_to_inbox',
    //   auth: 'SEAMLESS_SIDEMENU_DISTRIBUTION'

    // },
    {
      name: 'investors',
      routeOrFunction: '/investors',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'person_pin',
      auth: 'SEAMLESS_SIDEMENU_CUSTOMIZATION'
      // classImg: 'imgSetup',
      // imgSrc: '../assets/img/setup.png'
    },
    {
      name: 'distribution',
      routeOrFunction: '/distribution',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'forward_to_inbox',
      auth: 'SEAMLESS_SIDEMENU_DISTRIBUTION'

    },
    {
      name: 'accounting-dashboard',
      routeOrFunction: '/accounting-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'monetization_on',
      auth: 'SEAMLESS_SIDEMENU_ACCOUNTING_DASHBOARD_CLIENT'

    },
    {
      name: 'activity-upload-review',
      aliasName: '/activity-upload-review',
      routeOrFunction: '/activity-upload-review',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'cloud_circle',
      auth: 'SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT'
    },
    {
      name: 'user-setup',
      aliasName: '/user-setup',
      routeOrFunction: '/user-setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_CLIENT_SETUP'
    },

    {
      name: 'input',
      aliasName: '/input',
      routeOrFunction: '/input',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SEAMLESS_SIDEMENU_INPUT'
    },
    {
      name: 'vendor',
      aliasName: '/vendor',
      routeOrFunction: '/vendor',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'storefront',
      auth: 'TRONIQ_SIDEMENU_VENDOR'
    },
    {
      name: 'Customer',
      aliasName: '/customer',
      routeOrFunction: '/customer',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'person_pin',
      auth: 'TRONIQ_SIDEMENU_CUSTOMER'
    },
    {
      name: 'processing',
      aliasName: '/rapid-processing',
      routeOrFunction: '/rapid-processing',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'slow_motion_video',
      auth: 'SEAMLESS_SIDEMENU_PROCESSING'
    },
    {
      name: 'Legacy',
      aliasName: '/legacy',
      routeOrFunction: '/legacy',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'collections_bookmark',
      auth: 'SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS'
    },
    {
      name: 'admin',
      routeOrFunction: '/manager',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'manage_accounts',
      auth: 'SEAMLESS_SIDEMENU_ADMIN'
    },
    {
      name: '',
      class: 'settings',
      auth: ''
    },
    {
      name: 'trades_module',
      routeOrFunction: '/trades',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SIDEMENU_TRADES'
    },
    {
      name: 'portfolio',
      routeOrFunction: '/portfolio',
      badgeColor: '#2196F3',
      position: 15,
      class: 'work_outline',
      auth: 'SIDEMENU_PORTFOLIO_RISK'
    },
    // {
    //   name: 'accounting',
    //   routeOrFunction: '/accounting',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'monetization_on',
    //   auth: 'SIDEMENU_FUNDACCOUNTING'
    // },
    {
      name: 'investors',
      routeOrFunction: '/investor',
      badgeColor: '#2196F3',
      position: 15,
      class: 'person_pin',
      auth: 'SIDEMENU_INVESTOR'
    },
    {
      name: 'processing',
      routeOrFunction: '/processing',
      badgeColor: '#2196F3',
      position: 15,
      class: 'slow_motion_video',
      auth: 'SIDEMENU_DAILY_PROCESSING'
    },
    {
      name: 'workflow',
      routeOrFunction: '/workflow',
      badgeColor: '#2196F3',
      position: 15,
      class: 'subscriptions',
      auth: 'SIDEMENU_DAILY_WORK_FLOW'
    },
    {
      name: 'reconciliation',
      routeOrFunction: '/reconciliation',
      badgeColor: '#2196F3',
      position: 15,
      class: 'layers',
      auth: 'SIDEMENU_RECONCILIATION'
    },
    // {
    //   name: 'query',
    //   routeOrFunction: '/query',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'query_stats',
    //   auth: 'SIDEMENU_QUERY'
    // },
    // {
    //   name: 'taxation',
    //   routeOrFunction: '/taxSetup',
    //   position: 15,
    //   viewBox: '0 0 50 50',
    //   class: 'price_change',
    //   auth: 'SEAMLESS_TAX_SETUP'
    // },
    {
      name: 'Financial Statement',
      routeOrFunction: '/fs',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_FINANCIAL_STATEMENT'
    },
    {
      name: 'batch_tracking',
      routeOrFunction: '/reportdeliveryconfi',
      badgeColor: '#2196F3',
      position: 15,
      class: 'library_add_check',
      auth: 'SIDEMENU_REPORTS'
    },
    {
      name: 'seamless_reports',
      routeOrFunction: '/reports',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'analytics',
      auth: 'LEFTPANEL_REPORTS_NAV'
    }
  ];

  public userComponent = [

    ///  *********** real nave bar  end ***************//
    {
      name: 'dashboard',
      routeOrFunction: '/dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD'
    },
    {
      name: 'dashboard2',
      routeOrFunction: '/new-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD_TWO'
    },
    {
      name: 'input',
      aliasName: '/input',
      routeOrFunction: '/input',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SEAMLESS_SIDEMENU_INPUT'
    },
    {

      name: 'vendor',
      aliasName: '/vendor',
      routeOrFunction: '/vendor',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'storefront',
      auth: 'TRONIQ_SIDEMENU_VENDOR'
    },
    {
      name: 'Customer',
      aliasName: '/customer',
      routeOrFunction: '/customer',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'person_pin',
      auth: 'TRONIQ_SIDEMENU_CUSTOMER'
    },
    {
      name: 'accounting-dashboard',
      routeOrFunction: '/accounting-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'monetization_on',
      auth: 'SEAMLESS_SIDEMENU_ACCOUNTING_DASHBOARD_CLIENT'

    },
    {
      name: 'activity-upload-review',
      aliasName: '/activity-upload-review',
      routeOrFunction: '/activity-upload-review',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'cloud_circle',
      auth: 'SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT'
    },
    {
      name: 'user-setup',
      aliasName: '/user-setup',
      routeOrFunction: '/user-setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_CLIENT_SETUP'
    },
    {
      name: 'processing',
      aliasName: '/rapid-processing',
      routeOrFunction: '/rapid-processing',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'slow_motion_video',
      auth: 'SEAMLESS_SIDEMENU_PROCESSING'
    },
    {
      name: 'Legacy',
      aliasName: '/legacy',
      routeOrFunction: '/legacy',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'collections_bookmark',
      auth: 'SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS'
    },
    {
      name: 'trades_module',
      routeOrFunction: '/trades',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SIDEMENU_TRADES'
    },
    {
      name: 'portfolio',
      routeOrFunction: '/portfolio',
      badgeColor: '#2196F3',
      position: 15,
      class: 'work_outline',
      auth: 'SIDEMENU_PORTFOLIO_RISK'
    },
    // {
    //   name: 'accounting',
    //   routeOrFunction: '/accounting',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'monetization_on',
    //   auth: 'SIDEMENU_FUNDACCOUNTING'
    // },
    {
      name: 'investors',
      routeOrFunction: '/investor',
      badgeColor: '#2196F3',
      position: 15,
      class: 'person_pin',
      auth: 'SIDEMENU_INVESTOR'
    },
    {
      name: 'processing',
      routeOrFunction: '/processing',
      badgeColor: '#2196F3',
      position: 15,
      class: 'slow_motion_video',
      auth: 'SIDEMENU_DAILY_PROCESSING'
    },
    {
      name: 'workflow',
      routeOrFunction: '/workflow',
      badgeColor: '#2196F3',
      position: 15,
      class: 'subscriptions',
      auth: 'SIDEMENU_DAILY_WORK_FLOW'
    },
    {
      name: 'reconciliation',
      routeOrFunction: '/reconciliation',
      badgeColor: '#2196F3',
      position: 15,
      class: 'layers',
      auth: 'SIDEMENU_RECONCILIATION'
    },
    {
      name: 'taxation',
      routeOrFunction: '/taxSetup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_TAXATION_SETUP'
    },
    // {
    //   name: 'query',
    //   routeOrFunction: '/query',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'query_stats',
    //   auth: 'SIDEMENU_QUERY'
    // },
    {
      name: 'Financial Statement',
      routeOrFunction: '/fs',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'description',
      auth: 'SEAMLESS_FINANCIAL_STATEMENT'
    },
    {
      name: 'batch_tracking',
      routeOrFunction: '/reportdeliveryconfi',
      badgeColor: '#2196F3',
      position: 15,
      class: 'library_add_check',
      auth: 'SIDEMENU_REPORTS'
    },
    {
      name: 'seamless_reports',
      routeOrFunction: '/reports',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'analytics',
      auth: 'LEFTPANEL_REPORTS_NAV'
    },
    {
      name: 'distribution',
      routeOrFunction: '/distribution',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'forward_to_inbox',
      auth: 'SEAMLESS_SIDEMENU_DISTRIBUTION'

    },
    {
      name: '',
      class: 'settings',
      auth: ''
    }
  ];

  public systemAdminComponent = [
    {
      name: 'admin',
      routeOrFunction: '/systemmanager',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'manage_accounts',
      auth: 'SEAMLESS_SIDEMENU_SYSADMIN'
    },
    {
      name: 'mis',
      routeOrFunction: '/MIS',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_MIS'
    },
    {
      name: 'Entities',
      routeOrFunction: '/Entities',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_ENTITIES'
    },
    {
      name: 'api_management',
      routeOrFunction: '/api-management',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'miscellaneous_services',
      auth: 'SYSADMIN_SIDEMENU_API'
    },
    {
      name: 'global_security_master',
      routeOrFunction: '/global-security-master',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'work_outline',
      auth: 'SEAMLESS_SIDEMENU_GLOBAL_SECURITY_MASTER'
    },
  ];
  public usertype: any;
  isEnterpriseUser: boolean;
  roleAs: string;
  hostName: string;
  userEmail: string;
  userName: string;
  imgUrl: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  displayBasic: boolean = false;
  pinedMenu: any;
  status;
  hasIdled = false;
  tokenExpires: any;
  currentUser: any;
  authList2: any;
  Id: any;
  messages: any[] = [];
  subscription: Subscription;
  valiDdata: boolean;
  public isLogin: any;
  username: string;
  v: any;
  isChecked: any = false;
  urls: any;
  videoUrls: any;
  videoshow: any = false;
  hubspot: boolean = false;
  localStorageChange: boolean = false;
  setCookies: boolean = false;
  cookiesLoaded: string = 'false';
  url: string = "";
  urlSafe: SafeResourceUrl;
  sessionList: any = [];
  refreshToken: boolean = false;

  @HostListener('window:storage')
  onStorageChange() {
    if (!localStorage.getItem('fss') && !localStorage.getItem('tfss') && !this.cookieService.check('access_token') && !this.localStorageChange) {
      this.displayBasic = false;
      this.localStorageChange = true;
      console.log("local storage clear");
      this.auth.signout();
    }
  }

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private http: HttpClient,
    private bnIdle: BnNgIdleService,
    private commonService: CommonService,
    private idle: Idle,
    private keepalive: Keepalive,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private route: ActivatedRoute,
    private localstoreservice: LocalStoreService,
    private splashScreenService: SplashScreenService,
    private globalMessageService: GlobalMessageService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private cookieService: CookieService,
    private setuppercentage: SetupPercentageService,
    public sanitizer: DomSanitizer,
    private localCookieService: LocalCookieService
  ) {
    idle.setIdle(3600);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(3600);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(3600);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();

    this.subscription = this.globalMessageService.getMessage().subscribe(message => {
      if (Object.keys(message).length != 0) {
        this.messages.push(message);
        this.messageService.add({ severity: message.severity, summary: message.summary, detail: message.detail, sticky: message.sticky, closable: message.closable })
        if (this.messages.length > 2) {
          this.messageService.clear();
          this.messages.shift()
          this.messageService.addAll(this.messages)
        }
      } else {
        this.messages = [];
      }
    });

    //set cookies logic when user login
    this.auth.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
        //set domains for silent login
        const [subdomain, domain] = this.localCookieService.getDomainAndSubdomain();
        let sessionList = JSON.parse(this.localstoreservice.getItem('sessionList')) || [];
        let domainList = sessionList.filter(element => { return element != domain });
        this.sessionList = domainList.map(element => ({ domain: element, url: '', urlSafe: '' }));
        //set cookies check and update logic here
        if (!this.cookiesChange) {
          this.setCookiesChange();
        } else {
          clearInterval(this.cookiesChange);
          this.cookiesChange = null;
          this.setCookiesChange();
        }

        //set silent session logic here
        localStorage.setItem('cookiesLoaded', 'false');
        this.setCookies = false;
        if (!this.silentSignInChange) {
          this.setSilentSignInChange();
        } else {
          clearInterval(this.silentSignInChange);
          this.silentSignInChange = null;
          this.setSilentSignInChange();
        }
      } else {
        idle.stop();
      }
    });

    //set refresh cookies logic when token update
    this.auth.getTokenRefresh().subscribe(refreshToken => {
      this.refreshToken = refreshToken;
    });

    //set domains for silent login
    const [subdomain, domain] = this.localCookieService.getDomainAndSubdomain();
    let sessionList = JSON.parse(this.localstoreservice.getItem('sessionList')) || [];
    let domainList = sessionList.filter(element => { return element != domain });
    this.sessionList = domainList.map(element => ({ domain: element, url: '', urlSafe: '' }));

    //set cookies check and update logic here
    if (!this.cookiesChange) {
      this.setCookiesChange();
    } else {
      clearInterval(this.cookiesChange);
      this.cookiesChange = null;
      this.setCookiesChange();
    }

    //set silent session logic here
    if (!this.silentSignInChange) {
      this.setSilentSignInChange();
    } else {
      clearInterval(this.silentSignInChange);
      this.silentSignInChange = null;
      this.setSilentSignInChange();
    }
  }

  setCookiesChange() {
    this.cookiesChange = setInterval(() => {
      var access_token = this.cookieService.check('access_token');
      var refresh_token = this.cookieService.check('refresh_token');
      if ((!access_token || !refresh_token) && localStorage.getItem('fss') && localStorage.getItem('tfss')) {
        //clear cookies watch
        clearInterval(this.cookiesChange);
        this.cookiesChange = null;
        //clear session watch
        clearInterval(this.silentSignInChange);
        this.silentSignInChange = null;
        localStorage.setItem('cookiesLoaded', 'false');
        this.setCookies = false;
        //remove session
        console.log("no cookies");
        this.auth.signout();
      }
    }, 1000);
  }

  setSilentSignInChange() {
    this.silentSignInChange = setInterval(() => {
      var access_token = this.cookieService.check('access_token');
      var refresh_token = this.cookieService.check('refresh_token');
      this.cookiesLoaded = localStorage.getItem('cookiesLoaded');
      if (access_token && refresh_token && this.sessionList.length && ((!this.setCookies && this.cookiesLoaded == 'false') || this.refreshToken)) {
        this.setCookies = false;
        this.refreshToken = false;
        let acTkn = this.cookieService.get('access_token');
        let rfTkn = this.cookieService.get('refresh_token');
        var token = jwt_decode(acTkn);
        let request = {
          acTkn: acTkn,
          rfTkn: rfTkn,
          exp: (token['exp'] * 1000).toString()
        };
        let data = btoa(JSON.stringify(request));
        this.sessionList.forEach(element => {
          element.url = "https://app." + element.domain + "/formidium/serviceLogin?session=" + data;
          element.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(element.url);
        });
        localStorage.setItem('cookiesLoaded', 'true');
        this.setCookies = true;
      }
    }, 1000);
  }

  iframeLoaded() { }

  iframeLoadedError() { }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void { }

  stay() {
    this.displayBasic = false;
    this.reset();
  }

  onclosemessage(evt) {
    this.messages.forEach((element, index) => {
      if (element.detail == evt.message.detail) {
        this.messages.splice(index, 1);
      }
    });
  }

  logout() {
    this.displayBasic = false;
    this.setuppercentage.add(false);
    this.auth.signout();
  }

  changed1 = (evt) => {
    this.valiDdata = evt.target.checked;
  }

  changed($event: Event) {
    this.v = $event;
    this.valiDdata = this.v['checked'];

  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (this.displayBasic && event instanceof NavigationEnd) {
        const url = event.url;
        var matches = url.match(/generatePassword|changePassword|login|forgot-password/g);
        if ((!matches || !matches.length)) {
          this.displayBasic = false;
        }
      }
    });
    this.hostName = window.location.hostname;
    this.currentUser = JSON.parse(this.localstoreservice.getItem('fss'));
    this.hubspot = this.currentUser?.authList ? (this.currentUser.authList.indexOf('SEAMLESS_CONTACT_SUPPORT') > -1 ? true : false) : false;
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);

      }

      this.renderer.addClass(this.document.body, theme[1]);

    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }
    let val = JSON.parse(this.localstoreservice.getItem('fss'));
    if (val) {
      this.roleAs = JSON.parse(this.localstoreservice.getItem('fss'))['ROLE']

    }

    if (this.roleAs == "Manager") {
      let sideLinks = [];
      this.enterpriseComponent.forEach(element => {
        if (element['auth'] && this.currentUser && this.currentUser.authList) {
          if (this.currentUser && this.currentUser.authList.includes(element['auth'])) {
            sideLinks.push(element);
          }
        } else {
          sideLinks.push(element);
        }
      });
      this.sidenavService.addItems(sideLinks);
    }

    if (this.roleAs == "User" || this.roleAs == "Reporting") {
      let sideLinks = [];
      this.userComponent.forEach(element => {
        if (element['auth'] && this.currentUser && this.currentUser.authList) {
          if (this.currentUser && this.currentUser.authList.includes(element['auth'])) {
            sideLinks.push(element);
          }
        } else {
          sideLinks.push(element);
        }
      });
      this.sidenavService.addItems(sideLinks);
    }

    if (this.roleAs == "SystemManager") {
      let SidenavManger = [];
      this.systemAdminComponent.forEach(element => {

        if (element['auth'] && this.currentUser && this.currentUser.authList) {
          if (this.currentUser && this.currentUser.authList.includes(element['auth'])) {
            SidenavManger.push(element);
          }
        } else {
          SidenavManger.push(element);
        }
      });
      this.sidenavService.addItems(SidenavManger);
    }
    this.getFormData()
  }

  getFormData() {
    if (this.hubspot) {
      document.getElementsByClassName('hotspottalk')[0]['style'].display = 'block'
    } else {
      document.getElementsByClassName('hotspottalk')[0]['style'].display = 'none'
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.cookiesChange);
    this.cookiesChange = null;
    clearInterval(this.silentSignInChange);
    this.silentSignInChange = null;
  }
}