/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { tap, catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthService } from '../../../../src/app/shared/services/commonServices/auth.service';
import { GlobalMessageService } from "../services/commonServices/global-message.service";
import { TranslateService } from "@ngx-translate/core";
import { LocalStoreService } from "../services/commonServices/local-store.service";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    
  constructor(public router: Router, private auth: AuthService, private messageService: GlobalMessageService,public translate: TranslateService,private localService : LocalStoreService) {
  }
  currentUser = JSON.parse(this.localService.getItem('fss'));
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      tap(evt => {
        const url = this.router.url;
        var matches = url.match(/generatePassword|login|forgot-password|otp-password/g); 
        if (evt instanceof HttpResponse) {
          if (evt.body && (evt.body?.message == "login First") && (!matches || !matches.length)) {
            console.log("login first");
            this.auth.signout();
          }
          if (evt.body && (evt.body?.message == 'Invalid Token.')) {
            console.log("Invalid Token.");
            this.auth.signout(true);
          }
        }
      }),

      catchError((error) => {
        if(error.error && error.error.message) {
        
            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(error.error.message) });
        }
        else if(error.error && error.error.text   && this.router.url !='/reports/general-ledger-account' && this.router.url !='/reports/gl-ac' && this.router.url !="/reports/investor-statement-aif-ror" && this.router.url !="/reports/investor-statement-consolidate" && this.router.url !="/reports/investor-statement-aif-new") {
        if(error.error.text !='User Not Exist' && error.error.text !='Created Successfully.' && ((this.router.url =='/setup/custodianst-entry/create') && error.error.text!='503 : [no body]')&& !(error.url.includes('api/clients/') && error.url.includes('/users'))) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(error.error.text) });

        }
      }
      
        
      if(error?.status && error?.status !=200) {
          
        if(!((this.router.url =='/user-management/user-entry'||  this.router.url=='/systemmanager/enterprises-list/create'|| this.router.url =='/investor/investors/create' || this.router.url =='/investor/investors/view') && !(this.currentUser.authList.indexOf('INVESTORGROUP_VIEW')>-1) && error.url.includes('InvestorGroupV2/fetchAllRecords') || error.url.includes('fundV2/create')|| (error.url.includes('tpsourceapi')) || (error.url.includes('userV2/create')) ||error.url.includes('enterpriseV2/create'))){          
        
           if(!error?.error?.message){
           this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('request_failed') });
           }
        }  
        if(error.url.includes('tpsourceapi') && (error.message ==undefined)){
          if(error.status ==500){
            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('request_failed') });
          }
          else{
           this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('crypto_server_down') });
          }
       }
    
       else if((this.router.url =='/setup/custodianst-entry/create') && (error.url.includes('/api/clients'))){
         this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('custodian_server_down') });
       }
          else if ((this.router.url == '/manager/fetch-live-data' || this.router.url == '/customization/fetch-live-data') && (error.url.includes('/api/clients'))) {
         this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('fetch_server_down') });
       }
          else if ((this.router.url == '/manager/view-data-collection' || this.router.url == '/customization/view-data-collection') && (error.url.includes('/api/clients'))) {
         this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('data_set_server_down') });
       }
          else if ((this.router.url == '/manager/data-scheduler/create') && (error.url.includes('/api/clients'))) {
         this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('data_schedular_server_down') });
       }
          else if ((this.router.url == '/manager/data-scheduler') && (error.url.includes('/api/clients'))) {
         this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('data_schedular_server_down') });
       }
     }else if(error?.url && error?.url?.includes('api/accounting/live') &&(this.router.url =='/setup/custodianst-entry/create') && error?.error?.text=='503 : [no body]' ){
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('crypto_fetch_503error') });
     }else if(this.router.url =='/customization/fetch-live-data'){
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('crypto_fetch_503errors') });
     }else if(error?.url && error?.url?.includes('api/clients/') && error?.url?.includes('/users')){
      if(error.error.text){
       let messageSplit = error.error.text.split('"message":')
       if(messageSplit.length>0){
        let messageString =  messageSplit[1]
        let finalMsg = messageString.split('"')[1]
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(finalMsg) });

       }else{
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(error.error.text) });
       }
       
     }
    }
       console.log('errorhandel',error)
        return throwError(error);
      })
    )
  }
}