/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { DynamicFormTemplateComponent } from '../dynamic-form/dynamic-form-template/dynamic-form-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { SharedModule } from '../shared.module';

//import { SharedComponentsModule } from '../components/shared-components.module';
import { PrimeNgModule } from '../primeNg.module';
import { MaterialModule } from '../../../../src/@fury/shared/material-components.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { CustomDateFormatMonthDirective } from './custom-date-format-month.directive';
import { CustomDateFormatYearDirective } from './custom-date-format-year.directive';
import { ClickStopPropagationModule } from '../../../../src/@fury/shared/click-stop-propogation/click-stop-propogation.module';
import { OrderByPipe } from '../pipes/order-by.pipe';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MentionModule } from 'angular-mentions';
import { CustomDateDirective } from './custom-date.directive';
import { NumberDirective } from './numbers-only.directive';
import { CustomDateFormatMonthYearDirective } from './custom-date-format-month-year.directive';
import { HttpLoaderFactory } from 'src/app/layout/layout.module';
import { RemoveAriaOwnsDirective } from './directive remove-aria-owns.directivecopy';

@NgModule({
  declarations: [DynamicFormComponent, DynamicFormTemplateComponent, CustomDateFormatMonthDirective,RemoveAriaOwnsDirective,
    OrderByPipe, CustomDateFormatYearDirective, CustomDateDirective, NumberDirective, CustomDateFormatMonthYearDirective],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PrimeNgModule,
    //  SharedComponentsModule,
    ClickStopPropagationModule,
    MaterialModule,
    MentionModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [DynamicFormComponent, DynamicFormTemplateComponent, OrderByPipe]
})
export class DynamicFormModule { }
