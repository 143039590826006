/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
 
import  *  as CryptoJS from  'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {
  public seriesModel: any;
  key = "a1bc5559-dfa3-4046-8f7e-31e2997ce6a8";
  private ls = window.localStorage;
  constructor() { }

  public setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, this.encrypt(value));
    return true;
  }

  public getItem(key) {
    const value = this.ls.getItem(key);
    try {
      return JSON.parse(this.decrypt(value));
    } catch (e) {
      return null;
    }
  }
  public clear() {
    this.ls.clear();
  }

  public getCurrentUser() {
    return this.ls.getItem('fss');
  }

  public setShareSeries (val) {
     this.seriesModel = val;
}

  public getShareSeries () {
    return this.seriesModel;
}
private encrypt(txt: string): string {
  return CryptoJS.AES.encrypt(txt, this.key).toString();
}
  public getUserAuthList() {
    return JSON.parse(this.getItem('fss'))['authList'];
  }
  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }

  authFnInGroups(arr, size) {
    let newArr = [];
    if (arr && arr.length) {
      for (let i = 0; i < arr.length; i += size) {
        newArr.push(arr.slice(i, i + size));
      }
    }
    return newArr;
  }

  isAuthrised(chunk, auth) {
    let isAuth = false
    if (chunk && chunk.length && auth) {
      let len = Object.keys(chunk).length
      let n = 0
      while (n < len) {
        if (chunk[n].indexOf(auth) > -1) {
          isAuth = true;
          break
        }
        n++;
      }
    }
    return isAuth
  }


}
