<!-- <fury-page-layout mode="card" class="tableview_desgin">

    <fury-page-layout-content>
        <div fxLayout="column" fxLayoutAlign="start center">
            <div class="card rapid-pos-btn" style="width: 100%">
                <mat-vertical-stepper #verticalStepper="matVerticalStepper">
                    <mat-step *ngIf="!formBasic">
                        <ng-template matStepLabel>{{heading|translate}}</ng-template>
                        <div class="content" fxLayout="column" fxLayoutGap="8px">
                            <p-skeleton height="2rem" width="40rem" styleClass="p-mb-2 skeleton-style"
                                *ngFor="let number of numbers">
                            </p-skeleton>
                        </div>
                    </mat-step>
                    <mat-step *ngIf="formBasic">
                        <ng-template matStepLabel>{{heading|translate}}
                            <span class="fs-btn">
                                <ng-template *ngTemplateOutlet="ButtonTemplate"></ng-template>
                            </span>
                        </ng-template>
                        <div class="content" fxLayout="column" fxLayoutGap="8px">
                            <app-dynamic-form [data]="fsReportData" (status)="formValidation($event)"
                                (dataItemDetails)="setOnChange($event)"></app-dynamic-form>
                        </div>
                    </mat-step>
                </mat-vertical-stepper>
            </div>
        </div>
    </fury-page-layout-content>
</fury-page-layout>
 -->
<div class="setup-process">
    <ng-template #ButtonTemplate>
        <button mat-raised-button type="button" label="Submit" (click)="close()" style="margin-left: 10px;">
            <span class="ui-text">
                {{'close'|translate}}
            </span>
        </button>
        <!-- <button mat-raised-button type="button" label="Submit" (click)="getReportData()"
            matTooltip="{{'required_fields' | translate}}"
            [matTooltipDisabled]="!formValid || !objectKeys(fsConfigData).length"
            [disabled]="formValid || !objectKeys(fsConfigData).length">
            <span class="ui-text">
                {{'Generate FS'|translate}}
            </span>
        </button> -->
    </ng-template>
    <div fxLayout="column" fxLayoutAlign="start center">
        <div style="width: 100%">
            <mat-vertical-stepper #verticalStepper="matVerticalStepper">
                <mat-step *ngIf="!formBasic">
                    <ng-template matStepLabel>{{heading|translate}}</ng-template>
                    <div class="content" fxLayout="column" fxLayoutGap="8px">
                        <p-skeleton height="2rem" width="40rem" styleClass="p-mb-2 skeleton-style"
                            *ngFor="let number of numbers">
                        </p-skeleton>
                    </div>
                </mat-step>
                <mat-step *ngIf="formBasic">
                    <ng-template matStepLabel>{{heading|translate}}
                        <span class="fs-btn">
                            <ng-template *ngTemplateOutlet="ButtonTemplate"></ng-template>
                        </span>
                    </ng-template>
                    <div class="content" fxLayout="column" fxLayoutGap="8px">
                        <app-dynamic-form [data]="fsReportData" (status)="formValidation($event)"
                            (dataItemDetails)="setOnChange($event)"></app-dynamic-form>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>
    </div>
</div>