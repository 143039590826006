<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row"
  fxLayoutAlign="start center">
  <button mat-mini-fab color="primary" [ngClass]="{'active':isOpen}" (click)="toggleDropdown()" [class.open]="isOpen"
    class="button toolbar-btn-mini zoom-in-zoom-out" fxFlex="grow" mat-button type="button"
    matTooltip="{{'help_and_resource'|translate}}">
    <mat-icon>info</mat-icon>
  </button>

  <div [class.open]="isOpen" class="dropdown viewpop uploadpop popupvw newmodalui-create trainingvideos-popup">
    <fury-card>
      <fury-card-content class="dropdown-card-content view-modal-header">
        <fury-scrollbar class="dropdown-content">
          <div fxLayout="column">
            <div class="createpopup custom-setting-panel">
              <div class="popuporow bodytext">
                <div class="popupwid-toolbar col-1 sidemenu">
                  <div class="heading">
                    <h3>{{'help_and_resource'  | translate}}</h3>
                  </div>
                  <ng-container *ngFor="let cat of category">
                 
                     <div class="subheading active" *ngIf="cat != 'UserManagement'">{{cat | translate}}</div>
                     <div class="subheading active" *ngIf="cat == 'UserManagement'"> {{'user_management' | translate}}</div>
                     <ng-container style="cursor: not-allowed !important;">

                     
                    <ng-container *ngFor="let obj of updatedVideoData; let indexOfCard=index;">
                      <div *ngIf="cat == obj.categoryName" class="listuisidebar" >
                        <ul>
                          <li  (click)="getCurrentSubCatName(cat , obj.subCategoryName, obj.videos, indexOfCard)"  [ngClass]="{'disable': isDoubleVideoClick}">
                            <a class="open"  [ngClass]="indexOfCard==selectedSubCategory? 'active': 'dummy'">{{obj.subCategoryName | translate}}</a>
                            <div class="iconset d-flex">
                              <span class="material-icons-outlined">
                                <i> videocam </i></span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                </div>

                <div class="popupwid-toolbar videodoc">   
                  <div class="headinguitext"> 
                  <p>
                   <b>{{currentSubCatName | translate}} > {{currentVideoTile | translate}}</b>
                  </p>                    
                    <a  class="addSelectOption-non addicon_right" (click)="closeDialog()">
                      <mat-icon>clear</mat-icon>
                    </a>
                  </div>       
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="openvideo" >
                   
                    <video  id = "TrainingVideo"disablePictureInPicture  #videoPlayer controls  [autoplay]="isOpen" controlsList="nodownload"   width="960" height="450"  style="width: 80%;height: 67vh; display: none;" *ngIf="defaultDataIsValid" [style.display]="'block'">
                      <source [src]="defaultData" type="video/mp4">
                    </video>
                    
                    <div class="emptyimage" [style.display]="'flex'" *ngIf="!defaultDataIsValid">
                    <img src="https://si.formidium.com/signinlogo/logo_formidium_dark.png">
                    </div>
                 </div> 
                  <div fxLayout="row" fxLayoutGap="15px" class="previewvideo">
                    
                    <ng-container *ngFor="let obj of updatedVideoData; let indexOfCard=index;">
                      <div *ngIf="obj.subCategoryName == currentSubCatName" fxFlex="100" fxLayoutGap="15px" class="overflowdiv">
                        <ng-container *ngFor="let v of obj.videos; let indexOfelement=index;">
                          <div (click)="getVideoUrl(indexOfCard, indexOfelement, v)"   class="cuvcontrol"  [ngClass]="indexOfelement==selectedIndex? 'active': 'dummy'" fxLayoutGap="8px"  fxFlex="20">
                            <div class="cuvblock">
                              <mat-icon class="play">play_circle</mat-icon>
                              <mat-icon class="pause">pause_circle</mat-icon>
                            </div>                            
                          <caption fxLayout="row">{{v.title | translate}}</caption>
                        </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fury-scrollbar>
      </fury-card-content>
    </fury-card>
  </div>
</div>