<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div class="navigation-item-container">
  <a (click)="handleClick.emit(item)"   *ngIf="item.class != 'settings'" class="navigation-item"  fxLayout="row"
  [routerLink]="item.routeOrFunction"    fxLayoutAlign="start center" matRipple routerLinkActive="active">
    <mat-icon class="icon" >{{ item?.class }}</mat-icon>
    <span class="name" >{{ item?.name | translate }}</span>
  </a>
  <a  *ngIf="item.class == 'settings'"   matRipple routerLinkActive="active">
    <div class="setting_faq setting_faq_top" fxLayout="row"
    fxLayoutAlign="start center" *ngIf="currentUser.authList.indexOf('SIDEMENU_SETTINGS') > -1" >
      <fury-toolbar-setting  class="toolbar-button" (settingPanelValue)="getSettingPanleValue($event)"
        ></fury-toolbar-setting> 
    </div>
  </a>
</div>