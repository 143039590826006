import { HttpClient, HttpHeaders } from '@angular/common/http';
/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2021 - 2022 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SimpleBar from 'simplebar';
import { DataService } from 'src/app/shared/services/commonServices/data.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { GridService } from 'src/app/shared/services/gridServices/grid.service';

@Injectable({
  providedIn: 'root'
})
export class ToolbarTrainingVideosService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public updatedVideoData: any = [];
  selectedIndex: any = 4;
  public defaultData: any;
  defaultVideoData: any;
  scrollbar: SimpleBar;

  constructor(private http:HttpClient, private setupPercentageService: SetupPercentageService,private dataService: DataService) {
  }

  getTrainingVideos(): Observable<any> {
    return this.http.post<any>('trainingVideo/getAllActiveTrainingVideo', this.httpOptions)
  }
  

  startTrainingVideo(data): Observable<any> {
    return this.http.post<any>("trainingVideo/getPreSignedURL", data);
  }

  getVideoData(event,val){
    this.setupPercentageService.setVideoStatus(true);
  }


  trainingVideoPlay(event,videoData){
     this.dataService.setTrainingVideoData(videoData)
    if(event) {
      this.getVideoData(false,true);
    } else {
      this.getVideoData(false,false);
    }
  }

  



}
