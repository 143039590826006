<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<!-- <div style=" color: blue; border-style: none;"
  *ngIf="moduleName!='dataImportStatus' || moduleName!='dataImportStatusManager'">
  <button click="getPreviewData(alteryxWorkflowStatus)"
    [disabled]="!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp "
    [ngStyle]="(!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp) && { cursor: 'not-allowed'}"
    style="background-color: transparent !important; text-align:center; position: relative; transform: translateY(15%); align-items: center; text-align: center; align-content: center;  border-style: none;">

    <span><img src="../../../../assets/img/download-xlsx.png" width="25px" height="auto" alt=""></span>
  </button>
</div> -->

<div style="color: blue; border-style: none;"
  *ngIf="moduleName!='dataImportStatus' || moduleName!='dataImportStatusManager'">
  <button (click)="getPreviewData()"
    [disabled]="!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp || alteryxWorkflowStatus.dataCountTemp === 0"
    [ngStyle]="(!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp || alteryxWorkflowStatus.dataCountTemp === 0) && { cursor: 'not-allowed'}"
    style="background-color: transparent !important; text-align:center; position: relative; transform: translateY(15%); align-items: center; text-align: center; align-content: center;  border-style: none;">
    <span>
      <img src="assets/img/download-xlsx.png" width="25px" height="auto" alt="">
    </span>
  </button>
</div>
