/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2021 - 2022 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/ 
import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appRemoveAriaOwns]'
})
export class RemoveAriaOwnsDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.el.nativeElement.parentElement.removeAttribute('aria-owns');
  }
}
