import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
@Injectable()
export class FsScheduleCardsService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public isApiCalled: boolean = false;

  constructor(private http: HttpClient) { }

  refreshFSInvestorList(data) {
    return this.http.post<any>('fSInvestor/create', data);
  }
  refreshFSPortfolioList(data) {
    return this.http.post<any>('fSPortfolio/create', data);
  }
  refreshFsChartOfAccount(data) {
    return this.http.post<any>('fsChartOfAccounts/create', data);
  }
  copyChartOfAccountsConfig(data) {
    return this.http.post<any>('fsChartOfAccounts/copyChartOfAccountsConfig', data);
  }
  copyFSPositionConfig(data) {
    return this.http.post<any>('fSPortfolio/copyFSPositionConfig', data);
  }
  refreshFsCashAndCashEquivalent(data) {
    return this.http.post<any>('cashAndCashEquivalent/create', data);
  }
  refreshFsExposure(data) {
    return this.http.post<any>('fsExposure/create', data);
  }
  refreshDataSequentially(fsChartOfAccountData: object,
    fsPortfolioData: object,
    fsInvestorData: object): Observable<any> {
    return this.refreshFsChartOfAccount(fsChartOfAccountData).pipe(
      switchMap(() => this.refreshFSPortfolioList(fsPortfolioData)),
      switchMap(() => this.refreshFSInvestorList(fsInvestorData))
    );
  }
}
