/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../../../../../src/app/shared/services/commonServices/auth.service";
import { SidenavService } from "../../../../../src/app/layout/sidenav/sidenav.service";
import { environment } from "src/environments/environment";
import { LocalStoreService } from "src/app/shared/services/commonServices/local-store.service";
import { CookieService } from 'ngx-cookie-service';
import { GlobalMessageService } from "src/app/shared/services/commonServices/global-message.service";

@Injectable({
  providedIn: "root"
})
export class OptPasswordService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  public isLogin: boolean;

  public enterpriseComponent = [
    {
      name: 'dashboard',
      routeOrFunction: '/dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD'
    },
    {
      name: 'dashboard2',
      routeOrFunction: '/new-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD_TWO'
    },
    {
      name: 'setup',
      routeOrFunction: '/setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      // auth: 'SEAMLESS_SIDEMENU_SETUP'
    },
    // {
    //   name: 'user_management',
    //   routeOrFunction: '/user-management',
    //   position: 15,
    //   viewBox: '0 0 50 50',
    //   class: 'people_alt',
    //   auth: 'SEAMLESS_SIDEMENU_USERMANAGEMENT'
    // },
    {
      name: 'customization',
      routeOrFunction: '/customization',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'miscellaneous_services',
      auth: 'SEAMLESS_SIDEMENU_CUSTOMIZATION'
    },
    {
      name: 'taxSetup',
      routeOrFunction: '/taxSetup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_TAX_SETUP'
    },
    {
      name: 'investors',
      routeOrFunction: '/investors',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'person_pin',
      auth: 'SEAMLESS_SIDEMENU_CUSTOMIZATION'
      // classImg: 'imgSetup',
      // imgSrc: '../assets/img/setup.png'
    },
    {
      name: 'distribution',
      routeOrFunction: '/distribution',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'forward_to_inbox',
      auth: 'SEAMLESS_SIDEMENU_DISTRIBUTION'
    },
    {
      name: 'accounting-dashboard',
      routeOrFunction: '/accounting-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'monetization_on',
      auth: 'SEAMLESS_SIDEMENU_ACCOUNTING_DASHBOARD_CLIENT'
    },
    {
      name: 'activity-upload-review',
      routeOrFunction: '/activity-upload-review',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'cloud_circle',
      auth: 'SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT'
    },
    {
      name: 'user-setup',
      routeOrFunction: '/user-setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_CLIENT_SETUP'
    },
    {
      name: 'input',
      aliasName: '/input',
      routeOrFunction: '/input',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SEAMLESS_SIDEMENU_INPUT'
    },
    {
      name: 'vendor',
      aliasName: '/vendor',
      routeOrFunction: '/vendor',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'storefront',
      auth: 'TRONIQ_SIDEMENU_VENDOR'
    },
    {
      name: 'Customer',
      aliasName: '/customer',
      routeOrFunction: '/customer',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'person_pin',
      auth: 'TRONIQ_SIDEMENU_CUSTOMER'
    },
    {
      name: 'processing',
      aliasName: '/rapid-processing',
      routeOrFunction: '/rapid-processing',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'slow_motion_video',
      auth: 'SEAMLESS_SIDEMENU_PROCESSING'
    },
    {
      name: 'Legacy',
      aliasName: '/legacy',
      routeOrFunction: '/legacy',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'collections_bookmark',
      auth: 'SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS'
    },
    {
      name: 'admin',
      routeOrFunction: '/manager',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'manage_accounts',
      auth: 'SEAMLESS_SIDEMENU_ADMIN'
    },
    {
      name: '',
      class: 'settings',
      auth: ''
    },
    // {
    //   name: 'processing',
    //   routeOrFunction: '/managerprocessing',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'slow_motion_video'
    // },
    // {
    //   name: 'seamless_reports',
    //   routeOrFunction: '/reports',
    //   position: 15,
    //   viewBox: '0 0 50 50',
    //   class: 'analytics',
    //   auth: 'LEFTPANEL_REPORTS_NAV'
    // },
    {
      name: 'trades_module',
      routeOrFunction: '/trades',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SIDEMENU_TRADES'
    },
    {
      name: 'portfolio',
      routeOrFunction: '/portfolio',
      badgeColor: '#2196F3',
      position: 15,
      class: 'work_outline',
      auth: 'SIDEMENU_PORTFOLIO_RISK'
    },
    // {
    //   name: 'accounting',
    //   routeOrFunction: '/accounting',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'monetization_on',
    //   auth: 'SIDEMENU_FUNDACCOUNTING'
    // },
    {
      name: 'investors',
      routeOrFunction: '/investor',
      badgeColor: '#2196F3',
      position: 15,
      class: 'person_pin',
      auth: 'SIDEMENU_INVESTOR'
    },
    {
      name: 'processing',
      routeOrFunction: '/processing',
      badgeColor: '#2196F3',
      position: 15,
      class: 'slow_motion_video',
      auth: 'SIDEMENU_DAILY_PROCESSING'
    },
    {
      name: 'workflow',
      routeOrFunction: '/workflow',
      badgeColor: '#2196F3',
      position: 15,
      class: 'subscriptions',
      auth: 'SIDEMENU_DAILY_WORK_FLOW'
    },
    {
      name: 'Reconciliation',
      routeOrFunction: '/reconciliation',
      badgeColor: '#2196F3',
      position: 15,
      class: 'layers',
      auth: 'SIDEMENU_RECONCILIATION'
    },
    {
      name: 'taxation',
      routeOrFunction: '/taxSetup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_TAXATION_SETUP'
    },
    // {
    //   name: 'Query',
    //   routeOrFunction: '/query',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'query_stats',
    //   auth: 'SIDEMENU_QUERY'
    // },
    {
      name: 'Financial Statement',
      routeOrFunction: '/fs',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_FINANCIAL_STATEMENT'
    },
    {
      name: 'batch_tracking',
      routeOrFunction: '/reportdeliveryconfi',
      badgeColor: '#2196F3',
      position: 15,
      class: 'library_add_check',
      auth: 'SIDEMENU_REPORTS'
    },
    {
      name: 'seamless_reports',
      routeOrFunction: '/reports',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'analytics',
      auth: 'LEFTPANEL_REPORTS_NAV'
    }
  ];
  public systemAdminComponent = [
    {
      name: 'admin',
      routeOrFunction: '/systemmanager',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'manage_accounts',
      auth: 'SEAMLESS_SIDEMENU_SYSADMIN'
    },
    {
      name: 'mis',
      routeOrFunction: '/MIS',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_MIS'
    },
    {
      name: 'Entities',
      routeOrFunction: '/Entities',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_ENTITIES'
    },
    {
      name: 'api_management',
      routeOrFunction: '/api-management',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'miscellaneous_services',
      auth: 'SYSADMIN_SIDEMENU_API'
    },
    {
      name: 'global_security_master',
      routeOrFunction: '/global-security-master',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'work_outline',
      auth: 'SEAMLESS_SIDEMENU_GLOBAL_SECURITY_MASTER'
    },
    // {
    //   name: 'seamless_reports',
    //   routeOrFunction: '/reports',
    //   position: 15,
    //   viewBox: '0 0 50 50',
    //   class: 'analytics',
    //   auth: 'LEFTPANEL_REPORTS_NAV'
    // },

  ];
  public userComponent = [
    ///  *********** real nave bar  end ***************//
    {
      name: 'dashboard',
      routeOrFunction: '/dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD'
    },
    {
      name: 'dashboard2',
      routeOrFunction: '/new-dashboard',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'dashboard',
      auth: 'SEAMLESS_DASHBOARD_TWO'
    },
    {
      name: 'input',
      aliasName: '/input',
      routeOrFunction: '/input',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SEAMLESS_SIDEMENU_INPUT'
    },
    {
      name: 'vendor',
      aliasName: '/vendor',
      routeOrFunction: '/vendor',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'storefront',
      auth: 'TRONIQ_SIDEMENU_VENDOR'
    },
    {
      name: 'Customer',
      aliasName: '/customer',
      routeOrFunction: '/customer',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'person_pin',
      auth: 'TRONIQ_SIDEMENU_CUSTOMER'
    },
    {
      name: 'user-setup',
      aliasName: '/user-setup',
      routeOrFunction: '/user-setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'forward_to_inbox',
      auth: 'SEAMLESS_SIDEMENU_ACCOUNTING_DASHBOARD_CLIENT'

    },
    {
      name: 'activity-upload-review',
      aliasName: '/activity-upload-review',
      routeOrFunction: '/activity-upload-review',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'cloud_circle',
      auth: 'SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT'
    },
    {
      name: 'user-setup',
      aliasName: '/user-setup',
      routeOrFunction: '/user-setup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_SIDEMENU_CLIENT_SETUP'
    },

    {
      name: 'processing',
      aliasName: '/rapid-processing',
      routeOrFunction: '/rapid-processing',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'slow_motion_video',
      auth: 'SEAMLESS_SIDEMENU_PROCESSING'
    },
    {
      name: 'Legacy',
      aliasName: '/legacy',
      routeOrFunction: '/legacy',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'collections_bookmark',
      auth: 'SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS'
    },
    {
      name: 'trades_module',
      routeOrFunction: '/trades',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'insights',
      auth: 'SIDEMENU_TRADES'
    },
    {
      name: 'portfolio',
      routeOrFunction: '/portfolio',
      badgeColor: '#2196F3',
      position: 15,
      class: 'work_outline',
      auth: 'SIDEMENU_PORTFOLIO_RISK'
    },
    // {
    //   name: 'accounting',
    //   routeOrFunction: '/accounting',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'monetization_on',
    //   auth: 'SIDEMENU_FUNDACCOUNTING'
    // },
    {
      name: 'investors',
      routeOrFunction: '/investor',
      badgeColor: '#2196F3',
      position: 15,
      class: 'person_pin',
      auth: 'SIDEMENU_INVESTOR'
    },
    {
      name: 'processing',
      routeOrFunction: '/processing',
      badgeColor: '#2196F3',
      position: 15,
      class: 'slow_motion_video',
      auth: 'SIDEMENU_DAILY_PROCESSING'
    },
    {
      name: 'workflow',
      routeOrFunction: '/workflow',
      badgeColor: '#2196F3',
      position: 15,
      class: 'subscriptions',
      auth: 'SIDEMENU_DAILY_WORK_FLOW'
    },
    {
      name: 'Reconciliation',
      routeOrFunction: '/reconciliation',
      badgeColor: '#2196F3',
      position: 15,
      class: 'layers',
      auth: 'SIDEMENU_RECONCILIATION'
    },
    {
      name: 'taxation',
      routeOrFunction: '/taxSetup',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'price_change',
      auth: 'SEAMLESS_TAXATION_SETUP'
    },
    // {
    //   name: 'Query',
    //   routeOrFunction: '/query',
    //   badgeColor: '#2196F3',
    //   position: 15,
    //   class: 'query_stats',
    //   auth: 'SIDEMENU_QUERY'
    // },
    {
      name: 'batch_tracking',
      routeOrFunction: '/reportdeliveryconfi',
      badgeColor: '#2196F3',
      position: 15,
      class: 'library_add_check',
      auth: 'SIDEMENU_REPORTS'
    },
    {
    name: 'Financial Statement',
    routeOrFunction: '/fs',
    position: 15,
    viewBox: '0 0 50 50',
    class: 'description',
    auth: 'SEAMLESS_FINANCIAL_STATEMENT'
  },
    {
      name: 'seamless_reports',
      routeOrFunction: '/reports',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'analytics',
      auth: 'LEFTPANEL_REPORTS_NAV'
    },
    {
      name: 'distribution',
      routeOrFunction: '/distribution',
      position: 15,
      viewBox: '0 0 50 50',
      class: 'forward_to_inbox',
      auth: 'SEAMLESS_SIDEMENU_DISTRIBUTION'
    },
    {
      name: '',
      class: 'settings',
      auth: ''
    },
  ];

  constructor(private localStoreService: LocalStoreService, private router: Router, private http: HttpClient, private auth: AuthService, private sidenavService: SidenavService, private cookieService: CookieService, private messageService: GlobalMessageService) {
  }

  setTabsFunction(fss) {
    this.sidenavService.clearTabs();
    if (fss['authList'].includes("USER_CREATE")) {
      let sideLinks = [];
      this.enterpriseComponent.forEach(element => {
        if (element['auth'] && fss && fss.authList) {
          if (!element['auth'] || (fss && fss.authList.includes(element['auth']))) {
            sideLinks.push(element);
          }
        } else {
          sideLinks.push(element);
        }
      });
      let val = JSON.parse(this.localStoreService.getItem('fss'));
      if (val) {
        val['STATE'] = 'true'
        val['ROLE'] = 'Manager'
        this.localStoreService.setItem('fss', JSON.stringify(val));
      }
      this.auth.setUserLoggedIn(true);
      this.sidenavService.addItems(sideLinks);
      let setupExist = sideLinks.filter((link: any) => { return link.name == 'setup' });
      if (setupExist && setupExist.length) {
        this.router.navigate([fss['authList'].includes("SEAMLESS_DASHBOARD") ? '/dashboard' : '/setup']);
      } else {
        this.router.navigate(['/manager']);
      }
    } else if (fss['authList'].includes("FUNCTION_CREATE") && fss['authList'].includes("ENTERPRISE_CREATE")) {
      let val = JSON.parse(this.localStoreService.getItem('fss'));
      if (val) {
        val['STATE'] = 'true'
        val['ROLE'] = 'SystemManager'
        this.localStoreService.setItem('fss', JSON.stringify(val));
      }
      let SidenavManger = [];
      this.systemAdminComponent.forEach(element => {
        if (element['auth'] && fss && fss.authList) {
          if (fss && fss.authList.includes(element['auth'])) {
            SidenavManger.push(element);
          }
        } else {
          SidenavManger.push(element);
        }
      });
      this.auth.setUserLoggedIn(true);
      this.sidenavService.addItems(SidenavManger);
      this.router.navigate([SidenavManger[0].routeOrFunction]);
    } else {
      let sideLinks = [];
      this.userComponent.forEach(element => {
        if (element['auth'] && fss && fss.authList) {
          if (fss && fss.authList.includes(element['auth'])) {
            sideLinks.push(element);
          }
        } else {
          sideLinks.push(element);
        }
      });
      if (sideLinks.length && sideLinks[0].routeOrFunction) {
        let val = JSON.parse(this.localStoreService.getItem('fss'));
        if (val) {
          val['STATE'] = 'true'
          val['ROLE'] = 'User'
          this.localStoreService.setItem('fss', JSON.stringify(val));
        }
        this.auth.setUserLoggedIn(true);
        this.sidenavService.addItems(sideLinks);
        this.router.navigate([sideLinks[0].routeOrFunction]);
      } else {
        // let val = JSON.parse(this.localStoreService.getItem('fss'));
        // if (val) {
        //   val['STATE'] = 'true'
        //   val['ROLE'] = 'Reporting'
        //   this.localStoreService.setItem('fss', JSON.stringify(val));
        // }
        // this.sidenavService.addItems(sideLinks);
        // this.router.navigate(['/reportingPortal']);
	
        this.messageService.add({ sticky: true, severity: 'error', summary: "Error", detail: "You don't have sufficient permissions , please check with your system admin first" });
      }
    }
  }

  setTabs(userData) {
    if (userData['otpVerification'] == "Yes" && localStorage.getItem('otpValidation') != 'true' && this.cookieService.get('otpVerification') != 'true') {
      if (userData.otpverified == null || userData.otpverified === false) {
        let checkName = { userId: userData.id };
        this.OTPreSend(checkName).subscribe((res) => {
          if (res.message == 'Created') {
            this.router.navigate(['/otp-password']);
            localStorage.setItem('otpValidation', "true");
            sessionStorage.setItem('OTP_check', 'true');
          }
        });
      } else {
        this.setOtpLessSession(userData);
      }
    } else if (localStorage.getItem('otpValidation') == 'true' && sessionStorage.getItem("OTP_check") && this.cookieService.get('otpVerification') != 'true') {
      this.router.navigate(['/otp-password']);
    } else if (localStorage.getItem('otpValidation') == 'true' && !sessionStorage.getItem("OTP_check")) {
      this.auth.signout()
    } else {
      this.setOtpLessSession(userData);
    }
  }

  setOtpLessSession(userData) {
    this.localStoreService.setItem('tfss', null);
    this.localStoreService.setItem('fss', JSON.stringify(userData));
    let val = JSON.parse(this.localStoreService.getItem('fss'));
    if (val) {
      val['otpverified'] = 'true'
      val['STATE'] = 'true'
      this.localStoreService.setItem('fss', JSON.stringify(val));
    }
    sessionStorage.setItem('userId', userData['id']);
    this.setTabsFunction(userData);
  }

  OTPreSend(data): Observable<any> {
    return this.http.post<any>('userV2/OTPsend', data);
  }

  OTPCheck(data): Observable<any> {
    return this.http.post<any>('userV2/OTPCheck', data);
  }
}
