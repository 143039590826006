/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';

@Injectable({
  providedIn: 'root'
})
export class SetupButtonService {
  amcList: any;
  fundList: any;
  custodian: any;
  investor: any;
  assestBasedFee: any;
  performanceFee: any;
  setupActionsubject = new Subject<any>();
  tabhighlightsubject = new Subject<any>();
  componentName: string = 'setupcustodianList';


  constructor(private http: HttpClient, private setupPercentageService: SetupPercentageService) {

    this.setupPercentageService.getCustodianType().subscribe((res) => {

      console.log("res111>>>>>", res);
      if (res == 'seamlessLegacy') {
        // this.router.navigate(["/manager/custodians"]);
        this.componentName = 'custodianListLegacy';
      } else {
        this.componentName = 'setupcustodianList';
      }

    })
  }
  // setupManagementTabs = [
  //   { id: 1, aliasName: "amc", name: 'amc_im_gp', isSubmit: false, isDisabled: false, description: 'amc_description', viewRouterlink: "amc-im-program", createRouterlink: 'amc-im-program-entry/create', uploadRouterlink: 'amc-im-program-upload', authView: '', authCreate: 'ASSETMANAGEMENT_CREATE', authUpload: '',videoedit:true ,displayOrder:1},
  //   { id: 2, aliasName: "fund", name: 'funds_client_accounts', isSubmit: false, isDisabled: true, description: 'fund_client_account_description', authView: '', createRouterlink: 'fund-account/create', viewRouterlink: 'fund-client-account-list', uploadRouterlink: 'fund-client-account-upload', authUpload: '', authCreate: '',videoedit:true ,displayOrder:1},
  //   { id: 3, aliasName: "custodian", name: 'account_configurations', isSubmit: false, isDisabled: true, description: 'account_configurations_description', authView: '', createRouterlink: this.componentName == 'custodianListLegacy' ? 'custodianst-legacy-entry/create' : 'custodianst-entry/create', viewRouterlink: this.componentName == 'custodianListLegacy' ? 'custodians-legacy-list' : 'custodians-list', uploadRouterlink: this.componentName == 'custodianListLegacy' ? 'custodians-legacy-upload' : 'custodians-upload', authUpload: '', authCreate: '',videoedit:true,displayOrder:1},
  //   { id: 4, aliasName: "investor", name: 'investors', isSubmit: false, isDisabled: true, description: 'investors_description', authView: '', createRouterlink: 'investor-entry/create', viewRouterlink: 'investor-list', uploadRouterlink: 'investor-upload', authUpload: '', authCreate: '',videoedit:true ,displayOrder:1},
  //   { id: 5, aliasName: "investManager", name: 'assest_based_fee', isSubmit: false, isDisabled: true, description: 'assest_based_fee_description', authView: '', viewRouterlink: 'asset-based-fee', createRouterlink: 'asset-based-fee-entry/create', uploadRouterlink: '', authCreate: '', authUplaod: '',videoedit:true,displayOrder:1},
  //   { id: 6, aliasName: "managerPerfBasedFee", name: 'performance_based_fee', isSubmit: false, isDisabled: true, description: 'performance_based_fee_description', authView: '', viewRouterlink: 'performanc-based-fee', createRouterlink: 'performanc-based-fee-entry/create', uploadRouterlink: '', authCreate: '', authUplaod: '',videoedit:true ,displayOrder:1},
  // ];
  setupManagementTabs = [
    { id:1, aliasName: "amc", name: 'amc_im_gp', isSubmit: false, isDisabled: false, description: 'amc_description', viewRouterlink: "client-list", createRouterlink: 'client-entry/create', uploadRouterlink: 'amc-im-program-upload', authView: '', authCreate: 'ASSETMANAGEMENT_CREATE', authUpload: '',videoedit:true ,displayOrder:1 },
    { id:2, aliasName: "fund", name: 'funds_client_accounts', isSubmit: false, isDisabled: true, description: 'fund_client_account_description', authView: '', createRouterlink: 'entity-entry/create', viewRouterlink: 'entity-list', uploadRouterlink: 'entity-upload', authUpload: '', authCreate: '',videoedit:true ,displayOrder:1 },
    { id: 4, aliasName: "custodian", name: 'account_configurations', isSubmit: false, isDisabled: true, description: 'account_configurations_description', authView: '', createRouterlink: this.componentName == 'custodianListLegacy' ? 'custodianst-legacy-entry/create' : 'custodianst-entry/create', viewRouterlink: this.componentName == 'custodianListLegacy' ? 'custodians-legacy-list' : 'custodians-list', uploadRouterlink: this.componentName == 'custodianListLegacy' ? 'custodians-legacy-upload' : 'custodians-upload', authUpload: '', authCreate: '',videoedit:true,displayOrder:1},
    // { id:4, aliasName: "investor", name: 'investors', isSubmit: false, isDisabled: true, description: 'investors_description', authView: '', createRouterlink: 'investor-entry/create', viewRouterlink: 'investor-list', uploadRouterlink: 'investor-upload', authUpload: '', authCreate: '' },
    // { id: 5, aliasName: "investManager", name: 'assest_based_fee', isSubmit: false, isDisabled: true, description: 'assest_based_fee_description', authView: '', viewRouterlink: 'asset-based-fee', createRouterlink: 'asset-based-fee-entry/create', uploadRouterlink: '', authCreate: '', authUplaod: '' },
    // { id: 6, aliasName: "managerPerfBasedFee", name: 'performance_based_fee', isSubmit: false, isDisabled: true, description: 'performance_based_fee_description', authView: '', viewRouterlink: 'performanc-based-fee', createRouterlink: 'performanc-based-fee-entry/create', uploadRouterlink: '', authCreate: '', authUplaod: '' },
  ];
  tabs = {
    groupingTabs: {
      Name: 'grouping', description: 'grouping_description', auth: 'SEAMLESS_CUSTOMIZATION_DIV_GROUPING',
      tabs: [
        { id: 1, name: 'fund_group', isSubmit: false, isDisabled: false, description: '', viewRouterlink: "entity-group", createRouterlink: 'entity-group-entry/create', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
        // { id: 2, name: 'grouping_investor', isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: 'investor-Group-entry/create', viewRouterlink: 'investor-Group', uploadRouterlink: '', authUpload: '', authCreate: '' },
      ],
      id:0
    },
    ProcessConfigTabs: {
      Name: 'process_onfiguration', description: 'process_onfiguration_description', auth: 'SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION',
      tabs: [
        // { id: 1, name: 'non_custodian_workflow', isCreate: true, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "non-custodian-workflow", createRouterlink: 'non-custodian-workflow-entry/create', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
        // { id: 2, name: 'sys_adm_seq_configuration', isCreate: false, isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: '', viewRouterlink: 'sequence-config', uploadRouterlink: '', authUpload: '', authCreate: '' },
        // { id: 3, name: 'input_configuration', isCreate: false, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "input-config", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
        { id: 4, name: 'data_import_status', isCreate: false, isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: '', viewRouterlink: 'data-import-status', uploadRouterlink: '', authUpload: '', authCreate: '' },
        { id: 3, name: 'connections', isSubmit: false, isDisabled: false, isCreate: false, description: '', viewRouterlink: "connections", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },

      ],
      id: 1
    },
    // automationDeliveryProcessTabs: {
    //   Name: 'automated_report_deliverylog', description: 'automated_report_deliverylog_description', auth: 'SEAMLESS_CUSTOMIZATION_DIV_AUTOMATEREPORTDELIVERYLOG',
    //   tabs: [
    //     { id: 1, name: 'delivery_process', isSubmit: false, isDisabled: false, isCreate: false, description: '', viewRouterlink: "delivery-process", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    //     { id: 2, name: 'checker_pending_request', isSubmit: false, isDisabled: true, isCreate: false, description: '', authView: '', createRouterlink: '', viewRouterlink: 'checker-pending-request', uploadRouterlink: '', authUpload: '', authCreate: '' },
    //     { id: 3, name: 'notification_status', isSubmit: false, isDisabled: false, isCreate: false, description: '', viewRouterlink: "notification-status", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' }

    //   ],
    //   id: 2
    // },
    // deliveryProcessTabs: {
    //   Name: 'report_configuration', description: 'report_configuration_description', auth: 'SEAMLESS_CUSTOMIZATION_DIV_REPORTCONFIGURATION',
    //   tabs: [
    //     { id: 2, name: 'report_approval_rule', isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: 'report-approval-rule-entry/create', viewRouterlink: 'report-approval-rule', uploadRouterlink: '', authUpload: '', authCreate: '' },
    //     { id: 1, name: 'report_notification', isSubmit: false, isDisabled: false, description: '', viewRouterlink: "", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    //   ],
    //   id: 3
    // },
    // cryptoExchangeData: {
    //   Name: 'crypto_exchange_data', description: 'crypto_exchange_data_description', auth: 'SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA',
    //   tabs: [
    //     { id: 1, name: 'data_scheduler', isCreate: true, isView: true, isDownload: false, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "data-scheduler", createRouterlink: 'data-scheduler-entry/create', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    //     { id: 2, name: 'data_set', isCreate: false, isView: true, isSubmit: false, isDownload: false, isDisabled: false, description: '', viewRouterlink: "view-data-collection", createRouterlink: '', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    //     { id: 3, name: 'fetch_crypto_data', isCreate: false, isView: false, isDownload: true, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "", createRouterlink: 'fetch-live-data', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },

    //   ],
    //   id: 4
    // },
    otherConfigTabs: {
      Name: 'other_configuration', description: 'other_configuration_description', auth: 'SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION',
      tabs: [
        // { id: 1, name: 'ror_configuration', isCreate: true, isView: true, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "ror-config", createRouterlink: 'ror-config-entry/create', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
        { id: 1, name: 'setup_je_transfer_fund_level', isCreate: true, isView: true, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "setup-je-transfer", createRouterlink: 'setup-je-transfer-entry/create', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
        // { id: 1, name: 'doc_management', isCreate: false, isView: true, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "docManagement", createRouterlink: '', uploadRouterlink: 'upload', authView: '', authCreate: '', authUpload: '' },
        // { id: 3, name: 'notes_and_comments', isCreate: true, isView: false, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "", createRouterlink: 'notes-comments-entry', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
        { id: 2, name: 'Document_Management', isCreate: false, isView: true, isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: '', viewRouterlink: 'doc-management', uploadRouterlink: '', authUpload: '', authCreate: '' },
        // { id: 2, name: 'securities_master_lower', isCreate: false, isView: true, isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: 'user-fund', viewRouterlink: 'securities-master', uploadRouterlink: '', authUpload: '', authCreate: '' },
        // { id: 6, name: 'vendors_update', isCreate: false, isView: false, isDownload: true, isSubmit: false, isDisabled: false, description: '', viewRouterlink: "", createRouterlink: 'vendors-upload', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },

      ],
      id: 5
    },
    // miscellaneousTabs: {
    //   Name: 'Miscellaneous', description: 'Document sharing with users/investors / Retrieve/Extract exchange data for current time or period.',
    //   tabs: [
    //     // { id: 1, name: 'Fetch Crypto Data', isSubmit: false, isDisabled: false, description: '', viewRouterlink: "", createRouterlink: 'fetch-live-data', uploadRouterlink: '', authView: '', authCreate: '', authUpload: '' },
    //     { id: 2, name: 'Document Management', isSubmit: false, isDisabled: true, description: '', authView: '', createRouterlink: 'doc-management-entry/create', viewRouterlink: 'doc-management', uploadRouterlink: '', authUpload: '', authCreate: '' },

    //   ]
    // }
  }
  getBasicDetails() {
    return new Promise(resolve => {
      this.fetchMultipleApiSector().subscribe(res => {
        this.amcList = res[0].resultList && res[0].resultList.length ? res[0].resultList.map(element => ({ label: element.fundName, value: { "id": element.id }, alias: element.fundAlias })) : [];
        this.fundList = res[1].resultList && res[1].resultList.length ? res[1].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.custodian = res[2].resultList && res[2].resultList.length ? res[2].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.investor = res[3].resultList && res[3].resultList.length ? res[3].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.assestBasedFee = res[4].resultList && res[4].resultList.length ? res[4].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        this.performanceFee = res[5].resultList && res[5].resultList.length ? res[5].resultList.map(element => ({ label: element.nameOfFee, value: element.nameOfFee })) : [];
        //  let basicTemp = this.basicDetails();
        // resolve(basicTemp)
      })
    })
  }

  getPrecentageProgress(username: any): Observable<any> {
    return this.http.get('userPerferenceV2/searchV2?view=ProgressPercentage&userName=' + username);
  }
  checkSetupProgress(): Observable<any> {
    return this.http.get('enterpriseV2/checkSetupProgress');
  }

  fetchMultipleApiSector(): Observable<any> {
    let response1 = this.http.get<any>('assetManagementV2/records/false/0');
    let response2 = this.http.get<any>('fundV2/records/false/0');
    let response3 = this.http.get<any>('brokerAccountV2/records/false/0');
    let response4 = this.http.get<any>('brokerAccountV2/records/false/0');
    let response5 = this.http.get<any>('brokerAccountV2/records/false/0');
    let response6 = this.http.get<any>('brokerAccountV2/records/false/0');
    return forkJoin([response1, response2, response3, response4, response5, response6]);
  }

  hideAllSection() {
    document.querySelectorAll(".mat-vertical-content-container").forEach((item, index) => {
      if (index != 0) {
        item['style'].cssText = `
      display: none;
      animation:  500ms ease-in-out both;
    `;
      }
    });
  }
  showAllSection() {
    document.querySelectorAll(".mat-vertical-content-container").forEach(item => {
      item['style'].cssText = `
      display: block;
      animation:  500ms ease-in-out both;
    `;
    });
  }

  chagngeTabIcon(index, evt) {
    document.querySelectorAll(".tab-hide").forEach((item, index1) => {
      if (index != index1) {
        if (item.children.length && item.children[0].classList.contains('adv-config')) {
          item.children[0].classList.remove('adv-config')
          item.children[0].classList.add('adv-config-after')
        }
      } else if (evt.target.children.length == 0) {
        if (item.children[0].classList.contains('adv-config-after')) {
          item.children[0].classList.remove('adv-config-after')
          item.children[0].classList.add('adv-config')
        } else {
          item.children[0].classList.remove('adv-config')
          item.children[0].classList.add('adv-config-after')
        }
      }
    })
  }

  advConfToggle(evt) {
    if (evt.target.classList.length) {
      if (evt.target.classList.contains('adv-config-acc-after')) {
        evt.target.classList.add('adv-config-acc')
        evt.target.classList.remove('adv-config-acc-after')
      } else {
        evt.target.classList.add('adv-config-acc-after')
        evt.target.classList.remove('adv-config-acc')
      }
    }

  }

  setupIcon() {
    document.querySelectorAll(".tab-hide").forEach((item, index1) => {
      if (index1 == 0) {
        if (item.children[0].classList.contains('adv-config')) {
          item.children[0].classList.remove('adv-config')
          item.children[0].classList.add('adv-config-after')
        }
      }
    })
  }

  getActionMethod() {
    return this.setupActionsubject.asObservable();
  }
  setActionMethod(data) {
    if (data)
      this.setupActionsubject.next(data)
  }

  getTabSelected() {
    return this.tabhighlightsubject.asObservable();

  }
  setTabSelected(data) {
    if (data)
      this.tabhighlightsubject.next(data)
  }
  getSetupSetting(username: any): Observable<any> {
    return this.http.get('userPerferenceV2/searchV2?view=RapidSetupSetting&userName=' + username);
  }
  setSetupSetting(data: any) {
    return this.http.post('userPerferenceV2/create', data)
  }
  updateSetupSetting(data) {
    return this.http.put<any>('userPerferenceV2/update', data);

  }

}
