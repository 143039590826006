<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<!-- New Loading Spinner Code Start -->

<div>
  <img [src]='isBlueSpinner' style="height: 80px; width: 80px;">
</div>

<!-- New Loading Spinner Code End --> 

<!-- <svg class="spinner center" viewBox="0 0 50 50">
  <circle class="path" cx="25" cy="25" r="12" fill="none" stroke-width="5">

</circle></svg> -->
<!-- <style>
  #fury-splash-screen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #13293d;
    z-index: 99999;
    pointer-events: none;
  }

  #fury-splash-screen > .wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    
  }
  

  </style> -->
<!--   
  /* @-webkit-keyframes ball-scale-multiple {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes ball-scale-multiple {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  } */

  /* .ball-scale-multiple {
    position: relative;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  .ball-scale-multiple > div:nth-child(2) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .ball-scale-multiple > div:nth-child(3) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  } */

  /* .ball-scale-multiple > div {
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    left: -30px;
    top: 0;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
    animation: ball-scale-multiple 1s 0s linear infinite;
  } */
  /* .loader {
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: flex;
  
  padding: 150px;
 
}

.loader span {
  display: block;
  width: 20px;
  height: 20px;
  background: #1976d2;
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.loader span:nth-child(2) {
  background: #b3c2f5;
}

.loader span:nth-child(3) {
  background: #84cdfa;
}

.loader span:nth-child(4) {
  background: #5ad1cd;
}

.loader span:not(:last-child) {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

.loader span:last-child {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}


  .waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0,0,0,.2));
  font-family:'Times New Roman';
  right:-8em;
  top: -7em;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i))
}
@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}
</style> -->

<!-- <div id="fury-splash-screen">
  <div class="wrapper">
    
    <div class="loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
   
    
    
  </div>
</div>  -->

<!-- DEsign 1 -->

<!-- <style>
 .spinner {
  width: 55px;
  height: 55px;
  position: relative;
  color: #1976d2;
  animation: spinner 3.5s infinite linear  both;
}

.spinner-dot {
  width: 100%;
  height: 100%;
  color: #1976d2;
  position: absolute;
  margin: 0 5px;
  left: 0;
  top: 0; 
  animation: spinner-dot 1.2s infinite ease-in-out both; 
}

.spinner-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background: #1976d2;
  border-radius: 100%;
  animation: spinner-dot-before 1.5s infinite ease-in-out both; 
}

.spinner-dot:nth-child(1) { animation-delay: -1.1s;  }
.spinner-dot:nth-child(2) { animation-delay: -1.0s; }
.spinner-dot:nth-child(3) { animation-delay: -0.9s; }
.spinner-dot:nth-child(4) { animation-delay: -0.8s; }

.spinner-dot:nth-child(1):before { animation-delay: -1.1s; background-color: #1976d2; }
.spinner-dot:nth-child(2):before { animation-delay: -1.0s;  background-color:  #58baf7;}
.spinner-dot:nth-child(3):before { animation-delay: -0.9s;   background-color:  #5ad1cd;;}
.spinner-dot:nth-child(4):before { animation-delay: -0.8s;   background-color:  #38aef7;}


@keyframes spinner {
  100% { transform: rotate(360deg); } 
}

@keyframes spinner-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes spinner-dot-before {
  80%, 100% { transform: rotate(360deg); } 
}

</style>

<div class="spinner">
  <div class="spinner-dot"></div>
  <div class="spinner-dot"></div>
  <div class="spinner-dot"></div>
  <div class="spinner-dot"></div>
  
 </div> -->


 



  <!-- NEW-DESIGN-Loader -->
<!--   
  <div class="spin l-1">
    <div class="l-inner2"></div>
    <div class="l-outter"></div>
    <div class="l-inner"></div>

  </div>

 <style>
       @keyframes l-1-inner2 {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }

  
  @keyframes l-1-outter {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }
  
 
  
  @keyframes l-1-inner {
      0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
      }
  }
   *,
  *:before,
  *:after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
  }
  
    
      
  .spin {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 75px;
      display: inline-block;
      vertical-align: middle;
  }
  
  .l-1 .l-outter {
      position: absolute;
      border: 4px solid #1976D2;
      border-right-color:#033e79;
      border-left-color: transparent;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      
      animation:  l-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }
  .l-1 .l-inner2 {
      position: absolute;
      border: 4px solid #1976D2;
      border-left-color:#094e94;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      left: calc(60% - 20px);
      
      top: calc(60% - 20px);
      border-right: 0;
      border-top-color: transparent;
     
      animation: l-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }
  .l-1 .l-inner {
      position: absolute;
      border: 4px solid #1976D2;
      border-left-color:#094e94;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      left: calc(50% - 20px);
      top: calc(50% - 20px);
      border-right: 0;
      border-top-color: transparent;
     
      animation: l-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }
 </style> -->



<!-- Animation 1st -->

<!-- <section id="global" class="spinner_text">

  <div id="top" class="mask">
    <div class="plane"></div>
  </div>
  <div id="middle" class="mask">
    <div class="plane"></div>
  </div>

  <div id="bottom" class="mask">
    <div class="plane"></div>
  </div>
  
<p><i>SEAMLESS...</i></p>
  
</section> -->

<!-- END Animation 1st -->




