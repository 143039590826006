/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  EventEmitter,
  Output
} from "@angular/core";
import { GlobalService } from "../../services/commonServices/global.service";
import { GridService } from "../../services/gridServices/grid.service";
import { UploadService } from "../../services/uploadService/upload.service";
import alasql from "../../../../../node_modules/alasql";
import * as XLSX from "../../../../../node_modules/xlsx";
import { apiConstants } from "../../../../../src/app/constants/apiConstants";
import { GlobalMessageService } from "src/app/shared/services/commonServices/global-message.service";
import { DemoDialogComponent } from "../../../../../src/app/pages/components/dialogs/dialogs.component";
import { MatDialog } from "@angular/material/dialog";
import { UploadDialogComponent } from "../../dialogs/upload-dialog-component";
import { fadeInUpAnimation } from "../../../../../src/@fury/animations/fade-in-up.animation";
import { fadeInRightAnimation } from "../../../../../src/@fury/animations/fade-in-right.animation";
import { CommonService } from "../../services/commonServices/common.service";
import { MatStepper } from "@angular/material/stepper";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Router, ActivatedRoute } from "@angular/router";
import { take, takeUntil } from "rxjs/operators";
import moment from "moment";
import { ProcessScreenService } from "../../services/processService/process-screen.service";
import FileSaver from "file-saver";
import JSZip from "jszip";
import { environment } from "../../../../../src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SetupPercentageService } from "src/app/shared/services/commonServices/setup-percentage.service";
import { LocalStoreService } from '../../services/commonServices/local-store.service';

alasql["utils"].isBrowserify = false;
alasql["utils"].global.XLSX = XLSX;

@Component({
  selector: "file-dynamic-upload",
  templateUrl: "./file-dynamic-upload.component.html",
  styleUrls: ["./file-dynamic-upload.component.scss"],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class FileDynamicUploadComponent implements OnInit {
  @Input() isUploadBtn: Boolean;
  @Input() isSubmitBtn: Boolean;
  @Input() isShowCustomLBtn: Boolean;
  @Input() showSelectClient: Boolean;
  @Input() isTemplateBtn: Boolean;
  @Input() showSmType: Boolean;
  @Input() operationType: Boolean;
  @Input() heading: String;
  @Input() isCloseBtn: Boolean;
  @Input() componentName: any;
  @Input() isCarryWeekend: Boolean = false;
  @Input() isShowThirdParySource: Boolean = false;
  @Input() isNotShowBreadCrum: Boolean;
  @Input() isShow: any;
  @Input() uniqueId: any;
  @ViewChild("fileUpload") fileUpload: any;
  @Output() uploadResult: EventEmitter<any> = new EventEmitter();
  public isDoubleClick: boolean = false;

  public displayBasic: boolean;
  public displayBasic2: boolean;
  public UploadApi: string;
  public domainCustomTypes: any;
  public prop;
  public thirdParySource;
  public smTypes;
  public operationTypes;
  public value: any;
  selectedOption: any;
  public tradeData = [];
  public configFields = {};
  public currentUser: any;
  public uploadedData: any;
  public uploadDetails: any;
  public uploadDataName: string;
  public resName: string;
  public sampleName: string;
  public dataStringify: Boolean = false;
  public moduleName: any;
  public selectedList = [false];
  public nextBtn: Boolean = false;
  submitDisable: boolean;
  reqObj: string | Object;
  selectedOptionVar: any;
  carryWeekend: boolean = true;
  public headertitle: string;
  uploadUrl: string;
  smtype: string;
  opType: string;
  displayNoCustom: boolean = false;
  public uploadTitleName: string;
  filename: any;
  dragDrop: boolean = true;
  public formValid: boolean = true;
  public daysInMilliSecs: any;
  getConfigCheck: boolean;
  smName: any;
  fileData: File;
  successListCount: any;
  failureListCount: any;
  tradeUploadId: any;
  selectPreviewAndAggregation: boolean;
  pathName: any;
  card: any;
  public fschartList: any;
  public roleAs: any = localStorage.getItem("ROLE");
  genericUploadComponents: any = ['tradeList', 'smEquitiesList', 'smOptionsList', 'smFixedIncomeList', 'smFxForwardList', 'smPrivateFundList', 'smFutureList'];
  createDefaultConfigModule: any = ['globalallsmupload', 'investorAllocationProgram', "grossCashFlowAdjustment", "contributionDistributionAdjustment", 'vendorsUpload', 'notesList', 'allsmupdate', 'fschartOfAccounts', 'fsAllSMupdate', 'chartOfAccountTaxUpdate', 'setupJeTransfer', 'reBudgets', 'MISFundList', 'investorUpdate', 'periodEndIndicatorsByFund', 'taxK1BoxConfiguration','FootNoteMaster','taxAllocationAdjustmentRatios','commonIncomeExpenseConfiguration'];

  constructor(
    private uploadService: UploadService,
    private globalService: GlobalService,
    private gridService: GridService,
    private messageService: GlobalMessageService,
    private commonService: CommonService,
    private dialog: MatDialog,
    private processScreenService: ProcessScreenService,
    private translateService: TranslateService,
    private setuppercentage: SetupPercentageService,
    private router: Router,
    private http: HttpClient,
    private localService: LocalStoreService
  ) {
    this.prop = [{ name: "Client" }, { name: "Broker" }];
    this.operationTypes = [{ name: "Update", value: "Update" }];

    this.thirdParySource = [{ name: "Client" }, { name: "ThirdParySource" }];
    this.translateService.onLangChange.subscribe(() => {
      // this.commonService.moduleNameObs$.subscribe((moduleName) => {
      //   this.moduleName = moduleName;
      //   this.translateService.get(this.moduleName).subscribe((res) => {
      //     this.moduleName = res;
      //   });
      // });
      this.moduleName = this.router.url.split("/")[1];
      switch (this.moduleName) {
        case "trades":
          this.moduleName = "trades_module";
          break;
        case "portfolio":
          this.moduleName = "portfolio";
          break;
        case "accounting":
          this.moduleName = "accounting";
          break;
        case "investor":
          this.moduleName = "investors";
          break;
        case "processing":
          this.moduleName = "processing";
          break;
        case "workflow":
          this.moduleName = "workflow";
          break;
        case "reconciliation":
          this.moduleName = "reconciliation";
          break;
        case "query":
          this.moduleName = "query";
          break;
        case "batch-tracking":
          this.moduleName = "batch_tracking";
          break;
        case "reports":
          this.moduleName = "seamless_reports";
          break;
        case "setting":
          this.moduleName = "settings";
          break;
        case "manager":
          this.moduleName = "manager";
          break;
        case 'legacy':
          this.moduleName = 'legacy';
          break;
        case 'MIS':
          this.moduleName = 'mis';
          break;
        case 'Entities':
          this.moduleName = 'Entities';
          break;
        case 'activity-upload-review':
          this.moduleName = "activity_upload_review";
          break;
        case 'systemmanager':
          this.moduleName = "admin";
          break;
        default:
          this.commonService.moduleNameObs$.subscribe((moduleName) => {
            this.moduleName = moduleName;
            this.translateService.get(this.moduleName).subscribe((res) => {
              this.moduleName = res;
            });
          });
          break;
      }
      this.translateService.get(this.moduleName).subscribe((res) => {
        this.moduleName = res;
      });
    });
  }

  protected _onDestroy = new Subject<void>();
  public uploadCtrl: FormControl = new FormControl();
  public uploadFilterCtrl: FormControl = new FormControl();
  public filteredSMTypes: ReplaySubject<[]> = new ReplaySubject<[]>(1);

  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;

  ngOnInit(): void {
    this.displayBasic2 = false;
    if (this.componentName == "uploadWorkflow") {
      this.displayBasic = false;
      this.displayBasic2 = true;
    }
    this.moduleName = this.router.url.split("/")[1];
    switch (this.moduleName) {
      case "trades":
        this.moduleName = "trades_module";
        break;
      case "portfolio":
        this.moduleName = "portfolio";
        break;
      case "accounting":
        this.moduleName = "accounting";
        break;
      case "investor":
        this.moduleName = "investors";
        break;
      case "processing":
        this.moduleName = "processing";
        break;
      case "workflow":
        this.moduleName = "workflow";
        break;
      case "reconciliation":
        this.moduleName = "reconciliation";
        break;
      case "query":
        this.moduleName = "query";
        break;
      case "batch-tracking":
        this.moduleName = "batch_tracking";
        break;
      case "reports":
        this.moduleName = "seamless_reports";
        break;
      case "setting":
        this.moduleName = "settings";
        break;
      case "manager":
        this.moduleName = "manager";
        break;
      case 'legacy':
        this.moduleName = 'legacy';
        break;
      case 'MIS':
        this.moduleName = 'mis';
        break;
      case 'Entities':
        this.moduleName = 'Entities';
        break;
      case 'activity-upload-review':
        this.moduleName = "activity_upload_review";
        break;
      default:
        this.commonService.moduleNameObs$.subscribe((moduleName) => {
          this.moduleName = moduleName;
          this.translateService.get(this.moduleName).subscribe((res) => {
            this.moduleName = res;
          });
        });
        break;
    }
    this.translateService.get(this.moduleName).subscribe((res) => {
      this.moduleName = res;
    });

    if (this.roleAs == "SystemManager") {
      this.card = apiConstants[this.componentName]["systemAdminCard"];
    } else if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
      this.card = apiConstants[this.componentName]["entCard"];
    } else if (this.roleAs == "User") {
      this.card = apiConstants[this.componentName]["userCard"];
    } else {
      undefined;
    }
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.uploadDetails = apiConstants[this.componentName]["uploadDetails"];
    this.pathName = apiConstants[this.componentName]?.pathName;
    this.uploadDataName =
      apiConstants[this.componentName]["uploadDetails"].name;
    this.headertitle = "upload_";
    this.uploadTitleName = "upload_" + this.uploadDataName + "_file";
    this.resName = apiConstants[this.componentName]["uploadDetails"].resName;
    this.sampleName = apiConstants[this.componentName]["uploadDetails"]
      .sampleName
      ? apiConstants[this.componentName]["uploadDetails"].sampleName
      : "";
    this.dataStringify =
      apiConstants[this.componentName]["uploadDetails"].dataStringify || false;
    if (this.componentName != "tradeUploadHV" || this.componentName != "rapidTradeUploadHV") {
      this.fetchAllCustomTypes();
    }

    this.value = "Client";
    if (this.componentName == "allsmupload" || this.componentName=='globalallsmupload' || this.componentName == "allsmupdate" || this.componentName == "fsAllSMupdate") {
      this.gridService.getSMNames(
        "AllSMDownload/getAllSMNames",
        (cbs) => {
          this.smTypes = cbs.allSMNames;
          this.uploadCtrl.setValue(this.smTypes);

          // load the initial sm  list
          this.filteredSMTypes.next(this.smTypes.slice());

          // listen for search field value changes
          this.uploadFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterSMTypes();
            });
        },
        (cbe) => { }
      );
    }
  }

  protected filterSMTypes() {
    if (!this.smTypes) {
      return;
    }
    // get the search keyword
    let search = this.uploadFilterCtrl.value;
    if (!search) {
      this.filteredSMTypes.next(this.smTypes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSMTypes.next(
      this.smTypes.filter(
        (element) => element.name.toLowerCase().indexOf(search) > -1
      )
    );
  }
  fetchAllCustomTypes() {
    if (this.createDefaultConfigModule.includes(this.componentName)) {
      let reqObj = this.componentName == 'fschartOfAccounts' ? 'chartOfAccountUpdate' : this.componentName == 'fsAllSMupdate' ? 'smupdate' : this.componentName == 'contributionDistributionAdjustment' ? 'contributionDistributionAdjustment' : this.componentName == 'grossCashFlowAdjustment' ? 'grossCashFlowAdjustment' : this.componentName == 'investorAllocationProgram' ? 'investorAllocationProgram' :this.componentName == 'commonIncomeExpenseConfiguration' ? 'commonIncomeExpenseConfig' : this.componentName == 'MISFundList' ? 'internalAttributes' : this.componentName;

      this.uploadService.createDefaultConfig(this.uploadDetails.createDefaultConfigUrl, reqObj).subscribe((res) => {
          this.uploadService.fetchAllCustomTypes(
            this.uploadDetails.getCustomTypeUrl,
            (response) => {
              this.domainCustomTypes = response.resultList;
              if (this.domainCustomTypes.length) {
                this.displayBasic = true;
              } else if (this.componentName == "reportDeliveryConfig") {
                this.displayBasic = false;
                this.displayNoCustom = false;
              } else {
                this.displayNoCustom = true;
              }
            })
        }, (cbe) => { })
    }

    else {
      this.uploadService.fetchAllCustomTypes(
        this.uploadDetails.getCustomTypeUrl,
        (response) => {
          this.domainCustomTypes = response.resultList;

          if (this.domainCustomTypes.length) {
            this.displayBasic = true;
          } else if (this.componentName == "reportDeliveryConfig") {
            this.displayBasic = false;
            this.displayNoCustom = false;
          } else {
            this.displayNoCustom = true;
          }
        }
      );
    }
  }
  openDialog() {
    this.dialog
      .open(UploadDialogComponent, {
        disableClose: false
      })
      .afterClosed()
      .subscribe((result) => { });
  }

  selectCustomType = function (customType) {
    this.selectedOption = customType;
    this.displayBasic = false;
    if (this.domainCustomTypes.length) {
      this.getConfigFields(customType);
    }
  };

  getConfigFields(customType) {
    this.getConfigCheck = true;
    this.gridService.getConfigFields(
      this.uploadDetails.getConfigUrl,
      customType,
      (cbs) => {
        if (cbs.resultList) {
          var list = cbs.resultList;
          this.configFields = {};
          for (var i = 0; i < list.length; i++) {
            this.configFields[list[i].userField] = list[i].serverField;
          }
        }
      },
      (cbe) => { }
    );
  }

  downloadSampleFile() {
    this.translateService.get(this.uploadDataName).subscribe((res) => {
      this.uploadDataName = res;
    });
    var sampletradeData = this.globalService.getFieldsForExcel(this.sampleName);
    if (this.componentName == "tradeUploadHV" || this.componentName == "rapidTradeUploadHV") {
      const zip = new JSZip();
      var data, filename, link;
      var csv = this.convertArrayOfObjectsToCSV({
        data: sampletradeData
      });
      if (csv == null) return;
      filename = this.sampleName + ".csv" || "export.csv";
      data = csv;
      link = document.createElement("a");
      zip.file(filename, data);
      zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver(content, "tradeuploadhv.zip");
      });
    } else {
      alasql(
        'SELECT * INTO XLSX("Sample ' +
        this.uploadDataName +
        '.xlsx",{headers:true}) FROM ?',
        [sampletradeData]
      );
    }
  }

  clear() {
    this.formValid = true;
    this.fileUpload.clear();
    this.uploadedData = null;
    this.dragDrop = true;
    if (this.componentName == "uploadWorkflow") {
      this.uploadResult.emit("reset");
    }
  }

  onRead(evt) {
    if (!this.getConfigCheck && this.componentName != "uploadWorkflow" && this.componentName != "updateFSTransactionCode") {
      this.clear();
      this.toast("info", "File Upload", "Please Select Custom Type", true);
      return;
    }
    this.formValid = false;
    this.dragDrop = false;
    let workbook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.currentFiles[0];
    this.filename = evt.currentFiles[0];
    reader.onload = (event) => {
      const data = reader.result;
      workbook = XLSX.read(data, { type: "binary" });

      /* DO SOMETHING WITH workbook HERE */
      var fieldArray = [
        "createdDate",
        "pnlDate",
        "issueDate",
        "bondDefaultDate",
        "lastInterestPayDate",
        "firstInterestPayDate",
        "lastTradingDay",
        "expiryDate",
        "expiryDate",
        "effectiveDateTo",
        "dateOfRecordCreation",
        "effectiveDateFrom",
        "taxlotdate",
        "nextPayDate",
        "lastPaydate",
        "date",
        "dateOfIncorporation",
        "dateOfTax",
        "valuationDate",
        "valuationTime",
        "startDate",
        "transferDate",
        "endDate",
        "exDate",
        "payDate",
        "entryDate",
        "effectivedate",
        "tradeDateTime",
        "settlementDate",
        "entryDateTime",
        "maturityDate",
        "updatedOn",
        "dateOfIncorp",
        "tradingStartDate",
        "dateOfDoc",
        "fundStartDate",
        "fiscalYearEnd",
        "taxYearEnd",
        "serviceStartDate",
        "valuationDateAndTime",
        "eventDate",
        "issueStartDate",
        "issueEndDate",
        "exchangeTime",
        "exchangeDate",
        "taxLotDate",
        "tenureEndDate",
        "purchaseDate",
        "vintageDate",
        "customDate2",
        "customDate1",
        "fundEndDate",
        "closedDate",
        "jedate",
        "dateOfEntry",
        "date1",
        "date2",
        "timestamp",
        "expirationDate",
        "effectiveDate",
        "lastPayDate",
        "jeDate",
        "reconciliationDate",
        "holidayCalendar",
        "effectiveDateStart",
        "effectiveDateEnd",
        "createdOn",
        "startdate",
        "enddate",
        "seriesDate",
        "feeResetDate",
        "resetDate",
        "distributionDate",
        "ratioDate",
        "capitalCallDate",
        "ufSubscriptionDate",
        "periodLockDate",
        "reportApprovalDate",
        "ctaManagerStartDate",
        "ctaManagerEndDate",
         "startDateString", "endDateString",
        "fundVAMICalcSincePeriod",
        "redemptionLockupDate",
        "wsOpenTaxLotDate",
        "fundPeriodLockDate",
        "originalPurchaseDate",
        'acquisitionDate', 'dispositionDate', "currentDate","Date"
      ];

      var breakCheck = true;
      var sheet_name_list = workbook.SheetNames;
      var resultArray = [];
      var configFields = this.configFields;
      var currentUser = this.currentUser;
      sheet_name_list.forEach((y) => {
        /* iterate through sheets */
        var worksheet = workbook.Sheets[y];
        var cellFieldLinker = {};
        var dateFieldCells = [];
        var custodian = { id: "" };

        for (var z in worksheet) {
          /* all keys that do not begin with "!" correspond to cell addresses */
          if (z[0] === "!") continue;
          var res = z;
          var row = res.replace(/[^0-9]+/gi, "");
          var col = res.replace(row, "");

          if (row == "1") {
            var configIndexCheck =
              configFields[worksheet[z].v] != undefined &&
              configFields[worksheet[z].v] != null &&
              configFields[worksheet[z].v] != "";
            if (configFields[worksheet[z].v]) {
              cellFieldLinker[col] = configFields[worksheet[z].v];
            } else {
              cellFieldLinker[col] = worksheet[z].v;
            }
            if (fieldArray.indexOf(cellFieldLinker[col]) > -1) {
              dateFieldCells.push(col);
            }
          } else {
            var fieldName = cellFieldLinker[col];

            if (resultArray[Number(row) - 2] == undefined) {
              resultArray[Number(row) - 2] = {};
            }
            if (this.componentName != "MISFundList") {
              resultArray[Number(row) - 2].enterpriseId =
                currentUser.enterpriseId;
            }
            if (this.componentName != "MISFundList") {
              resultArray[Number(row) - 2].brokerAccount = {
                id: custodian.id
              }
            };
            if (this.componentName == "chartOfAccountTaxUpdate") {
              resultArray[Number(row) - 2].taxTeam = true;
            }
            if (
              (this.componentName == "smEquitiesList" ||
                this.componentName == "smFixedIncomeList" ||
                this.componentName == "smFutureList" ||
                this.componentName == "smFxForwardList" ||
                this.componentName == "smOptionsList" ||
                this.componentName == "smPrivateFundList") &&
              (fieldName == "TimeStamp" || fieldName == "timeStamp")
            ) {
              resultArray[Number(row) - 2][fieldName] = worksheet[z].w;
            } else {
              resultArray[Number(row) - 2][fieldName] = worksheet[z].v;
            }
            if (dateFieldCells.indexOf(col) > -1) {
              if (
                fieldName == "settlementDate" &&
                resultArray[Number(row) - 2][fieldName] == ""
              ) {
                resultArray[Number(row) - 2][fieldName] =
                  resultArray[Number(row) - 2]["tradeDateTime"];
              } else {
                resultArray[Number(row) - 2][fieldName] =
                  this.setDateFieldsFromDoc(worksheet[z].v);
              }
            }
          }
        }
        this.setuppercentage.setExcelFeild(Object.values(cellFieldLinker))

      });
      this.uploadedData = this.clearNullElementsInArray(resultArray);
    };
    reader.readAsBinaryString(file);
  }

  setDateFieldsFromDoc(value: any) {
    var numOfDays = Math.floor(value);
    this.daysInMilliSecs = 0;
    if (parseInt(new Date().toString().match(/([-\+][0-9]+)\s/)[1]) < 0) {
      this.daysInMilliSecs = (numOfDays - 25568) * 24 * 60 * 60 * 1000; // (no of days between 1/1/1900 to 1/1/1970) + 1 days = 25568
    } else if (numOfDays == 0) {
      this.daysInMilliSecs = "";
    } else {
      this.daysInMilliSecs = (numOfDays - 25569) * 24 * 60 * 60 * 1000; // (no of days between 1/1/1900 to 1/1/1970) + 2 days = 25569
    }
    var numOfDaysInFraction = value - numOfDays;
    var dayToHours = numOfDaysInFraction * 24;
    var hours = Math.floor(dayToHours);
    var numOfHoursInFraction = dayToHours - hours;
    var hourToMins = numOfHoursInFraction * 60;
    var mins = Math.floor(hourToMins);
    var numOfMinsInFraction = hourToMins - mins;
    var minToSecs = numOfMinsInFraction * 60;
    var secs = Math.round(minToSecs);
    var newDate = new Date(this.daysInMilliSecs);
    newDate.setHours(hours);
    newDate.setMinutes(mins);
    newDate.setSeconds(secs);
    if (this.componentName == "programHistoricalData") {
      newDate = value;
    }

    return String(newDate);
  }

  clearNullElementsInArray(arr) {
    var result = [];
    for (var c = 0; c < arr.length; c++) {
      if (arr[c] != undefined && arr[c] != null) {
        result.push(arr[c]);
      }
    }
    return result;
  }

  upload() {
    this.formValid = true;
    var fileNameArr = this.filename.name.split(".");
    if (
      fileNameArr[fileNameArr.length - 1] == "xlsx" ||
      fileNameArr[fileNameArr.length - 1] == "xls"
    ) {
      if (this.uploadedData) {
        this.submitDisable = true;
        if (this.componentName == "tradeList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element.parValue == "" || element.parValue == null) {
                element.parValue = 1;
              }
            });
          }
        }
        if (this.componentName == "smOptionsList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.usTaxExempt &&
                (element.usTaxExempt == "" || element.usTaxExempt == null)
              ) {
                element.usTaxExempt = "No";
              }
            });
          }
        }
        if (this.componentName == "contributionDistributionAdjustment" || this.componentName == "grossCashFlowAdjustment") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element.date)
                element.date = moment(element.date).format("YYYY-MM-DD"); {
              }
            });
          }
        }
        if (this.componentName == "chartOfAccountTaxUpdate") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId'];
              if (el?.["Seamless Tax Code"]) {
                el["seamlessTaxCode"] =
                  el["Seamless Tax Code"];
                delete el["Seamless Tax Code"];
              }
            });
          }
        }
        if (this.componentName == "taxK1BoxConfiguration") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              if (!("k1BoxConfigName" in el)) {
                el["k1BoxConfigName"] = "";
              }
            });
          }
        }
        if (this.componentName == "investorUpdate" || this.componentName == "contributionDistributionAdjustment" || this.componentName == "grossCashFlowAdjustment" || this.componentName =="commonIncomeExpenseConfiguration") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId']
            });
          }
        }
        if (this.componentName == "setupJeTransfer") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId']
            });
          }
        }
        if (this.componentName == "reBudgets" || this.componentName == "reviewChartOfAccounts" || this.componentName == "activityUploadReview" || this.componentName == "taxClassification" || this.componentName == "FootNoteMaster"|| this.componentName == "taxK1BoxConfiguration" || this.componentName == "taxAllocationAdjustmentRatios") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
              delete el['enterpriseId'];
            });
          }
        }
        if (this.componentName == "periodEndIndicatorsByFund"  || this.componentName =="commonIncomeExpenseConfiguration") {
          if (this.uploadedData) {
            this.uploadedData.forEach((el) => {
              delete el['brokerAccount'];
            });
          }
        }
        if (this.componentName == "investorAllocationProgram" || this.componentName == "contributionDistributionAdjustment"  || this.componentName == "grossCashFlowAdjustment") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
                if (element.date){
                  element.date = moment(element.date).format("YYYY-MM-DD");
                }
                if (element.startDateString) {
                  element.startDateString = moment(element.startDateString).format("YYYY-MM-DD");
                }
                if (element.endDateString) {
                  element.endDateString = moment(element.endDateString).format("YYYY-MM-DD");
                }
              
            });
          }
        }
        if (this.componentName == "chartOfAccounts" || this.componentName == "defaultChartOfAccounts") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element?.["Is this a cost account"]) {
                element["customField3"] = element["Is this a cost account"];
                delete element["Is this a cost account"];
              }
              if (element?.["Is this a portfolio account"]) {
                element["customField5"] =
                  element["Is this a portfolio account"];
                delete element["Is this a portfolio account"];
              }
              if (element?.["Seamless Tax Code"]) {
                element["seamlessTaxCode"] =
                  element["Seamless Tax Code"];
                delete element["Seamless Tax Code"];
              }
            });
          }
        }
        if (this.componentName == "dividendaccrual") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.paymentInKind == "" ||
                element.paymentInKind == null
              ) {
                element.paymentInKind = "No";
              }
              if (
                element.optionOfCashPay == "" ||
                element.optionOfCashPay == null
              ) {
                element.optionOfCashPay = "No";
              }
              // delete element['brokerAccount'];
            });
          }
        }
        if (this.componentName == "setupcustodianList" || this.componentName == "custodianList" || this.componentName == "custodianListLegacy") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (element?.["Custodian Name"]) {
                element["nameOfCustodian"] = element["Custodian Name"];
                delete element["Custodian Name"];
              }
              if (element?.["Account Number/Name"]) {
                element["accountNumber"] = element["Account Number/Name"];
                delete element["Account Number/Name"];
              }
              if (element?.["Connection ID/Account Number in Source File"]) {
                element["accountNumberInSourceFile"] = element["Connection ID/Account Number in Source File"];
                delete element["Connection ID/Account Number in Source File"];
              }
            });
          }
        }
        if (
          this.componentName == "marketdata" ||
          this.componentName == "uploadcashBalance" ||
          this.componentName == "uploadPositionBroker" ||
          this.componentName == "sourceDestTradeTransferTaxLotInfo" ||
          this.componentName == "cryptoMarketData" ||
          this.componentName == "nonTradeActivity" ||
          this.componentName == "investors" ||
          this.componentName == "uploadallocationhistory" ||
          this.componentName == "externalROR" ||
          this.componentName == 'rapidExternalROR' ||
          this.componentName == "sidepocketcreation" ||
          this.componentName == "dividendaccrual" ||
          this.componentName == "dividendadjuctment" ||
          this.componentName == "seamlessmarketdata" ||
          this.componentName == "bondinterestadjustment" ||
          this.componentName == "benchmark" ||
          this.componentName == "performanceAnalyticsSettings" ||
          this.componentName == "customRatio" ||
          this.componentName == "specialAllocation" ||
          this.componentName == "TLRegime" ||
          this.componentName == "takeOverPerfBaseFee" ||
          this.componentName == "transferTradeRawDataHistory" ||
          this.componentName == "historicalwashsaleopenLot" ||
          this.componentName == "setupInvestorsList" ||
          this.componentName == "notesList" || 
         this.componentName == "commonIncomeExpenseConfiguration"  ||
	   this.componentName == "investorAllocationProgram"
          || this.componentName == "contributionDistributionAdjustment" || this.componentName == "grossCashFlowAdjustment"
        ) {
          if (this.uploadedData.length != 0) {
            this.uploadedData.forEach((el) => {
              delete el["brokerAccount"];
            });
          }
          for (var key in this.uploadedData[0]) {
            if (this.uploadedData[0].hasOwnProperty(key)) {
              if (this.uploadedData[0][key] == "Invalid Date" || this.uploadedData[0][key] == "Invalid date") {
                this.uploadedData[0][key] = "";
              }
            }
          }
          dataPrep = { data: this.uploadedData };
        }

        if (this.componentName == "smFxForwardList") {
          if (this.uploadedData) {
            this.uploadedData.forEach((element) => {
              if (
                element.priceInversion &&
                (element.priceInversion == "" || element.priceInversion == null)
              ) {
                element.priceInversion = "No";
              }
            });
          }
        }
        if (this.componentName == "allsmupdate" ||
          this.componentName == "allsmupload" ||
          this.componentName == "custodianList" ||
          this.componentName == "custodianListLegacy" ||
          this.componentName == "FundList" ||
          this.componentName == "sourceDestTradeTransferTaxLotInfo" ||
          this.componentName == "cryptoMarketData" ||
          this.componentName == "nonTradeActivity" ||
          this.componentName == "investors" ||
          this.componentName == "setupInvestorsList" ||
          this.componentName == "uploadallocationhistory" ||
          this.componentName == "externalROR" ||
          this.componentName == 'rapidExternalROR' ||
          this.componentName == "sidepocketcreation" ||
          this.componentName == "takeOverPerfBaseFee" ||
          this.componentName == "TLRegime" ||
          this.componentName == "specialAllocation" ||
          this.componentName == "customRatio" ||
          this.componentName == "performanceAnalyticsSettings" ||
          this.componentName == "benchmark"
          || this.componentName == 'smRealestateList' ||
          this.componentName == "seamlessmarketdata" ||
          this.componentName == "smEquitiesList" ||
          this.componentName == "smFixedIncomeList" ||
          this.componentName == "smPrivateFundList" ||
          this.componentName == "smFutureList" ||
          this.componentName == "dailyMargin" ||
          this.componentName == "journalentry" ||
          this.componentName == "rapidCompoundJEListComponent" ||
          this.componentName == "tradeList" ||
          this.componentName == "vendors" ||
          this.componentName == "customer"||
          this.componentName == "smOptionsList" ||
          this.componentName == "smFxForwardList" ||
          this.componentName == "recurringTransaction" ||
          this.componentName == "programHistoricalData" ||
          this.componentName == "fsAllSMupdate" ||
          this.componentName == "fschartOfAccounts" ||
          this.componentName == "globalallsmupload"||
          this.componentName == "notesList" ||
          this.componentName == "commonIncomeExpenseConfiguration" 
          || this.componentName == "investorAllocationProgram"
          || this.componentName == "contributionDistributionAdjustment" || this.componentName == "grossCashFlowAdjustment"
        ) {
          this.uploadedData.forEach((element) => {
            for (var key in element) {
              if (element.hasOwnProperty(key)) {
                if ((!element[key] && !(element[key] === 0) && !(element[key] === false)) || (element[key] == "Invalid Date" || element[key] === " " || key == "dateOfTax")) {
                  if (key == 'auaCode') {
                    element[key] = element['auaCode'];
                  } else
                    delete element[key];
                } else if (typeof element[key] == "object") {
                  let keys = Object.keys(element[key]);
                  if (!element[key][keys[0]]) {
                    delete element[key];
                  }
                }
              }
            }
          });
          if (this.componentName == "fsAllSMupdate") {
            var sampletradeData = ["FS ProductTypes", "FS Country", "FS Sector", "FS Level"]
            this.uploadedData.forEach(ele => {
              for (const item of sampletradeData) {
                if (!ele.hasOwnProperty(this.configFields[item])) {
                  ele[(this.configFields[item] || item)] = ''
                }
              }
            })
        }
        }
        if (this.componentName == "setupFundList") {
          this.uploadedData.forEach((element) => {
            for (var key in element) {
              if (element.hasOwnProperty(key)) {
                if (
                  !element[key] ||
                  element[key] == "Invalid Date" ||
                  element[key] == " " ||
                  key == "dateOfTax"
                ) {
                  if (key == "defaultInvestorFormat") {
                    element[key] = "Investor Statement Generic";
                  } else if (key == "fundManager") {
                    element[key] = { id: this.currentUser["id"] };
                  } else if (key == "fundManagerId") {
                    element[key] = this.currentUser["firstName"];
                  } else if (key == 'auaCode') {
                    element[key] = element['auaCode'];
                  }
                  else delete element[key];
                } else if (typeof element[key] == "object") {
                  let keys = Object.keys(element[key]);
                  if (!element[key][keys[0]]) {
                    delete element[key];
                  }
                }
              }
            }

            element["defaultInvestorFormat"] =
              element["defaultInvestorFormat"] || "Investor Statement Generic";
            element["fundManagerId"] =
              element["fundManagerId"] || this.currentUser["firstName"];
            element["fundManager"] = element["fundManager"] || {
              id: this.currentUser["id"]
            };
          });
        }

        var dataPrep = new Object();

        switch (this.componentName) {
          case "tradeList": {
            dataPrep = {
              data: this.uploadedData,
              selectedOption: this.selectedOption
            };
            break;
          }
          case "vendors": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "customer": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "exchangerate": {
            let carryWeekEndDataexchange;
            if (this.carryWeekend) {
              carryWeekEndDataexchange = "Yes";
            } else {
              carryWeekEndDataexchange = "No";
            }
            dataPrep = {
              data: this.uploadedData,
              carryWeekEndData: carryWeekEndDataexchange
            };
            break;
          }
          case "vendorsUpload": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['id'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "marketdatavar": {
            let carryWeekEndData;
            if (this.carryWeekend) {
              carryWeekEndData = "Yes";
            } else {
              carryWeekEndData = "No";
            }

            dataPrep = {
              data: this.uploadedData,
              selectedOption: this.selectedOptionVar,
              carryWeekEndData: carryWeekEndData
            };
            break;
          }
          case "marketdata": {
            let carryWeekEndData;
            if (this.carryWeekend) {
              carryWeekEndData = "Yes";
            } else {
              carryWeekEndData = "No";
            }

            dataPrep = {
              data: this.uploadedData,
              selectedOption: this.selectedOption,
              carryWeekEndData: carryWeekEndData
            };
            break;
          }
          case "subsectors": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
          }
          case "uploadallocationhistory": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
          }
          case "setupInvestorsList":
          case "investors": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }

            dataPrep = { data: this.uploadedData };
          }
          case "programHistoricalData": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                if (el["ytdRor"] == "") {
                  delete el["ytdRor"];
                }
              });
            }

            dataPrep = { data: this.uploadedData };
          }

          case "recurringTransaction": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "chartOfAccounts": {
            delete this.uploadedData[0]["brokerAccount"];
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "defaultChartOfAccounts": {
            delete this.uploadedData[0]["brokerAccount"];
            this.uploadedData[0]["userId"] = this.uploadedData[0]["enterpriseId"]
            delete this.uploadedData[0]["enterpriseId"]
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "identifiers": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "performanceAnalyticsSettings": {
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "allsmupload": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "globalallsmupload": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "fschartOfAccounts": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "updateFSTransactionCode": {
            if (this.uploadedData.length != 0) {
              const sampleFSTransactionCode = ["fsTransactionCode", "tradeId"]
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el['enterpriseId'];
                for (const item of sampleFSTransactionCode) {
                  if (!el.hasOwnProperty(item)) {
                    el[item] = ''
                  }
                }
              });              
            }
            dataPrep = this.uploadedData;
            break;
          }
          case "allsmupdate": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "fsAllSMupdate": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["enterpriseId"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "setupbondinterest": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "tradingLevelChange": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "predefinedJe": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "hurdleRate": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "journalentry": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "rapidCompoundJEListComponent": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "custodianListLegacy":
          case "setupcustodianList":
          case "custodianList": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
                delete el["undefined"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "historicalInvestorCapitalSummary": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach((el) => {
                delete el["brokerAccount"];
              });
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "uploadWorkflow": {
            this.uploadedData.forEach((el) => {
              delete el["brokerAccount"];
            });
            dataPrep = { data: this.uploadedData, uniqueId: this.uniqueId };
            break;
          }
          case "connectUserFundList": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
                delete el['enterpriseId'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          case "connectUserInvestor": {
            if (this.uploadedData.length != 0) {
              this.uploadedData.forEach(el => {
                delete el['brokerAccount'];
              })
            }
            dataPrep = { data: this.uploadedData };
            break;
          }
          default: {
            dataPrep = { data: this.uploadedData };
          }
        }
        if (this.uploadedData.length != 0) {
          this.uploadedData.forEach((el) => {
            if (el["dateOfRecordCreation"] == "") {
              this.uploadedData["dateOfRecordCreation"] = new Date();
            }
          });
        }
        if (this.componentName == "allsmupload" || this.componentName == "allsmupdate") {
          if (this.smtype != undefined || this.smtype != null) {
            this.uploadUrl =
              "AllSMDownload/uploadAllSM/" +
              this.smtype + '/'+ false + 
              "/allSMs?smUploadConfigType=" +
              this.selectedOption;
          } else {
            this.toast(
              "info",
              "Please Select",
              "please select mandatory fields",
              true
            );
            return;
          }
        }
        else if (this.componentName == "globalallsmupload") {
          if (this.smtype != undefined || this.smtype != null) {
            this.uploadUrl =
              "AllSMDownload/uploadAllSM/" +
              this.smtype + '/' + true +
              "/allSMs?smUploadConfigType=" +
              this.selectedOption;
          } else {
            this.toast(
              "info",
              "Please Select",
              "please select mandatory fields",
              true
            );
            return;
          }
        }
        else if (this.componentName == "fsAllSMupdate") {
          if (this.smtype != undefined || this.smtype != null) {
            this.uploadUrl =
              "AllSMDownloadV2/smUpdate/" +
              this.smtype;
          } else {
            this.toast(
              "info",
              "Please Select",
              "please select mandatory fields",
              true
            );
            return;
          }
        }
        else {
          this.uploadUrl =
            this.componentName == "tradeList"
              ? this.value == "Client"
                ? this.uploadDetails.uploadUrl
                : this.value == "Broker"
                  ? "tradeEntryBrokerV2/upload"
                  : ""
              : this.uploadDetails.uploadUrl;
          if (this.componentName == "nonTradeActivity") {
            this.uploadUrl =
              this.componentName == "nonTradeActivity"
                ? this.value == "Client"
                  ? this.uploadDetails.uploadUrl
                  : this.value == "ThirdParySource"
                    ? "nonTradeTransactionTpSourceV2/newUpload"
                    : ""
                : this.uploadDetails.uploadUrl;
          }
        }
        if (this.componentName == "uploadallocationhistory") {
          this.reqObj = dataPrep;
        }
        try {
          if (this.componentName == "tradeList") {
            this.reqObj = btoa(JSON.stringify(dataPrep));
          }
        }
        catch (error) {
          this.toast(
            "error", 'Trade list could not be uploaded due to special character(s). Please recheck and upload again.',
            '',
            true
          );
          throw error;
        }
        if (
          this.componentName == "investorsList" ||
          this.componentName == "setupInvestorsList"
          || this.componentName == "investorAllocationProgram"
        ) {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == 'vendorsUpload') {
          this.reqObj = JSON.stringify(dataPrep);
        }
        else if (this.componentName == "investorPortalUser") {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == "setupJeTransfer") {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == "reBudgets" || this.componentName == "reviewChartOfAccounts" || this.componentName == "activityUploadReview" || this.componentName == "taxClassification"  || this.componentName == "FootNoteMaster"|| this.componentName == "taxK1BoxConfiguration"|| this.componentName == "taxAllocationAdjustmentRatios") {
          this.reqObj = this.uploadedData;
        } else if (this.componentName == "reportingPortalUser") {
          this.reqObj = dataPrep;
        } else if (this.componentName == "historicalInvestorCapitalSummary") {
          this.reqObj = dataPrep;
        }
        else if (this.genericUploadComponents.includes(this.componentName)) {
          this.reqObj = btoa(JSON.stringify(dataPrep));
        }

        else if (this.componentName == "smRealestateList") {
          this.reqObj = window.btoa(unescape(encodeURIComponent(JSON.stringify(dataPrep))));
        }
        else if (this.componentName == "chartOfAccountTaxUpdate") {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == "investorUpdate") {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == "periodEndIndicatorsByFund") {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == "MISFundList") {
          this.reqObj = dataPrep;
        }
        else if (this.componentName == "commonIncomeExpenseConfiguration") {
          this.reqObj = dataPrep;
        }
        else if (
          this.componentName == "FundList" ||
          this.componentName == "setupFundList"
        ) {
          this.reqObj = dataPrep;
        } else {
          this.reqObj = this.dataStringify
            ? btoa(JSON.stringify(dataPrep))
            : dataPrep;
        }
        if (this.componentName == "setupcustodianList") {
          this.fetchtradeAdvisor().subscribe((res) => {
            let tradeAdvisorId =
              res.resultList.length > 0 ? res.resultList[0].nameOfAdvisor : "";
            this.uploadedData.forEach((element) => {
              for (var key in element) {
                if (key == "Key") {
                  element[key] = btoa(element[key]);
                }
                if (key == "Secret") {
                  element[key] = btoa(element[key]);
                }
                if (key == "Pass Phrase") {
                  element[key] = btoa(element[key]);
                }
                if (element.hasOwnProperty(key)) {
                  if (
                    !element[key] ||
                    element[key] == "Invalid Date" ||
                    element[key] == "" ||
                    element[key] == " " ||
                    key == "dateOfTax"
                  ) {
                    if (key == "taxlotclosingRankingMethod") {
                      element[key] = "FIFO";
                    } else if (key == "tradeAdvisorId") {
                      element[key] = tradeAdvisorId;
                    } else {
                      delete element[key];
                    }
                  } else if (typeof element[key] == "object") {
                    let keys = Object.keys(element[key]);
                    if (!element[key][keys[0]]) {
                      delete element[key];
                    }
                  }
                }
                let timeStamp: any = key['fund']?.timeStamp != null || key['fund']?.timeStamp != undefined ? key['fund']?.timeStamp.split(':') : "23:59:59".split(':');
                let timeStampTemp = timeStamp[2] + ' ' + timeStamp[1] + ' ' + timeStamp[0] + ' 1/1 * ? *';
                element["timeStamp"] = btoa(timeStampTemp);

                element["taxlotclosingRankingMethod"] =
                  element["taxlotclosingRankingMethod"] || "FIFO";
                element["tradeAdvisorId"] =
                  element["tradeAdvisorId"] || tradeAdvisorId;
              }
            });
            this.gridService.getUploadData(
              this.uploadUrl,
              this.reqObj,
              (response) => {
                this.formValid = false;
                let type: string = 'Zip file';
                var res = response.data ? response.data : response;
                if (res) {
                  var count = 0;
                  for (var i in res) {
                    if (res[i]) {
                      count++;
                    }
                  }
                  if (res && res.failedObjects != null) {
                    count = res.failedObjects.length;
                  }
                  var alertDataObj = {};
                  var goToListScreen = true;
                  if (
                    count != 0 &&
                    count != this.uploadedData.length &&
                    res.message !== "Data Saved"
                  ) {
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });
                    var name = "Warning!" + this.uploadDataName + " uploaded";
                    var message =
                      count +
                      " " +
                      this.uploadDataName +
                      " uploaded are n't saved due to invalid data. Please check once.";
                    this.toast("warn", name, message, true);
                    if (this.componentName != "uploadWorkflow") {
                      var arg = this.resName ? res[this.resName] : res['failedObjects'];
                      this.downloadFailureTrades(arg);
                    } else {
                      if (res && res['failedObjects']) {
                        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                        // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                        this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                      }
                    }
                  } else if (
                    count != 0 &&
                    count == this.uploadedData.length &&
                    res.failedObjects != null
                  ) {
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });
                    goToListScreen = false;
                    name = "Uploading aborted!";
                    if (this.uploadDataName == 'Investors') {
                      message = "Investor records were not uploaded. Please check error file.";
                    } else {
                      message = "All " + this.uploadDataName + " aren't saved due to invalid data. Please check once.";
                    }
                    this.toast("error", name, message, true);
                    if (this.componentName != "uploadWorkflow") {
                      var arg = this.resName ? res[this.resName] : res['failedObjects'];
                      this.downloadFailureTrades(arg);
                    } else {
                      if (res && res['failedObjects']) {
                        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                        // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                        this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                      }
                    }
                  } else if (count == 0 && count == this.uploadedData.length) {
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });
                    var name = "Warning!";
                    var message = "Uploaded file has no data.";
                    this.toast("info", name, message, true);
                    if (this.componentName != "uploadWorkflow") {
                      var args = this.resName ? res[this.resName] : res['failedObjects'];
                      this.downloadFailureTrades(args);
                    } else {
                      if (res && res['failedObjects']) {
                        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                        // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                        this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                      }
                    }
                  } else if (res.message == "DataNotFound") {
                    this.toast(
                      "error",
                      "No Recods Found",
                      "No records found in the uploaded file",
                      true
                    );
                  } else {
                    this.clear();
                    this.translateService
                      .get(this.uploadDataName)
                      .subscribe((res) => {
                        this.uploadDataName = res;
                      });

                    let message1 =
                      "unable to upload" +
                      " " +
                      this.smName +
                      " " +
                      "please contact System Administrator.";
                    if (
                      (this.componentName == "globalallsmupload" || this.componentName == "allsmupload" || this.componentName == "allsmupdate" || this.componentName == "fsAllSMupdate") &&
                      res.message == message1
                    ) {
                      this.toast(
                        "error",
                        this.uploadDataName,
                        res.message,
                        true
                      );
                      //  this.toast('error', "", res.message, true);
                    } else {
                      this.setuppercentage.setUploadTrigger(true);
                      if (this.uploadDataName == 'Investors') {
                        this.toast('success', "", "Investor(s)" + " uploaded successfully.", true);
                      } else {
                        this.toast('success', "", this.uploadDataName + " Uploaded Successfully.", true);
                      }
                    }
                  }
                  this.submitDisable = false;
                }
              },
              (cbe) => {
                this.formValid = true;
                this.translateService
                  .get(this.uploadDataName)
                  .subscribe((res) => {
                    this.uploadDataName = res;
                  });

                this.toast(
                  "error",
                  "",
                  this.uploadDataName +
                  " records could not be uploaded due to invalid data. Please check the downloaded excel file",
                  true
                );

                this.submitDisable = false;
              }
            );
          });
        } else {
          this.gridService.getUploadData(
            this.uploadUrl,
            this.reqObj,
            (response) => {
              this.formValid = false;
              let type: string = 'Zip file';
              var res = response.data ? response.data : response;
              if (res) {
                var count = 0;
                for (var i in res) {
                  if (res[i]) {
                    count++;
                  }
                }
                if (res && res.failedObjects != null) {
                  count = res.failedObjects.length;
                }
                var alertDataObj = {};
                var goToListScreen = true;

                if (this.componentName == "uploadWorkflow") {
                  this.uploadDataName = "up_workflow";
                }
                if (
                  count != 0 &&
                  count != this.uploadedData.length &&
                  res.message !== "Data Saved"
                ) {
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });
                  var name = "Warning!" + this.uploadDataName + " uploaded";
                  if (this.uploadDataName == 'Investors') {
                    var name = "Warning!" + this.uploadDataName + " not uploaded";
                    var message = count + " " + 'Investor records were not uploaded. Please check error file.';
                  }
                  else if (this.componentName == 'globalallsmupload' && res.message !='Invalid JSON Provided.' && !res['failedObjects']) {
                    this.toast('success', "Success", "All SM Uploaded Successfully.", true);
                    return;
                  } 
                  else {
                    var message = count + " " + this.uploadDataName + " uploaded aren't saved due to invalid data. Please check once.";
                  }
                  this.toast("warn", name, message, true);
                  if (this.componentName == "updateFSTransactionCode") {
                    this.downloadFailureTrades(res);
                  }
                  if (this.componentName != "uploadWorkflow") {
                    var args = this.resName ? res[this.resName] : res['failedObjects'];
                    this.downloadFailureTrades(args);
                  } else {
                    if (res && res['failedObjects']) {
                      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                     // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                      this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                    }
                  }
                } else if (
                  count != 0 &&
                  count == this.uploadedData.length &&
                  res.failedObjects != null
                ) {
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });
                  goToListScreen = false;
                  name = "Uploading aborted!";
                  if (this.uploadDataName == 'Investors') {
                    message = "Investor records were not uploaded. Please check error file.";
                  } else {
                    message = "All " + this.uploadDataName + " aren't saved due to invalid data. Please check once.";
                  }
                  this.toast("error", name, message, true);
                  if (this.componentName != "uploadWorkflow") {
                    var arg = this.resName ? res[this.resName] : res['failedObjects'];
                    this.downloadFailureTrades(arg);
                  } else {
                    if (res && res['failedObjects']) {
                      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                      // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                      this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                    }
                  }
                } else if (count == 0 && count == this.uploadedData.length) {
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });
                  var name = "Warning!";
                  var message = "Uploaded file has no data.";
                  this.toast("info", name, message, true);
                  if (this.componentName != "uploadWorkflow") {
                    var args = this.resName ? res[this.resName] : res['failedObjects'];
                    this.downloadFailureTrades(args);
                  } else {
                    if (res && res['failedObjects']) {
                      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + this.uniqueId + '/' + type;
                   //   this.processScreenService.downloadCSV(url, 'Failed Data.xlsx', 'Failed Data');
                      this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                    }
                  }
                } else if (res.message == "DataNotFound") {
                  this.toast(
                    "error",
                    "No Recods Found",
                    "No records found in the uploaded file",
                    true
                  );
                } else {
                  this.clear();
                  this.translateService
                    .get(this.uploadDataName)
                    .subscribe((res) => {
                      this.uploadDataName = res;
                    });

                  let message1 =
                    "unable to upload" +
                    " " +
                    this.smName +
                    " " +
                    "please contact System Administrator.";
                  if (
                    (this.componentName == "globalallsmupload" || this.componentName == "allsmupload") &&
                    res.message == message1
                  ) {
                    this.toast("error", this.uploadDataName, res.message, true);
                    //  this.toast('error', "", res.message, true);
                  } else {
                    this.setuppercentage.setUploadTrigger(this.componentName);
                    if (this.componentName == "updateFSTransactionCode") {
                      if (res.length > 0) {
                        this.toast('error', "", this.uploadDataName + " records could not be uploaded due to invalid data.", true);
                        this.downloadFailureTrades(res);
                        return
                      } else {
                        this.toast('success', "Success", this.uploadDataName + " Uploaded Successfully.", true);
                      }
                    }
                    if (this.uploadDataName == 'Investors') {
                      this.toast('success', "", "Investor(s)" + " uploaded successfully.", true);
                    } else {
                      this.toast('success', "Success", this.uploadDataName + " Uploaded Successfully.", true);
                    }
                  }
                }
                this.submitDisable = false;
              }
            },
            (cbe) => {
              this.formValid = true;
              this.translateService
                .get(this.uploadDataName)
                .subscribe((res) => {
                  this.uploadDataName = res;
                });
              if (this.uploadDataName == 'Investors') {
                this.toast('Investors not uploaded', "", "Investor records were not uploaded. Please check error file.", true);
              } else {
                this.toast('error', "", this.uploadDataName + " records could not be uploaded due to invalid data. Please check the downloaded excel file", true);
              }
              this.submitDisable = false;
            }
          );
        }
      } else {
        this.formValid = true;
        this.toast("error", "", "Please choose file to upload!", true);
      }
    } else {
      this.formValid = true;
      this.toast(
        "error",
        "Unsupported File Format!",
        "Please upload only in excel (.xlsx , .xls) format.",
        true
      );
    }
  }

  downloadFailureTrades = function (failedTradeAllocationConfig) {
    var failName = "failed " + this.uploadDataName + " (Upload errors).xlsx";
    var failUrl =
      'SELECT * INTO XLSX("' + failName + '",{headers:true}) FROM ?';
    alasql(failUrl, [failedTradeAllocationConfig]);
  };

  toast(severity, summary, detail, autoClear) {
    this.messageService.add({ sticky: true, severity: severity, summary: summary, detail: detail, });
    // if (autoClear) {
    //   setTimeout(() => {
    //     this.messageService.clear();
    //   }, 10000)
    // }
  }

  compareCategoryObjects(object1: any, object2: any) {
    if (object1 != null && object2 != null) {
      if (object1.id && object2.id) {
        return object1 && object2 && object1.id == object2.id;
      } else {
        return object1 && object2 && object1 == object2;
      }
    }
  }
  setOnChange(type) {
    this.smTypes.forEach((item, index) => {
      if (item.value == type) {
        this.smName = item.name;
      }
    });
  }

  onFileSelected(evt) {
    this.formValid = false;
    // const file: File = evt.target.files[0];
    this.fileData = evt.files[0];
    this.filename = this.fileData;
    this.dragDrop = false;
    //this.uploadedData = this.fileData
    this.uploadedData = this.fileData.name;
  }
  uploadZipFile() {
    this.formValid = true;
    var fileNameArr = this.filename.name.split(".");
    if (fileNameArr[fileNameArr.length - 1] == "zip") {
      const formData = new FormData();
      formData.append("files", this.fileData);
      this.gridService.uploadFile(formData).subscribe((response) => {
        this.formValid = false;
        var count = JSON.parse(response.message);
        let responseData = JSON.parse(response.message);
        this.successListCount = count.SuccessListCount;
        this.failureListCount = count.FailedListCount;
        this.tradeUploadId = count.tradeUploadId;
        localStorage.setItem("failureListCount", this.failureListCount);
        localStorage.setItem("tradeUploadId", this.tradeUploadId);
        if (this.failureListCount != 0) {
          var name = "Uploading aborted!";
          var message =
            this.failureListCount +
            " Trade aren't saved due to invalid data. Please check once.";
          this.toast("error", name, message, true);
          this.exportTradeUploadFailedList();
        }
        if (this.failureListCount == 0 && this.successListCount == 0) {
          var name = "Warning!";
          var message = "Uploaded file has no data.";
          this.toast("info", name, message, true);
        }

        if (responseData.message) {
          this.messageService.add({
            sticky: true,
            severity: "error",
            summary: "Error",
            detail: responseData.message
          });
        }
        //  this.toast('error', " Trade Upload", this.successListCount + " uploaded Successfully.", true);
        if (this.successListCount > 0) {
          this.selectPreviewAndAggregation = true;
          this.toast(
            "success",
            " Trade Upload",
            this.successListCount + " uploaded Successfully.",
            true
          );
        }
      });
    } else {
      this.toast(
        "error",
        "Unsupported File Format!",
        "Please upload only in csv (.zip) format",
        true
      );
    }
  }

  exportTradeUploadFailedList() {
    let tradeUploadId = localStorage.getItem("tradeUploadId");
    let url =
      apiConstants[this.componentName]["exportTradeUploadFailedURL"].url +
      "/" +
      this.failureListCount +
      "/" +
      tradeUploadId;
    // this.processScreenService.downloadCSV(
    //   url,
    //   "Trade Upload Failed.zip",
    //   "Trade Upload HV"
    // );
    this.commonService.GenericDownload(url,'Trade Upload Failed','Trade Upload HV')

  }
  convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result +=
          item[key] == null
            ? ""
            : Array.isArray(item[key])
              ? item[key].join().replace(",", "-")
              : typeof item[key] === "string"
                ? item[key].replace(",", "-")
                : item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  directUploadTradeEntry() {
    //let tradeUploadId = localStorage.getItem('tradeUploadId');
    if (this.isDoubleClick) return;
    this.isDoubleClick = true;
    let url = "tradeUpload/aggRecordsWithUpload/" + this.tradeUploadId;
    this.gridService.getGridData(
      url,
      (cbs) => {
        this.selectPreviewAndAggregation = false;
        this.isDoubleClick = false;
        this.messageService.add({
          severity: "success",
          summary: "Trade Upload ",
          detail:
            "Group Count" + cbs.groupCount + "\n Total Count" + cbs.totalCount
        });
      },
      (cbe) => {
        this.isDoubleClick = false;
      }
    );
  }

  fetchtradeAdvisor(): Observable<any> {
    return this.http.get<any>("tradeAdvisorV2/fetchAllByEnterprise");
  }
  dialogClose(evt) {
    if (this.componentName == "uploadWorkflow") {
      this.uploadResult.emit("reset");
    }
  }
}
