/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { environment } from '../../environments/environment'

const api_url = environment['api_url'];



export const apiConstants = {
    userSettingsList: ['view', 'ThemeSettings', 'create', 'languageSettings', 'settings', 'upload', 'ThemeSettingsUsr', 'NeverShowSettings'],

    "washSaleAdjustedOpenLot": {

        exportURL: { url: api_url + 'historicalWashsaleV2/downloadWashSaleHistorical', method: 'GET' },
    },
    "washSales": {
        exportURL: { url: api_url + 'washsaleV2/downloadExcel', method: 'GET' },
    },
    "refreshOpenTaxLots": {
        getdata: 'openTaxLots/refreshCurrentOpenTaxlotByGivenDate'

    },
    "perfTableReport": {
        exportURLMonthly: { url: api_url + 'performanceCompositeCustom/MonthlyPerformance' },
        IframeURL: { url: 'performanceCompositeCustom/MonthlyPerformance' },
        exportURLDaily: { url: api_url + 'performanceCompositeCustom/DailyPerformance' },
        IFrameURLDaily: { url: 'performanceCompositeCustom/DailyPerformance' },
    },
    "invoiceReport": {
        IframeURL: { url: 'invoiceReports/invoiceReport', method: 'GET' },
        exportURL: { url: api_url + 'invoiceReports/invoiceReport', method: 'GET' },
    },
    "loanReport": {
        exportURL: { url: 'loanReportController/loanReport' },
    },
    "perfTableAccount": {
        getData: { url: 'runProgramCompoFund/getDataFund/', method: 'GET' },
        exportURL: { url: api_url + 'runProgramCompoFund/DownloadExcelFund/', method: 'GET' },
    },
    "tradeBlotterFixedIncome": {

        exportURL: { url: api_url + 'omsEntryV2/excleDownload', method: 'GET' },
    },
    "positionReport": {
        exportURL: { url: 'positonReport/PositionReport', },
        exportCSVURL: { url: 'eodPosition/downloadCsv', },
    },
    "investmentReport": {
        exportURL: { url: 'investmentReport/InvestmentReport' }
    },
    "positionReportpost": {
        exportURL: { url: 'positonReport/PositionReport', },
    },
    "investmentReportpost": {
        exportURL: { url: 'investmentReport/InvestmentReports' }
    },
    "attributionReport": {
        exportURL: { url: api_url + 'attributionReportController/runAttributionReport', },
        exportExcelURL: { url: api_url + 'attributionReportController/downloadExcel/', },
    },
    "investorRollForwardReport": {
        exportURL: { url: api_url + 'investorRollForwardReportController/runAttributionReport', },
        exportExcelURL: { url: 'investorRollForwardReportController/downloadExcel/', },
        exportPdfURL: { url: 'investorRollForwardReportController/downloadPdf/', }
    },
    "positionReconMultiParty": {
        exportURL: { url: 'positionRecMultiPartyReport/positionRecMultiPartyReport', },
    },
    "assestAllocation": {
        exportURL: { url: 'assetAllocationDBController/assetAllocationDbReport', },
    },
    "investmentSummary": {
        exportURL: { url: api_url + 'investmentSummaryController/InvestmentSummaryReport', },
    },
    "dividend": {
        exportURL: { url: 'dividendReportController/', },
    },
    "pnlAttribution": {
        exportURL: { url: api_url + 'PNLContributionReport/pnlAttributionExcleDownload/', },
        pdfURL: { url: api_url + 'PNLContributionReport/pnlAttributionPdfDownload/', }
    },
    "pnlAttributionFeeder": {
        exportURL: { url: api_url + 'PnlAttributionFeeder/pnlAttributionFeederExcleDownload/', },
        pdfURL: { url: api_url + 'PnlAttributionFeeder/pnlAttributionFeederPdfDownload/', }
    },
    "navPackageReport": {
        pdfURL: { url: api_url + 'navReportingController/navReporting', }
    },
    "investorTransactionreport": {
        pdfURL: { url: api_url + 'InvestorTransactionController/InvestorTransactionReport', },
        exportURL: { url: 'InvestorTransactionController/InvestorTransactionReport' },
    },
    "investmentDetailsReport": {
        exportURL: { url: 'investmentDetails/excleDownload', },
        pdfURL: { url: 'investmentDetails/InvestmentDetails', }
    },
    "opentaxLotReport": {
        exportURL: { url: api_url + 'openTaxLotReport/openTaxDownload', },
    },
    "interestReport": {
        exportURL: { url: 'interestReport/InterestReport', },

    },
    "eodChecks": {
        exportURL: { url: api_url + 'eodChecksReport/downloadRecordsExcel/', },
        csvURL: { url: api_url + 'eodChecksReport/downloadRecords/', }
    },
    "eodCheckspost": {
        exportURL: { url: 'eodChecksReport/downloadRecordsExcels', },
        csvURL: { url: 'eodChecksReport/downloadRecord', },
        historicalURL: { url: 'eodChecksReport/downloadHistoricalRecord', }
    },
    "eodChecksAccount": {
        exportURL: { url: api_url + 'eodChecksReport/downloadExcel/', },
        csvURL: { url: api_url + 'eodChecksReport/downloadCsv/', }
    },
    "glAccountReport": {
        exportURL: {
            urldetail: api_url + 'generalLedger/excleDownloadGeneralLedgerCustomWithData',
            urlsummary: api_url + "generalLedger/excleDownloadGeneralLedgerCustomWithDataSummary",
            urlIFrameDetail: 'generalLedger/pdfDownloadGeneralLedgerCustomWithData',
            urlIFrameSummary: "generalLedger/pdfDownloadGeneralLedgerCustomWithDataSummary"
        },
    },
    "generalledgeraccount": {
        exportURL: {
            urldetail: api_url + 'accountGeneralLedger/excelAndPdfDownloadGeneralLedgerCustom/',
            ConsolidatedUrlDetail: api_url + 'generalLedgerAccountConsolidate/downloadGeneralLedgerAccountConsolidated',
        },
        IFrameURL: {
            urldetail: 'accountGeneralLedger/excelAndPdfDownloadGeneralLedgerCustom/',
            ConsolidatedUrlDetail: 'generalLedgerAccountConsolidate/downloadGeneralLedgerAccountConsolidated',
        }
    },
    "journalEntryRevised": {
        exportURL: { excelUrl: api_url + 'journalEntryRevisedV2/downloadExcelByJeId' },
    },
    "ytdDistributionBaseReport": {
        exportURL: { excelUrl: api_url + 'YtdDistributionBase/downloadYtdBaseDistributionReport' },
    },

    "realizedTaxLotReport": {
        exportURL: { excelUrl: api_url + 'realizedTaxLot/downloadRealizedTaxLotReport' },
    },

    "tradeReportTax": {
        exportURL: {
            excelUrl: api_url + 'tradeEntryReportControllerV2/tradeEntryTaxReport'
        },
    },
    "accountGeneralLedger": {
        exportURL: {
            url: 'accountGeneralLedger/excleDownloadWithoutTickerWithoutCustodianSummary?data=',
            excelUrl: api_url + 'accountGeneralLedger/excleDownloadWithoutTickerWithoutCustodianSummary?data='
        },
    },
    "performanceDetailed": {
        exportURL: { url: 'performanceAnalyticsSettings/performanceDetailed', },
    },
    "resetOpenTaxLots": {
        fetchTicket: "tradeEntryV2/getDitinctTicker?accountNo=",
        fetchBroker: 'brokerAccountV2/getMultipleAccountNumber?fundList',
        resetOpenInfo: 'openTaxLots/reset-open-tax-lots'
    },
    "tradeList": {
        pathName: 'trade',
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeList.JSON",
        gridDataUrl: "tradeEntryV2/records/",
        gridDeleteUrl: "tradeEntry/",
        moduleName: "tradeEntry",
        exportModuleName: "tradeEntry",
        filterUrl: { url: "tradeEntryV2/filterByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "exportController/download/tradeEntry", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/tradeEntry/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        newButtonFunction: "TRADEENTRY_VIEW",
        deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "/trades/trade-list/view",
        routeUrl: { create: "/trades/trade-list/create", upload: "/trades/trade-list/upload" },
        uploadDetails: {
            uploadUrl: "tradeEntryV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "trades", sampleName: "trade", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "TRADEENTRY_CREATE", uploadButtonFunction: "TRADEENTRY_CREATE",
            viewButtonFunction: "TRADEENTRY_VIEW", deleteButtonFunction: "TRADEENTRY_DELETE",
        }
    },
    "tradeArchiveList": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeArchiveList.JSON",
        gridDataUrl: "tradeEntryCopy/records/",
        gridDeleteUrl: "tradeEntry/",
        moduleName: "tradeArchiveList",
        filterUrl: { url: "tradeEntryCopy/filterByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "exportController/download/tradeEntryArchival", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/tradeEntryArchival/", isZoneName: false },
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "TRADEENTRY_ARCHIVE_LIST_VIEW,SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TRADEARCHIVELIST_VIEW,SIDEMENU_TRADES,SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME",
        }
    },
    "tradeUploadEntryHV": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeUploadEntryHV.JSON",
        gridDataUrl: "tradeUpload/entryRecords/",
        processUrl: "tradeUpload/aggRecords/",
        moduleName: "tradeUpload",
        filterUrl: { url: "tradeUpload/filterUploadEntryByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "tradeUpload/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/tradeEntryArchival/", isZoneName: false },
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "TRADE_UPLOAD_HV_VIEW",
        }
    },
    "tradeSucessListHV": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeSuccessListHV.JSON",
        gridDataUrl: "tradeUpload/getUserRecords/",
        processUrl: "tradeUpload/aggRecordsWithUpload/",
        moduleName: "tradeArchiveList",
        filterUrl: { url: "tradeUpload/filterByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "TRADEENTRY_ARCHIVE_LIST_VIEW,SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TRADEARCHIVELIST_VIEW,SIDEMENU_TRADES,SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME",
        }
    },
    "runViewOpenTaxlots": {
        headers: "assets/headers/trade-headers/runViewOpenTaxlots.JSON",
        gridDataUrl: "openTaxLotsV2/records/",
        gridDeleteUrl: "openTaxLots/",
        moduleName: "openTaxLotsMap",
        exportModuleName: "openTaxLotsMap",
        filterUrl: { url: "openTaxLotsV2/filterByKeys" },
        gridExportUrl: { url: api_url + 'exportController/download/openTaxLotsMap', method: 'GET' },
        // gridFilteredUrl: { url: api_url + "exportController/downloadFilter/openTaxLotsMap/", method: 'GET' },
        gridFilteredUrl: { url: "exportController/downloadFilter/openTaxLotsMapRapid/" },
    },
    "runTaxLotClosing":
    {
        headers: "assets/headers/trade-headers/runTaxLotclosing.JSON",
        fetchBroker: 'taxLotCloseV2/fetchAllDistinctCustodiansByEnterprise',
        gridDataUrl: 'taxLotCloseV2/getTaxLotTrades',
        exportURL: { url: api_url + 'taxLotCloseV2/downloadTaxlotClosing/' },

    },
    'createOpenTaxLots':
    {
        submitCreateOpenTax: 'openTaxLots/createOpenTaxLotsByGivenCriteria',
        tickerDataUrl: 'tradeEntryV2/getOpenLotTickersListByPrimaryAssetClass'
    },
    "orderList": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/orderList.JSON",
        gridDataUrl: "tradeEntryV2/orderRecords/",
        gridDeleteUrl: "tradeEntry/",
        moduleName: "orderList",
        newButtonFunction: "TRADEENTRY_VIEW",
        gridExportUrl: { url: "tradeEntryV2/downloadOrderList/", isZoneName: false },
        filterUrl: { url: "tradeEntry/filterByKeys", orderFilter: true },
        gridFilteredUrl: { url: "tradeEntryV2/downloadFilterOrderList/", isZoneName: false },
        viewUrl: "/input/create/trade-entry/view",
        routeUrl: { create: "/legacy/trade-list/view", upload: "/legacy/trade-list/upload" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "TRADEENTRY_VIEW", deleteButtonFunction: "TRADEENTRY_DELETE",
        }
    },
    "omslist": {
        "unallocated_order": {
            headers: "assets/headers/trade-headers/omsListData.JSON",
            gridDataUrl: "omsEntryV2/records/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeys" },
            gridDeleteUrl: "omsEntry/",
            moduleName: "oMSTrade",
            isOmsEdit: true,
            isOmsCancel: true,
            gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },
            routeUrl: { create: "/trades/oms/create" },
            viewUrl: "/trades/oms/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "",
                viewButtonFunction: "OMSENTRY_VIEW", deleteButtonFunction: "OMSENTRY_DELETE",
            }

        },
        "allocated_order": {
            headers: "assets/headers/trade-headers/omsListDataAllo.JSON",
            gridDataUrl: "omsEntryV2/records/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeys" },
            gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
            isomsApproveList: true,
            isOmsEdit: false,
            isOmsCancel: false,
            gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

        },
        "unapproved_order": {
            headers: "assets/headers/trade-headers/omsListDataAlloWithEmail.JSON",
            gridDataUrl: "omsEntryV2/records/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeys" },
            gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
            isomsApproveList: true,
            isOmsEdit: false,
            isOmsCancel: false,
            gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

        },
        "approved_order": {
            headers: "assets/headers/trade-headers/omsListDataApproved.JSON",
            gridDataUrl: "omsEntryV2/getApprovedOrderRecords/",
            filterUrl: { url: "omsEntryV2/filterByKeysApproved" },
            gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
            gridExportUrl: { url: "omsEntryV2/downloadAllAssetClass/", isZoneName: false },
            gridExportWellUrl: { url: "omsEntryV2/downloadMortgageDeal/MortgageDeal/", isZoneName: false },
            gridExportWellForgoUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },
            gridExportUSBankUrl: { url: "omsEntryV2/downloadUSExport/", isZoneName: false },
            isomsApproveList: true,
            isOmsCancel: false,
            omsCancelApprove: true,
            isomsEditList: true
        },
        "Real Time Positions": {
            headers: "assets/headers/trade-headers/omsListDataRealTimePos.JSON",
            gridDataUrl: "omsEntryV2/getBalanceWithAccountAndticker/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeysRealTimePosition" },
            gridExportUrl: { url: "omsEntryV2/downloadPosition", isZoneName: false },
            isomsApproveList: false,
            isOmsEdit: false,
            isOmsCancel: false
        }
    },
    "viewclosedtaxLots": {
        userCard: "tax_lot_closing",
        pathName: "view_closed_taxlots",
        headers: "assets/headers/trade-headers/viewclosedtaxLots.JSON",
        gridDataUrl: "taxLotCloseV2/records/",
        moduleName: "taxLotClose",
        exportModuleName: "taxLotClose",
        gridExportUrl: { url: "exportController/download/taxLotClose", isZoneName: false },
        filterUrl: { url: "taxLotCloseV2/filterByKeys" },
        // gridFilteredUrl: { url: "exportController/downloadFilter/taxLotClose/" }
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {

            deleteButtonFunction: "TAXLOTCLOSE_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TAXLOTSCLOSE_VIEW",
        }
    },
    "newIssues": {
        userCard: "other_card",
        headers: "assets/headers/trade-headers/newIssues.JSON",
        gridDataUrl: "newIssuesV2/records/",
        gridDeleteUrl: "newIssues/",
        moduleName: "newIssues",
        filterUrl: { url: "newIssuesV2/filterByKeys" },
        gridFilteredUrl: { url: "newIssuesV2/downloadFilterNewIssue/", isZoneName: false },
        gridExportUrl: { url: "newIssuesV2/downloadRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "", getCustomTypeUrl: "",
            getConfigUrl: "", name: "",
        },
        routeUrl: { create: "/trades/new-issues/create" },
        viewUrl: "/trades/new-issues/view",
        authFunctions: {
            newButtonFunction: "NEWISSUES_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "NEWISSUES_VIEW", deleteButtonFunction: "NEWISSUES_DELETE",
        }
    },
    "dailyMargin": {
        userCard: "other_card",
        pathName: 'daily_margin',
        headers: "assets/headers/trade-headers/dailyMargin.JSON",
        gridDataUrl: "dailyMarginV2/records/",
        gridDeleteUrl: "dailyMargin/",
        newButtonFunction: "DAILYMARGIN_VIEW",
        moduleName: "dailyMargin",
        gridExportUrl: { url: "dailyMarginV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dailyMarginV2/filterByKeys" },
        gridFilteredUrl: { url: "dailyMarginV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/trades/daily-margin/create", upload: "/trades/daily-margin/upload" },
        uploadDetails: {
            uploadUrl: "dailyMarginV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dailyMargin",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dailyMargin/", name: "daily_margin", sampleName: "dailymargin"
        },
        viewUrl: "/trades/daily-margin/view",
        authFunctions: {
            newButtonFunction: "NON_TRADING_TRANSACTION_CREATE", uploadButtonFunction: "NON_TRADING_TRANSACTION_CREATE",
            viewButtonFunction: "DAILYMARGIN_VIEW", deleteButtonFunction: "DAILYMARGIN_DELETE",
        }

    },
    "dealSummary": {
        pdfURL: { url: 'dealSummaryReport/dealSummaryReport', },
        csvURL: { url: 'dealSummaryReport/dealSummaryReport', },
        iFrameURL: { url: 'dealSummaryReport/dealSummaryReport', },
    },

    "navReporting": {
        pdfURL: { url: api_url + 'navReportingController/navReporting', }
    },
    "sourceDestTradeTransferTaxLotInfo": {
        userCard: "other_card",
        pathName: 'transfer_trade',
        headers: "assets/headers/trade-headers/trade-tranfer.JSON",
        gridDataUrl: "transferTradeV2/records/",
        gridDeleteUrl: "sourceDestTradeTransferTaxLotInfo/",
        moduleName: "sourceDestTradeTransferTaxLotInfo",
        gridExportUrl: { url: "transferTradeV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "transferTradeV2/filterByKeys" },
        gridFilteredUrl: { url: "transferTradeV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/trades/transfer-trade/create", upload: "/trades/transfertradeHistoryUpload" },
        uploadDetails: {
            uploadUrl: "transferTradeV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transfertradetaxlot",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transfertradetaxlot/", name: "transfer_trade", sampleName: "transfertradetaxlot"
        },
        viewUrl: "/trades/transfer-trade/view",
        authFunctions: {
            newButtonFunction: "TRADE_ENTRY_TRANSFER_TRADE_NEW_VERSION_CREATE", uploadButtonFunction: "TRADE_ENTRY_TRANSFER_TRADE_NEW_VERSION_UPLOAD",
            viewButtonFunction: "TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW", deleteButtonFunction: "TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW",//view button is not show only delete button is showing on grid
        }

    },
    "sequentialProcessStatusReview": {
        userCard: "other_card",
        headers: "assets/headers/trade-headers/sequentialProcessStatusReview.JSON",
        gridDataUrl: "sequentialProcessStatusReview/records/",
        gridDeleteUrl: "sourceDestTradeTransferTaxLotInfo/",
        moduleName: "sourceDestTradeTransferTaxLotInfo",
        gridExportUrl: { url: "sequentialProcessStatusReview/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sequentialProcessStatusReview/filterByKeys" },
        gridFilteredUrl: { url: "sequentialProcessStatusReview/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/trades/transfer-trade/create", upload: "/trades/transfertradeHistoryUpload" },
        uploadDetails: {
            uploadUrl: "transferTradeRawData/uploadTransferInput", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transfertradetaxlot",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transfertradetaxlot/", name: "transfer_trade", sampleName: "transfertradetaxlot"
        },
        viewUrl: "/trades/transfer-trade/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW,TRADEENTRY_TRANSFERTRADE_NEWVERSION,SIDEMENU_TRADES,SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TRANSFERTRADE_STATUS"
        }

    },
    "transferTradeRawDataHistory": {
        userCard: "other_card",
        pathName: 'transfer_trade',
        headers: "assets/headers/trade-headers/transferTradeRawDataHistory.JSON",
        gridDataUrl: "transferTradeRawData/records/",
        gridDeleteUrl: "transferTradeRawData/",
        moduleName: "transferTradeRowData",
        gridExportUrl: { url: "transferTradeV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "transferTradeRawData/filterByKeys" },
        gridFilteredUrl: { url: "transferTradeV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/trades/transfer-trade-raw-data-history/create", upload: "/trades/transfer-trade-raw-data-history/upload" },
        uploadDetails: {
            uploadUrl: "transferTradeRawData/uploadTransferInput", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transfertradetaxlot",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transfertradetaxlot/", name: "transfer_trade", sampleName: "transfertradetaxlot"
        },
        viewUrl: "/trades/transfer-trade/view",
        authFunctions: {
            deleteButtonFunction: "TRADEENTRY_TRANSFERTRADE_RAWDATA,SEAMLESS_INPUT_VIEW_TRADES_TRANSFERTRADE_RAWDATA",
        }
    },
    "tradeAllocationConfig": {
        pathName: 'trade_allocation',
        headers: "assets/headers/trade-headers/tradeAllocationConfig.JSON",
        gridDataUrl: "tradeAllocationConfigV2/records/",
        gridDeleteUrl: "tradeAllocationConfig/",
        newButtonFunction: "TRADEALLOCATIONCONFIG_VIEW",
        moduleName: "tradeAllocationConfig",
        fileName: "TradeAllocationConfigFiltered",
        filterUrl: { url: "tradeAllocationConfigV2/filterByKeys" },
        gridExportUrl: { url: "tradeAllocationConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "tradeAllocationConfigV2/downloadFilterRecords/", isZoneName: false, method: 'Post' },
        routeUrl: { create: "/setting/trade-allocation-config-entry/create", upload: "/setting/tradeAllocationConfigUpload" },
        uploadDetails: {
            uploadUrl: "tradeAllocationConfigV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "trade_allocation_config",
            resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        },
        viewUrl: "/setting/trade-allocation-config-entry/view",
        authFunctions: {
            newButtonFunction: "TRADEALLOCATIONCONFIG_CREATE", uploadButtonFunction: "TRADEALLOCATIONCONFIG_CREATE",
            viewButtonFunction: "TRADEALLOCATIONCONFIG_VIEW", deleteButtonFunction: "TRADEALLOCATIONCONFIG_DELETE",
        }
    },
    "ruleList": {
        headers: "assets/headers/trade-headers/ruleList.JSON",
        gridDataUrl: "ruleV2/records/",
        gridDeleteUrl: "rule/",
        moduleName: "rule",
        filterUrl: { url: "ruleV2/filterByKeys" },
        gridExportUrl: { url: "tradeEntryV2/downloadOrderList/", isZoneName: false },
        gridFilteredUrl: { url: "tradeEntryV2/downloadFilterOrderList/", isZoneName: false },
        viewUrl: "/trades/rule-list-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "historicalwashsaleopenLot": {
        userCard: "tax_process",
        pathName: 'historical_wash_sale_openlot',
        headers: "assets/headers/trade-headers/historicalwashsaleopenLot.JSON",
        gridDataUrl: "historicalWashsaleV2/records/",
        moduleName: "washSaleHistoricalOpenLot",
        gridExportUrl: { url: "historicalWashsaleV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "historicalWashsaleV2/filterByKeys" },
        gridFilteredUrl: { url: "historicalWashsaleV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/trades/trade-list/create", upload: "/trades/historical-wash/upload" },
        uploadDetails: {
            uploadUrl: "historicalWashsaleV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/historicalWashSaleOpenLotData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "historical_wash_sale_openlot", sampleName: "historicalwashsaleopenlotlist"
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "TRADEENTRY_HISTORICAL_WASHSALE_OPENLOT_UPLOAD",
            viewButtonFunction: "", deleteButtonFunction: "CTAMANAGER_DELETE",
        }
    },
    "smEquitiesList": {
        pathName: 'sm_equities',
        userCard: 'securities_master',
        headers: "assets/headers/portfolio-headers/smEquities.JSON",
        gridDataUrl: "sMEquitiesV2/records/",
        gridDeleteUrl: "sMEquities/",
        moduleName: "sMEquities",
        gridExportUrl: { url: "sMEquitiesV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesV2/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/sMEquities", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "sMEquities/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMEquitiesV2/downloadFilterSMEquitiesRecord/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMEquitiesV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquities",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquities/", name: "sm_equities", sampleName: "smequities", dataStringify: true
        },
        viewUrl: "/portfolio/sm-equities/view",
        routeUrl: { create: "/portfolio/sm-equities/create", upload: "/portfolio/sm-equities/upload" },
        authFunctions: {
            newButtonFunction: "SMEQUITIES_CREATE", uploadButtonFunction: "SMEQUITIES_CREATE",
            viewButtonFunction: "SMEQUITIES_VIEW", deleteButtonFunction: "SMEQUITIES_DELETE",
        }

    },
    "paymentPayableData": {
        headers: "assets/headers/accounting/paymentsPayableData.JSON",
        gridDataUrl: "rePaymentDetails/records/",
        moduleName: "paymentsPayableData",
        gridExportUrl: { url: "rePaymentDetails/downloadRecords/", isZoneName: false },
        filterUrl: { url: "rePaymentDetails/filterByKeys" },
        gridFilteredUrl: { url: "rePaymentDetails/downloadFilterRecords" },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "None",
        routeUrl: { create: "/accounting/payment/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_VIEW", deleteButtonFunction: "None",
        }
    },
    "paymentsComponent": {

        headers: "assets/headers/accounting/paymentsPayable.JSON",
        gridDataUrl: "rePaymentDetails/getBillRecords/",
        moduleName: "paymentsPayable",
        gridExportUrl: { url: "rePaymentDetails/downloadRecords/", isZoneName: false },
        filterUrl: { url: "rePaymentDetails/filterByBill" },
        newgridExportUrl: { url: "exportController/download/sMEquities", isZoneName: false },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_CREATE", deleteButtonFunction: "None",
        }
    },
    "smFixedIncomeList": {
        userCard: 'securities_master',
        pathName: 'sm_fixed_income',
        headers: "assets/headers/portfolio-headers/SmFixedIncome.JSON",
        gridDataUrl: "sMFixedIncomeV2/records/",
        gridDeleteUrl: "sMFixedIncome/",
        moduleName: "sMFixedIncome",
        gridExportUrl: { url: "sMFixedIncomeV2/exportSMFixedIncome/", isZoneName: false },
        filterUrl: { url: "sMFixedIncomeV2/filterByKeys" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMFixedIncome", isZoneName: false },
        filterDeleteUrl: { url: "sMFixedIncome/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMFixedIncomeV2/exportFilterSMFixedIncome/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFixedIncomeV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFixedIncome",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFixedIncome/", name: "sm_fixed_income", sampleName: "smfixedincome", dataStringify: true
        },
        viewUrl: "/portfolio/sm-fixed-income/view",
        deleteButtonFunction: "SMFIXEDINCOME_DELETE",
        routeUrl: { create: "/portfolio/sm-fixed-income/create", upload: "/portfolio/sm-fixed-income/upload" },
        authFunctions: {
            newButtonFunction: "SMFIXEDINCOME_CREATE", uploadButtonFunction: "SMFIXEDINCOME_CREATE",
            viewButtonFunction: "SMFIXEDINCOME_VIEW", deleteButtonFunction: "SMFIXEDINCOME_DELETE",
        }
    },
    "smFundLookthruList": {
        headers: "assets/headers/portfolio-headers/privateFundLookupThrough.JSON",
        gridDataUrl: "privateFunDLookThruInputV2/records/",
        gridDeleteUrl: "privateFunDLookThruInput/",
        gridExportUrl: { url: "privateFunDLookThruInputV2/export/", isZoneName: false },
        filterUrl: { url: "privateFunDLookThruInputV2/filterByKeys" },
        gridFilteredUrl: { url: "privateFunDLookThruInputV2/exportFiterByKey/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "privateFunDLookThruInputV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/privateFundLookThruInput",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/privateFundLookThruInput/", name: "Pvt Fund Lookthru", sampleName: "privatefundlookthruinput", dataStringify: true
        },
        viewUrl: "/portfolio/sm-fund-lookthru-entry/view",
        routeUrl: { create: "/portfolio/sm-fund-lookthru-entry/create", upload: "/portfolio/uploadSMFundLookthru" },
        authFunctions: {
            newButtonFunction: "LOOK_THRU_LIST_CREATE", uploadButtonFunction: "LOOK_THRU_LIST_CREATE",
            viewButtonFunction: "LOOK_THRU_LIST_VIEW", deleteButtonFunction: "LOOK_THRU_LIST_DELETE",
        }
    },
    "smFutureList": {
        userCard: 'securities_master',
        pathName: 'sm_futures',
        headers: "assets/headers/portfolio-headers/smFuturesListData.JSON",
        gridDataUrl: "sMFuturesV2/records/",
        gridDeleteUrl: "sMFutures/",
        moduleName: "sMFutures",
        gridExportUrl: { url: "sMFuturesV2/exportSMFutures/", isZoneName: false },
        filterUrl: { url: "sMFuturesV2/filterByKeys" },
        filterDeleteUrl: { url: "sMFutures/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMFuturesV2/exportFilterSMFutures/", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMFutures", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFuturesV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFutures",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFutures/", name: "sm_futures", sampleName: "smfutures", dataStringify: true
        },
        viewUrl: "/portfolio/sm-futures/view",
        deleteButtonFunction: "SMFUTURES_DELETE",
        routeUrl: { create: "/portfolio/sm-futures/create", upload: "/portfolio/sm-futures/upload" },
        authFunctions: {
            newButtonFunction: "SMFUTURES_CREATE", uploadButtonFunction: "SMFUTURES_CREATE",
            viewButtonFunction: "SMFUTURES_VIEW", deleteButtonFunction: "SMFUTURES_DELETE",
        }
    },
    "smFxForwardList": {
        userCard: 'securities_master',
        pathName: 'sm_fx_forward',
        headers: "assets/headers/portfolio-headers/smFxForward.JSON",
        gridDataUrl: "sMFXForwardV2/records/",
        gridDeleteUrl: "sMFXForward/",
        moduleName: "sMFXForward",
        gridExportUrl: { url: "sMFXForwardV2/exportSMFxForward/", isZoneName: false },
        filterUrl: { url: "sMFXForwardV2/filterByKeys" },
        filterDeleteUrl: { url: "sMFXForward/filterByKeysDelete" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMFXForward", isZoneName: false },
        gridFilteredUrl: { url: "sMFXForwardV2/exportFilterSMFxForward/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFXForwardV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFXForward",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFXForward/", name: "sm_fx_forward", sampleName: "smfxforward", dataStringify: true
        },
        viewUrl: "/portfolio/sm-fx-forward/view",
        routeUrl: { create: "/portfolio/sm-fx-forward/create", upload: "/portfolio/sm-fx-forward/upload" },
        authFunctions: {
            newButtonFunction: "SMFXFORWARD_CREATE", uploadButtonFunction: "SMFXFORWARD_CREATE",
            viewButtonFunction: "SMFXFORWARD_VIEW", deleteButtonFunction: "SMFXFORWARD_DELETE",
        }
    },
    "smOptionsList": {
        userCard: 'securities_master',
        pathName: 'sm_options',
        headers: "assets/headers/portfolio-headers/smEquitiesOption.JSON",
        gridDataUrl: "sMEquitiesOptionsV2/records/",
        gridDeleteUrl: "sMEquitiesOptions/",
        moduleName: "sMEquitiesOptions",
        gridExportUrl: { url: "sMEquitiesOptionsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesOptionsV2/filterByKeys" },
        filterDeleteUrl: { url: "sMEquitiesOptions/filterByKeysDelete" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMEquitiesOptions", isZoneName: false },
        gridFilteredUrl: { url: "sMEquitiesOptionsV2/downloadFilterSMEquitiesOptionRecord/", isZoneName: false },
        viewUrl: "/portfolio/sm-options/view",
        uploadDetails: {
            uploadUrl: "sMEquitiesOptionsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquitiesOption",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquitiesOption/", name: "sm_options", sampleName: "smequitiesoptions", dataStringify: true
        },
        routeUrl: { create: "/portfolio/sm-options/create", upload: "/portfolio/sm-options/upload" },
        authFunctions: {
            newButtonFunction: "SMEQUITIESOPTIONS_CREATE", uploadButtonFunction: "SMEQUITIESOPTIONS_CREATE",
            viewButtonFunction: "SMEQUITIESOPTIONS_VIEW", deleteButtonFunction: "SMEQUITIESOPTIONS_DELETE",
        }
    },
    "smPrivateFundList": {
        userCard: 'securities_master',
        pathName: 'sm_private_fund',
        headers: "assets/headers/portfolio-headers/smPrivateFund.JSON",
        gridDataUrl: "sMPrivateFundV2/records/",
        gridDeleteUrl: "sMPrivateFund/",
        moduleName: "sMPrivateFund",
        gridExportUrl: { url: "sMPrivateFundV2/exportSMPrivate/", isZoneName: false },
        filterUrl: { url: "sMPrivateFundV2/filterByKeys" },
        filterDeleteUrl: { url: "sMPrivateFund/filterByKeysDelete" },
        newgridExportUrl: { url: "exportController/download/sMPrivateFund", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        gridFilteredUrl: { url: "sMPrivateFundV2/exportFilterSMPrivate/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMPrivateFundV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMPrivateFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMPrivateFund/", name: "sm_private_fund", sampleName: "smprivatefund", dataStringify: true
        },
        viewUrl: "/portfolio/sm-private-fund/view",
        deleteButtonFunction: "SMPRIVATEFUND_DELETE",
        routeUrl: { create: "/portfolio/sm-private-fund/create", upload: "/portfolio/sm-private-fund/upload" },
        authFunctions: {
            newButtonFunction: "SMPRIVATEFUND_CREATE", uploadButtonFunction: "SMPRIVATEFUND_CREATE",
            viewButtonFunction: "SMPRIVATEFUND_VIEW", deleteButtonFunction: "SMPRIVATEFUND_DELETE",
        }
    },
    "investorgroup": {
        userCard: "investors",
        headers: "assets/headers/investor-headers/investorgroup.JSON",
        gridDataUrl: "InvestorGroupV2/records/",
        gridDeleteUrl: "InvestorGroup/",
        moduleName: "investorGroup",
        fileName: "InvestorGroupFiltered",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "InvestorGroupV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "InvestorGroupV2/filterByKeys" },
        gridFilteredUrl: { url: "InvestorGroupV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/investor/investor-group/view",
        routeUrl: { create: "/investor/investor-group/create", upload: "/portfolio/sm-equities/upload" },
        authFunctions: {
            newButtonFunction: "INVESTORGROUP_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "INVESTORGROUP_VIEW", deleteButtonFunction: "INVESTORGROUP_VIEW,SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORGROUP",
        }
    },
    "investorsaddresses": {
        userCard: "investors",
        headers: "assets/headers/investor-headers/investorsaddresses.JSON",
        gridDataUrl: "investorAddressV2/records/",
        gridDeleteUrl: "investorAddress/",
        newButtonFunction: "INVESTORADDRESS_CREATE",
        moduleName: "investorAddress",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "investorAddressV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "investorAddressV2/filterByKeys" },
        viewUrl: "/legacy/investor-addresses/view",
        routeUrl: { create: "/legacy/investor-addresses/create" },
        gridFilteredUrl: { url: "investorAddressV2/downloadFilterRecords/", isZoneName: false },
        authFunctions: {
            newButtonFunction: "INVESTORADDRESS_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "INVESTORADDRESS_VIEW", deleteButtonFunction: "INVESTORADDRESS_DELETE",
        }
    },
    "capitalActivities": {
        userCard: "investors",
        pathName: "capital_activity",
        headers: "assets/headers/investor-headers/capitalactivities.JSON",
        gridDataUrl: "capitalActivityV2/records/",
        gridDeleteUrl: "capitalActivity/",
        newButtonFunction: "CAPITALACTIVITY_VIEW",
        moduleName: "capitalActivity",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
        gridExportUrl: { url: "exportController/download/capitalActivity", isZoneName: false },
        filterUrl: { url: "capitalActivityV2/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/capitalActivity/", isZoneName: false },
        viewUrl: "/investor/capital-activities/view",
        routeUrl: { create: "/investor/capital-activities/create", upload: "/investor/capital-activities/upload" },
        uploadDetails: {
            uploadUrl: "capitalActivityV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/capitalActivity/", name: "capital_activity", sampleName: "capitalactivity", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "CAPITALACTIVITY_CREATE", uploadButtonFunction: "CAPITALACTIVITY_CREATE",
            viewButtonFunction: "CAPITALACTIVITY_VIEW", deleteButtonFunction: "CAPITALACTIVITY_DELETE",
        }
    },
    "investors": {
        pathName: 'investor',
        userCard: "investors",
        headers: "assets/headers/investor-headers/investors.JSON",
        gridDataUrl: "investorV2/records/",
        isExtraParams: true,
        extraParams: "/false",
        gridDeleteUrl: "tradeEntry/",
        newButtonFunction: "INVESTOR_VIEW",
        moduleName: "investor",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
        gridExportUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        filterUrl: { url: "investorV2/filterByKeys/", isZoneName: true },
        gridFilteredUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        viewUrl: "/investor/investors/view",
        routeUrl: { create: "/investor/investors/create", upload: "/investor/investors/upload" },
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "investordemo", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "INVESTOR_CREATE", uploadButtonFunction: "INVESTOR_CREATE",
            viewButtonFunction: "INVESTOR_VIEW", deleteButtonFunction: "INVESTOR_DELETE",
        }
    },
    "docManagement": {
        headers: "assets/headers/investor-headers/docManagement.JSON",
        gridDataUrl: "documentManagementV2/fetchAllRecords/",
        gridDeleteUrl: "documentManagement/",
        gridExportUrl: { url: "documentManagementV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "documentManagement/filterByKeys" },
        routeUrl: { create: "", upload: "/setup/docupload" },
        newButtonFunction: "None",
        gridDownloadUrl: "documentManagementV2/download",
        downloadButtonFunction: true,
        moduleName: 'documentManagement',
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "Doc Management", sampleName: "docManagement", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "SETTING_CONFIG_DOC_MANAGEMENT,DOCUMENTMANAGEMENT_VIEW,SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION,USER_CREATE",
        }
    },
    "docManagementForSetting": {
        headers: "assets/headers/investor-headers/docManagement.JSON",
        gridDataUrl: "documentManagementV2/fetchAllRecords/",
        gridDeleteUrl: "documentManagement/",
        gridExportUrl: { url: "documentManagementV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "documentManagement/filterByKeys" },
        routeUrl: { create: "", upload: "/setting/docupload" },
        newButtonFunction: "None",
        gridDownloadUrl: "documentManagementV2/download",
        // downloadButtonFunction: true,
        moduleName: 'documentManagement',
        uploadDetails: {
            uploadUrl: "investor/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "Doc Management", sampleName: "docManagement", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "SETTING_CONFIG_DOC_MANAGEMENT,DOCUMENTMANAGEMENT_VIEW,SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION,USER_CREATE",
        }
    },
    "casplit": {
        userCard: 'other_cor_actions',
        headers: "assets/headers/portfolio-headers/caSplit.JSON",
        gridDataUrl: "cASplitV2/records/",
        gridDeleteUrl: "cASplit/",
        moduleName: "cASplit",
        gridExportUrl: { url: "cASplitV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "cASplitV2/filterByKeys" },
        gridFilteredUrl: { url: "cASplitV2/downloadFilterCaSplit/" },
        routeUrl: { create: "/legacy/ca-split/create" },
        viewUrl: "/legacy/ca-split/view",
        authFunctions: {
            newButtonFunction: "CASPLIT_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "CASPLIT_VIEW", deleteButtonFunction: "CASPLIT_DELETE",
        }
    },
    "bondinterestadjustment": {
        userCard: 'bond',
        pathName: 'bond_interest_adjustment',
        headers: "assets/headers/portfolio-headers/bondIinterestAdjustment.JSON",
        gridDataUrl: "bondInterestAdjustmentV2/records/",
        gridDeleteUrl: "bondInterestAdjustment/",
        newButtonFunction: "BONDINTERESETADJUSTMENT_VIEW",
        moduleName: "bondInterestAdjustment",
        gridExportUrl: { url: "bondInterestAdjustmentV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "bondInterestAdjustmentV2/filterByKeys" },
        gridFilteredUrl: { url: "bondInterestAdjustmentV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "bondInterestAdjustmentV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/bondInterestAdjustment",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/bondInterestAdjustment/", name: "bond_interest_adjustment", sampleName: "bondinterestadjustmentaccsample"
        },
        viewUrl: "/portfolio/bond-interest-adjustment/view",
        routeUrl: { create: "/portfolio/bond-interest-adjustment/create", upload: "/portfolio/bond-interest-adjustment/upload" },
        authFunctions: {
            newButtonFunction: "BONDINTERESETADJUSTMENT_CREATE", uploadButtonFunction: "BONDINTERESETADJUSTMENT_CREATE",
            viewButtonFunction: "BONDINTERESETADJUSTMENT_VIEW", deleteButtonFunction: "BONDINTERESETADJUSTMENT_DELETE",
        }
    },
    "caspinoff": {
        userCard: 'other_cor_actions',
        headers: "assets/headers/portfolio-headers/caspinOff.JSON",
        gridDataUrl: "cASpinOffV2/records/",
        gridDeleteUrl: "cASpinOff/",
        moduleName: "cASpinOff",
        gridExportUrl: { url: "cASpinOffV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "cASpinOffV2/filterByKeys" },
        gridFilteredUrl: { url: "cASpinOffV2/downloadFilterCASpinOff/" },
        routeUrl: { create: "/legacy/ca-spin-off/create" },
        viewUrl: "/legacy/ca-spin-off/view",
        authFunctions: {
            newButtonFunction: "CASPINOFF_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "CASPINOFF_VIEW", deleteButtonFunction: "CASPINOFF_DELETE",
        }

    },
    "dividendaccrual": {
        userCard: 'dividend',
        pathName: 'dividend_accrual',
        headers: "assets/headers/portfolio-headers/dividendAccrual.JSON",
        gridDataUrl: "dividendV2/records/",
        gridDeleteUrl: "dividend/",
        newButtonFunction: "DIVIDEND_VIEW",
        moduleName: "dividend",
        gridExportUrl: { url: "dividendV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dividendV2/filterByKeys" },
        gridFilteredUrl: { url: "dividendV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "dividendV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dividendAccrual",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dividendAccrual/", name: "dividend_accrual", sampleName: "dividendaccrualdemo"
        },
        viewUrl: "/portfolio/dividend-accrual/view",
        routeUrl: { create: "/portfolio/dividend-accrual/create", upload: "/portfolio/dividend-accrual/upload" },
        authFunctions: {
            newButtonFunction: "DIVIDEND_CREATE", uploadButtonFunction: "DIVIDEND_CREATE",
            viewButtonFunction: "DIVIDEND_VIEW", deleteButtonFunction: "DIVIDEND_DELETE",
        }

    },
    "camerger": {
        userCard: 'other_cor_actions',
        headers: "assets/headers/portfolio-headers/caMerger.JSON",
        gridDataUrl: "cAMergerV2/records/",
        gridDeleteUrl: "cAMerger/",
        moduleName: "cAMerger",
        gridExportUrl: { url: "cAMergerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "cAMergerV2/filterByKeys" },
        gridFilteredUrl: { url: "cAMergerV2/downloadFilterCaSplit/" },
        routeUrl: { create: "/legacy/ca-merger/create" },
        viewUrl: "/legacy/ca-merger/view",
        authFunctions: {
            newButtonFunction: "CAMERGER_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "CAMERGER_VIEW", deleteButtonFunction: "CAMERGER_DELETE",
        }

    },
    "setupbondinterest": {
        userCard: 'bond',
        pathName: 'setup_bond_interest',
        headers: "assets/headers/portfolio-headers/setupbondinterest.JSON",
        gridDataUrl: "bondInterestAccrualV2/records/",
        gridDeleteUrl: "bondInterestAccrual/",
        newButtonFunction: "FUNDCLASS_VIEW",
        moduleName: "bondInterestAccrual",
        gridExportUrl: { url: "bondInterestAccrualV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "bondInterestAccrualV2/filterByKeys" },
        gridFilteredUrl: { url: "bondInterestAccrualV2/downloadFilterRecords/" },

        uploadDetails: {
            uploadUrl: "bondInterestAccrualV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/BondInterestAccrual",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/BondInterestAccrual/", name: "setup_bond_interest", sampleName: "bondinterestaccsample"
        },
        routeUrl: { create: "/portfolio/setup-bond-interest/create", upload: "/portfolio/setup-bond-interest/upload" },
        viewUrl: "/portfolio/setup-bond-interest/view",
        authFunctions: {
            newButtonFunction: "BONDINTEREST_CREATE", uploadButtonFunction: "BONDINTEREST_CREATE",
            viewButtonFunction: "BONDINTEREST_VIEW", deleteButtonFunction: "FUNDCLASS_DELETE",
        }
    },
    "caspecialdividend": {
        userCard: 'other_cor_actions',
        headers: "assets/headers/portfolio-headers/caspecialDividend.JSON",
        gridDataUrl: "cASpecialDividendV2/records/",
        gridDeleteUrl: "cASpecialDividend/",
        moduleName: "cASpecialDividend",
        gridExportUrl: { url: "cASpecialDividendV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "cASpecialDividendV2/filterByKeys" },
        gridFilteredUrl: { url: "cASpecialDividendV2/downloadFilterCASpecialDividend/" },
        routeUrl: { create: "/legacy/ca-special-dividend/create" },
        viewUrl: "/legacy/ca-special-dividend/view",
        authFunctions: {
            newButtonFunction: "CASPECIALDIVIDEND_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "CASPECIALDIVIDEND_VIEW", deleteButtonFunction: "CASPECIALDIVIDEND_DELETE",
        }

    },
    "itdSubscriptionAndRedemption": {
        userCard: "investors",
        pathName: "itd_subscription_and_redemption",
        headers: "assets/headers/investor-headers/itdSubscriptionandRedemption.JSON",
        gridDataUrl: "ITDSubscriptionandRedemptionV2/records/",
        gridDeleteUrl: "ITDSubscriptionandRedemption/",
        moduleName: "ItdSubscriptionAndRedemption",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ITDSubscriptionandRedemption",
        gridExportUrl: { url: "exportController/download/ItdSubscriptionAndRedemption", isZoneName: false },
        filterUrl: { url: "ITDSubscriptionandRedemptionV2/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/ItdSubscriptionAndRedemption/", isZoneName: false },
        routeUrl: { upload: "/investor/itd-subscription-and-redemption/upload" },
        uploadDetails: {
            uploadUrl: "ITDSubscriptionandRedemptionV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ITDSubscriptionandRedemption",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ITDSubscriptionandRedemption/", name: "itd_subscription_and_redemption", sampleName: "itdsubscriptionandredemption", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_INVESTORS_ITDSUBCRIPTIONANDREDEMPTION_UPLOAD",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INVESTORS_ITDSUBCRIPTIONANDREDEMPTION",
        }
    },
    "dividendadjuctment": {
        userCard: 'dividend',
        pathName: 'dividend_adjustment',
        headers: "assets/headers/portfolio-headers/dividendAdjustment.JSON",
        gridDataUrl: "dividendAdjustmentV2/records/",
        gridDeleteUrl: "dividendAdjustment/",
        newButtonFunction: "PORTFOLIO_CORPORATEACTION_DIVIDENDADJUSTMENT_VIEW",
        moduleName: "dividendAdjustment",
        gridExportUrl: { url: "dividendAdjustmentV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dividendAdjustmentV2/filterByKeys" },
        gridFilteredUrl: { url: "dividendAdjustmentV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "dividendAdjustmentV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dividendAdjustment",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dividendAdjustment/", name: "dividend_adjustment", sampleName: "dividendadjustmentccsample"
        },
        routeUrl: { create: "/portfolio/dividend-adjustment/create", upload: "/portfolio/dividend-adjustment/upload" },
        viewUrl: "/portfolio/dividend-adjustment/view",
        authFunctions: {
            newButtonFunction: "PORTFOLIO_CORPORATEACTION_DIVIDENDADJUSTMENT_CREATE", uploadButtonFunction: "PORTFOLIO_CORPORATEACTION_DIVIDENDADJUSTMENT_CREATE",
            viewButtonFunction: "PORTFOLIO_CORPORATEACTION_DIVIDENDADJUSTMENT_VIEW", deleteButtonFunction: "PORTFOLIO_CORPORATEACTION_DIVIDENDADJUSTMENT_DELETE",
        }
    },
    "seamlessmarketdata": {
        userCard: 'market_data',
        pathName: 'seamless_market_data',
        headers: "assets/headers/portfolio-headers/seamlessmarketData.JSON",
        gridDataUrl: "seamlessMarketDataV2/records/",
        gridDeleteUrl: "seamlessMarketData/",
        moduleName: "seamlessMarketData",
        gridExportUrl: { url: "seamlessMarketDataV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "seamlessMarketDataV2/filterByKeys" },
        gridFilteredUrl: { url: "seamlessMarketDataV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "seamlessMarketDataV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/seamlessMarketData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/seamlessMarketData/", name: "seamless_market_data", sampleName: "seamlessmarketdata"
        },
        deleteButtonFunction: "SEAMLESS_MARKET_DATA_DELETE",
        newButtonFunction: "None",
        routeUrl: { create: "/trades/trade-list/create", upload: "/portfolio/seamless-market-data/upload" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "PORTFOLIO_SEAMLESS_MARKET_DATA_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "PORTFOLIO_SEAMLESS_MARKET_DATA_DELETE",
        }
    },
    "alertThreshold": {
        headers: "assets/headers/portfolio-headers/alertThreshold.JSON",
        gridDataUrl: "alertThresholdV2/records/",
        gridDeleteUrl: "alertThreshold/delete",
        moduleName: "thresholdRule",
        gridExportUrl: { url: "alertThresholdV2/AlertThresholdlAll/", isZoneName: false },
        filterUrl: { url: "alertThresholdV2/filterByKeys" },
        gridFilteredUrl: { url: "alertThresholdV2/filterAlertThreshold/" },
        routeUrl: { create: "/portfolio/alert-thhreshold-entry/create", upload: "/portfolio/seamless-market-data/upload" },
        viewUrl: "/portfolio/alert-threshold/view",
        alertThresholdrun: true,
        authFunctions: {
            deleteButtonFunction: "SIDEMENU_PORTFOLIO_RISK,ALERTTHRESHOLD_VIEW",
        }
    },
    "alertThresholdLog": {
        headers: "assets/headers/portfolio-headers/alertThresholdLog.JSON",
        gridDataUrl: "alertThresholdV2/getRuleExecutionLogs/",
        gridDeleteUrl: "seamlessalertThreshold/",
        gridExportUrl: { url: "alertThresholdV2/generateAlertThresholdLogsCSV/", isZoneName: false },
        filterUrl: { url: "alertThresholdV2/logsFilterByKey" },
        gridFilteredUrl: { url: "alertThresholdV2/generateFilteredAlertThresholdLogsCSV" },
        routeUrl: { create: "/trades/trade-list/create", upload: "/portfolio/seamless-market-data/upload" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "marketdata": {
        userCard: 'market_data',
        pathName: 'market_data',
        headers: "assets/headers/portfolio-headers/marketData.JSON",
        gridDataUrl: "marketDataV2/records/",
        gridDeleteUrl: "marketData/",
        newButtonFunction: "MARKETDATA_VIEW",
        moduleName: "marketData",
        gridExportUrl: { url: "exportController/download/marketData", isZoneName: false },
        filterUrl: { url: "marketDataV2/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/marketData/" },

        uploadDetails: {
            uploadUrl: "marketDataV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/marketdata",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/marketdata/", name: "market_data", sampleName: "marketData"
        },
        routeUrl: { create: "/portfolio/market-data/create", upload: "/portfolio/market-data/upload" },
        viewUrl: '/portfolio/market-data/view',
        authFunctions: {
            newButtonFunction: "MARKETDATA_CREATE", uploadButtonFunction: "MARKETDATA_CREATE",
            viewButtonFunction: "MARKETDATA_VIEW", deleteButtonFunction: "MARKETDATA_DELETE",
        }
    },
    "marketdatavar": {
        headers: "assets/headers/portfolio-headers/marketdataVar.JSON",
        gridDataUrl: "marketDataVarV2/records/",
        gridDeleteUrl: "marketDataVar/",
        moduleName: "marketDataVar",
        gridExportUrl: { url: "marketDataVarV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "marketDataVarV2/filterByKeys" },
        gridFilteredUrl: { url: "marketDataVarV2/downloadFilterRecords/" },
        viewUrl: "/portfolio/market-data-var-entry/view",

        uploadDetails: {
            uploadUrl: "marketDataVarV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/marketdatavar",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/marketdatavar/", name: "Market Data Var", sampleName: "marketdatavar"
        },
        routeUrl: { create: "/portfolio/market-data-var-entry/create", upload: "/portfolio/uploadMarketDataVar" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "exchangerate": {
        userCard: 'market_data',
        pathName: 'exchange_rate',
        headers: "assets/headers/portfolio-headers/exchangeRate.JSON",
        gridDataUrl: "exchangeRateV2/records/",
        gridDeleteUrl: "exchangeRate/",
        moduleName: "exchangeRate",
        gridExportUrl: { url: "exportController/download/exchangeRate", isZoneName: false },
        filterUrl: { url: "exchangeRateV2/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/exchangeRate/" },
        uploadDetails: {
            uploadUrl: "exchangeRateV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/exchangeRate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/exchangeRate/", name: "exchange_rate", sampleName: "exchangeratedemo"
        },
        routeUrl: { create: "/portfolio/exchange-rate/create", upload: "/portfolio/exchange-rate/upload" },
        viewUrl: "/portfolio/exchange-rate/view",
        authFunctions: {
            newButtonFunction: "EXCHANGERATE_CREATE", uploadButtonFunction: "EXCHANGERATE_CREATE",
            viewButtonFunction: "EXCHANGERATE_VIEW", deleteButtonFunction: "EXCHANGERATE_DELETE",
        }
    },
    "benchmark": {
        userCard: 'market_data',
        pathName: 'benchmark',
        headers: "assets/headers/portfolio-headers/benchMark.JSON",
        gridDataUrl: "benchMarkListV2/records/",
        gridDeleteUrl: "benchMarkList/",
        moduleName: "benchmark",
        gridExportUrl: { url: "benchMarkListV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "benchMarkListV2/filterByKeys" },
        gridFilteredUrl: { url: "benchMarkListV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "benchMarkListV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/benchmark",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/benchmark/", name: "benchmark", sampleName: "benchmarkdemo"
        },
        routeUrl: { create: "/portfolio/benchmark/create", upload: "/portfolio/benchmark/upload" },
        viewUrl: "/portfolio/benchmark/view",
        authFunctions: {
            newButtonFunction: "BENCHMARK_CREATE", uploadButtonFunction: "BENCHMARK_CREATE",
            viewButtonFunction: "BENCHMARK_VIEW", deleteButtonFunction: "BENCHMARK_DELETE",
        }
    },
    "cryptocurrency": {
        headers: "assets/headers/portfolio-headers/cryptoCurrency.JSON",
        gridDataUrl: "bitcoinPriceDataV2/records/",
        gridDeleteUrl: "bitcoinPriceData/",
        moduleName: "bitcoinPriceData",
        gridExportUrl: { url: "bitcoinPriceDataV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "bitcoinPriceData/filterByKeys" },
        gridFilteredUrl: { url: "bitcoinPriceDataV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "CRYPTOCURRENCY_VIEW",
        }

    },
    "viewtotalgl": {
        userCard: 'pl_and_positions',
        headers: "assets/headers/portfolio-headers/viewtotalGl.JSON",
        gridDataUrl: "totalGainLossReportV2/allRecords/",
        gridDeleteUrl: "totalGainLossReport/",
        moduleName: "totalGainLossReports",
        exportModuleName: "totalGainLossReports",
        gridExportUrl: { url: "exportController/download/totalGainLossReports", isZoneName: false },
        filterUrl: { url: "totalGainLossReportV2/filterByKeys" },
        // gridFilteredUrl: { url: "exportController/downloadFilter/totalGainLossReports/" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "TOTAL_GAIN_LOSS_REPORTS_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_PORTFOLIO_TOTALGAINLOSS_REPORTS_VIEW",
        }

    },
    "ufdata": {
        headers: "assets/headers/portfolio-headers/ufData.JSON",
        gridDataUrl: "ufDataV2/records/",
        gridDeleteUrl: "ufData/",
        gridExportUrl: { url: "ufDataV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "ufDataV2/filterByKeys" },
        gridFilteredUrl: { url: "ufDataV2/downloadFilterRecords/" },

    },
    "creditratings": {
        headers: "assets/headers/portfolio-headers/creditRatings.JSON",
        gridDataUrl: "creditRatingV2/records/",
        gridDeleteUrl: "creditRating/",
        gridExportUrl: { url: "creditRatingV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "creditRatingV2/filterByKeys" },
        gridFilteredUrl: { url: "creditRatingV2/downloadFilterCreditRating/" },

    },
    "subsectors": {
        headers: "assets/headers/portfolio-headers/subSectors.JSON",
        gridDataUrl: "subSectorV2/records/",
        gridDeleteUrl: "subSector/",
        moduleName: "subSector",
        gridExportUrl: { url: "subSectorV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "subSectorV2/filterByKeys" },
        gridFilteredUrl: { url: "subSectorV2/downloadFilterRecords/" },

        uploadDetails: {
            uploadUrl: "subSectorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/subSector",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/subSector/", name: "Sub Sector", sampleName: "subsector"
        },
        routeUrl: { create: "/portfolio/sub-sectors/create", upload: "/portfolio/uploadSubsectors" },
        viewUrl: '/portfolio/sub-sectors/view',
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "rapidSubSectors": {
        headers: "assets/headers/portfolio-headers/subSectors.JSON",
        gridDataUrl: "subSectorV2/records/",
        gridDeleteUrl: "subSector/",
        moduleName: "subSector",
        gridExportUrl: { url: "subSectorV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "subSectorV2/filterByKeys" },
        gridFilteredUrl: { url: "subSectorV2/downloadFilterRecords/" },
        uploadRedirectionUrl: "/input/view/sub-sectors",
        uploadDetails: {
            uploadUrl: "subSectorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/subSector",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/subSector/", name: "sub_sectors", sampleName: "subsector"
        },
        routeUrl: { create: "/input/create/sub-sectors-entry/create", upload: "/input/upload/sub-sectors-upload" },
        viewUrl: '/input/create/sub-sectors-entry/view',
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_SUB_SECTORS", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_SUB_SECTORS",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_SUB_SECTORS", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_SUB_SECTORS_DELETE",
        }
    },
    "cryptoMarketData": {
        userCard: 'market_data',
        pathName: 'crypto_market_data',
        exportURL: { url: api_url + 'cryptoMarketData/downloadData/' },
        exportTickerURL: { url: api_url + 'cryptoMarketData/downloadTicker/' },
        uploadDetails: {
            uploadUrl: "cryptoMarketDataV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/cryptoMarketData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/cryptoMarketData/", name: "crypto_market_data", sampleName: "cryptoMarketData"
        },
        //   routeUrl: { create: "/portfolio/sub-sectors/create", upload: "/portfolio/uploadSubsectors" },
        //  viewUrl: '/portfolio/sub-sectors/view'
    },
    "targetallocation": {
        headers: "assets/headers/portfolio-headers/targetAllocation.JSON",
        gridDataUrl: "targetSectorV2/records/",
        gridDeleteUrl: "targetSector/",
        gridExportUrl: { url: "targetSectorV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "targetSectorV2/filterByKeys" },
        gridFilteredUrl: { url: "targetSectorV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "targetSectorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/targetAllocation",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/targetAllocation/", name: "Target Allocation", sampleName: "targetallocationdemo"
        },
        routeUrl: { create: "/trades/trade-list/create", upload: "/portfolio/uploadTargetAllocation" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "restrictedstock": {
        headers: "assets/headers/portfolio-headers/restrictedStock.JSON",
        gridDataUrl: "restrictedStockV2/records/",
        gridDeleteUrl: "restrictedStock/",
        gridExportUrl: { url: "restrictedStockV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "restrictedStockV2/filterByKeys" },
        gridFilteredUrl: { url: "restrictedStockV2/downloadFilterRestrictedStock/" },

    },
    "benchmarkirr": {
        userCard: 'irr',
        pathName: 'benchmark_irr',
        headers: "assets/headers/portfolio-headers/benchmarkIrr.JSON",
        gridDataUrl: "irrWorkingV2/records/",
        gridDeleteUrl: "irrWorking/",
        moduleName: "xirrBenchMarkWorking",
        newButtonFunction: 'null',
        gridExportUrl: { url: "irrWorkingV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "irrWorkingV2/filterByKeys" },
        gridFilteredUrl: { url: "irrWorkingV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "irrWorkingV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/targetAllocation",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/targetAllocation/", name: "benchmark_irr", sampleName: "irrworkingdatademo"
        },
        // uploadDetails: { uploadUrl: "irrWorking/upload", name: "Benchmark Irr", sampleName: "irrworkingdatademo" },
        routeUrl: { create: "/trades/trade-list/create", upload: "/portfolio/bench-mark-irr/upload" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "BENCHMARK_CREATE",
            viewButtonFunction: "None", deleteButtonFunction: "BENCHMARK_DELETE",
        }

    },
    "viewjesummary": {
        headers: "assets/headers/accounting/viewJeSummary.JSON",
        gridDataUrl: "jesummaryreportV2/WithoutTickerSymbol/",
        filterUrl: { url: "jesummaryreportV2/filterBywithoutTicker?" },
        method: 'post',
        newButtonFunction: 'None',
        deleteButtonFunction: 'None',
        gridExportUrl: { url: "exportController/download/jeSummary", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/jeSummary/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "none",
        }
    },
    "postjetrading": {
        getdata: 'openTaxLots/refreshCurrentOpenTaxlotByGivenDate'
    },
    "comprehensivePL": {
        exportURL: { url: api_url + 'PNLContributionReport/comprehensivePNLExcelDownload/', method: 'GET' }
    },
    "pnlContributionror": {
        exportURL: { url: api_url + 'PNLContributionReport/excleDownload/', method: 'GET' }
    },
    "operatingExpenses": {
        IframeUrl: { url: 'operatingExpensesCntrlr/OperatingExpenses', method: 'GET' },
        exportURL: { url: api_url + 'operatingExpensesCntrlr/OperatingExpenses', method: 'GET' }
    },
    "accountoperatingExpenses": {
        exportURL: { url: api_url + 'accountoperatingExpensesCntrlr/OperatingExpenses', method: 'POST' },
        iFrameURL: { url: 'accountoperatingExpensesCntrlr/OperatingExpenses', method: 'POST' }

    },
    "returnReport": {
        exportURL: { url: api_url + 'dailyRORReportController/dailyRORReportReportDownload', method: 'GET' }
    },
    "commissionReport": {
        exportURL: { url: api_url + 'CommissionReportController/CommissionReport', method: 'GET' }
    },
    "journalentry": {
        headers: "assets/headers/accounting/journalEntry.JSON",
        gridDataUrl: "journalEntryRevisedV2/records/",
        filterUrl: { url: "journalEntryRevisedV2/filterByKeys" },
        newButtonFunction: 'JOURNALENTRY_CREATE',
        deleteButtonFunction: 'JOURNALENTRY_DELETE',
        gridDeleteUrl: "journalEntryRevisedV2/",
        moduleName: "journalEntryRevised",
        exportModuleName: "journalEntryRevised",
        routeUrl: { create: "/accounting/journalEntry-entry/create", upload: '/accounting/journalEntryUpload' },
        uploadDetails: {
            uploadUrl: "journalEntryRevisedV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/journalEntry",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/journalEntry/", name: "journal_entry", sampleName: "journalentrydemo", dataStringify: true
        },
        viewUrl: "/accounting/journalEntry-entry/view",
        gridExportUrl: { url: "exportController/downloadFilter/journalEntryRevised/", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/journalEntryRevised/" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "JOURNALENTRY_CREATE", uploadButtonFunction: "JOURNALENTRY_CREATE",
            viewButtonFunction: "JOURNALENTRY_VIEW", deleteButtonFunction: "JOURNALENTRY_DELETE",
        }

    },
    "sidepocketcreation": {
        headers: 'assets/headers/accounting/sidepocketcreation.JSON',
        gridDataUrl: "sidePocketConfigV2/records/",
        filterUrl: { url: "sidePocketConfigV2/filterByKeys" },
        //newButtonFunction: 'SIDE_POCKET_CONFIGURATION_CREATE',
        //deleteButtonFunction: 'SIDE_POCKET_CONFIGURATION_DELETE',
        moduleName: "sidePocketConfiguration",
        gridExportUrl: { url: "sidePocketConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "sidePocketConfigV2/downloadFilterRecords/" },
        routeUrl: { create: "/accounting/sidepocketcreation-entry/create", upload: '/accounting/sidePocketCreationUpload' },
        viewUrl: '/accounting/sidepocketcreation-entry/view',
        uploadDetails: {
            uploadUrl: "sidePocketConfigV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sidePocketConfig",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sidePocketConfig/", name: "side_pocket_creation", sampleName: "sidepocketcreation", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "SIDE_POCKET_CONFIGURATION_CREATE", uploadButtonFunction: "SIDE_POCKET_CONFIGURATION_CREATE",
            viewButtonFunction: "SIDE_POCKET_CONFIGURATION_VIEW", deleteButtonFunction: "SIDE_POCKET_CONFIGURATION_DELETE",
        }

    },
    "sidepockettotalincome": {
        headers: 'assets/headers/accounting/sidepockettotalincome.JSON',
        gridDataUrl: "sidePocketTotalIncomeV2/records/",
        filterUrl: { url: "sidePocketTotalIncomeV2/filterByKeys" },
        moduleName: "sidePocketTotalIncome",
        gridExportUrl: { url: "sidePocketTotalIncome/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "sidePocketTotalIncome/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "SIDE_POCKET_TOTAL_INCOME_VIEW",
        }


    },
    "sidepocketredemption": {
        headers: 'assets/headers/accounting/sidepocketredemption.JSON',
        gridDataUrl: "sidePocketRedemptionV2/records/",
        filterUrl: { url: "sidePocketRedemptionV2/filterByKeys" },
        //newButtonFunction: 'SIDE_POCKET_REDEMPTION_CREATE',
        //deleteButtonFunction: 'SIDE_POCKET_REDEMPTION_DELETE',
        moduleName: "sidePocketRedemption",
        routeUrl: { create: "/accounting/sidepocketredemption-entry/create" },
        viewUrl: '/accounting/sidepocketredemption-entry/view',
        gridExportUrl: { url: "sidePocketRedemptionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "sidePocketRedemptionV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "SIDE_POCKET_REDEMPTION_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "SIDE_POCKET_REDEMPTION_VIEW", deleteButtonFunction: "NON_TRADING_TRANSACTION_DELETE",
        }


    },
    "assetBasedFeeList": {
        headers: 'assets/headers/accounting/assetBasedFeeList.JSON',
        gridDataUrl: 'assetBaseFeeV2/records/',
        moduleName: "assetBaseFee",
        filterUrl: { url: "assetBaseFeeV2/filterByKeys" },
        gridExportUrl: { url: "assetBaseFeeV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "assetBaseFeeV2/downloadFilterData/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "ASSETS_BASE_FEE_CALCULATION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_ASSETBASEDFEE_CALCULATION_VIEW",
        }
    },
    "assetBasedFeeCalculation": {
        headers: 'assets/headers/accounting/assetBasedFeeCalculation.JSON',
        gridDataUrl: 'assetBaseFeeV2/calculaterecords/',
        moduleName: "assetBaseFeeCalculation",
        filterUrl: { url: "assetBaseFeeV2/calculationfilterByKeys" },
        gridExportUrl: { url: "assetBaseFeeV2/downloadCalculateRecords/", isZoneName: false },
        gridFilteredUrl: { url: "assetBaseFeeV2/downloadFilterCalculate/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "ASSETS_BASE_FEE_CALCULATION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_ASSETBASEDFEE_CALCULATION_VIEW",
        }
    },
    "viewPerfBasedFees": {
        headers: 'assets/headers/accounting/viewPerfBasedFees.JSON',
        gridDataUrl: 'performanceFeeV2/records/',
        moduleName: "performanceFee",
        filterUrl: { url: "performanceFeeV2/filterByKeys" },
        gridExportUrl: { url: "performanceFeeV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "performanceFeeV2/downloadFilterData/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "PERFORMANCE_BASED_FEE_CALCULATION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_PERFORMANCE_FEE_CALCULATION_VIEW",
        }
    },
    'externalROR': {
        headers: 'assets/headers/accounting/externalROR.JSON',
        gridDataUrl: 'externalRorV2/records/',
        gridDeleteUrl: 'externalRor/',
        moduleName: "externalROR",
        filterUrl: { url: "externalRorV2/filterByKeys" },
        routeUrl: { upload: "/accounting/externalRorUpload", },
        uploadDetails: {
            uploadUrl: "externalRorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/externalROR",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/externalROR/", name: "external_ror", sampleName: "externalrordemo", dataStringify: false
        },
        gridExportUrl: { url: "externalRorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "externalRorV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "EXTERNAL_ROR_CREATE",
            viewButtonFunction: "none", deleteButtonFunction: "EXTERNAL_ROR_DELETE",
        }
    },
    'midPeriodReset': {
        headers: 'assets/headers/accounting/midPeriodReset.JSON',
        gridDataUrl: 'midPeriodResetV2/records/',
        gridDeleteUrl: "midPeriodReset/",
        moduleName: "midPeriod",
        newButtonFunction: "FUNDCLASS_CREATE",
        deleteButtonFunction: "FUNDCLASS_DELETE",
        filterUrl: { url: "midPeriodResetV2/filterByKeys" },
        viewUrl: '/accounting/midPeriodReset-entry/view',
        routeUrl: { create: "/accounting/midPeriodReset-entry/create", },
        gridExportUrl: { url: "midPeriodResetV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "midPeriodResetV2/downloadFilterMidPeriod/" },
        authFunctions: {
            newButtonFunction: "FUNDCLASS_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "MID_PERIOD_RESET_VIEW", deleteButtonFunction: "FUNDCLASS_DELETE",
        }
    },
    'viewDistribution': {
        headers: 'assets/headers/accounting/viewDistribution.JSON',
        gridDataUrl: 'distributionV2/records/',
        gridDeleteUrl: 'distribution/',
        moduleName: "distribution",
        filterUrl: { url: "distributionV2/filterByKeys" },
        newButtonFunction: 'None',
        gridExportUrl: { url: "distributionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "distributionV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "DISTRIBUTION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_DISTRIBUTION_VIEW",
        }
    },
    'viewCapitalCall': {
        headers: 'assets/headers/accounting/viewCapitalCall.JSON',
        gridDataUrl: 'capitalCallV2/records/',
        gridDeleteUrl: 'capitalCall/',
        moduleName: "capitalCall",
        filterUrl: { url: "capitalCallV2/filterByKeys" },
        newButtonFunction: 'None',
        gridExportUrl: { url: "capitalCallV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "capitalCallV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "CAPITAL_CALL_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_CAPITALCALL_VIEW",
        }
    },
    "specialAllocation": {
        headers: 'assets/headers/accounting/specialAllocation.JSON',
        gridDataUrl: 'specialAllocationV2/records/',
        gridDeleteUrl: 'specialAllocation/',
        moduleName: "specailAllocation",
        filterUrl: { url: "specialAllocationV2/filterByKeys" },
        newButtonFunction: 'SPECIALALLOCATION_CREATE',
        deleteButtonFunction: "SPECIALALLOCATION_DELETE",
        routeUrl: { create: "/accounting/specialAllocation-entry/create", upload: "/accounting/specialallocationUpload" },
        viewUrl: '/accounting/specialAllocation-entry/view',
        uploadDetails: {
            uploadUrl: "specialAllocationV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/specialAllocation",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/specialAllocation/", name: "special_allocation", sampleName: "specilallocation", dataStringify: false
        },
        gridExportUrl: { url: "specialAllocationV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "specialAllocationV2/downloadFilterSpecialAllocation/" },
        authFunctions: {
            newButtonFunction: "SPECIALALLOCATION_CREATE", uploadButtonFunction: "SPECIALALLOCATION_CREATE",
            viewButtonFunction: "SPECIALALLOCATION_VIEW", deleteButtonFunction: "SPECIALALLOCATION_DELETE",
        }
    },
    "customRatio": {
        headers: 'assets/headers/accounting/customRatio.JSON',
        gridDataUrl: 'uploadRatioV2/records/',
        gridDeleteUrl: 'uploadRatio/',
        moduleName: "customRatio",
        filterUrl: { url: "uploadRatioV2/filterByKeys" },
        newButtonFunction: 'CUSTOM_RATIO_VIEW',
        deleteButtonFunction: "CUSTOM_RATIO_DELETE",
        uploadDetails: {
            uploadUrl: "uploadRatioV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ratio",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ratio/", name: "custom_ratio", sampleName: "ratiodemo", dataStringify: false
        },
        routeUrl: { create: "/accounting/customRatio-entry/create", upload: "/accounting/customRatioUpload" },
        viewUrl: '/accounting/customRatio-entry/view',
        gridExportUrl: { url: "uploadRatioV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "uploadRatioV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "CUSTOM_RATIO_CREATE", uploadButtonFunction: "CUSTOM_RATIO_CREATE",
            viewButtonFunction: "CUSTOM_RATIO_VIEW", deleteButtonFunction: "CUSTOM_RATIO_DELETE",
        }
    },
    'excludeComposite': {
        headers: 'assets/headers/accounting/excludeComposite.JSON',
        gridDataUrl: 'tradingProgramCompositeV2/records/',
        gridDeleteUrl: 'tradingProgramComposite/',
        moduleName: "programAcctExclusion",
        filterUrl: { url: "tradingProgramCompositeV2/filterByKeys" },
        newButtonFunction: 'TRADING_PROGRAM_COMPOSITE_CREATE',
        uploadDetails: {
            uploadUrl: "tradingProgramCompositeV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/excludeComposite",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/excludeComposite/", name: "exclude_composite", sampleName: "excludecompsitedemo", dataStringify: false
        },
        routeUrl: { create: "/legacy/excludeComposite-entry/create", upload: "/legacy/excludeCompositeUpload" },
        viewUrl: '/legacy/excludeComposite-entry/view',
        gridExportUrl: { url: "tradingProgramCompositeV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "tradingProgramCompositeV2/downloadFilterProgramExclusion/" },
        authFunctions: {
            newButtonFunction: "TRADING_PROGRAM_COMPOSITE_CREATE", uploadButtonFunction: "EXCLUDECOMPOSITE_UPLOAD",
            viewButtonFunction: "", deleteButtonFunction: "FUNDCLASS_DELETE",
        }

    },
    'tradingLevelChange': {
        headers: 'assets/headers/accounting/tradingLevelChange.JSON',
        gridDataUrl: "capitalActivityTraderV2/records/",
        gridDeleteUrl: 'capitalActivityTrader/',
        moduleName: "capitalActivityTrader",
        filterUrl: { url: "capitalActivityTraderV2/filterByKeys" },
        newButtonFunction: 'FUNDCLASS_CREATE',
        deleteButtonFunction: "FUNDCLASS_DELETE",
        uploadDetails: {
            uploadUrl: "capitalActivityTraderV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradingLevelChange",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradingLevelChange/", name: "trading_level_change", sampleName: "tradinglevelchangedemo", dataStringify: false
        },
        routeUrl: { create: "/accounting/tradingLevelChange-entry/create", upload: "/accounting/tradingLevelChangeUpload" },
        viewUrl: "/accounting/tradingLevelChange-entry/view",
        gridExportUrl: { url: "capitalActivityTraderV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "capitalActivityTraderV2/downloadFilterCapitalActivityTrader/" },
        authFunctions: {
            newButtonFunction: "FUNDCLASS_CREATE", uploadButtonFunction: "FUNDCLASS_CREATE",
            viewButtonFunction: "TRADING_LEVEL_CHANGE_VIEW", deleteButtonFunction: "CAPITALACCOUNT_DELETE",
        }
    },
    'viewPnlDistribution': {
        headers: 'assets/headers/accounting/viewPnlDistribution.JSON',
        gridDataUrl: 'PNLContributionReportV2/records/',
        filterUrl: { url: "PNLContributionReport/filterByKeys" },
        moduleName: "pNLContribution",
        gridExportUrl: { url: "PNLContributionReportV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "PNLContributionReportV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "none",
        }

    },
    'TLRegime': {
        headers: 'assets/headers/accounting/TLRegime.JSON',
        gridDataUrl: 'tLRegimeV2/records/',
        gridDeleteUrl: 'tLRegime/',
        moduleName: "tlRegime",
        filterUrl: { url: "tLRegimeV2/filterByKeys" },
        uploadDetails: {
            uploadUrl: "tLRegimeV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tlRegime",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tlRegime/", name: "tl_regime", sampleName: "tlregimedemo", dataStringify: false
        },
        routeUrl: { create: "/accounting/TLRegime-entry/create", upload: "/accounting/tlregimeUpload" },
        viewUrl: "/accounting/TLRegime-entry/view",
        gridExportUrl: { url: "tLRegimeV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "tLRegimeV2/downloadFiltertLRegime/" },
        authFunctions: {
            newButtonFunction: "ACCOUNTING_TLREGIME_CREATE", uploadButtonFunction: "ACCOUNTING_TLREGIME_CREATE",
            viewButtonFunction: "ACCOUNTING_TLREGIME_VIEW", deleteButtonFunction: "ACCOUNTING_TLREGIME_DELETE",
        }
    },
    'Manager': {
        headers: 'assets/headers/accounting/Manager.JSON',
        gridDataUrl: 'managerListV2/records/',
        gridDeleteUrl: 'managerList/',
        moduleName: "managerList",
        filterUrl: { url: "managerListV2/filterByKeys" },
        uploadDetails: {
            uploadUrl: "managerListV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/managerList",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/managerList/", name: "manager", sampleName: "managerlistdemo", dataStringify: false
        },
        routeUrl: { create: "/legacy/Manager-entry/create", upload: "/legacy/managerUpload" },
        viewUrl: '/legacy/Manager-entry/view',
        gridExportUrl: { url: "managerListV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "managerListV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "CTAMANAGER_CREATE", uploadButtonFunction: "CTAMANAGER_CREATE",
            viewButtonFunction: "CTAMANAGER_VIEW", deleteButtonFunction: "CTAMANAGER_DELETE",
        }
    },
    'programHistoricalData': {
        headers: 'assets/headers/accounting/programHistoricalData.JSON',
        gridDataUrl: 'ctaHistoricalDataV2/records/',
        gridDeleteUrl: 'ctaHistoricalData/',
        moduleName: "ctaHistoricalData",
        filterUrl: { url: "ctaHistoricalDataV2/filterByKeys" },
        newButtonFunction: 'ACCOUNTING_CTASPECIFIC_PROGRAMHISTORICALDATA_CREATE',
        uploadDetails: {
            uploadUrl: "ctaHistoricalDataV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ctaHistoricalData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ctaHistoricalData/", name: "program_historical_data", sampleName: "programhistoricaldatalist", dataStringify: false
        },
        routeUrl: { create: "/accounting/programHistoricalData-entry/create", upload: "/accounting/programHistoricalDataUpload" },
        viewUrl: '/accounting/programHistoricalData-entry/view',
        gridExportUrl: { url: "ctaHistoricalDataV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "ctaHistoricalDataV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "ACCOUNTING_CTASPECIFIC_PROGRAMHISTORICALDATA_CREATE", uploadButtonFunction: "ACCOUNTING_CTASPECIFIC_PROGRAMHISTORICALDATA_CREATE",
            viewButtonFunction: "ACCOUNTING_CTASPECIFIC_PROGRAMHISTORICALDATA_VIEW", deleteButtonFunction: "ACCOUNTING_CTASPECIFIC_PROGRAMHISTORICALDATA_DELETE",
        }
    },
    'feeRateChange': {
        headers: 'assets/headers/accounting/feeRateChange.JSON',
        gridDataUrl: 'feeRateChangeV2/records/',
        gridDeleteUrl: 'feeRateChange/',
        moduleName: "feeRateChange",
        filterUrl: { url: "feeRateChangeV2/filterByKeys" },
        newButtonFunction: 'FEE_RATE_CHANGE_CREATE',
        gridExportUrl: { url: "feeRateChangeV2/downloadRecords/", isZoneName: false },
        viewUrl: "/legacy/fee-rate-change-entry/view",
        routeUrl: { create: "/legacy/fee-rate-change-entry/create" },
        gridFilteredUrl: { url: "feeRateChangeV2/downloadFilterFeeRateChangeRecord/" },
        authFunctions: {
            newButtonFunction: "FEE_RATE_CHANGE_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "FEE_RATE_CHANGE_VIEW", deleteButtonFunction: "FUNDCLASS_DELETE",
        }
    },
    'takeOverPerfBaseFee': {
        headers: 'assets/headers/accounting/takeOverPerfBaseFee.JSON',
        gridDataUrl: 'takeOverPerformanceBaseFeeV2/records/',
        gridDeleteUrl: 'takeOverPerformanceBaseFee/',
        moduleName: "takeOverPerformanceBaseFee",
        filterUrl: { url: "takeOverPerformanceBaseFeeV2/filterByKeys" },
        newButtonFunction: 'TAKEOVERPERFORMANCEBASEFEE_CREATE',
        gridExportUrl: { url: "takeOverPerformanceBaseFeeV2/downloadRecords/", isZoneName: false },
        routeUrl: { create: "/accounting/takeover-perf-base-fee-entry/create", upload: "/accounting/takeOverPerfBaseFeeUpload" },
        uploadDetails: {
            uploadUrl: "takeOverPerformanceBaseFeeV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/takeOverPerformanceBaseFee",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/takeOverPerformanceBaseFee/", name: "take_over_perf_base_fee", sampleName: "topbfee", dataStringify: false
        },
        viewUrl: "/accounting/takeover-perf-base-fee-entry/view",
        gridFilteredUrl: { url: "takeOverPerformanceBaseFeeV2/downloadFilterTakeOverPerBaseFee/" },
        authFunctions: {
            newButtonFunction: "TAKEOVERPERFORMANCEBASEFEE_CREATE", uploadButtonFunction: "TAKEOVERPERFORMANCEBASEFEE_CREATE",
            viewButtonFunction: "TAKEOVERPERFORMANCEBASEFEE_VIEW", deleteButtonFunction: "TAKEOVERPERFORMANCEBASEFEE_DELETE",
        }
    },
    'mgtIncentiveFeeAdj': {
        headers: 'assets/headers/accounting/mgtAndIncentivefeeAdj.JSON',
        gridDataUrl: 'mGTAndIncentiveFeeAdjV2/records/',
        gridDeleteUrl: 'mGTAndIncentiveFeeAdj/',
        moduleName: "mGTAndIncentiveFeeAdj",
        filterUrl: { url: "mGTAndIncentiveFeeAdjV2/filterByKeys" },
        newButtonFunction: 'MGTANDINCENTIVEFEEADJ_CREATE',
        gridExportUrl: { url: "exportController/download/mGTAndIncentiveFeeAdj", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        // gridExportUrl: { url: "mGTAndIncentiveFeeAdjV2/downloadFilter/", isZoneName: false },
        routeUrl: { create: "/accounting/mgt-incentive-fee-adj-entry/create", upload: "/accounting/mgtIncentiveFeeAdjUpload" },
        uploadDetails: {
            uploadUrl: "mGTAndIncentiveFeeAdjV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/mGTAndIncentiveFeeAdj",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/mGTAndIncentiveFeeAdj/", name: "mgt_incentive_fee_adj", sampleName: "mgtandincentivefeeadj", dataStringify: false
        },
        viewUrl: "/accounting/mgt-incentive-fee-adj-entry/view",
        gridFilteredUrl: { url: "exportController/downloadFilter/mGTAndIncentiveFeeAdj/" },
        authFunctions: {
            newButtonFunction: "MGTANDINCENTIVEFEEADJ_CREATE", uploadButtonFunction: "MGTANDINCENTIVEFEEADJ_CREATE",
            viewButtonFunction: "MGTANDINCENTIVEFEEADJ_VIEW", deleteButtonFunction: "MGTANDINCENTIVEFEEADJ_DELETE",
        }
    },
    'nonTradeActivity': {
        headers: 'assets/headers/accounting/nonTradeActivity.JSON',
        gridDataUrl: 'nonTradeTransactionsV2/records/',
        gridDeleteUrl: "nonTradeTransactions/",
        moduleName: "nonTradeTransactions",
        exportModuleName: "nonTradeTransactions",
        deleteButtonFunction: "NON_TRADING_TRANSACTION_DELETE",
        filterUrl: { url: "nonTradeTransactionsV2/filterByKeys" },
        gridExportUrl: { url: "exportController/download/nonTradeTransactions", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/nonTradeTransactions/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        newButtonFunction: 'CHARTOFACCOUNTS_VIEW',
        routeUrl: { create: "/accounting/nontradeactivity-entry/create", upload: "/accounting/nonTradeActivityUpload" },
        viewUrl: "/accounting/nontradeactivity-entry/view",
        uploadDetails: {
            uploadUrl: "nonTradeTransactionsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/nonTradeTransactions",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/nonTradeTransactions/", name: "non_trade_activity", sampleName: "nontradetransactions", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "NON_TRADING_TRANSACTION_CREATE", uploadButtonFunction: "NON_TRADING_TRANSACTION_CREATE",
            viewButtonFunction: "NON_TRADING_TRANSACTION_VIEW", deleteButtonFunction: "NON_TRADING_TRANSACTION_DELETE",
        }
    },
    "uploadallocationhistory": {
        headers: "assets/headers/accounting/uploadallocationhistory.JSON",
        gridDataUrl: "investorCapitalSummaryPEV2/historicalInvestorCapitalSummaryRecords/",
        moduleName: "investorCapitalSummary",
        filterUrl: { url: "investorCapitalSummaryPEV2/filterByKeys" },
        filterDeleteUrl: { url: "investorCapitalSummaryPE/filterByKeysDelete" },
        gridExportUrl: { url: "investorCapitalSummaryPEV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "investorCapitalSummaryPEV2/downloadFilterRecords/", isZoneName: false },
        //deleteButtonFunction: 'ACCOUNTING_ALLOCATION_HISTORY_DELETE',
        gridDeleteUrl: "investorCapitalSummaryPE/",
        newButtonFunction: "null",

        uploadDetails: {
            uploadUrl: "investorCapitalSummaryPEV2/historicalInvestorCapitalSummaryUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/historicalInvestorCapitalSummary",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/historicalInvestorCapitalSummary/", name: "allocation_history", sampleName: "historicalinvestorcapitalsummarydemo", dataStringify: true
        },
        routeUrl: { upload: "/accounting/uploadallocationhistoryupload" },
        viewUrl: "/accounting/historicalInvestorCapitalSummaryView",
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "ACCOUNTING_ALLOCATION_HISTORY_UPLOAD",
            viewButtonFunction: "none", deleteButtonFunction: "ACCOUNTING_ALLOCATION_HISTORY_DELETE",
        }


    },
    "setupJeTransfer": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/setupJeTransfer.JSON",
        gridDataUrl: "setupJETransferFundLevelV2/records/",
        gridDeleteUrl: "setupJETransferFundLevel/",
        moduleName: "setupJETransferFundLevelDto",
        filterUrl: { url: "setupJETransferFundLevelV2/filterByKeys" },
        gridExportUrl: { url: "setupJETransferFundLevelV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "setupJETransferFundLevelV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/setup-je-transfer-fund-level/create", upload: "/manager/upload-setup-je-transfer" },
        viewUrl: "/manager/setup-je-transfer-fund-level/view",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/setupJETransferFundLevel',
            uploadUrl: "setupJETransferFundLevelV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/setupJETransferFundLevel",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/setupJETransferFundLevel/", name: "setup_je_transfer_fund_level", sampleName: "setupjetransferdemo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION,USER_CREATE",
        }
    },
    "reportApproval": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/reportApproval.JSON",
        gridDataUrl: "reportApprovalPeriodRuleV2/records/",
        gridDeleteUrl: "reportApprovalPeriodRule/",
        moduleName: "reportApprovalPeriodRule",
        filterUrl: { url: "reportApprovalPeriodRuleV2/filterByKeys" },
        routeUrl: { create: "/manager/report-approval-rule/create" },
        viewUrl: "/manager/report-approval-rule/view",
        authFunctions: {
            newButtonFunction: "REPORTING_PORTAL_USER_CREATE", uploadButtonFunction: "REPORTING_PORTAL_USER_CREATE",
            viewButtonFunction: "REPORTING_PORTAL_USER_VIEW", deleteButtonFunction: "REPORTING_PORTAL_USER_DELETE",
        }
    },
    "fundGroup": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/fundGroupList.JSON",
        gridDataUrl: "fundGroupV2/records/",
        gridDeleteUrl: "fundGroup/",
        moduleName: "fundGroup",
        fileName: "FundGroupFiltered",
        filterUrl: { url: "fundGroupV2/filterByKeys" },
        gridExportUrl: { url: "fundGroupV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "fundGroupV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/fund-group/create" },
        viewUrl: "/manager/fund-group/view",
        authFunctions: {
            newButtonFunction: "FUNDGROUP_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_GROUPING",
        }
    },
    "investorPortalUser": {
        entCard: 'others',
        pathName: 'investor_portal_user',
        headers: "assets/headers/manager-headers/investorPortalUser.JSON",
        gridDataUrl: "investorPortalUser/records/",
        gridDeleteUrl: "investorPortalUser/",
        moduleName: "investorPortalUser",
        filterUrl: { url: "investorPortalUserV2/filterByKeys" },
        gridExportUrl: { url: "investorPortalUserV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "investorPortalUserV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/investor-portal-user/create", upload: "/manager/investor-portal-user/upload" },
        uploadDetails: {
            uploadUrl: "investorPortalUserV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investorPortalUser",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investorPortalUser/", name: "investor_portal_user",
            sampleName: "investorportaluserdemo", dataStringify: true
        },
        viewUrl: "/manager/investor-portal-user/view",
        authFunctions: {
            newButtonFunction: "INVESTORPORTALUSER_CREATE", uploadButtonFunction: "INVESTORPORTALUSER_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "INVESTORPORTALUSER_DELETE",
        }
    },
    "reportingPortalUser": {
        pathName: 'reporting_portal_user',
        entCard: 'others',
        headers: "assets/headers/manager-headers/investorPortalUser.JSON",
        gridDataUrl: "reportingPortalUserV2/records/",
        gridDeleteUrl: "reportingPortalUser/",
        moduleName: "reportingPortalUser",
        filterUrl: { url: "reportingPortalUserV2/filterByKeys" },
        gridExportUrl: { url: "reportingPortalUserV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "reportingPortalUserV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/reporting-portal-user/create", upload: "/manager/reporting-portal-user/upload" },
        uploadDetails: {
            uploadUrl: "reportingPortalUserV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/reportingPortalUser",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/reportingPortalUser/", name: "reporting_portal_file",
            sampleName: "allsmupload2", dataStringify: true
        },
        viewUrl: "/manager/reporting-portal-user/view",
        authFunctions: {
            newButtonFunction: "REPORTING_PORTAL_USER_CREATE", uploadButtonFunction: "REPORTING_PORTAL_USER_CREATE",
            viewButtonFunction: "REPORTING_PORTAL_USER_VIEW", deleteButtonFunction: "REPORTING_PORTAL_USER_DELETE",
        }
    },
    "investorGroups": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/investorGroups.JSON",
        gridDataUrl: "InvestorGroupV2/records/",
        gridDeleteUrl: "InvestorGroup/",
        moduleName: "investorGroup",
        filterUrl: { url: "InvestorGroupV2/filterByKeys" },
        gridExportUrl: { url: "InvestorGroupV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "InvestorGroupV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/investor-group/create" },
        viewUrl: "/manager/investor-group/view",
        authFunctions: {
            newButtonFunction: "INVESTORGROUP_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "INVESTORGROUP_VIEW", deleteButtonFunction: "INVESTORGROUP_VIEW,SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORGROUP",
        }
    },
    "dataImportStatus": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/dataImportStatus.JSON",
        gridDataUrl: "configurations/filterWorkflowByKeys?", //systemManager/alteryx/fetchAllWorklowStatus/
        method: 'post',
        getCustomTypeUrl: "configurations/fetchAllWorklowStatus/", //systemManager/alteryx/filterWorkflowByKeys
        moduleName: "dataImportStatus",
        filterUrl: { url: "configurations/filterWorkflowByKeys" },
        gridExportUrl: { url: "configurationsV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "configurationsV2/downloadFilterRecords/", isZoneName: true },
        viewUrl: "systemManager/alteryx/fetchAllWorklowStatus/",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_DIV_IMPORT,SEAMLESS_SIDEMENU_CUSTOMIZATION,DERIBITDATA_VIEW,EXCHANGERATE_VIEW,USER_CREATE",
        }
    },
    "positionReportFixedIncome": {
        pdfURL: { url: 'positionReportFixedIncome/PositionReportFixedIncome', },
        csvURL: { url: 'positionReportFixedIncome/PositionReportFixedIncome', }
    },
    "inputConfiguration": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/inputConfiguration.JSON",
        gridDataUrl: "alteryxInputConfig/records/",
        gridDeleteUrl: "alteryxInputConfig/delete",
        moduleName: "inputConfiguration",
        filterUrl: { url: "alteryxInputConfig/filterByKeys" },
        customFilterUrl: { url: "alteryxInputConfig/filterByKeys", method: 'Post' },
        gridExportUrl: { url: "alteryxInputConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "alteryxInputConfigV2/exportFilteredRecords", isZoneName: false },
        routeUrl: { create: "/manager/input-configuration/create" },
        viewUrl: "/manager/input-configuration/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION",
        }
    },
    "vendorsUpload": {
        pathName: 'vendors_update',
        entCard: 'others',
        headers: "assets/headers/settings-header/CreateNew/vendors.JSON",
        gridDataUrl: "vendorV2/records/",
        gridDeleteUrl: "vendorV2/",
        moduleName: "vendor",
        gridExportUrl: { url: "vendorV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "vendorV2/filterByKeys" },
        gridFilteredUrl: { url: "vendorV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/vendorUpload',
            uploadUrl: "VendorUploadController/uploadVenderFromEnt", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/vendorUpload",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/vendorUpload/", name: "vendor", sampleName: "vendorsupload", dataStringify: false
        },
        viewUrl: "/setting/vendors/view",
        routeUrl: { create: "/setting/vendors/create", upload: "/VendorUploadController/uploadVenderFromEnt" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_VENDOR_UPLOAD", uploadButtonFunction: "SEAMLESS_VENDOR_UPLOAD",
            viewButtonFunction: "SEAMLESS_VENDOR_UPLOAD", deleteButtonFunction: "SEAMLESS_VENDOR_UPLOAD",
        }
    },
    "viewassetfees": {
        exportURL: { url: api_url + 'assetBaseFeeV2/downloadAssetBasedCSV/' },
    },
    "runfxgl": {
        exportURL: { url: api_url + 'exportController/downloadFilter/fXGainLoss/' },
        moduleName: "fXGainLoss",
    },
    "hurdleCalculation": {
        exportURL: { url: api_url + 'calculateHurdleV2/excleDownload/' },
    },
    "viewperffees": {
        exportURL: { url: api_url + 'performanceFeeV2/performanceFeeCalculationExcleDownload/' },
    },
    "incomeallocation2": {
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "allocationRatio",
    },
    "incomeAllocatonhurdle": {
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        exportITDURL: { url: api_url + 'allocationRatioV2/downloadExcelOfITDAllcation/' },
        moduleName: "allocationRatio",
    },
    "runincomeallocationSeries": {
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "allocationRatio",
    },
    "generateIRRWithfundGroup": {
        exportURL: { url: api_url + 'xirrV2/xirrFundGroupExcleDownload/' },
    },
    "irrAndMocIRR": {
        exportURL: { url: api_url + 'xirrMoicV2/xirrAndMoicExportProcess/' },
    },
    "runTotalGainLoss": {
        exportURL: { url: api_url + 'totalGainLossReportV2/downloadRecords/' },

    },
    "totalGainLossReportByFund": {
        exportURL: { url: api_url + 'totalGainLossReportV2/downloadRecordsByFund/' },
    },
    "calculateBondInterest": {
        exportURL: { url: api_url + 'bondV2/downloadRecords/' },
        moduleName: "bondInterestIncome",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "BOND_INTEREST_INCOME_CALCULATION_VIEW",
        }
    },
    "deribitData": {
        exportDeribitURL: { url: api_url + 'deribitAPITransactionsV2/downloadDeribitRecords/' },
        exportMarketURL: { url: api_url + 'deribitAPITransactionsV2/downloadMarketData/' },
    },
    "generateIRR": {
        exportURL: { url: api_url + 'xirrV2/xirrExcleDownload/' },
    },
    "generateCustomIrrCombine": {
        exportURL: { url: api_url + 'xirrV2/xirrFundGroupCustomZIPDownload/' },
    },
    "configureFetchMarketData": {
        userCard: 'market_data',
        headersMarketDataFailed: 'assets/headers/portfolio-headers/marketDataFailed.JSON',
        headersMarketDataComparison: 'assets/headers/portfolio-headers/marketDataComparison.JSON',
        moduleName: "configureFetchMarketData",
        gridExportUrl: {
            marketDataFailed: { url: "seamlessMarketDataV2/downloadFailedRecords/", isZoneName: false },
            marketComparison: { url: "seamlessMarketData/downloadMarketDataComparisonRecords/", isZoneName: false }
        },
        gridFilteredUrl: {
            marketDataFailed: { url: "seamlessMarketDataV2/downloadRecordsFilterFailed/", isZoneName: false },
            marketComparison: { url: "seamlessMarketData/downloadMarketDataComparisonRecords/", isZoneName: false }
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "GETMARKETDATA_FROMGOLBALMARKETDATA,SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA,TRADE_RECONCILE_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_RECONCILIATION_TRADE_RECONCILIATION_VIEW,MULTIPARTYRECONCILE_VIEW",
        }
    },
    "perfTableExtract": {
        exportURL: { url: api_url + 'runProgramCompoFundV2/downloadPerfTableExcel/' },

    },
    "performanceAnalytics": {
        exportURL: { url: api_url + 'dataAnalyticsV2/DownloadCSV/' },
    },

    "dailyProcessingView": {
        headers: "assets/headers/system-manager-headers/dailyProcessing.JSON",
        gridDataUrl: "dataProcessingV2/getRecord/",
        moduleName: "dataProcessingConfiguration",
        filterUrl: { url: "dataProcessingV2/filterByKeys" },
        gridDeleteUrl: "dataProcessing/deleteAll",
        routeUrl: { create: "/processing/configure-execute-entry/create" },
        viewUrl: "/processing/configure-execute-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "DAILYPROCESSING_VIEW",
        }
    },
    "dailyProcessingStatus": {
        headers: "assets/headers/system-manager-headers/systemProcessingStatus.JSON",
        gridDataUrl: "dataProcessingV2/getWorkflows/",
        moduleName: "dailyProcessingStatus",
        filterUrl: { url: "dataProcessingV2/filterByKeys" },
        gridExportUrl: { url: "dataProcessingV2/fetchAllRecords/", isZoneName: false },
        gridFilteredUrl: { url: "dataProcessingV2/fetchAllRecords/", isZoneName: false },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "DAILYPROCESSING_VIEW,SEAMLESS_PROCESSING_AUTOMATEPROCESS_PROCESSINGSTATUS",
        }
    },
    "executeWorkflow": {
        headers: "assets/headers/system-manager-headers/executeWorkflowData.JSON",
        gridDataUrl: "alteryxExecuteWorkflowV2/records/",
        getCustomTypeUrl: "configurations/fetchAllWorklowStatus/",
        moduleName: "executeWorkflow",
        filterUrl: { url: "alteryxExecuteWorkflow/filterByKeys" },
        gridExportUrl: { url: "alteryxExecuteWorkflowV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "alteryxExecuteWorkflowV2/downloadFilterRecords/", isZoneName: true },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_DIV_IMPORT,SEAMLESS_INPUT_DIV_IMPORT",
        }

    }, "workflowStatus": {
        entCard: 'others',
        headers: "assets/headers/system-manager-headers/alteryxWorkflowStatus.JSON",
        gridDataUrl: "configurations/filterWorkflowByKeys?", //configurations/fetchAllWorklowStatus/0/user
        getCustomTypeUrl: "configurations/fetchAllWorklowStatus/", //configurations/filterWorkflowByKeys
        method: 'post',
        // gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "workflowStatus",
        filterUrl: { url: "configurations/filterWorkflowByKeys" },
        gridExportUrl: { url: "configurationsV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "configurationsV2/downloadFilterRecords/", isZoneName: true },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_DIV_IMPORT,SEAMLESS_INPUT_DIV_IMPORT",
        }

    },

    "inputDataIntegrityCheck": {
        headers: "assets/headers/system-manager-headers/integrityCheckData.JSON",
        gridDataUrl: "alteryx/integrityCheckV2/records/",
        gridDeleteUrl: "alteryx/integrityCheck/",
        moduleName: "inputDataRecon",
        filterUrl: { url: "alteryx/integrityCheckV2/filterByKeys" },
        gridExportUrl: { url: "alteryx/integrityCheckV2/exportCSV/", isZoneName: false },
        gridFilteredUrl: { url: "alteryx/integrityCheckV2/exportFilteredCSV/", isZoneName: false },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "USER_Input_Data_Integrity_Check",
        }
    },

    //"inputDataIntegritySummery"
    "inputDataIntegritySummery": {
        headers: "assets/headers/system-manager-headers/integrityCheckDataSum.JSON",
        gridDataUrl: "alteryx/integrityCheckSummaryV2/records/",
        gridDeleteUrl: "alteryx/integrityCheckSummary/",
        moduleName: "inputDataReconSummary",
        filterUrl: { url: "alteryx/integrityCheckSummaryV2/filterByKeys" },
        gridExportUrl: { url: "alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        gridFilteredUrl: { url: "alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },
        authFunctions: {
            deleteButtonFunction: "USER_Input_Data_Integrity_Check",
        }
    },
    "batch": {
        headers: "assets/headers/batch-tracking/batch.JSON",
        gridDataUrl: "batchV2/getBatchesAllRecords/",
        // gridDeleteUrl: "alteryx/integrityCheckSummary/",
        //gridDataUrl:"batch?t=1637730407688&length=10&order=%7B%22column%22:%22createdOn%22,%22dir%22:%22desc%22%7D&search=&start=0",
        moduleName: "batch",
        filterUrl: { url: "batchV2/filterBatchesByKeys" },
        // gridExportUrl: { url: "/formidium/alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        // gridFilteredUrl: { url: "/formidium/alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },
        routeUrl: { create: "/reportdeliveryconfi/create" },
        authFunctions: {
            deleteButtonFunction: "SIDEMENU_REPORTS,SEAMLESS_PROCESSING_DIV_BATCH",
        }
    },
    "batchLog": {
        headers: "assets/headers/batch-tracking/batchExecutionLogs.JSON",
        //  gridDataUrl: 'batch/batchExecutionLogs?t=1632549893315&length=10&order=%7B%22column%22:%22executionDateTime%22,%22dir%22:%22desc%22%7D&search=&start=0',
        gridDeleteUrl: "alteryx/integrityCheckSummary/",
        gridDataUrl: "batchV2/getBatchExecutionRecords/",
        moduleName: "batchExecutionLog",
        filterUrl: { url: "batchV2/filterBatchExecutionByKeys" },
        authFunctions: {
            deleteButtonFunction: "SIDEMENU_REPORTS,SEAMLESS_PROCESSING_DIV_BATCH",
        }
        // gridExportUrl: { url: "alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        // gridFilteredUrl: { url: "alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },

    },
    "scheduleTaskInfo": {
        headers: "assets/headers/batch-tracking/allschedules.JSON",
        gridDataUrl: "batchV2/allSchedulesRecords/",
        // gridDeleteUrl: "alteryx/integrityCheckSummary/",
        moduleName: "scheduleTaskInfo",
        filterUrl: { url: "batchV2/filterSchedulesByKeys" },
        // gridExportUrl: { url: "alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        // gridFilteredUrl: { url: "alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },

    },
    "bill": {
        headers: "assets/headers/accounting/billPayable.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "bill",
        gridDeleteUrl: "reBillDetails/delete",
        filterUrl: { url: "reBillDetails/filterByKeys" },
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        viewUrl: "/accounting/bill/view",
        routeUrl: { create: "/accounting/bill/create" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL",
            deleteButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_DELETE",
        }
    },
    "unpaidBills": {
        headers: "assets/headers/accounting/unPaidBillsPayable.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "unpaidBills",
        filterUrl: { url: "reBillDetails/filterByKeys" },
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        viewUrl: "/accounting/bill/view",
        routeUrl: { create: "/accounting/bill/create" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL",
            deleteButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_DELETE",
        }

    },
    "paidBills": {
        headers: "assets/headers/accounting/paidBillsPayable.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "paidBills",
        filterUrl: { url: "reBillDetails/filterByKeys" },
        viewUrl: "/accounting/bill/view",
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        routeUrl: { create: "/accounting/bill/create" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL",
            deleteButtonFunction: "SEAMLESS_ACCOUNTING_ACCOUNTSPAYABLE_BILL_DELETE",
        }

    },
    "usersList": {
        headers: "assets/headers/manager-headers/users.JSON",
        entCard: "enterprise_configuration",
        gridDataUrl: "userV2/records/",
        gridDeleteUrl: "user/",
        moduleName: "user",
        filterUrl: { url: "userV2/filterByKeys" },
        gridExportUrl: { url: "userV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "userV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/users/create" },
        viewUrl: "/manager/users/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "none", deleteButtonFunction: "SYSADMIN_USER_DELETE",
        }
    },
    // Arav Jain
    "connectionList": {
        pathName: 'connection',
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/connection.JSON",
        gridDataUrl: "ConnectionV2/records/",///needs to change true/false once we add same on systemmanager lvl
        // gridDeleteUrl: "brokerAccount/", no delete 
        moduleName: "brokerAccount",
        fileName: "connectionListFiltered",
        filterUrl: { url: "ConnectionV2/filterByKeys" },
        gridExportUrl: { url: "ConnectionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "ConnectionV2/downloadFilterRecords", isZoneName: false },
        routeUrl: { create: "/manager/connections/create", upload: "/manager/connections/upload" },
        uploadDetails: {
            uploadUrl: "brokerAccountV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/connections",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/connections/", name: "connections ", sampleName: "connectiondemo"
        },
        viewUrl: "/manager/connections/view",
        systemAdminViewUrl: "/systemmanager/connections/view",
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "",
            //Auth :Vg, SDI-151 Connection UI Delete Action Removed deleteButtonFunction: "NONE"
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION,SMPRIVATEFUND_VIEW",
        }
    },
    "custodianList": {
        pathName: 'custodians',
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/custodian.JSON",
        gridDataUrl: "brokerAccountV2/records/",
        // gridDeleteUrl: "brokerAccount/", no delete 
        moduleName: "brokerAccount",
        fileName: "custodianListFiltered",
        filterUrl: { url: "brokerAccountV2/filterByKeys" },
        gridExportUrl: { url: "brokerAccountV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "brokerAccountV2/downloadFilterRecords", isZoneName: false },
        routeUrl: { create: "/manager/custodians/create", upload: "/manager/custodians/upload" },
        uploadDetails: {
            uploadUrl: "brokerAccountV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/custodian",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/custodian/", name: "Custodian ", sampleName: "custodiandemo"
        },
        viewUrl: "/manager/custodians/view",
        viewEntitiesUrl: "/Entities/custodians/view",
        systemAdminViewUrl: "/systemmanager/custodians/view",
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION,SMPRIVATEFUND_VIEW",
        }
    },

    "connectCustodianInvestor": {
        entCard: 'mapping',
        headers: "assets/headers/manager-headers/connect-custodian-investor.JSON",
        gridDataUrl: "connectCustodianInvestorV2/records/",
        gridDeleteUrl: "connectCustodianInvestor/",
        moduleName: "connectCustodianAndInvestor",
        filterUrl: { url: "connectCustodianInvestorV2/filterByKeys" },
        gridExportUrl: { url: "connectCustodianInvestorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "connectCustodianInvestorV2/downloadFilterRecords/", isZoneName: false },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/manager/connect-custodian-investor/view",
        routeUrl: { create: "/manager/connect-custodian-investor/create", upload: " " },
        authFunctions: {
            newButtonFunction: "CONNECT_CUSTODIAN_INVESTOR_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "CONNECT_CUSTODIAN_INVESTOR_DELETE",
        }
    },
    "connectUserInvestor": {
        entCard: 'mapping',
        pathName: 'connect_user_investor',
        headers: "assets/headers/manager-headers/connect-user-investor.JSON",
        gridDataUrl: "associationOfUserFundInvestorV2/records/",
        gridDeleteUrl: "associationOfUserFundInvestor/",
        moduleName: "associationOfUserFundInvestor",
        fileName: "User-Fund-Investor-MappingFiltered",
        filterUrl: { url: "associationOfUserFundInvestorV2/filterByKeys" },
        gridExportUrl: { url: "associationOfUserFundInvestorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "associationOfUserFundInvestorV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/connect-user-investor/create", upload: "/manager/connect-user-investor/upload" },
        viewUrl: "/manager/connect-user-investor/view",
        uploadDetails: {
            uploadUrl: "associationOfUserFundInvestorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/associationOfUserFundInvestor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/associationOfUserFundInvestor/", name: "connect_user_investor", sampleName: "associationofuserfundinvestordemo", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,Enterprise_UserAndInvestor_view,Enterprise_UserAndInvestor_create",
        }
    },
    "amcIMProgram": {
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/amcIMProgram.JSON",
        gridDataUrl: "assetManagementV2/records/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "assetManagement/",
        moduleName: "assetManagement",
        fileName: "AssetManagementFiltered",
        filterUrl: { url: "assetManagementV2/filterByKeys" },
        gridExportUrl: { url: "assetManagementV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "assetManagementV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/manager/amc-im-program/create", upload: "/trades/tradeAllocationConfigUpload" },
        viewUrl: "/manager/amc-im-program/view",
        viewEntitiesUrl: "/Entities/amc-im-program/view",
        systemAdminViewUrl: "/systemmanager/amc-im-program/view",
        authFunctions: {
            newButtonFunction: "ASSETMANAGEMENT_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "ASSETMANAGEMENT_VIEW", deleteButtonFunction: "ASSETMANAGEMENT_DELETE",
        }
    },

    "setupamcIMProgram": {
        headers: "assets/headers/manager-headers/setupamcIMProgram.JSON",
        gridDataUrl: "assetManagementV2/records/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "assetManagement/",
        moduleName: "assetManagement",
        fileName: "AssetManagementFiltered",
        filterUrl: { url: "assetManagementV2/filterByKeys" },
        gridExportUrl: { url: "assetManagementV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "assetManagementV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/setup/client-entry/create", upload: "/setup/amc-im-program-upload" },
        viewUrl: "None",
        systemAdminViewUrl: "/systemmanager/amc-im-program/view",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/aMC',
            uploadUrl: "assetManagementV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/aMC",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/aMC/", name: "amc_im_programs", sampleName: "amcupload", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "ASSETMANAGEMENT_CREATE", uploadButtonFunction: "ASSETMANAGEMENT_CREATE",
            viewButtonFunction: "ASSETMANAGEMENT_VIEW", deleteButtonFunction: "ASSETMANAGEMENT_DELETE",
        }
    },
    "setupFundList": {
        pathName: 'funds_client_accounts',
        headers: "assets/headers/manager-headers/setupFundClientAccount.JSON",
        gridDataUrl: "fundV2/records/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "fund/delete/",
        moduleName: "fund",
        fileName: "FundListFiltered",
        filterUrl: { url: "fundV2/filterByKeys" },
        fetchCustomTypeUrl: "dataConfigurationV2/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "fundV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "fundV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/setup/entity-entry/create", upload: "/setup/entity-upload" },
        uploadDetails: {
            uploadUrl: "fundV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fundClient",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fundClient/", name: "fund_client", sampleName: "funddemo", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/fund-client-account-list/view",
        authFunctions: {
            newButtonFunction: "FUND_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "FUND_VIEW", deleteButtonFunction: "FUND_DELETE",
        }
    },
    "taxFundList": {
        pathName: 'funds_client_accounts',
        headers: "assets/headers/manager-headers/taxFundClientAccount.JSON",
        gridDataUrl: "fundV2/records/false/",
        // gridDataUrl: "taxSetup/fundFetchAllRecords/",needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "fund/delete/",
        moduleName: "fundUpdate",
        fileName: "FundListFiltered",
        filterUrl: { url: "taxSetup/filterByKeys" },
        fetchCustomTypeUrl: "dataConfigurationV2/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "taxSetup/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxSetup/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        updateUrl: "fundV2/update",
        routeUrl: { create: "/setup/fund-account/create", upload: "/taxSetup/fund-client-account-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/fundUpdate',
            uploadUrl: "taxSetup/fundUpdate", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fundUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fundUpdate/", name: "fund_client", sampleName: "fundclient", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/fund-client-account-list/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_TAXATION_SETUP_FUND_UPDATE",
            viewButtonFunction: "SEAMLESS_TAXATION_SETUP_FUND_VIEW", deleteButtonFunction: "",
            updateButtonFunction: "SEAMLESS_TAXATION_SETUP_FUND_VIEW"
        }
    },
    "taxInvestorsList": {
        pathName: 'investor',
        headers: "assets/headers/manager-headers/taxinvestor.JSON",
        gridDataUrl: "investorV2/records/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "investor/",
        moduleName: "investorUpdate",
        filterUrl: { url: "investorV2/filterByKeys/" },
        gridExportUrl: { url: "exportController/downloadFilter/taxSetupInvestors/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/taxSetupInvestors/", orderFilter: false, isZoneName: true },
        sysAdminGridExportUrl: { url: "investor/downloadRecords/", isZoneName: false },
        SystemAdminGridFilteredUrl: { url: "investor/downloadFilterRecords/", orderFilter: false, isZoneName: true },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/setup/investor-entry/create", upload: "/taxSetup/investor-upload" },
        // uploadDetails: {
        //     uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "taxInvestordemo", dataStringify: true
        // },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/investorUpdate',
            uploadUrl: "investorUpdate/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investorUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investorUpdate/", name: "investor_update", sampleName: "investorupdate", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/investors/view",
        updateUrl: 'investorV2/update',
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_TAXATION_SETUP_INVESTORS_UPDATE",
            viewButtonFunction: "SEAMLESS_TAXATION_SETUP_INVESTORS_VIEW", deleteButtonFunction: "",
        }
    },
    "taxchartOfAccounts": {
        headers: "assets/headers/manager-headers/taxChartOfAccount.JSON",
        gridDataUrl: "chartOfAccountsV2/records/",
        gridDeleteUrl: "chartOfAccounts/",
        // moduleName: "chartOfAccounts",
        gridExportUrl: { url: "chartOfAccountsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "chartOfAccountsV2/filterByKeys" },
        gridFilteredUrl: { url: "chartOfAccountsV2/downloadFilterRecords/", isZoneName: false },
        // uploadDetails: {
        //     uploadUrl: "chartOfAccountsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccounts",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccounts/", name: "chart_of_accounts", sampleName: "chartOfAccounts", dataStringify: false
        // },
        moduleName: "chartOfAccountTaxUpdate",
        updateUrl: 'chartOfAccountsV2/update',
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/chartOfAccountTaxUpdate',
            uploadUrl: "chartOfAccountsV2/uploadFileAndUpdate", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccountTaxUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccountTaxUpdate/", name: "chart_of_accounts_update", sampleName: "chartOfAccountTaxUpdate", dataStringify: true
        },
        viewUrl: "None",
        routeUrl: { create: "/setting/chart-of-accounts-entry/create", upload: "/taxSetup/chart-of-accounts-upload" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_TAXATION_SETUP_COA_UPDATE",
            viewButtonFunction: "SEAMLESS_TAXATION_SETUP_COA_VIEW", deleteButtonFunction: "",
            updateButtonFunction: "SEAMLESS_TAXATION_SETUP_COA_UPDATE"
        }
    },

    "taxVendors": {
        headers: "assets/headers/settings-header/CreateNew/taxVendors.JSON",
        gridDataUrl: "vendorV2/records/",
        gridDeleteUrl: "vendorV2/",
        moduleName: "vendor",
        gridExportUrl: { url: "taxSetup/downloadVendorRecords/", isZoneName: false },
        filterUrl: { url: "vendorV2/filterByKeys" },
        gridFilteredUrl: { url: "taxSetup/downloadVendorFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "vendorV2/vendorUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/vendor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/vendor/", name: "vendor", sampleName: "vendorlistdemo", dataStringify: false
        },
        viewUrl: "None",
        routeUrl: { create: "/taxSetup/vendors-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_SETUP_VENDORDETAILS_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_SETUP_VENDORDETAILS_CREATE",
            viewButtonFunction: "SEAMLESS_TAXATION_SETUP_VENDORDETAILS_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_SETUP_VENDORDETAILS_DELETE",
        }
    },
    "userCoaList": {
        pathName: 'user_coa',
        headers: "assets/headers/user-setup/userCoa.JSON",
        gridDataUrl: "financialDataIntegration/records/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "deleteController/",
        moduleName: "clientChartofAccount",
        // fileName: "FundListFiltered",
        filterUrl: { url: "financialDataIntegration/filterByKeys" },
        fetchCustomTypeUrl: "dataConfigurationV2/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "financialDataIntegration/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "financialDataIntegration/downloadFilterRecords/", isZoneName: false },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "/user-setup/user-coa/view",
        routeUrl: { create: "/user-setup/user-coa/create", upload: "/user-setup/user-coa-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/clientCoaUpload',
            uploadUrl: "financialDataIntegration/coaUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/clientCoaUpload",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/clientCoaUpload/", name: "client_coa", sampleName: "clientcoademo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_CHARTOFACCOUNT_CLIENT_CREATE", uploadButtonFunction: "SEAMLESS_CHARTOFACCOUNT_CLIENT_CREATE",
            viewButtonFunction: "SEAMLESS_CHARTOFACCOUNT_CLIENT_VIEW", deleteButtonFunction: "SEAMLESS_CHARTOFACCOUNT_CLIENT_DELETE",
        }
    },
    "fundPropertyList": {
        headers: "assets/headers/user-setup/fundPropertyAccount.JSON",
        gridDataUrl: "fundV2/getAllUserFundRecords/",
        filterUrl: { url: "fundV2/filterUserFundByKeys" },
        gridExportUrl: { url: "fundV2/downloadUserRecords/", isZoneName: false },
        gridFilteredUrl: { url: "fundV2/downloadFilterUserRecords/", isZoneName: false },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: " ", uploadButtonFunction: " ",
            viewButtonFunction: "SEAMLESS_SETUP_CLIENT_FUND_VIEW", deleteButtonFunction: " ",
        }
    },
    "FxgainlossReport": {
        exportURL: {
            excelUrl: 'FXGainLossV2/downloadFXGainLossRecords/',
            pdfUrl: 'FXGainLossV2/downloadFXGainLossRecordsPdf',
        },
        moduleName: "",
    },
    "reviewChartOfAccounts": {
        headers: "assets/headers/settings-header/accountingSetting/reviewCoa.JSON",
        gridDataUrl: "financialDataIntegration/records/",
        gridDeleteUrl: "deleteController/",
        moduleName: "reviewChartofAccount",
        filterUrl: { url: "financialDataIntegration/filterByKeys" },
        gridExportUrl: { url: "financialDataIntegration/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "financialDataIntegration/downloadFilterRecords/", isZoneName: false },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "None",
        routeUrl: { create: "", upload: "/setting/review-chart-of-accounts-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/reviewCoaUpload',
            uploadUrl: "financialDataIntegration/allCoaUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/reviewCoaUpload",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/reviewCoaUpload/", name: "review_coa", sampleName: "reviewcoademo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPLOAD",
            viewButtonFunction: "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_VIEW", deleteButtonFunction: "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_DELETE",
        }
    },
    "activityUploadReview": {
        headers: "assets/headers/activity-upload-review/activity-review-upload.JSON",
        gridDataUrl: "financialDataIntegration/activityRecords/",
        moduleName: "clientActivity",
        filterUrl: { url: "financialDataIntegration/filterActivityByKeys" },
        gridExportUrl: { url: "financialDataIntegration/downloadActivityRecords/", isZoneName: false },
        gridFilteredUrl: { url: "financialDataIntegration/downloadFilterActivityRecords/", isZoneName: false },
        viewUrl: "None",
        routeUrl: { create: "", upload: "/activity-upload-review/upload-activity-upload-review" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/activityUpload',
            uploadUrl: "financialDataIntegration/activityUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/activityUpload",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/activityUpload/", name: "activityUpload", sampleName: "activityuploaddemo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: " ", uploadButtonFunction: "SEAMLESS_ACITIVITIES_CLIENT_UPLOAD",
            viewButtonFunction: "SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT", deleteButtonFunction: "SEAMLESS_SIDEMENU_ACITIVITIES_CLIENT",
        }
    },
    "setupcustodianList": {
        pathName: 'custodians',
        headers: "assets/headers/manager-headers/setupcustodian.JSON",
        gridDataUrl: "brokerAccountV2/records/",///needs to change true/false once we add same on systemmanager lvl
        // gridDeleteUrl: "brokerAccount/", no delete 
        moduleName: "brokerAccount",
        fileName: "custodianListFiltered",
        filterUrl: { url: "brokerAccountV2/filterByKeys" },
        gridExportUrl: { url: "brokerAccountV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "brokerAccountV2/downloadFilterRecords", isZoneName: false },
        routeUrl: { create: "/setup/custodianst-entry/create", upload: "/setup/custodians-upload" },
        uploadDetails: {
            uploadUrl: "brokerAccountV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/custodian",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/custodian/", name: "Custodian ", sampleName: "custodiandemo"
        },
        viewUrl: "None",
        systemAdminViewUrl: "/systemmanager/custodians/view",
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION,SMPRIVATEFUND_VIEW",
        }
    },
    "setupperformanceBasedFee": {
        headers: "assets/headers/manager-headers/setupManagerPerfBasedFeeData.JSON",
        gridDataUrl: "managerPerfBasedFeeV2/records/",
        gridDeleteUrl: "managerPerfBasedFee/",
        moduleName: "managerPerfBasedFee",
        gridExportUrl: { url: "managerPerfBasedFeeV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "managerPerfBasedFeeV2/filterByKeys" },
        gridFilteredUrl: { url: "managerPerfBasedFeeV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "None",
        routeUrl: { create: "/setup/performanc-based-fee-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "MANAGERPERFBASEDFEE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "MANAGERPERFBASEDFEE_VIEW", deleteButtonFunction: "MANAGERPERFBASEDFEE_DELETE",
        }
    },
    "setupInvestorsList": {
        pathName: 'investor',
        headers: "assets/headers/manager-headers/setupinvestor.JSON",
        gridDataUrl: "investorV2/records/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "investor/",
        moduleName: "investor",
        filterUrl: { url: "investorV2/filterByKeys/" },
        gridExportUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/investors/", orderFilter: false, isZoneName: true },
        sysAdminGridExportUrl: { url: "investor/downloadRecords/", isZoneName: false },
        SystemAdminGridFilteredUrl: { url: "investor/downloadFilterRecords/", orderFilter: false, isZoneName: true },
        updateUrl: 'investorV2/update',
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/investors/investor-entry/create", upload: "/investors/investor-upload" },
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "investordemo", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/investors/view",
        authFunctions: {
            newButtonFunction: "INVESTOR_CREATE", uploadButtonFunction: "INVESTOR_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "INVESTOR_DELETE",
            updateButtonFunction: "INVESTOR_UPDATE"
        }
    },
    "duplicateSetupInvestorsList": {
        pathName: 'investor',
        method: 'post',
        headers: "assets/headers/manager-headers/duplicateSetupInvestorsList.JSON",
        gridDataUrl: "investor/fetchAllInvestors/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "investor/",
        moduleName: "investor",
        filterUrl: { url: "investor/filterByKeys" },
        gridExportUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/investors/", orderFilter: false, isZoneName: true },
        sysAdminGridExportUrl: { url: "investor/downloadRecords/", isZoneName: false },
        SystemAdminGridFilteredUrl: { url: "investor/downloadFilterRecords/", orderFilter: false, isZoneName: true },
        updateUrl: 'investorV2/update',
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/setup/investor-entry/create", upload: "/setup/investor-upload" },
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "investordemo", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/investors/view",
        authFunctions: {
            newButtonFunction: "INVESTOR_CREATE", uploadButtonFunction: "INVESTOR_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "INVESTOR_DELETE",
            updateButtonFunction: "INVESTOR_UPDATE"
        }
    },
    "duplicaterapidInvestors": {
        pathName: 'investor',
        method: 'post',
        headers: "assets/headers/manager-headers/duplicateSetupInvestorsList.JSON",
        gridDataUrl: "investor/fetchAllInvestors/",///needs to change true/false once we add same on systemmanager lvl
        gridDeleteUrl: "investor/",
        moduleName: "investor",
        filterUrl: { url: "investor/filterByKeys" },
        gridExportUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/investors/", orderFilter: false, isZoneName: true },
        sysAdminGridExportUrl: { url: "investor/downloadRecords/", isZoneName: false },
        SystemAdminGridFilteredUrl: { url: "investor/downloadFilterRecords/", orderFilter: false, isZoneName: true },
        updateUrl: 'investorV2/update',
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/setup/investor-entry/create", upload: "/setup/investor-upload" },
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "investordemo", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/investors/view",
        authFunctions: {
            newButtonFunction: "INVESTOR_CREATE", uploadButtonFunction: "INVESTOR_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "INVESTOR_DELETE",
            updateButtonFunction: "INVESTOR_UPDATE"
        }
    },
    "setupAssestBasedFee": {
        headers: "assets/headers/manager-headers/setupAssetBasedFeeData.JSON",
        gridDataUrl: "investManagerV2/records/",
        gridDeleteUrl: "investManager/",
        moduleName: "investManager",
        gridExportUrl: { url: "investManagerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "investManagerV2/filterByKeys" },
        gridFilteredUrl: { url: "investManagerV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "None",
        routeUrl: { create: "/setup/asset-based-fee-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "ASSETBASEDFEE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "ASSETBASEDFEE_VIEW", deleteButtonFunction: "ASSETBASEDFEE_DELETE",
        }
    },
    "tradeAdvisorList": {
        entCard: 'manager',
        headers: "assets/headers/manager-headers/tradingAdvisor.JSON",
        gridDataUrl: "tradeAdvisorV2/records/",
        gridDeleteUrl: "tradeAdvisor/",
        moduleName: "tradeAdvisor",
        fileName: "TradeAdvisorListFiltered",
        filterUrl: { url: "tradeAdvisorV2/filterByKeys" },
        fetchCustomTypeUrl: "tradeAdvisorV2/fetchAllRecords",
        gridExportUrl: { url: "tradeAdvisorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "tradeAdvisorV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "/legacy/trading-advisor/view",
        routeUrl: { create: "/legacy/trading-advisor/create" },
        authFunctions: {
            newButtonFunction: "TRADEADVISOR_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_SIDEMENU_LEGACY_ALL_BUTTONS",
        }
    },
    "connectUserFundList": {
        entCard: 'mapping',
        pathName: 'connect_user_fund',
        headers: "assets/headers/manager-headers/connectUserFund.JSON",
        gridDataUrl: "associationOfUserFundV2/records/",
        gridDeleteUrl: "associationOfUserFund/",
        moduleName: "associationOfUserFund",
        fileName: "User-Fund-Mapping-Filtered",
        filterUrl: { url: "associationOfUserFundV2/filterByKeys" },
        gridExportUrl: { url: "associationOfUserFundV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "associationOfUserFundV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/manager/connect-user-fund/view",
        routeUrl: { create: "/manager/connect-user-fund/create", createSuper: "/manager/connect-super-user-fund-entry/create", upload: "/manager/connect-user-fund/upload" },
        uploadDetails: {
            uploadUrl: "associationOfUserFundV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/associationOfUserFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/associationOfUserFund/", name: "connect_user_fund", sampleName: "associationofuserfunddemo", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "FUNDCLASS_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN,USER_CREATE,USER_VIEW",
        }
    },
    "allsmupload": {
        entCard: 'others',
        pathName: 'all_sm_upload',
        moduleName: 'allsmupload',
        uploadDetails: {
            uploadUrl: "AllSMDownload/uploadAllSMV2/SMFutures/allSMs?smUploadConfigType=Default", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/allSMs",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/allSMs/", name: "all_sm_upload", sampleName: "allsmupload", dataStringify: true
        }
    },

    "FundList": {
        pathName: 'funds_client_accounts',
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/fundClientAccount.JSON",
        gridDataUrl: "fundV2/records/",
        gridDeleteUrl: "fund/delete/",
        moduleName: "fund",
        fileName: "FundListFiltered",
        filterUrl: { url: "fundV2/filterByKeys" },
        fetchCustomTypeUrl: "dataConfigurationV2/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "fundV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "fundV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "/manager/fund-client-account/view",
        viewEntitiesUrl: "/Entities/fund-client-account/view",
        routeUrl: { create: "/manager/fund-client-account/create", upload: "/manager/fund-client-account/upload" },
        uploadDetails: {
            uploadUrl: "fundV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fundClient",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fundClient/", name: "fund_client", sampleName: "funddemo", dataStringify: true,
        },
        systemAdminViewUrl: "/systemmanager/fund-client-account-list/view",
        authFunctions: {
            newButtonFunction: "FUND_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "FUND_VIEW", deleteButtonFunction: "FUND_DELETE",
        }
    },

    "nonCustodianList": {
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/nonCustodianWorkflow.JSON",
        gridDataUrl: "custodianNotTagV2/records/",
        gridDeleteUrl: "custodianNotTag/",
        moduleName: "custodianSourceConfig",
        filterUrl: { url: "custodianNotTagV2/filterByKeys" },
        ///fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "custodianNotTagV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "custodianNotTagV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "/manager/non-custodian-workflow/view",
        routeUrl: { create: "/manager/non-custodian-workflow/create" },
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION",
        }
    },
    "investorsList": {
        pathName: 'investor',
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/investor.JSON",
        gridDataUrl: "investorV2/records/",
        gridDeleteUrl: "investor/",
        moduleName: "investor",
        filterUrl: { url: "investorV2/filterByKeys/" },
        gridExportUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        sysAdminGridExportUrl: { url: "investor/downloadRecords/", isZoneName: false },
        SystemAdminGridFilteredUrl: { url: "investor/downloadFilterRecords/", orderFilter: false, isZoneName: true },
        gridFilteredUrl: { url: "exportController/downloadFilter/investors/", orderFilter: false, isZoneName: true },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "/manager/investors/view",
        viewEntitiesUrl: "/Entities/investors/view",
        routeUrl: { create: "/manager/investors/create", upload: "/manager/investors/upload" },
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "investordemo", dataStringify: true
        },
        systemAdminViewUrl: "/systemmanager/investors/view",
        authFunctions: {
            newButtonFunction: "INVESTOR_CREATE", uploadButtonFunction: "INVESTOR_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "INVESTOR_DELETE",
        }
    },

    "checkerPendingRequest": {
        entCard: 'automated_report_delivery_log',
        headers: "assets/headers/manager-headers/checker-pending-request.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/checker/pendingWorkflow/",
        //gridDeleteUrl: "fund/delete/",
        moduleName: "checkerPendingRequest",
        filterUrl: { url: "reportDeliveryWorkflowV2/filterWorkflowByKeys" },
        // gridExportUrl: { url: "tradeEntry/downloadRecords/", isZoneName: true },
        // gridFilteredUrl: { url: "tradeEntry/downloadFilterRecords/", isZoneName: true },
        //newButtonFunction: "TRADEENTRY_CREATE",
        deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_AUTOMATEREPORTDELIVERYLOG",
        viewUrl: "/manager/checker-pending-request-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_AUTOMATEREPORTDELIVERYLOG",
        }
        // routeUrl: { create: "/viewmanager/manager/manual-trade-entry/create", upload: "/trades/tradeupload" },
        // uploadDetails: {
        //     uploadUrl: "tradeEntry/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "Trades", sampleName: "trade", dataStringify: true
        // }
    },
    "reportNotificationStatusList": {
        entCard: 'automated_report_delivery_log',
        headers: "assets/headers/manager-headers/report-notification-status.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/",
        gridDeleteUrl: "reportDeliveryConfigurationV2/configurations/",
        moduleName: "reportNotificationStatus",
        filterUrl: { url: "reportDeliveryWorkflowV2/" },
        gridExportUrl: { url: "reportDeliveryWorkflowV2/", isZoneName: false },
        gridFilteredUrl: { url: "reportDeliveryWorkflowV2/", isZoneName: false },
        //deleteButtonFunction: "none",
        viewUrl: "/manager/report-notification-status-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "ENT_REPORTNOTIFICATIONSTATUS,FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN,SEAMLESS_ISD_NOTIFICATION_STATUS,SEAMLESS_ISD_GROUP_APPROVAL_FUND_LIST,SMPRIVATEFUND_VIEW,CASPINOFF_VIEW",
        }
    },
    "doc-managementList": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/doc-management.JSON",
        gridDataUrl: "documentManagementV2/fetchAllRecords/",
        gridDeleteUrl: "documentManagement/",
        moduleName: "documentManagement", //doc-managementList documentManagement
        filterUrl: { url: "documentManagementV2/filterByKeys" },
        // fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        // gridExportUrl: { url: "tradeEntry/downloadRecords/", isZoneName: true },
        // gridFilteredUrl: { url: "tradeEntry/downloadFilterRecords/", isZoneName: true },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "",
        // routeUrl: { create: "/trades/manual-trade-entry/create", upload: "/trades/tradeupload" },
        // uploadDetails: {
        //     uploadUrl: "tradeEntry/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "Trades", sampleName: "trade", dataStringify: true
        // }
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SETTING_CONFIG_DOC_MANAGEMENT,DOCUMENTMANAGEMENT_VIEW,SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION,USER_CREATE",
        }
    },
    "ror-config-List": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/config-ror.JSON",
        gridDataUrl: "rorConfigV2/records/",
        gridDeleteUrl: "rorConfig/",
        moduleName: "rorConfigDto",
        filterUrl: { url: "rorConfigV2/filterByKeys" },
        gridExportUrl: { url: "rorConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "rorConfigV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "/manager/ror-config/view",
        routeUrl: { create: "/manager/ror-config/create" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION",
        }
        // uploadDetails: {
        //     uploadUrl: "tradeEntry/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "Trades", sampleName: "trade", dataStringify: true
        // }
    },
    "alertThresholdLogManager": {
        headers: "assets/headers/system-manager-headers/alertThresholdLog.JSON",
        gridDataUrl: "alertThresholdV2/getRuleExecutionLogs/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "alertThresholdLogManager",
        filterUrl: { url: "alertThresholdV2/filterRecordsByKeyForManager" },
        gridExportUrl: { url: "alertThresholdV2/generateAlertThresholdLogsCSV/", isZoneName: false },
        gridFilteredUrl: { url: "alertThresholdV2/generateFilteredAlertThresholdLogsCSV/", isZoneName: false },
        viewUrl: "/trades/trade-allocation-config/view",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "SMEQUITIESOPTIONS_VIEW,AlertThresholdLog_VIEW",
        }
    },
    "reportNotificationStatus": {
        headers: "assets/headers/system-manager-headers/reportNotificationStatus.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/allworkFlow",
        gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "reportNotificationStatus",
        deleteButtonFunction: 'null',
        filterUrl: { url: "reportDeliveryWorkflowV2/filterByKeys" },
        gridExportUrl: { url: "reportDeliveryWorkflowV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "reportDeliveryWorkflowV2/downloadFilterRecords/", isZoneName: false },
        //routeUrl: { create: "/trades/trade-allocation-config/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/trades/trade-allocation-config/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "ENT_REPORTNOTIFICATIONSTATUS,FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN,SEAMLESS_ISD_NOTIFICATION_STATUS,SEAMLESS_ISD_GROUP_APPROVAL_FUND_LIST,SMPRIVATEFUND_VIEW,CASPINOFF_VIEW",
        }
    },
    "sendMailToInvestorGrid": {
        headers: "assets/headers/system-manager-headers/sendMailToInvestorGrid.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/allworkFlow",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "sendMailToInvestorGrid",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_ISD_NOTIFICATION_STATUS",
        }
    },

    "allUsers": {
        headers: "assets/headers/system-manager-headers/allUsers.JSON",
        gridDataUrl: "userV2/allrecords/",
        gridDeleteUrl: "user/",
        moduleName: "user",
        filterUrl: { url: "userV2/filterSysAllByKeys" },
        gridExportUrl: { url: "userV2/fetchAllUserRecords/", isZoneName: false },
        gridFilteredUrl: { url: "userV2/fetchAllUserRecords/", isZoneName: false },
        //routeUrl: { create: "/trades/trade-allocation-config/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/all-users-list/view",
        viewEntitiesUrl: "/Entities/all-users-list/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "SYSADMIN_USER_DELETE",
        }

    },
    "connectUserAndFund": {
        entCard: 'enterprise_configuration',
        headers: "assets/headers/system-manager-headers/connectUserAndFund.JSON",
        gridDataUrl: "associationOfUserFundV2/records/",
        // gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "associationOfUserFund",
        filterUrl: { url: "associationOfUserFundV2/filterByKeys" },
        fileName: "User-Fund-Mapping-Filtered",
        gridExportUrl: { url: "associationOfUserFundV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "associationOfUserFundV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/connect-user-fund/create", upload: "/trades/tradeAllocationConfigUpload" },
        viewUrl: "/systemmanager/connect-user-fund/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN,USER_CREATE,USER_VIEW",
        }
    },
    "taxClassification": {
        pathName: 'tax_classification',
        headers: "assets/headers/system-manager-headers/taxClassifiction.JSON",
        gridDataUrl: "taxClassificationV2/records/",
        gridDeleteUrl: "taxClassification/",
        moduleName: "taxClassification",
        filterUrl: { url: "taxClassificationV2/filterByKeys" },
        gridExportUrl: { url: "taxClassificationV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxClassificationV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/tax-classification/create", upload: "/systemmanager/tax-classification-upload" },
        viewUrl: "/systemmanager/tax-classification/view",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/taxClassification',
            uploadUrl: "taxClassificationV2/uploadTaxClassification", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxClassification",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxClassification/", name: "tax_classification", sampleName: "taxclassificationdemo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SYSADMIN_TAXATION_TAXCLASSIFICATION_CREATE", uploadButtonFunction: "SYSADMIN_TAXATION_TAXCLASSIFICATION_UPLOAD",
            viewButtonFunction: "SYSADMIN_TAXATION_TAXCLASSIFICATION_VIEW", deleteButtonFunction: "None",
        }

    },
    "mappingDefaultCoaTaxClassification": {
        pathName: 'mapping-default-coa-tax-classification',
        headers: "assets/headers/system-manager-headers/mappingDefaultCoaTaxClassification.JSON",
        gridDataUrl: "defaultCOATaxClassification/records/",
        moduleName: "mappingDefaultCoaTaxClassification",
        filterUrl: { url: "defaultCOATaxClassification/filterByKeys" },
        gridExportUrl: { url: "defaultCOATaxClassification/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "defaultCOATaxClassification/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/systemmanager/mapping-default-coa-tax-classification/view",
        routeUrl: { create: "/systemmanager/mapping-default-coa-tax-classification/create" },
        authFunctions: {
            newButtonFunction: "SYSADMIN_TAXATION_DEFAULTCOATAXCLASSIFICATION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SYSADMIN_TAXATION_DEFAULTCOATAXCLASSIFICATION_VIEW", deleteButtonFunction: "None",
        }

    },
    "PartnershipPassthroughConfigurationList": {
        pathName: 'partnership-passthrough-configuration',
        headers: "assets/headers/system-manager-headers/PartnershipPassthroughConfiguration.JSON",
        gridDataUrl: "taxationPassThroughConfiguration/records/",
        moduleName: "taxationPassThroughConfiguration",
        filterUrl: { url: "taxationPassThroughConfiguration/filterByKeys" },
        gridExportUrl: { url: "taxationPassThroughConfiguration/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxationPassThroughConfiguration/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/systemmanager/partnership-passthrough-configuration/view",
        routeUrl: { create: "/systemmanager/partnership-passthrough-configuration/create" },
        authFunctions: {
            newButtonFunction: "SYSADMIN_TAXATION_PASSTHROUGHCONFIGURATION_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "SYSADMIN_TAXATION_PASSTHROUGHCONFIGURATION_VIEW", deleteButtonFunction: "SYSADMIN_TAXATION_PASSTHROUGHCONFIGURATION_DELETE",
            updateButtonFunction: "SYSADMIN_TAXATION_PASSTHROUGHCONFIGURATION_UPDATE"
        }
    },
    "chartOfAccounttaxClassification": {
        pathName: 'tax_classification',
        headers: "assets/headers/system-manager-headers/chartOfAccounttaxClassifiction.JSON",
        gridDataUrl: "taxClassificationV2/records/",
        gridDeleteUrl: "taxClassification/",
        moduleName: "taxClassification",
        method: 'post',
        filterUrl: { url: "taxClassificationV2/filterByKeys" },
        gridExportUrl: { url: "taxClassificationV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxClassificationV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/tax-classification/create", upload: "/systemmanager/tax-classification-upload" },
        viewUrl: "/systemmanager/tax-classification/view",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/taxClassification',
            uploadUrl: "taxClassificationV2/uploadTaxClassification", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxClassification",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxClassification/", name: "tax_classification", sampleName: "taxclassificationdemo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SYSADMIN_OTHERS_TAXCLASSIFICATION_CREATE", uploadButtonFunction: "SYSADMIN_OTHERS_TAXCLASSIFICATION_CREATE",
            viewButtonFunction: "SYSADMIN_OTHERS_TAXCLASSIFICATION_VIEW", deleteButtonFunction: "None",
        }

    },
    "defaultChartOfAccounts": {
        headers: "assets/headers/system-manager-headers/defaultChartOfAccount.JSON",
        gridDataUrl: "defaultChartOfAccounts/records/",
        gridDeleteUrl: "defaultChartOfAccounts/",
        moduleName: "defaultChartOfAccounts",
        gridExportUrl: { url: "defaultChartOfAccounts/downloadRecords/", isZoneName: false },
        filterUrl: { url: "defaultChartOfAccounts/filterByKeys" },
        gridFilteredUrl: { url: "defaultChartOfAccounts/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/loadDefaultChartOfAccounts',
            uploadUrl: "defaultChartOfAccounts/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/loadDefaultChartOfAccounts",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/loadDefaultChartOfAccounts/", name: "default_chartOfAccount", sampleName: "defaultchartofaccountsdemo", dataStringify: true
        },
        viewUrl: "/systemmanager/default-chart-of-accounts/view",
        routeUrl: { create: "/systemmanager/default-chart-of-accounts/create", upload: "/systemmanager/default-chart-of-account-upload" },
        authFunctions: {
            newButtonFunction: "SYSADMIN_OTHERS_DEFAULT_CHART_OF_ACCOUNTS_CREATE", uploadButtonFunction: "SYSADMIN_OTHERS_DEFAULT_CHART_OF_ACCOUNTS_CREATE",
            viewButtonFunction: "SYSADMIN_OTHERS_DEFAULT_CHART_OF_ACCOUNTS", deleteButtonFunction: "None",
        }
    },
    "modules": {
        headers: "assets/headers/system-manager-headers/modules.JSON",
        gridDataUrl: "moduleV2/records/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "module",
        filterUrl: { url: "moduleV2/filterByKeys" },
        //gridExportUrl: { url: "/formidium/tradeEntry/downloadOrderList/", isZoneName: false },
        //gridFilteredUrl: { url: "tradeEntry/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/modules-list/create" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/modules-list/view",
        authFunctions: {
            newButtonFunction: "MODULE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "MODULE_CREATE", deleteButtonFunction: "MODULE_DELETE",
        }
    },
    "dataImportStatusManager": {
        headers: "assets/headers/system-manager-headers/dataImport.JSON",
        gridDataUrl: "systemManager/alteryx/filterWorkflowByKeys?", //systemManager/alteryx/fetchAllWorklowStatus/
        method: 'post',
        getCustomTypeUrl: "systemManager/alteryx/fetchAllWorklowStatus/", //systemManager/alteryx/filterWorkflowByKeys
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "dataImportStatusManager",
        filterUrl: { url: "systemManager/alteryx/filterWorkflowByKeys" },
        gridExportUrl: { url: "systemManager/alteryxV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "systemManager/alteryxV2/downloadFilterRecords/", isZoneName: true },
        //routeUrl: { create: "/trades/trade-allocation-config-entry/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "systemManager/alteryx/fetchAllWorklowStatus/",
        authFunctions: {
            newButtonFunction: "ENTERPRISE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "BROKERACCOUNT_VIEW", deleteButtonFunction: "ENTERPRISE_DELETE",
        }
    },
    "domainConfiguration": {
        headers: "assets/headers/system-manager-headers/domainConfiguration.JSON",
        gridDataUrl: "domainConfigurationV2/records/",
        gridDeleteUrl: "domainConfiguration/",
        moduleName: "domainConfiguration",
        filterUrl: { url: "domainConfigurationV2/filterBySearchCriteria" },
        gridExportUrl: { url: "domainConfigurationV2/download/", isZoneName: false },
        gridFilteredUrl: { url: "domainConfigurationV2/downloadFilter/", isZoneName: false },
        routeUrl: { create: "/systemmanager/domain-configuration/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/domain-configuration/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "ENTERPRISE_VIEW",
        }
    },
    "enterprises": {
        headers: "assets/headers/system-manager-headers/enterprises.JSON",
        gridDataUrl: "enterpriseV2/records/0",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "enterprise",
        filterUrl: { url: "enterpriseV2/filterByKeys" },
        //gridExportUrl: { url: "/formidium/tradeEntry/downloadOrderList/", isZoneName: false },
        //gridFilteredUrl: { url: "tradeEntry/downloadFilterRecords/", isZoneName: false },
        routeUrl: {
            create: "/systemmanager/enterprises-list/create",
            createEntities: "/Entities/enterprises-list/create",
            upload: "/trades/tradeAllocationConfigUpload"
        },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/enterprises-list/view",
        viewEntitiesUrl: "/Entities/enterprises-list/view",
        authFunctions: {
            newButtonFunction: "ENTERPRISE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "ENTERPRISE_CREATE", deleteButtonFunction: "ENTERPRISE_DELETE",
        }
    },
    "functions": {
        headers: "assets/headers/system-manager-headers/functions.JSON",
        gridDataUrl: "functionV2/records/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "function",
        filterUrl: { url: "functionV2/filterByKeys" },
        gridExportUrl: { url: "functionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "functionV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/functions-list/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/functions-list/view",
        authFunctions: {
            newButtonFunction: "FUNCTION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "FUNCTION_CREATE", deleteButtonFunction: "FUNCTION_DELETE",
        }
    },
    "roles": {
        headers: "assets/headers/system-manager-headers/roles.JSON",
        gridDataUrl: "roleV2/records/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "role",
        filterUrl: { url: "roleV2/filterByKeys" },
        //gridExportUrl: { url: "/formidium/tradeEntry/downloadOrderList/", isZoneName: false },
        //gridFilteredUrl: { url: "tradeEntry/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/roles-list/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/roles-list/view",
        authFunctions: {
            newButtonFunction: "ROLE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "ROLE_CREATE", deleteButtonFunction: "ROLE_DELETE",
        }
    },
    "sftpConfiguration": {
        headers: "assets/headers/system-manager-headers/sftp.JSON",
        gridDataUrl: "sftpDetailV2/records/",
        gridDeleteUrl: "sftpDetailV2/delete",
        moduleName: "sFTPDetails",
        filterUrl: { url: "sftpDetailV2/filterByKeys" },
        gridExportUrl: { url: "sftpDetailV2/exportCSV/", isZoneName: false },
        gridFilteredUrl: { url: "sftpDetailV2/exportFilteredRecords", isZoneName: false },
        routeUrl: { create: "/systemmanager/sftp-configuration/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/sftp-configuration/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN",
        }

    },
    "sourceConfiguration": {
        headers: "assets/headers/system-manager-headers/source.JSON",
        gridDataUrl: "systemManager/alteryx/sourceConfigurationV2/records/",
        gridDeleteUrl: "systemManager/alteryx/sourceConfigurationV2/delete/",
        moduleName: "sourceConfiguration",
        filterUrl: { url: "systemManager/alteryx/sourceConfigurationV2/filterByKeys" },
        gridExportUrl: { url: "systemManager/alteryx/sourceConfigurationV2/exportCSV/", isZoneName: false },
        gridFilteredUrl: { url: "systemManager/alteryx/sourceConfigurationV2/exportFilteredRecords", isZoneName: false },
        routeUrl: { create: "/systemmanager/source-configuration/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/source-configuration/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN",
        }
    },
    "auditLog": {
        headers: "assets/headers/system-manager-headers/ViewAuditLog.JSON",
        gridDataUrl: "auditLogControllerV2/records/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "auditLogController",
        filterUrl: { url: "auditLogControllerV2/filterByKeys" },
        gridExportUrl: { url: "auditLogControllerV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "auditLogControllerV2/downloadFilterRecords/", isZoneName: false },
        //routeUrl: { create: "/trades/trade-allocation-config-entry/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/trades/trade-allocation-config/view",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN",
        }
    },
    "currencies": {
        headers: "assets/headers/system-manager-headers/currencies.JSON",
        gridDataUrl: "currencyV2/records/",
        gridDeleteUrl: "currency/",
        moduleName: "currency",
        filterUrl: { url: "currencyV2/filterByKeys" },
        gridExportUrl: { url: "currencyV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "currencyV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/currencies/create" },
        viewUrl: "/systemmanager/currencies/view",
        authFunctions: {
            newButtonFunction: "CURRENCY_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "CURRENCY_VIEW", deleteButtonFunction: "CURRENCY_DELETE",
        }

    },
    "currenciesForSetting": {
        headers: "assets/headers/system-manager-headers/currencies.JSON",
        gridDataUrl: "currencyV2/records/",
        gridDeleteUrl: "currency/",
        moduleName: "currency",
        filterUrl: { url: "currencyV2/filterByKeys" },
        gridExportUrl: { url: "currencyV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "currencyV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/setting/currencies-entry/create" },
        viewUrl: "/setting/currencies-entry/view",
        authFunctions: {
            newButtonFunction: "CURRENCY_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "CURRENCY_VIEW", deleteButtonFunction: "CURRENCY_DELETE",
        }
    },
    "regulators": {
        headers: "assets/headers/system-manager-headers/regulators.JSON",
        gridDataUrl: "securityRegulatorV2/records/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "securityRegulator",
        filterUrl: { url: "securityRegulatorV2/filterByKeys" },
        gridExportUrl: { url: "securityRegulatorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "securityRegulatorV2/downloadFilterSecurityRegulator/", isZoneName: false },
        routeUrl: { create: "/systemmanager/regulators/create" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/regulators/view",
        authFunctions: {
            newButtonFunction: "SECURITYREGULATOR_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SECURITYREGULATOR_VIEW", deleteButtonFunction: "SECURITYREGULATOR_DELETE",
        }
    },
    "regulatorsForSetting": {
        headers: "assets/headers/system-manager-headers/regulators.JSON",
        gridDataUrl: "securityRegulatorV2/records/",
        //gridDeleteUrl: "tradeAllocationConfig/",
        moduleName: "securityRegulator",
        filterUrl: { url: "securityRegulatorV2/filterByKeys" },
        gridExportUrl: { url: "securityRegulatorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "securityRegulatorV2/downloadFilterSecurityRegulator/", isZoneName: false },
        routeUrl: { create: "/setting/regulators-entry/create" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/setting/regulators-entry/view",
        authFunctions: {
            newButtonFunction: "SECURITYREGULATOR_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SECURITYREGULATOR_VIEW", deleteButtonFunction: "SECURITYREGULATOR_DELETE",
        }
    },
    "workflowManagement": {
        headers: "assets/headers/system-manager-headers/workflow.JSON",
        gridDataUrl: "alteryxWorkflowMngtV2/records/",
        gridDeleteUrl: "alteryxWorkflowMngtV2/delete/",
        moduleName: "alteryxWorkflowMngt",
        filterUrl: { url: "alteryxWorkflowMngtV2/filterByKeys" },
        gridExportUrl: { url: "alteryxWorkflowMngtV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "alteryxWorkflowMngtV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/workflow-management/create", upload: "/trades/tradeAllocationConfigUpload" },
        // uploadDetails: {
        //  uploadUrl: "tradeAllocationConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradeAllocationConfig",
        //  getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "Trade Allocation Config",
        //  resName: "failedTradeAllocationConfig", sampleName: "tradeallocationconfig"
        // },
        viewUrl: "/systemmanager/workflow-management/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN",
        }
    },
    "workflowMapped": {
        headers: "assets/headers/system-manager-headers/workflowMapped.JSON",
        gridDataUrl: "systemManager/workflowUserMapping/records/",
        moduleName: "workflowMapped",
        filterUrl: { url: "systemManager/workflowUserMapping/filterByKeys" },
        gridExportUrl: { url: "systemManager/workflowUserMapping/exportCSV/", isZoneName: false },
        gridFilteredUrl: { url: "systemManager/workflowUserMapping/exportFilteredRecords", isZoneName: false },
        viewUrl: "/systemmanager/trade-allocation-config/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN",
        }
    },
    "toolbarView": {
        headers: "assets/headers/toolbar/toolbarView.JSON",
        // gridDataUrl: "tradeEntry/orderRecords/",
        // gridDeleteUrl: "tradeEntry/",
        moduleName: "ViewmoduleModule",
        // gridExportUrl: { url: "tradeEntry/downloadOrderList/", isZoneName: false },
        // filterUrl: { url: "tradeEntry/filterByKeys", orderFilter: true },
        // gridFilteredUrl: { url: "tradeEntry/downloadFilterOrderList/", isZoneName: false },
        // viewUrl: "/trades/trade-list/view",
        routeUrl: { view: "/views/viewmodule/viewmodule.module" },
    },
    "toolbarUpload": {
        headers: "assets/headers/toolbar/toolbarUpload.JSON",
    },
    "toolbarCreate": {
        headers: "assets/headers/toolbar/toolbarCreate.JSON",
    },
    "admintoolbarCreate": {
        headers: "assets/headers/toolbar/managerstoolbarCreate.JSON",
    },
    "admintoolbarView": {
        headers: "assets/headers/toolbar/managertoolbarView.JSON",
    },
    "enttoolbarCreate": {
        headers: "assets/headers/toolbar/enttoolbarCreate.JSON",
    },
    "ent&usertoolbarCreate": {
        headers: "assets/headers/toolbar/ent&usertoolbarCreate.JSON",
    },
    "ent&usertoolbarUpload": {
        headers: "assets/headers/toolbar/ent&usertoolbarUpload.JSON",
    },
    "enttoolbarView": {
        headers: "assets/headers/toolbar/enttoolbarView.JSON",
    },
    "ent&usertoolbarView": {
        headers: "assets/headers/toolbar/ent&usertoolbarView.JSON",
    },
    "enttoolbarUpload": {
        headers: "assets/headers/toolbar/enttoolbarUpload.JSON",
    },
    "reportDeliveryConfig": {
        headers: "assets/headers/settings-header/Configuration/report-delivery-config.JSON",
        gridDataUrl: "reportDeliveryConfiguration/configurationsV2/records/",
        gridDeleteUrl: "reportDeliveryConfiguration/configurationsV2/",
        moduleName: "reportDeliveryConfiguration",
        filterUrl: { url: "reportDeliveryConfiguration/configurationsV2/filterByKeys" },
        gridExportUrl: { url: "reportDeliveryConfiguration/configurationsV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "reportDeliveryConfiguration/configurationsV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/setting/report-delivery-config-entry/create", upload: "/setting/report-delivery-config-upload" },
        viewUrl: "/setting/report-delivery-config-entry/view",
        uploadDetails: {
            uploadUrl: "reportDeliveryConfiguration/configurationsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/reportDeliveryConfiguration",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/reportDeliveryConfiguration/", name: "workflowconfig", sampleName: "workflowconfigdemo", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "countries": {
        headers: "assets/headers/settings-header/lookupTables/countryData.JSON",
        gridDataUrl: "countrymasterV2/records/",
        gridDeleteUrl: "countrymaster/",
        moduleName: "countryMaster",
        gridExportUrl: { url: "countrymasterV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "countrymasterV2/filterByKeys" },
        // filterDeleteUrl: { url: "countrymaster/filterByKeysDelete" },
        gridFilteredUrl: { url: "countrymasterV2/downloadFiltercountry/", isZoneName: false },
        viewUrl: "/setting/country-entry/view",
        routeUrl: { create: "/setting/country-entry/create", upload: "/view/portfolio/uploadSMequities" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },

    "calendar": {
        headers: "assets/headers/settings-header/lookupTables/calendarData.JSON",
        gridDataUrl: "calendarV2/records/",
        gridDeleteUrl: "calendar/",
        moduleName: "calendar",
        gridExportUrl: { url: "calendarV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "calendarV2/filterByKeys" },
        // filterDeleteUrl: { url: "countrymaster/filterByKeysDelete" },
        gridFilteredUrl: { url: "calendarV2/downloadFilterCalendar/", isZoneName: false },
        viewUrl: "/setting/calendar-entry/view",
        routeUrl: { create: "/setting/calendar-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "CALENDAR_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "CALENDAR_VIEW", deleteButtonFunction: "CALENDAR_DELETE",
        }
    },
    "exchanges": {
        headers: "assets/headers/settings-header/lookupTables/exchangeData.JSON",
        gridDataUrl: "exchangeV2/records/",
        gridDeleteUrl: "exchange/",
        moduleName: "exchange",
        gridExportUrl: { url: "exchangeV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "exchangeV2/filterByKeys" },
        // filterDeleteUrl: { url: "countrymaster/filterByKeysDelete" },
        gridFilteredUrl: { url: "exchangeV2/downloadFilterExchange/", isZoneName: false },
        viewUrl: "/setting/exchanges-entry/view",
        routeUrl: { create: "/setting/exchanges-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "EXCHANGE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "EXCHANGE_VIEW", deleteButtonFunction: "EXCHANGE_DELETE",
        }
    }
    ,
    "identifiers": {
        headers: "assets/headers/settings-header/lookupTables/securityIdentifiersData.JSON",
        gridDataUrl: "securityIdentifiersV2/records/",
        gridDeleteUrl: "securityIdentifiers/",
        moduleName: "securityIdentifiers",
        gridExportUrl: { url: "securityIdentifiersV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "securityIdentifiersV2/filterByKeys" },
        gridFilteredUrl: { url: "securityIdentifiersV2/downloadFilterIdentifiers/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "securityIdentifiersV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/securityIdentifiers",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/securityIdentifiers/", name: "Identifiers", sampleName: "securityidentifiersdemo", dataStringify: false
        },
        viewUrl: "/setting/identifiers-entry/view",
        routeUrl: { create: "/setting/identifiers-entry/create", upload: "/setting/identifiers-upload" },
        authFunctions: {
            newButtonFunction: "SECURITYIDENTIFIERS_CREATE", uploadButtonFunction: "SECURITYIDENTIFIERS_CREATE",
            viewButtonFunction: "SECURITYIDENTIFIERS_VIEW", deleteButtonFunction: "SECURITYIDENTIFIERS_DELETE",
        }
    },
    "configureNotes": {
        entCard: 'others',
        headers: "assets/headers/settings-header/CreateNew/configureNotes.JSON",
        gridDataUrl: "oathStatementCntlV2/records/",
        gridDeleteUrl: "oathStatementCntl/",
        moduleName: "oathStatement",
        gridExportUrl: { url: "oathStatementCntlV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "oathStatementCntlV2/filterByKeys" },
        gridFilteredUrl: { url: "oathStatementCntlV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/configure-notes-entry/view",
        routeUrl: { create: "/setting/configure-notes-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "CONFIGURENOTES_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "CONFIGURENOTES_VIEW", deleteButtonFunction: "CONFIGURENOTES_DELETE",
        }
    },
    "fundAddresses": {
        headers: "assets/headers/settings-header/CreateNew/fundAddress.JSON",
        gridDataUrl: "fundAddressV2/records/",
        gridDeleteUrl: "fundAddress/",
        moduleName: "fundAddress",
        gridExportUrl: { url: "fundAddressV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "fundAddressV2/filterByKeys" },
        gridFilteredUrl: { url: "fundAddressV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/fund-addresses-entry/view",
        routeUrl: { create: "/setting/fund-addresses-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "FUNDADDRESS_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "FUNDADDRESS_VIEW", deleteButtonFunction: "FUNDADDRESS_DELETE",
        }
    },
    "tradingPrograms": {
        headers: "assets/headers/settings-header/CreateNew/tradingProgram.JSON",
        gridDataUrl: "tradingProgramV2/records/",
        gridDeleteUrl: "tradingProgram/",
        moduleName: "tradingProgram",
        gridExportUrl: { url: "tradingProgramV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "tradingProgramV2/filterByKeys" },
        gridFilteredUrl: { url: "tradingProgramV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/trading-programs-entry/view",
        routeUrl: { create: "/setting/trading-programs-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRADINGPROGRAM_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRADINGPROGRAM_VIEW", deleteButtonFunction: "TRADINGPROGRAM_DELETE",
        }
    },
   
    "vendors": {
        headers: "assets/headers/settings-header/CreateNew/vendors.JSON",
        gridDataUrl: "vendorV2/records/",
        gridDeleteUrl: "vendorV2/",
        moduleName: "vendor",
        gridExportUrl: { url: "vendorV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "vendorV2/filterByKeys" },
        gridFilteredUrl: { url: "vendorV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "vendorV2/vendorUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/vendor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/vendor/", name: "vendor", sampleName: "vendorlistdemo", dataStringify: false
        },
        viewUrl: "vendor/vendors-entry/view",
        routeUrl: { create: "/vendor/vendors-entry/create", upload: "/vendor/vendors-upload" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_CREATE", uploadButtonFunction: "TRONIQ_VENDOR_UPLOAD",
            viewButtonFunction: "TRONIQ_VENDOR_VIEW", deleteButtonFunction: "TRONIQ_VENDOR_DELETE",
        }
    },
    "customer": {
        headers: "assets/headers/settings-header/CreateNew/customerList.JSON",
        gridDataUrl: "customerV2/records/",
        gridDeleteUrl: "customerV2/",
        moduleName: "customer",
        gridExportUrl: { url: "customerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "customerV2/filterByKeys" },
        gridFilteredUrl: { url: "customerV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "customerV2/customerUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/customer",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/customer/", name: "customer", sampleName: "customerlistdemo", dataStringify: false
        },
        viewUrl: "customer/customer-entry/view",
        routeUrl: { create: "/customer/customer-entry/create", upload: "/customer/customer-upload" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_CREATE", uploadButtonFunction: "TRONIQ_CUSTOMER_UPLOAD",
            viewButtonFunction: "TRONIQ_CUSTOMER_VIEW", deleteButtonFunction: "TRONIQ_CUSTOMER_DELETE",
        }
    },
    "redemptionFee": {
        headers: "assets/headers/settings-header/InvestorSetting/redemptionFees.JSON",
        gridDataUrl: "redemptionV2/records/",
        gridDeleteUrl: "redemption/",
        moduleName: "redemptionFee",
        gridExportUrl: { url: "redemptionV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "redemptionV2/filterByKeys" },
        gridFilteredUrl: { url: "redemptionV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/redemption-fees-entry/view",
        routeUrl: { create: "/setting/redemption-fees-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "REDEMPTION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "REDEMPTION_VIEW", deleteButtonFunction: "REDEMPTION_DELETE",
        }
    },
    "shareClasses": {
        headers: "assets/headers/settings-header/InvestorSetting/shareClasses.JSON",
        gridDataUrl: "shareClassV2/records/",
        gridDeleteUrl: "shareClass/",
        moduleName: "shareClass",
        gridExportUrl: { url: "shareClassV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "shareClassV2/filterByKeys" },
        gridFilteredUrl: { url: "shareClassV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/share-classes-entry/view",
        routeUrl: { create: "/setting/share-classes-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "FUNDCLASS_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "FUNDCLASS_VIEW", deleteButtonFunction: "FUNDCLASS_DELETE",
        }
    },
    "shareSeries": {
        headers: "assets/headers/settings-header/InvestorSetting/shareSeries.JSON",
        gridDataUrl: "shareSeriesV2/records/",
        gridDeleteUrl: "shareSeries/",
        moduleName: "shareSeries",
        gridExportUrl: { url: "shareSeriesV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "shareSeriesV2/filterByKeys" },
        gridFilteredUrl: { url: "shareSeriesV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/share-series-entry/view",
        routeUrl: { create: "/setting/share-series-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SHARESERIES_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SHARESERIES_VIEW", deleteButtonFunction: "SHARESERIES_DELETE",
        }
    },
    "closingMethods": {
        headers: "assets/headers/settings-header/PortfolioSetting/closingMethod.JSON",
        gridDataUrl: "taxlotClosingMethodV2/records/",
        gridDeleteUrl: "taxlotClosingMethod/",
        moduleName: "taxlotClosingMethod",
        gridExportUrl: { url: "taxlotClosingMethodV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "taxlotClosingMethodV2/filterByKeys" },
        gridFilteredUrl: { url: "taxlotClosingMethodV2/downloadFilterTaxlotClosingMethod/", isZoneName: false },
        viewUrl: "/setting/closing-methods-entry/view",
        routeUrl: { create: "/setting/closing-methods-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TAXLOTCLOSINGMETHOD_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TAXLOTCLOSINGMETHOD_VIEW", deleteButtonFunction: "TAXLOTCLOSINGMETHOD_DELETE",
        }
    },
    "riskMaster": {
        headers: "assets/headers/settings-header/PortfolioSetting/riskMaster.JSON",
        gridDataUrl: "riskMasterV2/records/",
        gridDeleteUrl: "riskMaster/",
        moduleName: "riskMaster",
        gridExportUrl: { url: "riskMasterV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "riskMasterV2/filterByKeys" },
        gridFilteredUrl: { url: "riskMasterV2/downloadFilterRiskMaster/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "riskMasterV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/riskMaster",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/riskMaster/", name: "risk_master", sampleName: "riskmasterdemo", dataStringify: false
        },
        viewUrl: "/setting/risk-master-entry/view",
        routeUrl: { create: "/setting/risk-master-entry/create", upload: "/setting/risk-master-upload" },
        authFunctions: {
            newButtonFunction: "RISKMASTER_CREATE", uploadButtonFunction: "RISKMASTER_CREATE",
            viewButtonFunction: "RISKMASTER_VIEW", deleteButtonFunction: "RISKMASTER_DELETE",
        }
    },
    "assetClass": {
        headers: "assets/headers/settings-header/trade/Assetclass.JSON",
        gridDataUrl: "assetClassV2/records/",
        gridDeleteUrl: "assetClass/",
        moduleName: "assetClass",
        gridExportUrl: { url: "assetClassV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "assetClassV2/filterByKeys" },
        gridFilteredUrl: { url: "assetClassV2/downloadFilterAssetClass/", isZoneName: false },
        viewUrl: "/setting/asset-class-entry/view",
        routeUrl: { create: "/setting/asset-class-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "ASSETCLASS_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "ASSETCLASS_VIEW", deleteButtonFunction: "ASSETCLASS_DELETE",
        }
    },
    "commission": {
        headers: "assets/headers/settings-header/trade/commission.JSON",
        gridDataUrl: "commissionV2/records/",
        gridDeleteUrl: "commission/",
        moduleName: "commission",
        gridExportUrl: { url: "commissionV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "commissionV2/filterByKeys" },
        gridFilteredUrl: { url: "commissionV2/downloadFilterCommission/", isZoneName: false },
        viewUrl: "/setting/commission-entry/view",
        routeUrl: { create: "/setting/commission-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "COMMISSION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "COMMISSION_VIEW", deleteButtonFunction: "COMMISSION_DELETE",
        }
    },
    "tradeAllocation": {
        headers: "assets/headers/settings-header/trade/TradeAllocation.JSON",
        gridDataUrl: "tradeAllocationV2/records/",
        gridDeleteUrl: "tradeAllocation/",
        moduleName: "tradeAllocation",
        gridExportUrl: { url: "tradeAllocationV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "tradeAllocationV2/filterByKeys" },
        gridFilteredUrl: { url: "tradeAllocationV2/downloadFilterTradeAllocation/", isZoneName: false },
        viewUrl: "/setting/trade-allocation-entry/view",
        routeUrl: { create: "/setting/trade-allocation-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRADEALLOCATION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRADEALLOCATION_VIEW", deleteButtonFunction: "TRADEALLOCATION_DELETE",
        }
    },
    "assestBasedFee": {
        headers: "assets/headers/settings-header/accountingSetting/assetBasedFeeData.JSON",
        gridDataUrl: "investManagerV2/records/",
        gridDeleteUrl: "investManager/",
        moduleName: "investManager",
        gridExportUrl: { url: "investManagerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "investManagerV2/filterByKeys" },
        gridFilteredUrl: { url: "investManagerV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/asset-based-fee-entry/view",
        routeUrl: { create: "/setting/asset-based-fee-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "ASSETBASEDFEE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "ASSETBASEDFEE_VIEW", deleteButtonFunction: "ASSETBASEDFEE_DELETE",
        }
    },
    "chartOfAccounts": {
        headers: "assets/headers/settings-header/accountingSetting/chartofaccounts.json",
        gridDataUrl: "chartOfAccountsV2/records/",
        gridDeleteUrl: "chartOfAccounts/",
        moduleName: "chartOfAccounts",
        gridExportUrl: { url: "chartOfAccountsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "chartOfAccountsV2/filterByKeys" },
        gridFilteredUrl: { url: "chartOfAccountsV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "chartOfAccountsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccounts",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccounts/", name: "chart_of_accounts", sampleName: "chartOfAccounts", dataStringify: false
        },
        viewUrl: "/setting/chart-of-accounts-entry/view",
        routeUrl: { create: "/setting/chart-of-accounts-entry/create", upload: "/setting/chart-of-accounts-upload" },
        authFunctions: {
            newButtonFunction: "CHARTOFACCOUNTS_CREATE", uploadButtonFunction: "CHARTOFACCOUNTS_CREATE",
            viewButtonFunction: "CHARTOFACCOUNTS_VIEW", deleteButtonFunction: "CHARTOFACCOUNTS_DELETE",
        }
    },
    "fschartOfAccounts": {
        headers: "assets/headers/settings-header/accountingSetting/chartofaccounts.json",
        gridDataUrl: "chartOfAccountsV2/records/",
        gridDeleteUrl: "chartOfAccounts/",
        moduleName: "chartOfAccounts",
        gridExportUrl: { url: "chartOfAccountsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "chartOfAccountsV2/filterByKeys" },
        gridFilteredUrl: { url: "chartOfAccountsV2/downloadFilterRecords/", isZoneName: false },

        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/chartOfAccountUpdate',
            uploadUrl: "chartOfAccountsV2/uploadFileAndUpdate", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccountUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccountUpdate/", name: "chart_of_accounts", sampleName: "fschartofaccounts", dataStringify: false
        },
        viewUrl: "/setting/chart-of-accounts-entry/view",
        routeUrl: { create: "/setting/chart-of-accounts-entry/create", upload: "/setting/chart-of-accounts-upload" },
        authFunctions: {
            newButtonFunction: "CHARTOFACCOUNTS_CREATE", uploadButtonFunction: "CHARTOFACCOUNTS_CREATE",
            viewButtonFunction: "CHARTOFACCOUNTS_VIEW", deleteButtonFunction: "CHARTOFACCOUNTS_DELETE",
        }
    },
    "chartOfAccountTaxUpdate": {
        moduleName: "chartOfAccountTaxUpdate",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/chartOfAccountTaxUpdate',
            uploadUrl: "chartOfAccountsV2/uploadFileAndUpdate", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccountTaxUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccountTaxUpdate/", name: "chart_of_accounts_update", sampleName: "chartOfAccountTaxUpdate", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "CHARTOFACCOUNTS_DELETE",
        }
    },
    "FSAutomation": {
        headers: "assets/headers/fs/FSAutomation.JSON",
        gridDataUrl: "fSConfig/records/false/",
        gridDeleteUrl: "fSConfig/deleteByConfigId",
        moduleName: "fSConfig",
        filterUrl: { url: "fSConfig/filterByfundandDate" },
        viewUrl: "/fs/config/config-entry/view",
        routeUrl: { create: "/fs/config/config-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "LEFTPANEL_REPORTS_NAV,REPORTS_FINANCIAL_STATEMENT",
        }
    },
    "archivedFSAutomation": {
        headers: "assets/headers/fs/FSAutomation.JSON",
        gridDataUrl: "fSConfig/records/true/",
        gridDeleteUrl: "fSConfig/deleteByConfigId",
        moduleName: "archivedFSConfig",
        filterUrl: { url: "fSConfig/filterByfundandDate" },
        viewUrl: "/fs/config/config-entry/view",
        deleteButtonFunction: "",
        routeUrl: { create: "/fs/config/config-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "FSAutomationUnlock": {
        headers: "assets/headers/fs/FSAutomationUnlock.JSON",
        gridDataUrl: "fsConfigLocked/records/",
        //gridDeleteUrl: "fSConfig/deleteByConfigId",
        moduleName: "fSConfigUnlock",
        filterUrl: { url: "fsConfigLocked/filterByKeys" },
        //viewUrl: "/fs/config/config-entry/view",
        //routeUrl: { create: "/fs/config/config-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "LEFTPANEL_REPORTS_NAV,REPORTS_FINANCIAL_STATEMENT",
        }
    },
    "fsSchedule": {
        headers: "assets/headers/fs/FSSchedule.JSON",
        gridDataUrl: "scheduleConfig/records/",
        gridDeleteUrl: "scheduleConfig/",
        moduleName: "fsScheduleConfig",
        filterUrl: { url: "scheduleConfig/filterByKeys" },
        viewUrl: "None",
        routeUrl: { create: "/fs/schedule/schedule-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "REPORTS_FINANCIAL_STATEMENT",
        }
    },
    "fSInvestor": {
        headers: "assets/headers/fs/FSInvestor.JSON",
        gridDataUrl: "fSInvestor/records/",
        moduleName: "fSInvestor",
        gridExportUrl: { url: "fSInvestor/downloadRecords/", isZoneName: false },
        filterUrl: { url: "fSInvestor/filterByKeys" },
        gridFilteredUrl: { url: "fSInvestor/downloadFSInvestorRecord/", isZoneName: false },
        updateUrl: "fSInvestor/update",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/fsInvestor',
            uploadUrl: "fSInvestor/fsInvestorUpdate/", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fsInvestor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fsInvestor/", name: "FS Investor", sampleName: "fsInvestor", dataStringify: true
        },
        authFunctions: {
            uploadButtonFunction: "REPORTS_FINANCIAL_STATEMENT",
            updateButtonFunction: "REPORTS_FINANCIAL_STATEMENT"
        }
    },
    "fsCashEquivalents": {
        headers: "assets/headers/fs/fsCashEquivalents.JSON",
        gridDataUrl: "cashAndCashEquivalent/records/",
        gridDeleteUrl: "cashAndCashEquivalent/",
        moduleName: "cashAndCashEquivalent",
        gridExportUrl: { url: "cashAndCashEquivalent/downloadRecords/", isZoneName: false },
        filterUrl: { url: "cashAndCashEquivalent/filterCashAndCashEquivalentKeys" },
        gridFilteredUrl: { url: "cashAndCashEquivalent/downloadFilteredRecords/", isZoneName: false },
        updateUrl: "cashAndCashEquivalent/update",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/cashAndCashEquivalent',
            uploadUrl: "cashAndCashEquivalent/uploadCashAndCashEquivalent", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/cashAndCashEquivalent",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/cashAndCashEquivalent/", name: "cash_and_cash_equivalents", sampleName: "fscashandcashequivalent", dataStringify: false
        },
        authFunctions: {
            uploadButtonFunction: "REPORTS_FINANCIAL_STATEMENT",
            updateButtonFunction: "REPORTS_FINANCIAL_STATEMENT"
        }
    },
    "fSManagementCompanyInvestor": {
        headers: "assets/headers/fs/FSManagementCompanyInvestor.JSON",
        gridDataUrl: "fSInvestor/records/",
        moduleName: "fSInvestor",
        gridExportUrl: { url: "/sudrania/fSInvestor/downloadRecords/", isZoneName: false },
        filterUrl: { url: "fSInvestor/filterByKeys" },
        gridFilteredUrl: { url: "/sudrania/fSInvestor/downloadFSInvestorRecord/", isZoneName: false },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/fsInvestor',
            uploadUrl: "fSInvestor/fsInvestorUpdate/", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fsInvestor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fsInvestor/", name: "FS Investor", sampleName: "fsInvestor", dataStringify: true
        },
        authFunctions: {
            uploadButtonFunction: "REPORTS_FINANCIAL_STATEMENT"
        }
    },
    "fSPortfolio": {
        headers: "assets/headers/fs/fSPortfolio.JSON",
        gridDataUrl: "fSPortfolio/records/",
        moduleName: "fSPortFolio",
        gridExportUrl: { url: "fSPortfolio/downloadRecords/", isZoneName: false },
        filterUrl: { url: "fSPortfolio/filterFsPositionReportByKeys" },
        gridFilteredUrl: { url: "fSPortfolio/downloadFilteredRecords/", isZoneName: false },
        updateUrl: "fSPortfolio/update",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/fsPortfolio',
            uploadUrl: "fSPortfolio/fsPortfolioUpdate", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fsPortfolio",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fsPortfolio/", name: "FS Portfolio", sampleName: "fSPortfolio", dataStringify: true
        },
        authFunctions: {
            uploadButtonFunction: "FINANCIALSTATEMENT_CONFIGURE_FINANCIALSTATEMENT_MANUALFEED_PORTFOLIO",
            updateButtonFunction: "FINANCIALSTATEMENT_CONFIGURE_FINANCIALSTATEMENT_MANUALFEED_PORTFOLIO"
        }
    },
    "fschartOfAccountsRapid": {
        headers: "assets/headers/fs/fsChartofAccount.JSON",
        gridDataUrl: "fsChartOfAccounts/records/",
        gridDeleteUrl: "chartOfAccounts/",
        moduleName: "chartOfAccounts",
        gridExportUrl: { url: "fsChartOfAccounts/downloadRecords/", isZoneName: false },
        filterUrl: { url: "fsChartOfAccounts/filterFSChartOfAccountsByKeys" },
        gridFilteredUrl: { url: "fsChartOfAccounts/downloadFilteredRecords/", isZoneName: false },
        updateUrl: "fsChartOfAccounts/update",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/chartOfAccountUpdate',
            uploadUrl: "fsChartOfAccounts/uploadFSChartOfAccount", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccountUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccountUpdate/", name: "chart_of_accounts", sampleName: "fschartofaccountsrapid", dataStringify: false
        },
        authFunctions: {
            uploadButtonFunction: "REPORTS_FINANCIAL_STATEMENT",
            updateButtonFunction: "REPORTS_FINANCIAL_STATEMENT"
        }
    },
    "fsCashFlowUpdate": {
        headers: "assets/headers/fs/fsCashFlowUpdate.JSON",
        gridDataUrl: "cashFlowUpdate/records/",
        //gridDeleteUrl: "chartOfAccounts/",
        //moduleName: "chartOfAccounts",
        gridExportUrl: { url: "cashFlowUpdate/downloadRecords/", isZoneName: false },
        filterUrl: { url: "cashFlowUpdate/filterCashFlowUpdateByKeys" },
        gridFilteredUrl: { url: "cashFlowUpdate/downloadFilteredRecords/", isZoneName: false },
        //updateUrl: "fsChartOfAccounts/update",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/fsCashFlowUpdate',
            uploadUrl: "cashFlowUpdate/update", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fsCashFlowUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fsCashFlowUpdate/", name: "fscashflowupdate", sampleName: "fscashflowupdate", dataStringify: false
        },
        authFunctions: {
            uploadButtonFunction: "REPORTS_FINANCIAL_STATEMENT",
            updateButtonFunction: "REPORTS_FINANCIAL_STATEMENT"
        }
    },
    "fsExposure": {
        headers: "assets/headers/fs/fsExposure.JSON",
        gridDataUrl: "fsExposure/records/",
        //gridDeleteUrl: "chartOfAccounts/",
        moduleName: "fsExposure",
        gridExportUrl: { url: "fsExposure/downloadRecords/", isZoneName: false },
        filterUrl: { url: "fsExposure/filterFSExposureByKeys" },
        gridFilteredUrl: { url: "fsExposure/downloadFilteredRecords/", isZoneName: false },
        updateUrl: "fsExposure/update",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/fsExposure',
            uploadUrl: "fsExposure/uploadFSExposure", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/fsExposure",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/fsExposure/", name: "fsexposure", sampleName: "fsexposure", dataStringify: false
        },
        authFunctions: {
            uploadButtonFunction: "REPORTS_FINANCIAL_STATEMENT",
            updateButtonFunction: "REPORTS_FINANCIAL_STATEMENT"
        }
    },
    "feeSharing": {
        headers: "assets/headers/settings-header/accountingSetting/feeSharingSetupData.JSON",
        gridDataUrl: "feeSharingV2/records/",
        gridDeleteUrl: "feeSharing/",
        moduleName: "feeSharing",
        gridExportUrl: { url: "feeSharingV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "feeSharingV2/filterByKeys" },
        gridFilteredUrl: { url: "feeSharingV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/Fee-sharing-entry/view",
        routeUrl: { create: "/setting/Fee-sharing-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "FEESHARINGSETUP_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "FEESHARINGSETUP_VIEW", deleteButtonFunction: "FEESHARINGSETUP_DELETE",
        }
    },
    "hurdleRate": {
        headers: "assets/headers/settings-header/accountingSetting/hurdleRateData.JSON",
        gridDataUrl: "hurdleRateV2/records/",
        gridDeleteUrl: "hurdleRate/",
        moduleName: "hurdleRate",
        gridExportUrl: { url: "hurdleRateV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "hurdleRateV2/filterByKeys" },
        gridFilteredUrl: { url: "hurdleRateV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "hurdleRateV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/hurdleRate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/hurdleRate/", name: "hurdle_rate", sampleName: "hurdleratedemo", dataStringify: false
        },
        viewUrl: "/setting/hurdle-rate-entry/view",
        routeUrl: { create: "/setting/hurdle-rate-entry/create", upload: "/setting/hurdle-rate-upload" },
        authFunctions: {
            newButtonFunction: "HURDLERATE_CREATE", uploadButtonFunction: "HURDLERATE_CREATE",
            viewButtonFunction: "HURDLERATE_VIEW", deleteButtonFunction: "HURDLERATE_DELETE",
        }
    },
    "hurdleTireSetup": {
        headers: "assets/headers/settings-header/accountingSetting/hurdleTireSetup.JSON",
        gridDataUrl: "hurdleTierIncentiveSetupV2/records/",
        gridDeleteUrl: "hurdleRate/",
        moduleName: "hurdleTierIncentiveSetup",
        gridExportUrl: { url: "hurdleTierIncentiveSetupV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "hurdleTierIncentiveSetupV2/filterByKeys" },
        gridFilteredUrl: { url: "hurdleTierIncentiveSetupV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "hurdleRate/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/hurdleRate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/hurdleRate/", name: "Hurdle Rate", sampleName: "hurdleRate", dataStringify: false
        },
        viewUrl: "/setting/hurdle-tire-incentive-entry/view",
        routeUrl: { create: "/setting/hurdle-tire-incentive-entry/create", upload: "/setting/hurdle-tire-incentive-upload" },
        authFunctions: {
            newButtonFunction: "HURDLE_TIER_INCENTIVE_CREATE", uploadButtonFunction: "HURDLE_TIER_INCENTIVE_CREATE",
            viewButtonFunction: "HURDLE_TIER_INCENTIVE_VIEW", deleteButtonFunction: "HURDLE_TIER_INCENTIVE_DELETE",
        }
    },
    "investorUpdate": {
        moduleName: "investorUpdate",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/investorUpdate',
            uploadUrl: "investorUpdate/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investorUpdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investorUpdate/", name: "investor_update", sampleName: "investorupdate", dataStringify: true
        }
    },
    "taxAttributesAllocation": {
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "taxAttributesAllocation",
    },
    "hurdle": {
        headers: "assets/headers/settings-header/accountingSetting/hurdleData.JSON",
        gridDataUrl: "hurdleV2/records/",
        gridDeleteUrl: "hurdle/",
        moduleName: "hurdle",
        gridExportUrl: { url: "hurdleV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "hurdleV2/filterByKeys" },
        gridFilteredUrl: { url: "hurdleV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/hurdle-entry/view",
        routeUrl: { create: "/setting/hurdle-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "HURDLE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "HURDLE_VIEW", deleteButtonFunction: "HURDLE_DELETE",
        }
    },
    "incomeDistribution": {
        headers: "assets/headers/settings-header/accountingSetting/incomeDistributionBasedData.JSON",
        gridDataUrl: "incomeDistributionBaseV2/records/",
        gridDeleteUrl: "incomeDistributionBase/",
        moduleName: "incomeDistributionBase",
        gridExportUrl: { url: "incomeDistributionBaseV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "incomeDistributionBaseV2/filterByKeys" },
        gridFilteredUrl: { url: "incomeDistributionBaseV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/investors/income-distribution-base-entry/view",
        routeUrl: { create: "/investors/income-distribution-base-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "INCOMEDISTRIBUTIONBASE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "INCOMEDISTRIBUTIONBASE_VIEW", deleteButtonFunction: "INCOMEDISTRIBUTIONBASE_DELETE",
        }
    },
    "performanceAnalyticsSettings": {
        headers: "assets/headers/settings-header/accountingSetting/performanceAnalyticsSetting.JSON",
        gridDataUrl: "performanceAnalyticsSettingsV2/records/",
        gridDeleteUrl: "performanceAnalyticsSettings/",
        moduleName: "performanceAnalyticsSettings",
        gridExportUrl: { url: "performanceAnalyticsSettingsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "performanceAnalyticsSettingsV2/filterBySearchCriteria" },
        gridFilteredUrl: { url: "performanceAnalyticsSettingsV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "performanceAnalyticsSettingsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/performanceAnalyticsSettings",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/performanceAnalyticsSettings/", name: "performance_analytics_settings", sampleName: "performanceanalyticssettingsdemo", dataStringify: false
        },
        viewUrl: "/setting/performance-analytics-settings-entry/view",
        routeUrl: { create: "/setting/performance-analytics-settings-entry/create", upload: "/setting/performance-analytics-settings-upload" },
        authFunctions: {
            newButtonFunction: "PERFORMANCE_ANALYTICS_SETTINGS_CREATE", uploadButtonFunction: "PERFORMANCE_ANALYTICS_SETTINGS_CREATE",
            viewButtonFunction: "PERFORMANCE_ANALYTICS_SETTINGS_VIEW", deleteButtonFunction: "PERFORMANCE_ANALYTICS_SETTINGS_DELETE",
        }
    },
    "reReportConfig": {
        headers: "assets/headers/settings-header/Configuration/reReportConfigData.JSON",
        gridDataUrl: "setupRealEstateConfig/records/",
        gridDeleteUrl: "deleteController/",
        moduleName: "setupREConfiguration",
        gridExportUrl: { url: "setupRealEstateConfig/downloadRecords", isZoneName: false },
        filterUrl: { url: "setupRealEstateConfig/filterREConfigurationByKeys" },
        gridFilteredUrl: { url: "setupRealEstateConfig/downloadFilterRecords", isZoneName: false },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "/setting/re-report-config-entry/view",
        routeUrl: { create: "/setting/re-report-config-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_SETTINGS_RE_GROUPING_CONFIG", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_SETTINGS_RE_GROUPING_CONFIG", deleteButtonFunction: "SEAMLESS_SETTINGS_RE_GROUPING_CONFIG",
        }
    },
    "performanceBasedFee": {
        headers: "assets/headers/settings-header/accountingSetting/managerPerfBasedFeeData.JSON",
        gridDataUrl: "managerPerfBasedFeeV2/records/",
        gridDeleteUrl: "managerPerfBasedFee/",
        moduleName: "managerPerfBasedFee",
        gridExportUrl: { url: "managerPerfBasedFeeV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "managerPerfBasedFeeV2/filterByKeys" },
        gridFilteredUrl: { url: "managerPerfBasedFeeV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/performanc-based-fee-entry/view",
        routeUrl: { create: "/setting/performanc-based-fee-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "MANAGERPERFBASEDFEE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "MANAGERPERFBASEDFEE_VIEW", deleteButtonFunction: "MANAGERPERFBASEDFEE_DELETE",
        }
    },
    "predefinedJe": {
        headers: "assets/headers/settings-header/accountingSetting/predefinedJE.json",
        gridDataUrl: "predefinedJournalEntryV2/records/",
        gridDeleteUrl: "predefinedJournalEntry/",
        moduleName: "predefinedJournalEntry",
        gridExportUrl: { url: "predefinedJournalEntryV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "predefinedJournalEntryV2/filterByKeys" },
        gridFilteredUrl: { url: "predefinedJournalEntryV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "predefinedJournalEntryV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/PredefinedJournalEntry",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/PredefinedJournalEntry/", name: "pre_defined_je", sampleName: "predefinejournalentrydemo", dataStringify: false
        },
        viewUrl: "/setting/predefinedJe-entry/view",
        routeUrl: { create: "/setting/predefinedJe-entry/create", upload: "/setting/predefinedJe-upload" },
        authFunctions: {
            newButtonFunction: "PREDEFINED_JOURNAL_ENTRY_CREATE", uploadButtonFunction: "PREDEFINED_JOURNAL_ENTRY_CREATE",
            viewButtonFunction: "PREDEFINED_JOURNAL_ENTRY_VIEW", deleteButtonFunction: "PREDEFINED_JOURNAL_ENTRY_DELETE",
        }
    },
    "recurringTransaction": {
        headers: "assets/headers/settings-header/accountingSetting/recurringTransactionData.JSON",
        gridDataUrl: "recurringTransactionV2/records/",
        gridDeleteUrl: "recurringTransaction/",
        moduleName: "recurringTransaction",
        gridExportUrl: { url: "recurringTransactionV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "recurringTransactionV2/filterByKeys" },
        gridFilteredUrl: { url: "recurringTransactionV2/downloadFilterRecords/", isZoneName: false },
        updateUrl: "recurringTransactionV2/update",
        uploadDetails: {
            uploadUrl: "recurringTransactionV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/recurringTransaction",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/recurringTransaction/", name: "recurring_transaction", sampleName: "recurringtransactiondemo", dataStringify: false
        },
        viewUrl: "/setting/recurring-transaction-entry/view",
        routeUrl: { create: "/setting/recurring-transaction-entry/create", upload: "/setting/recurring-transaction-upload" },
        authFunctions: {
            newButtonFunction: "RECURRINGTRANSACTION_CREATE", uploadButtonFunction: "RECURRINGTRANSACTION_CREATE",
            viewButtonFunction: "RECURRINGTRANSACTION_VIEW", deleteButtonFunction: "RECURRINGTRANSACTION_DELETE",
            updateButtonFunction: "RECURRINGTRANSACTION_UPDATE"
        }
    },
    "setupExpenseAmortization": {
        headers: "assets/headers/settings-header/accountingSetting/setupExpenseAmortizationData.JSON",
        gridDataUrl: "setupExpenseAmortizationV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "setupExpenseAmortization",
        gridExportUrl: { url: "setupExpenseAmortizationV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "setupExpenseAmortizationV2/filterByKeys" },
        gridFilteredUrl: { url: "setupExpenseAmortizationV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/setting/setup-expense-amortization-entry/view",
        routeUrl: { create: "/setting/setup-expense-amortization-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SETUPEXPENSEAMORTIZATION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SETUPEXPENSEAMORTIZATION_VIEW", deleteButtonFunction: "SETUPEXPENSEAMORTIZATION_DELETE",
        }
    },
    "tradeEntryBroker": {
        headers: "assets/headers/reconciliation/tradeEntryBroker.json",
        gridDataUrl: "tradeEnterBrokerV2/records/",
        gridDeleteUrl: "tradeEnterBroker/delete",
        moduleName: "tradeEntryBroker",
        gridExportUrl: { url: "tradeEnterBrokerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "tradeEnterBrokerV2/filterByKeys" },
        gridFilteredUrl: { url: "tradeEnterBrokerV2/downloadFilterRecords/", isZoneName: false },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_TRADE_ENTRY_BROKER,RECONCILIATION_TRADE_ENTRY_BROKER_NEW_VIEW,JOURNAL_ENTRY_REVISED_THIRD_PARTY_DELETE",
        }
    },
    "cashBalanceBroker": {
        headers: "assets/headers/reconciliation/cashBalanceBroker.json",
        gridDataUrl: "cashBalanceBrokerV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "cashBalanceBroker",
        gridExportUrl: { url: "cashBalanceBrokerV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "cashBalanceBrokerV2/downloadFilterRecords/", isZoneName: false },
        filterUrl: { url: "cashBalanceBrokerV2/filterByKeys" },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "RECONCILIATION_CASH_BALANCE_BROKER_NEW_VIEW",
        }
    },
    "viewNonTradeTransTp": {
        headers: "assets/headers/reconciliation/viewNonTradeTransTp.json",
        gridDataUrl: "nonTradeTransactionTpSourceV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "nonTradeTransactionThirdParty",
        gridFilteredUrl: { url: "nonTradeTransactionTpSourceV2/downloadFilterRecords/", isZoneName: false },
        gridExportUrl: { url: "nonTradeTransactionTpSourceV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "nonTradeTransactionTpSourceV2/filterByKeys" },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "VIEW_NON_TRADE_TRANSACTION_THIRD_PARTY",
        }
    },
    "jeThirdParty": {
        headers: "assets/headers/reconciliation/jeThirdParty.json",
        gridDataUrl: "journalEntryRevisedThirdPartyV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "tradeEntryBroker",
        gridExportUrl: { url: "journalEntryRevisedThirdPartyV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "journalEntryRevisedThirdPartyV2/filterByKeys" },
        gridFilteredUrl: { url: "journalEntryRevisedThirdPartyV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "",
        deleteButtonFunction: "JOURNAL_ENTRY_REVISED_THIRD_PARTY_DELETE",
        newButtonFunction: "None",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_TRADE_ENTRY_BROKER,RECONCILIATION_TRADE_ENTRY_BROKER_NEW_VIEW,JOURNAL_ENTRY_REVISED_THIRD_PARTY_DELETE",
        }
    },
    "positionBroker": {
        headers: "assets/headers/reconciliation/positionBroker.json",
        gridDataUrl: "positionBrokerV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "positionBroker",
        gridFilteredUrl: { url: "positionBrokerV2/downloadFilterRecords/", isZoneName: false },
        gridExportUrl: { url: "positionBrokerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "positionBrokerV2/filterByKeys" },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "RECONCILIATION_POSITION_BROKER_NEW_VIEW,SEAMLESS_INPUT_VIEW_RECONCILIATION_POSITION_BROKER_LIST",
        }
    },
    "uploadPositionBroker": {

        uploadDetails: {
            uploadUrl: "positionBrokerV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/position",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/position/", name: "position", sampleName: "position"
        }
    },
    "monthlyColumnarReport": {
        exportURL: { url: api_url + 'monthlyIncomeStatementReport/excelDownload' },
        moduleName: "",
    },
    "subPeriodReport": {
        exportURL: { url: api_url + 'incomeStatementSubPeriodReport/excelDownload' },
        moduleName: "",
    },
    "uploadcashBalance": {

        uploadDetails: {
            uploadUrl: "cashBalanceBrokerV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/cashBalance",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/cashBalance/", name: "cashbalance", sampleName: "cashbalance"
        }
    },
    "uploadWorkflow": {
        pathName: 'uploadWorkflow',
        uploadDetails: {
            uploadUrl: "alteryxV2/upload",
        }
    },
    "runTopRecon": {
        exportURL: { url: api_url + 'summaryReconV2/downloadRecords/' },
        moduleName: "",
    },
    "runTradeReconSummary": {
        exportURL: { url: api_url + 'summaryReconV2/downloadRecordsSummaryRecon' },
        moduleName: "",
    },
    "runCashReconMulti": {
        exportURL: { url: api_url + 'cashBalanceBrokerV2/downloadRecordMulti' },
        moduleName: "",
    },
    "runCashRecon": {
        exportURL: { url: api_url + 'cashBalanceBrokerV2/downloadRecord' },
        moduleName: "",
    },
    "runPositionRecon": {
        exportURL: { url: api_url + 'positionBrokerV2/downloadRecord' },
        moduleName: "",
    },
    "runPositionReconMulti": {
        exportURL: { url: api_url + 'positionBrokerV2/downloadRecordMulti' },
        moduleName: "",
    },
    "activityRecon": {
        exportURL: { url: api_url + 'activityReconV2/excleDownload/' },
        moduleName: "",
    },
    "custodianRecon": {
        exportURL: { url: api_url + 'reconcilationPositionSummaryExchangeRateV2/excleDownloadPositionSummaryExchangeRate/' },
        moduleName: "",
    },
    "recon": {
        exportURL: { url: api_url + 'reconcilationPositionSummaryExchangeRateV2/excleDownloadPositionSummarySecond/' },
        moduleName: "",
    },
    "reconTradeManually": {
        headers: "assets/headers/reconciliation/tradeEntryClient.json",
        gridDataUrl: "unMatchTradeListV2/UmatchClientRecords/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "tradeEntryBroker",
        gridExportUrl: { url: "positionBrokerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "unMatchTradeList/filterByKeys/tradeClientV2/UmatchClientRecords" },
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_TRADE_ENTRY_BROKER,RECONCILIATION_TRADE_ENTRY_BROKER_NEW_VIEW,JOURNAL_ENTRY_REVISED_THIRD_PARTY_DELETE",
        }
    },

    "configureReconTradeManually": {
        tradeEntryClient: 'assets/headers/reconciliation/tradeEntryClient.json',
        tradeEntryBrokers: 'assets/headers/reconciliation/tradeEntryClient.json',
        moduleName: "configureFetchMarketData",
        gridExportUrl: {
            tab1: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeClient/", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeClient/", isZoneName: false }
            },
            tab2: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeBroker/", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeBroker/", isZoneName: false }
            }
        },
        filterUrl: {
            tab1: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/filterByKeys/tradeClient/UmatchClientRecords", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/filterByKeys/tradeClient/MatchClientRecords", isZoneName: false }
            },
            tab2: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/filterByKeys/tradeBroker/UnMatchBrokerRecords", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/filterByKeys/tradeBroker/MatchBrokerRecords", isZoneName: false }
            },
        },
        gridFilteredUrl: {
            tab1: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/downloadFilterRecordsClient/", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/downloadFilterRecordsClient/", isZoneName: false }
            },
            tab2: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/downloadFilterRecordsBroker/", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/downloadFilterRecordsBroker/", isZoneName: false }
            }
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "GETMARKETDATA_FROMGOLBALMARKETDATA,SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA,TRADE_RECONCILE_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_RECONCILIATION_TRADE_RECONCILIATION_VIEW,MULTIPARTYRECONCILE_VIEW,SEAMLESS_RECONCILIATION_TRADE_RECONCILIATION_RECONCILE_TRADES",
        }
    },
    "configureReconTradeMulti": {
        tradeEntryClient: 'assets/headers/reconciliation/tradeEntryClient.json',
        tradeEntryBrokers: 'assets/headers/reconciliation/tradeEntryClient.json',
        moduleName: "configureFetchMarketData",
        gridExportUrl: {
            tab1: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeClient/", isZoneName: false }
            },
            tab2: {
                unmatchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeBroker/", isZoneName: false },
                matchTradeEntry: { url: "unMatchTradeListV2/downloadRecords/tradeBroker/", isZoneName: false }
            }
        },
        filterUrl: {
            tab1: {
                unmatchTradeEntry: { url: "thridPartyReconcilationV2/filterByKeys/tradeClient/UmatchClientRecords/tPSource1", isZoneName: false },
                matchTradeEntry: { url: "thridPartyReconcilationV2/filterByKeys/tradeClient/MatchClientRecords/tPSource1", isZoneName: false }
            },
            tab2: {
                unmatchTradeEntry: { url: "thridPartyReconcilationV2/filterByKeys/tradeBroker/UnMatchBrokerRecords/tPSource1", isZoneName: false },
                matchTradeEntry: { url: "thridPartyReconcilationV2/filterByKeys/tradeBroker/MatchBrokerRecords/tPSource1", isZoneName: false }
            },
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "GETMARKETDATA_FROMGOLBALMARKETDATA,SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA,TRADE_RECONCILE_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_RECONCILIATION_TRADE_RECONCILIATION_VIEW,MULTIPARTYRECONCILE_VIEW",
        }
    },
    "positionByTaxlot": {
        headers: "assets/headers/query/PositionByTaxlot.JSON",
        gridDataUrl: "positionByTaxlotV2/records/",
        gridDeleteUrl: "alteryx/integrityCheckSummary/",
        moduleName: "positionByTaxlot",
        filterUrl: { url: "positionByTaxlotV2/filterByKeys" },
        gridExportUrl: { url: "positionByTaxlotV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "positionByTaxlotV2/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/query/position-By-Taxlot-Entry/view",
        routeUrl: { create: "/query/position-By-Taxlot-Entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "POSITIONBYTAXLOT_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "POSITIONBYTAXLOT_VIEW", deleteButtonFunction: "POSITIONBYTAXLOT_DELETE",
        }
    },
    'cashTransaction': {
        headers: 'assets/headers/query/cashTransaction.JSON',
        gridDataUrl: 'cashLedgerV2/records/',
        filterUrl: { url: "cashLedgerV2/filterByKeys" },
        moduleName: "cashLedger",
        gridExportUrl: { url: "cashLedgerV2/CashLedgerExcelAll/", isZoneName: false },
        gridFilteredUrl: { url: "cashLedgerV2/CashLedgerExcel/" },
        newButtonFunction: "None",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "QUERY_CASHTRANSCATION_VIEW",
        }
    },
    'notesList': {
        headers: 'assets/headers/query/notesList.JSON',
        gridDataUrl: 'investorsFundNotesDetailsV2/records/',
        filterUrl: { url: "investorsFundNotesDetailsV2/filterByKeys" },
        moduleName: "investorsFundNotesDetails",
        gridExportUrl: { url: "investorsFundNotesDetailsV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "investorsFundNotesDetailsV2/downloadFilterNotes/" },
        viewUrl: "/query/notes-entry/view",
        routeUrl: { create: "/query/notes-entry/create", upload: "/query/notes-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/notes',
            uploadUrl: "investorsFundNotesDetailsV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/notes",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/notes/", name: "notesList", sampleName: "notesList"
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "REPORTS_ACCOUNTING_NOTESDETAILS_CREATE",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    'copnotesList': {
        headers: 'assets/headers/query/notesList.JSON',
        gridDataUrl: 'investorsFundNotesDetailsV2/records/',
        filterUrl: { url: "investorsFundNotesDetailsV2/filterByKeys" },
        moduleName: "investorsFundNotesDetails",
        gridExportUrl: { url: "investorsFundNotesDetailsV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "investorsFundNotesDetailsV2/downloadFilterNotes/" },
        viewUrl: "/investors/notes-entry/view",
        routeUrl: { create: "/investors/notes-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "REPORTS_ACCOUNTING_NOTESDETAILS_VIEW",
        }

    },
    "exportIncomeStatementMultiFund": {
        exportURL: { url: api_url + 'balanceSheetReportV2/incomeStatementMultiFundExcleDownload/' },
        moduleName: "",
    },
    "incomeAllocationSummary": {
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "investorCapitalSummary",
    },
    "balanceSheetReportNFMC": {
        exportURL: { url: api_url + 'balanceSheetReportV2/excleDownloadNewVersion/' },
        moduleName: "",
    },
    "tickerLedgerAccount": {
        exportURL: { url: api_url + 'PNLContributionReportV2/tickerLedgerAccExcleDownload/' },
        moduleName: "",
    },
    "postedFundTransaction": {
        exportURL: { url: api_url + 'otherReportsPostedFundTransactionsControllerV2/PostedFundTransactionsReportDownload/' },
        moduleName: "",
    },
    "fundActivityByFund": {
        exportURL: { url: api_url + 'fundActivityByFundV2/excleDownload/' },
        moduleName: "",
    },
    "fundActivityByDealer": {
        exportURL: { url: api_url + 'fundActivityByDealerV2/excleDownload/' },
        moduleName: "",
    },

    "incomeStatementNFM": {
        exportURL: { url: api_url + 'balanceSheetReportV2/incomeStatementNFM/' },
        moduleName: "",
    },
    "incomeStatement": {
        exportURL: { url: api_url + 'incomeStatementV2/downloadRecords' },
        moduleName: "",
    },
    "balanceSheetReport": {
        exportURL: { url: api_url + 'balanceSheetV2/downloadRecords' },
        moduleName: "",
    },
    "postRecurringTransaction": {
        exportURL: { url: api_url + 'postRecurringTransactionV2/downloadRecords' },
        moduleName: "",
    },
    "investorTagSummary": {
        exportURL: { url: api_url + 'investorstatementnewV2/downloadRecords/' },
        moduleName: "",
    },
    "investorStatementReport2": {
        exportURL: { url: api_url + 'investorstatementnewV2/downloadRecords/' },
        moduleName: "",
    },
    "dstFanmailOutput": {
        exportURL: { url: api_url + 'dstFanMailOutputV2/txtFileDownload/' },
        moduleName: "",
    },
    "costRollForward": {
        exportURL: { url: 'CostRollForwardReport/costRollForwardReport' },
        moduleName: "",
    },
    "costRollForwarddealwise": {
        exportURL: { url: 'CostRollForwardReportDealWise/costRollForwardDealWiseReport' },
        moduleName: "",
    },
    "investorIncomeSummary": {
        exportURL: { url: api_url + 'pennyInvestorIncomeSummaryV2/downloadPdf?' },
        IFrameURL: { url: 'pennyInvestorIncomeSummaryV2/downloadPdf?' },

        moduleName: "",
    },
    "policyGainLossReport": {
        exportURL: { url: api_url + 'policyGainLossV2/excleDownload/' },
        moduleName: "",
    },
    "orionDataFilesReport": {
        exportURL: { url: api_url + 'orionDataFiles/txtFileDownload/' },
        moduleName: "",
    },
    "dailyPNL": {
        exportURL: { url: api_url + 'dailyPNL/downloadCsv/' },
        moduleName: "",
    },

    "fidelityInvestorReport": {
        exportURL: { url: api_url + 'fidelityInvestorReports/CSVFileDownload/' },
        moduleName: "",
    },
    "realizedGainLossCalculation": {
        exportURL: { url: api_url + 'realisedGainLossController/realisedgainLossExcel?data=' },
        moduleName: "",
    },
    "realizedGainLoss": {
        exportURL: { url: api_url + 'realisedGainLossController/realizedGainLoss?data=' },
        iframeURL: { url: 'realisedGainLossController/realizedGainLoss?data=' },
        iframeURLDetailed: { url: 'realisedGainLossController/realizedGainLossPdf' },
        moduleName: "",
    },
    "dailyPNL2": {
        exportURL: { url: api_url + 'dailyPNLTwo/dailyPNLDownload/' },
        moduleName: "",
        exportURLpdf: { url: api_url + 'shareHolderRegister/pdfDownload/' },
        exportURLExcel: { url: api_url + 'shareHolderRegister/excleDownload/' },

    },
    "equityschedulepe": {
        pdfURL: { url: 'equitySchedulePE/getRecord', },
        exportURL: { url: 'equitySchedulePE/equitySchedulePe' },
    },
    "redemptionReport": {
        exportURL: { url: api_url + 'redemptionReport/redemptionReportDownload/' },
        moduleName: "",
    },
    "annualImpactNoteStatement": {
        exportURL: { url: 'InvestorStatementsController/AnnualImpactNoteStatement?' },
        moduleName: "",
    },
    "openlotswtl": {
        exportURL: { url: api_url + 'openLots/openLotsExcelDownload/' },
        moduleName: "",
    },
    "calculateDividend": {
        exportURL: { url: api_url + 'dividendIncomeV2/downloadCSV' },
        moduleName: "",
    },
    "dailyActivityByFund": {
        exportURL: { url: api_url + 'dailyActivityByFundControllerV2/dailyActivityByFundDataReportDownload/' },
        moduleName: "",
    },
    "assetSummaryFrontier": {
        exportURL: { url: api_url + 'assetSummaryFrontierControllerV2/assetSummaryFrontierReportDownload/' },
        moduleName: "",
    },
    "statementOfIncome": {
        exportURL: { url: api_url + 'statementOfIncomeV2/excleDownload/' },
        moduleName: "",
    },
    "breportService": {
        exportURL: { url: api_url + 'bReportV2/excleDownload/' },
        moduleName: "",
    },
    "TradeReconciledReportService": {
        exportURL: { url: api_url + 'tradeReconciled/tradeReconcileDownload/' },
        moduleName: "",
    },
    "dailyPerformanceReport": {
        exportURL: { url: api_url + 'dailyPerformanceReportControllerV2/dailyActivityByFundDataReportDownload/' },
        moduleName: "",
    },
    "investorCapitalSummaryCustom": {
        exportURL: {
            url: 'customInvestorCapitalSummaryV2/InvestorCapitalSummary?data=',
            tsvUrl: api_url + 'customInvestorCapitalSummaryV2/downloadTSV',
            excelUrl: api_url + 'customInvestorCapitalSummaryV2/downloadExcel'
        },
        moduleName: "",
    },
    "EquityScheduleSummaryReport": {
        exportURL: { url: 'EquityScheduleSummary/EquityScheduleSummary', }
    },
    "InstrumentReport": {
        exportURL: {
            url: 'instrumentReportCntrlr/InstrumentReport?data=',
            excelUrl: api_url + 'instrumentReportCntrlr/InstrumentReport?data=',

        },
        moduleName: "",
    },
    "InvestorCapitalSummaryReport": {
        exportURL: {
            url: 'investorCapitalSummaryCtlr/InvestorCapitalSummary?data=',
            excelUrl: api_url + 'investorCapitalSummaryCtlr/InvestorCapitalSummary?data=',
            excelUrldeatail: api_url + 'investorCapitalSummary/investorCapitalSummaryDetailed?data='
        },
        moduleName: "",
    },
    "capitalScheduleReport": {
        exportURL: {
            url: 'CapitalScheduleController/CapitalScheduleReport?data=',
            excelUrl: 'CapitalScheduleController/CapitalScheduleReport?data='
        },
        moduleName: "",
    },
    "EconomicAllocationReport": {
        exportURL: {
            url: 'inverstorCapitalSummaryEA/summary/excelData/',
            excelUrl: api_url + 'inverstorCapitalSummaryEA/summary/excelData/'
        },
        moduleName: "",
    },

    "equityAllocationReport": {
        exportURL: {
            url: 'equityAllocationController/EquityAllocation',
            excelUrl: 'equityAllocationController/equityAllocationExcelExport'
        },
        moduleName: "",
    },
    "InvestorCapitalScheduleReport": {
        exportURL: {
            url: 'InvestorCapitalScheduleController/InvestorCapitalScheduleReport?data=',
            excelUrl: 'InvestorCapitalScheduleController/InvestorCapitalScheduleReport?data='
        },
        moduleName: "",
    },
    "position-rec-summary": {
        getReport: { url: 'multiPartyReconcile/getRecord', method: 'GET' },
        excleDownload: { url: 'multiPartyReconcile/multiPartyReconcileSummaryExcelDownload/date/fundId', method: 'GET' },
    },
    "position-rec-report": {
        getReport: { url: 'multiPartyReconcileV2/getRecord', method: 'GET' },
        excleDownload: { url: 'multiPartyReconcileV2/multiPartyReconcileExcelDownload/date/fundId/dataReportType', method: 'GET' },
        pdfDownload: { url: 'multiPartyReconcileV2/multiPartyReconcilePdfDownload/date/fundId/dataReportType', method: 'GET' },
    },
    "cash-balance-rec-report": {
        getReport: { url: 'multiPartyCashBalance/getRecord/date/fundId/reportType', method: 'GET' },
        excleDownload: { url: 'multiPartyCashBalance/multiPartyCashBalanceExcelDownload/date/fundId/reportType', method: 'GET' },
        pdfDownload: { url: 'multiPartyCashBalance/multiPartyCashBalancePdfDownload/date/fundId/reportType', method: 'GET' },
    },
    "PortfolioTurnoverRatioReport": {
        exportURL: {
            excelUrl: api_url + 'portfolioTurnoverReport/getReport?data='
        },
        moduleName: "",
    },
    "cash-ledger": {
        getReportDetailed: { url: api_url + 'cashLedger/cashLedgerRecordDetailed/', method: 'POST' },
        getReportSummary: { url: api_url + 'cashLedger/cashLedgerRecordSummary/', method: 'POST' },
        downloadDetailed: { url: api_url + 'cashLedgerV2/excleDownloadNew/endDate/fund/startDate/type/fundName/brokerAccountList', method: 'GET' },
        downloadSummary: { url: api_url + 'cashLedgerV2/excleDownloadSummaryNew/endDate/fund/startDate/type/fundName/brokerAccountList', method: 'GET' },
        downloadSummaryPdf: { url: api_url + 'cashLedger/excleDownloadSummary/endDate/fund/startDate/type/fundName/brokerAccountList', method: 'GET' }

    },
    "accountCashLedger": {
        downloadDetailed: { url: api_url + 'accountcashLedger/accountcashLedger', method: 'POST' },
        iFrameURL: { url: 'accountcashLedger/accountcashLedger', method: 'POST' },

    },
    "PortDataReport": {
        exportURL: {
            url: 'exportController/downloadFilter',
            excelUrl: api_url + 'portDataReport/PortDataReport',
            iFrameUrl: 'portDataReport/PortDataReport'
        },
        moduleName: "portData",
    },
    "PositionReportDetailed": {
        exportURL: {
            url: api_url + 'exportController/downloadFilter',
            excelUrl: 'fsPostion/fsPostionReport'
        },
        moduleName: "postionReportDetailed",
    },
    "InvestorSummaryReport": {
        exportURL: {
            url: api_url + 'investorCommitments/txtFileDownload/',
            excelUrl: 'investorCommitments/csvFileDownload/',
            tsvUrl: 'investorCommitments/txtFileDownload/'
        },
        moduleName: "",
    },
    "dividendIncome": {
        exportURL: {
            url: api_url + 'dividendIncomeV2/dividendTradeView',
            exportURLpdf: { url: api_url + 'dividendIncomeV2/downloadPDF' },
            exportURL: { url: api_url + 'dividendIncomeV2/downloadExcel' },
        },
        moduleName: "",
    },
    "trialBalanceReport": {
        exportURL: { url: api_url + 'trialBalanceV2/downloadRecords' },
        moduleName: "",
    },
    "assetBasedFeeReport": {
        exportURL: { url: api_url + 'assetBaseFeeV2/downloadAssetBasedFeeReportCSV/' },
        moduleName: "",
    },
    "performanceBasedFeeReport": {
        exportURL: { url: api_url + 'performanceFeeV2/downloadCSV/' },
        moduleName: "",
    },
    "traderAssetBasedFeeReport": {
        exportURL: { url: api_url + 'assetBaseFeeForTraderV2/downloadRecords' },
        moduleName: "",
    },
    "traderPerformanceBasedFeeReport": {
        exportURL: { url: api_url + 'assetBaseFeeForTraderV2/downloadRecords' },
        moduleName: "",
    },

    "viewjesummaryByAccount": {
        exportURL: { url: api_url + 'jesummaryreportV2/downloadRecordsExcel' },
        csvURL: { url: api_url + 'jesummaryreportV2/downloadRecordsCSV' },
        moduleName: "",
        file: "JeSummaryByAccount"
    },
    "autoTradesCreation": {
        exportURL: { url: api_url + 'autoTradeCreation/exportData' },
        moduleName: "",
        file: "autoTradesCreation"
    },
    "processBar": {
        exportURL: { url: api_url + 'processLogV2/downloadRecords' },
        moduleName: "",
    },
    "traderReport": {
        exportURL: { url: api_url + 'tradeV2/downloadRecords' },
        moduleName: "",
    },
    "incomeAllocationTotal": {
        exportURL: { url: api_url + 'allocationRatioV2/downloadRecords' },
        moduleName: "",
    },
    "trialBalanceNfm": {
        exportURL: { url: api_url + 'trialBalance/excleDownloadNFM/' },
        moduleName: "",
    },
    "smRealestateList": {
        userCard: 'securities_master',
        pathName: 'sm_real_estate',
        headers: "assets/headers/portfolio-headers/smRealEstate.JSON",
        gridDataUrl: "sMRealEstateController/records/",
        gridDeleteUrl: "sMRealEstate/",
        moduleName: "sMRealEstate",
        gridExportUrl: { url: "sMRealEstateController/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMRealEstateController/filterByKeys" },
        filterDeleteUrl: { url: "sMRealEstateController/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMRealEstateController/downloadFilterRecords/", isZoneName: true },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMRealEstate", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMRealEstateController/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMRealEstate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMRealEstate/", name: "sMRealEstate", sampleName: "sMRealEstate", dataStringify: true
        },
        viewUrl: "/portfolio/sm-real-estate/view",
        deleteButtonFunction: "SM_REAL_ESTATE_DELETE",
        routeUrl: { create: "/portfolio/sm-real-estate/create", upload: "/portfolio/sm-real-estate/upload" },
        authFunctions: {
            newButtonFunction: "SM_REAL_ESTATE_CREATE", uploadButtonFunction: "SM_REAL_ESTATE_CREATE",
            viewButtonFunction: "SM_REAL_ESTATE_VIEW", deleteButtonFunction: "SM_REAL_ESTATE_DELETE",
        }
    },
    "incomeStatementReport": {
        exportURLNFM: { url: api_url + 'balanceSheetReport/incomeStatementNFM/' },
        getReport: { url: api_url + 'balanceSheetReport/record/', method: 'POST' },
        getReportAccount: { url: api_url + 'incomeStatementCustodian/getRecord/', method: 'POST' },
        excleDownload: { url: api_url + 'balanceSheetReport/excleDownload/endDate/fund/Income Statement/fundName/startDate/type', method: 'GET' },
        excleDownloadAccount: { url: api_url + 'incomeStatementCustodian/download/startDate/endDate/fund/type/fundName', method: 'GET' },
        pdfDownload: { url: api_url + 'balanceSheetReport/pdfDownload/endDate/fund/Income Statement/fundName/startDate', method: 'GET' },
        nFMReport:{url: 'balanceSheetReport/NFMReport'},
        monthlyIncomeStatementReportUrl: { url: api_url + 'balanceSheetMonthlyIncomeReport/excelDownload' },
    },
    "profitlossReport": {
        exportURLNFM: { url: api_url + 'balanceSheetReport/profitAndLossNFM/' },
        getReport: { url: api_url + 'balanceSheetReport/record/', method: 'POST' },
        getReportAccount: { url: api_url + 'incomeStatementCustodian/getRecord/', method: 'POST' },
        excleDownload: { url: api_url + 'balanceSheetReport/excleDownload/endDate/fund/Profit And Loss/fundName/startDate/type', method: 'GET' },
        excleDownloadAccount: { url: api_url + 'incomeStatementCustodian/download/startDate/endDate/fund/type/fundName', method: 'GET' },
        pdfDownload: { url: api_url + 'balanceSheetReport/pdfDownload/endDate/fund/Profit And Loss/fundName/startDate', method: 'GET' },
        nFMReport:{url:'balanceSheetReport/NFMReport'}
    },
    "cashBalanceReport": {
        exportURL: {
            csv: { url: api_url + 'cashBalanceV2/downloadRecords', method: 'Post' },
            excel: { url: api_url + 'cashBalanceV2/excleDownloadWithBackend', method: 'Post' },
            pdf: { url: api_url + 'cashBalanceV2/pdfDownloadWithBackend', method: 'Post' },
        },
        moduleName: "",
    },
    "historicalInvestorCapitalSummary": {
        headers: "assets/headers/query/historicalInvestorCapitalSummary.JSON",
        gridDataUrl: "investorCapitalSummaryPEV2/historicalInvestorCapitalSummaryRecords/",
        moduleName: "investorCapitalSummary",
        filterUrl: { url: "investorCapitalSummaryPEV2/filterByKeys" },
        filterDeleteUrl: { url: "investorCapitalSummaryPEV2/filterByKeysDelete" },
        gridExportUrl: { url: "investorCapitalSummaryPEV2/downloadRecords", isZoneName: false },
        gridFilteredUrl: { url: "investorCapitalSummaryPEV2/downloadFilterRecords/", isZoneName: false },
        //deleteButtonFunction: 'ACCOUNTING_ALLOCATION_HISTORY_DELETE',
        gridDeleteUrl: "investorCapitalSummaryPE/",
        newButtonFunction: "null",
        uploadDetails: {
            uploadUrl: "investorCapitalSummaryPEV2/historicalInvestorCapitalSummaryUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/historicalInvestorCapitalSummary",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/historicalInvestorCapitalSummary/", name: "historical_investor_capital_summary", sampleName: "historicalinvestorcapitalsummarydemo", dataStringify: true
        },
        routeUrl: { upload: "/query/historical-investor-capital-summary-upload" },
        viewUrl: "/query/historical-investor-capital-summary",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "REPORTS_ACCOUNTING_INVESTORCAPITALSUMMARY_PE,ACCOUNTING_ALLOCATION_HISTORY_UPLOAD,SEAMLESS_INPUT_VIEW_ACCOUNTING_ALLOCATION_HISTORY",
        }
    },
    "processLog": {
        exportURL: { url: api_url + 'processLogV2/exportRecords/' },
        moduleName: "",
        filterUrl: { url: "processLogV2/filterByKeys" },
        // gridFilteredUrl:{url:""}
    },

    "batchWorkList": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/batchWork.JSON",
        gridDataUrl: "batchWork/records/",
        deleteButtonFunction: "None",
        moduleName: "batchWork",
        // gridExportUrl: { url: "dailyMarginV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "batchWork/filterByKeys" },
        //  gridFilteredUrl: { url: "dailyMarginV2/downloadFilterRecords/", isZoneName: false },
        //routeUrl: { create: "/trades/daily-margin-entry/create", upload: "/trades/daily-margin/upload" },

        viewUrl: "/legacy/batch-work-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "DAILYMARGIN_VIEW", deleteButtonFunction: "BATCH_PROGRESS_SCREEN_VIEW,TRADE_UPLOAD_HV,SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME",
        }

    },

    "batchSession": {
        headers: "assets/headers/trade-headers/batchSession.JSON",
        gridDataUrl: "batchSession/records/",
        filterUrl: { url: "batchSession/filterByKeys" },
        moduleName: "batchSession",
        authFunctions: {
            deleteButtonFunction: "SIDEMENU_TRADES",
        }
    },


    "tradeUploadHV": {
        pathName: 'trade_upload_hv',
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeUplaodHV.JSON",
        gridDataUrl: "batchWork/records/",
        moduleName: "batchWork",
        filterUrl: { url: "batchWork/filterByKeys" },
        exportTradeUploadFailedURL: { url: api_url + 'tradeUpload/downloadFaildRecords' },
        uploadDetails: {
            uploadUrl: "dailyMarginV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dailyMargin",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dailyMargin/", name: "tradeUploadHV", sampleName: "tradeuploadhv"
        },

    },


    "tradehv": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeUplaodHV.JSON",
        gridDataUrl: "tradeUpload/records/",
        gridDeleteUrl: "tradeUpload/",
        moduleName: "tradeUpload",
        // gridExportUrl: { url: "tradeUpload/download/", isZoneName: false },
        gridExportUrl: { url: "exportController/download/tradeUpload", isZoneName: false },
        filterUrl: { url: "tradeUpload/filterByKeys" },
        // gridFilteredUrl: { url: "tradeUpload/downloadFilter/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/tradeUpload/", isZoneName: false },
        routeUrl: { create: "", upload: "/trades/trade-hv/upload" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "TRADE_UPLOAD_HV",
            viewButtonFunction: "None", deleteButtonFunction: "TRADE_UPLOAD_HV_DELETE",
        }

    },
    "realizedgl": {
        IframeURL: { url: api_url + 'realisedGainLossController/realizedGainLoss', method: 'GET' },
        iframeURLDetailed: { url: 'realisedGainLossController/realizedGainLossPdf' },
        exportURL: { url: api_url + 'realisedGainLossController/realisedgainLossExcel', method: 'GET' },
    },
    "unrealizedgl": {
        IframeURL: { url: api_url + 'unrealisedGainLossController/unrealizedGainLoss', method: 'Post' },
        exportExcelURL: { url: api_url + 'unrealisedGainLossController/unrealisedgainLossExcel' },
        exportPDFURL: { url: api_url + 'unrealisedGainLossController/unrealizedGainLossDownloadPdf' },
    },
    "managementFee": {
        IframeURL: { url: api_url + 'newManagementFeeReportController/managementFeeRecords', method: 'GET' },
        exportURL: { url: api_url + 'newManagementFeeReportController/downloadExcel', method: 'GET' },
    },
    "dailyCheck": {
        IframeURL: { url: 'DailyCheckReportController/DailyCheckReport', method: 'GET' },
        exportURL: { url: api_url + 'DailyCheckReportController/DailyCheckReport?data=', method: 'GET' },
    },
    "performanceCapsuleProgram": {
        IframeURL: { url: 'runProgramCompo/performanceCapsule/', method: 'GET' },
        exportURL: { url: api_url + 'runProgramCompo/performanceCapsule/', method: 'GET' },
    },
    "trial-balance": {
        getReport: { url: 'trialBalance/trialBalanceRecord/', method: 'POST' },
        getSummaryReport: { url: 'trialBalance/trialBalanceRecordNewVersion/', method: 'POST' },
        getATBReport: { url: api_url + 'trialBalance/accountTrialBalanceReport', method: 'POST' },
        iFrameURL: { url: 'trialBalance/accountTrialBalanceReport', method: 'POST' },
        excleDownload: { url: api_url + 'trialBalance/excleDownload/endDate/fund/startDate/fundName/type', method: 'GET' },
        pdfDownload: { url: api_url + 'trialBalance/excleDownload/endDate/fund/startDate/fundName/type', method: 'GET' },
        excleDownloadNewVersion: { url: api_url + 'trialBalance/excleDownloadNewVersion/endDate/fund/startDate/fundName/type', method: 'GET' },
        pdfDownloadNewVersion: { url: api_url + 'trialBalance/excleDownloadNewVersion/endDate/fund/startDate/fundName/type', method: 'GET' }
    },
    "performanceTableProgram": {
        IframeURL: { url: 'runProgramCompo/performanceData/', method: 'GET' },
        exportURL: { url: api_url + 'runProgramCompo/performanceData/', method: 'GET' },
    },

    "taxLotAppraisalReport": {
        exportURL: { url: api_url + 'taxlotreport/filterDataByKeys', },
        pdfURL: { url: api_url + 'taxlotreport/filterDataByKeys', },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "INCOME_STATEMENT_VIEW",
        }
    },
    "transactionDetailed": {
        exportURL: { url: api_url + 'entryreport/downloadExcel/', },
        pdfURL: { url: api_url + 'transactionHistorty/tradeExcelExport?data=', },
        gridFilteredHistoryUrl: { url: api_url + "exportController/downloadFilter/transactionHistory/" },
        gridFilteredDetailUrl: { url: api_url + "exportController/downloadFilter/transactionDetailed/" },
        gridFilteredGenericUrl: { url: api_url + "exportController/downloadFilter" }, //generic export 
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_TRADING_GAINLOSS_TRANSACTION_HISTORY_VIEW",
        }
    },

    "unrealizeGainLoss": {
        pdfURL: { url: api_url + 'unrealisedGainLossController/unrealizedGainLossDownloadPdf' },
        csvURL: { url: api_url + 'unrealisedGainLossController/unrealisedgainLossExcel' }
    },

    "balanceSheet": {
        getReport: { url: api_url + 'balanceSheetReport/record/', method: 'POST' },
        getReportCustodian: { url: 'balanceSheetCustodian/getRecord/', method: 'POST' },
        excleDownload: { url: api_url + 'balanceSheetReport/excleDownload/endDate/fund/Balance Sheet/fundName/NaN/type', method: 'GET' },
        excleDownloadCustodian: { url: api_url + 'balanceSheetCustodian/excleDownloadCustodianBalanceSheet/endDate/fund/type/fundName', method: 'GET' },
        pdfDownload: { url: api_url + 'balanceSheetReport/pdfDownload/endDate/fund/Balance Sheet/fundName/NaN', method: 'GET' },
        filterByRefresh: { url: 'balanceSheetReport/recordAfterRefresh/', method: 'POST' },
        getReportNC: { url: 'balanceSheetReportNC/record/', method: 'POST' },
        excleDownloadNC: { url: api_url + 'balanceSheetReportNC/excleDownload/endDate/fund/Balance Sheet/fundName/NaN', method: 'GET' },
        pdfDownloadNC: { url: api_url + 'balanceSheetReportNC/pdfDownload/endDate/fund/Balance Sheet/fundName/NaN', method: 'GET' },
        filterByRefreshNC: { url: 'balanceSheetReportNC/recordAfterRefresh/', method: 'POST' },
        nFMReport:{url: 'balanceSheetReport/NFMReport'},
        monthlyIncomeStatementReportUrl: { url: api_url + 'balanceSheetMonthlyReport/excelDownload' },

    },
    "accountStatement": {
        getReport: { url: api_url + 'accountStatementReport/accountStatementRecord/', method: 'POST' },
        excleDownload: { url: api_url + 'accountStatementReport/accountExcleDownload/endDate/fund/fundName/showDaily/showMTD/showQTD/showYTD/showITD/showIRR/showOath/type/displayOptions', method: 'GET' },
        pdfDownload: { url: api_url + 'accountStatementReport/downloadPdfForAccountStatement/endDate/fund/fundName/showDaily/showMTD/showQTD/showYTD/showITD/showIRR/showOath/type/displayOptions', method: 'GET' },
        accountExcleDownload: { url: api_url + 'accountStatementReport/accountExcleDownload', method: 'POST' },
        downloadPdfForAccountStatement: { url: api_url + 'accountStatementReport/downloadPdfForAccountStatement', method: 'POST' }
    },
    "performanceFee": {
        IframeURL: { url: api_url + 'performanceFeeReportController/performanceFeeRecords', method: 'GET' },
        exportURL: { url: 'performanceFeeReportController/downloadExcel', method: 'GET' },
    },
    "investorStatementReport": {
        filterByReportType: { url: api_url + 'fund/findDefaultInvestorReport', method: 'GET' },
        investorstatementdynamiclink: {
            url: 'investorStatementDynamic/InvestorStatementResponse', method: 'GET'
        },
        investorNameList: { url: api_url + 'fund/getDistinctInvestors', method: 'GET' },
        investorStatementAccountSummaryStatusCheck: { url: api_url + 'investmentAccountConsolidateControllerV2/investorStatementAccountSummaryStatusCheck/', method: 'GET' },
        investorStatementAccountSummary: { url: api_url + 'investmentAccountConsolidateController/investorStatementAccountSummary/', method: 'GET' },
        investmentAccountSummaryWithRORController: {
            url: api_url + 'investmentAccountSummaryWithRORControllerV2/investorStatementAccountSummaryStatusCheck/', method: 'GET'
        },
        aifNewSummaryStatusCheck: {
            url: api_url + 'investorStatementReportControllerV2/investorStatementAccountSummaryStatusCheck/', method: 'GET'
        },
    },
    "taxLotStatus": {
        userCard: "tax_lot_closing",
        headers: "assets/headers/trade-headers/taxLotStatus.JSON",
        gridDataUrl: "taxLotProcessStatus/records/",
        // gridDeleteUrl: "tradeEntry/",
        moduleName: "taxLotStatus",
        filterUrl: { url: "taxLotProcessStatus/filterByKeys" },
        gridExportUrl: { url: "taxLotProcessStatus/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxLotProcessStatus/downloadFilterRecords/", isZoneName: false },
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "TRADE_TAXLOTSTATUS,SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_PROCESSEDTAXLOTS_STATUS",
        }
    },
    "auditRequest": {
        headers: "assets/headers/trade-headers/auditRequest.JSON",
        gridDataUrl: "auditRequest/records/",
        //  gridDeleteUrl: "tradeEntry/",
        moduleName: "auditRequest",
        gridExportUrl: { url: "auditRequest/fetchRecord/", isZoneName: false },
        gridFilteredUrl: { url: "auditRequest/filterByKeys/", isZoneName: false },
        filterUrl: { url: "auditRequest/filterByKeys" },

    },
    "TAXAutomation": {
        headers: "assets/headers/tax-automation/taxAutomation.JSON",
        gridDataUrl: "taxReturnDetails/records/",
        gridDeleteUrl: "taxReturnDetails/",
        moduleName: "TaxReturnDetails",
        gridExportUrl: { url: "taxReturnDetails/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxReturnDetails/downloadFilterRecords/", isZoneName: false },
        filterUrl: { url: "taxReturnDetails/filterByKeys" },
        viewUrl: "/ta/config/ta-entry/view",
        routeUrl: { create: "/ta/config/ta-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILS_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILS_UPDATE",
            viewButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILS_UPDATE", deleteButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILS_DELETE",
        }
    },
    "manageJobSchedule": {
        headers: "assets/headers/manager-headers/manage-job-schedule.JSON",
        gridDataUrl: "schedule/cronjobs",
        moduleName: "manageJobSchedule",
        routeUrl: { create: "/manager/data-scheduler/create" },
        viewUrl: "/manager/data-scheduler/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,VIEW_NON_TRADE_TRANSACTION_THIRD_PARTY",
        }
    },
    "viewDataCollection": {
        entCard: 'enterprise_configuration',
        "Trade": {
            headers: "assets/headers/manager-headers/trade-collection.JSON",
            gridDataUrl: "api/genericdata/getResult/",
            filterUrl: { url: "api/genericdata/filter" },
            moduleName: "viewDataCollection",
            gridExportUrl: { url: api_url + "api/genericdata/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: api_url + "api/genericdata/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/data-scheduler/create" },
            viewUrl: "/manager/data-scheduler/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "None",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,OMSENTRYALLASSETCLASS_UNALLOCATED ORDERS",
            }

        },
        "MarketData": {
            headers: "assets/headers/manager-headers/marketData-collection.JSON",
            gridDataUrl: "api/genericdata/getResult/",
            filterUrl: { url: "api/genericdata/filter" },
            moduleName: "viewDataCollection",
            gridExportUrl: { url: api_url + "api/genericdata/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: api_url + "api/genericdata/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/data-scheduler/create" },
            viewUrl: "/manager/data-scheduler/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "None",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,OMSENTRYALLASSETCLASS_ALLOCATED ORDERS",
            }
        },
        "Position": {
            headers: "assets/headers/manager-headers/position-collection.JSON",
            gridDataUrl: "api/genericdata/getResult/",
            filterUrl: { url: "api/genericdata/filter" },
            moduleName: "viewDataCollection",
            gridExportUrl: { url: api_url + "api/genericdata/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: api_url + "api/genericdata/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/data-scheduler/create" },
            viewUrl: "/manager/data-scheduler/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "None",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,OMSENTRYALLASSETCLASS_UNAPPROVED ORDERS",
            }
        },
        "Balance": {
            headers: "assets/headers/manager-headers/balance-collection.JSON",
            gridDataUrl: "api/genericdata/getResult/",
            filterUrl: { url: "api/genericdata/filter" },
            moduleName: "viewDataCollection",
            gridExportUrl: { url: api_url + "api/genericdata/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: api_url + "api/genericdata/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/data-scheduler/create" },
            viewUrl: "/manager/data-scheduler/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "None",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,OMSENTRYALLASSETCLASS_APPROVED ORDERS",
            }
        },
        "GenericData": {
            headers: "assets/headers/manager-headers/genericData-collection.JSON",
            gridDataUrl: "api/genericdata/getResult/",
            filterUrl: { url: "api/genericdata/filter" },
            moduleName: "viewDataCollection",
            gridExportUrl: { url: api_url + "api/genericdata/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: api_url + "api/genericdata/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/data-scheduler/create" },
            viewUrl: "/manager/data-scheduler/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "None",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,OMSENTRYALLASSETCLASS_REAL TIME POSITIONS",
            }
        },
        "Transfers": {
            headers: "assets/headers/manager-headers/transfers-collection.JSON",
            gridDataUrl: "api/genericdata/getResult/",
            filterUrl: { url: "api/genericdata/filter" },
            moduleName: "viewDataCollection",
            gridExportUrl: { url: api_url + "api/genericdata/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: api_url + "api/genericdata/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/data-scheduler/create" },
            viewUrl: "/manager/data-scheduler/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "None",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA",
            }
        }

    },
    "processLogArchive": {
        exportURL: { url: api_url + 'processLogArchive/exportRecords/' },
        gridFilteredUrl: { url: "processLogArchive/exportFilteredRecords/", isZoneName: false },

        moduleName: "",
        filterUrl: { url: "processLogArchive/filterByKeys" }
    },
    "cashRecMultiParty": {
        exportPDFURL: { url: 'cashRecMultiParty/downloadPDF' },
        exportExcelURL: { url: 'cashRecMultiParty/downloadExcel' },
        exportCsvURL: { url: 'cashRecMultiParty/downloadCSV' }
    },
    "aumAllocationReport": {
        exportURL: {
            aumAllocationurl: api_url + 'aumAllocationController/getRecordAumAllocation?data=',
            tsvUrl: api_url + 'aumAllocationController/downloadTSV?data=',
            aumAllocationexcelUrl: api_url + 'aumAllocationController/aumAllocationReport?data=',
            aumSecurityexcelUrl: api_url + 'aumAllocationController/aumSecurityReport?data='
        },
        moduleName: "",
    },
    "usermanagementlist": {
        "Troniq": {
            headers: "assets/headers/manager-headers/userManagementUsers.JSON",
            gridDataUrl: "userV2/records/",
            gridDeleteUrl: "user/",
            moduleName: "user",
            filterUrl: { url: "userV2/filterByKeys" },
            gridExportUrl: { url: "userV2/downloadFilterRecords/", isZoneName: false },
            gridFilteredUrl: { url: "userV2/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/users-entry/create", upload: "/user-management/upload-connect-user-fund" },
            uploadDetails: {
                uploadUrl: "associationOfUserFundV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/associationOfUserFund",
                getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/associationOfUserFund/", name: "connect_user_fund", sampleName: "associationofuserfunddemo", dataStringify: false
            },
            viewUrl: "None",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "SEAMLESS_USERMANAGEMENT_USER_AND_FUND_MAPPING",
                viewButtonFunction: "SEAMLESS_SIDEMENU_USERMANAGEMENT", deleteButtonFunction: "SEAMLESS_SIDEMENU_USERMANAGEMENT",
            }

        },
        "TRO": {
            headers: "assets/headers/manager-headers/userManagementReportingPortalUser.JSON",
            gridDataUrl: "reportingPortalUserV2/records/",
            gridDeleteUrl: "reportingPortalUser/",
            moduleName: "reportingPortalUser",
            filterUrl: { url: "reportingPortalUserV2/filterByKeys" },
            gridExportUrl: { url: "reportingPortalUserV2/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: "reportingPortalUserV2/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/reporting-portal-user-entry/create", upload: "/user-management/upload-reporting-portal-user" },
            uploadDetails: {
                uploadUrl: "reportingPortalUserV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/reportingPortalUser",
                getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/reportingPortalUser/", name: "reporting_portal_file",
                sampleName: "allsmupload2", dataStringify: true
            },
            viewUrl: "None",
            authFunctions: {
                newButtonFunction: "REPORTING_PORTAL_USER_CREATE", uploadButtonFunction: "REPORTING_PORTAL_USER_CREATE",
                viewButtonFunction: "REPORTING_PORTAL_USER_VIEW", deleteButtonFunction: "REPORTING_PORTAL_USER_DELETE",
            }

        },
        // "SIO": {
        //     headers: "assets/headers/manager-headers/userManagementInvestorPortalUser.JSON",
        //     gridDataUrl: "investorPortalUser/records/",
        //     gridDeleteUrl: "investorPortalUser/",
        //     moduleName: "investorPortalUser",
        //     filterUrl: { url: "investorPortalUserV2/filterByKeys" },
        //     gridExportUrl: { url: "/formidium/investorPortalUserV2/downloadRecords/", isZoneName: false },
        //     gridFilteredUrl: { url: "/formidium/investorPortalUserV2/downloadFilterRecords/", isZoneName: false },
        //     routeUrl: { create: "/manager/investor-portal-user-entry/create", upload: "/user-management/upload-investor-portal-user" },
        //     uploadDetails: {
        //         uploadUrl: "investorPortalUserV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investorPortalUser",
        //         getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investorPortalUser/", name: "investor_portal_user",
        //         sampleName: "investorportaluserdemo", dataStringify: true
        //     },
        //     viewUrl: "None",
        //     authFunctions: {
        //         newButtonFunction: "INVESTORPORTALUSER_CREATE", uploadButtonFunction: "INVESTORPORTALUSER_CREATE",
        //         viewButtonFunction: "", deleteButtonFunction: "INVESTORPORTALUSER_DELETE",
        //     }

        // }
    },
    "usermappinglist": {
        "connectUserInvestor": {
            headers: "assets/headers/manager-headers/connect-user-investor.JSON",
            gridDataUrl: "associationOfUserFundInvestorV2/records/",
            gridDeleteUrl: "associationOfUserFundInvestor/",
            moduleName: "associationOfUserFundInvestor",
            fileName: "User-Fund-Investor-MappingFiltered",
            filterUrl: { url: "associationOfUserFundInvestorV2/filterByKeys" },
            gridExportUrl: { url: "associationOfUserFundInvestorV2/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: "associationOfUserFundInvestorV2/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/manager/connect-user-investor/create", upload: "/user-management/upload-connectUserInvestor" },
            viewUrl: "None",
            uploadDetails: {
                uploadUrl: "associationOfUserFundInvestorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/associationOfUserFundInvestor",
                getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/associationOfUserFundInvestor/", name: "connect_user_investor", sampleName: "associationofuserfundinvestordemo", dataStringify: false
            },
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "",
                viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,Enterprise_UserAndInvestor_view,Enterprise_UserAndInvestor_create",
            }

        },
        "connectCustodianInvestor": {
            headers: "assets/headers/manager-headers/connect-custodian-investor.JSON",
            gridDataUrl: "connectCustodianInvestorV2/records/",
            gridDeleteUrl: "connectCustodianInvestor/",
            moduleName: "connectCustodianAndInvestor",
            filterUrl: { url: "connectCustodianInvestorV2/filterByKeys" },
            gridExportUrl: { url: "connectCustodianInvestorV2/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: "connectCustodianInvestorV2/downloadFilterRecords/", isZoneName: false },
            viewUrl: "None",
            routeUrl: { create: "/manager/connectcustodianinvestor-entry/create", upload: " " },
            authFunctions: {
                newButtonFunction: "CONNECT_CUSTODIAN_INVESTOR_CREATE", uploadButtonFunction: "CONNECT_CUSTODIAN_INVESTOR_CREATE",
                viewButtonFunction: "", deleteButtonFunction: "CONNECT_CUSTODIAN_INVESTOR_DELETE",
            }
        },

    },
    "compositePerf": {
        exportURL: { url: api_url + 'compositePerf/downloadExcel', },
        pdfURL: { url: 'compositePerf/downloadPDF', }
    },
    "customizationfundGroup": {
        headers: "assets/headers/manager-headers/fundGroupList.JSON",
        gridDataUrl: "fundGroupV2/records/",
        gridDeleteUrl: "fundGroup/",
        moduleName: "fundGroup",
        fileName: "FundGroupFiltered",
        filterUrl: { url: "fundGroupV2/filterByKeys" },
        gridExportUrl: { url: "fundGroupV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "fundGroupV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/setup/entity-group-entry/create" },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "FUNDGROUP_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_GROUPING",
        }
    },
    "customizationInvestorgroup": {
        userCard: 'others',
        headers: "assets/headers/manager-headers/customizationInvestorGroups.JSON",
        gridDataUrl: "InvestorGroupV2/records/",
        gridDeleteUrl: "InvestorGroup/",
        moduleName: "investorGroup",
        fileName: "InvestorGroupFiltered",
        filterUrl: { url: "InvestorGroupV2/filterByKeys" },
        gridExportUrl: { url: "InvestorGroupV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "InvestorGroupV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/investors/investor-Group-entry/create" },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "INVESTORGROUP_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "INVESTORGROUP_VIEW", deleteButtonFunction: "INVESTORGROUP_VIEW,SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORGROUP",
        }
    },
    "customizationNonCustodianList": {
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/customizationNonCustodianWorkflow.JSON",
        gridDataUrl: "custodianNotTagV2/records/",
        gridDeleteUrl: "custodianNotTag/",
        moduleName: "custodianSourceConfig",
        filterUrl: { url: "custodianNotTagV2/filterByKeys" },
        ///fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "custodianNotTagV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "custodianNotTagV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/customization/non-custodian-workflow-entry/create" },
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "none",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION",
        }
    },

    "customizationReportApproval": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/customizationReportApproval.JSON",
        gridDataUrl: "reportApprovalPeriodRuleV2/records/",
        gridDeleteUrl: "reportApprovalPeriodRule/",
        moduleName: "reportApprovalPeriodRule",
        filterUrl: { url: "reportApprovalPeriodRuleV2/filterByKeys" },
        routeUrl: { create: "/customization/report-approval-rule-entry/create", upload: "/customization/upload-report-approval-rule" },
        viewUrl: "None",
        updateUrl: "reportApprovalPeriodRuleV2/update",
        authFunctions: {
            newButtonFunction: "REPORTING_PORTAL_USER_CREATE", uploadButtonFunction: "REPORTING_PORTAL_USER_CREATE",
            viewButtonFunction: "REPORTING_PORTAL_USER_VIEW", deleteButtonFunction: "REPORTING_PORTAL_USER_DELETE",
        },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/reportApprovalPeriodRule',
            uploadUrl: "reportApprovalPeriodRuleV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/reportApprovalPeriodRule",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/reportApprovalPeriodRule/", name: "report_approval_rule", sampleName: "reportApprovalPeriodRule", dataStringify: true
        },
        uploadRedirectionUrl: "/customization/report-approval-rule",
    },
    "customization-ror-config-List": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/config-ror.JSON",
        gridDataUrl: "rorConfigV2/records/",
        gridDeleteUrl: "rorConfig/",
        moduleName: "rorConfigDto",
        filterUrl: { url: "rorConfigV2/filterByKeys" },
        gridExportUrl: { url: "rorConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "rorConfigV2/downloadFilterRecords/", isZoneName: false },
        //newButtonFunction: "TRADEENTRY_CREATE",
        // deleteButtonFunction: "TRADEENTRY_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/customization/ror-config-entry/create" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION",
        }
        // uploadDetails: {
        //     uploadUrl: "tradeEntry/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "Trades", sampleName: "trade", dataStringify: true
        // }
    },
    "customizationCheckerPendingRequest": {
        entCard: 'automated_report_delivery_log',
        headers: "assets/headers/manager-headers/checker-pending-request.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/checker/pendingWorkflow/",
        //gridDeleteUrl: "fund/delete/",
        moduleName: "checkerPendingRequest",
        filterUrl: { url: "reportDeliveryWorkflowV2/filterWorkflowByKeys" },
        // gridExportUrl: { url: "tradeEntry/downloadRecords/", isZoneName: true },
        // gridFilteredUrl: { url: "tradeEntry/downloadFilterRecords/", isZoneName: true },
        //newButtonFunction: "TRADEENTRY_CREATE",
        deleteButtonFunction: "none",
        viewUrl: "None",
        // routeUrl: { create: "/viewmanager/manager/trade-list/create", upload: "/trades/tradeupload" },
        // uploadDetails: {
        //     uploadUrl: "tradeEntry/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "Trades", sampleName: "trade", dataStringify: true
        // }
    },
    "positionAnalytics": {
        pdfURL: { url: 'positionAnalyticsReport/positionAnalyticsReport', }
    },
    "TotalgainlossFxGainLossOnCash": {
        exportURL: {
            url: api_url + 'exportController/downloadFilter',
            excelUrl: api_url + 'totalGainLossWithFX/TotalGainLossWithFX?data='
        },
        moduleName: "portData",
    },
    "customizationReportNotificationStatusList": {
        entCard: 'automated_report_delivery_log',
        headers: "assets/headers/manager-headers/customization-report-notification-status.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/",
        gridDeleteUrl: "reportDeliveryConfigurationV2/configurations/",
        moduleName: "reportNotificationStatus",
        filterUrl: { url: "reportDeliveryWorkflowV2/" },
        gridExportUrl: { url: "reportDeliveryWorkflowV2/", isZoneName: false },
        gridFilteredUrl: { url: "reportDeliveryWorkflowV2/", isZoneName: false },
        viewUrl: "/manager/report-notification-status-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "ENT_REPORTNOTIFICATIONSTATUS,FUNCTION_CREATE,ENTERPRISE_CREATE,SEAMLESS_SIDEMENU_SYSADMIN,SEAMLESS_ISD_NOTIFICATION_STATUS,SEAMLESS_ISD_GROUP_APPROVAL_FUND_LIST,SMPRIVATEFUND_VIEW,CASPINOFF_VIEW",
        }
    },

    "capitalCallReport": {
        pdfURL: { url: 'capitalCallReportController/Download', },
        csvURL: { url: 'capitalCallReportController/downloadCsv', }
    },

    "consolidateDetailedReport": {
        pdfCsvURL: { url: 'investorStatementConsolidateDetailedController/download', },
    },
    "customizationDataImportStatus": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/customizationDataImportStatus.JSON",
        gridDataUrl: "configurations/filterWorkflowByKeys?", //systemManager/alteryx/fetchAllWorklowStatus/
        method: 'post',
        getCustomTypeUrl: "configurations/fetchAllWorklowStatus/", //systemManager/alteryx/filterWorkflowByKeys
        moduleName: "dataImportStatus",
        filterUrl: { url: "configurations/filterWorkflowByKeys" },
        gridExportUrl: { url: "configurationsV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "configurationsV2/downloadFilterRecords/", isZoneName: true },
        viewUrl: "systemManager/alteryx/fetchAllWorklowStatus/",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_DIV_IMPORT,SEAMLESS_SIDEMENU_CUSTOMIZATION,DERIBITDATA_VIEW,EXCHANGERATE_VIEW,USER_CREATE",
        }
    },
    "customizationInputConfiguration": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/customizationInputConfiguration.JSON",
        gridDataUrl: "alteryxInputConfig/records/",
        gridDeleteUrl: "alteryxInputConfig/delete",
        moduleName: "inputConfiguration",
        filterUrl: { url: "alteryxInputConfig/filterByKeys" },
        customFilterUrl: { url: "alteryxInputConfig/filterByKeys", method: 'Post' },
        gridExportUrl: { url: "alteryxInputConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "alteryxInputConfigV2/exportFilteredRecords", isZoneName: false },
        routeUrl: { create: "/manager/input-configuration/create" },
        viewUrl: "/manager/input-configuration/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION",
        }
    },
    // "customizationSetupJeTransfer": {
    //     entCard: 'others',
    //     headers: "assets/headers/manager-headers/customizationSetupJeTransfer.JSON",
    //     gridDataUrl: "setupJETransferFundLevelV2/records/",
    //     gridDeleteUrl: "setupJETransferFundLevel/",
    //     moduleName: "setupJETransferFundLevelDto",
    //     filterUrl: { url: "setupJETransferFundLevelV2/filterByKeys" },
    //     gridExportUrl: { url: "formidium/setupJETransferFundLevelV2/downloadRecords/", isZoneName: false },
    //     gridFilteredUrl: { url: "/formidium/setupJETransferFundLevelV2/downloadFilterRecords/", isZoneName: false },
    //     routeUrl: { create: "/customization/setup-je-transfer-entry/create", upload: "/customization/upload-setup-je-transfer" },
    //     uploadDetails: {
    //         createDefaultConfigUrl: 'createDefaultConfigModulebase/setupJETransferFundLevel',
    //         uploadUrl: "setupJETransferFundLevelV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/setupJETransferFundLevel",
    //         getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/setupJETransferFundLevel/", name: "setup_je_transfer_fund_level", sampleName: "setupjetransferdemo", dataStringify: true
    //     },
    //     viewUrl: "None",
    //     authFunctions: {
    //         newButtonFunction: "", uploadButtonFunction: "",
    //         viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION,USER_CREATE",
    //     }
    // },

    "customizationSetupJeTransfer": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/customizationSetupJeTransfer.JSON",
        gridDataUrl: "setupJETransferFundLevelV2/records/",
        gridDeleteUrl: "setupJETransferFundLevel/",
        moduleName: "setupJETransferFundLevelDto",
        filterUrl: { url: "setupJETransferFundLevelV2/filterByKeys" },
        gridExportUrl: { url: "setupJETransferFundLevelV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "setupJETransferFundLevelV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/customization/setup-je-transfer-entry/create", upload: "/customization/upload-setup-je-transfer" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/setupJETransferFundLevel',
            uploadUrl: "setupJETransferFundLevelV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/setupJETransferFundLevel",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/setupJETransferFundLevel/", name: "setup_je_transfer_fund_level", sampleName: "setupjetransferdemo", dataStringify: true
        },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_OTHERCONFIGURATION,USER_CREATE",
        }
    },
    "customization-doc-managementList": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/doc-management.JSON",
        gridDataUrl: "documentManagementV2/fetchAllRecords/",
        gridDeleteUrl: "documentManagement/",
        moduleName: "documentManagement", //doc-managementList documentManagement
        filterUrl: { url: "documentManagementV2/filterByKeys" },
        viewUrl: "",
    },
    "customization-connectionList": {
        pathName: 'connection',
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/connection.JSON",
        gridDataUrl: "ConnectionV2/records/",///needs to change true/false once we add same on systemmanager lvl
        // gridDeleteUrl: "brokerAccount/", no delete 
        moduleName: "brokerAccount",
        fileName: "connectionListFiltered",
        filterUrl: { url: "ConnectionV2/filterByKeys" },
        gridExportUrl: { url: "ConnectionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "ConnectionV2/downloadFilterRecords", isZoneName: false },
        routeUrl: { create: "/manager/connections/create", upload: "/manager/connections/upload" },
        uploadDetails: {
            uploadUrl: "brokerAccountV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/connections",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/connections/", name: "connections ", sampleName: "connectiondemo"
        },
        viewUrl: "None",
        systemAdminViewUrl: "/systemmanager/connections/view",
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "",
            //Auth :Vg, SDI-151 Connection UI Delete Action Removed deleteButtonFunction: "NONE"
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION,SMPRIVATEFUND_VIEW",
        }

    },
    "customization-cumanageJobSchedule": {
        headers: "assets/headers/manager-headers/manage-job-schedule.JSON",
        gridDataUrl: "schedule/cronjobs",
        moduleName: "manageJobSchedule",
        routeUrl: { create: "/customization/data-scheduler-entry/create" },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA,VIEW_NON_TRADE_TRANSACTION_THIRD_PARTY",
        }
    },
    "varianceReport": {
        pdfURL: { url: api_url + 'varianceReportCtrl/varianceReportDownload', method: 'POST' },
        IframeURL: { url: 'varianceReportCtrl/varianceReportDownload', method: 'POST' },
    },
    "dailyCashReport": {
        excleURL: { url: api_url + 'dailyCashReport/getDailyCashReport', method: 'POST' },
        IFrameURL: { url: 'dailyCashReport/getDailyCashReport', method: 'POST' },
    },
    "accounttrialbalance": {
        exportURL: { url: api_url + 'accountTrailBalance/excleDownload', method: 'POST' },
        pdfURL: { url: api_url + 'accountTrailBalance/pdfDownload', method: 'POST' },
        iFrameURL: { url: 'accountTrailBalance/pdfDownload', method: 'POST' },
        getReport: { url: 'accountTrailBalance/trialBalanceRecord/', method: 'POST' },
        getSummaryReport: { url: 'trialBalance/trialBalanceRecordNewVersion/', method: 'POST' },
        excleDownload: { url: api_url + 'accountTrailBalance/excleDownload/endDate/fund/startDate/fundName', method: 'GET' },
        pdfDownload: { url: api_url + 'trialBalance/excleDownload/endDate/fund/startDate/fundName/type', method: 'GET' },
        excleDownloadNewVersion: { url: api_url + 'trialBalance/excleDownloadNewVersion/endDate/fund/startDate/fundName/type', method: 'GET' },
        pdfDownloadNewVersion: { url: api_url + 'trialBalance/excleDownloadNewVersion/endDate/fund/startDate/fundName/type', method: 'GET' }
    },
    "agedPayables": {
        exportURL: { url:  'reBillDetails/excelAndPdfDownload' },
    },
    "receivablesAgeing": {
        exportURL: { url:  'customerInvoice/excelAndPdfDownload' },
    },
    "vendorUnpaidBill": {
        exportURL: { url: 'reBillDetails/downloadsForUnpaidBill'}, 
    },
    "Outstandinginvoices": {
        exportURL: { url:  'customerInvoice/downloadsForOutstandingInvoices'}, 
    },
    "vendorTransReport" :{
        exportURL: { url: 'reBillDetails/vendorTransReport'},
    },
    "vendorTransactionReport":{
        exportURL: { url: 'reBillDetails/vendorLedger'},
    },
   
    "customerTransactionDetail":{
        exportURL: { url: 'customerInvoice/customerTransReport'},
    },
    "accountbalancesheet": {
        exportURL: { url: api_url + 'accountBalanceSheetController/excleAndPdfDownload' },
        iframeURL: { url: 'accountBalanceSheetController/excleAndPdfDownload' },
        comparativeURL: { url: api_url + 'comparativeAccountBalanceSheet/excleAndPdfDownload' },
        comparativeiFrameURL: { url: 'comparativeAccountBalanceSheet/excleAndPdfDownload' },

    },
    "incomeStatementAccount": {
        exportURL: { url: api_url + 'accountIncomeStatement/accountIncomeStatement' },
        IframeURL: { url: 'accountIncomeStatement/accountIncomeStatement' },

    },
    "accountStatementReport": {
        exportURL: { excelUrl: api_url + 'accountStatementReportController/excelAndPdfDownload' },
        pdfURL: { pdfUrl: api_url + 'accountStatementReportController/excelAndPdfDownload' },
        iframeURL: { pdfUrl: 'accountStatementReportController/excelAndPdfDownload' },

    },
    "accountposition": {
        exportURL: { url: api_url + 'accountPositionReportCtrl/excelAccPositionReport' },
    },
    "accountpositionsgain": {
        exportURL: { url: api_url + 'accountPositionReportCtrl/excelAccPositionReport' },
        iFrameURL: { url: 'accountPositionReportCtrl/excelAccPositionReport' },
    },
    "activityAcoountReport": {
        exportURL: { excelUrl: api_url + 'activityReportController/excleDownload' },
        iFrameURL: {
            pdfUrl: 'activityReportController/accountActivityReportPdf'
        },
        pdfURL: {
            pdfUrl: api_url + 'activityReportController/accountActivityReportPdf'
        },
        getReport: { url: 'accountTrailBalance/trialBalanceRecord/', method: 'POST' },
        getSummaryReport: { url: 'trialBalance/trialBalanceRecordNewVersion/', method: 'POST' },
        excleDownload: { url: api_url + 'accountTrailBalance/excleDownload/endDate/fund/startDate/fundName', method: 'GET' },
        pdfDownload: { url: api_url + 'trialBalance/excleDownload/endDate/fund/startDate/fundName/type', method: 'GET' },
        excleDownloadNewVersion: { url: api_url + 'trialBalance/excleDownloadNewVersion/endDate/fund/startDate/fundName/type', method: 'GET' },
        pdfDownloadNewVersion: { url: api_url + 'trialBalance/excleDownloadNewVersion/endDate/fund/startDate/fundName/type', method: 'GET' }
    },
    "callAndDist": {
        pdfURL: { url: 'callAndDist/callAndDistReport', }
    },

    "comprehensivePnLBySymbol": {
        pdfURL: { url: 'comprehensivePNLBySymbolController/comprehensivePNLBySymbol', },
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "pNLContribution",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_REPORTS_GAINLOSS_COMPREHENSIVE_P&L_BY_SYMBOL",
        },
    },
    "automate-process": {
        "dailyProcessingView": {
            headers: "assets/headers/system-manager-headers/rapid-dailyProcessing.JSON",
            gridDataUrl: "dataProcessingV2/getRecord/",
            moduleName: "dataProcessingConfiguration",
            filterUrl: { url: "dataProcessingV2/filterByKeys" },
            gridDeleteUrl: "dataProcessing/deleteAll",
            routeUrl: { create: "/rapid-processing/daily-processing-view/create" },
            viewUrl: "None",
            authFunctions: {
                newButtonFunction: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_CREATE", uploadButtonFunction: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_CREATE",
                viewButtonFunction: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_VIEW", deleteButtonFunction: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_DELETE",
            },
            exicuteBtn: true

        },
        "dailyProcessingStatus": {
            headers: "assets/headers/system-manager-headers/processing-systemProcessingStatus.JSON",
            gridDataUrl: "dataProcessingV2/getWorkflows/",
            moduleName: "dailyProcessingStatus",
            filterUrl: { url: "dataProcessingV2/filterByKeys" },
            gridExportUrl: { url: "dataProcessingV2/fetchAllRecords/", isZoneName: false },
            gridFilteredUrl: { url: "dataProcessingV2/fetchAllRecords/", isZoneName: false },
            authFunctions: {
                newButtonFunction: "None", uploadButtonFunction: "None",
                viewButtonFunction: "None", deleteButtonFunction: "SEAMLESS_PROCESSING_AUTOMATEPROCESS_PROCESSINGSTATUS,DAILYPROCESSING_VIEW",
            }
        }
    },
    "rapidTradeList": {
        pathName: 'trade',
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeList.JSON",
        gridDataUrl: "tradeEntryV2/records/",
        gridDeleteUrl: "tradeEntry/",
        moduleName: "tradeEntry",
        exportModuleName: "tradeEntry",
        filterUrl: { url: "tradeEntryV2/filterByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "exportController/download/tradeEntry", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/tradeEntry/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        newButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_TRADE",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_TRADE_DELETE",
        viewUrl: "/input/create/trade-entry/view",
        uploadRedirectionUrl: "/input/view/trade-list",
        uploadRedirectionUrl2: "/input/view/trade-entry-broker",
        updateUrl: "tradeEntryV2/update",
        routeUrl: { create: "/input/create/trade-entry/create", upload: "/input/upload/trade-list-upload" },
        uploadDetails: {
            uploadUrl: "tradeEntryV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/trade/", name: "trades", sampleName: "trade", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_TRADES_TRADE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_TRADES_TRADE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_TRADE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_TRADE_DELETE",
        }
    },
    "accountTrialBalance": {
        headers: "assets/headers/trade-headers/dailyMargin.JSON",
        exportURL: 'accountTrailBalance/getAccountTrialBalanceData',
        // assets/headers/trade-headers/dailyMargin.JSON
        //assets/headers/portfolio-headers/dividendAdjustment.JSON
        gridDataUrl: "omsEntryV2/records/?page=",
        filterUrl: { url: "omsEntryV2/filterByKeys" },
        gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
        isomsApproveList: true,
        isOmsEdit: false,
        isOmsCancel: false,
        gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

    },
    "GLAccount": {
        headers: "assets/headers/trade-headers/omsListDataAllo.JSON",
        exportURL: 'accountGeneralLedger/viewGeneralLedgerCustom/',
        gridDataUrl: "omsEntryV2/records/?page=",
        filterUrl: { url: "omsEntryV2/filterByKeys" },
        gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
        isomsApproveList: true,
        isOmsEdit: false,
        isOmsCancel: false,
        gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

    },
    "viewJESummary": {
        headers: "assets/headers/accounting/journalEntry.JSON",
        gridDataUrl: "omsEntryV2/records/?page=",
        filterUrl: { url: "omsEntryV2/filterByKeys" },
        gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
        isomsApproveList: true,
        isOmsEdit: false,
        isOmsCancel: false,
        gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

    },
    "viewDetail": {
        headers: "assets/headers/trade-headers/omsListDataAlloWithEmail.JSON",
        gridDataUrl: "omsEntryV2/records/?page=",
        filterUrl: { url: "omsEntryV2/filterByKeys" },
        gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
        isomsApproveList: true,
        isOmsEdit: false,
        isOmsCancel: false,
        gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

    },
    "rapidDailyMargin": {
        userCard: "other_card",
        pathName: 'daily_margin',
        headers: "assets/headers/trade-headers/dailyMargin.JSON",
        gridDataUrl: "dailyMarginV2/records/",
        gridDeleteUrl: "dailyMargin/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_DAILYMARGIN",
        moduleName: "dailyMargin",
        gridExportUrl: { url: "dailyMarginV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dailyMarginV2/filterByKeys" },
        gridFilteredUrl: { url: "dailyMarginV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/input/create/daily-margin-entry/create", upload: "/input/upload/daily-margin-upload" },
        uploadDetails: {
            uploadUrl: "dailyMarginV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dailyMargin",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dailyMargin/", name: "daily_margin", sampleName: "dailymargin"
        },
        viewUrl: "/input/create/daily-margin-entry/view",
        uploadRedirectionUrl: "/input/view/daily-margin",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_TRADES_DAILYMARGIN", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_TRADES_DAILYMARGIN",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_DAILYMARGIN", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_DAILYMARGIN_DELETE",
        }

    },
    "rapidNewIssues": {
        userCard: "other_card",
        headers: "assets/headers/trade-headers/newIssues.JSON",
        gridDataUrl: "newIssuesV2/records/",
        gridDeleteUrl: "newIssues/",
        moduleName: "newIssues",
        filterUrl: { url: "newIssuesV2/filterByKeys" },
        gridFilteredUrl: { url: "newIssuesV2/downloadFilterNewIssue/", isZoneName: false },
        gridExportUrl: { url: "newIssuesV2/downloadRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "", getCustomTypeUrl: "",
            getConfigUrl: "", name: "",
        },
        routeUrl: { create: "/input/create/new-issues-entry/create" },
        viewUrl: "/input/create/new-issues-entry/view",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_TRADES_NEWISSUES", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_TRADES_NEWISSUES",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_NEWISSUES", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_NEWISSUES_DELETE",
        }
    },
    "rapidExchangerate": {
        userCard: 'market_data',
        pathName: 'exchange_rate',
        headers: "assets/headers/portfolio-headers/exchangeRate.JSON",
        gridDataUrl: "exchangeRateV2/records/",
        gridDeleteUrl: "exchangeRate/",
        moduleName: "exchangeRate",
        gridExportUrl: { url: "exportController/download/exchangeRate", isZoneName: false },
        filterUrl: { url: "exchangeRateV2/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/exchangeRate/" },
        uploadDetails: {
            uploadUrl: "exchangeRateV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/exchangeRate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/exchangeRate/", name: "exchange_rate", sampleName: "exchangeratedemo"
        },
        routeUrl: { create: "/input/create/exchange-rate-entry/create", upload: "/input/upload/exchange-rate-upload" },
        viewUrl: "/input/create/exchange-rate-entry/view",
        uploadRedirectionUrl: "/input/view/exchange-rate",
        updateUrl: 'exchangeRateV2/update',
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_EXCHANGERATE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_EXCHANGERATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_EXCHANGERATE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_EXCHANGERATE_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_EXCHANGERATE_EDIT"
        }
    },
    "rapidMarketdata": {
        userCard: 'market_data',
        pathName: 'market_data',
        headers: "assets/headers/portfolio-headers/marketData.JSON",
        gridDataUrl: "marketDataV2/records/",
        gridDeleteUrl: "marketData/",
        newButtonFunction: "MARKETDATA_VIEW",
        moduleName: "marketData",
        gridExportUrl: { url: "exportController/download/marketData", isZoneName: false },
        filterUrl: { url: "marketDataV2/filterByKeys" },
        updateUrl: 'marketDataV2/update',
        gridFilteredUrl: { url: "exportController/downloadFilter/marketData/" },
        uploadDetails: {
            uploadUrl: "marketDataV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/marketdata",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/marketdata/", name: "market_data", sampleName: "marketData"
        },
        routeUrl: { create: "/input/create/market-data-entry/create", upload: "/input/upload/market-data-upload" },
        viewUrl: "/input/create/market-data-entry/view",
        uploadRedirectionUrl: "/input/view/market-data",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_MARKETDATA", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_MARKETDATA",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_MARKETDATA_EDIT"
        }
    },
    "rapidCapitalActivities": {
        userCard: "investors",
        pathName: "capital_activity",
        headers: "assets/headers/investor-headers/capitalactivities.JSON",
        gridDataUrl: "capitalActivityV2/records/",
        gridDeleteUrl: "capitalActivity/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_CAPITALACTIVITY",
        moduleName: "capitalActivity",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
        gridExportUrl: { url: "exportController/download/capitalActivity", isZoneName: false },
        filterUrl: { url: "capitalActivityV2/filterByKeys" },
        updateUrl: 'capitalActivityV2/update',
        gridFilteredUrl: { url: "exportController/downloadFilter/capitalActivity/", isZoneName: false },
        //  viewUrl: "/investor/capital-activities/view",
        routeUrl: { create: "/input/create/capital-activity-entry/create", upload: "/input/upload/capital-activities-upload" },
        uploadDetails: {
            uploadUrl: "capitalActivityV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/capitalActivity/", name: "capital_activity", sampleName: "capitalactivity", dataStringify: false
        },
        viewUrl: "/input/create/capital-activity-entry/view",
        uploadRedirectionUrl: "/input/view/capital-activities",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_INVESTORS_CAPITALACTIVITY", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_INVESTORS_CAPITALACTIVITY",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_CAPITALACTIVITY", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_CAPITALACTIVITY_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_CAPITALACTIVITY_EDIT"
        }
    },

    "disableAutoUpdateViaAllocationUpdate": {
        userCard: "investors",
        pathName: "capital_activity",
        headers: "assets/headers/investor-headers/capital_Activities/disableAutoUpdateHeaders.JSON",
        gridDataUrl: "capitalActivityV2/fetchRecords/",
        gridDeleteUrl: "capitalActivity/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE",
        moduleName: "capitalActivityDisableAutoUpdate",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
        gridExportUrl: { url: "exportController/download/Disable Auto Update Via Allocation Update", isZoneName: false },
        filterUrl: { url: "capitalActivityV2/filterByKeys" },
        updateUrl: 'capitalActivityV2/update',
        fileName: "CapitalActivityFiltered",
        gridFilteredUrl: { url: "exportController/downloadFilter?moduleName=Disable Auto Update Via Allocation Update", isZoneName: false },
        //  viewUrl: "/investor/capital-activities/view",
        routeUrl: { create: "/input/create/capital-activity-entry/create", upload: "/input/upload/disableAutoUpdateViaAllocation-upload" },
        uploadDetails: {
            uploadUrl: "capitalActivityV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/Disable Auto Update Via Allocation Update",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/Disable Auto Update Via Allocation Update/", name: "disable_auto_update", sampleName: "disableAutoUpdateViaAllocationUpdate", dataStringify: false,
            getUpdateConfigUrl: "dataConfiguration/fetchAllByDomainAndType/Disable Auto Update Via Allocation Update/", getUpdateCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivityUpdate",
            updateUploadUrl: "capitalActivityV2/newUpdate/Disable Auto Update Via Allocation Update", createDefaultConfigUrl: "createDefaultConfigModulebase/Disable Auto Update Via Allocation Update"
        },
        viewUrl: "/input/create/capital-activity-entry/view",
        uploadRedirectionUrl: "/input/view/disableAutoUpdateViaAllocationUpdate",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE_CREATE", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_DISABLEAUTOUPDATEVIAALLOCATIONUPDATE_EDIT"
        },
        editableColumnArray: {
            "Redemption - Partial by Amount": ["shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Transfer": ["shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Full": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Partial - by share or Unit": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Partial - by % value of Account": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Subscription - First Time": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "Subscription - Additional": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "Subscription - Transfer": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "default": ["effectivedate", "timing", "dateOfEntry", "currency", "fundedCommitmentApplicable", "shares", "amount", "investor.investorNumber", "fund.fundName", "shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "reductionInLCF", "fundingFactor", "fundingFactor"]
        }
    },

    "redemptionActivityUpdate": {
        userCard: "investors",
        pathName: "capital_activity",
        headers: "assets/headers/investor-headers/capital_Activities/redemptionActivityUpdateHeaders.JSON",
        gridDataUrl: "capitalActivityV2/fetchRecords/",
        gridDeleteUrl: "capitalActivityRedemption/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE",
        moduleName: "capitalActivityRedemptionActivityUpdate",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
        gridExportUrl: { url: "exportController/download/Redemption Activity Update", isZoneName: false },
        filterUrl: { url: "capitalActivityV2/filterByKeys" },
        updateUrl: 'capitalActivityV2/update',
        fileName: "CapitalActivityFiltered",
        gridFilteredUrl: { url: "exportController/downloadFilter?moduleName=Redemption Activity Update", isZoneName: false },
        //  viewUrl: "/investor/capital-activities/view",
        routeUrl: { create: "/input/create/capital-activity-entry/create", upload: "/input/upload/redemptionActivityUpdate-upload" },
        uploadDetails: {
            uploadUrl: "capitalActivityV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/Redemption Activity Update",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/Redemption Activity Update/", name: "redemption_activity", sampleName: "redemptionActivityUpdate", dataStringify: false,
            getUpdateConfigUrl: "dataConfiguration/fetchAllByDomainAndType/Redemption Activity Update/", getUpdateCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivityUpdate",
            updateUploadUrl: "capitalActivityV2/newUpdate/Redemption Activity Update", createDefaultConfigUrl: "createDefaultConfigModulebase/Redemption Activity Update"
        },
        viewUrl: "/input/create/capital-activity-entry/view",
        uploadRedirectionUrl: "/input/view/redemptionActivityUpdate",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE_CREATE", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_INVESTORS_REDEMPTIONACTIVITYUPDATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_REDEMPTIONACTIVITYUPDATE_EDIT"
        },
        editableColumnArray: {
            "Redemption - Partial by Amount": ["shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Transfer": ["shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Full": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Partial - by share or Unit": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Partial - by % value of Account": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Subscription - First Time": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "Subscription - Additional": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "Subscription - Transfer": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "default": ["effectivedate", "timing", "dateOfEntry", "currency", "fundedCommitmentApplicable", "shares", "amount", "investor.investorNumber", "fund.fundName", "shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "reductionInLCF", "fundingFactor", "fundingFactor"]
        }
    },


    "subscriptionActivityUpdate": {
        userCard: "investors",
        pathName: "capital_activity",
        headers: "assets/headers/investor-headers/capital_Activities/subscriptionActivityUpdateHeaders.JSON",
        gridDataUrl: "capitalActivityV2/fetchRecords/",
        gridDeleteUrl: "capitalActivity/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE",
        moduleName: "capitalActivitySubscriptionActivityUpdate",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivity",
        gridExportUrl: { url: "exportController/download/Subscription Activity Update", isZoneName: false },
        filterUrl: { url: "capitalActivityV2/filterByKeys" },
        updateUrl: 'capitalActivityV2/update',
        fileName: "CapitalActivityFiltered",
        gridFilteredUrl: { url: "exportController/downloadFilter?moduleName=Subscription Activity Update", isZoneName: false },
        //  viewUrl: "/investor/capital-activities/view",
        routeUrl: { create: "/input/create/capital-activity-entry/create", upload: "/input/upload/subscriptionActivityUpdate-upload" },
        uploadDetails: {
            uploadUrl: "capitalActivityV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/Subscription Activity Update",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/Subscription Activity Update/", name: "subscription_activity", sampleName: "subscriptionActivityUpdate", dataStringify: false,
            getUpdateConfigUrl: "dataConfiguration/fetchAllByDomainAndType/Subscription Activity Update/", getUpdateCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/capitalActivityUpdate",
            updateUploadUrl: "capitalActivityV2/newUpdate/Subscription Activity Update", createDefaultConfigUrl: "createDefaultConfigModulebase/Subscription Activity Update"
        },
        viewUrl: "/input/create/capital-activity-entry/view",
        uploadRedirectionUrl: "/input/view/subscriptionActivityUpdate",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE_CREATE", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_SUBSCRIPTIONACTIVITYUPDATE_EDIT"
        },
        editableColumnArray: {
            "Redemption - Partial by Amount": ["shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Transfer": ["shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Full": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Partial - by share or Unit": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Redemption - Partial - by % value of Account": ["amount", "shares", "shareClass", "series", "perValueOfAc", "reductionInLCF", "fundingFactor", "descriptionNotes"],
            "Subscription - First Time": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "Subscription - Additional": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "Subscription - Transfer": ["shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "fundingFactor", "descriptionNotes"],
            "default": ["effectivedate", "timing", "dateOfEntry", "currency", "fundedCommitmentApplicable", "shares", "amount", "investor.investorNumber", "fund.fundName", "shareClass", "series", "fundingMethod", "assetBasedFee", "performanceFee", "reductionInLCF", "fundingFactor", "fundingFactor"]
        }
    },



    "custodianListLegacy": {
        pathName: 'custodians',
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/custodian.JSON",
        gridDataUrl: "brokerAccountV2/records/",
        // gridDeleteUrl: "brokerAccount/", no delete 
        moduleName: "brokerAccount",
        fileName: "custodianListFiltered",
        filterUrl: { url: "brokerAccountV2/filterByKeys" },
        gridExportUrl: { url: "brokerAccountV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "brokerAccountV2/downloadFilterRecords", isZoneName: false },
        // routeUrl: { create: "/manager/custodians/create", upload: "/manager/custodians/upload" },
        routeUrl: { create: "/setup/custodianst-legacy-entry/create", upload: "/setup/custodians-legacy-upload" },
        uploadDetails: {
            uploadUrl: "brokerAccountV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/custodian",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/custodian/", name: "Custodian ", sampleName: "custodiandemo"
        },
        viewUrl: "/setup/custodianst-legacy-entry/view",
        systemAdminViewUrl: "/systemmanager/custodians/view",
        authFunctions: {
            newButtonFunction: "BROKERACCOUNT_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "USER_CREATE,SEAMLESS_CUSTOMIZATION_DIV_PROCESSCONFIGURATION,SMPRIVATEFUND_VIEW",
        }
    },
    "rapidSetupbondinterest": {
        userCard: 'bond',
        pathName: 'setup_bond_interest',
        headers: "assets/headers/portfolio-headers/setupbondinterest.JSON",
        gridDataUrl: "bondInterestAccrualV2/records/",
        gridDeleteUrl: "bondInterestAccrual/",
        newButtonFunction: "FUNDCLASS_VIEW",
        moduleName: "bondInterestAccrual",
        updateUrl: 'bondInterestAccrualV2/update',
        gridExportUrl: { url: "bondInterestAccrualV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "bondInterestAccrualV2/filterByKeys" },
        gridFilteredUrl: { url: "bondInterestAccrualV2/downloadFilterRecords/" },

        uploadDetails: {
            uploadUrl: "bondInterestAccrualV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/BondInterestAccrual",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/BondInterestAccrual/", name: "bond_interest", sampleName: "bondinterestaccsample"
        },
        // routeUrl: { create: "/portfolio/setup-bond-interest/create", upload: "/portfolio/setup-bond-interest/upload" },
        // viewUrl: "/portfolio/setup-bond-interest/view",
        routeUrl: { create: "/input/create/setup-bond-interest-entry/create", upload: "/input/upload/setup-bond-interest-upload" },
        viewUrl: "/input/create/setup-bond-interest-entry/view",
        uploadRedirectionUrl: "/input/view/setup-bond-interest",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTEREST", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTEREST",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTEREST", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTEREST_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTEREST_EDIT"
        }
    },
    "rapidDividend": {
        userCard: 'dividend',
        pathName: 'dividend_accrual',
        headers: "assets/headers/portfolio-headers/dividendAccrual.JSON",
        gridDataUrl: "dividendV2/records/",
        gridDeleteUrl: "dividend/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDEND",
        moduleName: "dividend",
        updateUrl: 'dividendV2/update',
        gridExportUrl: { url: "dividendV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dividendV2/filterByKeys" },
        gridFilteredUrl: { url: "dividendV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "dividendV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dividendAccrual",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dividendAccrual/", name: "dividend", sampleName: "dividendaccrualdemo"
        },
        routeUrl: { create: "/input/create/dividend-entry/create", upload: "/input/upload/dividend-upload" },
        viewUrl: "/input/create/dividend-entry/view",
        uploadRedirectionUrl: "/input/view/dividend", authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDEND", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDEND",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDEND", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDEND_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDEND_EDIT"
        }

    },
    "rapidBondinterestadjustment": {
        userCard: 'bond',
        pathName: 'bond_interest_adjustment',
        headers: "assets/headers/portfolio-headers/bondIinterestAdjustment.JSON",
        gridDataUrl: "bondInterestAdjustmentV2/records/",
        gridDeleteUrl: "bondInterestAdjustment/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTERESTADJUSTMENT",
        moduleName: "bondInterestAdjustment",
        gridExportUrl: { url: "bondInterestAdjustmentV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "bondInterestAdjustmentV2/filterByKeys" },
        gridFilteredUrl: { url: "bondInterestAdjustmentV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "bondInterestAdjustmentV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/bondInterestAdjustment",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/bondInterestAdjustment/", name: "bond_interest_adjustment", sampleName: "bondinterestadjustmentaccsample"
        },
        viewUrl: "/input/create/bond-interest-adjustment-entry/view",
        uploadRedirectionUrl: "/input/view/bond-interest-adjustment",
        routeUrl: { create: "/input/create/bond-interest-adjustment-entry/create", upload: "/input/upload/bond-interest-adjustment-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTERESTADJUSTMENT", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_BONDINTERESTADJUSTMENT",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BONDINTERESTADJUSTMENT", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDEND_DELETE",
        }
    },
    "RapidDividendadjuctment": {
        userCard: 'dividend',
        pathName: 'dividend_adjustment',
        headers: "assets/headers/portfolio-headers/dividendAdjustment.JSON",
        gridDataUrl: "dividendAdjustmentV2/records/",
        gridDeleteUrl: "dividendAdjustment/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDENDADJUSTMENT",
        moduleName: "dividendAdjustment",
        gridExportUrl: { url: "dividendAdjustmentV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dividendAdjustmentV2/filterByKeys" },
        gridFilteredUrl: { url: "dividendAdjustmentV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "dividendAdjustmentV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dividendAdjustment",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dividendAdjustment/", name: "dividend_adjustment", sampleName: "dividendadjustmentccsample"
        },
        routeUrl: { create: "/input/create/dividend-adjustment-entry/create", upload: "/input/upload/dividend-adjustment-upload" },
        viewUrl: "/input/create/dividend-adjustment-entry/view",
        uploadRedirectionUrl: "/input/view/dividend-adjustment",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDENDADJUSTMENT", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_DIVIDENDADJUSTMENT",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDENDADJUSTMENT", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_DIVIDENDADJUSTMENT_DELETE",
        }
    },
    'rapidNonTradeActivity': {
        headers: 'assets/headers/accounting/nonTradeActivity.JSON',
        gridDataUrl: 'nonTradeTransactionsV2/records/',
        gridDeleteUrl: "nonTradeTransactions/",
        moduleName: "nonTradeTransactions",
        updateUrl: 'nonTradeTransactionsV2/update',
        exportModuleName: "nonTradeTransactions",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_NONTRADEACTIVITY_DELETE",
        filterUrl: { url: "nonTradeTransactionsV2/filterByKeys" },
        gridExportUrl: { url: "exportController/download/nonTradeTransactions", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/nonTradeTransactions/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        newButtonFunction: 'CHARTOFACCOUNTS_VIEW',
        routeUrl: { create: "/input/create/nontradeactivity-entry/create", upload: "/input/upload/nonTradeActivity-upload" },
        viewUrl: "/input/create/nontradeactivity-entry/view",
        uploadRedirectionUrl: "/input/view/nontradeactivity",
        uploadRedirectionUrl2: "/reconciliation/view-non-trade-trans-tp",
        uploadDetails: {
            uploadUrl: "nonTradeTransactionsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/nonTradeTransactions",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/nonTradeTransactions/", name: "non_trade_activity", sampleName: "nontradetransactions", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_NONTRADEACTIVITY", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_NONTRADEACTIVITY",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_NONTRADEACTIVITY", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_NONTRADEACTIVITY_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_NONTRADEACTIVITY_EDIT"
        }
    },
    "customizationReportsApproval": {
        headers: "assets/headers/manager-headers/reportsApproval.JSON",
        headersISDContribution: "assets/headers/manager-headers/clientApproval.JSON",

        gridDataUrl: "reportDeliveryWorkflowV2/enterprise/workFlow/checkerapprovedlist",
        gridHistoricalApprovalUrl: "reportDeliveryWorkflowV2/enterprise/workFlow/historicalcheckerapprovedlist",
        downloadurl: "formidium/document/downloadDocument",

        moduleName: "reportDeliveryWorkflowV2",
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },
    "enableInvestorReDistribution": {
        headers: "assets/headers/manager-headers/enableInvestorReDistribution.JSON",
        gridDataUrl: "reportDeliveryWorkflowV2/enterprise/workFlow/isdgrid",
        headersISREDistribution: "assets/headers/manager-headers/enableISDistribution.JSON",

        moduleName: "enableInvestorReDistribution",
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },



    "customizationISDistribution": {
        headers: "assets/headers/manager-headers/isDistribution.JSON",
        headersISDContribution: 'assets/headers/manager-headers/ISDContribution.JSON',
        gridDataUrl: 'reportDeliveryWorkflowV2/enterprise/workFlow/isdgrid',
        moduleName: "customizationISDistribution",
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None",
            deleteButtonFunction: "SEAMLESS_ISD_CLIENT_APPROVAL_FUND_LIST,SEAMLESS_DISTRIBUTION_DIV_INVESTORSTATEMENTDISTRIBUTION,SEAMLESS_ISD_CLIENT_APPROVED_FUND_VIEW",
        }
    },
    "rapidSmEquitiesList": {
        pathName: 'sm_equities',
        userCard: 'securities_master',
        headers: "assets/headers/portfolio-headers/smEquities.JSON",
        gridDataUrl: "sMEquitiesV2/records/",
        gridDeleteUrl: "sMEquities/",
        moduleName: "sMEquities",
        gridExportUrl: { url: "sMEquitiesV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesV2/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/sMEquities", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "sMEquities/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMEquitiesV2/downloadFilterSMEquitiesRecord/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMEquitiesV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquities",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquities/", name: "sm_equities_upload_header", sampleName: "smequities", dataStringify: true
        },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-equities",
        updateUrl: "sMEquitiesV2/update",
        routeUrl: { create: "/input/create/sm-equities-entry/create", upload: "/input/upload/sm-equities-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_EQUITIES", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_EQUITIES",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_EQUITIES", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_EQUITIES_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_EQUITIES_EDIT"
        }

    },
    "rapidSmFxForwardList": {
        userCard: 'securities_master',
        pathName: 'sm_fx_forward',
        headers: "assets/headers/portfolio-headers/smFxForward.JSON",
        gridDataUrl: "sMFXForwardV2/records/",
        gridDeleteUrl: "sMFXForward/",
        moduleName: "sMFXForward",
        updateUrl: 'sMFXForwardV2/update',
        gridExportUrl: { url: "sMFXForwardV2/exportSMFxForward/", isZoneName: false },
        filterUrl: { url: "sMFXForwardV2/filterByKeys" },
        filterDeleteUrl: { url: "sMFXForward/filterByKeysDelete" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMFXForward", isZoneName: false },
        gridFilteredUrl: { url: "sMFXForwardV2/exportFilterSMFxForward/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFXForwardV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFXForward",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFXForward/", name: "fx_forward", sampleName: "smfxforward", dataStringify: true
        },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-fx-forward",
        routeUrl: { create: "/input/create/sm-fx-forward-entry/create", upload: "/input/upload/sm-fx-forward-upload" },

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_FXFORWARD", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_FXFORWARD",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FXFORWARD", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FXFORWARD_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FXFORWARD_EDIT"

        }
    },
    "RapidSmOptionsList": {
        userCard: 'securities_master',
        pathName: 'sm_options',
        headers: "assets/headers/portfolio-headers/smEquitiesOption.JSON",
        gridDataUrl: "sMEquitiesOptionsV2/records/",
        gridDeleteUrl: "sMEquitiesOptions/",
        moduleName: "sMEquitiesOptions",
        updateUrl: "sMEquitiesOptionsV2/update",
        gridExportUrl: { url: "sMEquitiesOptionsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesOptionsV2/filterByKeys" },
        filterDeleteUrl: { url: "sMEquitiesOptions/filterByKeysDelete" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMEquitiesOptions", isZoneName: false },
        gridFilteredUrl: { url: "sMEquitiesOptionsV2/downloadFilterSMEquitiesOptionRecord/", isZoneName: false },
        // viewUrl: "/portfolio/sm-options/view",
        uploadDetails: {
            uploadUrl: "sMEquitiesOptionsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquitiesOption",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquitiesOption/", name: "sm_options_upload_header", sampleName: "smequitiesoptions", dataStringify: true
        },
        // routeUrl: { create: "/portfolio/sm-options/create", upload: "/portfolio/sm-options/upload" },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-options",
        routeUrl: { create: "/input/create/sm-options-entry/create", upload: "/input/upload/sm-options-upload" },

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_OPTIONS", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_OPTIONS",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_OPTIONS", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_OPTIONS_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_OPTIONS_EDIT"
        }
    },
    "rapidItdSubscriptionAndRedemption": {
        userCard: "investors",
        pathName: "itd_subscription_and_redemption",
        headers: "assets/headers/investor-headers/itdSubscriptionandRedemption.JSON",
        gridDataUrl: "ITDSubscriptionandRedemptionV2/records/",
        gridDeleteUrl: "ITDSubscriptionandRedemption/",
        moduleName: "ItdSubscriptionAndRedemption",
        // fileName: "InvestorGroupFiltered",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ITDSubscriptionandRedemption",
        gridExportUrl: { url: "exportController/download/ItdSubscriptionAndRedemption", isZoneName: false },
        filterUrl: { url: "ITDSubscriptionandRedemptionV2/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/ItdSubscriptionAndRedemption/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "ITDSubscriptionandRedemptionV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ITDSubscriptionandRedemption",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ITDSubscriptionandRedemption/", name: "itd_subscription_and_redemption", sampleName: "itdsubscriptionandredemption", dataStringify: false
        },
        // viewUrl: "/investor/investor-group/view",
        // routeUrl: { create: "/investor/investor-group/create", upload: "/portfolio/sm-equities/upload" },
        viewUrl: "/input/view/investor-group-entry/view",
        uploadRedirectionUrl: "/input/view/itd-subscription-redemption",
        routeUrl: { upload: "/input/upload/itd-subscription-redemption-upload" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_INVESTORS_ITDSUBSCRIPTIONANDREDEMPTION",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_ITDSUBSCRIPTIONANDREDEMPTION_DELETE",
        }
    },
    "rapidSmFixedIncomeList": {
        userCard: 'securities_master',
        pathName: 'fixed_income',
        headers: "assets/headers/portfolio-headers/SmFixedIncome.JSON",
        gridDataUrl: "sMFixedIncomeV2/records/",
        gridDeleteUrl: "sMFixedIncome/",
        moduleName: "sMFixedIncome",
        updateUrl: 'sMFixedIncomeV2/update',
        gridExportUrl: { url: "sMFixedIncomeV2/exportSMFixedIncome/", isZoneName: false },
        filterUrl: { url: "sMFixedIncomeV2/filterByKeys" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMFixedIncome", isZoneName: false },
        filterDeleteUrl: { url: "sMFixedIncome/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMFixedIncomeV2/exportFilterSMFixedIncome/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFixedIncomeV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFixedIncome",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFixedIncome/", name: "fixed_income", sampleName: "smfixedincome", dataStringify: true
        },
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FIXEDINCOME_DELETE",
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-fixed-income",
        routeUrl: { create: "/input/create/sm-fixed-income-entry/create", upload: "/input/upload/sm-fixed-income-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_FIXEDINCOME", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_FIXEDINCOME",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FIXEDINCOME", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FIXEDINCOME_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FIXEDINCOME_EDIT"
        }
    },
    "RapidSmPrivateFundList": {
        userCard: 'securities_master',
        pathName: 'sm_private_fund',
        headers: "assets/headers/portfolio-headers/smPrivateFund.JSON",
        gridDataUrl: "sMPrivateFundV2/records/",
        gridDeleteUrl: "sMPrivateFund/",
        moduleName: "sMPrivateFund",
        updateUrl: "sMPrivateFundV2/update",
        gridExportUrl: { url: "sMPrivateFundV2/exportSMPrivate/", isZoneName: false },
        filterUrl: { url: "sMPrivateFundV2/filterByKeys" },
        filterDeleteUrl: { url: "sMPrivateFund/filterByKeysDelete" },
        newgridExportUrl: { url: "exportController/download/sMPrivateFund", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        gridFilteredUrl: { url: "sMPrivateFundV2/exportFilterSMPrivate/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMPrivateFundV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMPrivateFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMPrivateFund/", name: "private_fund", sampleName: "smprivatefund", dataStringify: true
        },
        // viewUrl: "/portfolio/sm-private-fund/view",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_PRIVATEFUND_DELETE",
        // routeUrl: { create: "/portfolio/sm-private-fund/create", upload: "/portfolio/sm-private-fund/upload" },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-private-fund",
        routeUrl: { create: "/input/create/sm-private-fund-entry/create", upload: "/input/upload/sm-private-fund-upload" },

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_PRIVATEFUND", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_PRIVATEFUND",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_PRIVATEFUND", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_PRIVATEFUND_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_PRIVATEFUND_EDIT"
        }
    },
    "RapidSmFutureList": {
        userCard: 'securities_master',
        pathName: 'futures',
        headers: "assets/headers/portfolio-headers/smFuturesListData.JSON",
        gridDataUrl: "sMFuturesV2/records/",
        gridDeleteUrl: "sMFutures/",
        moduleName: "sMFutures",
        updateUrl: "sMFuturesV2/update",
        gridExportUrl: { url: "sMFuturesV2/exportSMFutures/", isZoneName: false },
        filterUrl: { url: "sMFuturesV2/filterByKeys" },
        filterDeleteUrl: { url: "sMFutures/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMFuturesV2/exportFilterSMFutures/", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMFutures", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFuturesV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFutures",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFutures/", name: "security_futures", sampleName: "smfutures", dataStringify: true
        },
        //  viewUrl: "/portfolio/sm-futures/view",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FUTURES_DELETE",
        //  routeUrl: { create: "/portfolio/sm-futures/create", upload: "/portfolio/sm-futures/upload" },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-future",
        routeUrl: { create: "/input/create/sm-future-entry/create", upload: "/input/upload/sm-future-upload" },

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_FUTURES", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_FUTURES",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FUTURES", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FUTURES_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_FUTURES_EDIT"
        }
    },
    "RapidSmRealestateList": {
        userCard: 'securities_master',
        pathName: 'real_estate',
        headers: "assets/headers/portfolio-headers/smRealEstate.JSON",
        gridDataUrl: "sMRealEstateController/records/",
        gridDeleteUrl: "sMRealEstate/",
        moduleName: "sMRealEstate",
        gridExportUrl: { url: "sMRealEstateController/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMRealEstateController/filterByKeys" },
        filterDeleteUrl: { url: "sMRealEstateController/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMRealEstateController/downloadFilterRecords/", isZoneName: true },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMRealEstate", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMRealEstateController/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMRealEstate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMRealEstate/", name: "real_estate", sampleName: "sMRealEstate", dataStringify: true
        },
        //  viewUrl: "/portfolio/sm-real-estate/view",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_REALESTATE_DELETE",
        //  routeUrl: { create: "/portfolio/sm-real-estate/create", upload: "/portfolio/sm-real-estate/upload" },
        viewUrl: "/input/create/sm-real-estate-entry/view",
        uploadRedirectionUrl: "/input/view/sm-real-estate",
        routeUrl: { create: "/input/create/sm-real-estate-entry/create", upload: "/input/upload/sm-real-estate-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_REALESTATE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_REALESTATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_REALESTATE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_REALESTATE_DELETE",
        }
    },
    "rapidInvestors": {
        pathName: 'investor',
        userCard: "investors",
        headers: "assets/headers/investor-headers/investors.JSON",
        gridDataUrl: "investorV2/records/",
        isExtraParams: true,
        extraParams: "/false",
        gridDeleteUrl: "tradeEntry/",
        newButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORS",
        moduleName: "investor",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
        gridExportUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        filterUrl: { url: "investorV2/filterByKeys/", isZoneName: true },
        gridFilteredUrl: { url: "exportController/downloadFilter/investors/", isZoneName: false },
        updateUrl: "investorV2/update",
        //  viewUrl: "/investor/investors/view",
        //  routeUrl: { create: "/investor/investors/create", upload: "/investor/investors/upload" },
        viewUrl: "/input/create/investor-entry/view",
        uploadRedirectionUrl: "/input/view/investor",
        routeUrl: { create: "/input/create/investor-entry/create", upload: "/input/upload/investor-upload" },
        uploadDetails: {
            uploadUrl: "investorV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investor",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investor/", name: "investors", sampleName: "investordemo", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_INVESTORS_INVESTORS", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_INVESTORS_INVESTORS",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORS", deleteButtonFunction: "SEAMLESS_INPUT_ VIEW_INVESTORS_INVESTORS_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORS_EDIT"
        }
    },
    "rapidInvestorgroup": {
        userCard: "investors",
        headers: "assets/headers/investor-headers/investorgroup.JSON",
        gridDataUrl: "InvestorGroupV2/records/",
        gridDeleteUrl: "InvestorGroup/",
        moduleName: "investorGroup",
        fileName: "InvestorGroupFiltered",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "InvestorGroupV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "InvestorGroupV2/filterByKeys" },
        gridFilteredUrl: { url: "InvestorGroupV2/downloadFilterRecords/", isZoneName: false },
        // viewUrl: "/investor/investor-group/view",
        // routeUrl: { create: "/investor/investor-group/create", upload: "/portfolio/sm-equities/upload" },
        viewUrl: "/input/create/investor-group-entry/view",
        uploadRedirectionUrl: "/input/view/investor-group",
        routeUrl: { create: "/input/create/investor-group-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_INVESTORS_INVESTORGROUP", uploadButtonFunction: "none",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORGROUP", deleteButtonFunction: "INVESTORGROUP_VIEW,SEAMLESS_INPUT_VIEW_INVESTORS_INVESTORGROUP",
        }
    },
    "rapidRecurringTransaction": {
        headers: "assets/headers/settings-header/accountingSetting/recurringTransactionData.JSON",
        gridDataUrl: "recurringTransactionV2/records/",
        gridDeleteUrl: "recurringTransaction/",
        moduleName: "recurringTransaction",
        gridExportUrl: { url: "recurringTransactionV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "recurringTransactionV2/filterByKeys" },
        gridFilteredUrl: { url: "recurringTransactionV2/downloadFilterRecords/", isZoneName: false },
        updateUrl: "recurringTransactionV2/update",
        uploadDetails: {
            uploadUrl: "recurringTransactionV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/recurringTransaction",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/recurringTransaction/", name: "recurring_transaction", sampleName: "recurringtransactiondemo", dataStringify: false
        },
        viewUrl: "/input/create/recurring-transaction-entry/view",
        uploadRedirectionUrl: "/input/view/recurring-transaction",
        routeUrl: { create: "/input/create/recurring-transaction-entry/create", upload: "/input/upload/recurring-transaction-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_RECURRINGTRANSACTION", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_RECURRINGTRANSACTION",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_RECURRINGTRANSACTION", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_RECURRINGTRANSACTION_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_RECURRINGTRANSACTION_EDIT"
        }
    },
    "RapidSeamlessmarketdata": {
        userCard: 'market_data',
        pathName: 'seamless_market_data',
        headers: "assets/headers/portfolio-headers/seamlessmarketData.JSON",
        gridDataUrl: "seamlessMarketDataV2/records/",
        gridDeleteUrl: "seamlessMarketData/",
        moduleName: "seamlessMarketData",
        gridExportUrl: { url: "seamlessMarketDataV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "seamlessMarketDataV2/filterByKeys" },
        gridFilteredUrl: { url: "seamlessMarketDataV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "seamlessMarketDataV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/seamlessMarketData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/seamlessMarketData/", name: "seamless_market_data", sampleName: "seamlessmarketdata"
        },
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_SEAMLESSMARKETDATA_DELETE",
        newButtonFunction: "None",
        uploadRedirectionUrl: "/input/view/seamless-market-data",
        routeUrl: { create: "", upload: "/input/upload/seamless-market-data-upload" },
        // routeUrl: { create: "/trades/trade-list/create", upload: "/portfolio/seamless-market-data/upload" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_SEAMLESSMARKETDATA",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_SEAMLESSMARKETDATA_DELETE",
        }
    },
    "RapidCryptoMarketData": {
        userCard: 'market_data',
        pathName: 'crypto_market_data',
        exportURL: { url: api_url + 'cryptoMarketData/downloadData/' },
        exportTickerURL: { url: api_url + 'cryptoMarketData/downloadTicker/' },
        uploadDetails: {
            uploadUrl: "cryptoMarketDataV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/cryptoMarketData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/cryptoMarketData/", name: "crypto_market_data", sampleName: "cryptoMarketData"
        },
        //   routeUrl: { create: "/portfolio/sub-sectors/create", upload: "/portfolio/uploadSubsectors" },
        //  viewUrl: '/portfolio/sub-sectors/view'
    },
    "RapidSmPools": {
        userCard: 'securities_master',
        pathName: 'pools',
        headers: "assets/headers/portfolio-headers/smPools.JSON",
        gridDataUrl: "sMPrivateFundV2/records/",
        gridDeleteUrl: "sMPrivateFund/",
        moduleName: "sMPrivateFund",
        updateUrl: "sMPrivateFundV2/update",
        gridExportUrl: { url: "sMPrivateFundV2/exportSMPrivate/", isZoneName: false },
        filterUrl: { url: "sMPrivateFundV2/filterByKeys" },
        filterDeleteUrl: { url: "sMPrivateFund/filterByKeysDelete" },
        newgridExportUrl: { url: "exportController/download/sMPrivateFund", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        gridFilteredUrl: { url: "sMPrivateFundV2/exportFilterSMPrivate/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMPrivateFundV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMPrivateFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMPrivateFund/", name: "pools", sampleName: "smpools", dataStringify: true
        },
        // viewUrl: "/portfolio/sm-private-fund/view",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_POOLS_DELETE",
        // routeUrl: { create: "/portfolio/sm-private-fund/create", upload: "/portfolio/sm-private-fund/upload" },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-pools",
        routeUrl: { create: "/input/create/sm-pools-entry/create", upload: "/input/upload/sm-pools-upload" },

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_POOLS", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_POOLS",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_POOLS", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_POOLS_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_POOLS"
        }
    },
    "rapidCrypto": {
        pathName: 'sm_equities',
        userCard: 'securities_master',
        headers: "assets/headers/portfolio-headers/smEquities.JSON",
        gridDataUrl: "sMEquitiesV2/records/",
        gridDeleteUrl: "sMEquities/",
        moduleName: "sMEquities",
        updateUrl: "sMEquitiesV2/update",
        gridExportUrl: { url: "sMEquitiesV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesV2/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/sMEquities", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "sMEquities/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMEquitiesV2/downloadFilterSMEquitiesRecord/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMEquitiesV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquities",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquities/", name: "crypto", sampleName: "rapidCrypto", dataStringify: true
        },
        viewUrl: "None",
        uploadRedirectionUrl: "/input/view/sm-crypto",
        routeUrl: { create: "/input/create/sm-crypto-entry/create", upload: "/input/upload/sm-crypto-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_CRYPTO", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_SECURITYMASTER_CRYPTO",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_CRYPTO", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_CRYPTO_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_CRYPTO_EDIT"

        }

    },
    "rapidHistoricalwashsaleopenLot": {
        userCard: "tax_process",
        pathName: 'historical_wash_sale_openlot',
        headers: "assets/headers/trade-headers/historicalwashsaleopenLot.JSON",
        gridDataUrl: "historicalWashsaleV2/records/",
        moduleName: "washSaleHistoricalOpenLot",
        gridExportUrl: { url: "historicalWashsaleV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "historicalWashsaleV2/filterByKeys" },
        gridFilteredUrl: { url: "historicalWashsaleV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/trades/trade-list/create", upload: "/input/upload/historical-wash-upload" },
        uploadRedirectionUrl: "/input/view/historical-wash",
        uploadDetails: {
            uploadUrl: "historicalWashsaleV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/historicalWashSaleOpenLotData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradeAllocationConfig/", name: "historical_wash_sale_openlot", sampleName: "historicalwashsaleopenlotlist"
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_TRADES_HISTORICAL_WASHSALE_OPENLOTS",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_HISTORICAL_WASHSALE_OPENLOTS",
        }
    },
    "RapidTransferTradeRawDataHistory": {
        userCard: "other_card",
        pathName: 'transfer_trade',
        headers: "assets/headers/trade-headers/transferTradeRawDataHistory.JSON",
        gridDataUrl: "transferTradeRawData/records/",
        gridDeleteUrl: "transferTradeRawData/",
        moduleName: "transferTradeRowData",
        gridExportUrl: { url: "transferTradeV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "transferTradeRawData/filterByKeys" },
        gridFilteredUrl: { url: "transferTradeV2/downloadFilterRecords/", isZoneName: false },
        //  viewUrl: "/input/create/transfer-trade-history/view",
        uploadRedirectionUrl: "/input/view/transfer-trade-history",
        routeUrl: { create: "/input/create/transfer-trade-history/create", upload: "/input/upload/transfer-trade-history-upload" },

        //  routeUrl: { create: "/trades/transfer-trade-raw-data-history/create", upload: "/trades/transfer-trade-raw-data-history/upload" },
        uploadDetails: {
            uploadUrl: "transferTradeRawData/uploadTransferInput", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transfertradetaxlot",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transfertradetaxlot/", name: "transfer_trade", sampleName: "transfertradetaxlot"
        },
        viewUrl: "/trades/transfer-trade/view",
        authFunctions: {
            deleteButtonFunction: "TRADEENTRY_TRANSFERTRADE_RAWDATA,SEAMLESS_INPUT_VIEW_TRADES_TRANSFERTRADE_RAWDATA",
        }
    },
    "RapidJournalentry": {
        headers: "assets/headers/accounting/journalEntry.JSON",
        gridDataUrl: "journalEntryRevisedV2/records/",
        filterUrl: { url: "journalEntryRevisedV2/filterByKeys" },
        newButtonFunction: 'TRONIQ_INPUT_CREATE_ACCOUNTING_COMPOUNDJOURNALENTRY',
        deleteButtonFunction: 'BENCHMARK_DELETE',
        gridDeleteUrl: "journalEntryRevisedV2/",
        moduleName: "journalEntryRevised",
        exportModuleName: "journalEntryRevised",
        updateUrl: "journalEntryRevisedV2/update",
        // routeUrl: { create: "/accounting/journalEntry-entry/create", upload: '/accounting/journalEntryUpload' },
        uploadDetails: {
            uploadUrl: "journalEntryRevisedV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/journalEntry",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/journalEntry/", name: "journal_entry", sampleName: "journalentrydemo", dataStringify: true
        },
        // viewUrl: "/accounting/journalEntry-entry/view",
        viewUrl: "/input/create/journalEntry-entry/view",
        gridExportUrl: { url: "exportController/downloadFilter/journalEntryRevised/", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/journalEntryRevised/" },
        uploadRedirectionUrl: "/input/view/journalEntry",
        routeUrl: { create: "/input/create/journalEntry-entry/create", upload: "/input/upload/journalEntry-upload" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "TRONIQ_INPUT_CREATE_ACCOUNTING_COMPOUNDJOURNALENTRY", uploadButtonFunction: "TRONIQ_INPUT_CREATE_ACCOUNTING_COMPOUNDJOURNALENTRY",
            viewButtonFunction: "TRONIQ_INPUT_VIEW_ACCOUNTING_COMPOUNDJOURNALENTRY", deleteButtonFunction: "BENCHMARK_DELETE",
        }

    },
    "rapidCompoundJEListComponent": {
        headers: "assets/headers/accounting/compoundJeList.JSON",
        gridDataUrl: "CompoundJournalEntryRevisedV2/records/",
        filterUrl: { url: "CompoundJournalEntryRevisedV2/filterByKeys" },
        newButtonFunction: 'TRONIQ_INPUT_CREATE_ACCOUNTING_COMPOUNDJOURNALENTRY',
        deleteButtonFunction: 'TRONIQ_INPUT_DELETE_ACCOUNTING_COMPOUNDJOURNALENTRY',
        gridDeleteUrl: "CompoundJournalEntryRevisedV2/",
        moduleName: "CompoundJournalEntryRevised",
        exportModuleName: "CompoundJournalEntryRevised",
        uploadDetails: {
           
            uploadUrl: "CompoundJournalEntryRevisedV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/compoundJe",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/compoundJe/", name: "compound_journal_entry", sampleName: "compoundJe", dataStringify: true
        },
        // viewUrl: "/accounting/journalEntry-entry/view",
        viewUrl: "/input/create/compound-journal-entry/view",
        gridExportUrl: { url: "exportController/downloadFilter/CompoundJournalEntryRevised/", isZoneName: false },
        // gridFilteredUrl: { url: "/sudrania/exportController/downloadFilter/journalEntryRevised/" },
        uploadRedirectionUrl: "/input/view/compound-journal-entry-list",
        routeUrl: { create: "/input/create/compound-journal-entry/create", upload: "/input/upload/compoundjournalEntry-upload" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "TRONIQ_INPUT_CREATE_ACCOUNTING_COMPOUNDJOURNALENTRY", uploadButtonFunction: "TRONIQ_INPUT_UPLOAD_ACCOUNTING_COMPOUNDJOURNALENTRY",
            viewButtonFunction: "TRONIQ_INPUT_VIEW_ACCOUNTING_COMPOUNDJOURNALENTRY", deleteButtonFunction: "TRONIQ_INPUT_DELETE_ACCOUNTING_COMPOUNDJOURNALENTRY",
        }

    },
    "rapidOmslist": {
        "unallocated_order": {
            headers: "assets/headers/trade-headers/omsListData.JSON",
            gridDataUrl: "omsEntryV2/records/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeys" },
            gridDeleteUrl: "omsEntry/",
            moduleName: "oMSTrade",
            isOmsEdit: true,
            isOmsCancel: true,
            gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },
            routeUrl: { create: "/input/create/oms-entry/create" },
            viewUrl: "/input/create/oms-entry/view",
            authFunctions: {
                newButtonFunction: "SEAMLESS_INPUT_CRAETE_TRADES_ORDERMANAGEMENTSYSTEM", uploadButtonFunction: "SEAMLESS_INPUT_CRAETE_TRADES_ORDERMANAGEMENTSYSTEM",
                viewButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_OMS", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_OMS_DELETE",
            }

        },
        "allocated_order": {
            headers: "assets/headers/trade-headers/omsListDataAllo.JSON",
            gridDataUrl: "omsEntryV2/records/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeys" },
            gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
            isomsApproveList: true,
            isOmsEdit: false,
            isOmsCancel: false,
            gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

        },
        "unapproved_order": {
            headers: "assets/headers/trade-headers/omsListDataAlloWithEmail.JSON",
            gridDataUrl: "omsEntryV2/records/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeys" },
            gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
            isomsApproveList: true,
            isOmsEdit: false,
            isOmsCancel: false,
            gridExportUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },

        },
        "approved_order": {
            headers: "assets/headers/trade-headers/omsListDataApproved.JSON",
            gridDataUrl: "omsEntryV2/getApprovedOrderRecords/",
            filterUrl: { url: "omsEntryV2/filterByKeysApproved" },
            gridOMSAllocatedUrl: "omsEntryV2/findByIdWithStatus/",
            gridExportUrl: { url: "omsEntryV2/downloadAllAssetClass/", isZoneName: false },
            gridExportWellUrl: { url: "omsEntryV2/downloadMortgageDeal/MortgageDeal/", isZoneName: false },
            gridExportWellForgoUrl: { url: "omsEntryV2/downloadRepoDeal/RepoDeal/", isZoneName: false },
            gridExportUSBankUrl: { url: "omsEntryV2/downloadUSExport/", isZoneName: false },
            isomsApproveList: true,
            isOmsCancel: false,
            omsCancelApprove: true,
            isomsEditList: true
        },
        "Real Time Positions": {
            headers: "assets/headers/trade-headers/omsListDataRealTimePos.JSON",
            gridDataUrl: "omsEntryV2/getBalanceWithAccountAndticker/?page=",
            filterUrl: { url: "omsEntryV2/filterByKeysRealTimePosition" },
            gridExportUrl: { url: "omsEntryV2/downloadPosition", isZoneName: false },
            isomsApproveList: false,
            isOmsEdit: false,
            isOmsCancel: false
        }
    },
    "rapidViewtotalgl": {
        userCard: 'pl_and_positions',
        headers: "assets/headers/portfolio-headers/rapidViewtotalGl.JSON",
        gridDataUrl: "totalGainLossReportV2/allRecords/",
        gridDeleteUrl: "totalGainLossReport/",
        moduleName: "totalGainLossReports",
        exportModuleName: "totalGainLossReports",
        gridExportUrl: { url: "exportController/download/totalGainLossReports", isZoneName: false },
        filterUrl: { url: "totalGainLossReportV2/filterByKeys" },
        // gridFilteredUrl: { url: "exportController/downloadFilter/totalGainLossReports/" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "TOTAL_GAIN_LOSS_REPORTS_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_PORTFOLIO_TOTALGAINLOSS_REPORTS_VIEW",
        }

    },
    "transferTradeStatus": {
        "transferTradeHistory": {
            userCard: "other_card",
            pathName: 'transfer_trade',
            headers: "assets/headers/trade-headers/transferTradeHistory.JSON",
            gridDataUrl: "transferTradeV2/records/",
            gridDeleteUrl: "sourceDestTradeTransferTaxLotInfo/",
            moduleName: "sourceDestTradeTransferTaxLotInfo",
            gridExportUrl: { url: "transferTradeV2/downloadRecords/", isZoneName: false },
            filterUrl: { url: "transferTradeV2/filterByKeys" },
            gridFilteredUrl: { url: "transferTradeV2/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/trades/transfer-trade/create", upload: "/trades/transfertradeHistoryUpload" },
            uploadDetails: {
                uploadUrl: "transferTradeV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transfertradetaxlot",
                getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transfertradetaxlot/", name: "transfer_trade", sampleName: "transfertradetaxlot"
            },
            viewUrl: "/trades/transfer-trade/view",
            authFunctions: {
                newButtonFunction: "TRADE_ENTRY_TRANSFER_TRADE_NEW_VERSION_CREATE", uploadButtonFunction: "TRADE_ENTRY_TRANSFER_TRADE_NEW_VERSION_UPLOAD",
                viewButtonFunction: "TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW", deleteButtonFunction: "TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW,TRADEENTRY_TRANSFERTRADE_NEWVERSION,SIDEMENU_TRADES,SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TRANSFERTRADE_STATUS",//view button is not show only delete button is showing on grid
            }

        },
        "transferProcessStatusDayWise": {
            userCard: "other_card",
            headers: "assets/headers/trade-headers/sequentialProcessStatusReview.JSON",
            gridDataUrl: "sequentialProcessStatusReview/records/",
            gridDeleteUrl: "sourceDestTradeTransferTaxLotInfo/",
            moduleName: "sourceDestTradeTransferTaxLotInfo",
            gridExportUrl: { url: "sequentialProcessStatusReview/downloadRecords/", isZoneName: false },
            filterUrl: { url: "sequentialProcessStatusReview/filterByKeys" },
            gridFilteredUrl: { url: "sequentialProcessStatusReview/downloadFilterRecords/", isZoneName: false },
            routeUrl: { create: "/trades/transfer-trade/create", upload: "/trades/transfertradeHistoryUpload" },
            uploadDetails: {
                uploadUrl: "transferTradeRawData/uploadTransferInput", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transfertradetaxlot",
                getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transfertradetaxlot/", name: "transfer_trade", sampleName: "transfertradetaxlot"
            },
            viewUrl: "/trades/transfer-trade/view",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "",
                viewButtonFunction: "", deleteButtonFunction: "TRADEENTRY_TRANSFERTRADE_PROCESSREVIEW,TRADEENTRY_TRANSFERTRADE_NEWVERSION,SIDEMENU_TRADES,SEAMLESS_PROCESSING_EXPORTVIEW_TRADES_TRANSFERTRADE_STATUS"
            }

        },

    },
    'rapidTLRegime': {
        headers: 'assets/headers/accounting/TLRegime.JSON',
        gridDataUrl: 'tLRegimeV2/records/',
        gridDeleteUrl: 'tLRegime/',
        moduleName: "tlRegime",
        filterUrl: { url: "tLRegimeV2/filterByKeys" },
        uploadDetails: {
            uploadUrl: "tLRegimeV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tlRegime",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tlRegime/", name: "tl_regime", sampleName: "tlregimedemo", dataStringify: false
        },
        routeUrl: { create: "/input/create/TLRegime-entry/create", upload: "/input/upload/TLRegime-upload" },
        viewUrl: "/input/create/TLRegime-entry/view",
        gridExportUrl: { url: "tLRegimeV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "tLRegimeV2/downloadFiltertLRegime/" },
        uploadRedirectionUrl: "/input/view/TLRegime",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_TLREGIME", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_TLREGIME",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TLREGIME", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TLREGIME_DELETE",
        }
    },
    'rapidTradingLevelChange': {
        headers: 'assets/headers/accounting/tradingLevelChange.JSON',
        gridDataUrl: "capitalActivityTraderV2/records/",
        gridDeleteUrl: 'capitalActivityTrader/',
        moduleName: "capitalActivityTrader",
        filterUrl: { url: "capitalActivityTraderV2/filterByKeys" },
        newButtonFunction: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TRADINGLEVELCHANGE',
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TRADINGLEVELCHANGE_DELETE",
        uploadDetails: {
            uploadUrl: "capitalActivityTraderV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/tradingLevelChange",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/tradingLevelChange/", name: "trading_level_change", sampleName: "tradinglevelchangedemo", dataStringify: false
        },
        routeUrl: { create: "/input/create/tradingLevelChange-entry/create", upload: "/input/upload/tradingLevelChange-upload" },
        viewUrl: "/input/create/tradingLevelChange-entry/view",
        uploadRedirectionUrl: "/input/view/tradingLevelChange",
        gridExportUrl: { url: "capitalActivityTraderV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "capitalActivityTraderV2/downloadFilterCapitalActivityTrader/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_TRADINGLEVELCHANGE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_TRADINGLEVELCHANGE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TRADINGLEVELCHANGE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TRADINGLEVELCHANGE_DELETE",
        }
    },
    'rapidProgramHistoricalData': {
        headers: 'assets/headers/accounting/programHistoricalData.JSON',
        gridDataUrl: 'ctaHistoricalDataV2/records/',
        gridDeleteUrl: 'ctaHistoricalData/',
        moduleName: "ctaHistoricalData",
        filterUrl: { url: "ctaHistoricalDataV2/filterByKeys" },
        newButtonFunction: 'ACCOUNTING_CTASPECIFIC_PROGRAMHISTORICALDATA_CREATE',

        //  routeUrl: { create: "/accounting/programHistoricalData-entry/create", upload: "/accounting/programHistoricalDataUpload" },
        //  viewUrl: '/accounting/programHistoricalData-entry/view',
        uploadDetails: {
            uploadUrl: "ctaHistoricalDataV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ctaHistoricalData",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ctaHistoricalData/", name: "program_historical_data", sampleName: "programhistoricaldatalist", dataStringify: false
        },
        routeUrl: { create: "/input/create/programHistoricalData-entry/create", upload: "/input/upload/programHistoricalData-upload" },
        viewUrl: "/input/create/programHistoricalData-entry/view",
        uploadRedirectionUrl: "/input/view/programHistoricalData",
        gridExportUrl: { url: "ctaHistoricalDataV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "ctaHistoricalDataV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_PROGRAMHISTORICALDATA", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_PROGRAMHISTORICALDATA",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_PROGRAMHISTORICALDATA", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_PROGRAMHISTORICALDATA_DELETE",
        }
    },
    "rapidSpecialAllocation": {
        headers: 'assets/headers/accounting/specialAllocation.JSON',
        gridDataUrl: 'specialAllocationV2/records/',
        gridDeleteUrl: 'specialAllocation/',
        moduleName: "specailAllocation",
        filterUrl: { url: "specialAllocationV2/filterByKeys" },
        newButtonFunction: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_SPECIALALLOCATION',
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SPECIALALLOCATION_DELETE",
        routeUrl: { create: "/input/create/specialAllocation-entry/create", upload: "/input/upload/specialAllocation-upload" },
        viewUrl: '/input/create/specialAllocation-entry/view',
        uploadRedirectionUrl: "/input/view/specialAllocation",
        uploadDetails: {
            uploadUrl: "specialAllocationV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/specialAllocation",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/specialAllocation/", name: "special_allocation", sampleName: "specilallocation", dataStringify: false
        },
        gridExportUrl: { url: "specialAllocationV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "specialAllocationV2/downloadFilterSpecialAllocation/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_SPECIALALLOCATION", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_SPECIALALLOCATION",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SPECIALALLOCATION", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SPECIALALLOCATION_DELETE",
        }
    },
    "rapidUploadallocationhistory": {
        headers: "assets/headers/accounting/uploadallocationhistory.JSON",
        gridDataUrl: "investorCapitalSummaryPEV2/historicalInvestorCapitalSummaryRecords/",
        moduleName: "investorCapitalSummary",
        filterUrl: { url: "investorCapitalSummaryPEV2/filterByKeys" },
        filterDeleteUrl: { url: "investorCapitalSummaryPE/filterByKeysDelete" },
        gridExportUrl: { url: "investorCapitalSummaryPEV2/downloadRecords/", isZoneName: true },
        gridFilteredUrl: { url: "investorCapitalSummaryPEV2/downloadFilterRecords/", isZoneName: false },
        //deleteButtonFunction: 'ACCOUNTING_ALLOCATION_HISTORY_DELETE',
        gridDeleteUrl: "investorCapitalSummaryPE/",
        newButtonFunction: "null",
        uploadRedirectionUrl: "/input/view/uploadallocationhistory",
        uploadDetails: {
            uploadUrl: "investorCapitalSummaryPEV2/historicalInvestorCapitalSummaryUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/historicalInvestorCapitalSummary",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/historicalInvestorCapitalSummary/", name: "allocation_history", sampleName: "historicalinvestorcapitalsummarydemo", dataStringify: true
        },
        routeUrl: { upload: "/input/upload/uploadallocationhistory-upload" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_ACCOUNTING_ALLOCATION_HISTORY",
            viewButtonFunction: "None", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_ALLOCATION_HISTORY_DELETE",
        }
    },
    "allsmupdate": {
        moduleName: "allsmupdate",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/allsmupdate',
            uploadUrl: "AllSMDownload/uploadAllSMV2/SMFutures/allSMs?smUploadConfigType=Default", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/allsmupdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/allsmupdate/", name: "all_sm_update", sampleName: "allsmupdate", dataStringify: true
        }
    },
    "fsAllSMupdate": {
        moduleName: "fsAllSMupdate",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/smupdate',
            uploadUrl: "AllSMDownloadV2/smUpdate/{smType}", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/smupdate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/smupdate/", name: "all_sm_fs_update_change", sampleName: "fsallsmupdate", dataStringify: true
        }
    },
    "rapidCustomRatio": {
        headers: 'assets/headers/accounting/customRatio.JSON',
        gridDataUrl: 'uploadRatioV2/records/',
        gridDeleteUrl: 'uploadRatio/',
        moduleName: "customRatio",
        filterUrl: { url: "uploadRatioV2/filterByKeys" },
        newButtonFunction: 'SEAMLESS_INPUT_VIEW_ACCOUNTING_CUSTOM_RATIO',
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CUSTOM_RATIO_DELETE",
        uploadDetails: {
            uploadUrl: "uploadRatioV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ratio",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ratio/", name: "custom_ratio", sampleName: "ratiodemo", dataStringify: false
        },
        // routeUrl: { create: "/accounting/customRatio-entry/create", upload: "/accounting/customRatioUpload" },
        // viewUrl: '/accounting/customRatio-entry/view',
        routeUrl: { create: "/input/create/custom-ratio-entry/create", upload: "/input/upload/custom-ratio-upload" },
        viewUrl: "/input/create/custom-ratio-entry/view",
        uploadRedirectionUrl: "/input/view/custom-ratio",
        gridExportUrl: { url: "uploadRatioV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "uploadRatioV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_PROGRAMHISTORICALDATA", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_PROGRAMHISTORICALDATA",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CUSTOM_RATIO", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CUSTOM_RATIO_DELETE",
        }
    },
    "rapidVendorsUpload": {
        pathName: 'vendors_update',
        entCard: 'others',
        headers: "assets/headers/settings-header/CreateNew/vendors.JSON",
        gridDataUrl: "vendorV2/records/",
        gridDeleteUrl: "vendorV2/",
        moduleName: "vendor",
        gridExportUrl: { url: "vendorV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "vendorV2/filterByKeys" },
        gridFilteredUrl: { url: "vendorV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "VendorUploadController/uploadVenderFromEnt", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/vendorUpload",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/vendorUpload/", name: "vendors_update", sampleName: "vendorsupload", dataStringify: false
        },
        viewUrl: "/setting/vendors/view",
        routeUrl: { create: "/setting/vendors/create", upload: "/VendorUploadController/uploadVenderFromEnt" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_VENDOR_UPLOAD", uploadButtonFunction: "SEAMLESS_VENDOR_UPLOAD",
            viewButtonFunction: "SEAMLESS_VENDOR_UPLOAD", deleteButtonFunction: "SEAMLESS_VENDOR_UPLOAD",
        }
    },
    "rapidViewPerfBasedFees": {
        headers: 'assets/headers/accounting/rapidViewPerfBasedFees.JSON',
        gridDataUrl: 'performanceFeeV2/records/',
        moduleName: "performanceFee",
        filterUrl: { url: "performanceFeeV2/filterByKeys" },
        gridExportUrl: { url: "performanceFeeV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "performanceFeeV2/downloadFilterData/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "PERFORMANCE_BASED_FEE_CALCULATION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_PERFORMANCE_FEE_CALCULATION_VIEW",
        }
    },
    "rapidSidepocketcreation": {
        headers: 'assets/headers/accounting/sidepocketcreation.JSON',
        gridDataUrl: "sidePocketConfigV2/records/",
        filterUrl: { url: "sidePocketConfigV2/filterByKeys" },
        //newButtonFunction: 'SIDE_POCKET_CONFIGURATION_CREATE',
        //deleteButtonFunction: 'SIDE_POCKET_CONFIGURATION_DELETE',
        moduleName: "sidePocketConfiguration",
        gridExportUrl: { url: "sidePocketConfigV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "sidePocketConfigV2/downloadFilterRecords/" },
        // routeUrl: { create: "/accounting/sidepocketcreation-entry/create", upload: '/accounting/sidePocketCreationUpload' },
        //  viewUrl: '/accounting/sidepocketcreation-entry/view',
        routeUrl: { create: "/input/create/side-pocket-creation-entry/create", upload: "/input/upload/side-pocket-creation-upload" },
        viewUrl: "/input/create/side-pocket-creation-entry/view",
        uploadRedirectionUrl: "/input/view/side-pocket-creation",
        uploadDetails: {
            uploadUrl: "sidePocketConfigV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sidePocketConfig",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sidePocketConfig/", name: "side_pocket_creation", sampleName: "sidepocketcreation", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_SIDEPOCKET", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_SIDEPOCKET",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SIDEPOCKET", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SIDEPOCKET_DELETE",
        }

    },

    "rapidSidepocketredemption": {
        headers: 'assets/headers/accounting/sidepocketredemption.JSON',
        gridDataUrl: "sidePocketRedemptionV2/records/",
        filterUrl: { url: "sidePocketRedemptionV2/filterByKeys" },
        //newButtonFunction: 'SIDE_POCKET_REDEMPTION_CREATE',
        //deleteButtonFunction: 'SIDE_POCKET_REDEMPTION_DELETE',
        moduleName: "sidePocketRedemption",
        // routeUrl: { create: "/accounting/sidepocketredemption-entry/create" },
        // viewUrl: '/accounting/sidepocketredemption-entry/view',
        routeUrl: { create: "/input/create/side-pocket-redemption-entry/create" },
        viewUrl: "/input/create/side-pocket-redemption-entry/view",
        gridExportUrl: { url: "sidePocketRedemptionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "sidePocketRedemptionV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_SIDEPOCKET_REDEMPTION", uploadButtonFunction: "none",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SIDEPOCKET_REDEMPTION", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_SIDEPOCKET_REDEMPTION_DELETE",
        }


    },
    'rapidMidPeriodReset': {
        headers: 'assets/headers/accounting/midPeriodReset.JSON',
        gridDataUrl: 'midPeriodResetV2/records/',
        gridDeleteUrl: "midPeriodReset/",
        moduleName: "midPeriod",
        newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_MID_PERIOD_RESET",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MID_PERIOD_RESET_DELETE",
        filterUrl: { url: "midPeriodResetV2/filterByKeys" },
        // viewUrl: '/accounting/midPeriodReset-entry/view',
        // routeUrl: { create: "/accounting/midPeriodReset-entry/create", },
        routeUrl: { create: "/input/create/mid-period-reset-entry/create" },
        viewUrl: "/input/create/mid-period-reset-entry/view",
        gridExportUrl: { url: "midPeriodResetV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "midPeriodResetV2/downloadFilterMidPeriod/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_MID_PERIOD_RESET", uploadButtonFunction: "none",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MID_PERIOD_RESET", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MID_PERIOD_RESET_DELETE",
        }
    },
    "rapidAssestBasedFee": {
        "assetBasedFeeList": {
            headers: 'assets/headers/accounting/rapidAssetBasedFeeList.JSON',
            gridDataUrl: 'assetBaseFeeV2/records/',
            moduleName: "assetBaseFee",
            filterUrl: { url: "assetBaseFeeV2/filterByKeys" },
            gridExportUrl: { url: "assetBaseFeeV2/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: "assetBaseFeeV2/downloadFilterData/" },
            authFunctions: {
                newButtonFunction: "none", uploadButtonFunction: "none",
                viewButtonFunction: "none", deleteButtonFunction: "ASSETS_BASE_FEE_CALCULATION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_ASSETBASEDFEE_CALCULATION_VIEW",
            }
        },
        "assetBasedFeeCalculation": {
            headers: 'assets/headers/accounting/rapidAssetBasedFeeCalculation.JSON',
            gridDataUrl: 'assetBaseFeeV2/calculaterecords/',
            moduleName: "assetBaseFeeCalculation",
            filterUrl: { url: "assetBaseFeeV2/calculationfilterByKeys" },
            gridExportUrl: { url: "assetBaseFeeV2/downloadCalculateRecords/", isZoneName: false },
            gridFilteredUrl: { url: "assetBaseFeeV2/downloadFilterCalculate/" },
            authFunctions: {
                newButtonFunction: "none", uploadButtonFunction: "none",
                viewButtonFunction: "none", deleteButtonFunction: "ASSETS_BASE_FEE_CALCULATION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_ASSETBASEDFEE_CALCULATION_VIEW",
            }
        },

    },
    "rapidHurdleCalculation": {
        exportURL: { url: api_url + 'calculateHurdleV2/excleDownload/' },
    },

    "rapidjesummary": {
        headers: "assets/headers/accounting/rapidViewJeSummary.JSON",
        gridDataUrl: "jesummaryreportV2/WithoutTickerSymbol/",
        filterUrl: { url: "jesummaryreportV2/filterBywithoutTicker?" },
        method: 'post',
        newButtonFunction: 'None',
        deleteButtonFunction: 'None',
        gridExportUrl: { url: "exportController/download/jeSummary", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/jeSummary/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "none",
        }
    },
    "rapidViewjesummaryByAccount": {
        exportURL: { url: api_url + 'jesummaryreportV2/downloadRecordsExcel' },
        csvURL: { url: api_url + 'jesummaryreportV2/downloadRecordsCSV' },
        moduleName: "",
        file: "JeSummaryByAccount"
    },
    "nfaReport": {
        exportURL: { url: api_url + 'nfaReportController/excelDownload' },
        moduleName: "",
        file: "nfaReport"
    },
    "rapidWashSales": {
        exportURL: { url: api_url + 'washsaleV2/downloadExcel', method: 'GET' },
    },
    "rapidTradeArchiveList": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeArchiveList.JSON",
        gridDataUrl: "tradeEntryCopy/records/",
        gridDeleteUrl: "tradeEntry/",
        moduleName: "tradeArchiveList",
        filterUrl: { url: "tradeEntryCopy/filterByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        gridExportUrl: { url: "exportController/download/tradeEntryArchival", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/tradeEntryArchival/", isZoneName: false },
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "TRADEENTRY_ARCHIVE_LIST_VIEW,SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TRADEARCHIVELIST_VIEW,SIDEMENU_TRADES,SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME",
        }
    },
    "rapidResetOpenTaxLots": {
        fetchTicket: "tradeEntryV2/getDitinctTicker?accountNo=",
        fetchBroker: 'brokerAccountV2/getMultipleAccountNumber?fundList',
        resetOpenInfo: 'openTaxLots/reset-open-tax-lots'
    },
    "rapidPerformanceAnalytics": {
        exportURL: { url: api_url + 'dataAnalyticsV2/DownloadCSV/' },
    },
    'rapidViewDistribution': {
        headers: 'assets/headers/accounting/viewDistribution.JSON',
        gridDataUrl: 'distributionV2/records/',
        gridDeleteUrl: 'distribution/',
        moduleName: "distribution",
        filterUrl: { url: "distributionV2/filterByKeys" },
        newButtonFunction: 'None',
        gridExportUrl: { url: "distributionV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "distributionV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "DISTRIBUTION_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_DISTRIBUTION_VIEW",
        }
    },

    'rapidViewCapitalCall': {
        headers: 'assets/headers/accounting/viewCapitalCall.JSON',
        gridDataUrl: 'capitalCallV2/records/',
        gridDeleteUrl: 'capitalCall/',
        moduleName: "capitalCall",
        filterUrl: { url: "capitalCallV2/filterByKeys" },
        newButtonFunction: 'None',
        gridExportUrl: { url: "capitalCallV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "capitalCallV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "CAPITAL_CALL_VIEW,SEAMLESS_PROCESSING_EXPORTVIEW_ACCOUNTING_CAPITALCALL_VIEW",
        }
    },
    "rapidBenchmarkirr": {
        userCard: 'irr',
        pathName: 'benchmark_irr',
        headers: "assets/headers/portfolio-headers/benchmarkIrr.JSON",
        gridDataUrl: "irrWorkingV2/records/",
        gridDeleteUrl: "irrWorking/",
        moduleName: "xirrBenchMarkWorking",
        newButtonFunction: 'null',
        gridExportUrl: { url: "irrWorkingV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "irrWorkingV2/filterByKeys" },
        gridFilteredUrl: { url: "irrWorkingV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "irrWorkingV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/targetAllocation",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/targetAllocation/", name: "benchmark_irr", sampleName: "irrworkingdatademo"
        },
        // uploadDetails: { uploadUrl: "irrWorking/upload", name: "Benchmark Irr", sampleName: "irrworkingdatademo" },
        // routeUrl: { create: "/trades/trade-list/create", upload: "/portfolio/bench-mark-irr/upload" },
        routeUrl: { create: "", upload: "/input/upload/bench-mark-irr-upload" },
        //  viewUrl: "/input/create/side-pocket-creation-entry/view",
        uploadRedirectionUrl: "/input/view/bench-mark-irr",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "BENCHMARK_CREATE",
            viewButtonFunction: "None", deleteButtonFunction: "BENCHMARK_DELETE",
        }

    },
    "rapidUploadcashBalance": {

        uploadDetails: {
            uploadUrl: "cashBalanceBrokerV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/cashBalance",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/cashBalance/", name: "cashbalance", sampleName: "cashbalance"
        },
        uploadRedirectionUrl: "/input/view/cash-balance-broker",
    },
    "rapidUploadPositionBroker": {

        uploadDetails: {
            uploadUrl: "positionBrokerV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/position",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/position/", name: "position_broker", sampleName: "position"
        },
        uploadRedirectionUrl: "/input/view/position-broker",
    },
    "rapidUploadDividendBroker": {
        pathName: 'dividend_broker',
        userCard: 'securities_master',
        headers: "assets/headers/portfolio-headers/dividendBroker.JSON",
        gridDataUrl: "dividendReconcileController/records/",
        gridDeleteUrl: "dividendBroker/",
        moduleName: "dividendBroker",
        gridExportUrl: { url: "dividendBroker/downloadRecords/", isZoneName: false },
        filterUrl: { url: "dividendReconcileController/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/dividendBroker", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "dividendBroker/filterByKeysDelete" },
        gridFilteredUrl: { url: "dividendBroker/downloadFilterSMEquitiesRecord/", isZoneName: false },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/dividendBroker',
            uploadUrl: "dividendReconcileController/uploadDividendBroker", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dividendBroker",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dividendBroker/", name: "dividend_broker_upload_header", sampleName: "dividendbroker", dataStringify: true
        },
        viewUrl: "/input/create/sm-equities-entry/view",
        uploadRedirectionUrl: "/input/view/dividend-broker",
        updateUrl: "sMEquitiesV2/update",
        routeUrl: { create: "", upload: "/input/upload/dividend-broker-upload" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION_DIVIDEND_BROKER",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_DIVIDEND_BROKER_DELETE",
            updateButtonFunction: "none"
        }
    },
    "rapidPositionBroker": {
        headers: "assets/headers/reconciliation/positionBroker.json",
        gridDataUrl: "positionBrokerV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "positionBroker",
        gridFilteredUrl: { url: "positionBrokerV2/downloadFilterRecords/", isZoneName: false },
        gridExportUrl: { url: "positionBrokerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "positionBrokerV2/filterByKeys" },
        newButtonFunction: "None",
        updateUrl: 'positionBrokerV2/update',
        viewUrl: "/input/create/position-broker-entry/view",
        routeUrl: { create: "/input/create/position-broker-entry/create", upload: "/input/upload/upload-position-broker" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION_POSITION_BROKER", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION_POSITION_BROKER",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_POSITION_BROKER_LIST", deleteButtonFunction: "RECONCILIATION_POSITION_BROKER_NEW_VIEW,SEAMLESS_INPUT_VIEW_RECONCILIATION_POSITION_BROKER_LIST",
            updateButtonFunction: "SEAMLESS_INPUT_CREATE_RECONCILIATION_POSITIONBROKER_EDIT"
        }
    },
    "rapidDividendBroker": {
        pathName: 'dividend_broker',
        userCard: 'securities_master',
        headers: "assets/headers/portfolio-headers/dividendBroker.JSON",
        gridDataUrl: "dividendReconcileController/records/",
        gridDeleteUrl: "deleteController/",
        moduleName: "dividendBroker",
        gridExportUrl: { url: "exportController/download/dividendBroker", isZoneName: false },
        filterUrl: { url: "dividendReconcileController/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/dividendBroker", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "dividendReconcileController/uploadDividendBroker", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dividendBroker",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dividendBroker/", name: "sm_equities_upload_header", sampleName: "dividendBroker", dataStringify: true
        },
        viewUrl: "",
        uploadRedirectionUrl: "/input/view/dividend-broker",
        updateUrl: "sMEquitiesV2/update",
        routeUrl: { create: "", upload: "/input/upload/dividend-broker-upload" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_RECONCILIATION_DIVIDEND_BROKER",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_DIVIDEND_BROKER_DELETE",
            updateButtonFunction: "none"
        }
    },
    "rapidCashBalanceBroker": {
        headers: "assets/headers/reconciliation/cashBalanceBroker.json",
        gridDataUrl: "cashBalanceBrokerV2/records/",
        gridDeleteUrl: "setupExpenseAmortization/",
        moduleName: "cashBalanceBroker",
        gridExportUrl: { url: "cashBalanceBrokerV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "cashBalanceBrokerV2/downloadFilterRecords/", isZoneName: false },
        filterUrl: { url: "cashBalanceBrokerV2/filterByKeys" },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_CASHBALANCE_LIST",
        }
    },
    'rapidTakeOverPerfBaseFee': {
        headers: 'assets/headers/accounting/takeOverPerfBaseFee.JSON',
        gridDataUrl: 'takeOverPerformanceBaseFeeV2/records/',
        gridDeleteUrl: 'takeOverPerformanceBaseFee/',
        moduleName: "takeOverPerformanceBaseFee",
        filterUrl: { url: "takeOverPerformanceBaseFeeV2/filterByKeys" },
        newButtonFunction: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE',
        gridExportUrl: { url: "takeOverPerformanceBaseFeeV2/downloadRecords/", isZoneName: false },
        //  routeUrl: { create: "/accounting/takeover-perf-base-fee-entry/create", upload: "/accounting/takeOverPerfBaseFeeUpload" },
        uploadDetails: {
            uploadUrl: "takeOverPerformanceBaseFeeV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/takeOverPerformanceBaseFee",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/takeOverPerformanceBaseFee/", name: "take_over_perf_base_fee", sampleName: "topbfee", dataStringify: false
        },
        // viewUrl: "/accounting/takeover-perf-base-fee-entry/view",
        gridFilteredUrl: { url: "takeOverPerformanceBaseFeeV2/downloadFilterTakeOverPerBaseFee/" },

        routeUrl: { create: "/input/create/takeover-perf-fee-entry/create", upload: "/input/upload/take-over-perf-base-fee-upload" },
        viewUrl: "/input/create/takeover-perf-fee-entry/view",
        uploadRedirectionUrl: "/input/view/take-over-perf-base-fee",

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_TAKEOVER_PERFORMANCE_BASED_FEE_DELETE",
        }
    },
    'rapidMgtIncentiveFeeAdj': {
        headers: 'assets/headers/accounting/mgtAndIncentivefeeAdj.JSON',
        gridDataUrl: 'mGTAndIncentiveFeeAdjV2/records/',
        gridDeleteUrl: 'mGTAndIncentiveFeeAdj/',
        moduleName: "mGTAndIncentiveFeeAdj",
        filterUrl: { url: "mGTAndIncentiveFeeAdjV2/filterByKeys" },
        newButtonFunction: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT',
        gridExportUrl: { url: "exportController/download/mGTAndIncentiveFeeAdj", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        uploadDetails: {
            uploadUrl: "mGTAndIncentiveFeeAdjV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/mGTAndIncentiveFeeAdj",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/mGTAndIncentiveFeeAdj/", name: "mgt_incentive_fee_adj", sampleName: "mgtandincentivefeeadj", dataStringify: false
        },
        gridFilteredUrl: { url: "exportController/downloadFilter/mGTAndIncentiveFeeAdj/" },

        routeUrl: { create: "/input/create/mgt-incentive-fee-adj-entry/create", upload: "/input/upload/mgt-incentive-fee-adj-upload" },
        viewUrl: "/input/create/mgt-incentive-fee-adj-entry/view",
        uploadRedirectionUrl: "/input/view/mgt-incentive-fee-adj",

        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_MGT_AND_INCENTIVE_FEE_ADJUSTMENT_DELETE",
        }
    },
    "rapidBenchmark": {
        userCard: 'market_data',
        pathName: 'benchmark',
        headers: "assets/headers/portfolio-headers/benchMark.JSON",
        gridDataUrl: "benchMarkListV2/records/",
        gridDeleteUrl: "benchMarkList/",
        moduleName: "benchmark",
        gridExportUrl: { url: "benchMarkListV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "benchMarkListV2/filterByKeys" },
        gridFilteredUrl: { url: "benchMarkListV2/downloadFilterRecords/" },
        uploadDetails: {
            uploadUrl: "benchMarkListV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/benchmark",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/benchmark/", name: "benchmarks", sampleName: "benchmarkdemo"
        },
        routeUrl: { create: "/input/create/benchmark-entry/create", upload: "/input/upload/upload-benchmark" },
        viewUrl: "/input/create/benchmark-entry/view",
        uploadRedirectionUrl: "/input/view/benchmark",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_BENCHMARK", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_PORTFOLIO_BENCHMARK",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BENCHMARK", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_PORTFOLIO_BENCHMARK_DELETE",
        }
    },
    'rapidExternalROR': {
        headers: 'assets/headers/accounting/rapidexternalROR.JSON',
        gridDataUrl: 'externalRorV2/records/',
        gridDeleteUrl: 'externalRor/',
        moduleName: "externalROR",
        filterUrl: { url: "externalRorV2/filterByKeys" },
        // routeUrl: { upload: "/accounting/externalRorUpload", },
        uploadDetails: {
            uploadUrl: "externalRorV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/externalROR",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/externalROR/", name: "external_ror", sampleName: "externalrordemo", dataStringify: false
        },
        routeUrl: { upload: "/input/upload/external-ror-upload" },
        uploadRedirectionUrl: "/input/view/external-ror",
        gridExportUrl: { url: "externalRorV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "externalRorV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_ACCOUNTING_EXTERNAL_ROR",
            viewButtonFunction: "none", deleteButtonFunction: "EXTERNAL_ROR_DELETE",
        }
    },

    "rapidhighVolume": {
        "tradeUploadEntry": {
            userCard: "trade_process_",
            headers: "assets/headers/trade-headers/tradeUploadEntryHV.JSON",
            gridDataUrl: "tradeUpload/entryRecords/",
            processUrl: "tradeUpload/aggRecords/",
            moduleName: "tradeUpload",
            filterUrl: { url: "tradeUpload/filterUploadEntryByKeys" },
            fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
            gridExportUrl: { url: "tradeUpload/downloadRecords/", isZoneName: false },
            gridFilteredUrl: { url: "exportController/downloadFilter/tradeEntryArchival/", isZoneName: false },
            viewUrl: "",
            routeUrl: { create: "", upload: "" },
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "",
                viewButtonFunction: "", deleteButtonFunction: "TRADE_UPLOAD_HV_DELETE",
            }
        },
        "tradeUploadHV": {
            userCard: "trade_process_",
            headers: "assets/headers/trade-headers/tradeUplaodHV.JSON",
            gridDataUrl: "tradeUpload/records/",
            gridDeleteUrl: "tradeUpload/",
            moduleName: "tradeUpload",
            // gridExportUrl: { url: "tradeUpload/download/", isZoneName: false },
            gridExportUrl: { url: "exportController/download/tradeUpload", isZoneName: false },
            filterUrl: { url: "tradeUpload/filterByKeys" },
            // gridFilteredUrl: { url: "tradeUpload/downloadFilter/", isZoneName: false },
            gridFilteredUrl: { url: "exportController/downloadFilter/tradeUpload/", isZoneName: false },
            // routeUrl: { create: "", upload: "/trades/trade-hv/upload" },
            routeUrl: { upload: "/input/upload/trade-upload-hv" },
            uploadRedirectionUrl: "/input/view/high-volume-trade/tradeUploadHV",
            authFunctions: {
                newButtonFunction: "", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME",
                viewButtonFunction: "None", deleteButtonFunction: "TRADE_UPLOAD_HV_DELETE",
            },

        },

    },
    "rapidTradeUploadHV": {
        pathName: 'trade_upload_hv',
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeUplaodHV.JSON",
        gridDataUrl: "batchWork/records/",
        moduleName: "batchWork",
        filterUrl: { url: "batchWork/filterByKeys" },
        exportTradeUploadFailedURL: { url: api_url + 'tradeUpload/downloadFaildRecords' },
        uploadDetails: {
            uploadUrl: "dailyMarginV2/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/dailyMargin",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/dailyMargin/", name: "high_volume_trades", sampleName: "tradeuploadhv"
        },

    },
    "rapidTradeEntryBroker": {
        headers: "assets/headers/reconciliation/tradeEntryBroker.json",
        gridDataUrl: "tradeEnterBrokerV2/records/",
        gridDeleteUrl: "tradeEnterBroker/delete",
        moduleName: "tradeEntryBroker",
        gridExportUrl: { url: "tradeEnterBrokerV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "tradeEnterBrokerV2/filterByKeys" },
        gridFilteredUrl: { url: "tradeEnterBrokerV2/downloadFilterRecords/", isZoneName: false },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_RECONCILIATION_TRADE_ENTRY_BROKER,RECONCILIATION_TRADE_ENTRY_BROKER_NEW_VIEW,JOURNAL_ENTRY_REVISED_THIRD_PARTY_DELETE",
        }
    },
    'rapidViewPnlDistribution': {
        headers: 'assets/headers/accounting/viewPnlDistribution.JSON',
        gridDataUrl: 'PNLContributionReportV2/records/',
        filterUrl: { url: "PNLContributionReport/filterByKeys" },
        moduleName: "pNLContribution",
        gridExportUrl: { url: "PNLContributionReportV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "PNLContributionReportV2/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "none",
        }

    },
    "rapidTradeSucessListHV": {
        userCard: "trade_process_",
        headers: "assets/headers/trade-headers/tradeSuccessListHV.JSON",
        gridDataUrl: "tradeUpload/getUserRecords/",
        processUrl: "tradeUpload/aggRecordsWithUpload/",
        moduleName: "tradeArchiveList",
        filterUrl: { url: "tradeUpload/filterByKeys" },
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/trade",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "TRADEENTRY_ARCHIVE_LIST_VIEW,SEAMLESS_PROCESSING_CUSTOMWORKFLOW_TRADES_TRADEARCHIVELIST_VIEW,SIDEMENU_TRADES,SEAMLESS_INPUT_UPLOAD_TRADES_TRADES_HIGH_VOLUME",
        }
    },
    "rapidBatch": {
        headers: "assets/headers/batch-tracking/batch.JSON",
        gridDataUrl: "batchV2/getBatchesAllRecords/",
        // gridDeleteUrl: "alteryx/integrityCheckSummary/",
        //gridDataUrl:"batch?t=1637730407688&length=10&order=%7B%22column%22:%22createdOn%22,%22dir%22:%22desc%22%7D&search=&start=0",
        moduleName: "batch",
        filterUrl: { url: "batchV2/filterBatchesByKeys" },
        // gridExportUrl: { url: "alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        // gridFilteredUrl: { url: "alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },
        routeUrl: { create: "/rapid-processing/batchTracking/create" },
        authFunctions: {
            deleteButtonFunction: "SIDEMENU_REPORTS,SEAMLESS_PROCESSING_DIV_BATCH",
        }
    },
    "rapidBatchLog": {
        headers: "assets/headers/batch-tracking/batchExecutionLogs.JSON",
        //  gridDataUrl: 'batch/batchExecutionLogs?t=1632549893315&length=10&order=%7B%22column%22:%22executionDateTime%22,%22dir%22:%22desc%22%7D&search=&start=0',
        gridDeleteUrl: "alteryx/integrityCheckSummary/",
        gridDataUrl: "batchV2/getBatchExecutionRecords/",
        moduleName: "batchExecutionLog",
        filterUrl: { url: "batchV2/filterBatchExecutionByKeys" },
        authFunctions: {
            deleteButtonFunction: "SIDEMENU_REPORTS,SEAMLESS_PROCESSING_DIV_BATCH",
        }
        // gridExportUrl: { url: "alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        // gridFilteredUrl: { url: "alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },

    },
    "rapidScheduleTaskInfo": {
        headers: "assets/headers/batch-tracking/allschedules.JSON",
        gridDataUrl: "batchV2/allSchedulesRecords/",
        // gridDeleteUrl: "alteryx/integrityCheckSummary/",
        moduleName: "scheduleTaskInfo",
        filterUrl: { url: "batchV2/filterSchedulesByKeys" },
        // gridExportUrl: { url: "alteryx/integrityCheckSummaryV2/exportCSV/", isZoneName: false },
        // gridFilteredUrl: { url: "alteryx/integrityCheckSummaryV2/exportFilteredCSV/", isZoneName: false },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SIDEMENU_REPORTS,SEAMLESS_PROCESSING_DIV_BATCH",
        }
    },
    'accountSummaryReconcile': {
        userCard: 'bank_reconciliation',
        entCard: 'bank_reconciliation',
        parentHeading: 'account_summary',
        headers: 'assets/headers/reconciliation/accountSummaryReconcile.json',
        method: 'post',
        gridDataUrl: "bankReconciliation/getJEsByBankReconConfigInput/",
        moduleName: "reconciliationAccountSummary",
        viewUrl: "/legacy/fee-rate-change-entry/view",
        routeUrl: { create: "/reconciliation/account-summary-entry/create" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_RECONCILIATION_BANKRECONCILIATION_ACCOUNTSUMMARY_VIEW,SEAMLESS_INPUT_VIEW_BANKRECONCILIATION_ACCOUNTSUMMARY",
        }
    },
    "accountSummary": {
        headers: "assets/headers/reconciliation/account-summary.JSON",
        userCard: 'bank_reconciliation',
        entCard: 'bank_reconciliation',
        gridDataUrl: "bankReconciliation/records/",
        moduleName: "accountSummary",
        filterUrl: { url: "bankReconciliation/filterByKeys" },
        gridExportUrl: { url: "bankReconciliation/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "bankReconciliation/downloadFilterRecords" },
        viewUrl: "/reconciliation/account-summary-reconcile",
        routeUrl: { create: "/reconciliation/account-summary-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_RECONCILIATION_BANKRECONCILIATION_ACCOUNTSUMMARY_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_BANKRECONCILIATION_ACCOUNTSUMMARY,SEAMLESS_RECONCILIATION_BANKRECONCILIATION_ACCOUNTSUMMARY_VIEW",
        }
    },

    "rapidBill": {
        headers: "assets/headers/accounting/billPayable.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "bill",
        gridDeleteUrl: "reBillDetails/delete",
        filterUrl: { url: "reBillDetails/filterByKeys" },
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        viewUrl: "/vendor/bill-entry/view",
        routeUrl: { create: "/vendor/bill-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_VIEW",
            deleteButtonFunction: "TRONIQ_VENDOR_BILL_DELETE",
        }
    },
    "rapidUnpaidBills": {
        headers: "assets/headers/accounting/unPaidBillsPayable.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "unpaidBills",
        filterUrl: { url: "reBillDetails/filterByKeys" },
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        viewUrl: "/vendor/bill-entry/view",
        routeUrl: { create: "/vendor/bill-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_VIEW",
            deleteButtonFunction: "TRONIQ_VENDOR_BILL_DELETE",
        }

    },

    
    "rapidPaidBills": {
        headers: "assets/headers/accounting/paidBillsPayable.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "paidBills",
        filterUrl: { url: "reBillDetails/filterByKeys" },
        viewUrl: "/vendor/bill-entry/view",
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        routeUrl: { create: "/vendor/bill-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_VIEW",
            deleteButtonFunction: "TRONIQ_VENDOR_BILL_DELETE",
        }

    },
    "rapidInvoice": {
        headers: "assets/headers/accounting/customerinvoice.JSON",
        gridDataUrl: "customerInvoice/records/",
        moduleName: "Invoice",
        gridDeleteUrl: "customerInvoice/delete",
        filterUrl: { url: "customerInvoice/filterByKeys" },
        gridExportUrl: { url: "customerInvoice/downloadRecords/", isZoneName: false },
        viewUrl: "/customer/invoice-entry/view",
        routeUrl: { create: "/customer/invoice-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_INVOICE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_INVOICE_VIEW",
            deleteButtonFunction: "TRONIQ_CUSTOMER_INVOICE_DELETE",
        }
    },
    "rapidOutstandingInvoice": {
        headers: "assets/headers/accounting/outstandingInvoice.JSON",
        gridDataUrl: "customerInvoice/records/",
        moduleName: "OutstandingInvoice",
        filterUrl: { url: "customerInvoice/filterByKeys" },
        gridExportUrl: { url: "customerInvoice/downloadRecords/", isZoneName: false },
        viewUrl: "/customer/invoice-entry/view",
        routeUrl: { create: "/customer/invoice-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_INVOICE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_INVOICE_VIEW",
            deleteButtonFunction: "TRONIQ_CUSTOMER_INVOICE_DELETE",
        }

    },
    "paymentRecipt": {
        headers: "assets/headers/accounting/paymentRecipt.JSON",
        gridDataUrl: "customerInvoice/records/",
        moduleName: "paymentRecipt",
        filterUrl: { url: "customerInvoice/filterByKeys" },
        viewUrl: "/customer/invoice-entry/view",
        gridExportUrl: { url: "customerInvoice/downloadRecords/", isZoneName: false },
        routeUrl: { create: "/customer/invoice-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_INVOICE_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_INVOICE_VIEW",
            deleteButtonFunction: "TRONIQ_CUSTOMER_INVOICE_DELETE",
        }

    },
    "cashIncomeList":{
        headers: "assets/headers/accounting/cashIncome.JSON",
        gridDataUrl: "customerInvoice/records/",
        moduleName: "CashIncome",
        gridDeleteUrl: "customerInvoice/delete",
        filterUrl: { url: "customerInvoice/filterByKeys" },
        gridExportUrl: { url: "customerInvoice/downloadRecords/", isZoneName: false },
        viewUrl: "/customer/cashIncome-entry/view",
        routeUrl: { create: "/customer/cashIncome-entry/create" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_CASHINCOME_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_CASHINCOME_VIEW",
            deleteButtonFunction: "TRONIQ_CUSTOMER_CASHINCOME_DELETE",
        }
    },
    "rapidPaymentPayableData": {
        headers: "assets/headers/accounting/paymentsPayableData.JSON",
        gridDataUrl: "rePaymentDetails/records/",
        moduleName: "paymentsPayableData",
        gridExportUrl: { url: "rePaymentDetails/downloadRecords/", isZoneName: false },
        filterUrl: { url: "rePaymentDetails/filterByKeys" },
        gridFilteredUrl: { url: "rePaymentDetails/downloadFilterRecords" },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "None",
        routeUrl: { create: "/vendor/billpayments-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_VIEW", deleteButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_DELETE",
        }
    },
    "rapidReceiptsData": {
        headers: "assets/headers/accounting/receipts.JSON",
        gridDataUrl: "customerPayment/records/",
        moduleName: "paymentsPayableData",
        gridExportUrl: { url: "customerPayment/downloadRecords/", isZoneName: false },
        filterUrl: { url: "customerPayment/filterByKeys" },
        gridFilteredUrl: { url: "customerPayment/downloadFilterRecords" },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "None",
        routeUrl: { create: "/customer/receipts-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_RECEIPT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_RECEIPT_VIEW", deleteButtonFunction: "TRONIQ_CUSTOMER_RECEIPT_DELETE",
        }
    },
    "cashExpenseList":{
        headers: "assets/headers/accounting/cashExpenseList.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "CashExpense",
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        filterUrl: { url: "reBillDetails/filterByKeys" },
        // gridFilteredUrl: { url: "rePaymentDetails/downloadFilterRecords" },
        gridDeleteUrl: "reBillDetails/delete",
        viewUrl: "/vendor/vendorCashexpense-entry/view",
      routeUrl: { create: "/vendor/vendorCashexpense-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_CASHINCOME_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_CASHINCOME_VIEW", deleteButtonFunction: "TRONIQ_CUSTOMER_CASHINCOME_DELETE",
        }
    },

    "rapidPaymentsComponent": {

        headers: "assets/headers/accounting/paymentsPayable.JSON",
        gridDataUrl: "rePaymentDetails/getBillRecords/",
        moduleName: "paymentsPayable",
        gridExportUrl: { url: "rePaymentDetails/downloadRecords/", isZoneName: false },
        filterUrl: { url: "rePaymentDetails/filterByBill" },
        newgridExportUrl: { url: "exportController/download/sMEquities", isZoneName: false },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_CREATE", deleteButtonFunction: "TRONIQ_VENDOR_BILL_PAYMENT_DELETE",
        }
    },
    "rapidreciptsComponent": {

        headers: "assets/headers/accounting/reciptsList.JSON",
        gridDataUrl: "customerPayment/getBillRecords/",
        moduleName: "reciptsPayable",
        gridExportUrl: { url: "customerPayment/downloadRecords/", isZoneName: false },
        filterUrl: { url: "customerPayment/filterByBill" },
        newgridExportUrl: { url: "exportController/download/sMEquities", isZoneName: false },
        authFunctions: {
            newButtonFunction: "TRONIQ_CUSTOMER_RECEIPT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_CUSTOMER_RECEIPT_CREATE", deleteButtonFunction: "TRONIQ_CUSTOMER_RECEIPT_DELETE",
        }
    },
    "vendorCreditList":{
        headers: "assets/headers/accounting/vendorCredit.JSON",
        gridDataUrl: "reBillDetails/records/",
        moduleName: "Credit",
        gridExportUrl: { url: "reBillDetails/downloadRecords/", isZoneName: false },
        filterUrl: { url: "reBillDetails/filterByKeys" },
        // gridFilteredUrl: { url: "rePaymentDetails/downloadFilterRecords" },
        gridDeleteUrl: "reBillDetails/delete",
        viewUrl: "/vendor/vendorCredit-entry/view",
      routeUrl: { create: "/vendor/vendorCredit-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "TRONIQ_VENDOR_CREDIT_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "TRONIQ_VENDOR_CREDIT_VIEW", deleteButtonFunction: "TRONIQ_VENDOR_CREDIT_DELETE",
        }
    },

    

    "MISFundList": {
        pathName: 'funds_client_accounts',
        entCard: 'mis',
        headers: "assets/headers/system-manager-headers/MISFund.JSON",
        gridDataUrl: "internalAttributes/records/",
        moduleName: "fund",
        fileName: "FundListFiltered",
        filterUrl: { url: "internalAttributes/filterByKeys" },
        gridExportUrl: { url: "exportController/download/fund_internalAttributes/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/fund_internalAttributes/", isZoneName: false },
        viewUrl: "/MIS/fund/view",
        routeUrl: { create: "/MIS/fund/create", upload: "/MIS/fund/upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/internalAttributes',
            uploadUrl: "internalAttributes/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/internalAttributes",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/internalAttributes/", name: "ia_details", sampleName: "misfund", dataStringify: true,
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_MIS_FUND",
        }
    },
    'rapidAccountSummaryReconcile': {
        headers: 'assets/headers/reconciliation/accountSummaryReconcile.json',
        method: 'post',
        gridDataUrl: "bankReconciliation/getJEsByBankReconConfigInput/",
        moduleName: "reconciliationAccountSummary",
        routeUrl: { create: "/input/create/account-summary-entry/create" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "SEAMLESS_RECONCILIATION_BANKRECONCILIATION_ACCOUNTSUMMARY_VIEW,SEAMLESS_INPUT_VIEW_BANKRECONCILIATION_ACCOUNTSUMMARY",
        }
    },
    "rapidAccountSummary": {
        headers: "assets/headers/reconciliation/account-summary.JSON",
        gridDataUrl: "bankReconciliation/records/",
        moduleName: "accountSummary",
        filterUrl: { url: "bankReconciliation/filterByKeys" },
        gridExportUrl: { url: "bankReconciliation/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "bankReconciliation/downloadFilterRecords" },
        viewUrl: "/input/view/account-summary-reconcile",
        routeUrl: { create: "/input/create/account-summary-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_RECONCILIATION_BANKRECONCILIATION_ACCOUNTSUMMARY_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_BANKRECONCILIATION_ACCOUNTSUMMARY,SEAMLESS_RECONCILIATION_BANKRECONCILIATION_ACCOUNTSUMMARY_VIEW",
        }
    },
    "reBudgets": {
        pathName: 'budgets',
        headers: "assets/headers/accounting/reBudgets.JSON",
        gridDeleteUrl: "reBudgetDetails/deleteBudget",
        moduleName: "reBudgetDetails",
        exportModuleName: "budgeting",
        filterUrl: { url: "reBudgetDetails/filterByKeys" },
        gridExportUrl: { url: "reBudgetDetails/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        deleteButtonFunction: "SEAMLESS_ACCOUNTING_BUDGETING_BUDGETS_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/accounting/budgets/create", upload: "/accounting/budgets-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/rebudgets',
            uploadUrl: "reBudgetDetails/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/rebudgets",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/rebudgets/", name: "Budgets", sampleName: "rebudgetdemo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_ACCOUNTING_BUDGETING_BUDGETS_CREATE", uploadButtonFunction: "SEAMLESS_ACCOUNTING_BUDGETING_BUDGETS_CREATE",
            viewButtonFunction: "SEAMLESS_ACCOUNTING_BUDGETING_BUDGETS_VIEW", deleteButtonFunction: "SEAMLESS_ACCOUNTING_BUDGETING_BUDGETS_DELETE",
        }
    },
    "rapidReBudgets": {
        pathName: 'budgets',
        headers: "assets/headers/accounting/reBudgets.JSON",
        gridDeleteUrl: "reBudgetDetails/deleteBudget",
        moduleName: "reBudgetDetails",
        exportModuleName: "budgeting",
        filterUrl: { url: "reBudgetDetails/filterByKeys" },
        gridExportUrl: { url: "reBudgetDetails/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_BUDGETING_BUDGETS_DELETE",
        viewUrl: "None",
        routeUrl: { create: "/input/create/budgets-create/create", upload: "/input/upload/budgets-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/rebudgets',
            uploadUrl: "reBudgetDetails/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/rebudgets",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/rebudgets/", name: "Budgets", sampleName: "rebudgetdemo", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_BUDGETING_BUDGETS", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_BUDGETING_BUDGETS",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_BUDGETING_BUDGETS", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_BUDGETING_BUDGETS_DELETE",
        }
    },
    "waterfallList": {
        headers: "assets/headers/settings-header/accountingSetting/waterfallList.JSON",
        gridDataUrl: "waterFallConfig/records/",
        moduleName: "waterfall",
        // filterUrl: { url: "waterFallConfig/filterByKeys" },
        // gridExportUrl: { url: "waterFallConfig/downloadRecords/", isZoneName: false },
        // gridFilteredUrl: { url: "bankReconciliation/downloadFilterRecords" },
        viewUrl: "/setting/waterfall-entry/view",
        routeUrl: { create: "/setting/waterfall-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL", deleteButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL",
        }
    },
    "rapidJeTransferlog": {
        headers: "assets/headers/manager-headers/customization-je-transfer-logs.JSON",
        gridDataUrl: "rollUpLogCtrl/getRecords/",
        filterUrl: { url: "rollUpLogCtrl/filterByKeys" },
        moduleName: "rollUpConsolidationLogs",
        exportModuleName: "rollUpConsolidationLogs",
        newgridExportUrl: { url: "exportController/download/rollUpConsolidationLogs", isZoneName: false },
        //gridExportUrl: { url: "exportController/download/rollUpConsolidationLogs", isZoneName: false },
        //gridFilteredUrl: { url: "exportController/downloadFilter?moduleName=tradeEntry" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL", deleteButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FUNDWATERFALL",
        }
    },
    "activityClient": {
        // pathName: 'accounting-dashboard',
        headers: "assets/headers/manager-headers/activityClient.JSON",
        headersActvityUpload: "assets/headers/activity-upload-review/activity-upload.JSON",
        gridDataUrl: "financialDataIntegration/activityLogRecords/",
        filterUrl: { url: "financialDataIntegration/filterActivityLogByKeys" },
        moduleName: "financialDataIntegration",
        viewUrl: "/activity-upload-review",
        routeUrl: { create: "", upload: "/accounting-dashboard/activity-upload" },
        authFunctions: {
            newButtonFunction: "NONE", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_VIEW", deleteButtonFunction: "SEAMLESS_SIDEMENU_ACCOUNTING_DASHBOARD_CLIENT",
        },
        uploadDetails: {
            uploadUrl: "financialDataIntegration/activityUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/activityUpload",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/activityUpload/", name: "activity_upload", sampleName: "activityupload"
        },
    },
    "periodEndIndicatorsByFund": {
        moduleName: "periodEndIndicatorsByFund",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/periodEndIndicatorsByFund',
            uploadUrl: "periodEndIndicatorsByFund/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/periodEndIndicatorsByFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/periodEndIndicatorsByFund/", name: "period_end_indicator", sampleName: "periodEndIndicatorsByFund", dataStringify: true
        }
    },
    "updateFSTransactionCode": {
        moduleName: "updateFSTransactionCode",
        uploadDetails: {
            uploadUrl: "financialStatement1/updateFSTransactionCode",
            name: "fs_transaction_code_update",
            sampleName: "updateFSTransactionCode",
            dataStringify: false
        }
    },
    "apiKeyModule": {
        headers: "assets/headers/settings-header/apiManagement/apiKeyManagement.JSON",
        gridDataUrl: "apiKeys/getAPIKeys/",
        gridDeleteUrl: "investorCapitalSummaryAPI/disableAPIKey/",
        moduleName: "countryMaster",
        viewUrl: "/setting/api-key-entry/view",
        routeUrl: { create: "/setting/api-key-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "apiCreation": {
        headers: "assets/headers/system-manager-headers/apiCreation.JSON",
        gridDataUrl: "restAPI/getRestApiGridData/",
        gridDeleteUrl: "investorCapitalSummaryAPI/deleteRestAPI/",
        viewUrl: "/api-management/api/view",
        routeUrl: { create: "/api-management/api/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "apiModule": {
        headers: "assets/headers/system-manager-headers/apiModule.JSON",
        gridDataUrl: "usagePlan/getUsagePlanGridData/",
        gridDeleteUrl: "investorCapitalSummaryAPI/deleteUsagePlan/",
        viewUrl: "/api-management/api-module/view",
        routeUrl: { create: "/api-management/api-module/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "calculateTaxAllocation": {
        headers: "assets/headers/tax-automation/calculateTaxAllocation.JSON",
        gridDataUrl: "taxReturnDetails/records/",
        moduleName: "TaxReturnDetails",
        filterUrl: { url: "taxReturnDetails/filterByKeys" },
        viewUrl: "/ta/config/ta-entry/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_TAXATION_TAXALLOCATION_VIEW", deleteButtonFunction: "",
        }
    },
    "calculateTaxAllocationStatus": {
        headers: "assets/headers/tax-automation/calculateTaxAllocationStatus.JSON",
        gridDataUrl: "taxReturnDetails/statusRecords/",
        moduleName: "TaxReturnDetails",
        filterUrl: { url: "taxReturnDetails/statusFilterByKeys" },
        viewUrl: "None",
        gridExportUrl: { url: "taxReturnDetails/downloadStatusRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxReturnDetails/downloadStatusFilterRecords/", isZoneName: false },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_TAXATION_TAXALLOCATION_DATAVIEW", deleteButtonFunction: "",
        }
    },
    "isdClientApprovalGroupFund": {
        headers: "assets/headers/manager-headers/isdClientApprovalGroupFund.JSON",
        headersISDContribution: "assets/headers/manager-headers/clientApproval.JSON",
        filterUrl: { url: "isdConsolidateWorkflow/filterByKeys" },
        gridDataUrl: "isdConsolidateWorkflow/getIsdConsolidateWorkflowConfigurationDTOList",
        gridHistoricalApprovalUrl: "isdConsolidateWorkflow/historicalIsdConsolidateWorkflowConfigurationDTOList",
        downloadurl: "formidium/document/downloadDocument",
        moduleName: "isdClientApprovalGroupFund",
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },
    "isdClientApprovedGroup": {
        headers: "assets/headers/manager-headers/isdClientApprovalGroupFundList.JSON",
        headersISDContribution: 'assets/headers/manager-headers/isdGroupList.JSON',
        gridDataUrl: 'isdConsolidateReport/isdConsolidateGrid',
        //  gridDataUrl: 'reportDeliveryWorkflowV2/enterprise/workFlow/isdgrid',
        isdSendEmail: 'isdConsolidateReport/sendEmailForSelectedInvestors',
        isdPreviewEmail: 'isdConsolidateReport/sendPreviewEmailForSelectedInvestors',
        moduleName: "isdConsolidateReport",
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },

    "isdEmailTemplateConfiguration": {
        headers: "assets/headers/manager-headers/isdEmailTemplateConfiguration.JSON",
        headersISDContribution: 'assets/headers/manager-headers/ISDConsolidateApprovedWorkflow.JSON',
        gridDataUrl: 'isdConsolidateEmailConfig/getIsdConsolidateEmailConfigurationDTOList/',
        moduleName: "isdEmailTemplateConfiguration",
        viewUrl: "/customization/isd-multiple-fund-email-template-entry/view",
        userViewUrl: "/distribution/isd-multiple-fund-email-template-entry/view",
        routeUrl: { create: "/customization/isd-multiple-fund-email-template-entry/create", userCreate: "/distribution/isd-multiple-fund-email-template-entry/create" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_ISD_EMAIL_TEMPLATE_VIEW", uploadButtonFunction: "None",
            viewButtonFunction: "", deleteButtonFunction: "None",
        }
    },
    "isdAdhocDistribution": {
        headers: "assets/headers/manager-headers/isdAdhocDistribution.JSON",
        headersISDContribution: "assets/headers/manager-headers/adhocApprovalList.JSON",
        gridDataUrl: 'isdAdhocReport/isdAdhocGrid',
        moduleName: "isdAdhocDistribution",
        pdfDownload: { url: api_url + 'isdAdhocReport/previewIsdAdhocReportResponseDTOList' },
        viewUrl: "/customization/isd-adhoc-distribution-entry/view",
        //  routeUrl: { create: "/customization/isd-adhoc-distribution-entry/create" },
        //  userViewUrl: "/distribution/isd-adhoc-distribution-entry/view",
        // routeUrl: { create: "/customization/isd-adhoc-distribution-entry/create", userCreate: "/distribution/isd-adhoc-distribution-entry/create" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },
    "isdEnableInvAdhocReDistribution": {
        headers: "assets/headers/manager-headers/isdAdhocDistribution.JSON",
        headersISDAdhocREDistribution: "assets/headers/manager-headers/isdAdhocREDistribution.JSON",
        gridDataUrl: 'isdAdhocReport/isdAdhocGrid',
        moduleName: "isdAdhocReDistribution",
        viewUrl: "/customization/isd-adhoc-distribution-entry/view",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },


    "isdAdhocConfiguration": {
        headers: "assets/headers/manager-headers/isdAdhocConfiguration.JSON",
        headersISDContribution: "assets/headers/manager-headers/adhocApprovalList.JSON",
        moduleName: "isdAdhocConfiguration",
        gridDataUrl: 'isdAdhocDistributionConfig/getIsdAdhocDistributionConfigurationDTOList/',
        viewUrl: "/customization/isd-adhoc-configuration-entry/view",
        userViewUrl: "/distribution/isd-adhoc-configuration-entry/view",
        routeUrl: { create: "/customization/isd-adhoc-configuration-entry/create", userCreate: "/distribution/isd-adhoc-configuration-entry/create" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_ISD_ADHOC_CONFIGURATION_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_ISD_ADHOC_CONFIGURATION_VIEW", deleteButtonFunction: "None",
        }
    },
    "isdNotificationStatusGroup": {
        headers: "assets/headers/manager-headers/isdNotificationStatusGroup.JSON",
        gridDataUrl: "isdConsolidateWorkflow/getAllIsdConsolidateWorkflowConfigurationDTOList",
        moduleName: "isdNotificationStatusGroup",
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },
    "taxReturnPackageNew": {
        headers: "assets/headers/tax-automation/taxReturnPackageNew.JSON",
        gridDataUrl: "taxReturnPackageNew/records/",
        moduleName: "TaxReturnDetails",
        filterUrl: { url: "taxReturnDetails/filterByKeys" },
        viewUrl: "None",
        gridExportUrl: { url: "/sudrania/taxReturnDetails/downloadStatusRecords/", isZoneName: false },
        gridFilteredUrl: { url: "/sudrania/taxReturnDetails/downloadStatusFilterRecords/", isZoneName: false },
    },
    "globalSMEquitiesList": {
        pathName: 'sm_equities',
        userCard: 'securities_master',
        headers: "assets/headers/global-security-master-headers/globalSMEquities.JSON",
        gridDataUrl: "sMEquitiesGlobal/records/",
        gridDeleteUrl: "globalSMEquities/",
        moduleName: "globalSMEquities",
        exportModuleName: "globalSMEquities",
        gridExportUrl: { url: "sMEquitiesGlobal/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesGlobal/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/globalSMEquities", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "sMEquitiesGlobal/filterByKeysDelete" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMEquitiesGlobal/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquities",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquities/", name: "sm_equities", sampleName: "smequities", dataStringify: true
        },
        viewUrl: "/global-security-master/sm-equities/view",
        routeUrl: { create: "/global-security-master/sm-equities/create", upload: "/global-security-master/sm-equities/upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_EQUITIES_CREATE", uploadButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_EQUITIES_CREATE",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_EQUITIES_VIEW", deleteButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_EQUITIES_DELETE",
        }

    },
    "globalSmOptionsList": {
        userCard: 'securities_master',
        pathName: 'sm_options',
        headers: "assets/headers/global-security-master-headers/smEquitiesOption.JSON",
        gridDataUrl: "sMEquitiesOptionsGlobal/records/",
        gridDeleteUrl: "sMEquitiesOptionsGlobal/",
        moduleName: "globalSMEquitiesOptions",
        exportModuleName: "globalSMEquitiesOptions",
        gridExportUrl: { url: "sMEquitiesOptionsGlobal/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesOptionsGlobal/filterByKeys" },
        filterDeleteUrl: { url: "sMEquitiesOptions/filterByKeysDelete" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/globalSMEquitiesOptions", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        // viewUrl: "/portfolio/sm-options/view",
        uploadDetails: {
            uploadUrl: "sMEquitiesOptionsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquitiesOption",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquitiesOption/", name: "sm_options_upload_header", sampleName: "smequitiesoptions", dataStringify: true
        },
        // routeUrl: { create: "/portfolio/sm-options/create", upload: "/portfolio/sm-options/upload" },
        viewUrl: "/global-security-master/sm-options/view",
        uploadRedirectionUrl: "",
        routeUrl: { create: "", upload: "" },

        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_OPTIONS_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalSmFixedIncomeList": {
        userCard: 'securities_master',
        pathName: 'fixed_income',
        headers: "assets/headers/global-security-master-headers/SmFixedIncome.JSON",
        gridDataUrl: "sMFixedIncomeGlobal/records/",
        gridDeleteUrl: "sMFixedIncome/",
        moduleName: "globalSMFixedIncome",
        exportModuleName: "globalSMFixedIncome",
        gridExportUrl: { url: "sMFixedIncomeGlobal/exportSMFixedIncome/", isZoneName: false },
        filterUrl: { url: "sMFixedIncomeGlobal/filterByKeys" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/globalSMFixedIncome", isZoneName: false },
        filterDeleteUrl: { url: "sMFixedIncome/filterByKeysDelete" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFixedIncomeV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFixedIncome",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFixedIncome/", name: "fixed_income", sampleName: "smfixedincome", dataStringify: true
        },
        deleteButtonFunction: "None",
        viewUrl: "/global-security-master/sm-fixed-income/view",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_FIXED_INCOME_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalSmFxForwardList": {
        userCard: 'securities_master',
        pathName: 'sm_fx_forward',
        headers: "assets/headers/global-security-master-headers/smFxForward.JSON",
        gridDataUrl: "sMFXForwardGlobal/records/",
        gridDeleteUrl: "sMFXForward/",
        moduleName: "globalSMFXForward",
        exportModuleName: "globalSMFXForward",
        gridExportUrl: { url: "sMFXForwardGlobal/exportSMFxForward/", isZoneName: false },
        filterUrl: { url: "sMFXForwardGlobal/filterByKeys" },
        filterDeleteUrl: { url: "sMFXForward/filterByKeysDelete" },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/globalSMFXForward", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFXForwardV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFXForward",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFXForward/", name: "fx_forward", sampleName: "smfxforward", dataStringify: true
        },
        viewUrl: "/global-security-master/sm-fx-forward/view",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "NOne", uploadButtonFunction: "NOne",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_FX_FORWORD_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalSmPrivateFundList": {
        userCard: 'securities_master',
        pathName: 'sm_private_fund',
        headers: "assets/headers/global-security-master-headers/smPrivateFund.JSON",
        gridDataUrl: "sMPrivateFundGlobal/records/",
        gridDeleteUrl: "sMPrivateFund/",
        moduleName: "globalSMPrivateFund",
        exportModuleName: "globalSMPrivateFund",
        gridExportUrl: { url: "sMPrivateFundGlobal/exportSMPrivate/", isZoneName: false },
        filterUrl: { url: "sMPrivateFundGlobal/filterByKeys" },
        filterDeleteUrl: { url: "sMPrivateFund/filterByKeysDelete" },
        newgridExportUrl: { url: "exportController/download/globalSMPrivateFund", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMPrivateFundV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMPrivateFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMPrivateFund/", name: "private_fund", sampleName: "smprivatefund", dataStringify: true
        },
        // viewUrl: "/portfolio/sm-private-fund/view",
        deleteButtonFunction: "NOne",
        // routeUrl: { create: "/portfolio/sm-private-fund/create", upload: "/portfolio/sm-private-fund/upload" },
        viewUrl: "/global-security-master/sm-private-fund/view",
        // uploadRedirectionUrl: "/input/view/sm-private-fund",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "NOne", uploadButtonFunction: "NOne",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_PRIVATE_FUND_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalSmFutureList": {
        userCard: 'securities_master',
        pathName: 'futures',
        headers: "assets/headers/global-security-master-headers/smFuturesListData.JSON",
        gridDataUrl: "sMFuturesGlobal/records/",
        gridDeleteUrl: "sMFutures/",
        moduleName: "globalSMFutures",
        exportModuleName: "globalSMFutures",
        gridExportUrl: { url: "sMFuturesGlobal/exportSMFutures/", isZoneName: false },
        filterUrl: { url: "sMFuturesGlobal/filterByKeys" },
        filterDeleteUrl: { url: "sMFutures/filterByKeysDelete" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/globalSMFutures", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMFuturesV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMFutures",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMFutures/", name: "security_futures", sampleName: "smfutures", dataStringify: true
        },
        //  viewUrl: "/portfolio/sm-futures/view",
        deleteButtonFunction: "None",
        //  routeUrl: { create: "/portfolio/sm-futures/create", upload: "/portfolio/sm-futures/upload" },
        viewUrl: "/global-security-master/sm-futures/view",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "NOne", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_FUTURE_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalSmRealestateList": {
        userCard: 'securities_master',
        pathName: 'real_estate',
        headers: "assets/headers/global-security-master-headers/smRealEstate.JSON",
        gridDataUrl: "sMRealEstateControllerGlobal/records/",
        gridDeleteUrl: "sMRealEstate/",
        moduleName: "globalSMRealEstate",
        exportModuleName: "globalSMRealEstate",
        gridExportUrl: { url: "sMRealEstateControllerGlobal/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMRealEstateControllerGlobal/filterByKeys" },
        filterDeleteUrl: { url: "sMRealEstateControllerGlobal/filterByKeysDelete" },
        gridFilteredUrl: { url: "sMRealEstateControllerGlobal/downloadFilterRecords", isZoneName: true },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        newgridExportUrl: { url: "exportController/download/sMRealEstate", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMRealEstateControllerGlobal/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMRealEstate",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMRealEstate/", name: "real_estate", sampleName: "sMRealEstate", dataStringify: true
        },
        //  viewUrl: "/portfolio/sm-real-estate/view",
        deleteButtonFunction: "SEAMLESS_INPUT_VIEW_SECURITYMASTER_REALESTATE_DELETE",
        //  routeUrl: { create: "/portfolio/sm-real-estate/create", upload: "/portfolio/sm-real-estate/upload" },
        viewUrl: "/global-security-master/sm-real-estate/view",
        uploadRedirectionUrl: "/input/view/sm-real-estate",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_REAL_ESTATE_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalSmPools": {
        userCard: 'securities_master',
        pathName: 'pools',
        headers: "assets/headers/global-security-master-headers/smPools.JSON",
        gridDataUrl: "sMPrivateFundGlobal/records/",
        gridDeleteUrl: "sMPrivateFund/",
        moduleName: "globalSMPrivateFund",
        exportModuleName: "globalSMPrivateFund",
        gridExportUrl: { url: "sMPrivateFundGlobal/exportSMPrivate/", isZoneName: false },
        filterUrl: { url: "sMPrivateFundGlobal/filterByKeys" },
        filterDeleteUrl: { url: "sMPrivateFund/filterByKeysDelete" },
        newgridExportUrl: { url: "exportController/download/globalSMPrivateFund", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter/" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMRealEstateController/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMPrivateFund",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMPrivateFund/", name: "pools", sampleName: "smpools", dataStringify: true
        },
        // viewUrl: "/portfolio/sm-private-fund/view",
        deleteButtonFunction: "None",
        // routeUrl: { create: "/portfolio/sm-private-fund/create", upload: "/portfolio/sm-private-fund/upload" },
        viewUrl: "/global-security-master/sm-pool/view",
        uploadRedirectionUrl: "/input/view/sm-pools",
        routeUrl: { create: "", upload: "" },

        authFunctions: {
            newButtonFunction: "NOne", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_POOL_VIEW", deleteButtonFunction: "None",
        }
    },
    "globalCrypto": {
        pathName: 'sm_equities',
        userCard: 'securities_master',
        headers: "assets/headers/global-security-master-headers/globalSMEquities.JSON",
        gridDataUrl: "sMEquitiesGlobal/records/",
        gridDeleteUrl: "globalSMEquities/",
        moduleName: "globalSMEquities",
        exportModuleName: "globalSMEquities",
        gridExportUrl: { url: "sMEquitiesGlobal/downloadRecords/", isZoneName: false },
        filterUrl: { url: "sMEquitiesGlobal/filterByKeys" },
        newgridExportUrl: { url: "exportController/download/globalSMEquities", isZoneName: false },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        filterDeleteUrl: { url: "sMEquitiesGlobal/filterByKeysDelete" },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        uploadDetails: {
            uploadUrl: "sMEquitiesGlobal/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/sMEquities",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/sMEquities/", name: "sm_equities", sampleName: "smequities", dataStringify: true
        },
        viewUrl: "/global-security-master/sm-crypto/view",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_GLOBAL_SECURITY_MASTER_CRYPTO_VIEW", deleteButtonFunction: "None",
        }
    },
    "customizedPosition": {
        exportURL: { url: 'customizedPosition/CustomizedPosition?data=', },
    },
    "fundNavDaily": {
        exportURL: { url: api_url + 'fundNavDaily/Download/', },
    },
    "holdingMaturity": {
        exportURL: { url: api_url + 'HoldingMaturityController/HoldingMaturityReport?data=', },
    },
    "holdingPerReport": {
        exportURL: { url: 'holding/excel?fundId=', },
    },
    "investorCapitalSummarySidePocket": {
        exportURL: { url: api_url + 'investorCapitalSummarySidePocket/investorCapitalSidePocketSummaryDownload/' },
    },
    "productTaxcategoryClassification": {
        exportURL: { url: 'productTaxCategoryClassification/productTaxCategoryExcel?data=' },
    },
    "taxAdditionK1Details": {
        headers: "assets/headers/tax-automation/taxAdditionK1Details.JSON",
        gridDataUrl: "taxAdditionK1Details/records/",
        gridDeleteUrl: "taxationTaxReturnDetailsK1/",
        moduleName: "taxAdditionK1Details",
        gridExportUrl: { url: "taxAdditionK1Details/downloadRecords/", isZoneName: false },
        filterUrl: { url: "taxAdditionK1Details/filterByKeys" },
        gridFilteredUrl: { url: "taxAdditionK1Details/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/taxadditionk1details',
            uploadUrl: "taxAdditionK1Details/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxadditionk1details",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxadditionk1details/", name: "Additional K-1 Details", sampleName: "taxadditionk1details", dataStringify: true
        },
        viewUrl: "None",
        routeUrl: { create: "/taxSetup/tax-additionK1-details-entry/create", upload: "/taxSetup/tax-additionK1-details-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILSK1_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILSK1_CREATE",
            viewButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILSK1_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_TAXRETURNDETAILSK1_DELETE",
        }
    },
    "customizationEndPointScheduler": {
        headers: "assets/headers/manager-headers/endPointScheduler.JSON",
        gridDataUrl: "api/endpoint/getAllUserEndpoints",
        moduleName: "endpoint",
        routeUrl: { create: "/customization/fund-Group-entry" },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA_EXPORTDATACOLLECTION", deleteButtonFunction: "None",
        }
    },
    "rapidendpointschdulerinput": {
        userCard: 'endpoint',
        pathName: 'endpoint_scheduler',
        headers: "assets/headers/manager-headers/endPointScheduler.JSON",
        gridDataUrl: "api/endpoint/getAllUserEndpoints",
        newButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA_EXPORTDATACOLLECTION",
        moduleName: "rapidendpointschdulerinput",
        // viewUrl: "/input/create/endpoint-scheduler-entry/view",
        routeUrl: { create: "/input/create/endpoint-scheduler-entry/create", upload: "/input/upload/daily-margin-upload" },
        viewUrl: "/input/create/endpoint-scheduler-entry/view",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_CUSTOMIZATION_DIV_CRYPTOEXCHANGEDATA_EXPORTDATACOLLECTION", deleteButtonFunction: "None",
        }
    },

    "dataMonitoring": {
        entCard: 'enterprise_configuration',
        headers: "assets/headers/manager-headers/dataMonitoring.JSON",
        gridDeleteUrl: "api/eventMonitoring/filter",
        gridDataUrl: "api/eventMonitoring/filter",
        moduleName: "eventMonitoring",
        exportModuleName: "eventMonitoring",
        filterUrl: { url: "api/eventMonitoring/filter" },
        gridExportUrl: { url: "eventMonitoring/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "eventMonitoring/downloadFilter", isZoneName: false },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "dataMonitoringinput": {
        userCard: "trade_process_",
        headers: "assets/headers/manager-headers/dataMonitoring.JSON",
        gridDeleteUrl: "api/eventMonitoring/filter",
        gridDataUrl: "api/eventMonitoring/filter",
        moduleName: "eventMonitoring",
        exportModuleName: "eventMonitoring",
        filterUrl: { url: "api/eventMonitoring/filter" },
        gridExportUrl: { url: "eventMonitoring/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "eventMonitoring/downloadFilter", isZoneName: false },
        viewUrl: "None",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_TRADES_TRADE", deleteButtonFunction: "",
        }
    },
    "taxK1BoxConfiguration": {
        pathName: 'K_1_box_configuration',
        headers: "assets/headers/system-manager-headers/taxK1BoxConfiguration.JSON",
        gridDataUrl: "taxK1BoxDetails/records/",
        gridDeleteUrl: "taxK1BoxDetails/",
        moduleName: "taxK1BoxConfiguration",
        filterUrl: { url: "taxK1BoxDetails/filterByKeys" },
        gridExportUrl: { url: "taxK1BoxDetails/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxK1BoxDetails/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/systemmanager/tax-K1-box-configuration/create", upload: "/systemmanager/tax-K1-box-configuration-upload" },
        viewUrl: "/systemmanager/tax-K1-box-configuration/view",
        updateUrl: 'taxK1BoxDetails/update',
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/taxK1BoxConfiguration',
            uploadUrl: "taxK1BoxDetails/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxK1BoxConfiguration",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxK1BoxConfiguration/", name: "Tax K-1 Box Configuration", sampleName: "taxK1BoxConfiguration", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SYSADMIN_TAXATION_K1BOXCONFIGURATION_CREATE", uploadButtonFunction: "SYSADMIN_TAXATION_K1BOXCONFIGURATION_UPLOAD",
            viewButtonFunction: "SYSADMIN_TAXATION_K1BOXCONFIGURATION_VIEW", deleteButtonFunction: "SYSADMIN_TAXATION_K1BOXCONFIGURATION_DELETE",
            updateButtonFunction: "SYSADMIN_TAXATION_K1BOXCONFIGURATION_UPDATE"
        }
    },
    "investorStatementFooterCustomization": {
        headers: "assets/headers/settings-header/Configuration/investor-statement-footer-customization.JSON",
        gridDataUrl: "footerV2/records/",
        gridDeleteUrl: "footerV2/",
        moduleName: "footer",
        filterUrl: { url: "footerV2/filterByKeys" },
        gridExportUrl: { url: "/sudrania/footerV2/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "/sudrania/footerV2/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/setting/investor-statement-footer-customization-entry/create", upload: "" },
        viewUrl: "/setting/investor-statement-footer-customization-entry/view",

        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    'fetchBankTransaction': {
        headers: 'assets/headers/accounting/fetchBankTransaction.JSON',
        gridDataUrl: 'csdBankTransactionsController/records/',
        gridDeleteUrl: "nonTradeTransactions/",
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "csdBankTransactions",
        updateUrl: 'nonTradeTransactionsV2/update',
        exportModuleName: "csdBankTransactions",
        deleteButtonFunction: "None",
        filterUrl: { url: "nonTradeTransactionsV2/filterByKeys" },
        gridExportUrl: { url: "exportController/download/csdBankTransactions", isZoneName: false },
        // gridFilteredUrl: { url: "/sudrania/exportController/downloadFilter/nonTradeTransactions/", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        newButtonFunction: '',
        routeUrl: { create: "/input/create/fetchcsdbanktransaction-entry/create", upload: "/input/upload/csdbanktransaction-upload" },
        viewUrl: "/input/create/fetchcsdbanktransaction-entry/view",
        uploadRedirectionUrl: "/input/view/fetchcsdbanktransaction",
        uploadRedirectionUrl2: "/reconciliation/view-non-trade-trans-tp",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/csdBankTransaction',
            uploadUrl: "nonTradeTransactionsV2/csdBankTransactionUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/csdBankTransaction",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/csdBankTransaction/", name: "csd_bank_transaction", sampleName: "csdbanktransaction", dataStringify: true,
            resName: "failedObjects",
        },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_ACCOUNTING_CSDBANKTRANSACTION_UPDATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CSDBANKTRANSACTION", deleteButtonFunction: "None",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CSDBANKTRANSACTION_EDIT"
        }
    },
    "chartOfAccountTaxClassification": {
        headers: "assets/headers/tax-automation/chartOfAccountTaxClassification.JSON",
        gridDataUrl: "taxReturnDetails/records/",
        //  gridDeleteUrl: "taxReturnDetails/",
        moduleName: "TaxReturnDetails",
        filterUrl: { url: "taxReturnDetails/filterByKeys" },
        viewUrl: "/ta/config/ta-entry/view",
        //  routeUrl: { create: "/ta/config/ta-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }

    },
    "chartofAccountTaxMappingDetails": {
        pathName: 'chart-of-account-taxmapping-details',
        headers: "assets/headers/tax-automation/chartofAccountTaxMappingDetails.JSON",
        gridDataUrl: "coaTaxClassification/mappingDetails/",
        // gridDeleteUrl: "coaTaxClassification/",
        method: 'post',
        moduleName: "coaTaxClassification",
        filterUrl: { url: "coaTaxClassification/filterByKeys" },
        gridExportUrl: { url: "coaTaxClassification/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "coaTaxClassification/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/taxSetup/chart-of-account-taxmapping-details/create", upload: "" },
        //viewUrl: "/taxSetup/chart-of-account-taxmapping-details/view",

        viewUrl: "None",

        updateUrl: 'coaTaxClassification/update',
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_DELETE",
            updateButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_UPDATE"
        }

    },
    "COAMapping": {
        headers: "assets/headers/tax-automation/COAMapping.json",
        gridDataUrl: "chartOfAccountsV2/records/",
        gridDeleteUrl: "chartOfAccounts/",
        moduleName: "chartOfAccounts",
        gridExportUrl: { url: "chartOfAccountsV2/downloadRecords/", isZoneName: false },
        filterUrl: { url: "chartOfAccountsV2/filterByKeys" },
        gridFilteredUrl: { url: "chartOfAccountsV2/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            uploadUrl: "chartOfAccountsV2/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/chartOfAccounts",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/chartOfAccounts/", name: "chart_of_accounts", sampleName: "chartOfAccounts", dataStringify: false
        },
        viewUrl: "/setting/chart-of-accounts-entry/view",
        routeUrl: { create: "/setting/chart-of-accounts-entry/create", upload: "/setting/chart-of-accounts-upload" },
        authFunctions: {
            newButtonFunction: "CHARTOFACCOUNTS_CREATE", uploadButtonFunction: "CHARTOFACCOUNTS_CREATE",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },
    "dashboard": {
        getLatestRorData: { url: 'investorCapitalSummary/getLatestRorDataByInvestorAndDateRange', method: 'POST' },
        getLatestRorDataMonthly: { url: 'investorCapitalSummary/getLatestMonthlyRorDataByInvestorAndDateRange', method: 'POST' },
        getLatestRorDataQuarterly: { url: 'investorCapitalSummary/getLatestQuarterlyRorDataByInvestorAndDateRange', method: 'POST' },
        getLatestRorDataAnnually: { url: 'investorCapitalSummary/getLatestAnnuallyRorDataByInvestorAndDateRange', method: 'POST' },
        fundList: { url: 'investor/getInvestorsListAssociatedByFund', method: 'GET' },
        getInvestorByUser: { url: 'investor/getInvestorsListAssociatedByFund', method: 'GET' },
        dashboardCardData: { url: 'investorCapitalSummary/getLatestCapitalSummaryByInvestor', method: 'POST' },
        combineROR: { url: 'investorCapitalSummary/investorCombineROR', method: 'POST' },
        fundListEnt: { url: 'investorV2/getInvestorsFundListforEntUser', method: 'GET' },
    },
    "investorStatement": {
        fetchAllByEnterprise: { url: 'fund/fetchFundsListByInvestorEnterprise', method: 'POST' },
        filterByFund: { url: 'investor/findInvestorsFilterByFundId', method: 'GET' },
        generateReport: { url: 'investorPortal/filterinvestorItd', method: 'POST' },
        fundList: { url: 'investor/getInvestorsListAssociatedByFund', method: 'GET' },
        investorNameList: { url: 'fund/getInvestorsforInvStAIf', method: 'GET' },
    },
    "K1Merging": {
        pathName: 'K1-merging-details',
        headers: "assets/headers/tax-automation/K1merging.JSON",
        gridDataUrl: "coaTaxClassification/taxInvestorMapping/",
        updateUrl: 'coaTaxClassification/updateTaxInvestorMapping',
        // gridDeleteUrl: "coaTaxClassification/",
        method: 'post',
        moduleName: "K1Merging",
        filterUrl: { url: "coaTaxClassification/filterByKeysTaxInvestorMApping" },
        gridExportUrl: { url: "coaTaxClassification/downloadTaxInvestorMappingdRecords/", isZoneName: false },
        gridFilteredUrl: { url: "coaTaxClassification/downloadFilterRecordsTaxInvestorMapping/", isZoneName: false },
        routeUrl: { create: "/taxSetup/chart-of-account-taxmapping-details/create", upload: "" },
        //viewUrl: "/taxSetup/chart-of-account-taxmapping-details/view",

        viewUrl: "None",

        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "None",
            updateButtonFunction: "SEAMLESS_TAXATION_COATAXCLASSIFICATION_UPDATE"
        }
    },
    'fetchCsdBankTransactionPendingReview': {
        headers: 'assets/headers/pendingForReview/accounting/fetchBankTransaction.JSON',
        gridDataUrl: 'fetchCSDErrorsController/records/',
        gridDeleteUrl: "fetchCSDErrorsController/",
        exportURL: { url: api_url + 'exportController/downloadFilter' },
        moduleName: "csdBankTransactionsPendingForReview",
        updateUrl: 'fetchCSDErrorsController/update',
        exportModuleName: "csdBankTransactionErrors",
        deleteButtonFunction: "None",
        filterUrl: { url: "fetchCSDErrorsController/filterByKeys" },
        gridExportUrl: { url: "exportController/download/csdBankTransactionErrors", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        newButtonFunction: '',
        routeUrl: { create: "/input/create/fetchcsdbanktransaction-entry/create", upload: "/input/upload/csd-bank-transaction-pending-review-upload" },
        viewUrl: "/input/create/fetchcsdbanktransaction-entry/view",
        uploadRedirectionUrl: "/input/pendingForReview/fetchcsdbanktransactionReview",
        uploadRedirectionUrl2: "/reconciliation/view-non-trade-trans-tp",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/pendingForReview',
            uploadUrl: "fetchCSDErrorsController/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/pendingForReview",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/pendingForReview/", name: "csd_bank_transaction_review", sampleName: "pendingforreview", dataStringify: true,
            resName: "failedObjects",
            uploadRedirectionUrl: "input/pendingForReview/fetchcsdbanktransactionReview",

        },


        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "SEAMLESS_INPUT_UPLOAD_ACCOUNTING_CSDBANKTRANSACTION_UPDATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CSDBANKTRANSACTION", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CSDBANKTRANSACTION_DELETE",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_CSDBANKTRANSACTION_EDIT"
        }
    },
    "performanceAttributes": {
        exportURL: { url: api_url + 'reports/performanceAttributes/downloadExcel' },
        moduleName: "",
    },
    "grossCashFlowAdjustment": {
        headers: "assets/headers/settings-header/Configuration/gross-cash-flow-adjustment.JSON",
        gridDataUrl: "grossCashFlowAdjustment/getDetails/",
        gridDeleteUrl: "grossCashFlowAdjustment/delete/",
        moduleName: "grossCashFlowAdjustment",
        filterUrl: { url: "grossCashFlowAdjustment/filterByKeys" },
        gridExportUrl: { url: "grossCashFlowAdjustment/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "grossCashFlowAdjustment/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/input/create/gross-cash-flow-entry/create", upload: "/input/upload/gross-cash-flow-upload" },
        viewUrl: "/input/create/gross-cash-flow-entry/view",
        uploadRedirectionUrl: "/input/view/gross-cash-flow",

        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/grossCashFlowAdjustment',
            uploadUrl: "grossCashFlowAdjustment/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/grossCashFlowAdjustment",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/grossCashFlowAdjustment/", name: "gross_cash_flow_adjustment", sampleName: "grosscashflowadjustment", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_CUSTOM_GROSS_CASHFLOW_CREATE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_CUSTOM_GROSS_CASHFLOW_CREATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_CUSTOM_GROSS_CASHFLOW", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_CUSTOM_GROSS_CASHFLOW_DELETE",
        }
    },
    "contributionDistributionAdjustment": {
        headers: "assets/headers/settings-header/Configuration/corntribution-distribution-adjustment.JSON",
        gridDataUrl: "contributionDistributionAdjustment/getDetails/",
        gridDeleteUrl: "contributionDistributionAdjustment/delete",
        moduleName: "contributionDistributionAdjustment",
        filterUrl: { url: "contributionDistributionAdjustment/filterByKeys" },
        gridExportUrl: { url: "contributionDistributionAdjustment/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "contributionDistributionAdjustment/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/input/create/contribution-distribution-entry/create", upload: "/input/upload/upload-contribution-distribution" },
        viewUrl: "/input/create/contribution-distribution-entry/view",
        uploadRedirectionUrl: "/input/view/contribution-distribution",

        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/contributionDistributionAdjustment',
            uploadUrl: "contributionDistributionAdjustment/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/contributionDistributionAdjustment",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/contributionDistributionAdjustment/", name: "contribution_distribution_adjustment", sampleName: "contributiondistributionadjustment", dataStringify: false
        },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_CUSTOM_CONTRIBUTIONANDDISTRIBUTION_CREATE", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_CUSTOM_CONTRIBUTIONANDDISTRIBUTION_CREATE",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_CUSTOM_CONTRIBUTIONANDDISTRIBUTION", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_CUSTOM_CONTRIBUTIONANDDISTRIBUTION_DELETE",
        }
    },

    "realizedGainLossDealWise": {
        exportURL: { url: api_url + 'reports/realisedGainLossDealWise/realizedGainLossExcelDealWise' },
        pdfURL: { url: api_url + 'reports/realisedGainLossDealWise/realisedgainLossPdfDealWise' },
        iframeURLDetailed: { url: 'reports/realisedGainLossDealWise/realizedGainLossPdfDealWise' },

        moduleName: "",
    },
    "midPeriod": {
        headers: "assets/headers/tax-automation/MidPeriod.JSON",
        gridDataUrl: "taxationMidPeriod/records/",
        //  gridDeleteUrl: "taxReturnDetails/",
        moduleName: "taxationMidPeriod",
        filterUrl: { url: "taxationMidPeriod/filterByKeys" },
        gridExportUrl: { url: "taxationMidPeriod/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxationMidPeriod/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/ta/config/ta-entry/view",
        //  routeUrl: { create: "/ta/config/ta-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }

    },
    "taxAdjusted": {
        headers: "assets/headers/tax-automation/taxAdjusted.JSON",
        gridDataUrl: "taxationAdjustmentJE/records/",
        //  gridDeleteUrl: "taxReturnDetails/",
        moduleName: "taxAdjusted",
        method: 'post',
        filterUrl: { url: "taxationAdjustmentJE/filterByKeys" },
        gridExportUrl: { url: "taxationAdjustmentJE/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxationAdjustmentJE/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/ta/config/ta-entry/view",
        //  updateUrl: 'taxationAdjustmentJE/create',
        createUrl: { url: 'taxationAdjustmentJE/create', method: 'post' },
        updateUrl: "taxationAdjustmentJE/update",
        //  routeUrl: { create: "/ta/config/ta-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTANDPROCESSING_ADJUSTMENTENTRY_UPDATE", deleteButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTANDPROCESSING_ADJUSTMENTENTRY_DELETE",
            updateButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTANDPROCESSING_ADJUSTMENTENTRY_UPDATE"
        }

    },
    "investorAllocationProgram": {
        moduleName: "investorAllocationProgram",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/investorAllocationProgram',
            uploadUrl: "investorV2/uploadInvestorAllocationProgram", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/investorAllocationProgram",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/investorAllocationProgram/", name: "allocation_program", sampleName: "investorallocationprogram", dataStringify: true
        }
    },
    "feeTemplateLinking": {
        headers: "assets/headers/settings-header/accountingSetting/feeTemplateLinking.JSON",
        gridDataUrl: "feeTemplateLinkingController/records/",
        gridDeleteUrl: "feeTemplateLinkingController/",
        moduleName: "feeTemplateLinkingMoule",
        exportModuleName: "feeTemplateLinking",
        gridExportUrl: { url: "exportController/download/feeTemplateLinking", isZoneName: false },
        filterUrl: { url: "feeTemplateLinkingController/filterByKeys" },
        gridFilteredUrl: { url: "exportController/downloadFilter/feeTemplateLinking/", isZoneName: false },
        viewUrl: "/setting/fee-template-linking-entry/view",
        routeUrl: { create: "/setting/fee-template-linking-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "None", updateButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FEETEMPLATELINKING_EDIT", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_FEETEMPLATELINKING_VIEW", deleteButtonFunction: "None",
        }
    },
    "crystalizedPerformanceFeeallocationList": {
        headers: "assets/headers/settings-header/accountingSetting/crystalizedPerformanceFeeallocation.JSON",
        gridDataUrl: "crystalizedPerformanceFeeAllocation/records/",
        moduleName: "crystalizedPerformanceFeeConfig",
        viewUrl: "/setting/crystalized-per-fee-allocation-entry/view",
        routeUrl: { create: "/setting/crystalized-per-fee-allocation-entry/create", upload: "" },
        exportModuleName: "feeTemplateLinking",
        gridExportUrl: { url: "crystalizedPerformanceFeeAllocation/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "crystalizedPerformanceFeeAllocation/downloadFilter/", isZoneName: false },
        filterUrl: { url: "crystalizedPerformanceFeeAllocation/filterByKeys" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_CRYSTALIZED_PERFORMANCE_FEE_ALLOCATION_CREATE", uploadButtonFunction: "",
            viewButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_CRYSTALIZED_PERFORMANCE_FEE_ALLOCATION_VIEW", deleteButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_CRYSTALIZED_PERFORMANCE_FEE_ALLOCATION_DELETE",
        }
    },
    "notesToTaxationList": {
        headers: "assets/headers/tax-automation/notesToTaxationList.JSON",
        gridDataUrl: "notesToTaxation/records/",
        gridDeleteUrl: "taxationTaxReturnDetailsK1/",
        moduleName: "taxationNotesToTaxation",
        gridExportUrl: { url: "taxPartnershipPassthroughK1/downloadRecords/", isZoneName: false },
        filterUrl: { url: "taxPartnershipPassthroughK1/filterByKeys" },
        gridFilteredUrl: { url: "taxPartnershipPassthroughK1/downloadFilterRecords/", isZoneName: false },
        // uploadDetails: {
        //     createDefaultConfigUrl: 'createDefaultConfigModulebase/taxPartnershipPassthroughK1',
        //     uploadUrl: "taxPartnershipPassthroughK1/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxadditionk1details",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxPartnershipPassthroughK1/", name: "Partnership Passthrough-K1", sampleName: "taxadditionk1details", dataStringify: true
        // },
        viewUrl: "/taxSetup/notes-to-taxation-entry/view",
        routeUrl: { create: "/taxSetup/notes-to-taxation-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_NOTESTOTAXATION_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_NOTESTOTAXATION_CREATE",
            viewButtonFunction: "SEAMLESS_TAXATION_NOTESTOTAXATION_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_NOTESTOTAXATION_DELETE",
        }
    },
    "customizationSetupInvestorJeTransfer": {
        entCard: 'others',
        headers: "assets/headers/manager-headers/customizationSetupInvestorJeTransfer.JSON",
        gridDataUrl: "setupJETransferInvestorLevel/records/",
        gridDeleteUrl: "setupJETransferInvestorLevel/",
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        moduleName: "setupJETransferInvestorLevel",
        filterUrl: { url: "setupJETransferInvestorLevel/filterByKeys" },
        gridExportUrl: { url: "setupJETransferInvestorLevel/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "setupJETransferInvestorLevel/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/customization/setup-investor-je-transfer-entry/create", upload: "/customization/upload-setup-investor-je-transfer" },
        uploadRedirectionUrl: "/customization/setup-investor-je-transfer",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/setupJETransferInvestorLevel',
            uploadUrl: "setupJETransferInvestorLevel/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/setupJETransferInvestorLevel",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/setupJETransferInvestorLevel/", name: "setup_je_transfer_investor_level", sampleName: "setupjetransferinvestorleveldemo", dataStringify: true
        },
        viewUrl: "/customization/setup-investor-je-transfer-entry/view",
        authFunctions: {
            newButtonFunction: "SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL_CREATE", uploadButtonFunction: "SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL_CREATE",
            viewButtonFunction: "SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL", deleteButtonFunction: "SEAMLESS_CUSTOMIZATION_OTHERCONFIGURATION_SETUP_JETRANSFER_INVESTOR_LEVEL_DELETE",
        }
    },
    "transferorNumbersForInvestorStatements": {
        userCard: "custom",
        pathName: "transferor_Inv_Statements",
        headers: "assets/headers/custom/transferorNumbersForInvestorStatements.JSON",
        gridDataUrl: "transferorNumbers/fetchAllRecords/",
        gridDeleteUrl: "transferorNumbers/",
        newButtonFunction: "none",
        moduleName: "transferorNumbersForInvestorStatementsModule",
        fetchCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transferorNumberforStatements",
        gridExportUrl: { url: "exportController/download/transferorNumberforStatements", isZoneName: false },
        filterUrl: { url: "transferorNumbers/filterByKeys" },
        fileName: "transferorNumberforStatementsFiltered",
        gridFilteredUrl: { url: "exportController/downloadFilter?moduleName=transferorNumberforStatements", isZoneName: false },
        routeUrl: { create: "none", upload: "/input/upload/TransferorNoForInvStatement" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/transferorNumberforStatements', uploadUrl: "transferorNumbers/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/transferorNumberforStatements",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/transferorNumberforStatements/", name: "transferor_Inv_Statements", sampleName: "transferorNumbersForInvestorStatements", dataStringify: false,
        },
        viewUrl: "none",
        uploadRedirectionUrl: "/input/view/TransferorNoForInvStatement",
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "SEAMLESS_INPUT_CUSTOM_UPLOAD_TRANSFEROR_NUMBERS_FOR_INVESTOR_STATEMENTS",
            viewButtonFunction: "None", deleteButtonFunction: "SEAMLESS_INPUT_CUSTOM_VIEW_TRANSFEROR_NUMBERS_FOR_INVESTOR_STATEMENTS_DELETE",
            updateButtonFunction: "None"
        }
    },
    "globalallsmupload": {
        entCard: 'others',
        pathName: 'all_sm_upload',
        moduleName: 'globalallsmupload',
        uploadDetails: {
            createDefaultConfigUrl: 'dataConfiguration/fetchAllCustomTypes/allSMs',
            uploadUrl: "AllSMDownload/uploadAllSMV2/SMFutures/allSMs?smUploadConfigType=Default", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/allSMs",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/allSMs/", name: "all_sm_upload", sampleName: "allsmupload", dataStringify: true
        }
    },
    "taxPartnershipPassthroughK1": {
        headers: "assets/headers/tax-automation/taxPartnershipPassthroughK1.JSON",
        gridDataUrl: "taxationPassThroughEnt/records/",
        gridDeleteUrl: "taxationPassThroughJE/",
        moduleName: "taxationPassThroughJE",
        gridExportUrl: { url: "taxationPassThroughEnt/downloadRecords/", isZoneName: false },
        filterUrl: { url: "taxationPassThroughEnt/filterByKeys" },
        gridFilteredUrl: { url: "taxationPassThroughEnt/downloadFilterRecords/", isZoneName: false },
        // uploadDetails: {
        //     createDefaultConfigUrl: 'createDefaultConfigModulebase/taxationPassThroughEnt',
        //     uploadUrl: "taxationPassThroughEnt/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxadditionk1details",
        //     getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxationPassThroughEnt/", name: "Partnership Passthrough-K1", sampleName: "taxadditionk1details", dataStringify: true
        // },
        viewUrl: "None",
        routeUrl: { create: "/taxSetup/partnership-passthrough-K1-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTJES_PASSTHROUGHJE_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTJES_PASSTHROUGHJE_CREATE",
            viewButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTJES_PASSTHROUGHJE_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_TAXADJUSTMENTJES_PASSTHROUGHJE_DELETE",
        }
    },

    "commonIncomeExpenseConfiguration": {
        headers: "assets/headers/settings-header/accountingSetting/commonIncomeExpenseConfiguration.JSON",
        gridDataUrl: "commonIncomeExpenseConfig/fetchAllRecords/",
        gridDeleteUrl: "setupJETransferInvestorLevel/",
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        moduleName: "commonIncomeExpenseConfig",
        filterUrl: { url: "commonIncomeExpenseConfig/filterByKeys" },
        gridExportUrl: { url: "commonIncomeExpenseConfig/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "commonIncomeExpenseConfig/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/setting/common-income-expense-config-entry/create", upload: "/setting/upload-common-income-expense-config" },
        uploadRedirectionUrl: "/setting/upload-common-income-expense-config",
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/commonIncomeExpenseConfig',
            uploadUrl: "commonIncomeExpenseConfig/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/commonIncomeExpenseConfig",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/commonIncomeExpenseConfig/", name: "common-income-expense-config", sampleName: "commonincomeexpenseconfig", dataStringify: true
        },
        viewUrl: "/setting/common-income-expense-config-entry/view",
        authFunctions: {
            newButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_CREATE", uploadButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_CREATE",
            viewButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_VIEW", deleteButtonFunction: "SEAMLESS_SETTINGS_ACCOUNTING_COMMON_INCOME_EXPENSE_CONFIG_DELETE",
        }
    },
    "bookIncomeExpense": {

        headers: 'assets/headers/accounting/bookIncomeExpense.JSON',
        gridDataUrl: "bookIncomeExpense/fetchAllRecords/",
        gridDeleteUrl: "bookIncomeExpense/",
        moduleName: "bookIncomeExpense",
        filterUrl: { url: "bookIncomeExpense/filterByKeys" },
        gridExportUrl: { url: "bookIncomeExpense/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "bookIncomeExpense/downloadFilterRecords/", isZoneName: false },
        routeUrl: { create: "/input/create/book-income-expense-entry/create", upload: "/customization/upload-setup-investor-je-transfer" },
        uploadRedirectionUrl: "/input/view/book-income-expense",
        viewUrl: "/input/create/book-income-expense-entry/view",
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_COMMON_INCOME_EXPENSE_CREATE", uploadButtonFunction: "Node",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_COMMON_INCOME_EXPENSE_VIEW", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_COMMON_INCOME_EXPENSE_DELETE",

        }
    },
    "FootNoteMaster": {
        pathName: 'footnote_master_file',
        headers: "assets/headers/system-manager-headers/noteFooter.JSON",
        gridDataUrl: "taxationDefaultNotes/records/",
        moduleName: "taxationDefaultNotes",
        filterUrl: { url: "taxationDefaultNotes/filterByKeys" },
        gridExportUrl: { url: "taxationDefaultNotes/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxationDefaultNotes/downloadFilterRecords/", isZoneName: false },
        viewUrl: "/systemmanager/footnote-master-entry/view",
        routeUrl: { create: "/systemmanager/footnote-master-entry/create", upload: "/systemmanager/footnote-master-upload" },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/taxationDefaultNotes',
            uploadUrl: "taxationDefaultNotes/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxationDefaultNotes",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxationDefaultNotes/", name: "footnote_master", sampleName: "taxationdefaultnotes", dataStringify: true
        },
        authFunctions: {
            newButtonFunction: "SYSADMIN_TAXATION_FOOTNOTE_CREATE", uploadButtonFunction: "SYSADMIN_TAXATION_FOOTNOTE_UPLOAD",
            viewButtonFunction: "SYSADMIN_TAXATION_FOOTNOTE_VIEW", deleteButtonFunction: "SYSADMIN_TAXATION_FOOTNOTE_DELETE",
            updateButtonFunction: "SYSADMIN_TAXATION_FOOTNOTE_UPDATE"
        }
    },


    "rapidAccruedDividend": {
        headers: "assets/headers/reconciliation/accrued-dividend.JSON",
        gridDataUrl: "dividendReconcileController/accruedDividendRecords/",
        moduleName: "accruedDividend",
        gridFilteredUrl: { url: "exportController/downloadFilter", isZoneName: false },
        exportModuleName: "accruedDividend",
        gridExportUrl: { url: "exportController/download/accruedDividend", isZoneName: false },
        filterUrl: { url: "dividendReconcileController/filterReconciledData" },
        newButtonFunction: "None",
        viewUrl: "",
        routeUrl: { create: "", upload: "" },
        authFunctions: {
            newButtonFunction: "none", uploadButtonFunction: "none",
            viewButtonFunction: "none", deleteButtonFunction: "none",
        }
    },
    "emailLogDashboard": {
        headers: 'assets/headers/manager-headers/emailLogDashboard.JSON',
        gridDataUrl: 'emailLogDashboard/records/',
        gridDeleteUrl: "emailLogDashboard/",
        moduleName: "genericEmailLogs",
        exportModuleName: "genericEmailLogs",
        filterUrl: { url: "emailLogDashboard/filterByKeys" },
        gridExportUrl: { url: 'exportController/download/genericEmailLogs', method: 'GET' },
        gridFilteredModuleUrl: { url: "exportController/downloadFilter" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "None", uploadButtonFunction: "None",
            viewButtonFunction: "None", deleteButtonFunction: "None",
            updateButtonFunction: "None"
        }
    },
    "investorStatementCoverLetter": {
        headers: "assets/headers/settings-header/Configuration/investorStatementCoverLetter.JSON",
        gridDataUrl: "investorStatementCoverLetter/getDetails/",
        gridDeleteUrl: "deleteController/",
        moduleName: "investorStatementCoverLetter",
        gridExportUrl: { url: "investorStatementCoverLetter/downloadRecords", isZoneName: false },
        filterUrl: { url: "investorStatementCoverLetter/filterByKeys" },
        gridFilteredUrl: { url: "investorStatementCoverLetter/downloadFilterRecords", isZoneName: false },
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        viewUrl: "/setting/investor-statement-cover-letter-entry/view",
        routeUrl: { create: "/setting/investor-statement-cover-letter-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_SETTINGS_CONFIG_INVESTOR_STATEMENT_COVER_LETTER_CREATE", uploadButtonFunction: "None",
            viewButtonFunction: "SEAMLESS_SETTINGS_CONFIG_INVESTOR_STATEMENT_COVER_LETTER_VIEW", deleteButtonFunction: "SEAMLESS_SETTINGS_CONFIG_INVESTOR_STATEMENT_COVER_LETTER_DELETE",
        }
    },
    "schedulek1": {
        exportURL: { url: 'TaxationReport/downloadReportForK1' },

    },
    "taxJournalEntry": {
        headers: "assets/headers/tax-automation/TaxJournalEntry.JSON",
        gridDataUrl: "taxJournalEntryRevised/records/",
        filterUrl: { url: "taxJournalEntryRevised/filterByKeys" },
        newButtonFunction: 'SEAMLESS_INPUT_CREATE_ACCOUNTING_JOURNALENTRY',
        deleteButtonFunction: 'SEAMLESS_INPUT_VIEW_ACCOUNTING_JOURNALENTRY_DELETE',
        gridDeleteUrl: "taxJournalEntryRevised/",
        moduleName: "taxJournalEntryRevised",
        exportModuleName: "taxationJournalEntryRevised",
        updateUrl: "taxJournalEntryRevised/update",
        // routeUrl: { create: "/accounting/journalEntry-entry/create", upload: '/accounting/journalEntryUpload' },
        uploadDetails: {
            uploadUrl: "taxJournalEntryRevised/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/journalEntry",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/journalEntry/", name: "journal_entry", sampleName: "journalentrydemo", dataStringify: true
        },
        // viewUrl: "/accounting/journalEntry-entry/view",
        viewUrl: "/input/create/journalEntry-entry/view",
        gridExportUrl: { url: "exportController/downloadFilter/taxationJournalEntryRevised/", isZoneName: false },
        // gridFilteredUrl: { url: "exportController/downloadFilter/journalEntryRevised/" },
        uploadRedirectionUrl: "/input/view/journalEntry",
        routeUrl: { create: "/input/create/journalEntry-entry/create", upload: "/input/upload/journalEntry-upload" },
        gridFilteredUrl: { url: "exportController/downloadFilter" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_JOURNALENTRY", uploadButtonFunction: "SEAMLESS_INPUT_CREATE_ACCOUNTING_JOURNALENTRY",
            viewButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_JOURNALENTRY_12", deleteButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_JOURNALENTRY_DELETE_12",
            updateButtonFunction: "SEAMLESS_INPUT_VIEW_ACCOUNTING_JOURNALENTRY_EDIT"
        }

    },
    "jeSummary": {
        headers: "assets/headers/tax-automation/TaxJESummary.JSON",
        gridDataUrl: "taxJESummary/getTaxJESummary/",
        filterUrl: { url: "taxJESummary/filterByKeysJESummary" },
        method: 'post',
        newButtonFunction: 'None',
        deleteButtonFunction: 'None',
        gridExportUrl: { url: "exportController/download/taxationJESummary", isZoneName: false },
        gridFilteredUrl: { url: "exportController/downloadFilter/taxationJESummary/" },
    },
    "taxincomeAllocation": {
        headers: "assets/headers/tax-automation/taxincomeAllocation.JSON",
        gridExportUrl: { url: "exportController/downloadFilter", isZoneName: false },
        moduleName: "taxationAllocationRatio",
        method: 'post',
        // gridDataUrl: "taxIncomeAllocationRatio/viewTaxIncomeAllocation/",
        filterUrl: { url: "taxIncomeAllocationRatio/filterByKeys" },
        // http://localhost:8088/sudrania/exportController/downloadFilter?moduleName=taxationAllocationRatio
    },
    "taxcustodianList": {
        pathName: 'custodians',
        headers: "assets/headers/tax-automation/taxcustodian.JSON",
        gridDataUrl: "taxCustodianBroker/records/",///needs to change true/false once we add same on systemmanager lvl
        // gridDeleteUrl: "brokerAccount/", no delete 
        moduleName: "brokerAccount",
        fileName: "custodianListFiltered",
        filterUrl: { url: "taxCustodianBroker/filterByKeys" },
        gridExportUrl: { url: "taxCustodianBroker/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxCustodianBroker/downloadFilterRecords", isZoneName: false },
        routeUrl: { create: "/setup/custodianst-entry/create", upload: "/setup/custodians-upload" },
        uploadDetails: {
            uploadUrl: "taxCustodianBroker/newUpload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/custodian",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/custodian/", name: "Custodian ", sampleName: "custodiandemo"
        },
        viewUrl: "None",
        systemAdminViewUrl: "/systemmanager/custodians/view",
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "", deleteButtonFunction: "",
        }
    },
    "startupOrganizational": {
        headers: "assets/headers/tax-automation/StartupAndOrganizationalCost.JSON",
        gridDataUrl: "organizationalCostAndStartUp/getOrgCostCalculationRecords/",
        //  gridDeleteUrl: "taxReturnDetails/",
        method: 'post',
        moduleName: "organizationalCostAndStartUp",
        filterUrl: { url: "organizationalCostAndStartUp/filterByKeys" },
        gridExportUrl: { url: "organizationalCostAndStartUp/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "organizationalCostAndStartUp/downloadFilterRecords/", isZoneName: false },
        viewUrl: "None",
        //  routeUrl: { create: "/ta/config/ta-entry/create", upload: "" },
        authFunctions: {
            newButtonFunction: "", uploadButtonFunction: "",
            viewButtonFunction: "None", deleteButtonFunction: "None",
        }
    },
    "taxAllocationAdjustmentRatios": {
        headers: "assets/headers/manager-headers/taxAdjustmentallocation.JSON",
        gridDataUrl: "taxAllocationAdjRatio/records/",
        gridDeleteUrl: "vendorV2/",
        moduleName: "taxationSpecialAllocationRatio",
        updateUrl: "taxAllocationAdjRatio/update",
        filterDeleteUrl: { url: "deleteController/deleteFilterByKeys" },
        gridExportUrl: { url: "taxAllocationAdjRatio/downloadRecords/", isZoneName: false },
        filterUrl: { url: "taxAllocationAdjRatio/filterByKeys" },
        gridFilteredUrl: { url: "taxAllocationAdjRatio/downloadFilterRecords/", isZoneName: false },
        uploadDetails: {
            createDefaultConfigUrl: 'createDefaultConfigModulebase/taxationSpecialAllocationRatio',
            uploadUrl: "taxAllocationAdjRatio/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/taxationSpecialAllocationRatio",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/taxationSpecialAllocationRatio/", name: "tax_allocation_adjustment_ratios", sampleName: "taxationspecialallocationratio", dataStringify: false
        },
        viewUrl: "None",
        routeUrl: { create: "/taxSetup/tax-allocation-adjustment-ratios-entry/create", upload: "/taxSetup/tax-allocation-adjustment-ratios-upload" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_SETUP_ADJUSTMENTRATIO_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_SETUP_ADJUSTMENTRATIO_CREATE",
            viewButtonFunction: "SEAMLESS_TAXATION_SETUP_ADJUSTMENTRATIO_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_SETUP_ADJUSTMENTRATIO_DELETE",
        }
    },
    "taxCustomRatio": {
        headers: 'assets/headers/accounting/customRatio.JSON',
        gridDataUrl: 'taxationCustomRatio/records/',
        gridDeleteUrl: 'taxationCustomRatio/',
        moduleName: "taxationCustomRatio",
        updateUrl: "taxationCustomRatio/update",
        filterUrl: { url: "taxationCustomRatio/filterByKeys" },
        uploadDetails: {
            uploadUrl: "taxationCustomRatio/upload", getCustomTypeUrl: "dataConfiguration/fetchAllCustomTypes/ratio",
            getConfigUrl: "dataConfiguration/fetchAllByDomainAndType/ratio/", name: "custom_ratio", sampleName: "ratiodemo", dataStringify: false
        },
        // routeUrl: { create: "/accounting/customRatio-entry/create", upload: "/accounting/customRatioUpload" },
        // viewUrl: '/accounting/customRatio-entry/view',
        routeUrl: { create: "/taxSetup/taxation-custom-ratio/create", upload: "/taxSetup/taxation-custom-ratio-upload" },
        viewUrl: "None",
        uploadRedirectionUrl: "/taxSetup/taxation-custom-ratio",
        gridExportUrl: { url: "taxationCustomRatio/downloadRecords/", isZoneName: false },
        gridFilteredUrl: { url: "taxationCustomRatio/downloadFilterRecords/" },
        authFunctions: {
            newButtonFunction: "SEAMLESS_TAXATION_SETUP_TAXATIONCUSTOMRATIO_CREATE", uploadButtonFunction: "SEAMLESS_TAXATION_SETUP_TAXATIONCUSTOMRATIO_CREATE",
            viewButtonFunction: "SEAMLESS_TAXATION_SETUP_TAXATIONCUSTOMRATIO_VIEW", deleteButtonFunction: "SEAMLESS_TAXATION_SETUP_TAXATIONCUSTOMRATIO_DELETE",
        }
    },
    "taxBalancesheet": {
        exportURL: { url: 'taxationBalanceSheetReport/pdfDownload/true' },

    }

}
