<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<button class="button" fxHide fxShow.gt-sm mat-button
  [matTooltip]="!isFullscreen? ['full_screen'|translate] : ['exit_full_screen'|translate]" (click)="toggleFullscreen()"
  fxFlex="grow">
  <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
  <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
</button>