
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { GlobalService } from '../../services/commonServices/global.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { environment } from '../../../../../src/environments/environment';
import moment from 'moment';
import { GridService } from '../../services/gridServices/grid.service';
import { ClientGridComponent } from '../../custom-components/client-grid/client-grid.component';
import { ClientGridService } from '../../custom-components/client-grid/client-grid.service';
import { CommonService } from '../../services/commonServices/common.service';
import { FetchCSDBankTransactionEntryService } from 'src/app/views/rapid/input/create/fetchcsdbanktransaction-entry/fetchcsdbanktransaction-entry.service';
import alasql from 'alasql';

@Component({
  selector: 'fury-over-all-status-button',
  templateUrl: './over-all-status-button.component.html',
  styleUrls: ['./over-all-status-button.component.scss']
})
export class OverAllStatusButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  public alteryxWorkflowStatus: any;
  public moduleName: string;
  public display: boolean = false;
  public display1: boolean = false;
  public displayDeactive: boolean = false;
  public displayActive: boolean = false;
  public currentUser: any;
  public enterpriseId: any;
  public selectedRows: any;
  sendStatementToInvesor: any;
  status: any;
  coaStatus: any;
  years: any;
  month: any;
  monthYearNew: any
  sendEmailToClientAndCC: any;
  public overAllStatus: any;
  public custodianURL:any;
  monthyear: any
  taxLotsProcessStatus: any;
  public executionStatus: any;
  public colDef: any;
  toolTipValue: string;
  isdatadumpintemp: any;
  failureCount: any;
  pendingDataCount: any;
  processStatus: any;
  dataCountTemp: any;
  dispositionStatus: any;
  componentName:any;
  public disableAction:any;
  public isEODDownloadEnable :any;
  processingData:any={}
  headerProcessStatusDialogbox ='Processing Status'
  brsAmount: any;
  brsAmountCheck: any;
  isMemoEntryCheck: any;
  amountCheck: any;
  public taxStatus:any;
  displayStatus: boolean;
  constructor( private gridService: GridService,private http: HttpClient,private messageService: GlobalMessageService,private processScreenService: ProcessScreenService, private commonService: CommonService,
    private globalService: GlobalService,public translate: TranslateService, private clientGridService: ClientGridService,public fetchCSDBankTransaction:FetchCSDBankTransactionEntryService) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.overAllStatus = this.params.value ? this.params.value : null;
    this.taxLotsProcessStatus = this.params.value ? this.params.value : null;
    this.custodianURL = this.params.data.custodianURL ? this.params.data.custodianURL : null;
    this.taxStatus = this.params.data.status;
    this.sendStatementToInvesor = this.params.data.sendStatementToInvesor;
    this.processingData = this.params.data;
    this.disableAction = this.params.data.fetchCSDErrorsDownloadEnable;
    this.isEODDownloadEnable = this.params.data.eoddownloadEnable;
    this.brsAmount = this.params.data.amount ? this.params.data.amount : null;
    this.brsAmountCheck = this.params.data.crOrDr
    this.isMemoEntryCheck = this.params.data.isMemoEntry
    this.status = this.params.data.status;
    this.coaStatus = this.params.data.coaStatus;
    this.amountCheck = this.brsAmount< 0 ? Math.abs(this.brsAmount) : this.brsAmount
    this.globalService.getNewUserInfo().subscribe(info => {
      if (this.params.api.componentName == undefined) {
        this.params.api.componentName = info.componentName;
      }
    })
    this.componentName = this.params.api.componentName;
    this.moduleName = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent].moduleName:(apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName);
    this.sendEmailToClientAndCC = this.params.data.sendEmailToClientAndCC;
    if(this.moduleName == "workflowStatus" || this.moduleName == "dataImportStatusManager"){
      this.colDef = this.params.colDef.field;
      this.isdatadumpintemp = this.params.data.isDataDumpInTemp;
      this.failureCount = this.params.data.failureCount;
      this.pendingDataCount = this.params.data.pendingDataCount;
      this.dispositionStatus = this.params.data.disposition;
      this.executionStatus = this.params.data.executionStatus;
      this.processStatus = this.params.data.processStatus;
      this.dataCountTemp = this.params.data.dataCountTemp;
      if(this.executionStatus == 'Queued' || this.executionStatus == 'queued'){
        this.toolTipValue = this.translate.instant('q_tooltip')
      }else if(this.executionStatus == 'Success' || this.executionStatus == 'success'){
        this.toolTipValue = this.translate.instant('s_tooltip')
      }else if(this.executionStatus == 'No Data' || this.executionStatus == 'no data'){
        this.toolTipValue = this.translate.instant('ec_tooltip')
      }else if(this.executionStatus == 'Processing' || this.executionStatus == 'processing'){
        this.toolTipValue = this.translate.instant('p_tooltip')
      }else if(this.executionStatus == 'Error' || this.executionStatus == 'error'){
        this.toolTipValue = this.translate.instant('e_tooltip')
      }else if(this.executionStatus == 'Uploaded Manually' || this.executionStatus == 'uploaded manually' || this.executionStatus == 'Uploaded manually'){
        this.toolTipValue = this.translate.instant('u_tooltip')
      }else if(this.executionStatus == 'Process Cancelled' || this.executionStatus == 'process cancelled'){
        this.toolTipValue = this.translate.instant('pc_tooltip')
      }
      else if(this.executionStatus == 'Refer Downloaded file' || this.executionStatus == 'refer downloaded file'
      || this.executionStatus == 'Refer Downloaded File' || this.executionStatus == 'Refer Download File'){
        if(!this.isdatadumpintemp){
          if(this.failureCount > 0 || this.pendingDataCount > 0){
            this.toolTipValue = this.translate.instant('r_tooltip')
          }
        }else{
          if(this.processStatus == null){
            if(this.dataCountTemp > 0){
              this.toolTipValue = this.translate.instant('pre_tooltip')
            }
            if(this.pendingDataCount > 0){
              this.toolTipValue = this.translate.instant('r_tooltip')
            }
          }else if(this.processStatus == 'Done'){
            this.toolTipValue = this.translate.instant('r_tooltip')
          }
        }
     }
    }
    if (this.moduleName == "executeWorkflow") {
      this.alteryxWorkflowStatus = this.params.data.alteryxWorkflowStatus;

    }
    if (this.moduleName == "programAcctExclusion") {
      var splitted = this.params.value.split("-");
      if (splitted[0].length == 2) {
        this.arraymove(splitted, 0, 1)
        this.monthyear = splitted.join("-").toString();

      }
      else {
        this.monthyear = splitted.join("-").toString();


      }
    }
    const body = document.getElementsByTagName('body')[0];
    if(this.params.api.subComponent =='dailyProcessingStatus'){     
      body.classList.add('processing-status');
    }else if( body.classList.contains('processing-status')){
      body.classList.remove('processing-status');
    }
    
  }
  referDownloadFile(){
    var uniqueId;
    let type:string = 'Zip file';
    if (this.params.data.uniqueNumber) {
      uniqueId = this.params.data.uniqueNumber;
      let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId +'/' +type;
	  // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
    this.commonService.GenericDownload(url,'Failed Data','Failed Data')

    } else {
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('invalid_data'), detail: this.translate.instant('error') });
      return;
    }
  /*  if(!this.isdatadumpintemp){
      if(this.failureCount > 0 || this.pendingDataCount > 0){
        let url = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
        this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
      }
    }else{
      if(this.processStatus == null){
        if(this.dataCountTemp > 0){
          let url = environment.api_url + 'alteryxExecuteWorkflow/getTempData/' + uniqueId;
          this.processScreenService.downloadCSV(url, 'Preview Data.csv','Preview Data');
        }
        if(this.pendingDataCount > 0){
          let url = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
          this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
        }
      }else if(this.processStatus == 'Done'){
        if(this.failureCount > 0 || this.pendingDataCount > 0){
          let url = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
          this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
        }
      }
    }*/
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[0];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  runDailyProcessingCompliance(event) {

    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;

    if (this.params.runDailyProcessingCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.runDailyProcessingCompliance(params);
    }
  }

  reportNotisficationCompliance(event) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.sendStatementToInvesor == null) {
      return false
    }

    if (this.params.reportNotisficationCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.reportNotisficationCompliance(params);
    }
  }


  enterpriseRowEventActivate(event, data) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;

    // if (this.params.enterpriseRowEventCompliance instanceof Function) {
    //   const params = {
    //     event: event,
    //     rowData: selectedData
    //   }
    //   this.params.enterpriseRowEventCompliance(params);
    // }
    this.status=selectedNode.data.status;
    if(this.status!='inactive'){
      this.display1 = true; 
    }
    else{
      this.displayStatus=true;
    }
    this.selectedRows = selectedNode.data;  
  }

  allUsersRowEventActivate(event, data) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;

    if (data == 'active') {
      this.displayActive = true;
    }
    else {
      this.displayDeactive = true;
    }
    this.selectedRows = selectedData;
    if (this.params.allUsersRowEventCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.allUsersRowEventCompliance(params);
    }
  }
showProcessDailog(){
  this.display = true;
}
dialogClose(value){
  this.display = value;
}

  refreshProcess() {
    this.http.get<any>('dataProcessingV2/viewWorkflow/' + this.params.data.id).subscribe(response => {
      if (response) {
        this.processingData = response
      }
    })
  }

  isNegetive(value:any) {
    if(value.toString() && value.toString().includes('-')) {
      return true;
    } else {
      return false;
    }
  }

  confirmationAllUsersStatus(userData) {
    this.selectedRows['status'] = userData;
    this.selectedRows['createdOn'] = moment(this.selectedRows['createdOn']).utc().format()
    this.selectedRows['updatedOn'] = moment(new Date()).utc().format()
    delete this.selectedRows['lastLogin'] 
    this.clientGridService.updateUserStatus(this.selectedRows).subscribe(
      res => {
        if(res)
        this.displayActive = false
        this.displayDeactive = false;
        if (this.params.refershGrid instanceof Function) {
          const params = {
            event: event
          }
          this.params.refershGrid();
        }
      })
  }
  csdDownload(){
    let request = {
      workFlowId : this.params.data.id,
      startDate : this.params.data.startDate,
      endDate : this.params.data.completeEndDate
    }
    this.fetchCSDBankTransaction.download(request).subscribe(
      response => {

        if (response && response['failedObjects'].length) {
          var args = response['failedObjects'];
          // var args = response['failedNonTradeTransactions'];
          this.downloadFailureTrades(args);
          if(response.message === "Some of the fetched records can't be saved. Please check error file."){
            this.messageService.add({ sticky: true, severity: 'warn', summary: 'Fetch CSD Bank Transaction', detail: response.message });
          }
          else{
            this.messageService.add({ sticky: true, severity: 'error', summary: 'Fetch CSD Bank Transaction', detail: response.message });
          }
        }
        else if (response.message === 'Data fetched successfully') {
          this.messageService.add({ sticky: true, severity: 'success', summary: 'Fetch CSD Bank Transaction', detail: response.message });
        } 
        else if(response.message === "Some of the fetched records can't be saved. Please check error file."){
          this.messageService.add({ sticky: true, severity: 'warn', summary: 'Fetch CSD Bank Transaction', detail: response.message });
        }
        else{
          this.messageService.add({ sticky: true, severity: 'error', summary: 'Fetch CSD Bank Transaction', detail: response.message });
        }
      },
      error => {
      });
  }
  downloadFailureTrades = function (failedTradeAllocationConfig) {
    var failName = "failed " + "CSD bank Transaction" + " .xlsx";
    var failUrl =
      'SELECT * INTO XLSX("' + failName + '",{headers:true}) FROM ?';
    alasql(failUrl, [failedTradeAllocationConfig]);
  };

  confirmationEnterpriseStatus(userData){
    delete this.selectedRows['CustaccountNumber'];
    delete this.selectedRows['finalSequence'];
    delete this.selectedRows['inputMode'];
    delete this.selectedRows['cust_Tag'];
    delete this.selectedRows['uploadData'];
    delete this.selectedRows['isPreviewData'];
    delete this.selectedRows['isPreviewData'];
    delete this.selectedRows['scr_status'];
    if(this.selectedRows['executionLocked']){
     delete this.selectedRows['executionLocked'];
    }
    this.selectedRows['status']=userData;
    this.selectedRows['updatedOn']=moment(new Date()).utc().format() 
    
    this.gridService.updateEnterpriseStatus(this.selectedRows).subscribe(
       res => { 
        this.display1=false 
        this.displayStatus=false;
        
        if (this.params.refershGrid instanceof Function) {
          const params = {
            event: event
          }
          this.params.refershGrid();
        }
        
       })
   }
  exportDocForEODOneButton(){
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    let dataToTransfer = {
      "endDate": selectedData.endDate,
      "startDate": selectedData.startDate,
      "completeEndDate": selectedData.completeEndDate,
      "id": selectedData.id,
      "configurationId": selectedData.configurationId
    }
    let url = 'eodChecksReport/downloadHistoricalRecordEodCheckSheet'
    this.commonService.postBlobResponse(url,  btoa(JSON.stringify(dataToTransfer))).subscribe(({ blob, contentType, filename }) => {
      if (blob.size) {
        var a = document.createElement('a');
        var file = new Blob([blob], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = filename || "EODChecks.xlsx";
        a.click();
      this.messageService.add({ sticky: true, severity: 'success', summary: 'EOD Check Sheet', detail: this.translate.instant('download_request_taken_soon') });
      }
      else{
        this.messageService.add({ sticky: true, severity: 'error', summary: 'EOD Check Sheet', detail: this.translate.instant('eod_could_not_download') });
      }

    })
  }
}
