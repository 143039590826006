<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->

<div style="border: 0;" *ngIf="moduleName =='customizationISDistribution' || moduleName =='isdConsolidateReport' || moduleName == 'isdAdhocDistribution' || componentName =='isdAdhocConfiguration' || moduleName =='reportDeliveryWorkflowV2' || moduleName=='isdClientApprovalGroupFund'">
  <button *ngIf="data.validated===true && !data.id"
    style="background: green;color:white;right: 0%!important; bottom: 5px;" color="primary" mat-raised-button
    (click)="previewTemplateReport()">
    Preview
  </button>
  <button *ngIf="(data.validated === false ||data.validated == null) && data.id == null"
    style="background: red;color:white;right: 0%!important; bottom: 5px;" color="primary" mat-raised-button
    (click)="previewTemplateReport()">
    Preview
  </button>
  <button
    style="background-color: transparent !important; text-align:center; position: relative; transform: translateY(15%); align-items: center; text-align: center; align-content: center;  border-style: none;"
    (click)="pdfDownload()" *ngIf="!data.id">
    <span>
      <img src="assets/img/download-pdf.svg" width="25px" style="padding-top: 4px !important;" height="auto" alt="">
    </span>
  </button>
</div>


<div style="border: 0;" *ngIf="moduleName =='genericEmailLogs'">  
  <span class="material-icons-outlined agvisibility"  (click)="previewTemplateReport()"  matTooltip="{{'view'|translate}}">visibility</span>
    <span class="material-icons-outlined agvisibility">
      <img src="assets/img/download-pdf.svg" width="25px" style="padding-top: 4px !important;" height="auto" alt=""   (click)="emailLogDownload()"  matTooltip="{{'pdf_download'|translate}}">
    </span>

</div>
