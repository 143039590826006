/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FuryCardModule } from '../../../@fury/shared/card/card.module';
import { ClickOutsideModule } from '../../../@fury/shared/click-outside/click-outside.module';
import { MaterialModule } from '../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../@fury/shared/scrollbar/scrollbar.module';
import { ToolbarFullscreenToggleComponent } from './toolbar-fullscreen-toggle/toolbar-fullscreen-toggle.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { ToolbarViewComponent } from './toolbar-view/toolbar-view.component';
import { ToolbarCreateComponent } from './toolbar-create/toolbar-create.component';
import { ToolbarUploadComponent } from './toolbar-upload/toolbar-upload.component';
import { ToolbarTrainingVideosComponent } from './toolbar-training-videos/toolbar-training-videos.component';
import { ToolbarQuickpanelToggleComponent } from './toolbar-quickpanel-toggle/toolbar-quickpanel-toggle.component';
import { ToolbarSearchBarComponent } from './toolbar-search-bar/toolbar-search-bar.component';
import { ToolbarSearchComponent } from './toolbar-search/toolbar-search.component';
import { ToolbarSidenavMobileToggleComponent } from './toolbar-sidenav-mobile-toggle/toolbar-sidenav-mobile-toggle.component';
import { ToolbarUserComponent } from './toolbar-user/toolbar-user.component';
import { ToolbarComponent } from './toolbar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ConfigPanelModule } from '../config-panel/config-panel.module';
import { FurySharedModule } from '../../../../src/@fury/fury-shared.module';
import { BackdropModule } from '../../../../src/@fury/shared/backdrop/backdrop.module';
import { PrimeNgModule } from '../../../../src/app/shared/primeNg.module';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
      {prefix: "./assets/i18n/", suffix: ".json"},
      {prefix: "buttonName/getButtonNameJSON/", suffix: " "},
  ]);
}
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    

    RouterModule,
    ScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    FuryCardModule,
    FurySharedModule,
    ConfigPanelModule,
    PrimeNgModule,
    BackdropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    ToolbarComponent,
    ToolbarUserComponent,
    ToolbarNotificationsComponent,
    ToolbarViewComponent,
    ToolbarCreateComponent,
    ToolbarUploadComponent,
    ToolbarTrainingVideosComponent,
    ToolbarSearchComponent,
    ToolbarSearchBarComponent,
    ToolbarQuickpanelToggleComponent,
    ToolbarFullscreenToggleComponent,
    ToolbarSidenavMobileToggleComponent,
  ],
  exports: [ToolbarComponent]
})
export class ToolbarModule {
}
