<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<button *ngIf="!isCheckBox && moduleName!='executeWorkflow'" [disabled]="displayBasic" 
mat-icon-button (click)="uploadErrorStatus($event,'view')" [matTooltip]="tooltipTxt" [ngStyle]="(displayBasic || failureCount === 0) && { cursor: 'not-allowed'}"
style=" background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
align-content: center; border-style: none;">  
 <span><img src="assets/img/download-xlsx.png" width="25px" height="auto" alt=""></span>
</button>

<mat-checkbox *ngIf="isCheckBox" [checked]="isExecuted" (change)="setExecute($event)" 
[ngClass]="{'disable': !isExecuted,'disable1': displayBasic2 }" 
 style="align-items: center; text-align: center;
align-content: center; border-style: none;" 
   matTooltip="{{toolTipValue}}" 
></mat-checkbox>



<button *ngIf="moduleName=='executeWorkflow' && !isCheckBox" (click)="uploadErrorStatus($event,'view')"
[disabled]="!(alteryxWorkflowStatus) || alteryxWorkflowStatus.failureCount === 0"
[ngStyle]="(!alteryxWorkflowStatus || alteryxWorkflowStatus.failureCount === 0) && {'color':'gray', cursor: 'not-allowed'} || {'color': 'blue'}" style=" background-color: transparent
	  !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
 	  align-content: center; color: blue !important; border-style: none; ">
 	 <!-- <mat-icon>cloud_download</mat-icon> -->
	  <span><img src="assets/img/download-xlsx.png" width="25px" height="auto" alt=""></span>
</button>
