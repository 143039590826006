/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalCookieService } from 'src/app/shared/services/commonServices/localCookieService'
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from "rxjs";
import { delay } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  subjectAMService: Subject<any> = new Subject<any>();
  getAMData$ = this.subjectAMService.asObservable();
  public fss: any = {};
  private userLoggedIn = new Subject<boolean>();
  private tokenRefresh = new Subject<boolean>();
  public userLogInfo
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  isLogin: boolean;
  authenticated: boolean = true;
  private currentUserDetail: string;

  constructor(private LocalStoreService: LocalStoreService, private router: Router, private http: HttpClient, private localCookieService: LocalCookieService, private cookieService: CookieService) {
    this.checkAuth();
    this.userLoggedIn.next(false);
    this.tokenRefresh.next(false);
  }

  setStatus(status: any) {
    this.subjectAMService.next(status);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  setTokenRefresh(tokenRefresh: boolean) {
    this.tokenRefresh.next(tokenRefresh);
  }

  getTokenRefresh(): Observable<boolean> {
    return this.tokenRefresh.asObservable();
  }

  // current user store userid
  setUserInfo(userLogInfo: any) {
    this.userLogInfo = userLogInfo;
  }

  getUserInfo() {
    return this.userLogInfo;
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  /** 
   * @param form: consists of username and password 
   * @param cbs : callback success
   * @param cbe : callback error
   */
  loginSSO(form, cbs, cbe, isResendOtp?) {
    if (form && isResendOtp) {
      this.currentUserDetail = 'j_username=' + form.username.trim() + '&j_password=' + form.password
    } else {
      this.currentUserDetail = 'j_username=' + form.controls.username.value.trim() + '&j_password=' + form.controls.password.value
    }
    this.http.post('loginCall', btoa(this.currentUserDetail), this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  login(form, cbs, cbe) {
    this.http.post('loginCall', btoa(JSON.stringify(form)), this.httpOptions)
      //this.http.post('loginCall', btoa('j_username=' + form.controls.username.value + '&j_password=' + form.controls.password.value), this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  /** 
   * @param form: consists of username, password and OTP
   * @param cbs : callback success
   * @param cbe : callback error
   */
  loginAuth(form, cbs, cbe) {
    this.http.post('loginCall', btoa('j_username=' + form.username + '&j_password=' + form.password + '&j_otp=' + form.OTPToken), this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    return this.http.post('logOut', '', this.httpOptions);
  }

  authResolve() {
    return this.http.post<any>('isLoggedIn', this.httpOptions).pipe();
  }

  isloggedIn(cbs, cbe) {
    return this.http.get('isLoggedIn')
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  isLoggedIn1() {
    let loggedIn
    let val = JSON.parse(this.LocalStoreService.getItem('fss'));
    if (val) {
      loggedIn = JSON.parse(this.LocalStoreService.getItem('fss'))['STATE']
    }

    if (loggedIn == 'true')
      this.isLogin = true;
    else
      this.isLogin = false;
    return this.isLogin;
  }

  getNewAccessToken(cbs, cbe) {
    return this.http.get('getNewAccessToken?refresh_token=' + this.cookieService.get('refresh_token'))
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  signout(isRedirect?) {
    var current_time = new Date().getTime();
    var access_token = this.cookieService.get('access_token');
    var refresh_token = this.cookieService.get('refresh_token');
    console.log("token", access_token, refresh_token, current_time);

    this.authenticated = false;
    const url = this.router.url;
    var matches = url.match(/generatePassword|login|forgot-password/g);
    if ((!matches || !matches.length || isRedirect)) {
      this.localCookieService.clearSSOCookies();
      sessionStorage.clear();
      localStorage.clear();
      this.router.navigate(["/login"], { state: { reset: true } });
    }
  }

  logoutbyapi(cbs, cbe) {
    this.http.post('logOut', this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  forgotPassword(data, cbs, cbe) {
    this.http.post('forgotPassword', data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  generatePassword(data, cbs, cbe) {
    this.http.post('generatePassword', data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  changePassword(data, cbs, cbe) {
    this.http.post('changeTempPassword', data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getCurrentUser(cookieObject, cbs, cbe) {
    this.http.post('getCurrentUser', { cookieData: btoa(JSON.stringify(cookieObject)) }, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getDynamicLinks(): Observable<any> {
    return this.http.post<any>('getGeneralConfiguration', this.httpOptions);
  }

  setGuidCookies(guiId?) {
    this.fss = JSON.parse(this.LocalStoreService.getItem('fss'));
    let sessionUser = this.fss?.userName ? btoa(this.fss?.userName) : '';
    let cookiesName = 'JSessionData_' + sessionUser;
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + (1000 * 24 * 3600 * 90);
    now.setTime(expireTime);
    const [subdomain, domain] = this.localCookieService.getDomainAndSubdomain();
    let url = domain || '';
    this.cookieService.set(cookiesName, btoa(guiId), now, '/', url, true);
    this.cookieService.set('rememberme', "true", now, '/', url, true);
  }

  clearCookiesUserName() {
    this.fss = JSON.parse(this.LocalStoreService.getItem('fss'));
    let sessionUser = this.fss?.userName ? btoa(this.fss?.userName) : '';
    let cookiesName = 'JSessionData_' + sessionUser;
    const [subdomain, domain] = this.localCookieService.getDomainAndSubdomain();
    let url = domain || '';
    this.cookieService.delete(cookiesName, '/', url, true, "None");
  }

  loginForValidation(data, cbs, cbe) {
    this.http.post('loginCall', btoa(JSON.stringify(data)), this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }
}
