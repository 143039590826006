/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { environment } from '../../../../../src/environments/environment';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/commonServices/common.service';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;
@Component({
  selector: 'fury-grdbtn-row-down',
  templateUrl: './grdbtn-row-prv-down.component.html',
  styleUrls: ['./grdbtn-row-prv-down.component.scss']
})
export class GrdbtnPrvRowDownComponent {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  private rowData: any;
  moduleName: any;
  alteryxWorkflowStatus: any;
  attachment: any;
  public executionStatus:any;

  constructor(
    public translate: TranslateService,
    private processScreenService: ProcessScreenService,
    private messageService: GlobalMessageService, private http: HttpClient, private commonService : CommonService
  ) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.rowData = this.params.data;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
    if (this.moduleName == "executeWorkflow" && this.rowData && this.rowData.alteryxWorkflowStatus) {
      this.alteryxWorkflowStatus = this.rowData.alteryxWorkflowStatus;
    } else if (this.moduleName == "workflowStatus" && this.rowData) {
      this.alteryxWorkflowStatus = this.rowData;
    }
    this.executionStatus = this.alteryxWorkflowStatus?.executionStatus.toLowerCase();
    this.attachment=this.params.data.attachment;
  }

  getPreviewData() {
    var uniqueId;
    let type:string = 'Preview';
    if (this.params.data && this.alteryxWorkflowStatus && this.alteryxWorkflowStatus.uniqueNumber) {
      uniqueId = this.alteryxWorkflowStatus.uniqueNumber;
    } else {
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('invalid_data'), detail: this.translate.instant('error') });
      return;
    }
    let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId +'/' +type;
    this.commonService.GenericDownload(url, 'Preview Data','Preview Data');
  }

  downloadRowDoc(event) {
    
    if (this.params.data) {
      if (this.params.data.id) {
        var usrId = this.params.data.id;
        if (this.params.data.attachment) {
          var flUri = this.params.data.attachment;
          var filePath = flUri.split('/');
          var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];
         
        }
      }
    }
    this.downloadFileFromAWS(flUri).subscribe(res => {
      var a = document.createElement('a');
      var blob = new Blob([res], { type: res.ContentType });
      a.href = URL.createObjectURL(blob);
      a.download = filePath[5];
      a.click();
       
      this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('success'),detail: this.translate.instant('file_download_successful') });
      
    })

  }

  downloadFileFromAWS(fileUrl): Observable<any> {
    var filePath = fileUrl.split('/');
    var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];
    return this.http.post<any>('documentManagementV2/download', fileName, { responseType: 'blob' as 'json' });
  }
}
