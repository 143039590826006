
/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2021 - 2022 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fadeInRightAnimation } from 'src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from 'src/@fury/animations/fade-in-up.animation';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { AuthService } from 'src/app/shared/services/commonServices/auth.service';
import { SidenavService } from 'src/app/layout/sidenav/sidenav.service';
import { GoogleAuthenticatorService } from './google-authenticator.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
import { CommonService } from 'src/app/shared/services/commonServices/common.service';
import { OptPasswordService } from '../../otp-password/otp-password.service';
import { LocalCookieService } from 'src/app/shared/services/commonServices/localCookieService';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';

@Component({
  selector: 'fury-google-authenticator',
  templateUrl: './google-authenticator.component.html',
  styleUrls: ['./google-authenticator.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class GoogleAuthenticatorComponent implements OnInit {
  public fss: any = {};

  form: FormGroup;
  inputType = 'password';
  visible = false;
  userEmail: string;
  userName: string;
  imgUrl: any;
  currentYear: number;
  isSubmitDisabled: boolean = false;
  displayQRCode: boolean = false;
  showMfaEnable: any;
  showIconsTick: boolean = false;
  showIconsCross: boolean = false

  // Define ---------------------------------------- 
  loading: boolean = true;
  loadingText: string;
  userDetails: Object;
  smsForm = new FormGroup({
    password: new FormControl(''),
  });
  public lang: any;
  public logo;
  showSvg = true;
  currentUser: any;
  bodyEmailAddress: any;
  bodyTelephoneNo: any;
  copyrightText: any;
  linkCyberSecurity: any;
  linkPoweredBy: any;
  linkPrivacyPolicy: any;
  textCyberSecurity: any;
  textPoweredBy: any;
  textPrivacyPolicy: any;
  showFooter: boolean = false;
  responseData: any = {};
  siteKey: any;
  subdomainAgreement: string;
  imgSrc: any;
  urlSafe: any;
  displayPasswordForm: boolean = false;
  wrongAuthentication: boolean = false;
  wrongPassword: boolean = false;
  userData: any;
  isUserLoggedIn: boolean;
  hubspot: boolean = false;
  submitBtnCall: boolean = false
  tempOtp: any;
  apiCallMutex = false

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    inputStyles: {
      'width': '35px',
      'height': '35px',
      'font-size': '1.25rem'
    }
  };
  public domainName: string = 'localhost';
  public logo_domainName: string = 'localhost';
  public oneseamless: any;
  public cryptobackoffice: any;
  // End ------------------------------------

  constructor(
    private router: Router, private fb: FormBuilder,
    private http: HttpClient, private commonService: CommonService,
    private cd: ChangeDetectorRef, private localStoreService: LocalStoreService,
    public translate: TranslateService, private changeDetectorRef: ChangeDetectorRef,
    private auth: AuthService, public _d: DomSanitizer, private sidenavService: SidenavService,
    private googleAuthenticatorService: GoogleAuthenticatorService, private messageService: GlobalMessageService,
    public optPasswordService: OptPasswordService, private localCookieService: LocalCookieService,
  ) {
    var hostname = window.location.hostname;
    var subdomain = hostname.split('.')[0];
    this.oneseamless = hostname.split('.')[1];
    this.cryptobackoffice = hostname.split('.')[1];
    if (subdomain == 'localhost' || subdomain == 'betaseamless' || subdomain == 'devmig' || subdomain == 'uatmig') {
      this.domainName = 'localhostmigration';
      this.logo_domainName = 'sudrania';
    } else {
      if (window.location.hostname.includes('.')) {
        this.logo_domainName = window.location.hostname.includes('sudrania') ? 'sudrania' : 'formidium';
      }
      this.domainName = subdomain;
    }
    translate.addLangs(['English', 'French']);
    translate.setDefaultLang('English');
  }

  ngOnInit() {
    this.isUserLoggedIn = this.auth.isLoggedIn1();
    if (this.isUserLoggedIn) {
      this.auth.getDynamicLinks().subscribe((res) => {
        this.responseData = res.data || {};
        this.getDynamicLinks();
      });
    } else {
      this.responseData = this.commonService.getFooterData() || {};
      if (this.responseData) {
        this.getDynamicLinks();
      } else {
        this.router.navigate(['/login']);
      }
    }
    this.userData = this.commonService.getUserInfo();
    if (this.isUserLoggedIn || this.userData) {
      // this.sidenavService.setVisible(false);
      this.form = this.fb.group({
        password: ['', Validators.required]
      });
      this.displayPasswordForm = true;
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('mfa');
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  dismiss() {
    this.wrongAuthentication = false;
  }

  dismissPopup() {
    this.wrongPassword = false;
  }

  submit(type) {
    if (this.isUserLoggedIn) {
      this.validatePassword();
    } else {
      this.getGoogleAuthenticatorDetails();
    }
  }

  validatePassword() {
    let data = {
      mfaType: 'GoogleAuthenticator',
      password: this.smsForm.controls['password'].value
    }
    let req = btoa(JSON.stringify(data))
    this.http.post('userV2/validatePassword', req).subscribe((response: any) => {
      if (response && response.status == 200) {
        this.displayPasswordForm = false;
        this.displayQRCode = true;
        this.http.post<any>("userV2/generateQR", req).subscribe(res => {
          if (res) {
            this.urlSafe = res.data;
          }
        })
      } else {
        this.wrongPassword = true;
      }
    })
  }

  getGoogleAuthenticatorDetails() {
    let data = {
      j_username: this.userData['username'],
      j_password: this.userData['password'],
      mfaType: 'GoogleAuthenticator',
    }
    if (this.userData['password'] == this.smsForm.controls['password'].value) {
      this.displayPasswordForm = false;
      this.displayQRCode = true;
      this.auth.loginForValidation(data, (cbs) => {
        if (cbs && cbs['data']) {
          this.urlSafe = cbs['data'];
        }
      }, (cbe) => {
        console.log(cbe);
      });
    } else {
      this.wrongPassword = true;
    }
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }

  switchLang(lang: string) {
    this.lang = lang;
    localStorage.setItem('switchLanguage', JSON.stringify(this.lang));
    this.translate.use(lang);
  }

  loginOnEnter(event) {
    if (event.keyCode == 13 && this.smsForm.valid) {
      this.submit(event);
    }
  }

  handeOtpChange(value: string[]): void { }

  handleFillEvent(value: string): void { }

  onOtpChange(otp) {
    const regex = new RegExp(`\\d{${this.config.length}}`);
    if (!otp || !regex.test(otp)) {
      this.tempOtp = "";
      return;
    }
    this.tempOtp = otp;
  }

  submitBtn() {
    this.submitBtnCall = false;
    if (!this.submitBtnCall && this.isUserLoggedIn && this.tempOtp.length === 6) {
      this.validateMfaCode(this.tempOtp);
    } else if (!this.submitBtnCall && this.tempOtp.length === 6 && !this.apiCallMutex) {
      this.loginForValidation(this.tempOtp);
    }
  }

  submitOnEnter(event) {
    if (event.keyCode == 13 && !this.apiCallMutex) {
      this.submitBtn();
    }
  }

  validateMfaCode(otp) {
    this.apiCallMutex = true;
    let data = { mfaType: "GoogleAuthenticator", mfaCode: otp }
    let req = btoa(JSON.stringify(data))
    this.http.post("userV2/validateMfaCode", req).subscribe((response: any) => {
      if (response.status == 200) {
        this.showIconsTick = true;
        if (response.data.mfaEnable) {
          this.localStoreService.setItem('mfaEnable', response.data.mfaEnable);
          this.googleAuthenticatorService.setMfaValue(true);
        }
        setTimeout(() => {
          this.fss = JSON.parse(this.localStoreService.getItem('fss'));
          this.optPasswordService.setTabsFunction(this.fss);
        }, 2000)
      } else {
        this.showIconsCross = true;
        this.wrongAuthentication = true;
        this.apiCallMutex = false;
      }
    }, (cbe) => {
      this.apiCallMutex = false;
      console.log(cbe);
    })
  }

  loginForValidation(otp) {
    this.apiCallMutex = true;
    let data = {
      j_username: this.userData['username'],
      j_password: this.userData['password'],
      mfaType: "GoogleAuthenticator",
      mfaCode: otp,
    }
    this.auth.loginForValidation(data, (cbs) => {
      if (cbs && cbs.access_token) {
        this.localCookieService.setCookies(cbs);
        this.showIconsTick = true;
        this.getCurrentUser();
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: (cbs.errorMsg || cbs.message) });
        this.apiCallMutex = false;
        this.submitBtnCall = true;
        this.showIconsCross = true;
        this.wrongAuthentication = true;
      }
    }, (cbe) => {
      this.apiCallMutex = false;
      console.log(cbe);
    })
  }

  getCurrentUser() {
    let cookieObject = { [this.userData['username']]: 'a1bc5559-dfa3-4046-8f7e-31e2997ce6a8' }
    this.auth.getCurrentUser(cookieObject, (cbs) => {
      if (cbs && cbs.id) {
        this.localStoreService.setItem('photoUrl', cbs['photoUrl']);
        this.localStoreService.setItem('mfaEnable', cbs['mfaEnable'].toString());
        this.localStoreService.setItem('userDetails', JSON.stringify(cbs));
        this.fss = cbs;
        this.hubspot = this.fss.authList.indexOf('SEAMLESS_CONTACT_SUPPORT') > -1 ? true : false;
        this.localStoreService.setItem('tfss', JSON.stringify(this.fss));
        this.localStoreService.setItem('fss', JSON.stringify(this.fss));
        this.commonService.setIsLogin(true);
        //set domain list
        let domains = this.fss.domains || [];
        this.localStoreService.setItem('sessionList', JSON.stringify(domains));
        if (this.translate.currentLang) {
          this.translate.reloadLang(this.translate.currentLang).subscribe(res => {
            //set session with updated file
            this.translate.use(this.translate.currentLang);
            this.translate.setDefaultLang(this.translate.currentLang);
            this.optPasswordService.setTabsFunction(this.fss);
          }, (error) => {
            //set session with default file
            this.translate.use('English');
            this.translate.setDefaultLang('English');
            setTimeout(() => {
              this.optPasswordService.setTabsFunction(this.fss);
            }, 2000);
          });
        } else {
          this.translate.use('English');
          this.translate.setDefaultLang('English');
          this.optPasswordService.setTabsFunction(this.fss);
        }
      }
    }, (cbe) => {
      this.loading = false;
      this.apiCallMutex = false;
    })
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('mfa');
  }

  handlePaste(e: ClipboardEvent) {
    const pasteData = e.clipboardData?.getData('text');
    const regex = new RegExp(`\\d{${this.config.length}}`);
    if (!pasteData || !regex.test(pasteData)) {
      return;
    }
    if (pasteData) {
      this.setVal(pasteData)
    }
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  hasNull(element) {
    if (element) {
      return Object.keys(element).some((key) => {
        return element[key] === null || element[key] === '';
      });
    }
  }

  redirectToSetup() {
    if (this.isUserLoggedIn) {
      this.router.navigate(['/chooseSetupMfaType']);
    } else {
      this.router.navigate(['/chooseMfaType']);
    }
  }

  getDynamicLinks() {
    this.responseData = this.responseData || {};
    this.copyrightText = this.responseData.copyrightText;
    this.linkPoweredBy = this.responseData.linkPoweredBy;
    this.linkPrivacyPolicy = this.responseData.linkPrivacyPolicy;
    this.textPrivacyPolicy = this.responseData.textPrivacyPolicy;
    this.bodyTelephoneNo = this.responseData.bodyTelephoneNo;
    this.bodyEmailAddress = this.responseData.bodyEmailAddress;
    this.textPoweredBy = this.responseData.textPoweredBy;
  }
}
