/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Theme, ThemeService } from '../../../@fury/services/theme.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SidenavService } from '../sidenav/sidenav.service';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/commonServices/common.service';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/shared/services/commonServices/data.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';

@Component({
  selector: 'fury-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent implements OnInit {
  public theme_style : any ;
  public tollbar_position : any;
  public navigation_bar : any = {};
  public ext_options :  any = {};
  public themedata: any ={};

  activeTheme$ = this.themeService.activeTheme$;
  navigation$ = this.themeService.config$.pipe(map(config => config.navigation));
  sidenavOpen$ = this.sidenavService.open$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));
  footerVisible$ = this.themeService.config$.pipe(map(config => config.footerVisible));
  footerPosition$ = this.themeService.config$.pipe(map(config => config.footerPosition));
  public username: string;

  constructor(private themeService: ThemeService,
    private dataService: DataService,
    private sidenavService: SidenavService,
    private commonService: CommonService,
    private http: HttpClient,
    private localService: LocalStoreService) { }

  ngOnInit() {
    this.theme_style =  this.activeTheme$['source']['source']['_value'][0];
    this.navigation_bar['positon'] = this.navigation$['source']['source']['_value']['navigation'];
    this.navigation_bar['show'] = this.sidenavOpen$['source']['_value'];
    this.tollbar_position = this.toolbarPosition$['source']['source']['_value']['toolbarPosition'];
    this.ext_options['collapsed'] = this.sidenavCollapsed$['source']['_value'];
    this.ext_options['show_usr_pan'] = this.sidenavUserVisible$['source']['source']['_value']['sidenavUserVisible'];
    this.username = JSON.parse(this.localService.getItem('fss'))['userName'];

    this.commonService.userPerferenceData(this.username).subscribe((res1: any) => {
      this.dataService.setUserPerferenceData(res1);

    if(res1){
     this.themedata = res1.ThemeSettingsUsr;   
        if(this.themedata && this.themedata.length && this.themedata[0]){
          if(this.themedata[0].setting != (undefined || null)){
             
             /*
            Set ToolBar Bar
            parm : Fixed..
            */
            if(this.themedata[0].setting.tollbar_position  != (undefined || null)){ 
              this.themeService.setToolbarPosition(this.themedata[0].setting.tollbar_position);           
            }
            /*
            Set Navigation Bar
            parm : Side,Top
            */
            if(this.themedata[0].setting.navigation_bar  != (undefined || null)){
              if(this.themedata[0].setting.navigation_bar.show != (undefined || null)){
                this.themedata[0].setting.navigation_bar.show ? this.sidenavService.open() : this.sidenavService.close();
              }
              if(this.themedata[0].setting.navigation_bar.positon  != (undefined || null)){
                this.themeService.setNavigation(this.themedata[0].setting.navigation_bar.positon);
              }
            }
             /*
            Set Ext Options
            parm : collapsed
            */
            if(this.themedata[0].setting.ext_options != (undefined || null)){
              if(this.themedata[0].setting.ext_options.show_usr_pan != (undefined || null)){
                this.themeService.setSidenavUserVisible(this.themedata[0].setting.ext_options.show_usr_pan);
              }
              if(this.themedata[0].setting.ext_options.collapsed != (undefined || null)){
                this.sidenavService.setCollapsed(this.themedata[0].setting.ext_options.collapsed);
              }
            }
          }
        }
      }
    })
  }

  setActiveTheme(theme: Theme) {
    this.themeService.setTheme(theme);
    this.theme_style = theme;
    this.setThemePref();
  }

  navigationChange(change: MatRadioChange) {
    this.themeService.setNavigation(change.value);
    this.navigation_bar['positon'] = change.value;
    this.setThemePref();
  }

  sidenavOpenChange(change: MatSlideToggleChange) {
    change.checked ? this.sidenavService.open() : this.sidenavService.close();
    this.navigation_bar['show'] = change.checked;
    this.setThemePref();
  }

  sidenavCollapsedChange(change: MatCheckboxChange) {
    this.sidenavService.setCollapsed(change.checked);
    this.ext_options['collapsed'] = change.checked;
    this.setThemePref();
  }

  sidenavUserChange(change: MatCheckboxChange) {
    this.themeService.setSidenavUserVisible(change.checked);
    this.ext_options['show_usr_pan'] = change.checked;
    this.setThemePref();
  }

  toolbarVisibleChange(change: MatSlideToggleChange) {
    this.themeService.setToolbarVisible(change.checked);
  }

  toolbarPositionChange(change: MatRadioChange) {
    this.themeService.setToolbarPosition(change.value);
    this.tollbar_position =  change.value;  
    this.setThemePref(); 
  }

  footerVisibleChange(change: MatSlideToggleChange) {
    this.themeService.setFooterVisible(change.checked);
  }

  footerPositionChange(change: MatRadioChange) {
    this.themeService.setFooterPosition(change.value);
  }
  setThemePref() {
    var reqBody = {};
    if(this.themedata && this.themedata.length){
     reqBody = {
      "id": this.themedata[0].id,
      "userName": this.username,
      "view": 'ThemeSettingsUsr',
      "settingName": this.theme_style,
      "setting": {
          "theme_style": this.theme_style,
          "tollbar_position": this.tollbar_position,
          "navigation_bar": this.navigation_bar,
          "ext_options": this.ext_options
      },
      "status":"true",
    }
    this.http.put('userPerferenceV2/update', reqBody).subscribe(res2 => {
    })    
  }else{
      reqBody = {
      "userName": this.username,
      "view": 'ThemeSettingsUsr',
      "settingName": this.theme_style,
      "setting": {
        "theme_style": this.theme_style,
        "tollbar_position": this.tollbar_position,
        "navigation_bar": this.navigation_bar,
        "ext_options": this.ext_options     
      },
      "status":"true",
    }
    this.http.post('userPerferenceV2/create', reqBody).subscribe(items => {
    })
   }  
 }
}
