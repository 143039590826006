/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2017 - 2018 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import { Component } from "@angular/core";
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from "ag-grid-community";
import moment from 'moment';
import { FormControl, Validators } from '@angular/forms';

import { GridService } from "../../services/gridServices/grid.service";

@Component({
    selector: "edit-cell-password-editor",
    templateUrl: './edit-cell-password-editor.component.html',
    styleUrls: ['./edit-cell-password-editor.component.scss']
})
export class EditCellPasswordEditor implements ICellEditorAngularComp {
    private params!: ICellEditorParams;
    public selectedData = null;
    // public required: boolean = false;
    public isDisabled: boolean = false;
    public formData: any;
    public columnDef: any;
    public editableRowData: any;
    componentName: any;
    inputType = 'password';
    visible = false;
    cellEditorParams: any;
    required: boolean = true;
  
    inputControl: FormControl;
    constructor(private gridService: GridService) { }

    ngOnInit() {
        this.inputControl = new FormControl(this.selectedData, [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(9)
        ]);
      }
    agInit(params: ICellEditorParams): void {
        this.params = params;
        let colDef = this.params.colDef;
        this.cellEditorParams = this.params.colDef.cellEditorParams;
        this.columnDef = this.params.colDef;
        this.required = colDef["required"];
        this.editableRowData = this.params.data;
        let value = params.value;
        this.selectedData = value;
        this.componentName = this.params.api['componentName'];
        let field = this.cellEditorParams.refData?.displayField;
        this.selectedData = this.params.data[field];


      
    }

    afterGuiAttached = () => {
        if (!this.params.value) {
            return;
        }
        let field = this.cellEditorParams.refData?.displayField;
        this.selectedData = this.params.data[field];
    };


    getValue = () => {
        let dataString = null;
        if (this.params.node.data.isEditingSave && this.selectedData) {
            dataString = this.selectedData;
        }
        else {
            let field = this.cellEditorParams.refData?.displayField;
            this.selectedData = this.params.data[field];
        }
        return this.inputControl.value;
    };
    refresh(params?: ICellEditorParams): boolean {
        this.params = params;
        let field = this.cellEditorParams.refData?.displayField;
        this.selectedData = this.params.data[field];
        // this.selectedData = params.value;
        return true;
    }
    setValue(label, colId): any {
        this.params.node.setDataValue(colId, label);
    }

   
    toggleVisibility(): void {
        this.visible = !this.visible;
        this.inputType = this.visible ? 'text' : 'password';
    }

    filterNonNumeric(event: Event) {
        const input = event.target as HTMLInputElement;
        input.value = input.value.replace(/[^0-9]/g, '');
        this.inputControl.setValue(input.value);
      }
}

