<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->


<!-- <div *ngIf="moduleName!='workflowStatus'">
    <button
        *ngIf="((alteryxWorkflowStatus) && (alteryxWorkflowStatus.status == 'running' || alteryxWorkflowStatus.status == 'queued'))"
        [ngStyle]="((alteryxWorkflowStatus) &&(alteryxWorkflowStatus.status == 'running' || entity.alteryxWorkflowStatus.status == 'queued')) 
        && {'color':'gray', cursor: 'not-allowed'} || {'color': 'blue'}" class="refreshSpin">
        <mat-progress-spinner mode="indeterminate" diameter='32' strokeWidth='5'></mat-progress-spinner>
    </button>
</div> -->

<button
    *ngIf="moduleName == 'dataImportStatusManager' || moduleName == 'dataImportStatus'  || (moduleName != 'reportNotificationStatus' && moduleName != 'executeWorkflow' && moduleName != 'workflowStatus' && moduleName !='customizationISDistribution' && moduleName !='enableInvestorReDistribution' && moduleName !='isdConsolidateReport' && moduleName !='isdClientApprovalGroupFund'  && moduleName !='isdNotificationStatusGroup' && moduleName !='isdAdhocDistribution' && moduleName !='isdAdhocReDistribution' ) "
    (click)="updateWorkflowStatusUser(executionId, 'view')" [disabled]="wrkFlwSts == 'completed' ? true : false "
    [ngStyle]="(!wrkFlwSts  || wrkFlwSts == 'completed') && {'color':'gray','cursor': 'not-allowed'}" style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: blue; border-style: none; ">
    <!-- <mat-progress-spinner mode="indeterminate" diameter='32' strokeWidth='15'></mat-progress-spinner> -->
   Refresh
</button>

<button
    *ngIf="moduleName == 'workflowStatus' && !isProcessing && (executionStatus == 'Processing' || executionStatus == 'Queued' || executionStatus == 'processing' || executionStatus == 'queued')"
    (click)="updateWorkflowStatusUser(executionId, 'view')"
    style=" background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; border-style: none; width: 100%; padding-top: 14px;color: blue;">
    <mat-icon>refresh</mat-icon>
</button>

<button
    *ngIf="moduleName == 'workflowStatus' && isProcessing && (executionStatus == 'Processing' || executionStatus == 'Queued')"
    style=" background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: blue !important; border-style: none; width: 100%; padding-top: 14px;">
    <mat-progress-spinner mode="indeterminate" diameter='28' strokeWidth='5'></mat-progress-spinner>
</button>

<button
    *ngIf="moduleName == 'executeWorkflow' && (wrkFlwSts == 'completed' || !wrkFlwSts)" [disabled]="wrkFlwSts == 'completed' ? true : false "
    [ngStyle]="(!wrkFlwSts  || wrkFlwSts == 'completed') && {'color':'gray','cursor': 'not-allowed'} || {'color': 'gray'}"
    style=" background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%); 
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center; align-content: center; color: gray !important; border-style: none; width: 100%;">
   Refresh
</button>

<button
    *ngIf="moduleName == 'executeWorkflow' && (wrkFlwSts == 'running' || wrkFlwSts == 'queued')"
    [ngStyle]="(wrkFlwSts == 'running' || wrkFlwSts == 'queued') && {'color':'gray', cursor: 'not-allowed'} || {'color': 'blue'}"
    style=" background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: blue !important; border-style: none; width: 100%; padding-top: 14px;">
    <!-- <i class="fa fa-spinner fa-spin text-success" style="font-size:28px"></i> -->
    <mat-progress-spinner mode="indeterminate" diameter='28' strokeWidth='5'></mat-progress-spinner>
</button>

<span class="material-icons-outlined agvisibility" *ngIf="moduleName =='reportNotificationStatus'"
    (click)="viewrowDataCompliance($event)">visibility</span>

    <span class="material-icons-outlined agvisibility" *ngIf="moduleName =='customizationISDistribution' && isdViewBtnCheck"
    (click)="getIsdReportResponseDTO($event)">visibility</span>

    <span class="material-icons-outlined agvisibility" *ngIf="moduleName =='isdConsolidateReport' && isdGroupViewBtnCheck"
    (click)="getIsdConsolidateGridReportData($event,'groupApprovedList')">visibility</span> 
    <span class="material-icons-outlined agvisibility" *ngIf="moduleName =='isdConsolidateReport' || moduleName == 'isdClientApprovalGroupFund' || moduleName == 'isdNotificationStatusGroup'"
    (click)="getIsdConsolidateGridReportData($event,'inforeport')">info</span>


    <span class="material-icons-outlined agvisibility" *ngIf="moduleName =='enableInvestorReDistribution' && isdEnableViewBtnCheck"
    (click)="getIsdEnableInvestorReDistributionDTO($event)">visibility</span>
    <span class="material-icons-outlined agvisibility" *ngIf="moduleName =='isdAdhocDistribution'   && isAdhocDistributionViewBtnCheck"
    (click)="getIsdReportResponseDTO($event)">visibility</span>

    <span class="material-icons-outlined agvisibility" *ngIf="moduleName =='isdAdhocReDistribution'"
    (click)="getIsdReportResponseDTO($event)">visibility ffff</span>