/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
import { CommonService } from '../../../../src/app/shared/services/commonServices/common.service';

@Injectable({
  providedIn: 'root'
})
export class BatchTrackingEntryService {
  sftpExist: boolean;
  dbExist: boolean;
  reportingLevel: any;
  reportPackage: any;
  fundList: any;
  accountLists: any;
  groupList: any;
  reportinggroupList: any;
  programList: any;
  assetList: any;
  leadgerAccountList: any;
  sectorList: any;
  custodianList: any;
  amcList: any;







  chosenReload() {
    throw new Error('Method not implemented.');
  }


  public inputListCheckedList = [{ label: "DATABASE", value: "DATABASE" }, { label: "SFTP", value: "SFTP" }];
  public primaryAssetClassList = [];

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  allInputControl: any;
  public batchfundlist = [];
  public alteryxProcessList = [];
  public batchReportList = [];
  public fileNamePatternList = [];
  public fileNameFormatList = [];
  public frequency = [];
  public executionPeriod = [{ label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }, { label: "4", value: "4" }]
  public date: Date;
  currentUser = JSON.parse(this.localService.getItem('fss'));
  authList: any = this.currentUser['authList'];
  isEnterprise = ((this.authList.indexOf('USER_CREATE') > -1 && this.authList.indexOf('USER_DELETE') > -1) ? true : false)
  public Attributes = [{ label: "Shares Ending", value: "sharesEnding" }, { label: "NAV", value: "nav" }, { label: "QTD ROR", value: "QtdRor" }, { label: "YTD ROR", value: "YtdRor" }, { label: "MTD ROR", value: "MtdRor" }, { label: "MOIC", value: "moic" }, { label: "TVPI", value: "tvpi" }, { label: "DPI", value: "dpi" }, { label: "RVPI", value: "rvpi" }, { label: "(Capital Call received in advance)/Capital call receivable", value: "capitalCall" },
  { label: "Capital Call", value: "capitalCalls" },
  { label: "Capital Call - Expenses", value: "capitalCallExp" },
  { label: "Capital Call - Others", value: "capitalCallOthers" }];

  public reptLvlList = [{ label: "Group", value: "Group" }, { label: "Fund", value: "Fund" }];
  public accountList = [{ label: "Without Account", value: "generalLedgerWithoutCustodian" }, { label: "With Account", value: "generalLedgerWithCustodian" }];
  public fileFrtTypeList = [{ label: "PDF", value: "pdf" }, { label: "XLS", value: "excel" }, { label: "CSV", value: "csv" }]
  public reportingLevelList = [{ label: "Daily", value: "Daily" }, { label: "Monthly", value: "Monthly" }];
  public investorcapitalReportType = [{ label: "Daily", value: "Daily" }, { label: "Monthly", value: "Monthly" }, { label: "Quarterly", value: "Quaterly" }, { label: "Annually", value: "Annually" }, { label: "Inception", value: "Inception" }];
  public AllocationReporttype = [{ label: "Summary", value: "Summary" }, { label: "Detailed", value: "Detailed" }];
  public equityScheduleReportType = [{ label: "Daily", value: "Daily" }, { label: "Monthly", value: "Monthly" }, { label: "Quarterly", value: "Quarterly" }, { label: "Inception", value: "Inception" }, { label: "Yearly", value: "Yearly" }];
  public gainLossReportType = [{ label: "Summary Without Ticker", value: "SummaryWithoutTicker" }];
  public positionreportReportTyp = [{ label: "All", value: "all" }, { label: "Exception", value: "exception" }];
  public investorsumaryrepttype = [{ label: "Investor Commitments", value: "Investor Commitments" }, { label: "Investor Distributions", value: "Investor Distributions" }, { label: "Investor Calls", value: "Investor Calls" }, { label: "Investor Performance", value: "Investor Performance" }, { label: "UL Fund Commitments", value: "UL Fund Commitments" }, { label: "UL Fund Calls", value: "UL Fund Calls" },{ label: "UL Fund Distributions", value: "UL Fund Distributions" }, { label: "UL Fund Performance", value: "UL Fund Performance" }];
  public pdfFileList = [{ label: "PDF", value: "pdf" }];
  public xlsfileList = [ { label: "XLS", value: "excel" }];
  public csvfileList = [{ label: "CSV", value: "csv" }];
  public pdfCsvList = [{ label: "PDF", value: "pdf" }, { label: "CSV", value: "csv" }];
  public excelPdfList = [{ label: "PDF", value: "pdf" }, { label: "XLS", value: "excel" }];
  public excelTxtList = [{ label: "Text", value: "txt" }, { label: "XLS", value: "excel" }];
  public excelCsvList =  [{ label: "CSV", value: "csv" }, { label: "XLS", value: "excel" }];
  public   perTablereportType = [{ label: "Daily Performance", value: "Daily Performance" }, { label: "Monthly Performance", value: "Monthly Performance" }];
  public orianreportType = [{ label: "Name File", value: "Name File" }, { label: "Price File", value: "Price File" },{ label: "Security File", value: "Security File" }, { label: "Position File", value: "Position File" },{ label: "Transaction File", value: "Transaction File" }, { label: "Cost Basis File", value: "Cost Basis File" }];
  public txtFileList = [{ label: "txt", value: "txt" }];
  public csvtextFileList = [{ label: "CSV", value: "csv" },{ label: "Text", value: "txt" }];
  public fidelityTypeList =[{ label: "FTP Trade Template", value: "FTP Trade Template" },{ label: "FTP Recon Template", value: "FTP Recon Template" },{ label: "FTP DRP Template", value: "FTP DRP Template" }]
  public realizedReportType = [{ label: "Simple", value: "Simple" }, { label: "Detailed", value: "Detailed" }];
  public marginequityreportType =[{ "label": "Margin by Fund", "value": "ByFund"}, { "label": "Margin by  AMC","value": "ByAMC"}, { "label": "Margin by Account", "value": "ByAccount"}, {"label": "Margin by  Equity", "value": "ByEquity"}, {"label": "Margin Summary", "value": "BySummary"}];
  public investorList = [];
  public levelList = [{ label: "Fund", value: "Fund" }, { label: "Investor Group", value: "InvestorGroup" }, { label: "Investor", value: "Investor" }];
  public callAndDistReporttype = [{ label: "Call", value: "call" }, { label: "Dist", value: "dist" }];
  public AllocationReporttype1 = [{ label: "Summarized", value: "Summarized" }, { label: "Detailed", value: "Detailed" }];
  public reportType = [{ label: "Position Report", value: "Position Report" }, { label: "Investment Report", value: "Investment Report" }];
  public ytdPeriodList = [{ label: "6 Months Ended", value: "6-Months Ended" }, { label: "9 Months Ended", value: "9-Months Ended" }, { label: "12 Months Ended", value: "12-Months Ended" }];
  public rorList =  [ {label: 'MTD ROR', value: 'MTD ROR'}, {label: 'YTD ROR', value: 'YTD ROR'}, {label: 'ITD ROR', value: 'ITD ROR'}];
  public reportingLevels = [{
    "label": "Generic",
    "value": "Generic"
  }, {
    "label": "PE/SPV",
    "value": "PE/SPV"
  }, {
    "label": "Custom",
    "value": "Custom"
  }];
  public fundsArr =[];
  reportStartDate: any;
  reportEndDate: any;
  groupsList = [{
    "label": "Fund",
    "value":"Fund"
 }];


  entityLevels = [{
    "label": "Fund Level",
    "value": "Fund Level",

  }, {
    "label": "Holding Level",
    "value": "Holding Level"
  },
  {
    "label": "Asset Level",
    "value": "Asset Level"
  },
  {
    "label": "Property Level",
    "value": "Property Level"
  }
  ];
  displayOptions1 = [];
  displayOptions = [{
    "label": "MOIC",
    "value": "MOIC"
  },
  {
    "label": "TVPI",
    "value": "TVPI"
  },
  {
    "label": "DPI",
    "value": "DPI"
  },
  {
    "label": "RVPI",
    "value": "RVPI"
  }];
  public booklevelTotal = [{ label: "Fund Wise Summary", value: "fundwiseSummary" }, { label: "Book Level Total", value: "booklevelTotal" }]
  public fundGroupList = [];
  public custodianlist = [];
  public booksList = [];
  public AccountTrialBalanceTypeLst = [];
  public realEstateEntityType = [];
  public entityList = [];
  public brokerList: any = [];
  public periodListReReport = [{ label: "3 Months Ended", value: "3" }, { label: "6 Months Ended", value: "6" }, { label: "9 Months Ended", value: "9" }, { label: "12 Months Ended", value: "12" }]

  varianceReportType = [{
    "label": "Balance Sheet",
    "value": "balanceSheetGroup",

  }, {
    "label": "Income Statement",
    "value": "incomeStatementGroup"
  },
  ];
  reportTypeForConsolidate = [{
    "label": "Monthly",
    "value": "Monthly"
  }, {
    "label": "Quarterly",
    "value": "Quarterly"
  }];

  public varianceReportFrequencyList = [{ "label": "Daily", "value": "Daily" },
  { "label": "Monthly", "value": "Monthly" },
  { "label": "Quarterly", "value": "Quarterly" },
  { "label": "Halfyearly", "value": "Halfyearly" },
  { "label": "9 Months", "value": "9Months" },
  { "label": "Yearly", "value": "Yearly" }];
  public reportingAcBalanceType = [{ "label": "Default", "value": "Default" }, { "label": "Comparative Report", "value": "comparativeAccountBalanceSheet" }];
  public totalView = [{ "label": "Default", "value": "Default" }, { "label": "Consolidated", "value": "Consolidated" }];
  public ReportingTypeList = [{ label: "Ticker", value: "Ticker" },
  { label: "Sector", value: "Sector" },
  { label: "Sub Sector", value: "Sub Sector" },
  { label: "Strategy", value: "Strategy" },
  { label: "Asset Class", value: "Asset Class" }
  ];

  constructor(private http: HttpClient, private commonService: CommonService, private localService: LocalStoreService) { }

  public sysNewDate = new Date;//this.commonService.setDateFunction(new Date().getDay(), new Date());
  public sysOldDate = new Date((new Date()).getTime().valueOf() - 1000 * 60 * 60 * 24);
  public startDate =new Date((new Date()).getTime().valueOf() - 1000*60*60*24); // this.commonService.setDateFunction(new Date().getDay(), new Date());
  public endDate =  new Date((new Date()).getTime().valueOf() - 1000*60*60*24);
  public startYearDate=new Date(new Date().getFullYear(), 0, 1)
  public startYearMonth=new Date(new Date().getMonth(), 0, 1)
  basicDetails() { 
    return [
      { id: 'batchName', controlType: 1, label: 'Batch Name', name: 'BatchId', order: 1, value: '', required: true, readonly: false, class: "col-lg-12" },
      { id: 'batchfundIdg', controlType: 2, label: 'Fund', viewname: 'FundId', name: 'batchfundId', order: 2, value: null, required: false, fill: this.batchfundlist, disabled: false, class: "col-lg-12 mb-10" , style: "display:none;"},
      { id: 'batchStartDateg', controlType: 3, label: 'start_date', viewname: 'batchStartDate', name: 'batchStartDate', order: 3, value: this.sysOldDate, required: false, readonly: false, appearance: "fill", disabled: false, class: "col-lg-6" , style: "display:none;"},
      { id: 'batchEndDateg', controlType: 3, label: 'end_date', viewname: 'batchEndDate', name: 'batchEndDate', order: 4, value: this.sysNewDate, required: false, readonly: false, appearance: "fill", disabled: false, class: "col-lg-6 mb-10", style: "display:none;" },
      { id: 'batchEmailAddress', controlType: 1, label: 'email_address', viewname: 'batchEmailAddress', name: 'batchEmailAddress', order: 5, value: '', type: 'text', required: true, readonly: false, disabled: false, class: "col-lg-12", },
    ];
  };

  //Common Fields Report List
  reportCont() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    ]
  };

  //Common Fields
  reportDetailsCom() {
    return [
      { id: 'batcRptLvlId', controlType: 2, label: 'Reporting Level', viewname: 'FundId', name: 'batchfundId', order: 1, value: null, required: false, fill: this.reptLvlList, disabled: false, class: "col-lg-6 mb-10" },
      { id: 'batchfundIdr', controlType: 2, label: 'Reporting Fund', viewname: 'FundId', name: 'batchfundId', order: 2, value: null, required: false, fill: this.batchfundlist, disabled: false, class: "col-lg-6 mb-10" },
      { id: 'batchFlTypId', controlType: 2, label: 'File Type', viewname: 'FundId', name: 'batchfundId', order: 3, value: null, required: false, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12" },
    ]
  };

  //key: balanceSheet value: Balance Sheet   //accounting
  balanceSheet() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 2, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 2, value: null, required: false, fill: this.reportinggroupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'date', name: 'endDate', viewName: 'endDate', order: 1, value: this.endDate, required: true, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 3, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
    ]
  };
   //key: custodianReconReport value: Custodian Recon  //multiverse
   custodianReconReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 2, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 2, value: null, required: false, fill: this.reportinggroupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'date', name: 'endDate', viewName: 'endDate', order: 1, value: this.endDate, required: true, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 3, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
    ]
  };
  //key: incomeStatement value: Income Statement  //accoounting
  incomeStatement() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: balanceSheetNC value: Balance Sheet NC
  balanceSheetNC() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 4, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 5, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: custodianReconReport value: Custodian Recon



  //key: investorStatementsDynamic value: Investor Statement Dynamic   //investor  
  investorStatementsDynamic() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'endDate', name: 'endDate', order: 2, value: '', required: false, class: "col-lg-6",style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'investorName', controlType: 2, label: 'investor_name', name: 'investorName', viewName: 'investorName', order: 1, value: null, required: false, fill: null, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 3, value: null, required: true, fill: this.pdfFileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      { id: 'showShares', controlType: 9, label: 'Show Shares', viewname: 'showShares', name: 'showShares', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showSharesClass', controlType: 9, label: 'Show Shares Class', viewname: 'showSharesClass', name: 'showSharesClass', order: 5, value: false, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showSeriesInfo', controlType: 9, label: 'Show Series Info', viewname: 'showSeriesInfo', name: 'showSeriesInfo', order: 6, value: false, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showInvestorId', controlType: 9, label: 'Show Investor Id', viewname: 'showInvestorId', name: 'showInvestorId', order: 7, value: false, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showSpecificInvestor', controlType: 9, label: 'Show Specific Investor', viewname: 'showSpecificInvestor', name: 'showSpecificInvestor', order: 8, value: false, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'combinedPDF', controlType: 9, label: 'Combined PDF', viewname: 'combinedPDF', name: 'combinedPDF', order: 9, value: false, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showROR', controlType: 9, label: 'Show ROR', viewname: 'showROR', name: 'showROR', order: 10, value: true, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showInvestorAddress', controlType: 9, label: 'Show Investor Address', viewname: 'showInvestorAddress', name: 'showInvestorAddress', order: 10, value: true, binary: "True", class: "col-lg-6 check-bx-space" },
      { id: 'showIRR', controlType: 9, label: 'Show IRR', viewname: 'showIRR', name: 'showIRR', order: 12, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
      { id: 'showTvpi', controlType: 9, label: 'Show TVPI', viewname: 'showTvpi', name: 'showTvpi', order: 13, value: false, binary: "True", class: "col-lg-6 check-bx-space",  style: "display:none"},
      { id: 'showDvpi', controlType: 9, label: 'Show DPI', viewname: 'showDvpi', name: 'showDvpi', order: 14, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
      { id: 'showInvestorIrr', controlType: 9, label: 'Show IIR', viewname: 'showTvpi', name: 'showTvpi', order: 11, value: false, binary: "True", class: "col-lg-6 check-bx-space",  style: "display:none"},
      { id: 'showUnfundedCommitment', controlType: 9, label: 'Show Unfunded Commitment', viewname: 'showDvpi', name: 'showDvpi', order: 12, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
      { id: 'showArrear', controlType: 9, label: 'Call in Arrear/Advance', viewname: 'showArrear', name: 'showArrear', order: 12, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
      { id: 'actualReportTypes', controlType: 1, label: 'actualReportTypes', name: 'actualReportTypes', viewName: 'actualReportTypes', order: 13, value: null, required: false, fill: null, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'moic', controlType: 9, label: 'custom_moic', viewname: 'moic', name: 'moic', order: 11, value: false, binary: "True", class: "col-lg-6 check-bx-space",  style: "display:none"},
      { id: 'customMoic', controlType: 9, label: 'moic', viewname: 'custommoic', name: 'custommoic', order: 11, value: false, binary: "True", class: "col-lg-6 check-bx-space",  style: "display:none"},
      { id: 'showNSPRor', controlType: 9, label: 'period_net_return_non_side_pocket', viewname: 'showNSPRor', name: 'showNSPRor', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none;" },
      { id: 'showSPRor', controlType: 9, label: 'period_net_return_side_pocket', viewname: 'showSPRor', name: 'showSPRor', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none;" },
      { id: 'showCommitmentSummary', controlType: 9, label: 'commitment_summary', viewname: 'showCommitmentSummary', name: 'showCommitmentSummary', order: 15, value: false, binary: "True", class: "col-lg-6 check-bx-space",  style: "display:none"},
      { id: 'showInvestorDetails', controlType: 9, label: 'show_investor_details', viewname: 'showInvestorDetails', name: 'showInvestorDetails', order: 4, value: false, binary: "True", class: "col-lg-6 left_attachment",  style: "display:none;"},
      { id: 'showClassNAVPerShare', controlType: 9, label: 'use_class_nAV_per_share', viewname: 'showClassNAVPerShare', name: 'showClassNAVPerShare', order: 4, value: false, binary: "True", class: "col-lg-6 right_attachment",style: "display:none;" },
      { id: 'shownetror', controlType: 9, label: 'show_net_ror', viewname: 'shownetror', name: 'shownetror', order: 4, value: false, binary: "True", class: "col-lg-6 right_attachment",style: "display:none;" },
      { id: 'monthyear', controlType: 8, label: 'month_year', viewname: 'monthyear', name: 'monthyear', order: 2, value: null, required: true, class: "col-lg-12" },
      { id: 'notionalInterest', controlType: 9, label: 'notional_interest', viewname: 'notionalInterest', name: 'notionalInterest', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
{ id: 'annexure1', controlType: 9, label: 'annexure1_contributions_cash_and_non_cash', viewname: 'annexure1', name: 'annexure1', order: 4, value: false, binary: "True", class: "col-lg-8", style: "display:none;" },
      { id: 'annexure2', controlType: 9, label: 'annexure2_distributions_cash_and_non_cash', viewname: 'annexure2', name: 'annexure2', order: 4, value: false, binary: "True", class: "col-lg-8 check-bx-space", style: "display:none;" },
      { id: 'annexure3', controlType: 9, label: 'annexure3_expenses_income', viewname: 'annexure3', name: 'annexure3', order: 4, value: false, binary: "True", class: "col-lg-8 check-bx-space", style: "display:none;" },
      { id: 'excludeInvSupportNumber', controlType: 9, label: 'Exclude Investor Support Contact Number', viewname: 'excludeInvSupportNumber', name: 'excludeInvSupportNumber', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
      { id: 'fundLogo', controlType: 9, label: 'Fund Logo', viewname: 'fundLogo', name: 'fundLogo', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none;" },
      { id: 'ytdPeriod', controlType: 2, label: "ytd_Period", viewname: 'ytdPeriod', name: 'ytdPeriod', order: 1, value: this.ytdPeriodList[0].value, required: true, disabled: false, fill:this.ytdPeriodList, readonly: true, class: "col-lg-6",style: "display:none;" },
      { id: 'showFooter', controlType: 9, label: 'Show Footer', viewname: 'showFooter', name: 'showFooter', order: 4, value: true, binary: "True", class: "col-lg-6", style: "display:none;" },

      { id: 'showFundAddress', controlType: 9, label: 'fund_address', viewname: 'showFundAddress', name: 'showFundAddress', order: 4, value: true, binary: "True", class: "col-lg-6", style: "display:none" },
      { id: 'showRedemptionHoldback', controlType: 9, label: 'Show Redemption Holdback', viewname: 'showRedemptionHoldback', name: 'showRedemptionHoldback', order: 4, value: false, binary: "True", class: "col-lg-6 check-bx-space", style: "display:none" },
      { id: 'excludeContactDetail', controlType: 9, label: 'exclude_Contact_Detail', viewname: 'excludeContactDetail', name: 'excludeContactDetail', order: 4, value: false, binary: "True", class: "col-lg-6", style: "display:none" },
      { id: 'showAliasName', controlType: 9, label: 'show_alias_name', viewname: 'showAliasName', name: 'showAliasName', order: 4, value: false, binary: "True", class: "col-lg-6", style: "display:none" },
      { id: 'rorForSummaryOfInvestmentActivity', controlType: 7, label: "ROR (Summary of Investment Activity)", viewname: 'rorForSummaryOfInvestmentActivity', name: 'rorForSummaryOfInvestmentActivity', order: 2, value: [], required: false, fill: this.rorList, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:none"},
      { id: 'rorForDetailOfInvestments', controlType: 7, label: "ROR (Detail of Investments)", viewname: 'rorForDetailOfInvestments', name: 'rorForDetailOfInvestments', order: 2, value: [], required: false, fill: this.rorList, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:none"},
      { id: 'summaryOfInvestments', controlType: 9, label: 'Summary Of Investments', viewname: 'summaryOfInvestments', name: 'summaryOfInvestments', order: 4, value: true, binary: "True", class: "col-lg-6", style: "display:none;" },
      { id: 'showCapitalActivity', controlType: 9, label: 'Show Capital Activity (Subscription/Redemption)', viewname: 'summaryOfInvestments', name: 'summaryOfInvestments', order: 4, value: true, binary: "True", class: "col-lg-12", style: "display:none;" },
    ]
  };

  investorStatementAifWithRor2(){
    return[
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'investorListRor', controlType: 7, label: "investor", viewname: 'investorListRor', name: 'investorListRor', order: 2, value: [], selectionLimit: 10, required: true, fill: this.investorList, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      { id: 'fund', controlType: 2, label: "fund", viewname: 'fund', name: 'fund', order: 1, value: null, required: true, fill: this.fundList, class: "col-lg-12" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: '', required: true, class: "col-lg-6" },
      { id: 'emailAddress', controlType: 1, label: 'email_address', viewname: 'emailAddress', name: 'emailAddress', order: 1, value: '', type: 'email', required: false, class: "col-lg-12", style: "display:none;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 5, value: null, required: true, fill: this.pdfFileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },  
      { id: 'showInvestorDetails', controlType: 9, label: 'Show Investor Details', viewname: 'showInvestorDetails', name: 'showInvestorDetails', order: 4, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showClassNAVPerShare', controlType: 9, label: 'Use Class NAV Per Share', viewname: 'showClassNAVPerShare', name: 'showClassNAVPerShare', order: 4, value: false, binary: "True", class: "col-lg-6" },
      { id: 'combinePdf', controlType: 9, label: 'Combine PDF', viewname: 'combinePdf', name: 'combinePdf', order: 4, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showNetROR', controlType: 9, label: 'Show Net ROR', viewname: 'showNetROR', name: 'showNetROR', order: 4, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showShares', controlType: 9, label: 'Show Shares', viewname: 'showShares', name: 'showShares', order: 4, value: false, binary: "True", class: "col-lg-6" },
    ]
    };
  //key: investorStatementQuaterly value: Investor Statement Quarterly  //investor
  investorStatementQuaterly() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'investorName', controlType: 2, label: 'investor_name', name: 'investorName', viewName: 'investorName', order: 1, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: moneyLine value: Moneyline   //accounting
  moneyLine() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: investmentDetailsReport value: Investment Details Report //portfolio
  investmentDetailsReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors pt-0 pr-10 pl-0 mb-0 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: openTaxLotReport value: Open Tax Lot Report  //query
  openTaxLotReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: tradeReconciledReport value: Trade Reconciled Report    //Query
  tradeReconciledReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  //key: managementFeeReport value: Management Fee Report  //accounting
managementFeeReport () {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  
  //key: managementFeesReport value: Management Fee Report Preq    //accounting doubt
  //mangementFeeReportPreq
  mangementFeeReportPreq () {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: performanceFeeReport value: Performance Fee Report  //accounting
  performanceFeeReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

   //key: performanceFeeHurdleReport value: Performance Fee Hurdle Report //accounting
   performanceFeeHurdleReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 23, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate,required: true, class: "col-lg-6 dropdown-bgcolors" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors pt-0" },
    ]
  };
  //key: trialBalance value: Trial Balance  //accounting
  trialBalance() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  // Real Estate Reports Start
  //key: accountTrailBalance value: Account Trial Balance  //accounting RE Report
  accountTrailBalance() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingType', name: 'reportingType', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'isBookTotal', controlType: 2, label: 'totaling_view', viewname: 'showSubtotal', name: 'showSubtotal', order: 11, value: this.booklevelTotal[1].value, required: false, fill: this.booklevelTotal, readonly: false, class: "col-lg-12", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ];
  }
  //key: activityReportController value: Account Activity Report  //accounting RE Report
  activityReportController() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'isBookTotal', controlType: 2, label: 'totaling_view', viewname: 'showSubtotal', name: 'showSubtotal', order: 11, value: this.booklevelTotal[1].value, required: false, fill: this.booklevelTotal, readonly: false, class: "col-lg-12", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ];
  }
  //key: accountStatementReportController value: Account Statement Report  //accounting RE Report
  accountStatementReportController() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 9, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 16, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'showDTD', controlType: 9, label: 'Show DTD', viewname: 'showDTD', name: 'showDTD', order: 10, value: true, binary: "True", class: "col-lg-6 clearfix" },
      { id: 'showQTD', controlType: 9, label: 'Show QTD', viewname: 'showQTD', name: 'showQTD', order: 11, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showMTD', controlType: 9, label: 'Show MTD', viewname: 'showMTD', name: 'showMTD', order: 12, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showYTD', controlType: 9, label: 'Show YTD', viewname: 'showYTD', name: 'showYTD', order: 13, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showITD', controlType: 9, label: 'Show ITD', viewname: 'showITD', name: 'showITD', order: 14, value: false, binary: "True", class: "col-lg-6" },
      // { id: 'showIRR', controlType: 9, label: 'Show IRR', viewname: 'showIRR', name: 'showIRR', order: 15, value: false, binary: "True", class: "col-lg-6" },

    ];
  }

  //key: accountcashLedger value: Cash Ledger Account  //accounting RE Report
  accountcashLedger() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 1, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 1, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 1, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 1, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 1, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 3, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'account', controlType: 7, label: 'custodian_account', viewname: 'account', name: 'account', order: 1, value: '', required: true, fill: this.brokerList, readonly: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'isBookTotal', controlType: 2, label: 'totaling_view', viewname: 'showSubtotal', name: 'showSubtotal', order: 1, value: this.booklevelTotal[1].value, required: false, fill: this.booklevelTotal, readonly: false, class: "col-lg-12", style: "display:none;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 16, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ];
  }

  //key: accountGeneralLedger value: Account general Ledger  //accounting RE Report
  accountGeneralLedger() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 16, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ];
  }

  //key: accountoperatingExpensesCntrlr value: Account Operating Expenses  //accounting RE Report
  accountoperatingExpensesCntrlr() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 1, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 1, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 1, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 1, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 1, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 3, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 16, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ];
  }

  //key: accountBalanceSheetController value: Account Balance Sheet  //accounting RE Report
  accountBalanceSheetController() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportType', controlType: 2, label: 'reporting_type', viewname: 'reportType', name: 'reportType', order: 1, value: '', required: true, fill: this.reportingAcBalanceType, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: '', required: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'date', viewname: 'endDate', name: 'endDate', order: 9, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'isBookTotal', controlType: 2, label: 'totaling_view', viewname: 'showSubtotal', name: 'showSubtotal', order: 11, value: this.booklevelTotal[1].value, required: false, fill: this.booklevelTotal, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 12, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ];
  }

  //key: accountPositionReportCtrl value: Account Position Report  //accounting RE Report
  accountPositionReportCtrl() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'isBookTotal', controlType: 2, label: 'totaling_view', viewname: 'showSubtotal', name: 'showSubtotal', order: 11, value: this.booklevelTotal[1].value, required: false, fill: this.booklevelTotal, readonly: false, class: "col-lg-12", style: "display:none;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 12, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ];
  }

  //key: generalLedgerAccountReport value: General Ledger Account  //accounting RE Report
  generalLedgerAccountReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12 ml-3 clearfix" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-6  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3 mb-c" },
      { id: 'ledgerAccount', controlType: 7, label: 'Ledger Account', name: 'ledgerAccount', viewName: 'ledgerAccount', order: 9, value: '', required: true, fill: this.leadgerAccountList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", },
      { id: 'custodianAccount', controlType: 7, label: 'Custodian Account', name: 'custodianAccount', viewName: 'custodianAccount', order: 10, value: '', required: true, fill: this.custodianList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 mb-c dropdown-bgcolors" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 11, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 12, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'totalView', controlType: 2, label: 'totaling_view', viewname: 'totalView', name: 'totalView', order: 2, value: '', required: true, fill: this.totalView, readonly: false, disabled: false, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 13, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ];
  }

  //key: accountPositionReportWithRealizedGain value: Account Position Report with Realized Gain  //accounting RE Report
  accountPositionReportWithRealizedGain() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 9, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'isBookTotal', controlType: 2, label: 'totaling_view', viewname: 'showSubtotal', name: 'showSubtotal', order: 11, value: this.booklevelTotal[1].value, required: false, fill: this.booklevelTotal, readonly: false, class: "col-lg-12", style: "display:none;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 12, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ];
  }

  //key: accountIncomeStatement value: Income Statement (Account)  //accounting RE Report
  accountIncomeStatement() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'fundList', order: 2, value: '', required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'groupLevel', controlType: 2, label: 'entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'entityList', controlType: 7, label: 'Entity List', viewname: 'entityList', name: 'entityList', order: 4, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-12  ml-3" },
      { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 6, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'fundId', controlType: 2, label: 'property_reit_fund', viewname: 'fund', name: 'fund', order: 7, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, style: "display:none;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'fundList', controlType: 7, label: 'property_reit_fund', viewname: 'fundGroup', name: 'fundGroup', order: 8, value: '', fill: this.fundGroupList, required: true, style: "display:block;", class: "col-lg-12 ml-3" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 10, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'period', controlType: 2, label: 'period', viewname: 'period', name: 'period', order: 10, value: this.periodListReReport[0].value, required: false, fill: this.periodListReReport, readonly: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 11, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ];
  }

  //key: varianceReport value: Variance Report  //accounting RE Report
  varianceReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: '', required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'entityList', controlType: 7, label: 'entity_list', viewname: 'entityList', name: 'entityList', order: 2, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-6  ml-3" },
      { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 3, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " },
      { id: 'startDate', controlType: 3, label: 'comparision_date1', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6 clearfix" },
      { id: 'endDate', controlType: 3, label: 'comparision_date2', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'reportType', controlType: 2, label: 'reportType', viewname: 'reportType', name: 'reportType', order: 6, value: '', required: false, appearance: "fill", fill: this.varianceReportType, readonly: false, style: "display:block;", class: "col-lg-12  dropdown-bgcolors" },
      { id: 'statementgroupName', controlType: 7, label: 'statement_group_name', viewname: 'statementgroupName', name: 'statementgroupName', order: 7, value: '', required: true, fill: '', readonly: false, class: "col-lg-12  ml-3" },
      { id: 'varianceAmount', controlType: 1, label: 'variance_inamount', viewname: 'varianceAmount', name: 'varianceAmount', order: 8, value: '', required: true, disabled: false, readonly: false, appearance: "fill", class: "col-lg-6" },
      { id: 'variancePercentage', controlType: 1, label: 'variance_%', viewname: 'Variance%', name: 'Variance%', order: 9, value: '', required: true, disabled: false, readonly: false, appearance: "fill", class: "col-lg-6" },
      { id: 'reportFrequency', controlType: 2, label: 'report_frequency', viewname: 'reportFrequency', name: 'reportFrequency', order: 10, value: '', required: false, appearance: "fill", fill: this.varianceReportFrequencyList, readonly: false, style: "display:none;", class: "col-lg-6  dropdown-bgcolors" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 11, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ];
  }
  // Real Estate Reports End

  //key: dailyROR value: Returns %  //accounting
  dailyROR() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: tradeBreakReport value: Trade Break Report  //reconcial
  tradeBreakReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'reportingGroup', viewName: 'reportingGroup', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'custodianAccount', controlType: 7, label: 'Custodian Account', name: 'custodianAccount', viewName: 'custodianAccount', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

    //key: dailyCashReport value: Daily Cash Report  //accounting

  dailyCashReport() {    
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fundNameList', controlType: 7, label: 'entity_list', viewname: 'fundNameList', name: 'fundNameList', order: 2, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-6  ml-3" },
      { id: 'entityLevel', controlType: 2, label: 'report_entity_level', viewname: 'entityLevel', name: 'entityLevel', order: 3, value: '', required: true, fill: this.entityLevels, readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'bookValueList', controlType: 7, label: 'books', viewname: 'bookValueList', name: 'bookValueList', order: 3, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors " },
      { id: 'endDate', controlType: 3, label: 'report_date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
     { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors pt-0" },
    ]
  };

  //key: openLotsWTL value: Open lots W/TL  //Checks
  openLotsWTL() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'programopenLotsWTL', controlType: 2, label: 'Program', name: 'programopenLotsWTL', viewName: 'programopenLotsWTL', order: 1, value: '', required: true, fill: this.reportinggroupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'As of Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: positionRecReport value: Position Rec Report  //Reconcial
  positionRecReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      // { id: 'reportType', controlType: 2, label: 'Report Type', name: 'reportType', viewName: 'reportType', order: 1, value: null, required: true, fill: this.positionreportReportTyp, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 queryinvest dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'As Of Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: positionRecSummary value: Position Rec Summary   //reconcial
  positionRecSummary() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'As Of Date', viewname: 'endDate', name: 'endDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 3, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: dailyPNL value: Daily PNL   //Query
  dailyPNL() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelTxtList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: dailyPNL2 value: Daily PNL 2
  dailyPNL2() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelTxtList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: eodPosition value: EOD Position   //Query
  eodPosition() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: maturityAging  value: Maturity Aging   //reports custom
  maturityAging() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: capitalCallReport  value: Capital Call Report   //reports custom
  capitalCallReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.pdfCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: cashBalanceRecReport value: Cash Balance Rec Report  //reconcial
  cashBalanceRecReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportType', controlType: 2, label: 'Report Type', name: 'reportType', viewName: 'reportType', order: 2, value: null, required: true, fill: this.positionreportReportTyp, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 3, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6  ml-3", style: "display:block;" },
      { id: 'asOfDate', controlType: 3, label: 'As Of Date', viewname: 'asOfDate', name: 'asOfDate', order: 4, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 5, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: generalLedgerAccount value: GL A/c  //accounting
  generalLedgerAccount() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'coustodianAccount', controlType: 7, label: 'Custodian Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 4, value: null, required: true, fill: this.custodianList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'type', controlType: 2, label: 'Type', name: 'type', viewName: 'type', order: 2, value: this.AllocationReporttype[1].value, required: true, fill: this.AllocationReporttype, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'ledgerAccount', controlType: 7, label: 'Ledger Account', name: 'ledgerAccount', viewName: 'ledgerAccount', order: 3, value: null, required: true, fill: this.leadgerAccountList, appearance: "fill", disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 5, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 6, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'Download', viewname: 'fileType', name: 'fileType', order: 7, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: cashLedger value: Cash Ledger  //accounting
  cashLedger() {

    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'coustodianAccount', controlType: 7, label: 'Custodian Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'type', controlType: 2, label: 'Type', name: 'type', viewName: 'type', order: 3, value: this.AllocationReporttype[1].value, required: true, fill: this.AllocationReporttype, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: ' Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: totalGainLossViewReport  value: View Total G/L  //portfolio
  totalGainLossViewReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'coustodianAccount', controlType: 2, label: 'Broker Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'ticker', controlType: 1, label: 'Ticker', name: 'ticker', viewName: 'ticker', order: 3, value: null, required: false, fill: null, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: ' Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  //key: mvAndExposureReport value: MV and Exposure Report
  mvAndExposureReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: null, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 4, value: '', required: true, class: "col-lg-6" },
      // { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: eodChecksSheet value: EOD Checks Sheet  //Checks
  eodChecksSheet() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Report Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: PerfTableProgram value: Performance Table Program  //Cta
  PerfTableProgram() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'program', controlType: 2, label: 'Program', name: 'program', viewName: 'program', order: 1, value: this.programList[0].value, required: true, fill: this.programList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startMonth', controlType: 8, label: 'Start Month', viewname: 'startMonth', name: 'startMonth', order: 2, value: null, required: true, class: "col-lg-6" },
      { id: 'endMonth', controlType: 8, label: 'End Month', viewname: 'endMonth', name: 'endMonth', order: 5, value: '', required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: pnlBySymbolReport value: PNL By Symbol  

  pnlBySymbolReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', viewname: 'fund', name: 'fund', order: 1, value: [], required: true, appearance: "fill", fill: this.batchfundlist, readonly: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, appearance: "fill", class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: '', required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
      ];
  }
  
    

  //key: PerfCompositeProgram value: Performance Capsule - Program  //CTA
 //key: PerfCompositeProgram value: Performance Capsule - Program  //CTA
PerfCompositeProgram() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'program', controlType: 2, label: 'Program', name: 'program', viewName: 'program', order: 1, value: this.programList[0].value, required: true, fill: this.programList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
    { id: 'endMonth', controlType: 8, label: 'End Month', viewname: 'endMonth', name: 'endMonth', order: 5, value: '', required: true, class: "col-lg-6" },
   { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
  ]
};
  //key: ctaMoneyLine value: Money Line  //CtA
  ctaMoneyLine() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: null, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 4, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: false, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: orionDataFiles value: Orion Data Files   //Query
  orionDataFiles() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },

      { id: 'type', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: null, required: true, fill: this.orianreportType, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.txtFileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: investorSummaryReports value: Investor Summary Reports  //Query
  investorSummaryReports() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: null, required: true, fill: this.investorsumaryrepttype, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      //  { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: null, required: true, fill: this.fundList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: false, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.csvtextFileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };
  dealSummaryReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
      };
  //key: Report1099 value: 1099Report  //tax
  Report1099() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  cashRecMultiParty(){
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  }

  //key: fidelityInvestorReports value: Fidelity Investor Reports   //Query
  fidelityInvestorReports() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: null, required: true, fill: this.fidelityTypeList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: marginByEquityByFundReport value: Margin By Equity By Fund Report  //margin
  marginByEquityByFundReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 1, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'marginequityRepType', controlType: 2, label: 'Report Types', name: 'marginequityRepType', viewName: 'marginequityRepType', order: 2, value: this.marginequityreportType[0].value, required: true, fill: this.marginequityreportType, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fundMargin', controlType: 7, label: 'Fund', name: 'fundMargin', viewName: 'fundMargin', order: 3, value:  this.batchfundlist.map(e =>e), required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'programMargin', controlType: 7, label: 'Program', name: 'programMargin', viewName: 'programMargin', order: 4, value: null, required: false, fill: this.programList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:none;" },
      { id: 'accountMargin', controlType: 7, label: 'Account Number', name: 'accountMargin', viewName: 'accountMargin', order: 4, value: null, required: false, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: InvStmtwithFundSumm value: InvStmt with FundSumm  //investor
  InvStmtwithFundSumm() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'investorName', controlType: 2, label: 'investor_name', name: 'investorName', viewName: 'investorName', order: 1, value: null, required: true, fill: this.fundList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: InvStmtwithFundSummGeneric value: InvStmt with FundSumm Generic
  InvStmtwithFundSummGeneric() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'investorName', controlType: 2, label: 'investor_name', name: 'investorName', viewName: 'investorName', order: 1, value: null, required: true, fill: this.fundList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: positionReport value: Position Report
  positionReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportType', controlType: 2, label: 'Type', viewname: 'reportType', name: 'reportType', order: 1, value: this.reportType[0].value, required: true, appearance: "fill", fill: this.reportType, readonly: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: FundInvestorSummary value: Fund Investor Summary
  FundInvestorSummary() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'investorName', controlType: 2, label: 'investor_name', name: 'investorName', viewName: 'investorName', order: 1, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.pdfFileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: operatingExpenses value: Operating Expenses   //accounting
  operatingExpenses() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportPackage', controlType: 2, label: 'Report Type', name: 'reportPackage', viewName: 'reportPackage', order: 2, value: null, required: true, fill: this.AllocationReporttype1, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" }
]
  };

  //key: generalLedgerReport value: GL Report  //accounting
  generalLedgerReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fundglReport', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'reportingLevel1', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.gainLossReportType[0].value, required: true, fill: this.gainLossReportType, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'reportTypeCusto', controlType: 2, label: 'Account', name: 'Account', viewName: 'Account', order: 2, value: null, required: true, fill: this.accountList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endtDate', name: 'endtDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'Download', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: investorCapitalSummary value: Investor Capital Summary  //investor
  investorCapitalSummary() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'type', controlType: 2, label: 'Type', name: 'type', viewName: 'type', order: 2, value: this.AllocationReporttype[0].value, required: true, fill: this.AllocationReporttype, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value:'', required: false, fill: this.investorcapitalReportType, appearance: "fill", disabled: false, class: "col-lg-6 align-custodian", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 pt-0 pl-5 pr-10 pb-15 dropdown-bgcolors", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 pt-0 pl-5 pr-10 pb-15 dropdown-bgcolors" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
    ]
  };

  //key: investmentSummaryReport value: Investment Summary Report  //investor
  investmentSummaryReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 pt-0 pl-5 pr-10 pb-15 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors pt-0" },
    ]
  };

  //key: investorCapitalSummary value: Capital Summary Commitment  //investor
  capitalSummaryCommitmentReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: this.investorcapitalReportType[0].value, required: true, fill: this.investorcapitalReportType, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors  pt-0" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
    ]
  };

  //  key:  equitySchedule  value:   Equity Schedule  //investor
  equitySchedule() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'type', controlType: 2, label: 'Type', name: 'type', viewName: 'type', order: 2, value: this.AllocationReporttype[0].value, required: true, fill: this.AllocationReporttype, appearance: "fill", disabled: false, class: "col-lg-12", style: "display:block;" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: this.equityScheduleReportType[0].value, required: true, fill: this.equityScheduleReportType, appearance: "fill", disabled: false, class: "col-lg-6 align-custodian", style: "display:block;" },
      { id: 'fundList', controlType: 7, label: 'Fund Group', name: 'fund', viewName: 'fund', order: 2, value: null, required: false, fill: '', appearance: "fill", disabled: false, class: "col-lg-6 ml-1 dropdown-bgcolors", style: "display:none;" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 pt-0 pl-5 pr-10 pb-15 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'showAttribute', controlType: 7, label: 'Display Options', name: 'showAttribute', viewName: 'showAttribute', order: 2, value: [], required: false, fill: this.Attributes, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors pt-0", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors pt-0" },
    ]
  };
  //key: investorCapitalSummary-Custom value: Investor Capital Summary - Custom   //Query
  investorCapitalSummaryCustom() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'group', controlType: 7, label: 'fund_group', name: 'group', viewName: 'group', order: 1, value: null, required: true, fill: this.reportinggroupList, appearance: "fill", disabled: false, class: "col-lg-12  ml-3 dropdown-bgcolors" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: [], required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'investorList', controlType: 7, label: "investor", viewname: 'investorList', name: 'investorList', order: 3, value: [], required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.pdfCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: AnnualImpactNoteStatement value: Annual Impact Note Statement  //investor
  AnnualImpactNoteStatement() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fundAnnualNote', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'investorName', controlType: 2, label: 'investor_name', name: 'investorName', viewName: 'investorName', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 3, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.pdfFileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'MTD', controlType: 9, label: 'MTD', viewname: 'MTD', name: 'MTD', order: 5, value: false, binary: "True", class: "col-lg-6" },
      { id: 'QTD', controlType: 9, label: ' QTD', viewname: ' QTD', name: ' QTD', order: 6, value: false, binary: "True", class: "col-lg-6" },
      { id: 'YTD', controlType: 9, label: 'YTD', viewname: 'YTD', name: 'YTD', order: 7, value: true, binary: "True", class: "col-lg-6" },
    ]
  };


  //key: unrealizedGainLoss value: Unrealized G/L      //tradingl
  unrealizedGainLoss() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'account', controlType: 7, label: 'Account', name: 'account', viewName: 'account', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: realizedGainLoss value: Realized G/L    //tradingl
  realizedGainLoss() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: this.realizedReportType[0].value, required: true, fill: this.realizedReportType, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'assetClass', controlType: 7, label: 'Asset Class', name: 'assetClass', viewName: 'assetClass', order: 3, value: null, required: true, fill: this.assetList, appearance: "fill", disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: holdingMaturityReport value: Holding - Maturity
  holdingMaturityReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
    ]
  };
  

  


  //key: trialBalanceSummary value: Trial Balance Summary  //accounting

  trialBalanceSummary() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: instrumentReport value: Instrument Report    //tradinggainloss
  instrumentReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: null, required: true, fill: this.reportingLevelList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'programinstrument', controlType: 2, label: 'Program', name: 'program', viewName: 'program', order: 2, value: null, required: true, fill: this.programList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'sector', controlType: 2, label: 'sector', name: 'sector', viewName: 'sector', order: 2, value: null, required: false, fill: this.sectorList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: '', required: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: accountStatement value: Account Statement  //accounting
  accountStatement() {
    let viewCheck = this.currentUser.authList.indexOf('SEAMLESS_REPORTS_ACCOUNTING_ACCOUNTSTATEMENT_DISPLAYOPTIONS_MULTIPLES') > -1 ? true : false;
       if (viewCheck == false)
         this.displayOptions = this.displayOptions1;
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.reportinggroupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'displayOptions', controlType: 7, label: 'Display Options', viewname: 'displayOptions', name: 'displayOptions', order: 1, value: [], required: false, appearance: "fill", fill: this.displayOptions, readonly: false, style: "display:block;", class: "col-lg-6 ml-3 pt-0" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 4, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 5, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      { id: 'showDaily', controlType: 9, label: 'Show Daily', viewname: 'showDaily', name: 'showDaily', order: 5, value: true, binary: "True", class: "col-lg-6" },
      { id: 'showQTD', controlType: 9, label: 'Show QTD', viewname: 'showQTD', name: 'showQTD', order: 5, value: true, binary: "True", class: "col-lg-6" },
      { id: 'showMTD', controlType: 9, label: 'Show MTD', viewname: 'showMTD', name: 'showMTD', order: 9, value: true, binary: "True", class: "col-lg-6" },
      { id: 'showYTD', controlType: 9, label: 'Show YTD', viewname: 'showYTD', name: 'showYTD', order: 6, value: true, binary: "True", class: "col-lg-6" },
      { id: 'showITD', controlType: 9, label: 'Show ITD', viewname: 'showITD', name: 'showITD', order: 7, value: true, binary: "True", class: "col-lg-6" },
      //{ id: 'showIRR', controlType: 9, label: 'Show IRR', viewname: 'showIRR', name: 'showIRR', order: 8, value: false, binary: "True", class: "col-lg-6" },
      { id: 'showOath', controlType: 9, label: 'Show Oath', viewname: 'showOath', name: 'showOath', order: 9, value: false, binary: "True", class: "col-lg-6" },
    

    ]
  };

  //key: economicAllocationReport value: Economic Allocation Report  //investor
  economicAllocationReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'reportTypes', controlType: 2, label: 'Report Types', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: null, required: true, fill: this.AllocationReporttype, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'account', controlType: 7, label: 'Investor', name: 'account', viewName: 'account', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },

      { id: 'unitized', controlType: 9, label: 'Unitized', name: 'status', value: false, order: 8, binary: true, class: "col-lg-6 ", disabled: false, tooltip: true, tooltipTxt: "pending_approval" },
    ]
  };

  //key: callAndDist Report value: Call And Dist Report  //investor
  callAndDist() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6  ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'account', controlType: 7, label: 'Investor', name: 'account', viewName: 'account', order: 2, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'reportTypes', controlType: 2, label: 'Type', name: 'reportTypes', viewName: 'reportTypes', order: 1, value: this.callAndDistReporttype[0].value, required: true, fill: this.callAndDistReporttype, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.pdfCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ]
  };

  //key: dailyCheckReport value: Daily Check
  dailyCheckReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund Name', name: 'fund', viewName: 'fund', order: 1, value: null, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
     { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: false, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },   
    ]
  };

  //key: dividendDistribution value: Dividend Distribution  //tax
  dividendDistribution() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund Name', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: balanceSheetNFM value: Balance Sheet NFM  //accounting
  balanceSheetNFM() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 5, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: balanceSheetDetail value: Balance Sheet Detail
  balanceSheetDetail() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 4, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 5, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: incomeStatementNFM value: Income Statement NFM    //accounting
  incomeStatementNFM() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'Reporting Fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
    ]
  };
 //key: incomeStatementDetailed value: Income Statement Detail    //accounting
 incomeStatementDetailed() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'reportingLevel', controlType: 2, label: 'reporting_level', name: 'reportingLevel', viewName: 'reportingLevel', order: 1, value: this.reptLvlList[1].value, required: true, fill: this.reptLvlList, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'fund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 2, value: this.batchfundlist[0].value, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
    { id: 'group', controlType: 2, label: 'select_group', name: 'group', viewName: 'group', order: 3, value: null, required: false, fill: this.groupList, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:none;" },
    { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 4, value: this.startDate, required: true, class: "col-lg-6" },
    { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
    { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
  ]
};

  //key: positionSummary value: Position Summary
  positionSummary() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'programposition', controlType: 7, label: 'Program', name: 'program', viewName: 'programposition', order: 1, value: null, required: true, fill: this.amcList, appearance: "fill", disabled: false, class: " col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 2, value: null, required: true, fill: [], appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 3, value: '', required: false, class: "col-lg-6  ml-3  pl-0 pr-0" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 pt-0 dropdown-bgcolors" },
    ]
  };

  //key: performanceDetailReport value: Performance Detailed
  performanceDetailReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      { id: 'market', controlType: 9, label: 'Market', viewname: 'Market', name: 'Market', order: 10, value: false, binary: "True", class: "col-lg-6" },

    ]
  };

  //key: comprehensivePNL value: Comprehensive P&L  //tradingl
  comprehensivePNL() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: equityAllocation value: Equity Allocation  //investor //cta 
  equityAllocation() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 8, label: 'Start Month', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 8, label: 'End Month', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };







  //key: customizedPossitionReport value: customized Possition
  customizedPossitionReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 2, value: '', required: false, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: false, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: transactionHistory value: Transaction History
  transactionHistory() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: dailyChecks value: Daily Checks
  dailyChecks() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };


  //key: InvestorSummaryReports value: Investor Summary Reports
  InvestorSummaryReports() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'reporttype', controlType: 7, label: 'Report Type', name: 'reporttype', viewName: 'reporttype', order: 1, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },

    ]
  };

    //key: perfTable value: perfTable  //investor //cta 
perfTable() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'perefReportType', controlType: 2, label: 'Report Type', name: 'reporttype', viewName: 'reporttype', order: 1, value: this.perTablereportType[1].value, required: true, fill: this.perTablereportType, appearance: "fill", disabled: false, class: "col-lg-6", style: "display:block;" },
    { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
    { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startYearDate, required: true, class: "col-lg-6" },
    { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
   { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },   
  ]
};

	  //key:productTaxCategoryClassification  value:  Product Tax Category Classification //tax 
    productTaxCategoryClassification() {
      return [
        { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
        { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
        { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
        { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 3, value: '', required: true, class: "col-lg-12" },
        { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
      ]
    };

  //key: investorCapitalSchedule value: Investor Capital Schedule  //investor

  investorCapitalSchedule() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
    ]
  };

  //key: capitalSchedule value:  Capital Schedule  //investor

capitalSchedule() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
    { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
    { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-12" },
    { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
  ]
};



//key: pnlAllocation value: pnl Allocation  //investor //cta 
pnlAllocation() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
    { id: 'startDate', controlType: 8, label: 'Start Month', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, class: "col-lg-6" },
    { id: 'endDate', controlType: 8, label: 'End Month', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-12" },
   { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
  ]
};

//key: reportingPackage value:Reporting Package
navReporting(){
  return [
  { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
  { id: 'reportGroup', controlType: 2, label: 'reporting_package', viewname: 'reportingLevel', name: 'fundList', order: 1, value: this.reportingLevels[0].value, required: true, fill: this.reportingLevels, readonly: false, class: "col-lg-12" },
  { id: 'reportingLevel', controlType: 2, label: 'reporting_level', viewname: 'reportGroup', name: 'reportGroup', order: 2, value: this.groupsList[0].value,required: true, fill: this.groupsList, readonly: false, class: "col-lg-12" },
  { id: 'fund', controlType: 2, label: 'fund', viewname: 'fundList', name: 'fundList', order: 3, value:null, required: true, fill: this.batchfundlist, readonly: false, appearance: "fill", class: "col-lg-12" },
  { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'endDate', order: 4, value: this.startDate, required: true, appearance: "fill", class: "col-lg-6" },
  { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, appearance: "fill", class: "col-lg-6" },  
  { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
]
};

  // Custom Reporting Package
  customReports() {
    return [
      { id: 'isTrialBalance', controlType: 9, label: 'trial_balance', viewname: 'isTrialBalance', name: 'isTrialBalance', order: 0, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isBalanceSheet', controlType: 9, label: 'balance_sheet', viewname: 'isBalanceSheet', name: 'isBalanceSheet', order: 1, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isIncomeStatement', controlType: 9, label: 'income_statement', viewname: 'isIncomeStatement', name: 'isIncomeStatement', order: 2, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isCashAndPortfolio', controlType: 9, label: 'cash_and_portfolio', viewname: 'isCashAndPortfolio', name: 'isCashAndPortfolio', order: 3, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isPositionReport', controlType: 9, label: 'position_report', viewname: 'isPositionReport', name: 'isPositionReport', order: 4, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isCashBalance', controlType: 9, label: 'cash_balance', viewname: 'isCashBalance', name: 'isCashBalance', order: 5, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isRealizedGainLoss', controlType: 9, label: 'realized_gain_loss', viewname: 'isRealizedGainLoss', name: 'isRealizedGainLoss', order: 6, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isComprehensivePnlBySymbol', controlType: 9, label: 'comprehensive_p_and_l_by_symbol', viewname: 'isComprehensivePnlBySymbol', name: 'isPoisComprehensivePnlBySymbolsitionReport', order: 7, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isEconomicAllocationDetail', controlType: 9, label: 'economic_allocation_detail', viewname: 'isEconomicAllocationDetail', name: 'isEconomicAllocationDetail', order: 8, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isCapitalSchedule', controlType: 9, label: 'capital_schedule', viewname: 'isCapitalSchedule', name: 'isCapitalSchedule', order: 9, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isInvestorCapitalSummary', controlType: 9, label: 'investor_capital_summary', viewname: 'isInvestorCapitalSummary', name: 'isInvestorCapitalSummary', order: 10, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isOperatingExpenseDetailed', controlType: 9, label: 'operating_expenses_detailed', viewname: 'isOperatingExpenseDetailed', name: 'isOperatingExpenseDetailed', order: 11, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isManagementFee', controlType: 9, label: 'management_fee', viewname: 'isManagementFee', name: 'isManagementFee', order: 12, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isPerformanceFee', controlType: 9, label: 'performance_fee', viewname: 'isPerformanceFee', name: 'isPerformanceFee', order: 13, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isRedemptionPayable', controlType: 9, label: 'redemption_payable', viewname: 'isRedemptionPayable', name: 'isRedemptionPayable', order: 14, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isCustodianReconciliation', controlType: 9, label: 'custodian_recon', viewname: 'isCustodianReconciliation', name: 'isCustodianReconciliation', order: 15, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isCashLedger', controlType: 9, label: 'cash_ledger', viewname: 'isCashLedger', name: 'isCashLedger', order: 16, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isQuantityAndCostRollForward', controlType: 9, label: 'cost_roll_forward', viewname: 'isQuantityAndCostRollForward', name: 'isQuantityAndCostRollForward', order: 17, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isCapitalSummaryCommitment', controlType: 9, label: 'capital-summary-commitment', viewname: 'isCapitalSummaryCommitment', name: 'isCapitalSummaryCommitment', order: 18, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isOperatingExpense', controlType: 9, label: 'operating_expense', viewname: 'isOperatingExpense', name: 'isOperatingExpense', order: 19, value: true, binary: "True", class: "col-lg-6", style: "display:block" },
      { id: 'isAccountStatement', controlType: 9, label: 'account_statement', viewname: 'isAccountStatement', name: 'isAccountStatement', order: 20, value: true, binary: "True", class: "col-lg-6", style: "display:block" },

    ]
  }

  //key: portforlioTurnoverRatio value:Portfolio Turnover Ratio
  portforlioTurnoverRatio() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: portData value:Port Data Report
  portData() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  //key: portData value:  TotalgainlossFxGainLossOnCash Report
  TotalgainlossFxGainLossOnCash() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 pl-5 pr-10 pt-0 pb-15 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors pt-0" },
    ]
  };
//key: comission value: Comission // accounting
comission() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'fund', controlType: 7, label: 'Fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
    { id: 'symBol', controlType: 7, label: 'SymBol', name: 'symBol', viewName: 'symBol', order: 1, value: null, required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
    { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
    { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
    { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
  ]
};

 //key: capitalAndPLByInvestor value: Capital and PL By Investor  //investor
 capitalAndPLByInvestor() {
  return [
    { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
    { id: 'group', controlType: 7, label: 'fund_group_name', name: 'group', viewName: 'group', order: 1, value: null, required: true, fill:this.reportinggroupList, appearance: "fill", disabled: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
    { id: 'fundList', controlType: 7, label: 'fund_name', viewname: 'fundList', name: 'fundList', order: 2, value: [], required: true, fill: null, readonly: false, class: "col-lg-6  ml-3 dropdown-bgcolors" },
    { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 4, value: '', required: true, class: "col-lg-6 mb-c" },
    { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
    { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 6, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
  ]
};
  cashBalanceReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'custodianList', controlType: 7, label: 'select_custodian', viewname: 'custodianList', name: 'custodianList', order: 2, value: null, required: false, fill: this.custodianList, readonly: false, class: "col-lg-6 ml-3 dropdown-bgcolors" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.fileFrtTypeList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  //key: customizedPosition value: Customized Position
  customizedPosition() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'Date', viewname: 'endDate', name: 'endDate', order: 2, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

  //key: managementFeesReport value: Query - Management Fee Report  //query  
managementFeesReport () {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.pdfFileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };

//key: positionRecMultiPartyReport value: Position Rec Multi Party Report
  positionRecMultiPartyReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund Name', name: 'fund', viewName: 'fund', order: 1, value: null, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
     { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: false, fill: this.pdfCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
    ]
  };
  positionReportFixedIncome() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'Fund Name', name: 'fund', viewName: 'fund', order: 1, value: null, required: false, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: '', required: true, class: "col-lg-6" },
     { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: false, fill: this.pdfCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
    ]
  };
  pnlAttributionReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 btm-ml3 dropdown-bgcolors", style: "display:block;" },
      { id: 'reportingType', controlType: 2, label: 'reporting_type', viewname: 'reportingType', name: 'reportingType', order: 2, value: this.ReportingTypeList[0].value,  appearance: "fill",required: true, fill: this.ReportingTypeList, readonly: false, class: "col-lg-6"},
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  interestReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: [this.batchfundlist[0]], required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
     { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
    ]
  };
  investorRollForwardReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'levelRollReport', controlType: 2, label: 'level', name: 'level', viewName: 'level', order: 1, value: this.levelList[0].value, required: true, fill: this.levelList, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'rollReportFund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'investor', controlType: 7, label: 'investor', name: 'fund', viewName: 'fund', order: 1, value: null, required: false, fill: null, appearance: "fill", disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
     { id: 'fileType', controlType: 7 , label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.excelPdfList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },   
    ]
  };
  attributionReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'levelRollReport', controlType: 2, label: 'level', name: 'level', viewName: 'level', order: 1, value: this.levelList[0].value, required: true, fill: this.levelList, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'rollReportFund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: null, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'investor', controlType: 7, label: 'investor', name: 'fund', viewName: 'fund', order: 1, value: null, required: false, fill: null, appearance: "fill", disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors", style: "display:none;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-6" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
    ]
  };
  dividendDistributionReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fund', order: 1, value: this.batchfundlist[0].value, required: true, fill: this.batchfundlist, appearance: "fill", disabled: false, class: "col-lg-6 ", style: "display:block;" },
      { id: 'startDate', controlType: 3, label: 'Start Date', viewname: 'startDate', name: 'startDate', order: 2, value: this.startDate, required: true, class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'End Date', viewname: 'endDate', name: 'endDate', order: 5, value: this.endDate, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.xlsfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },
      ];
  }
  pandlBySymbolReport() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 7, label: 'fund', viewname: 'fund', name: 'fund', order: 1, value: [], required: true, appearance: "fill", fill: this.batchfundlist, readonly: false, class: "col-lg-12 pl-5 pr-10 pt-0 pb-15 dropdown-bgcolors" },
      { id: 'startDate', controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 2, value: '', required: true, appearance: "fill", class: "col-lg-6" },
      { id: 'endDate', controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: '', required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 4, value: null, required: true, fill: this.csvfileList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors pt-0" },
    ];
  };
  
  repTyp01() {
    return [
      { id: 'batchEndDater', controlType: 3, label: 'date', viewname: 'batchStartDate', name: 'batchStartDate', order: 4, value: this.sysNewDate, required: false, readonly: false, appearance: "fill", disabled: false, class: "col-lg-12" },
    ]
  };

  //key: comission value: Investor Statement Consolidate Detailed
  investorStatementConsolidateDetailed() {
    return [
      { id: 'batchReportId', controlType: 2, label: 'Report Type', viewname: 'batchReportId', name: 'batchReportId', order: 3, value: null, required: true, fill: this.batchReportList, disabled: false, class: "col-lg-12" },
      { id: 'investorList', controlType: 2, label: "investor", viewname: 'investorList', name: 'investorList', order: 1, value: [], required: true, fill: this.investorList, class: "col-lg-12", selectionLimit: 10 },
      { id: 'fund', controlType: 7, label: "fund", viewname: 'fund', name: 'fund', order: 1, value: [], required: true, fill: null, appearance: "fill", disabled: false, class: "col-lg-6 consolidate-input" },
      { id: 'reportingType', controlType: 2, label: "Report Type", viewname: 'reportingType', name: 'reportingType', order: 3, value: [], required: true, fill: this.reportTypeForConsolidate, class: "col-lg-6 consolidate-input" },
      { id: 'startMonth', controlType: 8, label: 'Month Year', viewname: 'startMonth', name: 'startMonth', order: 3, value: null, required: true, class: "col-lg-12" },
      { id: 'fileType', controlType: 7, label: 'File Type', viewname: 'fileType', name: 'fileType', order: 2, value: null, required: true, fill: this.pdfCsvList, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors" },

    ]
  };

  //key: incomeStatement value: Income Statement
  repTyp02() {
    return [
      { id: 'batchStartDater', controlType: 3, label: 'start_date', viewname: 'batchStartDate', name: 'batchStartDate', order: 3, value: this.sysOldDate, required: false, readonly: false, appearance: "fill", disabled: false, class: "col-lg-6" },
      { id: 'batchEndDater', controlType: 3, label: 'end_date', viewname: 'batchEndDate', name: 'batchEndDate', order: 4, value: this.sysNewDate, required: false, readonly: false, appearance: "fill", disabled: false, class: "col-lg-6 mb-10" },
    ]
  };

  getBasicDetails() {
    return new Promise(resolve => {
      this.fetchMultipleApiForBasicDetails().subscribe(res => {
        this.batchfundlist = res[0].resultList!=null?res[0].resultList.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id },alias: element.fundAlias })):[];
        let basicTemp = this.basicDetails();
        resolve(basicTemp);
      })
    })
  }

  getrepTyp01Details() {
    return new Promise(resolve => {
      let repotTemp = this.repTyp01();
      resolve(repotTemp);
    })
  }

  getReportDetailsCom() {
    return new Promise(resolve => {

      this.fetchMultipleApiForBasicDetails().subscribe(res => {
        this.batchfundlist = res[0].resultList!=null?res[0].resultList.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id },alias: element.fundAlias })):[];
        let repotTemp = this.reportDetailsCom();
        resolve(repotTemp);
      })
    })
  }
  getPeportContDetails() {
    let data = {
      type: 'PrimaryAssetClasses'
    }
    return new Promise(resolve => {
      this.fetchMultipleApi(data).subscribe(res => {
        this.batchReportList = res[1].data.map(element => ({ label: element.value, value: element.key }))
        this.batchReportList.forEach((element, index) => {
          if( element.value =='mangementFeeExpense'){
              this.batchReportList.splice(index, 1);
          }  
        });
      for(let i=0;i<this.batchReportList.length ;i++){
          if( this.batchReportList[i].value =='openlotsW/TL'){
            this.batchReportList[i].value='openLotsWTL';
          } 
          if( this.batchReportList[i].value =='investorCapitalSummary-Custom'){
            this.batchReportList[i].value='investorCapitalSummaryCustom';
          }   
        }
     
       
        let recordTemp = this.reportCont();
        resolve(recordTemp);
      })
    })
  }

  dropDownJSonKeyCalling(key) {
    return new Promise(resolve => {
      if (key == 'accountTrailBalance' || key == 'activityReportController' || key == 'accountStatementReportController' || key == 'accountcashLedger' || key == 'accountoperatingExpensesCntrlr'
        || key == 'accountBalanceSheetController' || key == 'accountPositionReportCtrl' || key == 'generalLedgerAccountReport' || key == 'accountPositionReportWithRealizedGain' || key == 'accountIncomeStatement') {
        let repotTemp = this[key]();
        this.fetchMultipleApiForReReports().subscribe(res => {
          this.AccountTrialBalanceTypeLst = res[0]?.resultList.map(element => ({ label: element.nameOfConfiguration, value: element }));
          let defaultValue;
          this.AccountTrialBalanceTypeLst.forEach(el => {
            if (el.label == "Default") {
              defaultValue = el.value
            }
          })
          this.realEstateEntityType = res[1].resultList.map(element => ({ label: element?.fields[0]?.value, value: element?.fields[0]?.value }));
          this.booksList = res[2]?.resultList.map(element => ({ label: element?.fields[0]?.value, value: element?.fields[0]?.value }));
          if (key == 'accountBalanceSheetController') {
            repotTemp[2] = { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'reportingLevel', order: 1, value: defaultValue, required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" }
            repotTemp[5] = { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
              repotTemp[6] = { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 1, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " }
          }
          else {
            repotTemp[1] = { id: 'reportingType', controlType: 2, label: 'reporting_View', viewname: 'reportingLevel', name: 'reportingLevel', order: 1, value: defaultValue, required: false, fill: this.AccountTrialBalanceTypeLst, readonly: false, class: "col-lg-6", style: "display:block;" }
            repotTemp[4] = { id: 'realEstateEntityValue', controlType: 7, label: 'real_estate_entity_type', viewname: 'realEstateEntityValue', name: 'realEstateEntityValue', order: 3, value: '', required: true, fill: this.realEstateEntityType, readonly: false, disabled: false, class: "col-lg-6 ml-3" },
              repotTemp[5] = { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 1, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " }
          }
          resolve(repotTemp);
        })
      }
      else if (key == 'varianceReport') {
        let repotTemp = this[key]();
        this.fetchMultipleApiForReVarianceReports().subscribe(res => {

          this.entityList = res[0]?.map(element => ({ label: element.fundName, value: element.fundName }));
          this.booksList = res[1]?.resultList.map(element => ({ label: element?.fields[0]?.value, value: element?.fields[0]?.value }));

          repotTemp[1] = { id: 'entityList', controlType: 7, label: 'entity_list', viewname: 'entityList', name: 'entityList', order: 1, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-6  ml-3" }
          repotTemp[2] = { id: 'booksList', controlType: 7, label: 'books', viewname: 'bookValue', name: 'bookValue', order: 1, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-6 ml-3 " }

          resolve(repotTemp);
        })
      }
      else if (key == 'dailyCashReport') {
        let repotTemp = this[key]();
        this.fetchMultipleApiForReVarianceReports().subscribe(res => {
          this.entityList = res[0]?.map(element => ({ label: element.fundName, value: element.id }));
          this.booksList = res[1]?.resultList.map(element => ({ label: element?.fields[0]?.value, value: element?.fields[0]?.value }));
          repotTemp[1] = { id: 'fundNameList', controlType: 7, label: 'entity_list', viewname: 'fundNameList', name: 'fundNameList', order: 2, value: '', required: true, fill: this.entityList, readonly: false, class: "col-lg-6  ml-3" },
          repotTemp[3] = { id: 'bookValueList', controlType: 7, label: 'books', viewname: 'bookValueList', name: 'bookValueList', order: 3, value: '', required: true, fill: this.booksList, readonly: false, disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors " },
          resolve(repotTemp);
        })
      }
      else if (key == 'positionSummary') {
        let repotTemp = this[key]();
        this.fetchProgramList().subscribe(res => {
          this.amcList = res.resultList.map(element => ({ label: element.entityName, value: { "id": element.id, name: element.entityName } }));
          repotTemp[1] = { id: 'programposition', controlType: 7, label: 'Program', name: 'program', viewName: 'programposition', order: 1, value: null, required: true, fill: this.amcList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors" }
          resolve(repotTemp);
        })
      }
      else {
        this.fetchMultipleApiForBasicDetails().subscribe(res => {
          if (key == 'perfTable') {
            this.batchfundlist = res[6].resultList.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id }, alias: element.fundAlias }));
          } else {
            if (key == 'cashLedger' || key == 'comission' || key == 'AnnualImpactNoteStatement' || key == 'incomeStatementNFM') {
              this.batchfundlist = res[0].resultList.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id }, alias: element.fundAlias }));
            } else {
              this.batchfundlist = res[0].resultList.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id }, alias: element.fundAlias }));
            }
          }

          this.reportinggroupList = res[1].resultList.map((element) => ({ label: element.fundGroup, value: { "id": element.id } }));
          this.programList = res[2].resultList.map((element) => ({ label: element.entityName, value: { "id": element.id } }));
          this.assetList = res[3].resultList.map((element) => ({ label: element.name, value: { "id": element.id } }));
          this.leadgerAccountList = res[4].resultList.map((element) => ({ label: element.nameOfGLAccount, value: { "id": element.id } }));
          this.sectorList = res[4].resultList.map((element) => ({ label: element.sector, value: { "id": element.sector } }));
          let repotTemp = this[key]();
          if (key == 'cashLedger') {
            let data = { fundId: this.batchfundlist[0].value.id }
            this.fetchCustodianList(data).subscribe(res => {
              let custodianList = res?.resultList.length != 0 ? res?.resultList.map(element => ({ label: element.accountNumber, value: { "id": element.id } })) : [];
            repotTemp[2] = { id: 'coustodianAccount', controlType: 7, label: 'Custodian Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 4, value: null, required: true, fill: custodianList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" };
              resolve(repotTemp);
            })
          }
          else if (key == "totalGainLossViewReport") {
            this.batchfundlist = res[0].resultList.map(element => ({ label: element.fundName, value: { "id": element.id }, alias: element.fundAlias }));
            let data = { fundId: this.batchfundlist[0].value.id }
            this.fetchCustodianList(data).subscribe(res => {
              let custodianList = res?.resultList.length != 0 ? res?.resultList.map(element => ({ label: element.accountNumber, value: { "id": element.accountNumber } })) : [];
              repotTemp[2] = { id: 'coustodianAccount', controlType: 2, label: 'Broker Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 4, value: null, required: false, fill: custodianList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" };
              resolve(repotTemp);
            })
          }
          else if (key == 'investorStatementAifWithRor2') {
            let reportTemp = this[key]();
            this.fetchMultipleApiBasicForROR2().subscribe(res => {
              this.investorList = res[0].resultList.map(element => ({ label: element.name, value: { "id": element.id } }));
              this.investorList.splice(0, 1);
              this.fundList = res[1].resultList.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
               reportTemp[1] = { id: 'investorListRor', controlType: 7, label: "investor", viewname: 'investorListRor', name: 'investorListRor', order: 2, value: [], selectionLimit: 10, required: true, fill: this.investorList, class: "col-lg-12 ml-3 dropdown-bgcolors" },
               reportTemp[2] = { id: 'fund', controlType: 2, label: "fund", viewname: 'fund', name: 'fund', order: 1, value: null, required: true, fill: this.fundList, class: "col-lg-12" }
              resolve(reportTemp);
            })
          }
          else if (key == "tradeBreakReport") {
            this.batchfundlist = res[0].resultList.map(element => ({ label: element.fundName, value: { "id": element.id }, alias: element.fundAlias }));
            let data = { fundlist: [this.batchfundlist[0].value.id] }
            this.getMultiAccountNumber(data).subscribe(res => {
              let custodianList = res?.length != 0 ? res.map(element => ({ label: element.accountNumber, value: { "id": element.id } })) : [];
              repotTemp[2] = { id: 'custodianAccount', controlType: 7, label: 'Broker Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 4, value: null, required: true, fill: custodianList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" };
              resolve(repotTemp);
            })
          }
          else if (key == 'generalLedgerAccount') {
            let data = { fundId: this.batchfundlist[0].value.id }
            this.fetchGlLedgerList(data).subscribe(res => {
              let custodianList = res[0]?.resultList.length != 0 ? res[0]?.resultList.map(element => ({ label: element.accountNumber, value: { "id": element.id } })) : [];
            repotTemp[2] = { id: 'coustodianAccount', controlType: 7, label: 'Custodian Account', name: 'coustodianAccount', viewName: 'coustodianAccount', order: 4, value: null, required: true, fill: custodianList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" };
              this.leadgerAccountList = res[1].resultList.map((element) => ({ label: element.nameOfGLAccount, value: { "id": element.id } }));
              repotTemp[4] = { id: 'ledgerAccount', controlType: 7, label: 'Ledger Account', name: 'ledgerAccount', viewName: 'ledgerAccount', order: 3, value: null, required: true, fill: this.leadgerAccountList, appearance: "fill", disabled: false, class: "col-lg-12 ml-3 dropdown-bgcolors", style: "display:block;" }
              resolve(repotTemp);
            })
          }
          else if (key == 'comission') {
            let data = { fundList: [this.batchfundlist[0].value.id] }
            this.symbolList(data).subscribe(res => {
              let symbolList = res?.resultList.length != 0 ? res?.resultList.map(element => ({ label: element.ticker, value: { "id": element.ticker } })) : [];
              repotTemp[2] = { id: 'symBol', controlType: 7, label: 'SymBol', name: 'symBol', viewName: 'symBol', order: 1, value: null, required: true, fill: symbolList, appearance: "fill", disabled: false, class: "col-lg-6 ml-3 dropdown-bgcolors", style: "display:block;" };
              resolve(repotTemp);
            })
          }
          else {
            resolve(repotTemp);
          }

        })
      }

    })
  }

getDateFormat(formData){
  Object.keys(formData).forEach(x => {
    switch (x) {
      case "startDate":
          formData['pnlDate'] = this.commonService.getStartDate(formData['startDate']) + 'LHRXJ10EWRdateZRHXJ10JFK' + (formData['endDate'] == undefined ? this.commonService.getEndDate(formData['startDate']) : this.commonService.getEndDate(formData['endDate']))
        break;
      case "endDate":
          formData['pnlDate'] = (formData['startDate'] == undefined ? this.commonService.getStartDate(formData['endDate']) : this.commonService.getStartDate(formData['startDate'])) + 'LHRXJ10EWRdateZRHXJ10JFK' + this.commonService.getEndDate(formData['endDate'])
        break;
    }
  });
  return formData['pnlDate'];
}



  fetchMultipleApi(data: any): Observable<any> {
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise');
    let response2 = this.http.get<any>('batchV2/getUserReports');
    let response3 = this.http.post<any>('lookupV2/getLookupValuesByTypeAndEnterprise', data);
    return forkJoin([response1, response2, response3]);
  }

  fetchMultipleApiForBasicDetails(): Observable<any> {
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise');
    let response2 = this.http.get<any>('fundGroupV2/getfundGroup');
    let response3 = this.http.get<any>('assetManagementV2/fetchAllByUserFund');
    let response4 = this.http.get<any>('assetClassV2/fetchAllRecords');
    let response5 = this.http.get<any>('chartOfAccountsV2/fetchAllByEnterprise');
    let response6 = this.http.get<any>('PNLContributionReportV2/fetchDistinctSector');
    let response7 = this.http.get<any>('fund/fetchAllFundEnterprise');
    return forkJoin([response1, response2, response3,response4,response5,response6,response7]);
  }
  getFundDetails(id) {
    return this.http.get<any>('fundGroupV2/getMultipleFundByFundGroupId?id=' + id, this.httpOptions);
  }

  getBatchId(data): Observable<any> {
    return this.http.post<any>('batchV2', data);
  }

  fetchGroupList(): Observable<any> {
    return this.http.get<any>('fundGroup/getfundGroup');
  }
  fetchProgramList(): Observable<any> {
    return this.http.get<any>('assetManagement/fetchAllByUserFund');
  }
  fetchfundList(): Observable<any> {
    return this.http.get<any>('fundV2/fetchAllByEnterprise');
  }
  fetchDateFormat(): Observable<any> {
    return this.http.get<any>('alteryxWorkflowMngtV2/getDateFormat');
  }
  fetchCustodianApiBasic(data: any): Observable<any> {
    return this.http.post('brokerAccountV2/filterByFundIdList', data);
  }
  fetchCustodianList(data): Observable<any> {
    return this.http.post<any>('brokerAccountV2/filterByFundId/', data);
  }
  fetchGlLedgerList(data): Observable<any> {
    let response1 = this.http.post<any>('brokerAccountV2/filterByFundId/', data);
    let response2 = this.http.post<any>('generalLedger/fetchAllByEnterprise', data);
    return forkJoin([response1, response2]);
  }
  fetchCustodianMultipleFundList(id): Observable<any> {
    return this.http.get<any>('brokerAccount/filterByMultipleGroup?fundGroupIds=' + id);
  }
  fetchInvestorList(id): Observable<any> {
    return this.http.get<any>('investor/filterByFundId?id=' + id);
  }
  findDefaultInvestorReport(id): Observable<any> {
    return this.http.get<any>('fund/findDefaultInvestorReport?id=' + id);
  }
  symbolList(data): Observable<any> {
    return this.http.post<any>('CommissionReportController/getSymbolList', data);
  }
  fetchGroupFundList(id): Observable<any> {
    return this.http.get<any>('fundGroup/filterByFundGroupId?id=' + id);
  }
  filterByFundIdForAC(id): Observable<any> {
    return this.http.get<any>('investor/filterByFundIdForAC?id=' + id);
  }
  filterByAMC(id): Observable<any> {
    return this.http.get<any>('fundV2/filterByAMCId?id=' + id);
  }
  getFundsByAMC(data): Observable<any> {
    return this.http.post<any>('fund/getFundsByAMC', data);
  }
  getMultiAccountNumber(data): Observable<any> {
    return this.http.post<any>('brokerAccount/getMultipleAccountNumber', data);
  }
  saveData(data): Observable<any> {
    return this.http.post<any>('batchV2/batchConfigAddReport', data);
  }

  updateData(data): Observable<any> {
    return this.http.post<any>('alteryxWorkflowMngtV2/create', data);
  }

  batchUpdate1(data): Observable<any> {
    return this.http.put('batchV2/update/' + data.id, data);
  }
  batchscheduleUpdate(data): Observable<any> {
    return this.http.put('batchV2/update/' + data.id, data);
  }
  updateSchedule(data): Observable<any> {
    return this.http.post<any>('batchV2/schedule', data);
  }

  deleteData(id): Observable<any> {
    return this.http.delete<any>("/batchV2/delete?id=" + id);
  }
  batchUpdate(id, data): Observable<any> {
    return this.http.put<any>('batchV2/update/' + id, data);

  }
  updateSchedules(data,isActive): Observable<any> {
    return this.http.put<any>('batchV2/schedule/' + data , isActive);
  }
  batchUpdate2(id:any,data:any): Observable<any> {
    return this.http.put('batchV2/' + id, data, { observe: 'response', responseType: 'arraybuffer' });
  }
  investorDetails(id) {
    return this.http.get<any>('investorV2/filterByFundIds?id=' + id, this.httpOptions);
  }
  checkCsdStatus(data: any): Observable<any> {
    return this.http.post('getPendingCapitalActivity', data);
  }
  eodIncomes(id) {
    return this.http.post<any>('eodChecksReportV2/getCapitalActivityVsIncomeAllocation', id);
  }
  checkFundDate(data: any): Observable<any> {
    return this.http.post('fundV2/setFundPeriodLockDate/', data);
  }
  filterByFundIds(id): Observable<any> {
    return this.http.post<any>('investorV2/filterByFundIds' ,id);
  }
  investorGroupDetails(id){
    return this.http.get<any>('InvestorGroupV2/getInvestorGroupByFund/' + id, this.httpOptions);
  }
  getFundByInvestor(id: any): Observable<any> {
    return this.http.get('investorV2/getFundMapWithUniqueInvestorAccountNumberList/' + id);
  }

  fetchMultipleApiForReReports(): Observable<any> {
    let data = {
      type: "RealEstateEntityType",
      enterpriseId: this.currentUser.enterpriseId
    }
    let bookdata = {
      type: "Books",
      enterpriseId: this.currentUser.enterpriseId
    }
    let response1 = this.http.get<any>('setupRealEstateConfig/fetchAllRecords/'+this.isEnterprise, this.httpOptions)
    let response2 = this.http.post<any>('lookupV2/getLookupValuesByTypeAndEnterprise/', data);
    let response3 = this.http.post<any>('lookupV2/getLookupValuesByTypeAndEnterprise/', bookdata);
    return forkJoin([response1, response2, response3]);
  }

  setupRealEstateConfigfindById(id: any): Observable<any> {
    return this.http.get('setupRealEstateConfig/findById/' + id+'/'+this.isEnterprise);
  }
  getREfundNameByLevel(data): Observable<any> {
    return this.http.post<any>('fundV2/getREfundNameByLevel', data);
  }
  getFundNameByEntityGroup(data): Observable<any> {
    return this.http.post<any>('fundV2/getFundNameByEntityGroup', data);
  }
  filterByFundGroupdata(data): Observable<any> {
    return this.http.post<any>('lookupV2/getFundNameByBooksAndProperty', data);
  }
  filterByFundGroupId(id: any): Observable<any> {
    return this.http.get('fundGroup/filterByFundGroupId?id=' + id);
  }
  getFundByGroup(id: any): Observable<any> {
    return this.http.get('fundGroupV2/getMultipleFundByFundGroupId?id=' + id, this.httpOptions);
  }
  fetchCustodianListReReport(data): Observable<any> {
    return this.http.post<any>('brokerAccountV2/getMultipleAccountNumber/', data);
  }
  fetchCashLedgerReReportList(data): Observable<any> {
    return this.http.post<any>('accountGeneralLedger/getCOAlistByFundInJE', data);
  }
  generalLedgerAccountReReportApiSubmit(data): Observable<any> {
    return this.http.post<any>('accountGeneralLedger/excleDownloadGeneralLedgerCustomDataCreation/', data);
  }
  fetchMultipleApiForReVarianceReports(): Observable<any> {
    let dataVariance = "Fund Level"
    let bookdata = {
      type: "Books",
      enterpriseId: this.currentUser.enterpriseId
    }
    let response1 = this.http.post<any>('fundV2/getFundNameByEntityGroup', dataVariance)
    let response2 = this.http.post<any>('lookupV2/getLookupValuesByTypeAndEnterprise/', bookdata);
    return forkJoin([response1, response2]);
  }
  getBSGandISGByGroupName(data): Observable<any> {
    return this.http.post<any>('chartOfAccountsV2/getBSGandISGByGroupName', data);
  }

  getGridData(gridDataUrl, cbs, cbe) {
    this.http.get(gridDataUrl, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }

  getformControlsDetail() {
    return new Promise(resolve => {
       this.fetchMultipleApiBasic().subscribe((response) => {
        this.investorList = response[0].map(element => ({ label: element.investorAccountNumber, value: { "id": element.investorAccountNumber } }));
        let Data = this.investorStatementConsolidateDetailed();
        resolve(Data);
       })
      })
      }
  getCustomReport(){
    return new Promise(resolve => {

      let customeReportData = this.customReports();
      console.log(customeReportData);
      resolve(customeReportData);
    })
  }
  
  fetchMultipleApiBasic() {
    let response1 = this.http.get<any>('investorV2/getUniqueInvestorAccountNumberList', this.httpOptions);
    return forkJoin([response1]);
  }

  fetchMultipleApiBasicForROR2() {
    let response1 = this.http.get<any>('fundV2/getDistinctInvestors', this.httpOptions);
    let response2 = this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
    return forkJoin([response1, response2]);
  }  
}