/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { ConfirmationDialog } from '../../dialogs/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'fury-approve-workflow-button',
  templateUrl: './approve-workflow-button.component.html',
  styleUrls: ['./approve-workflow-button.component.scss']
})
export class ApproveWorkflowButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  displayBasic: boolean = false;
  private params: any;
  alteryxWorkflowStatus: any;
  public omsCancel: boolean;
  public omsApproveList: boolean;
  public omsEditList: boolean;
  moduleName: any;
  public overAllStatus: any;
  public executionId: any;

  constructor(private http: HttpClient,
    public translate: TranslateService, private dialog: MatDialog, private messageService: GlobalMessageService) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.overAllStatus = this.params.value;
    this.alteryxWorkflowStatus = this.params.data.alteryxWorkflowStatus;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
    this.executionId = this.params.data.alteryxWorkflowStatus ? this.params.data.alteryxWorkflowStatus.uniqueNumber : "" ;
  }

//   ProcessDetailsWorkflow(event) {
//     let selectedNode = this.params.node;
//     let selectedData = selectedNode.data;
//     if (this.params.ProcessDetailsWorkflow instanceof Function) {
//       const params = {
//         event: event,
//         rowData: selectedData
//       }
//       this.params.ProcessDetailsWorkflow(params);
//     }
//   }

//   ProcessCancelDetailsWorkflow(event) {
//     let selectedNode = this.params.node;
//     let selectedData = selectedNode.data;
//     if (this.params.ProcessCancelDetailsWorkflow instanceof Function) {
//       const params = {
//         event: event,
//         rowData: selectedData
//       }
//       this.params.ProcessCancelDetailsWorkflow(params);
//     }
//   }
// }

 /*
  Auth : Vg
  Proceed n Cancle Fixed
  Dc24
   */

ProcessDetailsWorkflow(event) {
const dialogRef = this.dialog.open(ConfirmationDialog, {
    data: {
      message: 'Are you sure you want to proceed ?',
      header:'Confirm Upload',
      buttonText: {
        ok: 'Yes',
        cancel: 'No'
      }
    }
  });
  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  if (confirmed) {
      if(this.executionId){
        this.http.get<any>('alteryxV2/uploadTempData?uniqueId=' + this.executionId).subscribe(response => {
          if(response.success){
            this.messageService.add({sticky:true, severity: 'success', summary: 'success', detail: 'Successfully Proceed.' });
            this.params.refershGrid();
          }
          else {
            this.messageService.add({sticky:true, severity: 'error', summary: 'Error', detail: response.message });
            // this.messageService.add({sticky:true, severity: 'error', summary: 'Error', detail: 'Error occurred!' });
          }
        })
    }
  }
 });
}

ProcessCancelDetailsWorkflow(event) {
    if(this.executionId){
      this.http.get<any>('alteryx/cancelTempData?uniqueId=' + this.executionId).subscribe(response => {
        if(response.success){          
          this.messageService.add({sticky:true, severity: 'success', summary: 'success', detail: 'Workflow Process Cancelled.' });
          this.params.refershGrid()
        }
        else {
          this.messageService.add({sticky:true, severity: 'error', summary: 'Error', detail: 'Error occurred!' });
        }
      })
    }
  }
}
