<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <fury-toolbar-sidenav-mobile-toggle (openSidenav)="openSidenav.emit()"></fury-toolbar-sidenav-mobile-toggle>

    <div [fxShow.gt-sm]="topNavigation$ | async" [fxShow.xs]="false" [routerLink]="['/']" class="toolbar-logo" fxShow>
    </div>

    <fury-toolbar-search-bar *ngIf="((currentUser.authList.indexOf('GLOBAL_SEARCH_HEADER')  > -1) ||
      (currentUser.authList.indexOf('ENTERPRISE_CREATE')  > -1)) && !isprogrees && !isMFASetup" fxFlex="380px" fxHide
      fxShow.gt-md>
    </fury-toolbar-search-bar>

    <div *ngIf="!showMfaEnable" class="MFAmessage">
      <span style="display: flex; justify-content: center; align-items: center;">
        <mat-icon style="overflow: visible;">report_problem</mat-icon>
        <span *ngIf="isMFASetup" matTooltip="Multi-Factor Authentication:
          We recommend that you set up MFA for more security.">
          <!-- <b class="text-success">Multi-Factor Authentication:</b>
          We recommend that you set up MFA for more security. -->
        </span>
        <a matTooltip="Multi-Factor Authentication:
        We recommend that you set up MFA for more security." [routerLink]="['/enableMfaSetup']">MFA Settings</a>
      </span>
    </div>
  </div>

  <div fxLayout="row">
    <div *ngIf="hostName != 'seamless.sudrania.com' && hostName != 'seamless.formidium.com'" class="theme_btn">
      <div class="button b2" id="button-13">
        <input type="checkbox" class="checkbox" [(ngModel)]="themeToggle"
          (click)="setActiveTheme(themeToggle?'fury-default':'fury-dark');">
        <div class="knobs">
          <span id="circle"></span>
        </div>
        <div class="layer"></div>
      </div>
    </div>

    <div *ngIf="hostName == 'seamless.formidium.com'" class="theme_btn" style="align-self: center;">
      <h3 class="switchtext">Switch back to <a href="https://seamlessclassic.sudrania.com/">Old View</a></h3>
    </div>

    <div class="text-center translatedrop toolbar-button">
      <select #langSelect (change)="switchLang(langSelect.value)">
        <option *ngFor="let language of translate.getLangs()" [value]="language"
          [selected]="language === changeLanguage">
          {{ language }}
        </option>
      </select>
    </div>
    <button (click)="onClick()" class="button" fxFlex="grow" mat-button type="button"
    matTooltip="{{'user_management'|translate}}">
    <mat-icon class="material-symbols-outlined">manage_accounts</mat-icon>
  </button>
    <mat-icon class="toolbar-button duplicateico" style=" line-height: normal; height: auto; padding: 0em 1.8em; align-items: center;
        justify-content: center; font-size: 18px;cursor: pointer;" (click)="renderDuplicateTab();"
      matTooltip="{{'duplicate'|translate}}">
      filter_none
    </mat-icon>

    <fury-toolbar-fullscreen-toggle class="toolbar-button"></fury-toolbar-fullscreen-toggle>

    <fury-toolbar-search fxHide fxShow.gt-sm fxHide.gt-md class="toolbar-button"></fury-toolbar-search>

    <button (click)="toggleDropdown1()" *ngIf="roleAs=='Manager'" class="button" fxFlex="grow" mat-button type="button"
      matTooltip="{{'period_lock_date'|translate}}">
      <mat-icon class="icon"> event_note </mat-icon>
    </button>

    <mat-icon *ngIf="currentUser.authList.indexOf('FUNDNOTES_VIEW') > -1" class="toolbar-button note"
      (click)="openNoteFund()" matTooltip="{{'fund_note'|translate}}">
      assignment
    </mat-icon>

    <fury-toolbar-training-videos
      *ngIf="currentUser.authList.indexOf('SEAMLESS_GLOBAL_HELPANDRESOURCE') > -1 &&  roleAs != 'SystemManager'"
      class="toolbar-button toolbar-upload" (autoplay)="autoplay($event)"></fury-toolbar-training-videos>

    <fury-toolbar-create
      *ngIf="((roleAs == 'SystemManager') || (currentUser.authList.indexOf('GLOBAL_CREATE') > -1 && !isprogrees))"
      class="toolbar-button toolbar-create"></fury-toolbar-create>

    <fury-toolbar-upload
      *ngIf="currentUser.authList.indexOf('GLOBAL_UPLOAD') > -1 && !isprogrees && roleAs != 'SystemManager'"
      class="toolbar-button toolbar-upload"></fury-toolbar-upload>

    <fury-toolbar-view
      *ngIf="((roleAs == 'SystemManager') || (currentUser.authList.indexOf('GLOBAL_VIEW') > -1 && !isprogrees))"
      class="toolbar-button toolbar-view"></fury-toolbar-view>

    <fury-toolbar-user class="toolbar-button"></fury-toolbar-user>
   
  </div>
 
</div>

<p-dialog class="delModal sessionmodal" [modal]="true" header="{{'ent_period_lock_date'|translate}}"
  [(visible)]="displayBasic" [style]="{width: '30vw'}" [baseZIndex]="10000">
  <div class="col-md-12">
    <br><br>
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label appRemoveAriaOwns> {{'period_lock_date'|translate}} </mat-label>
      <input matInput [(ngModel)]="startDate" [matDatepicker]="startDatePicker">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #startDatePicker></mat-datepicker>
    </mat-form-field>
  </div>
  <p-footer>
    <button class="btn-logout" (click)="hideDialogBox()"> {{'close'|translate}} </button>
    <button class="btn-logout" (click)="submitPeriodLock()"> {{'submit'|translate}} </button>
  </p-footer>
</p-dialog>