/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { CommonService } from '../../services/commonServices/common.service';
import { ProcessButtonService } from '../../../../../src/app/views/commonmodule/processingmodule/process-button.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from '../../services/commonServices/local-store.service';

@Component({
  selector: 'fury-resume-daily-processing-button',
  templateUrl: './resume-daily-processing-button.html',
  styleUrls: ['./resume-daily-processing-button.scss']
})
export class ResumeDailyProcessingButton  {

  public overAllStatus: any;
  private params: any;
  currentUser: any;
  constructor(private http: HttpClient,private messageService: GlobalMessageService,public translate: TranslateService, private commonService:CommonService,
    private processButtonService:ProcessButtonService, private localService : LocalStoreService) { 
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    }
  availableFunds: any[];


  agInit(params: ICellRendererAngularComp): void {
    this.params = params;   
    this.overAllStatus = this.params.value ? this.params.value : null; 
  }


  resumeDailyProcessing(event) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;

    if (this.params.resumeDailyProcessing instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.resumeDailyProcessing(params);
    }
  }




getFundPeriodLockDate(fundId) {
  let fundPeriodLockDate;
    for (var v = 0; v < this.processButtonService.fundsArr.length; v++) {
      if (this.processButtonService.fundsArr[v].id == fundId) {
        fundPeriodLockDate =this.processButtonService.fundsArr[v].fundPeriodLockDate;
      }
    }

    return fundPeriodLockDate
}
  

resumeCompilance(event) {
  if (this.params.data.startDate) {
    for (var v = 0; v < this.params.data.fundList.length; v++) {     
      var result = this.commonService.getPeriodLockmessage(this.params.data.startDate,  this.getFundPeriodLockDate(this.params.data.fundList[v]), this.currentUser.periodLockDate)
      if (result == false) {
        break;
      }
    }
    if (result == false) {
      return;
    }
  }
  this.http.get<any>('dataProcessingResuming/resume/' + this.params.data.id).subscribe(response =>{     
    if(response && response.message){
      if(response.message == "Machine already working for this record."){
        this.messageService.add({sticky:true,   severity: 'warn', summary: this.translate.instant('warning'), detail: this.translate.instant(response.message)  });

       }else if(response.message == "Reset Open TaxLot not run properly"){
        this.messageService.add({sticky:true,   severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(response.message ) });

    }else{
      this.messageService.add({sticky:true,   severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('resume_process_initiated_successfully_now') });

      }    }
 })

}
}
