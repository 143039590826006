/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, forkJoin } from 'rxjs';

import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { apiConstants } from 'src/app/constants/apiConstants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ProcessScreenService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  public proceedBtnStatus: boolean = false;
  constructor(
    private http: HttpClient,
    private messageService: GlobalMessageService,
    public translate: TranslateService,
    private cookieService:CookieService

  ) { }

  fetchAllByEnterprise() {
    let response1 = this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
    return forkJoin([response1]);
  }

  fetchFundList(): Observable<any> {
    return this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
  }
  batchList() {
    let response1 = this.http.get<any>('reportDeliveryWorkflowV2/getBacthListWorkflow', this.httpOptions);
    return forkJoin([response1]);
  }

  deliveryChecklistResponses(): Observable<any> {
    return this.http.get<any>('reportDeliveryChecklistV2/getAllCheckListData', this.httpOptions);
  }
  getProcessList(): Observable<any> {
    return this.http.get<any>('reportDeliveryConfigurationV2/configurations/getProcessList', this.httpOptions);
  }
  getBrokerDetails(data: any): Observable<any> {
    return this.http.post('brokerAccountV2/getMultipleAccountNumber', data);
  }

  getBatchExecutionLog(id: any): Observable<any> {
    return this.http.get('batchV2/getBatchExecutionLog?batchId=', id);
  }


  downloadZipWithCsv(componentName, searchObject) {
    
    var res = {"searchObj":searchObject};
    let url = apiConstants[componentName].exportURL.url+'?moduleName='+ apiConstants[componentName].moduleName;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get('access_token');
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/zip');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'arraybuffer';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "confirmed", detail: 'Something went wrong with download, please try again.' });
        } else {
          var fileName = apiConstants[componentName].moduleName + "Filtered.zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "Error", detail: 'Something went wrong with download, please try again.' });
      }
    })
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    request.send(JSON.stringify(res));
  }
  dataExport(name: any, searchObj: any, url: any) {
    let message = name ? name : "Confirmed";
    let tmessage = message.toLocaleLowerCase();
    let infos = null;
    if (tmessage.includes('fail')) {
      message = 'Info'
      infos = 'warn'
    }

    var reqObj = searchObj;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get('access_token');

    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        this.messageService.add({sticky:true,severity: infos != null ? infos : 'success', summary: message, detail: 'Please wait while we download the file' });
        if (!request.response || !request.response.size) {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
        } else {
          var fileName: any = 'executeworkflow.xlsx'
          var disposition: any = request.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          var blob = request.response;
          var downloadLink: any = window.document.createElement('a');
          var contentTypeHeader: any = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
      else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('export_incomplete_contact_support') });
      }
    })
    request.send(JSON.stringify(reqObj));
  }
  setBtnStatus(status:boolean){
    this.proceedBtnStatus = !status;
  }
  getBtnStatus(){
    return this.proceedBtnStatus;
  }

}
