/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { DatePipe } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CellEditingStoppedEvent, CellValueChangedEvent, GridOptions } from 'ag-grid-community';
import { CommonService } from '../../services/commonServices/common.service';
import { GridService } from '../../services/gridServices/grid.service';
import moment from 'moment-timezone';
import { Calendar } from 'primeng/calendar';
import { ViewBtnCellRenderer } from '../../buttons/view-button-cell-renderer.component';
import { RunAlertThresholdButton } from '../../buttons/run-alert-threshold-button/run-alert-threshold-button';
import { AccountSummaryDateComponent } from '../../buttons/account-summary-date/account-summary-date.component';


import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../../dialogs/confirmation-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { OverAllStatusButton } from '../../buttons/over-all-status-button/over-all-status-button.component';
import { DownloadTempButton } from '../../buttons/download-temp-button/download-temp-button.component';
import { CaDownloadFileButton } from '../../buttons/Capital-Activity-download-button/Capital-Activity-download-button.component';

import { ApproveWorkflowButton } from '../../buttons/approve-workflow-button/approve-workflow-button.component';
import { RunDataImportErrorFileButton } from '../../buttons/run-data-import-error-file-button/run-data-import-error-file-button.component';

import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalService } from '../../services/commonServices/global.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { get } from 'lodash';
import { SendMailClientTemplate } from '../../buttons/send-mail-client-template/send-mail-client-template.component';
import { GrdbtnErrRowDownComponent } from '../../buttons/grdbtn-row-down/grdbtn-row-err-down.component';
import { GrdbtnPrvRowDownComponent } from '../../buttons/grdbtn-row-down/grdbtn-row-prv-down.component';
import { ApproveWorkflowStatusButton } from '../../buttons/approve-workflow-status-button/approve-workflow-status-button.component';
import { GrdbtnRowEditComponent } from '../../buttons/grdbtn-row-edit/grdbtn-row-edit.component';

import { GridHederBtnComponent } from '../../buttons/grid-heder-btn/grid-heder-btn.component';
import { AttachmentDownloadButton } from '../../buttons/attachment-download-button/attachment-download-button.component';
import { CheckboxRenderer } from '../../buttons/checkbox-renderer.component';
import { GrdbtnPendingDataComponent } from '../../buttons/grdbtn-row-down/grdbtn-row-pending-data.component';
import { GrdSchdBtnComponent } from '../../buttons/grd-schd-btn/grd-schd-btn.component';
import { BatchSessionButton } from '../../buttons/batch-session-button/batch-session-button.component';
import { environment } from '../../../../environments/environment';
import { fadeInRightAnimation } from '../../../../@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../../../@fury/animations/fade-in-up.animation';
import { ResumeDailyProcessingButton } from '../../buttons/resume-daily-processing-button/resume-daily-processing-button';
import { RefreshButton } from '../../buttons/refresh-button/refresh-button.component';
import { DetailsComponent } from '../../../views/batch-tracking/details/details.component';
import { BatchDownloadComponent } from '../../../views/batch-tracking/batch-download/batch-download.component';
import { BatchScheduleButtonComponent } from '../../../views/batch-tracking/batch-schedule-button/batch-schedule-button.component';
import { BatchBtnSchduleComponent } from '../../../views/batch-tracking/batch-btn-schdule/batch-btn-schdule.component';
import { apiConstants } from '../../../constants/apiConstants';
import { ExecuteWorkFlowRunComponent } from '../../../views/commonmodule/processingmodule/execute-work-flow/execute-work-flow-run/execute-work-flow-run.component';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { CustomHeader } from 'src/app/views/commonmodule/processingmodule/execute-work-flow/headercomponent';
import 'ag-grid-enterprise';
import 'ag-grid-enterprise';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
} from 'ag-grid-community';
import { UploadButtonComponent } from '../../buttons/upload-button/upload-button.component';
import { LocalStoreService } from '../../services/commonServices/local-store.service';
import { takeUntil } from 'rxjs/operators';
import { ExecuteWorkFlowRunRapidComponent } from '../../../views/rapid/input/import/execute-work-flow/execute-work-flow-run/execute-work-flow-run.component';
import { IsRowSelectable } from 'ag-grid';
import { GridCellDatepicker } from '../../buttons/grid-Cell-datepicker/grid-cell-datepicker.component';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { IsdButtonComponent } from '../../buttons/isd-button/isd-button.component';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;
const api_url = environment['api_url'];
import { FundItem } from '../../services/commonServices/FundItem.interface'
import { ReClientUploadComponent } from '../../buttons/re-client-upload/re-client-upload.component';
import { FsGenerateComponent } from 'src/app/views/finance-statement/fs-generate/fs-generate.component';
import { DuplicateButton } from '../../buttons/duplicate-button/duplicate-button.component';
import { ButtonRendererComponent } from '../../buttons/edit-cell-button/edit-cell-button.component';
import { EditCellDateRenderer } from '../../buttons/edit-cell-date/edit-cell-date-editor-renderer.component';
import { EditCellDateEditor } from '../../buttons/edit-cell-date/edit-cell-date-editor.compoent';
import { EditCellDropDownRenderer } from '../../buttons/edit-cell-dropdown/edit-cell-dropdown-renderer.component';
import { EditCellDropDownEditor } from '../../buttons/edit-cell-dropdown/edit-cell-dropdown.component';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CookieService } from 'ngx-cookie-service';
import { EditCellNumberEditor } from '../../buttons/edit-cell-number/edit-cell-number-editor.compoent';
import { EditCellNumberRenderer } from '../../buttons/edit-cell-number/edit-cell-number-renderer.component';
import { SearchFilterModule } from '../../pipes/search-filter.pipe';
import { EditCellStringRenderer } from '../../buttons/edit-cell-string-editor/edit-cell-string-renderer.component';
import { EditCellStringEditor } from '../../buttons/edit-cell-string-editor/edit-cell-string-editor.component';
import { EditCellPasswordEditor } from '../../buttons/edit-cell-password-editor/edit-cell-password-editor.component';
import { EditCellPasswordRenderer } from '../../buttons/edit-cell-password-editor/edit-cell-password-renderer.component';
import { DetailCellRendererTable } from '../../buttons/detail-cell-renderer-table/detail-cell-renderer-table/detail-cell-renderer-table.component';
import { EditCellMultiSelectDropDownEditor } from '../../buttons/edit-cell-multi-select-dropdown/edit-cell-multi-select-dropdown.component';
import { EditCellMultiSelectDropDownRenderer } from '../../buttons/edit-cell-multi-select-dropdown/edit-cell-multi-select-dropdown-renderer.component';
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GridComponent implements OnInit, OnDestroy {

  UserRoles: any;

  // Grid Input Options
  currentUser = JSON.parse(this.localService.getItem('fss'));
  authList: any = this.currentUser['authList'];
  public roleAs: any = this.currentUser['ROLE']
  currentRole = this.authList.indexOf('ORDER_INITIATOR') > -1 ? 'initiator' : 'approver';
  isEnterprise = ((this.authList.indexOf('USER_CREATE') > -1 && this.authList.indexOf('USER_DELETE') > -1) ? true : false)
  @Output() getDataForOmsAllo: EventEmitter<any> = new EventEmitter();
  @Output() runDataStatus: EventEmitter<any> = new EventEmitter();
  @Output() newItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() newonSelect: EventEmitter<any> = new EventEmitter();
  @Output() newOrder: EventEmitter<any> = new EventEmitter();
  @Output() todayOrder: EventEmitter<any> = new EventEmitter();
  @Output() allocateOrder: EventEmitter<any> = new EventEmitter();
  @Output() undoCofirmOrder: EventEmitter<any> = new EventEmitter();
  @Output() orderApprove: EventEmitter<any> = new EventEmitter();
  @Output() orderAllocation: EventEmitter<any> = new EventEmitter();
  @Output() isfilter: EventEmitter<any> = new EventEmitter();
  @Output() runALertCompliance: EventEmitter<any> = new EventEmitter();
  @Output() runDailyProcessingCompliance: EventEmitter<any> = new EventEmitter();
  @Output() runProceeAllCompliance: EventEmitter<any> = new EventEmitter();
  @Output() batchSessionCompliance: EventEmitter<any> = new EventEmitter();
  @Output() resumeDailyProcessing: EventEmitter<any> = new EventEmitter();
  @Output() updateSchdule: EventEmitter<any> = new EventEmitter();
  @Output() errorStatusDetailsWorkflow: EventEmitter<any> = new EventEmitter();
  @Output() batchDetail: EventEmitter<any> = new EventEmitter();
  @Output() batchDownloadDetail: EventEmitter<any> = new EventEmitter();
  @Output() batchScduleDetail: EventEmitter<any> = new EventEmitter();
  @Output() batchScduleDetail2: EventEmitter<any> = new EventEmitter();
  @Output() updateBatch: EventEmitter<any> = new EventEmitter();
  @Output() batchDetailScdule: EventEmitter<any> = new EventEmitter();
  @Output() ProcessDetailsWorkflow: EventEmitter<any> = new EventEmitter();
  @Output() ProcessCancelDetailsWorkflow: EventEmitter<any> = new EventEmitter();
  @Output() confirmationUsersStatus: EventEmitter<any> = new EventEmitter();
  @Output() PendingDataWorkflow: EventEmitter<any> = new EventEmitter();
  @Output() reportNotisficationCompliance: EventEmitter<any> = new EventEmitter();
  @Output() viewrowDataCompliance: EventEmitter<any> = new EventEmitter();
  @Output() enterpriseRowEventCompliance: EventEmitter<any> = new EventEmitter();
  @Output() allUsersRowEventCompliance: EventEmitter<any> = new EventEmitter();
  @Output() sendMailClientTemplate: EventEmitter<any> = new EventEmitter();
  @Output() rowEditEventCompliance: EventEmitter<any> = new EventEmitter();
  @Output() gridSchRowEventCompliance: EventEmitter<any> = new EventEmitter();
  @Output() sudcomponentName = new EventEmitter<string>();
  @Output() openModel = new EventEmitter<boolean>();
  @Output() openMemoModel = new EventEmitter<any>();
  @Output() clientApprovalFileUpload: EventEmitter<any> = new EventEmitter();
  @Output() getISDClientApprovalId: EventEmitter<any> = new EventEmitter();
  @Output() investorSpecificDocument: EventEmitter<any> = new EventEmitter();
  @Output() activityUploadCompliance: EventEmitter<any> = new EventEmitter();
  @Output() activityPostTOGL: EventEmitter<any> = new EventEmitter();
  @Output() viewClientUploadRowData: EventEmitter<any> = new EventEmitter();
  @Output() paymentDetails: EventEmitter<any> = new EventEmitter();
  @Output() updatePayment: EventEmitter<any> = new EventEmitter();
  @Input() gridOptions: GridOptions = {
    suppressKeyboardEvent: (params) => {
      const { event, editing } = params;
      // Suppress Enter key press
      return true;
    }
  };
  @Input() enableRangeSelection: Boolean;
  @Input() enableCharts: Boolean;
  @Input() componentName: any;
  @Input() subComponent: any;
  @Input() heading: String;
  @Input() modalHeading: String;
  @Input() rowData: any[];
  @Input() groupSelectsChildren: Boolean;
  @Input() suppressRowClickSelection: Boolean;
  @Input() animateRows: Boolean;
  @Input() rowSelection: String;

  // Button Input Options
  @Input() isDeleteBtn: Boolean
  @Input() isExportBtn: Boolean
  @Input() isUploadBtn: Boolean
  @Input() isNewBtn: Boolean
  @Input() isMultiGrid: Boolean
  @Input() isNewSuperBtn: Boolean
  @Input() isRefreshBtn: Boolean
  @Input() isCSVExcelExportBtn: Boolean;
  @Input() isTimerKill: Boolean
  @Input() isNotShowBreadCrum: Boolean
  @Input() isNewSetupBtn: boolean;
  @Input() isuploadSetupBtn: boolean;
  @Input() detailRowColDefs: Boolean;
  @Input() detailRowDataAccessor: string;
  @Input() detailRowEditableFields: string;

  @ViewChild('myStartCalendar') startCalendar: Calendar;
  @Input() isShow: any;

  // Smart Table Input
  @Input() columnDataforSmartTable: any;
  @Input() isSmartTable: any;
  @Input() firstColumnNotClickable: any;
  @Input() isRapid: Boolean
  // orderList Export Modal Variables

  public entityListReplay: ReplaySubject<[]> = new ReplaySubject<[]>(1);
  public fundEntityListReplay: ReplaySubject<[]> = new ReplaySubject<[]>(1);

  protected _onDestroy = new Subject<void>();
  @ViewChild('singleSelect') singleSelect: MatSelect;

  startDate: any = null;
  endDate = null;
  flUri: any;
  runDate = new Date();
  public lookups: any = [];
  value = new Date();
  subscription1: any;
  subscription2: any;
  subscription3: any;

  public columns: any;
  public defaultsideBar: any;
  public selectedRows: any[] = [];
  public filteredData: any[] = [];
  public gridApi: any;
  public gridColumnApi: any;
  public columnDefs: any;
  public gridHeaders: any;
  public gridISDHeaders: any;
  public gridDataUrl: any;
  public gridDeleteUrl: any;
  public gridExportUrl: any;
  public gridFilteredUrl: any;
  public filterUrl: any;
  public statusBar: any;
  public sideBar: any;
  public defaultColDef: any;
  public frameworkComponents: any
  public paginationPageSize: Number = 100;
  public cacheBlockSize: Number = 100;
  public page: number = 0;
  public pageNumber: number = 1;
  public isFirst: any = true;
  public isLast: any = false;
  public isFryFilterActmarketdata: any = false;
  public totalPages: number;
  public totalRecords: Number;
  public totalFltRecords: Number;
  public searchText = '';
  public SmartTotalRecords: Number;
  public totalCount: any;
  public customeDateRange: any[] = [];
  public isCustomRangeBtn: any = false;
  public btnPreviousDate: any = true;
  public btnTodayDate: any = false;
  public isZoneName: any = false;
  public orderFilter: any = false;
  public routeUrl: string;
  public isAllocated: Boolean = false;
  public brokerList: any = [];
  public custodianList: any = [];
  public tickerList: any = [];
  public selectedBroker: String;
  public selectedBrokerDelete: String;
  public selectedFundName: any;
  public selectedYear: any;
  public selectedTicker: String = "";
  public display: any = false;
  public method: String = 'get'
  public reqObj: any;
  public reqObj1: any;
  public enterpriseId: any;
  public periodLockDate: any;
  public showExport: any = false;
  public moduleName: any;
  public moduleNameGrid: any;
  public tempurl: any;
  public displayForm: boolean = true;
  public gridShow: boolean = false;
  public selectedISDList = [];
  public reviewRejectCheck = false;
  public isRowSelectable: IsRowSelectable = function (rowNode) {
    return (rowNode.data?.isUserGroupingConfig == false) ? false : (rowNode.data?.csdEnterpriseId ? false : rowNode.data?.approverStatus ? false : rowNode.data?.coaStatus == 'Mapped' ? false : true)
  };
  dateValue: any
  cols: any[];
  public LookupList: any = [];
  currentPage: number = 0;
  rowHeight = 30;
  data: { startDate: any; endDate: any; };
  filterDeleteUrl: any;
  oppValue: any = true;
  public display1: any = false;
  public display2: any = false;
  public fetchExchangedisplay2: any = false;
  colorObject = {
    "Due": "Red",
    "Partially Paid": "orange",
    "Paid": "green"
  }
  ids: any = [];
  public isAdmin: any = true;
  customFilterUrl: any;
  customFilterUrls: any;
  searchObj: any;
  row: any;
  getCustomTypeUrl: any;
  public logLevels = ["Audit Log", "Detailed Log"];
  public rowClassRules: any;
  public rowStyle: any;
  public getRowStyle: any;
  public brdScrumFlag: any = false;
  public viewUrl: any;
  public isFryFilterAct: any = false;
  public istotalFilterCount: any = false;
  public fstItmIndx = null;
  public fstItmFrqy = null;
  public filteredDataevt: any;
  public searchObjectTemp: any = {};
  public workflowTreeValue: any;
  public newgridExportUrl: any;
  public gridFilteredModuleUrl: any;
  // Smart Table Variables 
  public myFlagForSlideToggle: any = false;
  dataSource: MatTableDataSource<any>;
  initialData: any[];
  virtualDataOnScroll: MatTableDataSource<any>;
  smartPage: number = 0;
  _ = get;
  isToggled: any = false;
  UserType: string;
  onScrollDataVariable: number = 0;
  result: any;

  arrFunds: FundItem;
  fundlist: any;
  //refresh interval
  myVar: any;
  public pagecount: number = 0;
  filterType: string;
  filterData: any;
  public runBtnFlag: any = true;
  public toastFlag: any = true;
  deleteIndex!: number;
  public lastSelectedNode: any;
  public getNewFunctionName: any;
  public getuploadFunctionName: any;
  public getdeleteButtonFunctionName: any;
  public blobExport = false;
  processButton: any;
  public filterRequest: any;
  gridData: {};
  batchId: any;
  sucessUploadBtn: any;
  public keepGoing: boolean = true;
  isSystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
  failureListCount: string;
  public isDoubleClick: boolean = false;
  public fundPeriodLockDate: any;
  subComponentTemp: any;
  selectedId: any = '';
  public formBasic = false;
  commonDropdownDetails: any = [];
  @ViewChild('formBasic') formBasicInfo;
  public formValid: boolean = true;
  subComponentName: any;
  isExportBtnView: boolean = false;
  isCSVExportBtnView: boolean;
  card: any;
  gridCrumb: any;
  tempSerchObj: any;
  custSearchKey: any;
  custSearchStDate: any;
  custSearchEnDate: any;
  public isExecutionLocked: boolean = true;
  public isWorkflowsUnchecked: boolean = true;
  genericExportComponents: any = ['marketdata', 'rapidMarketdata', 'capitalActivities', 'rapidCapitalActivities', 'investors', 'rapidInvestors', 'runViewOpenTaxlots', 'tradehv', 'rapidhighVolume', 'investorsList', 'shareClasses', 'shareSeries', 'performanceBasedFee', 'assestBasedFee', 'transferTradeRawDataHistory', 'RapidTransferTradeRawDataHistory', 'connectCustodianInvestor', 'sourceDestTradeTransferTaxLotInfo', 'dividendaccrual', 'rapidDividend', 'dividendadjuctment', 'RapidDividendadjuctment', 'setupbondinterest', 'rapidSetupbondinterest', 'takeOverPerfBaseFee', 'rapidTakeOverPerfBaseFee', 'bondinterestadjustment', 'rapidBondinterestadjustment', 'feeRateChange', 'viewjesummary', 'rapidjesummary', 'tradeArchiveList', 'rapidTradeArchiveList', 'exchangerate', 'rapidExchangerate', 'casplit', 'caspinoff', 'camerger', 'caspecialdividend', 'mgtIncentiveFeeAdj', 'rapidMgtIncentiveFeeAdj', 'itdSubscriptionAndRedemption', 'rapidItdSubscriptionAndRedemption', 'rapidDividendBroker']
  public memoEntry: boolean = false;
  public currentClosingBalance: number;
  public accountSummaryselectedTotal: number;
  public accountSDiffernece: number;
  public bankClosingBalance: number;
  public unReconciledDifference: number;
  public totalAdjustmentsBank: number;
  public totalAdjustment: number;
  public adjustedEndingBalance: number;
  public glClosingBalance: number;
  public fundName: string;
  public taxYear: string;
  public amendedReturnSchedule: string;
  public reconcileBankId: any;
  public accountSummaryData: any;
  public lastnodeSelected: any[];
  selectedLanguage: any;
  public fundLevel: any = []
  public AllfundLevelId: any = []
  public quickFilterText: string;
  public entityList: any = []
  public entityAllList: any = []
  selectedFundEntityId: any = {}
  selectedEntityId: any = {}
  public lastSelectedRows = []
  public entityFilterCtrl: FormControl = new FormControl();
  public fundEntityFilterCtrl: FormControl = new FormControl();
  public isReconciliationLoaded: boolean = false;
public isshowmasterDetail : boolean = false;

  public fundListAll = []
  public reconcileLock: boolean = false;
  entitiesAdmin: boolean = false;
  parentHeading: any;
  public boxTaxYearDetails = [
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
    { label: '2026', value: '2026' },
    { label: '2027', value: '2027' },
    { label: '2028', value: '2028' },
    { label: '2029', value: '2029' },
    { label: '2030', value: '2030' },
    { label: '2031', value: '2031' },
    { label: '2032', value: '2032' },
    { label: '2033', value: '2033' },
    { label: '2034', value: '2034' },
    { label: '2035', value: '2035' }
  ];
  public showHistoricalDataTax: Boolean = false;

  //editable grid
  public updatedColumnData: any = {};
  @Input() editableAPIList: any = [];
  @Input() afterEditableRowAPIList: any = [];
  @Input() dependentAPIEditableRowAPIList: any = [];
  @Input() updateRequestArray: any = {};
  @Input() editable: Boolean;
  @Input() periodLockDateAccessor: string;
  mappingDetails: any = {};
  configIdMapping: any;
  // Grid Properties 
  public modules: any[] = [ClientSideRowModelModule];
  public port: string;
  isDeleteVisible: boolean = false;
  public componentNamesForRowUpdate = [
    // 'rapidInvestors',
    // 'investorsList',
    'rapidSmEquitiesList',
    'rapidSmFxForwardList',
    'RapidSmOptionsList',
    'rapidSmFixedIncomeList',
    'RapidSmPrivateFundList',
    'RapidSmFutureList',
    'rapidCrypto',
    'RapidSmPools',
    // 'recurringTransaction',
    // 'rapidPositionBroker',
    // 'rapidDividend',
    'fsCashEquivalents',
    'fsCashFlowUpdate',
    'fschartOfAccountsRapid',
    'fsExposure',
    'fSInvestor',
    'fSPortfolio',
    // 'rapidSetupbondinterest',
    // "rapidAccountSummary",
    // "rapidMarketdata",
    // "rapidCapitalActivities"
  ];
  //**smart table method */
  notClicked = true;
  investorNames: any;
  isDisableValue: any;
  fundNameList: any;
  isValidMidPeriodInput: boolean = false;
  isJECreated: boolean = false;
  nextIndex = 1;
  exportModuleName: any;
  duplicateinvFundId: any;
  invId: any;
  updateApiResponse: any;
  taxAdjustmentyear: any;
  taxAdjustmentfundName: any;
  fundata: any;
  detailCellRenderer: string;

  constructor(
    private translateService: TranslateService, private httpClient: HttpClient,
    private setupPercentageService: SetupPercentageService, handler: HttpBackend,
    public gridService: GridService, public globalService: GlobalService,
    private commonService: CommonService, private dialog: MatDialog,
    private messageService: GlobalMessageService, private routes: ActivatedRoute,
    private router: Router, private setuppercentage: SetupPercentageService,
    private processScreenService: ProcessScreenService, private localService: LocalStoreService,
    private cookieService: CookieService, private ref: ChangeDetectorRef
  ) {
    this.selectedLanguage = this.currentUser['switchLanguage'];
    this.httpClient = new HttpClient(handler);
    this.frameworkComponents = {
      viewBtnCellRenderer: ViewBtnCellRenderer,
      runAlertThresholdButton: RunAlertThresholdButton,
      accountSummaryDateComponent: AccountSummaryDateComponent,
      overAllStatusButton: OverAllStatusButton,
      approveWorkflowButton: ApproveWorkflowButton,
      resumeDailyProcessingButton: ResumeDailyProcessingButton,
      approveWorkflowStatusButton: ApproveWorkflowStatusButton,
      downloadTempButton: DownloadTempButton,
      CaDownloadFileButton: CaDownloadFileButton,
      runDataImportErrorFileButton: RunDataImportErrorFileButton,
      GrdbtnErrRowDownComponent: GrdbtnErrRowDownComponent,
      GrdbtnPrvRowDownComponent: GrdbtnPrvRowDownComponent,
      GrdbtnPendingDataComponent: GrdbtnPendingDataComponent,
      GrdbtnRowEditComponent: GrdbtnRowEditComponent,
      refreshButton: RefreshButton,
      sendMailClientTemplate: SendMailClientTemplate,
      attachmentDownloadButton: AttachmentDownloadButton,
      componentName: this.componentName,
      subComponent: this.subComponent,
      details: DetailsComponent,
      batchDownload: BatchDownloadComponent,
      batchScheduleButton: BatchScheduleButtonComponent,
      batchBtnSchdule: BatchBtnSchduleComponent,
      GridHederBtnComponent: GridHederBtnComponent,
      checkboxRenderer: CheckboxRenderer,
      grdSchdBtnComponent: GrdSchdBtnComponent,
      batchSessionButton: BatchSessionButton,
      GridCellDatepicker: GridCellDatepicker,
      uploadButtonComponent: UploadButtonComponent,
      isdButtonComponent: IsdButtonComponent,
      reClientUploadButtonComponent: ReClientUploadComponent,
      duplicateButton: DuplicateButton,
      DropDownEditor: EditCellDropDownEditor,
      DropDownCellRenderer: EditCellDropDownRenderer,
      DateEditor: EditCellDateEditor,
      DateCellRenderer: EditCellDateRenderer,
      NumberCellEditor: EditCellNumberEditor,
      StringCellEditor: EditCellStringEditor,
      NumberCellRenderer: EditCellNumberRenderer,
      StringCellRenderer: EditCellStringRenderer,
      editBtnCellRenderer: ButtonRendererComponent,
      passwordCellEditor: EditCellPasswordEditor,
      MultiSelectDropDownEditor: EditCellMultiSelectDropDownEditor,
      MultiSelectDropDownCellRenderer: EditCellMultiSelectDropDownRenderer,
      detailCellRendererTable: DetailCellRendererTable,
      passwordCellRenderer: EditCellPasswordRenderer,
      periodLockDateAccessor: this.periodLockDateAccessor
    };
  }

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    // arbitrarily expand a row for presentational purposes

    if (this.componentName == 'paymentsComponent' || this.componentName == 'rapidPaymentsComponent' || this.componentName == 'vendorCreditList' || this.componentName == 'rapidreciptsComponent' ) {
      setTimeout(function () {
        params.api.getDisplayedRowAtIndex(1)!.setExpanded(false);
      }, 200);
    }
  }
  public detailCellRendererParams: any = {
    detailGridOptions: {
      columnDefs: [
        // { field: 'fundName', "headerName": "Entity Name", "resizable": true, "suppressMenu": true },
        // { field: 'transactionTypeName', "headerName": "Transaction", "resizable": true, "suppressMenu": true },
        // { field: 'transAmount', "headerName": "Bill Amount", minWidth: 150, "resizable": true, "suppressMenu": true },
        // { field: 'transPaidAmount', "headerName": "Amount Paid", minWidth: 150, "resizable": true, "suppressMenu": true },
        // { field: 'transBalanceAmount', "headerName": "Balance Amount", "resizable": true, "suppressMenu": true },
        // {
        //   field: 'status', "headerName": "Status", minWidth: 150, "suppressMenu": true, "cellRenderer": "overAllStatusButton", cellStyle: (params) => {
        //     return { color: this.colorObject[params.data.status] }
        //   }
        // },
      ],
      defaultColDef: {
        flex: 1,
      },
      onCellValueChanged: this.onCellValueChanged.bind(this)
    },

   
    getDetailRowData: (params) => {
      this.gridApi.forEachNode((node) => {
        if (node.rowIndex === params.node.rowIndex - 1) {
          node.setExpanded(true);
        } else {
          node.setExpanded(false);
        }
      });
      if(this.componentName == 'paymentsComponent' || this.componentName == 'rapidPaymentsComponent' || this.componentName == 'rapidreciptsComponent' ){
        params.successCallback(params.data.entityDetails);
      }else if (this.componentName == 'vendorCreditList'){
        params.successCallback(params.data?.entityDetails[0]?.transactionTypeDetails);
      }else if (this.componentName == 'taxAllocationAdjustmentRatios') {
        params.successCallback(params.data?.investorAllocationList);
      }
      
    },
  }




  
  
  get visibleColumns() {
    return this.columnDataforSmartTable ? this.columnDataforSmartTable.filter(column => column.visible).map(column => column.property) : null;
  }

  ngOnDestroy(): void {
    if (this.subscription1) {
      this.subscription1.unsubscribe()
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe()
    }
    let x = Array.from(document.getElementsByClassName('content') as HTMLCollectionOf<HTMLElement>)
    clearInterval(this.myVar);
    this.myVar = null
  }

  ngOnInit(): void {
    if (this.authList.includes("SEAMLESS_SIDEMENU_ENTITIES")) {
      this.entitiesAdmin = true;
    }
this.isshowmasterDetail = (this.componentName == 'paymentsComponent' || this.componentName == 'rapidPaymentsComponent'|| this.componentName == 'vendorCreditList' || this.componentName == 'rapidreciptsComponent')? true : false
if(this.componentName == 'paymentsComponent'|| this.componentName == 'rapidPaymentsComponent' ){
    this.detailCellRendererParams.detailGridOptions.columnDefs = [
      { field: 'fundName', "headerName": "Entity Name", "resizable": true, "suppressMenu": true },
      { field: 'transactionTypeName', "headerName": "Transaction", "resizable": true, "suppressMenu": true },
      { field: 'transAmount', "headerName": "Bill Amount", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transPaidAmount', "headerName": "Amount Paid", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transBalanceAmount', "headerName": "Balance Amount", "resizable": true, "suppressMenu": true },
      {
        field: 'status', "headerName": "Status", minWidth: 150, "suppressMenu": true, "cellRenderer": "overAllStatusButton", cellStyle: (params) => {
          return { color: this.colorObject[params.data.status] }
        }
      },
    ];
  }else if( this.componentName == 'vendorCreditList'){
    this.detailCellRendererParams.detailGridOptions.columnDefs = [
    
      { field: 'transactionTypeName', "headerName": "Transaction Type", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transAmount', "headerName": "Transaction Amount", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'summary', "headerName": "Description", "resizable": true, "suppressMenu": true },
     
    ];
  }else if( this.componentName == 'rapidreciptsComponent'){
    this.detailCellRendererParams.detailGridOptions.columnDefs = [
    
      { field: 'fundName', "headerName": "Entity Name", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transactionTypeName', "headerName": "Transaction", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transAmount', "headerName": "Total Amount", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transPaidAmount', "headerName": "Amount Paid", minWidth: 150, "resizable": true, "suppressMenu": true },
      { field: 'transBalanceAmount', "headerName": "Balance Amount", "resizable": true, "suppressMenu": true },
      {
        field: 'status', "headerName": "Status", minWidth: 150, "suppressMenu": true, "cellRenderer": "overAllStatusButton", cellStyle: (params) => {
          return { color: this.colorObject[params.data.status] }
        }
      },
    ];
  }else if (this.componentName == 'taxAllocationAdjustmentRatios') {
    // this.detailCellRendererParams = 'detailCellRendererTable';
    this.detailCellRenderer = 'detailCellRendererTable';
  }
    if (this.componentName == 'accountSummaryReconcile' || this.componentName == 'rapidAccountSummaryReconcile') {
      this.gridService.getEntity().subscribe(res => {
        this.fundListAll = res.resultList;
        this.accountSummaryData = this.setuppercentage.getAccountSummaryReconcileGrid()
        if (this.accountSummaryData) {
          var result = this.getFundPeriodLockDate(this.accountSummaryData)
          if (result != "No locks found") {
            this.reconcileLock = true
          } else {
            this.reconcileLock = false
          }
          this.glClosingBalance = this.accountSummaryData.glEndingBalance || 0
          this.totalAdjustment = this.accountSummaryData.totalAdjstmntsToBank || 0
          this.adjustedEndingBalance = this.accountSummaryData.adjEndBankBalance || 0
          this.bankClosingBalance = this.accountSummaryData.bankClosingBalance || 0
          this.accountSummaryselectedTotal = this.accountSummaryData.currentClosingBalance || 0
          this.unReconciledDifference = this.accountSummaryData.unReconciledDifference || 0
          this.reconcileBankId = this.accountSummaryData.bankId || 0
        }
      });
    }
    if (this.componentName == 'chartofAccountTaxMappingDetails' || this.componentName == 'K1Merging') {
      this.setuppercentage.getMappingData().subscribe(Data => {
        if (Data) {
          this.fundName = Data.fundName ? Data.fundName : null;
          this.taxYear = Data.configTaxYear ? Data.configTaxYear : null;
          this.setuppercentage.setTaxYear(this.taxYear);
          this.amendedReturnSchedule = Data.amendedReturnAndScheduleK1 ? Data.amendedReturnAndScheduleK1 : null;
        }
      });
    }
    if (this.componentName == 'workflowStatus') {
      this.frameworkComponents.agColumnHeader = CustomHeader
    }
    if (this.componentName == "duplicateSetupInvestorsList" || this.componentName == "duplicaterapidInvestors") {
      this.isRowSelectable = function (rowNode) {
        return (rowNode.data?.isUserGroupingConfig == false) ? false : (rowNode.data?.approverStatus ? false : rowNode.data?.coaStatus == 'Mapped' ? false : true)
      };
      this.subscription3 = this.setupPercentageService.getShowGridMsg().subscribe((res) => {
        this.duplicateinvFundId = null;
        if (res == null) {
          this.gridApi.setRowData([]);
        }
        else {
          this.duplicateinvFundId = res;
          this.getGridData();
        }
      })
    }
    if (this.componentName == "setupInvestorsList" || this.componentName == "rapidInvestors") {
      this.isRowSelectable = function (rowNode) {
        return (rowNode.data?.isUserGroupingConfig == false) ? false : (rowNode.data?.csdEnterpriseId ? false : rowNode.data?.approverStatus ? false : rowNode.data?.coaStatus == 'Mapped' ? false : true)
      };
    }
    if (this.componentName == 'accountSummary' || this.componentName == 'rapidAccountSummary') {
      this.getEntity()
    }

    this.moduleNameGrid = this.router.url.split('/')[1];
    switch (this.moduleNameGrid) {
      case 'trades':
        this.moduleNameGrid = 'trades_module';
        break;
      case 'portfolio':
        this.moduleNameGrid = 'portfolio';
        break;
      case 'accounting':
        this.moduleNameGrid = 'accounting';
        break;
      case 'investor':
        this.moduleNameGrid = 'investors';
        break;
      case 'processing':
        this.moduleNameGrid = 'processing';
        break;
      case 'workflow':
        this.moduleNameGrid = 'workflow';
        break;
      case 'reconciliation':
        this.moduleNameGrid = 'reconciliation';
        break;
      case 'query':
        this.moduleNameGrid = 'query';
        break;
      case 'batch-tracking':
        this.moduleNameGrid = 'batch_tracking';
        break;
      case 'reports':
        this.moduleNameGrid = 'seamless_reports';
        break;
      case 'setting':
        this.moduleNameGrid = 'settings';
        break;
      case 'manager':
        this.moduleNameGrid = 'admin_module';
        break;
      case 'legacy':
        this.moduleNameGrid = 'legacy';
        break;
      case 'MIS':
        this.moduleNameGrid = 'mis';
        break;
      case 'Entities':
        this.moduleNameGrid = 'Entities';
        break;
      case 'global-security-master':
        this.moduleNameGrid = 'Securities Master';
        break;
      case 'systemmanager':
        this.moduleNameGrid = 'admin_module';
        break;
      case 'api-management':
        this.moduleNameGrid = 'api_management';
        break;
      default:
        this.commonService.moduleNameObs$.subscribe((moduleName) => {
          this.moduleNameGrid = moduleName;
          this.translateService.get(this.moduleNameGrid).subscribe((res) => {
            this.moduleNameGrid = res;
          });
        });
        break;
    }

    this.translateService.get(this.moduleNameGrid).subscribe((res) => {
      this.moduleNameGrid = res;
    });

    this.parentHeading = apiConstants[this.componentName]['parentHeading']
    if (this.roleAs == "SystemManager") {
      this.card = apiConstants[this.componentName]['systemAdminCard']
    } else if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
      this.card = apiConstants[this.componentName]['entCard']
    } else if (this.roleAs == "User") {
      this.card = apiConstants[this.componentName]['userCard']
    }

    this.translateService.onLangChange.subscribe(() => {
      if (this.gridApi) {
        this.gridApi.stopEditing(true);
      }
      if (this.editable && this.editableAPIList.length) {
        this.gridService.getEditableAPI(this.editableAPIList, this.rowData).subscribe((response: any) => {
          this.getGridHeader(response);
        })
      } else {
        this.getGridHeader();
      }
      setTimeout(() => {
        if (this.gridApi) {
          this.gridApi.refreshCells({ force: true });
        }
      }, 1000);

      this.moduleNameGrid = this.router.url.split('/')[1];
      switch (this.moduleNameGrid) {
        case 'trades':
          this.moduleNameGrid = 'trades_module';
          break;
        case 'portfolio':
          this.moduleNameGrid = 'portfolio';
          break;
        case 'accounting':
          this.moduleNameGrid = 'accounting';
          break;
        case 'investor':
          this.moduleNameGrid = 'investors';
          break;
        case 'processing':
          this.moduleNameGrid = 'processing';
          break;
        case 'workflow':
          this.moduleNameGrid = 'workflow';
          break;
        case 'reconciliation':
          this.moduleNameGrid = 'reconciliation';
          break;
        case 'query':
          this.moduleNameGrid = 'query';
          break;
        case 'batch-tracking':
          this.moduleNameGrid = 'batch_tracking';
          break;
        case 'reports':
          this.moduleNameGrid = 'seamless_reports';
          break;
        case 'setting':
          this.moduleNameGrid = 'settings';
          break;
        case 'manager':
          this.moduleNameGrid = 'manager';
          break;
        case 'systemmanager':
          this.moduleNameGrid = 'admin_module';
          break;
        case 'api-management':
          this.moduleNameGrid = 'api_management';
          break;
        default:
          this.commonService.moduleNameObs$.subscribe((moduleName) => {
            this.moduleNameGrid = moduleName;
            this.translateService.get(this.moduleNameGrid).subscribe((res) => {
              this.moduleNameGrid = res;
            });
          });
          break;
      }
      this.translateService.get(this.moduleNameGrid).subscribe((res) => {
        this.moduleNameGrid = res;
      });
    })

    this.getNewFunctionName = apiConstants[this.componentName]?.authFunctions?.newButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions.newButtonFunction) > -1 : true;
    this.getdeleteButtonFunctionName = apiConstants[this.componentName]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName]?.authFunctions.deleteButtonFunction, this.authList) : true;
    this.getuploadFunctionName = apiConstants[this.componentName]?.authFunctions?.uploadButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions.uploadButtonFunction) > -1 : true;
    if (this.componentName == 'usermanagementlist' || this.componentName == 'automate-process' || this.componentName == 'rapidOmslist' || this.componentName == 'transferTradeStatus' || this.componentName == 'rapidAssestBasedFee' || this.componentName == 'rapidhighVolume') {
      this.getNewFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.newButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions.newButtonFunction) > -1 : true;
      this.getdeleteButtonFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName][this.subComponent]?.authFunctions.deleteButtonFunction, this.authList) : false;
      this.getuploadFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.uploadButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions.uploadButtonFunction) > -1 : true;
    }

    let isExportButtonView = [
      "casplit",
      "caspinoff",
      "camerger",
      "newIssues",
      "rapidNewIssues",
      "sequentialProcessStatusReview",
      "sourceDestTradeTransferTaxLotInfo",
      "shareClasses",
      "shareSeries",
      "redemptionFee",
      "performanceBasedFee",
      "incomeDistribution",
      "feeSharing",
      "setupExpenseAmortization",
      "hurdleTireSetup",
      "closingMethods",
      "assetClass",
      "commission",
      "tradeAllocation",
      "exchanges",
      "calendar",
      "tradeArchiveList",
      "rapidTradeArchiveList",
      "caspecialdividend",
      "assetBasedFeeList",
      "assetBasedFeeCalculation",
      "viewPerfBasedFees",
      "rapidViewPerfBasedFees",
      "midPeriodReset",
      "rapidMidPeriodReset",
      "sidepockettotalincome",
      "viewjesummary",
      "jeSummary",
      "rapidjesummary",
      "feeRateChange",
      "jeThirdParty",
      "cashTransaction",
      // "notesList",
      "copnotesList",
      "taxLotStatus",
      "currenciesForSetting",
      "regulatorsForSetting",
      "executeWorkflow",
      "workflowStatus",
      "inputDataIntegritySummery",
      "inputDataIntegrityCheck",
      "accountSummary",
      "rapidAccountSummary"
    ];

    let isExportbuttonsubcomponent = [
      { component: 'rapidAssestBasedFee', subcomponent: 'assetBasedFeeList' },
      { component: 'rapidAssestBasedFee', subcomponent: 'assetBasedFeeCalculation' }
    ]

    isExportbuttonsubcomponent.forEach(item => {
      if (this.componentName == item.component && this.subComponent == item.subcomponent) {
        this.isExportBtnView = true;
      }
    })

    if (isExportButtonView.includes(this.componentName) || (this.componentName == 'transferTradeStatus' && (this.subComponent == 'transferProcessStatusDayWise' || this.subComponent == 'transferTradeHistory'))) {
      this.isExportBtnView = true;
    }

    let isCSVExportButtonView = ["investorgroup", "rapidInvestorgroup", "positionByTaxlot", "customizationInvestorgroup"];
    if (isCSVExportButtonView.includes(this.componentName)) {
      this.isCSVExportBtnView = true;
    }

    this.gridService.istoggleChange = false;
    this.UserRoles = this.currentUser['ROLE'] //JSON.parse(this.localService.getItem('fss'))['ROLE']
    if (this.UserRoles == "User" && this.componentName == "executeWorkflow") {
      this.brdScrumFlag = true;
    }
    if (this.UserRoles == 'Manager' && this.componentName == 'hurdleRate' && (this.componentName == 'recurringTransaction' || this.componentName == 'rapidRecurringTransaction')) {
      this.isUploadBtn = false;
    }
    const excludedComponents = ['apiCreation', 'apiModule', 'apiKeyModule', 'FSAutomationUnlock', 'FSAutomation', 'archivedFSAutomation'];
    if (!excludedComponents.includes(this.componentName)) {
      this.commonService.fetchFundList().subscribe((response) => {
        this.arrFunds = response.resultList?.map((item: FundItem) => ({
          fundPeriodLockDate: item.fundPeriodLockDate,
          fundName: item.fundName,
          fundAlias: item.fundAlias,
          id: item.id
        }));
        let val = this.currentUser;
        if (val) {
          val['fundlist'] = this.arrFunds;
          this.localService.setItem('fss', JSON.stringify(val));
        }
      });
    }

    this.reviewRejectCheck = this.currentUser.authList.indexOf('SEAMLESS_ACTIVITIES_CLIENT_REJECT') > -1 ? true : false;
    this.periodLockDate = this.currentUser.periodLockDate;
    this.statusBar = {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left',
          key: 'totalRowComponent',
        },
        {
          statusPanel: 'agFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agSelectedRowCountComponent',
          align: 'center',
        },
        {
          statusPanel: 'agAggregationComponent',
          align: 'right',
        },
      ],
    };

    this.defaultColDef = {
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: false,
      filter: true,
      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"]
    };

    this.defaultsideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: 'filters',
    };

    if (this.componentName == 'automate-process') {
      this.defaultsideBar['toolPanels'] = [];
    }
    this.customeDateRange[0] = new Date(moment().format('YYYY-MM-DD'));
    this.customeDateRange[1] = new Date(moment().format('YYYY-MM-DD'));
    //**smart table */
    this.dataSource = new MatTableDataSource();
    this.virtualDataOnScroll = new MatTableDataSource();
    this.dataSource.data = Array.from({ length: 10000 }).map(() => this.gridDataUrl);
    this.dataSource.data = Array.from({ length: 10000 });
    clearInterval(this.myVar);
    this.myVar = null;

    this.updateNotificationStatus();
    if (this.componentName == 'viewDataCollection') {
      this.gridService.commonDropdownDetails().then((response) => {
        this.commonDropdownDetails = response;
        this.formBasic = true;
      })
    }
    /**
     * Code that return FS config form object FSA-395
     * @author   Ashok Kumar
     */
    if (this.componentName == 'FSAutomation' || this.componentName == 'archivedFSAutomation') {
      this.gridService.commonFSDropdownDetails().then((response) => {
        this.commonDropdownDetails = response;
        this.formBasic = true;
      })
    }
    if (window.location.hostname == "localhost") {
      const url = new URL(api_url);
      this.port = url?.port || '8081'
    }
  }

  getEntity() {
    this.gridService.getREfundNameByLevel("Fund Level").subscribe(res => {
      this.AllfundLevelId = res.map(element => ({ label: element.fundName, value: { id: element.id, fundName: element.fundName } }));
      this.AllfundLevelId = this.AllfundLevelId.sort((a, b) => a.label.localeCompare(b.label));
      this.fillDropdown('AllfundLevelId', 'fundEntityListReplay', 'fundEntityFilterCtrl')

    })
    this.gridService.getEntity().subscribe(res => {
      this.entityList = res.resultList.map(element => ({ label: element.fundName, value: { id: element.id, fundName: element.fundName } }));
      this.entityList = this.entityList.sort((a, b) => a.label.localeCompare(b.label));
      this.fillDropdown('entityList', 'entityListReplay', 'entityFilterCtrl')
    })
  }

  fillDropdown(allList, filterdata, formControl) {
    this[filterdata].next(this[allList]?.slice());
    // listen for search field value changes
    this[formControl].valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterFn(this[allList], filterdata, formControl);
      });
  }

  getMidPeriod() {
    if (this.selectedFundName && this.selectedYear) {
      this.isValidMidPeriodInput = true;
      let data =
      {
        fundId: this.selectedFundName.id,
        year: this.selectedYear.value,
        fundName: this.selectedFundName.fundName
      }



      if (this.componentName == 'startupOrganizational') {
        this.setuppercentage.setStartOrganizational(data);
        this.setuppercentage.setOAndOData(data);
      } else if (this.componentName == 'midPeriod') {
        this.setuppercentage.setTaxAdjustment(data);
        this.setuppercentage.setMidPeriodDataTrigger(data);
        this.getGridData();
      }
      else if (this.componentName == 'taxincomeAllocation') {
        this.setuppercentage.setTaxAllocation(data);
        this.setuppercentage.setTaxAllocationDataTrigger(data);
      }
    }
  }


  protected filterFn(list, filterdata, formControl) {
    if (!list) {
      return;
    }
    // get the search keyword
    let search = this[formControl].value;
    if (!search) {
      this[filterdata].next(list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this[filterdata].next(
      list.filter(element => element.label.toLowerCase().indexOf(search) > -1)
    );
  }

  setEntityChange(data) {
    this.selectedEntityId = data;
    let req = {};

    if (data) {
      req['entityLevelFundId'] = {
        "filterType": "text",
        "type": "contains",
        "filter": this.selectedEntityId.fundName
      }
      this.gridService.getEntityfilterByCriteria({ entityLevelFundId: this.selectedEntityId.id }).subscribe(res => {
        if (res.data) {
          this.fundLevel.forEach(element => {
            if (res.data.fundName == element.fundName)
              this.selectedFundEntityId = element
          });
        }
        if (this.selectedFundEntityId) {
          req['fundLevelFundId'] = {
            "filterType": "text",
            "type": "contains",
            "filter": this.selectedFundEntityId.fundName
          }
        }
      })
    } else {
      this.gridService.getEntity().subscribe(response => {
        this.entityList = response.resultList.map(element => ({ label: element.fundName, value: { id: element.id, fundName: element.fundName } }));
        this.entityList = this.entityList.sort((a, b) => a.label.localeCompare(b.label));
        this.fillDropdown('entityList', 'entityListReplay', 'entityFilterCtrl')
      })
    }
    this.gridApi.setFilterModel(req);
  }

  setFundEntityChange(data) {
    this.selectedFundEntityId = data;
    let req = {};
    if (data) {
      req['fundLevelFundId'] = {
        "filterType": "text",
        "type": "contains",
        "filter": this.selectedFundEntityId.fundName
      }

      this.gridService.getEntityfilterByCriteria({ fundEntityName: this.selectedFundEntityId.fundName }).subscribe(res => {
        this.selectedEntityId = {}
        if (res.data) {
          this.entityList = res.data.map(element => ({ label: element.fundName, value: { id: element.id, fundName: element.fundName } }));
          this.entityList = this.entityList.sort((a, b) => a.label.localeCompare(b.label));
          this.fillDropdown('entityList', 'entityListReplay', 'entityFilterCtrl')
        }
      })
    } else {
      this.gridService.getEntity().subscribe(response => {
        this.entityList = response.resultList.map(element => ({ label: element.fundName, value: { id: element.id, fundName: element.fundName } }));
        this.entityList = this.entityList.sort((a, b) => a.label.localeCompare(b.label));
        this.fillDropdown('entityList', 'entityListReplay', 'entityFilterCtrl')
      })
    }
    this.gridApi.setFilterModel(req);
  }

  addNewSetupBtn() {
    this.gridService.setSetupActionMethod('create')
    if (this.componentName == 'usermanagementlist') {
      this.sudcomponentName.emit(this.subComponent);
      this.gridService.setUserActionMethod(1);
    }
  }

  fetchExchangeRate() {
    this.openModel.emit(true);
    if (this.componentName == 'chartofAccountTaxMappingDetails') {
      let type = 'seamlessTaxcode'
      this.openMemoModel.emit(type);
    }
  }

  openModelPopupEmitter(type) {
    // if (type == 'FSAutomation' && this.totalRecords == 0) {
    //   this.router.navigate([this.routeUrl?.['create']]);
    //   return
    // }
    this.openModel.emit(type);
  }

  uploadSetupBtn() {
    if (this.subComponent == 'SIO') {
      this.gridService.setUserActionMethod(3)
    }
    if (this.subComponent == 'TRO') {
      this.gridService.setUserActionMethod(5)
    }
    this.gridService.setSetupActionMethod('upload')
  }

  updateNotificationStatus() {
    this.gridService.GetTriggerMethod().subscribe((item) => {
      if ((this.componentName == "reportNotificationStatusList" || this.componentName == 'customizationReportNotificationStatusList') && !this.gridService.isSubscribed) {
        this.gridService.isSubscribed = true
        if ((!this.gridService.istoggleChange) || this.notClicked) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            if (this.componentName == 'customizationReportNotificationStatusList') {
              this.router.navigate(['/customization/notification-status'], { relativeTo: this.routes })
            } else {
              this.router.navigate(['/manager/report-notification-status'], { relativeTo: this.routes })
            }
          })
        } else {
          this.getGridData();
        }
      }
    })
  }

  getFilterData(e: any) {
    if (this.componentName == "isdAdhocConfiguration") {
      return
    }
    const nodeTextandNum = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[0]?.children[1]?.children[0];
    const nodeDate = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[0]?.children[0]?.children[0]?.children[1]?.children[0];
    const nodeDateRange = e?.filterInstance?.eGui?.children[0]?.children[1]?.children[1]?.children[0]?.children[0]?.children[1]?.children[0];
    const applyButton = e?.filterInstance?.eGui?.children[1]?.children[1];
    let isRange = e?.filterInstance?.eGui?.innerText.includes("range");
    let node = nodeTextandNum != undefined ? nodeTextandNum : (isRange ? nodeDateRange : nodeDate);
    node.addEventListener("keyup", (event: any) => {
      if (event.key === "Enter") {
        let ele: HTMLElement = document.getElementsByClassName(applyButton)[1] as HTMLElement;
        applyButton.click();
      }
    });
  }

  viewFromSmart(rowData: any) {
    this.viewUrl = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['viewUrl'] : apiConstants[this.componentName]['viewUrl'];
    this.router.navigate([this.viewUrl]);
    this.gridService.setViewData(rowData);
  }

  customSort(fieldName: string, order: number) {
    let x = this.rowData;
    this.rowData = [];
    if (order == 1) {
      if (fieldName && fieldName.includes('.')) {
        let fielsNames = fieldName.split('.');
        this.rowData = x.sort((first, second) => 0 - (first[fielsNames[0]][fielsNames[1]] > second[fielsNames[0]][fielsNames[1]] ? -1 : 1));
      } else {
        this.rowData = x.sort((first, second) => 0 - (first[fieldName] > second[fieldName] ? -1 : 1));
      }
    } else {
      if (fieldName.includes('.')) {
        let fielsNames = fieldName.split('.');
        this.rowData = x.sort((first, second) => 0 - (first[fielsNames[0]][fielsNames[1]] > second[fielsNames[0]][fielsNames[1]] ? 1 : -1));
      } else {
        this.rowData = x.sort((first, second) => 0 - (first[fieldName] > second[fieldName] ? 1 : -1));
      }
    }

    if (this.rowData.length) {
      this.virtualDataOnScroll.data = [...this.rowData];
    }
  }

  onSmartFilterChange(value: any) {
    /*
    Auth:Vg
    Smart Table Filter Count Upadte After Filter
    */
    if (value) {
      this.isFryFilterAct = true;
    } else {
      this.isFryFilterAct = false;
    }
    if (!this.virtualDataOnScroll) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.virtualDataOnScroll.filter = value;
    if (value && !this.myFlagForSlideToggle) {
      this.totalFltRecords = 0;
      this.totalFltRecords = this.virtualDataOnScroll.filteredData ? this.virtualDataOnScroll.filteredData.length : 0;
    }
  }

  ngOnChanges(changes: any) {
    let seamlessTaxData = Object.keys(changes)
    if (!changes?.seamlessTaxData?.currentValue) {
      this.myFlagForSlideToggle = this.isSmartTable;
      this.columnDefs = [];
      this.rowData = [];
      this.selectedRows = [];
      this.rowSelection = "multiple";
      this.getGridHeaderUrl();
      this.getGridDataUrl();
      this.getupdatedfunctionname();
      if (this.gridApi) {
        if (changes.subComponent) {
          this.gridApi.subComponent = changes.subComponent.currentValue
        }
      }

      if (this.isTimerKill) {
        this.componentName = 'executeWorkflow';
        clearInterval(this.myVar);
        this.myVar = null;
        if (this.workflowTreeValue) {
          this.method = "post";
        } else {
          this.method = "get";
        }
      }

      clearInterval(this.myVar);
      this.myVar = null;

      if (this.componentName == 'executeWorkflow') {
        this.workflowTreeValue = JSON.parse(localStorage.getItem('workflowTreeValue'));
        if (this.workflowTreeValue) {
          this.method = "post";
        } else {
          this.method = "get";
        }
      }

      if (this.componentName == 'usermanagementlist') {
        this.getNewFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.newButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions.newButtonFunction) > -1 : true;
        this.getdeleteButtonFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName][this.subComponent]?.authFunctions.deleteButtonFunction, this.authList) : false;
        this.getuploadFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.uploadButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions.uploadButtonFunction) > -1 : true;
      }
    }
  }

  /* Name:onGridReady
     param:null
     purpose: Grid Api Setup
     author: Akshar
   */
  onGridReady(params: any) {
    params.api.componentName = this.componentName;
    params.api.subComponent = this.subComponent;
    params.api.periodLockDateAccessor = this.periodLockDateAccessor;
    params.api.detailRowDataAccessor = this.detailRowDataAccessor;
    params.api.detailRowEditableFields = this.detailRowEditableFields;
    params.api.detailRowColDefs = this.detailRowColDefs;
    params.api.lookups = this.lookups;
    //refresh-button
    params.api.isdViewBtnCheck = this.authList.indexOf('SEAMLESS_ISD_INVESTOR_STATEMENT_DISTRIBUTION_VIEW') > -1 ? true : false;
    params.api.isdEnableViewBtnCheck = this.authList.indexOf('SEAMLESS_ISD_REDISTRIBUTION_VIEW') > -1 ? true : false;
    //refresh-button
    //view-button-cell-renderer
    params.api.enterpriseId = this.currentUser.enterpriseId;
    params.api.periodLockDate = this.currentUser.periodLockDate;
    params.api.roleAs = this.roleAs;
    params.api.issystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
    //view-button-cell-renderer
    this.gridApi = params.api;
    this.setGridApiData();
    this.gridColumnApi = params.columnApi;
    this.gridApi.closeToolPanel();
    this.gridApi.hideOverlay();
    if (this.isTimerKill) {
      this.componentName = 'executeWorkflow'
      clearInterval(this.myVar);
      this.myVar = null;
      if (this.workflowTreeValue) {
        this.method = "post";
      } else {
        this.method = "get";
      }
    }
  }

  setGridApiData() {
    this.gridApi.getViewFunctionName = apiConstants[this.componentName]?.authFunctions?.viewButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions?.viewButtonFunction) > -1 : true;
    this.gridApi.getEditFunctionName = apiConstants[this.componentName]?.authFunctions?.updateButtonFunction ? this.authList.indexOf(apiConstants[this.componentName]?.authFunctions?.updateButtonFunction) > -1 : true;
    this.gridApi.getDeleteFunctionName = apiConstants[this.componentName]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName]?.authFunctions?.deleteButtonFunction, this.authList) : true;
    this.gridApi.omsValidation = apiConstants[this.componentName][this.subComponent] ? true : false;
    if ((this.gridApi.omsValidation && this.componentName === 'usermanagementlist') || this.componentName === 'usermappinglist' || this.componentName === 'automate-process') {
      this.gridApi.getViewFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.viewButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions?.viewButtonFunction) > -1 : true;
      this.gridApi.getDeleteFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction, this.authList) : true;
    }
    if (this.componentName == 'userCoaList') {
      this.gridApi.updateUserCheck = this.authList.indexOf('SEAMLESS_SETUP_CHARTOFACCOUNT_APPROVER_UPDATE') > -1 ? true : false;
      this.gridApi.updateClientCheck = this.authList.indexOf('SEAMLESS_SETUP_CHARTOFACCOUNT_CLIENT_UPDATE') > -1 ? true : false;
    }
    if (this.componentName == 'reviewChartOfAccounts') {
      this.gridApi.updateClientCheck = this.authList.indexOf('SEAMLESS_SETTINGS_REVIEW_CHARTOFACCOUNTS_UPDATE') > -1 ? true : false;
    }
    if (this.componentName == 'reReportConfig') {
      this.gridApi.currentUser = this.currentUser;
      this.gridApi.isEnterprise = this.isEnterprise;
    }
    if (this.componentName == 'taxFundList' || this.componentName == 'taxInvestorsList' || this.componentName == 'taxchartOfAccounts') {
      this.gridApi.getDeleteFunctionName = false;
    }
    if (this.componentName == "accountSummary" || this.componentName == "rapidAccountSummary") {
      this.gridApi.currentUser = this.currentUser;
      this.gridService.getEntity().subscribe(res => {
        this.fundListAll = res.resultList;
        this.gridApi.fundListAll = this.fundListAll;
      })
    }
  }

  onPaginationChanged(evt: any) {
    if (this.page > 0) {
      this.isFirst = false;
    }
    if (this.page == this.totalPages) {
      this.isLast = true;
    }
  }

  onBtFirst() {
    if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
      var selectedRowsTally = []
      var rowDataIsReconcileCheck = []
      var selectedRowsChecked = this.gridApi.getSelectedRows();
      selectedRowsChecked.forEach(item => {
        selectedRowsTally.push(item)
      });

      this.rowData.forEach(item => {
        rowDataIsReconcileCheck.push(item)
      });

      const selectedRows = selectedRowsTally.filter(Boolean)
      const selectedIsReconcile = rowDataIsReconcileCheck.filter(el => el.isReconcile)

      let compareTwoArrayOfObjects = (selectedRowsTally, rowDataIsReconcileCheck) => {
        return (
          selectedRowsTally.length === rowDataIsReconcileCheck.length &&
          selectedRowsTally.every((element_1) =>
            rowDataIsReconcileCheck.some((element_2) =>
              Object.keys(element_1).every((key) => element_1[key] === element_2[key])
            )
          )
        );
      };
      if (!compareTwoArrayOfObjects(selectedRows, selectedIsReconcile)) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('Please Save & close Selected JE ID') });
        return false
      }
    }

    if (this.page > 0) {
      this.page = 0;
      this.pageNumber = 1;
      this.gridApi.paginationGoToFirstPage(0);
      if (this.componentName == "workflowStatus") {
        clearInterval(this.myVar);
        this.myVar = null;
      }
      this.filteredData.length || this.filterData == true ? this.getFilteredData(this.filteredDataevt) : this.getGridData()
      this.gridApi.paginationGoToPage(0);
    }
  }

  onBtLast() {
    if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
      var selectedRowsTally = []
      var rowDataIsReconcileCheck = []
      var selectedRowsChecked = this.gridApi.getSelectedRows();
      selectedRowsChecked.forEach(item => {
        selectedRowsTally.push(item)
      });

      this.rowData.forEach(item => {
        rowDataIsReconcileCheck.push(item)
      });

      const selectedRows = selectedRowsTally.filter(Boolean)
      const selectedIsReconcile = rowDataIsReconcileCheck.filter(el => el.isReconcile)

      let compareTwoArrayOfObjects = (selectedRowsTally, rowDataIsReconcileCheck) => {
        return (
          selectedRowsTally.length === rowDataIsReconcileCheck.length &&
          selectedRowsTally.every((element_1) =>
            rowDataIsReconcileCheck.some((element_2) =>
              Object.keys(element_1).every((key) => element_1[key] === element_2[key])
            )
          )
        );
      };
      if (!compareTwoArrayOfObjects(selectedRows, selectedIsReconcile)) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('Please Save & close Selected JE ID') });
        return false
      }
    }

    if (this.page < Number(this.totalPages) - 1) {
      this.page = Number(this.totalPages) - 1;
      this.pageNumber = this.totalPages;
      this.gridApi.paginationGoToLastPage(this.page);
      this.setupPercentageService.setIsJECreated(false)
      if (this.componentName == "workflowStatus") {
        clearInterval(this.myVar);
        this.myVar = null;
      }
      this.filteredData.length || this.filterData == true ? this.getFilteredData(this.filteredDataevt) : this.getGridData()
      this.gridApi.paginationGoToPage(this.page);
    }
  }

  onBtNext() {
    if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
      var selectedRowsTally = [];
      var rowDataIsReconcileCheck = [];
      var selectedRowsChecked = this.gridApi.getSelectedRows();
      selectedRowsChecked.forEach(item => {
        selectedRowsTally.push(item)
      });

      this.rowData.forEach(item => {
        rowDataIsReconcileCheck.push(item)
      });

      const selectedRows = selectedRowsTally.filter(Boolean)
      const selectedIsReconcile = rowDataIsReconcileCheck.filter(el => el.isReconcile)

      let compareTwoArrayOfObjects = (selectedRowsTally, rowDataIsReconcileCheck) => {
        return (
          selectedRowsTally.length === rowDataIsReconcileCheck.length &&
          selectedRowsTally.every((element_1) =>
            rowDataIsReconcileCheck.some((element_2) =>
              Object.keys(element_1).every((key) => element_1[key] === element_2[key])
            )
          )
        );
      };

      if (!compareTwoArrayOfObjects(selectedRows, selectedIsReconcile)) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('Please Save & close Selected JE ID') });
        return false
      }
    }

    if (this.page < (Number(this.totalPages) - 1)) {
      this.setupPercentageService.setIsJECreated(false)
      this.page = Number(this.page) + 1;
      this.pageNumber = Number(this.page) + 1;
      if (this.componentName == "workflowStatus") {
        clearInterval(this.myVar);
        this.myVar = null;
      }
      this.filteredData.length || this.filterData == true ? this.getFilteredData(this.filteredDataevt) : this.getGridData();
      this.gridApi.paginationGoToPage(this.page);
    }
  }

  onBtPrevious() {
    if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
      var selectedRowsTally = [];
      var rowDataIsReconcileCheck = [];
      var selectedRowsChecked = this.gridApi.getSelectedRows();
      selectedRowsChecked.forEach(item => {
        selectedRowsTally.push(item)
      });

      this.rowData.forEach(item => {
        rowDataIsReconcileCheck.push(item)
      });

      const selectedRows = selectedRowsTally.filter(Boolean);
      const selectedIsReconcile = rowDataIsReconcileCheck.filter(el => el.isReconcile)

      let compareTwoArrayOfObjects = (selectedRowsTally, rowDataIsReconcileCheck) => {
        return (
          selectedRowsTally.length === rowDataIsReconcileCheck.length &&
          selectedRowsTally.every((element_1) =>
            rowDataIsReconcileCheck.some((element_2) =>
              Object.keys(element_1).every((key) => element_1[key] === element_2[key])
            )
          )
        );
      };
      if (!compareTwoArrayOfObjects(selectedRows, selectedIsReconcile)) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('Please Save & close Selected JE ID') });
        return false;
      }
    }

    if (this.page > 0) {
      this.page = Number(this.page) - 1;
      this.pageNumber = Number(this.page) + 1;
      this.setupPercentageService.setIsJECreated(false)
      if (this.componentName == "workflowStatus") {
        clearInterval(this.myVar);
        this.myVar = null;
      }
      this.filteredData.length || this.filterData == true ? this.getFilteredData(this.filteredDataevt) : this.getGridData()
      this.gridApi.paginationGoToPage(this.page);
    }
  }

  selectedPagePagibation(pageNumber: any) {
    if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
      var selectedRowsTally = []
      var rowDataIsReconcileCheck = []
      var selectedRowsChecked = this.gridApi.getSelectedRows();
      selectedRowsChecked.forEach(item => {
        selectedRowsTally.push(item)
      });

      this.rowData.forEach(item => {
        rowDataIsReconcileCheck.push(item)
      });

      const selectedRows = selectedRowsTally.filter(Boolean)
      const selectedIsReconcile = rowDataIsReconcileCheck.filter(el => el.isReconcile)

      let compareTwoArrayOfObjects = (selectedRowsTally, rowDataIsReconcileCheck) => {
        return (
          selectedRowsTally.length === rowDataIsReconcileCheck.length &&
          selectedRowsTally.every((element_1) =>
            rowDataIsReconcileCheck.some((element_2) =>
              Object.keys(element_1).every((key) => element_1[key] === element_2[key])
            )
          )
        );
      };
      if (!compareTwoArrayOfObjects(selectedRows, selectedIsReconcile)) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('Please Save & close Selected JE ID') });
        return false;
      }
    }

    this.page = pageNumber - 1;
    this.setupPercentageService.setIsJECreated(false)
    if (pageNumber <= 0) {
      return false
    } else {
      if (this.componentName == "workflowStatus") {
        clearInterval(this.myVar);
        this.myVar = null;
      }
      this.filteredData.length || this.filterData == true ? this.getFilteredData(this.filteredDataevt) : this.getGridData()
    }
  }

  onBtnRun(event: any) {
    this.toastFlag = false;
    var selrow = this.selectedRows;
    const dialogRef3 = this.isRapid ? this.dialog.open(ExecuteWorkFlowRunRapidComponent, {
      panelClass: 'hide_dialog',
      data: {
        parms: this.gridApi,
        selrowdata: selrow,
      }
    }) : this.dialog.open((ExecuteWorkFlowRunComponent), {
      panelClass: 'hide_dialog',
      data: {
        parms: this.gridApi,
        selrowdata: selrow,
      }
    });
    dialogRef3.beforeClosed().subscribe((res) => {
      if (res?.close && this.componentName == "executeWorkflow") {
        this.runDataStatus.emit(2);
      }
    });
    dialogRef3.afterClosed().subscribe((res) => {
      if (res && this.componentName == "executeWorkflow" && dialogRef3.componentInstance.executedData.length > 0) {
        this.runDataStatus.emit(2);
      }
      if (res && res.close) {
        if (this.componentName == "executeWorkflow") {
          clearInterval(this.myVar);
          this.myVar = null;
          this.runBtnFlag = false;
          this.toastFlag = true;
          this.selectedRows = [];
        }
        this.getGridData();
      }
      if (res.msg) {
        this.toastFlag = true;
        setTimeout(() => {
          this.messageService.add(res.msg);
        }, 1000);
      }
    });
  }

  /* Name:getGridHeaderUrl
       param:null
       purpose: Get Grid Headers Url
       author: Akshar
   */
  getGridHeaderUrl() {
    if (this.componentName == 'omslist' || this.componentName == 'rapidOmslist' || this.componentName == 'usermanagementlist' || this.componentName == 'usermappinglist' || this.componentName == 'viewDataCollection' || this.componentName == 'automate-process' || this.componentName == 'transferTradeStatus' || this.componentName == 'rapidAssestBasedFee' || this.componentName == 'rapidhighVolume') {
      this.gridHeaders = apiConstants[this.componentName][this.subComponent]['headers'];
    } else {
      this.gridHeaders = apiConstants[this.componentName]['headers'];
    }
    if (this.editable && this.editableAPIList.length) {
      if (this.gridApi) {
        this.gridApi.stopEditing(true);
      }
      this.gridService.getEditableAPI(this.editableAPIList, this.rowData).subscribe((response: any) => {
        this.getGridHeader(response);
      })
    } else {
      this.getGridHeader();
    }
  }

  //for usermanagement in rapid  start
  getupdatedfunctionname() {
    if (this.componentName == 'usermanagementlist' || this.componentName == 'usermappinglist' || this.componentName == 'automate-process' || this.componentName == 'rapidOmslist' || this.componentName == 'transferTradeStatus' || this.componentName == 'rapidAssestBasedFee' || this.componentName == 'rapidhighVolume') {
      this.getNewFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.newButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions.newButtonFunction) > -1 : true;
      this.getdeleteButtonFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.deleteButtonFunction ? this.gridService.checkMultiAuth(apiConstants[this.componentName][this.subComponent]?.authFunctions.deleteButtonFunction, this.authList) : true;
      this.getuploadFunctionName = apiConstants[this.componentName][this.subComponent]?.authFunctions?.uploadButtonFunction ? this.authList.indexOf(apiConstants[this.componentName][this.subComponent]?.authFunctions.uploadButtonFunction) > -1 : true;
    }
  }
  //for usermanagement in rapid  end

  /* Name:getGridHeader
       param:null
       purpose: Grid Headers
       author: Akshar
   */
  getGridHeader(response?) {
    this.subscription1 = this.httpClient.get(this.gridHeaders).subscribe((headers: any) => {
      if (this.componentName == "customizationReportsApproval" && !(this.currentUser.authList.indexOf('SEAMLESS_ISD_CLIENT_APPROVAL_ACTION') > -1)) {
        headers[0]["checkboxSelection"] = false;
        headers[0]["headerCheckboxSelection"] = false;
        headers[0]["headerCheckboxSelectionFilteredOnly"] = false;
      }
      if (this.componentName == 'isdClientApprovalGroupFund' && !(this.currentUser.authList.indexOf('SEAMLESS_ISD_GROUP_APPROVAL_CHECK') > -1)) {
        headers[0]["checkboxSelection"] = false;
        headers[0]["headerCheckboxSelection"] = false;
        headers[0]["headerCheckboxSelectionFilteredOnly"] = false;
      }
      if (this.componentName == 'enableInvestorReDistribution' && !(this.currentUser.authList.indexOf('SEAMLESS_ISD_ENABLE_INVESTOR_REDISTRIBUTION_ACTION') > -1)) {
        headers[0]["checkboxSelection"] = false;
        headers[0]["headerCheckboxSelection"] = false;
        headers[0]["headerCheckboxSelectionFilteredOnly"] = false;
      }
      if (this.componentName == 'isdAdhocConfiguration' && !(this.currentUser.authList.indexOf('SEAMLESS_ISD_ADHOC_APPROVAL_OR_REJECTION_ACTION') > -1)) {
        headers[0]["checkboxSelection"] = false;
        headers[0]["headerCheckboxSelection"] = false;
        headers[0]["headerCheckboxSelectionFilteredOnly"] = false;
      }
      if (this.subComponent == 'allocated_order' || this.subComponent == 'unapproved_order' || this.subComponent == 'approved_order') {
        headers[0]['cellRendererParams'] = {
          findByIdAllocated: this.onBtnClickOms.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.subComponent == 'Troniq'|| this.subComponent == 'Seamless' || this.subComponent == 'SRO' || this.subComponent == 'TRO' || this.subComponent == 'SIO') {
        headers[0]['cellRendererParams'] = {
          findByIdAllocated: this.onBtnClickOms.bind(this),
          refershGrid: this.refershGrid.bind(this),
          updateCount: this.updateCount.bind(this)
        }
        if (this.componentName == "usermanagementlist" && this.subComponent == 'Seamless') {
          headers[1]['cellRendererParams'] = {
            confirmationUsersStatus: this.confirmationAllUsersStatus.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
        }
      } else if ((this.componentName == "automate-process" && this.subComponent == "dailyProcessingView")) {
        headers[0]['cellRendererParams'] = {
          runDailyProcessingCompliance: this.onBtnClickDailyProcessingView.bind(this),
          refershGrid: this.refershGrid.bind(this),
          updateCount: this.updateCount.bind(this)
        }
      } else if (this.componentName == "alertThreshold") {
        headers[0]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this)
        }
        headers[1]['cellRendererParams'] = {
          runCompliance: this.onBtnClickRunAlert.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "dailyProcessingView") {
        headers[0]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this)
        }
        headers[1]['cellRendererParams'] = {
          runDailyProcessingCompliance: this.onBtnClickDailyProcessingView.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[2]['cellRendererParams'] = {
          resumeDailyProcessing: this.onBtnClickResumeDailyProcessing.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "batchWorkList") {
        headers[5]['cellRendererParams'] = {
          batchSessionCompliance: this.onBtnClickBatchSessionView.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "customizationReportsApproval" || this.componentName == "isdClientApprovalGroupFund") {
        headers[2]['cellRendererParams'] = {
          clientApprovalFileUpload: this.onBtnClickClientApproval.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[3]['cellRendererParams'] = {
          investorSpecificDocument: this.onBtnClickInvestorSpecificDocument.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        if (this.componentName == "isdClientApprovalGroupFund") {
          headers[0]['cellRendererParams'] = {
            viewrowDataCompliance: this.onBtnClickISDRowDataReport.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
        }
      } else if (this.componentName == "isdAdhocConfiguration") {
        headers[3]['cellRendererParams'] = {
          clientApprovalFileUpload: this.onBtnClickClientApproval.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[4]['cellRendererParams'] = {
          investorSpecificDocument: this.onBtnClickInvestorSpecificDocument.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        if (this.componentName == "isdClientApprovalGroupFund") {
          headers[0]['cellRendererParams'] = {
            viewrowDataCompliance: this.onBtnClickISDRowDataReport.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
        }
      } else if (this.componentName == "activityClient") {
        headers[6]['cellRendererParams'] = {
          viewClientUploadRowData: this.onBtnClickViewClientUploadRowData.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "enterprises") {
        headers[1]['cellRendererParams'] = {
          enterpriseRowEventCompliance: this.onBtnClickEnterpriseStatus.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "inputDataIntegrityCheck") {
        headers[9]['cellRendererParams'] = {
          rowEditEventCompliance: this.onBtnClickEditComment.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "allUsers" || this.componentName == 'usersList') {
        headers[1]['cellRendererParams'] = {
          allUsersRowEventCompliance: this.onBtnClickAllUsersStatus.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "executeWorkflow") {
        headers[1]['cellRendererParams'] = {
          gridSchRowEventCompliance: this.onBtnGrdSchdBtnComponent.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[17]['cellRendererParams'] = {
          PendingDataWorkflow: this.onBtnPendingDataWorkflow.bind(this),
          ProcessDetailsWorkflow: this.onBtnPendingDataWorkflow.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[18]['cellRendererParams'] = {
          ProcessCancelDetailsWorkflow: this.onBtnProcessCancelStatusDetailsWorkflow.bind(this),
          ProcessDetailsWorkflow: this.onBtnProcessStatusDetailsWorkflow.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[20]['cellRendererParams'] = {
          errorStatusDetailsWorkflow: this.onBtnErrorStatusDetailsWorkflow.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "batchLog" || this.componentName == "rapidBatchLog") {
        headers[3]['cellRendererParams'] = {
          batchDownloadDetail: this.onBtnbatcDownlaodhDetail.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[4]['cellRendererParams'] = {
          batchDetail: this.onBtnbatchDetail.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') {
        headers[0]['cellRendererParams'] = {
          batchScduleDetail: this.onBtnbatcSchduleDetail.bind(this),
          updateSchdule: this.onBtnupdateSchdule.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') {
        headers[0]['cellRendererParams'] = {
          updateSchdule: this.onBtnupdateSchdule.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "batch" || this.componentName == "rapidBatch") {
        headers[0]['cellRendererParams'] = {
          batchScduleDetail2: this.onBtnbatcSchduleDetail2.bind(this),
          updateBatch: this.onBtnupdateBatch.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "batch" || this.componentName == "rapidBatch") {
        headers[0]['cellRendererParams'] = {
          batchDetailScdule: this.onBtnbatcDetailSchdule.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "userCoaList") {
        headers[0]['cellRendererParams'] = {
          batchScduleDetail2: this.onBtnbatcSchduleDetail2.bind(this),
          updateBatch: this.onBtnupdateBatch.bind(this),
          updateCount: this.updateCount.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      }
      else if (this.componentName == "bill" || this.componentName == "rapidBill" || this.componentName == "unpaidBills" || this.componentName == "paidBills" || this.componentName == "rapidInvoice" || this.componentName == "cashIncomeList") {
        headers[0]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "accountSummary" || this.componentName == "rapidAccountSummary") {
        headers[0]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
        headers[5]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      }
      else if (this.componentName == "paymentsComponent" || this.componentName == 'rapidPaymentsComponent' || this.componentName == 'rapidreciptsComponent') {

        headers[5]['cellRendererParams'] = {
          updatePayment: this.onBtnupdatePayableDetails.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "workflowStatus" || this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus") {
        if (this.componentName == "workflowStatus") {
          headers[16]['cellRendererParams'] = {
            errorStatusDetailsWorkflow: this.onBtnErrorStatusDetailsWorkflow.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
          headers[18]['cellRendererParams'] = {
            PendingDataWorkflow: this.onBtnPendingDataWorkflow.bind(this),
            ProcessDetailsWorkflow: this.onBtnPendingDataWorkflow.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
          headers[3]['cellRendererParams'] = {
            runProceeAllCompliance: this.onBtnClickProceedAllView.bind(this),
            ProcessCancelDetailsWorkflow: this.onBtnProcessCancelStatusDetailsWorkflow.bind(this),
            ProcessDetailsWorkflow: this.onBtnProcessStatusDetailsWorkflow.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
        } else {
          headers[13]['cellRendererParams'] = {
            errorStatusDetailsWorkflow: this.onBtnErrorStatusDetailsWorkflow.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
        }
      } else if (this.componentName == 'customizationISDistribution' || this.componentName == 'enableInvestorReDistribution' || this.componentName == 'isdAdhocDistribution' || this.componentName == 'isdEnableInvAdhocReDistribution') {
        headers[0]['cellRendererParams'] = {
          viewrowDataCompliance: this.onBtnClickViewRowDataReport.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == 'isdClientApprovedGroup' || this.componentName == 'isdNotificationStatusGroup') {
        headers[0]['cellRendererParams'] = {
          viewrowDataCompliance: this.onBtnClickISDRowDataReport.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == "reportNotificationStatus" || this.componentName == "reportNotificationStatusList" || this.componentName == 'customizationReportNotificationStatusList') {
        headers[1]['cellRendererParams'] = {
          viewrowDataCompliance: this.onBtnClickViewRowDataReport.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        headers[6]['cellRendererParams'] = {
          reportNotisficationCompliance: this.onBtnClickReportNotification.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
      } else if (this.componentName == 'FSAutomation' || this.componentName == 'archivedFSAutomation' || this.componentName == 'FSAutomationUnlock') {
        headers[0]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this),
          refershGrid: this.refershGrid.bind(this)
        }
        if (this.componentName == 'FSAutomation' || this.componentName == 'archivedFSAutomation') {
          headers[9]['cellRendererParams'] = {
            updateCount: this.updateCount.bind(this),
            refershGrid: this.refershGrid.bind(this)
          }
        }
      } else {
        headers[0]['cellRendererParams'] = {
          updateCount: this.updateCount.bind(this)
        }
      }
      this.columns = this.commonService.formatDataUsingGridHeaders(headers);

      if (this.componentName == 'reportingPortalUser') {
        this.columns.splice(6, 1);
      }
      if (this.componentName == "usermanagementlist" && this.subComponent == 'TRO') {
        this.columns.splice(8, 1);
      }
      /*
      *Grid Header Translate
      *Auth : VG 
      */
      this.columns.forEach((element: any) => {
        let heder = element.headerName;
        this.translateService.get([heder]).subscribe((traval) => {
          if (traval[heder] != undefined || traval[heder] != null) {
            element.headerName = traval[heder];
          }
        });

        if (element.filter == 'agNumberColumnFilter' || element.numberFormat == true || element.field == 'investorNumber' || element.field == 'commission' || element.field == 'otherCommission' || element.field == 'exchangeRateTrade' || element.field == 'orderId' || element.field == 'accountNumber' || element.field == 'tradeOrderedApproved' || element.field == 'discretionary' || element.field == 'executingBrokerCode' || element.field == 'portfolioBusinessUnit') {
          element['type'] = 'rightAligned';
        }
        if (element.field == 'netConsideration' || element.field == 'localAmount' || element.field == 'baseAmount' || element.field == 'baseClosingBalance' || element.field == 'totalQuantity' || element.field == 'price' || element.field == 'localClosingBalance') {
          element['valueFormatter'] = this.commonService.numberformatTotal;
        }
        if ((this.componentName == 'omslist' || this.componentName == 'rapidOmslist' || this.componentName == 'usermanagementlist' || this.componentName == 'orderList' || this.componentName == 'positionBroker' || this.componentName == 'rapidPositionBroker' || this.componentName == 'assetBasedFeeList' || (this.componentName == 'rapidAssestBasedFee' && this.subComponent == 'assetBasedFeeList') || this.componentName == 'sourceDestTradeTransferTaxLotInfo' || (this.componentName == 'transferTradeStatus' && this.subComponent == 'transferTradeHistory') || this.componentName == 'caspecialdividend' || this.componentName == 'tradeArchiveList' || this.componentName == 'rapidTradeArchiveList' || this.componentName == 'dividendaccrual' || this.componentName == 'rapidDividend' || this.componentName == 'casplit' || this.componentName == 'viewjesummary' || this.componentName == 'jeSummary' || this.componentName == 'rapidjesummary') && element.field == 'dividendRate' || element.field == 'marketValueTotalLocal' || element.field == 'marketValueTotalBase' || element.field == 'totalCostBase' || element.field == 'totalCostLocal' || element.field == 'fearLongTradingLevel' || element.field == 'reductionInCarryForwardPAndLOnBeginRedemption' || element.field == 'taxWithHolding' || element.field == 'dayBeginCapital' || element.field == 'splitToSymbol1' || element.field == 'splitToSymbol2' || element.field == 'splitToSymbol3' || element.field == 'dividendPerShare' || element.field == 'transferBalance' || element.field == "quantity" || element.field == "localAmount" || element.field == "baseAmount" || ((this.componentName == 'historicalwashsaleopenLot' || this.componentName == 'rapidHistoricalwashsaleopenLot') && element.field == 'tradePrice') || (this.componentName == 'smFutureList' && element.field == 'multiplier') || (this.componentName == 'tradeEntryBroker' && element.field == 'balance') || (this.componentName == 'rapidTradeEntryBroker' && element.field == 'balance')) {
          element['valueFormatter'] = this.commonService.numberformatTotal;
        }
        if ((this.componentName == 'viewtotalgl') && (element.field == "totalCostLocal" || element.field == "totalCostBase" || element.field == "unrealizedGainLocal" || element.field == "unrealizedGainPriceBase" || element.field == "unrealizedGainFXBase" || element.field == "unrealizedGainTotalBase")) {
          element['valueFormatter'] = this.commonService.numFormat;
        }

        if ((this.componentName == 'tradeList') && (element.field == "baseAmount" || element.field == "quantity" || element.field == "nfaFees" || element.field == "localAmount" || element.field == "ticketCharges" || element.field == "commission" || element.field == "exchangeFees" || element.field == "clearingFee" || element.field == "parValue" || element.field == "purchaseInterest" || element.field == "sellInterest")) {
          element['valueFormatter'] = this.commonService.numFormat;
        }
        if ((this.componentName == 'riskMaster' || this.componentName == 'specialAllocation' || this.componentName == 'rapidSpecialAllocation') && (element.field == "allocationRatio" || element.field == "delta" || element.field == "gamma" || element.field == "vega" || element.field == "theta" || element.field == "impliedVolatility" || element.field == "realizedVolatility" || element.field == "beta" || element.field == "tradingVolume" || element.field == "tradingVolumeLast30Days" || element.field == "tradingVolumeLast200Days" || element.field == "marketCap")) {
          element['valueFormatter'] = this.commonService.numFormat;
        }
        if ((this.componentName == 'transferTradeRawDataHistory' || this.componentName == 'RapidTransferTradeRawDataHistory') && element.field == "transferBalance") {
          element['valueFormatter'] = this.commonService.numFormatuptoEightDigits;
        }
        if ((this.componentName == 'sourceDestTradeTransferTaxLotInfo' || (this.componentName == 'transferTradeStatus' && this.subComponent == 'transferTradeHistory')) && element.field == "applicableBalance") {
          element['valueFormatter'] = this.commonService.numFormatuptoEightDigits;
        }
        if ((this.componentName == 'sourceDestTradeTransferTaxLotInfo' || (this.componentName == 'transferTradeStatus' && this.subComponent == 'transferTradeHistory')) && element.field == "remainingBalance") {
          element['valueFormatter'] = this.commonService.numFormatuptoEightDigits;
        }

        if (element.cellRenderer === 'editBtnCellRenderer') {
          // Set common cellRendererParams
          element.cellRendererParams = {
            onRowEdit: this.onRowEdit.bind(this),
            onRowSave: this.onRowEditingSave.bind(this),
            onRowCancel: this.onRowEditingCancel.bind(this)
          };

          // Additional params for 'taxAdjusted' component
          if (this.componentName === "taxAdjusted") {
            Object.assign(headers[0].cellRendererParams, {
              refershGrid: this.refershGrid.bind(this),
              updateCount: this.updateCount.bind(this)
            });
          }
        }
        if (element.editable && element.renderType == 'dropdown') {
          let apiDetails = this.editableAPIList.filter(item => { return item.updateField.includes(element.updateField) })[0];
          if (apiDetails) {
            let index = apiDetails["key"];
            let label = apiDetails["label"];
            let value = apiDetails["value"];
            let fields = apiDetails["fields"];
            let legalName = apiDetails["legalName"];
            let marketData = apiDetails["marketData"]
            let isLookup = apiDetails["isLookup"]
            let resultList = (response[index] && Array.isArray(response[index])) ? response[index] :
              (response[index] && response[index].resultList) ? response[index].resultList : (response[index] && response[index].data) ? response[index].data : [];
            let result = [];
            if (fields) {
              resultList.forEach(element => {
                element.fields.forEach(item => {
                  result.push({ label: item.value, value: isLookup ? element.id : item.value })
                })
              });
              element["cellEditorParams"].values = result;
            } else if (legalName) {
              resultList?.map(item => {
                var legalFieldName = item.legalName &&
                  item.legalName['fields'] &&
                  item.legalName['fields'].length &&
                  item.legalName['fields'][0].value ? item.legalName['fields'][0].value : "";
                var isExistLegalName = result.filter((list) => {
                  return list?.label == legalFieldName;
                });

                if (!isExistLegalName.length && legalFieldName) {
                  result.push({
                    label: legalFieldName,
                    value: legalFieldName
                  });
                }
              })
              element["cellEditorParams"].values = result;
            } else if (marketData) {
              result = resultList;
              element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: item.id }));
            } else {
              result = resultList;
              element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: value ? item[value] : item.id }));
            }
          }
        }
      });
      let columns = JSON.parse(JSON.stringify(this.columns));
      if (this.componentName == "reportNotificationStatus" || this.componentName == "reportNotificationStatusList" || this.componentName == 'customizationReportNotificationStatusList') {
        this.columnDataforSmartTable = columns.slice(2, 7);
      } else if (this.componentName == "tradeList" || this.componentName == "setupbondinterest" || this.componentName == 'rapidSetupbondinterest' || this.componentName == "dividendaccrual" || this.componentName == 'rapidDividend' || this.componentName == "capitalActivities" || this.componentName == "rapidCapitalActivities" || this.componentName == "viewclosedtaxLots") {
        this.columnDataforSmartTable = columns.slice(1, 7);
      } else if (this.componentName == "orderList" || this.componentName == "benchmark" || this.componentName == "rapidBenchmark") {
        this.columnDataforSmartTable = columns.slice(1, 8);
      } else if (this.componentName == "runViewOpenTaxlots" || this.componentName == "newIssues" || this.componentName == "rapidNewIssues" || this.componentName == "marketdata" || this.componentName == "rapidMarketdata") {
        this.columnDataforSmartTable = columns.slice(1, 5);
      } else if (this.componentName == "investorgroup" || this.componentName == "rapidInvestorgroup") {
        this.columnDataforSmartTable = columns.slice(1, 4);
      } else if (this.componentName == "customRatio" || this.componentName == "rapidCustomRatio" || this.componentName == "taxCustomRatio") {
        this.columnDataforSmartTable = columns.slice(1, 5);
      } else if (this.componentName == "excludeComposite") {
        this.columnDataforSmartTable = columns.slice(1, 5);
      } else if (this.componentName == "tradeAllocationConfig") {
        this.columnDataforSmartTable = columns.slice(1, 4);
      } else if (this.componentName == "rapidExternalROR") {
        this.columnDataforSmartTable = columns.slice(0, 6);
      } else {
        this.columnDataforSmartTable = columns.slice(1, 6);
      }
      setTimeout(() => {
        if (this.isToggled) {
          !this.myFlagForSlideToggle ? this.setDetailedDefinition() : this.setSmartDefinitions();
        } else {
          this.setDetailedDefinition();
        }
      }, 200)
    });
  }

  setSmartDefinitions() {
    this.columnDefs = _.cloneDeep(this.columns);
    this.sideBar = null;
    this.gridApi.setSideBar(null);
    if (this.componentName == "orderList" || this.componentName == "benchmark" || this.componentName == "rapidBenchmark") {
      this.columnDefs = _.cloneDeep(this.columnDefs).slice(1, 8);
    } else if (this.componentName == "runViewOpenTaxlots" || this.componentName == "newIssues" || this.componentName == "rapidNewIssues" || this.componentName == "marketdata" || this.componentName == "rapidMarketdata") {
      this.columnDefs = _.cloneDeep(this.columnDefs).slice(1, 5);
    } else if (this.componentName == "tradeList" || this.componentName == "setupbondinterest" || this.componentName == 'rapidSetupbondinterest' || this.componentName == "dividendaccrual" || this.componentName == 'rapidDividend' || this.componentName == "capitalActivities" || this.componentName == "rapidCapitalActivities" || this.componentName == "viewclosedtaxLots") {
      this.columnDefs = _.cloneDeep(this.columnDefs).slice(1, 7);
    } else if (this.componentName == "investorgroup" || this.componentName == "rapidInvestorgroup") {
      this.columnDefs = _.cloneDeep(this.columnDefs).slice(1, 4);
    } else if (this.componentName == "rapidExternalROR") {
      this.columnDefs = _.cloneDeep(this.columnDefs).slice(0, 5);
    } else {
      this.columnDefs = _.cloneDeep(this.columnDefs).slice(1, 6);
    }
    this.columnDefs.forEach((element: any) => {
      element.width = 250;
    });
    setTimeout(() => {
      this.gridApi.setColumnDefs(this.columnDefs);
    }, 200);
  }

  setDetailedDefinition() {
    this.columnDefs = new Array;
    this.columnDefs = _.cloneDeep(this.columns);
    if (this.gridApi) {
      this.gridApi.setColumnDefs([]);
      this.gridApi.setColumnDefs(this.columnDefs);
      this.sideBar = this.defaultsideBar;
    }

  }

  onBtnClickOms(val: any) {
    this.getDataForOmsAllo.emit(val.rowData);
  }

  onBtnClickRunAlert(val?: any) {
    if (val) {
      this.runALertCompliance.emit(val);
    } else {
      this.runALertCompliance.emit(this.selectedRows);
    }
  }

  onBtnClickDailyProcessingView(val: any) {
    this.runDailyProcessingCompliance.emit(val.rowData);
  }

  onBtnClickProceedAllView(val: any) {
    this.runProceeAllCompliance.emit(val);
  }

  onBtnClickBatchSessionView(val: any) {
    this.batchId = val.rowData.batchId;
    localStorage.setItem('batchID', this.batchId)
    this.batchSessionCompliance.emit(val.rowData);
  }

  onBtnClickResumeDailyProcessing(val: any) {
    this.resumeDailyProcessing.emit(val.rowData);
  }

  onBtnClickClientApproval(val: any) {
    this.clientApprovalFileUpload.emit(val.rowData);
  }

  onBtnClickInvestorSpecificDocument(val: any) {
    this.investorSpecificDocument.emit(val.rowData);
  }

  onBtnClickViewClientUploadRowData(val: any) {
    this.viewClientUploadRowData.emit(val.rowData);
  }

  onBtnClickSendMailToCompliance(val: any) {
    this.sendMailClientTemplate.emit(val.rowData);
  }

  onBtnClickEnterpriseStatus(val: any) {
    this.enterpriseRowEventCompliance.emit(val.rowData);
  }

  onBtnClickEditComment(val: any) {
    this.enterpriseRowEventCompliance.emit(val.rowData);
  }

  onBtnClickAllUsersStatus(val: any) {
    this.allUsersRowEventCompliance.emit(val.rowData);
  }

  onBtnGrdSchdBtnComponent(val: any) {
    this.gridSchRowEventCompliance.emit(val.rowData);
  }

  onBtnErrorStatusDetailsWorkflow(val: any) {
    this.errorStatusDetailsWorkflow.emit(val.rowData);
  }

  onBtnProcessStatusDetailsWorkflow(val: any) {
    this.ProcessDetailsWorkflow.emit(val.rowData);
  }

  onBtnProcessCancelStatusDetailsWorkflow(val: any) {
    this.ProcessCancelDetailsWorkflow.emit(val.rowData);
  }

  confirmationAllUsersStatus(val: any) {
    this.confirmationUsersStatus.emit(val.rowData);
  }

  onBtnPendingDataWorkflow(val: any) {
    this.ProcessCancelDetailsWorkflow.emit(val.rowData);
  }

  onBtnbatchDetail(val: any) {
    this.batchDetail.emit(val.rowData);
  }

  onBtnbatcDownlaodhDetail(val: any) {
    this.batchDownloadDetail.emit(val.rowData);
  }

  onBtnbatcSchduleDetail(val: any) {
    this.batchScduleDetail.emit(val.rowData);
  }

  onBtnbatcSchduleDetail2(val: any) {
    this.batchScduleDetail2.emit(val.rowData);
  }

  onBtnupdateSchdule(val: any) {
    this.updateSchdule.emit(val.rowData);
  }

  onBtnupdateBatch(val: any) {
    this.updateBatch.emit(val.rowData);
  }

  onBtnupdatePayableDetails(val: any) {
    this.updatePayment.emit(val.rowData);
  }

  onBtnbatcDetailSchdule(val: any) {
    this.batchDetailScdule.emit(val.rowData);
  }

  onBtnClickReportNotification(val: any) {
    this.reportNotisficationCompliance.emit(val.rowData);
  }

  onBtnClickViewRowDataReport(val: any) {
    this.viewrowDataCompliance.emit(val.rowData);
  }

  onBtnClickISDRowDataReport(val: any) {
    this.viewrowDataCompliance.emit(val);
  }

  refershGrid() {
    if (this.componentName == "workflowStatus") {
      clearInterval(this.myVar);
      this.myVar = null;
    }
    if (this.componentName == "executeWorkflow") {
      localStorage.removeItem('workflowTreeValue');
    }
    this.getGridData();
  }

  updateCount(count: any) {
    if (!this.isFryFilterAct) {
      this.totalRecords = Number(this.totalRecords) - count;
      if (this.totalRecords == 0) {
        this.setuppercentage.setDeleteTrigger(true)
      }
    }
  }

  /* Name:onQuickFilterChanged
         param:null
         purpose: To search value from one search box in whole grid
         author: Seema
         project:TaxAutomation
     */
  onQuickFilterChanged() {
    this.gridApi.setQuickFilter(this.quickFilterText);
  }

  /* Name:getGridDataUrl
       param:null
       purpose: Get Grid Data Url
       author: Akshar
   */
  getGridDataUrl() {
    this.gridDataUrl = apiConstants[this.componentName]['gridDataUrl'];
    this.gridDeleteUrl = apiConstants[this.componentName]['gridDeleteUrl'];
    this.moduleName = apiConstants[this.componentName]['moduleName'];
    this.exportModuleName = apiConstants[this.componentName]['exportModuleName'];
    this.gridExportUrl = apiConstants[this.componentName]['gridExportUrl'] ? apiConstants[this.componentName]['gridExportUrl'].url : "";
    this.isZoneName = apiConstants[this.componentName]['gridExportUrl'] ? apiConstants[this.componentName]['gridExportUrl'].isZoneName : "";
    this.gridFilteredUrl = apiConstants[this.componentName]['gridFilteredUrl'] ? apiConstants[this.componentName]['gridFilteredUrl'].url : "";
    this.orderFilter = apiConstants[this.componentName]['filterUrl'] ? apiConstants[this.componentName]['filterUrl'].orderFilter : "";
    this.filterUrl = apiConstants[this.componentName]['filterUrl'] ? apiConstants[this.componentName]['filterUrl'].url : "";
    this.filterDeleteUrl = apiConstants[this.componentName]['filterDeleteUrl'] ? apiConstants[this.componentName]['filterDeleteUrl'].url : "";
    this.routeUrl = apiConstants[this.componentName]['routeUrl'] ? apiConstants[this.componentName]['routeUrl'] : {};
    this.method = apiConstants[this.componentName]['method'] ? apiConstants[this.componentName]['method'] : 'get';
    this.customFilterUrl = apiConstants[this.componentName]['customFilterUrl']
    this.customFilterUrls = apiConstants[this.componentName]['customfilterurls']
    this.getCustomTypeUrl = apiConstants[this.componentName]['getCustomTypeUrl']
    this.workflowTreeValue = JSON.parse(localStorage.getItem('workflowTreeValue'));
    this.gridFilteredModuleUrl = apiConstants[this.componentName]['gridFilteredModuleUrl'] ? apiConstants[this.componentName]['gridFilteredModuleUrl'].url : "";
    this.newgridExportUrl = apiConstants[this.componentName]['newgridExportUrl'] ? apiConstants[this.componentName]['newgridExportUrl'].url : "";
    let systemAdminView = ["custodianList", "custodianListLegacy", "setupcustodianList", "FundList", "setupFundList", "amcIMProgram", "setupamcIMProgram"]
    if (this.workflowTreeValue) {
      this.method = "post";
    } else {
      this.method = apiConstants[this.componentName]['method'] ? apiConstants[this.componentName]['method'] : 'get';
    }
    if (this.componentName == "chartOfAccountTaxClassification" || this.componentName == 'customizationReportsApproval' || this.componentName == 'chartOfAccounttaxClassification' || this.componentName == 'customizationISDistribution' || this.componentName == 'isdClientApprovedGroup' || this.componentName == "isdEmailTemplateConfiguration" || this.componentName == "isdClientApprovalGroupFund" || this.componentName == "isdAdhocDistributionConfiguration" || this.componentName == 'FSAutomation' || this.componentName == 'archivedFSAutomation' || this.componentName == 'FSAutomationUnlock' || this.componentName == "isdAdhocConfiguration" || this.componentName == 'taxReturnPackageNew') {
      this.rowSelection = "single";
    }
    if (this.componentName == "orderlist") {
      this.gridDataUrl = this.gridDataUrl;
      this.isExportBtn = true;
      this.showExport = true;
    } else if (this.componentName == "runViewOpenTaxlots") {
      this.getBrokerAccountrList();
      this.gridDataUrl = this.gridDataUrl;
    } else if (this.componentName == "omslist" || this.componentName == 'rapidOmslist' || this.componentName == "viewDataCollection" || this.componentName == "usermanagementlist" || this.componentName == "usermappinglist" || this.componentName == 'automate-process' || this.componentName == 'transferTradeStatus' || this.componentName == 'rapidAssestBasedFee' || this.componentName == 'rapidhighVolume') {
      this.filterUrl = apiConstants[this.componentName][this.subComponent]['filterUrl'] ? apiConstants[this.componentName][this.subComponent]['filterUrl'].url : "";
      if (this.subComponent == 'unallocated_order') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'] + this.page + '&orderStatus=false&role=' + this.currentRole;
        this.isExportBtn = false;
        this.isCustomRangeBtn = false;
        this.rowSelection = "single";
      } else if (this.subComponent == 'allocated_order') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'] + this.page + '&orderStatus=true&role=' + this.currentRole;
        this.isExportBtn = false;
        this.isCustomRangeBtn = false;
      } else if (this.subComponent == 'unapproved_order') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'] + this.page + '&orderStatus=true&role=' + this.currentRole + '&sendEmail=true';
        this.isExportBtn = false;
        this.isCustomRangeBtn = false;
      } else if (this.subComponent == 'approved_order') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'] + this.page + '/true/Approved/' + this.currentRole + '/' + moment(this.customeDateRange[0]).format('YYYY-MM-DD') + '/' + moment(this.customeDateRange[1]).format('YYYY-MM-DD');
        this.isExportBtn = true;
        this.isCustomRangeBtn = true;
      } else if (this.subComponent == 'Real Time Positions') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'] + this.page;
        this.isExportBtn = true;
        this.isCustomRangeBtn = false;
      } else if (this.subComponent == 'Trade' || this.subComponent == 'Balance' || this.subComponent == 'MarketData' || this.subComponent == 'Position' || this.subComponent == 'GenericData' || this.subComponent == 'Transfers') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl']
      }

      else if (this.subComponent == 'Troniq' || this.subComponent == 'Seamless'|| this.subComponent == 'SRO' || this.subComponent == 'TRO'|| this.subComponent == 'SIO' || this.subComponent == 'connectUserInvestor' || this.subComponent == 'connectCustodianInvestor') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'] + this.page;
        this.moduleName = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['moduleName'] : ''
        this.routeUrl = apiConstants[this.componentName][this.subComponent]['routeUrl'] ? apiConstants[this.componentName][this.subComponent]['routeUrl'] : {};
        this.gridExportUrl = apiConstants[this.componentName][this.subComponent]['gridExportUrl'] ? apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url : {};
      } else if (this.subComponent == 'dailyProcessingView') {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl']
        this.moduleName = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['moduleName'] : ''
        this.routeUrl = apiConstants[this.componentName][this.subComponent]['routeUrl'] ? apiConstants[this.componentName][this.subComponent]['routeUrl'] : {};
      } else if (this.subComponent == 'dailyProcessingStatus') {
        this.moduleName = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['moduleName'] : ''
        this.routeUrl = apiConstants[this.componentName][this.subComponent]['routeUrl'] ? apiConstants[this.componentName][this.subComponent]['routeUrl'] : {};
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl']
        this.gridExportUrl = apiConstants[this.componentName][this.subComponent]['gridExportUrl']
        this.gridFilteredUrl = apiConstants[this.componentName][this.subComponent]['gridFilteredUrl'].url;
      } else {
        this.gridDataUrl = apiConstants[this.componentName][this.subComponent]['gridDataUrl'];
        this.moduleName = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['moduleName'] : ''
        this.gridExportUrl = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url : "";
        this.gridFilteredUrl = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['gridFilteredUrl'].url : "";
        this.filterUrl = apiConstants[this.componentName][this.subComponent] ? apiConstants[this.componentName][this.subComponent]['filterUrl'].url : "";
        this.routeUrl = apiConstants[this.componentName][this.subComponent]['routeUrl'] ? apiConstants[this.componentName][this.subComponent]['routeUrl'] : {};
      }
      if (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadEntry') {
        this.isExportBtn = true;
        this.isCustomRangeBtn = true;
        this.btnPreviousDate = false;
        this.processButton = true;
      } else if (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadHV') {
        this.isCustomRangeBtn = false;
        this.processButton = false;
      }
    } else if (this.componentName == 'tradeUploadEntryHV') {
      this.isExportBtn = true;
      this.isCustomRangeBtn = true;
      this.btnPreviousDate = false;
      this.processButton = true;
    } else if (this.componentName == 'tradeSucessListHV' || this.componentName == 'rapidTradeSucessListHV') {
      this.failureListCount = localStorage.getItem('failureListCount');
      this.sucessUploadBtn = true;
    } else if (systemAdminView.includes(this.componentName)) {
      this.filterUrl = apiConstants[this.componentName]['filterUrl'].url + ((this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? "/true" : "/false");
      this.gridDataUrl = apiConstants[this.componentName]['gridDataUrl'] + ((this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? "true/" : "false/");
    } else if (this.componentName == "investorsList" || this.componentName == "investors" || this.componentName == 'rapidInvestors' || this.componentName == "setupInvestorsList" || this.componentName == "taxInvestorsList") {
      this.filterUrl = apiConstants[this.componentName]['filterUrl'].url + this.isEnterprise + '/' + this.isSystemAdmin;
    } else {
      this.gridDataUrl = this.gridDataUrl;
    }
    if (this.componentName == "executeWorkflow" || this.componentName == "workflowStatus") {
      clearInterval(this.myVar);
      this.myVar = null;
    }
    this.getGridData();
  }

  processOfTradeUploadHV() {
    if (this.isDoubleClick) return;
    this.isDoubleClick = true;
    var customeDateRange = this.customeDateRange[0];
    this.result = this.commonService.getPeriodLockmessage(customeDateRange, this.fundPeriodLockDate, this.currentUser.periodLockDate)
    if (this.result != false) {
      let url = apiConstants[this.componentName]['processUrl'] + this.customeDateRange[0] + 'LHRXJ10EWRdateZRHXJ10JFK' + this.customeDateRange[1]
      if (this.componentName == 'rapidhighVolume') {
        url = apiConstants[this.componentName]['tradeUploadEntry']['processUrl'] + this.customeDateRange[0] + 'LHRXJ10EWRdateZRHXJ10JFK' + this.customeDateRange[1]
      }
      this.gridService.getGridData(url, (cbs) => {
        this.isDoubleClick = false;
        this.messageService.add({ severity: 'success', summary: 'trade Upload entry', detail: 'total records for grouping ' + cbs.groupCount + '\n' + 'total group records ' + cbs.totalCount });
        this.getGridData();
      }, (cbe) => {
        this.isDoubleClick = false;
      })
    } else {
      this.isDoubleClick = false;
    }
  }

  processOfTradeUploadHV1() {
    let tradeUploadId = localStorage.getItem('tradeUploadId');
    let url = apiConstants[this.componentName]['processUrl'] + tradeUploadId;
    this.gridService.getGridData(url, (cbs) => {
      this.messageService.add({ severity: 'success', summary: 'Trade Upload ', detail: 'Group Count' + cbs.groupCount + '\n Total Count' + cbs.totalCount });
      if (this.componentName == 'rapidTradeSucessListHV') {
        this.router.navigate(['/input/view/trade-list'])
      }
    }, (cbe) => { })
  }

  taxHistoricalData(url) {
    this.showHistoricalDataTax = true;
    this.gridService.getGridData(url, (cbs) => {
      cbs.resultList.forEach((element: any) => {
        if (element["executionDateTime"]) {
          element["executionTime"] = element["executionDateTime"].split(" ")[1].split(":").slice(0, -1).join(":");
          element["executionDateTime"] = moment(new Date(element['executionDateTime'])).format("MM/DD/YYYY HH:mm");
        }
      })
      this.rowData = cbs.resultList;
      this.totalRecords = cbs.totalCount;
      this.gridApi.setRowData(this.rowData);
    }, (cbe) => { })
  }

  /* Name:getGridData
     param:null
     purpose: Get Grid Data
     author: Akshar
  */
  getGridData() {
    if (this.subComponent == 'Troniq' || this.subComponent == 'Seamless') {
      this.getFilteredData(this.filteredDataevt)
      return
    }
    this.isFryFilterActmarketdata = false;
    if ((this.gridApi != null || this.gridApi != undefined) && this.componentName != 'chartofAccountTaxMappingDetails') {
      this.gridApi.deselectAll();
    }
    if (this.componentName == "executeWorkflow" || this.componentName == "dataImportStatusManager" ||
      this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus" || this.componentName == "workflowStatus") {
      if (!this.isFryFilterAct) {
        this.totalRecords = 0;
        this.totalFltRecords = 0;
        this.isAllocated = false;
      }
    }
    /*
    Auth:Vg
    Samart Search Filter Reset If any
    date:4/12
     */
    if (this.virtualDataOnScroll && this.virtualDataOnScroll.filter) {
      this.virtualDataOnScroll.filter = null;
    }

    if (this.method == 'post') {
      if (this.componentName == "viewjesummary" || this.componentName == 'rapidjesummary') {
        var request = {
          service: "WithoutTickerSymbol",
          page: Number(this.pageNumber) - 1
        };
        this.gridService.getGridJeSummaryReport(request).subscribe(cbs => {
          this.rowData = cbs.resultList;
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }
          this.gridApi.setRowData(this.rowData);
        }, (cbe) => {
        }, this.reqObj)
      } else if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
        this.reconcileLock = false
        var req = this.setuppercentage.getAccountSummaryReconcileGrid()
        if (req) {
          const page = Number(this.pageNumber) - 1
          this.gridService.getGridAccountSummaryReconcile(req, page).subscribe(cbs => {
            this.rowData = cbs.data
            let totalSum = 0;
            this.rowData.forEach(el => {
              if (el['crOrDr'] == "CR") {
                totalSum = totalSum - el['amount'];
              }
              else {
                totalSum = totalSum + el['amount'];
              }
            })

            this.totalAdjustment = totalSum
            this.rowData.forEach((element: any) => {
              if (element['jedate']) {
                element["jedate"] = moment(new Date(element['jedate'])).format('YYYY-MM-DD');
              }
              if (element['bankPostingDate'] && !element['isReconcile']) {
                element["bankPostingTempDate"] = moment(new Date(element['bankPostingDate'])).format('YYYY-MM-DD');
                element["bankPostingDate"] = "";
              }
              if (element['bankPostingDate'] && element['isReconcile']) {
                element["bankPostingDate"] = moment(new Date(element['bankPostingDate'])).format('YYYY-MM-DD');
              }
            })
            this.totalCount = cbs.metadata.totalCount;
            this.totalPages = Math.ceil(cbs.metadata.totalCount / 100) || 1;
            if (!this.isFryFilterAct) {
              this.totalRecords = cbs.metadata.totalCount;
            }
            if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
              this.rowData.forEach(el => {
                if (el.code) {
                  el.code = el.code
                } else {
                  el.code = el.chartOfAccount.code
                }
              })
            }
            this.gridApi.setRowData(this.rowData);
            if (this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') {
              this.isReconciliationLoaded = false
              this.glClosingBalance = this.accountSummaryData?.glEndingBalance || 0
              this.totalAdjustment = this.accountSummaryData?.totalAdjstmntsToBank || 0
              this.adjustedEndingBalance = this.accountSummaryData?.adjEndBankBalance || 0
              this.accountSummaryselectedTotal = this.accountSummaryData?.currentClosingBalance || 0
              this.unReconciledDifference = this.accountSummaryData?.unReconciledDifference || 0
              setTimeout(() => {
                this.gridApi.forEachNode((node) =>
                  node.data.isReconcile && node.setSelected(true)
                );
                setTimeout(() => {
                  this.isReconciliationLoaded = true
                  const params = { force: true, columns: ["bankPostingDate"] };
                  this.gridApi.refreshCells(params);
                }, 50)
              }, 20)
            }
          }, (cbe) => {
            if (cbe.status != 200) {
              this.componentName == "accountSummaryReconcile" ? this.router.navigate(['/reconciliation/account-summary']) : this.router.navigate(['/input/view/account-summary'])
            }
          }, this.reqObj)
        } else {
          this.componentName == "accountSummaryReconcile" ? this.router.navigate(['/reconciliation/account-summary']) : this.router.navigate(['/input/view/account-summary'])
        }
      } else if (this.componentName == "duplicateSetupInvestorsList" || this.componentName == "duplicaterapidInvestors") {

        // this.setupPercentageService.getShowGridMsg().subscribe((res) => {

        //  else {
        if (this.duplicateinvFundId) {
          const page = Number(this.pageNumber) - 1
          req = {
            "fundId": this.duplicateinvFundId
          }
          this.gridService.getDuplicateInvestor(req, page).subscribe(cbs => {
            this.rowData = cbs.resultList;
            this.totalCount = cbs.totalCount;
            this.isRowSelectable = function (rowNode) {
              return (rowNode.data?.isUserGroupingConfig == false) ? false : (rowNode.data?.approverStatus ? false : rowNode.data?.coaStatus == 'Mapped' ? false : true)
            };
            // this.totalPages = Math.ceil(cbs.totalCount) || 1;
            if (!this.isFryFilterAct) {
              this.totalRecords = cbs.totalCount;
            }
            this.gridApi.setRowData(this.rowData);
          }, (cbe) => {
          }, this.reqObj)
        }
        // })
      }
      else if (this.componentName == "processLogUser") {
        if (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) {
          this.UserType = "SystemManager";
        } else if ((this.currentUser.authList.indexOf('USER_CREATE') > -1)) {
          this.UserType = "Enterprise";
        } else {
          this.UserType = "User";
        }
        var data = {
          processLogDateRange: new Date(this.customeDateRange[0]) + 'LHRXJ10EWRdateZRHXJ10JFK' + new Date(this.customeDateRange[1]),
          "enterpriseList": [],
          "userNameList": [],
          "logLevel": this.logLevels[0],
          "page": 0,
          "user": this.UserType
        }
        this.gridService.getProcessLogPost(data).subscribe((cbs) => {
          this.rowData = cbs.resultList;
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }
        }, (cbe) => {
        }, this.reqObj)
      } else if (this.oppValue && (this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus"
        || this.componentName == "workflowStatus" || this.componentName == "executeWorkflow")) {
        this.reqObj = this.timeStampPayLoad(this.roleAs);
        this.reqObj = JSON.parse(this.reqObj);
        let custUrl;
        if (this.method == 'post') {
          custUrl = apiConstants[this.componentName]['gridDataUrl'];
        } else {
          custUrl = this.gridDataUrl;
        }
        if (this.workflowTreeValue) {
          if (this.workflowTreeValue?.componentName == 'executeWorkflow') {
            custUrl = "alteryxExecuteWorkflow/filterByKeys";
            if (this.workflowTreeValue?.data?.type == "legalName") {
              this.reqObj = {
                "isExecutionLocked": this.isExecutionLocked,
                "alteryxWorkflowMngt.sourceLegalName": this.workflowTreeValue?.data?.title,
                "page": 0
              }
            } else if (this.workflowTreeValue?.data?.type == "source") {
              this.reqObj = {
                "isExecutionLocked": this.isExecutionLocked,
                "alteryxWorkflowMngt.source": this.workflowTreeValue?.data?.title,
                "page": 0
              }
            } else if (this.workflowTreeValue?.data?.type == "workflow") {
              this.reqObj = {
                "isExecutionLocked": this.isExecutionLocked,
                "alteryxWorkflowMngt.sourceName": this.workflowTreeValue?.data?.title,
                "page": 0
              }
            } else if (this.workflowTreeValue?.data?.type == "fund" || this.workflowTreeValue?.data?.type == "Others") {
              this.reqObj = {
                "isExecutionLocked": this.isExecutionLocked,
                "fundOtherKey": this.workflowTreeValue?.data?.id,
                "page": 0
              }
            } else {
              this.method = "get";
            }
          } else if (this.workflowTreeValue?.componentName == 'workflowStatus') {
            //Auth : VG , SDI-42 Workwith Only V1
            custUrl = "configurations/filterWorkflowByKeys";
            this.custSearchKey = this.workflowTreeValue?.key;
            this.custSearchStDate = this.workflowTreeValue?.startDate;
            this.custSearchEnDate = this.workflowTreeValue?.endDate;
            if (this.workflowTreeValue?.data?.type == "legalName") {
              this.reqObj = {
                page: 0,
                executionStatus: this.custSearchKey,
                userType: this.roleAs,
                createdDate: this.changeDateToInit(new Date(this.custSearchStDate)) + "LHRXJ10EWRdateZRHXJ10JFK" + this.changeDateToEOD(new Date(this.custSearchEnDate)),
              }
            } else if (this.workflowTreeValue?.data?.type == "source") {
              this.reqObj = {
                workflowMngtId: this.workflowTreeValue?.wkNgtIds,
                inputConfigId: this.workflowTreeValue?.wkIds,
                startDate: this.custSearchStDate,
                endDate: this.custSearchEnDate,
                executionStatus: this.custSearchKey,
                redirectFrom: ''
              }
            } else if (this.workflowTreeValue?.data?.type == "workflow") {
              this.reqObj = {
                workflowMngtId: [this.workflowTreeValue?.data?.workflowMngtId],
                inputConfigId: [this.workflowTreeValue?.data?.id],
                startDate: this.custSearchStDate,
                endDate: this.custSearchEnDate,
                executionStatus: this.custSearchKey,
                redirectFrom: ''
              }
            } else if (this.workflowTreeValue?.data?.type == "ent") {
              this.reqObj = {
                startDate: this.custSearchStDate,
                endDate: this.custSearchEnDate,
                executionStatus: this.custSearchKey,
                redirectFrom: ''
              }
            } else if (this.workflowTreeValue?.data?.type == "fund" || this.workflowTreeValue?.data?.type == "Others") {
              this.reqObj = {
                page: 0,
                executionStatus: this.custSearchKey,
                userType: this.roleAs,
                createdDate: this.changeDateToInit(new Date(this.custSearchStDate)) + "LHRXJ10EWRdateZRHXJ10JFK" + this.changeDateToEOD(new Date(this.custSearchEnDate)),
              }
            } else {
              this.method = "get";
            }
          }
        }
        this.gridService.getGridDataPost(custUrl, (cbs) => {
          this.rowData = cbs.resultList;
          this.rowData.forEach((element: any) => {
            if (this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus"
              || this.componentName == "workflowStatus") {
              if (element['createdDateStr']) {
                element["createdDate"] = moment(new Date(element['createdDateStr'])).format("MM/DD/YYYY hh:mm:ss A");
              } else {
                element["createdDate"] = null;
              }
              if (element['endDateStr']) {
                element["endDate"] = moment(new Date(element['endDateStr'])).format("MM/DD/YYYY hh:mm:ss A");
              } else {
                element["endDate"] = null;
              }
              if (element['runDate']) {
                element['runDate'] = moment(new Date(element['runDate'])).format("MM/DD/YYYY");
              } else {
                element["runDate"] = null;
              }
            }
            if (this.componentName == "executeWorkflow") {
              if (element["alteryxWorkflowMngt"] && element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"]) {
                element["frequency"] = element["alteryxWorkflowMngt"]["frequency"] != null ? element["alteryxWorkflowMngt"]["frequency"] : null;
                element["executionPeriod"] = element["alteryxWorkflowMngt"]["executionPeriod"] != null ? element["alteryxWorkflowMngt"]["executionPeriod"] : null;
                element["file"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"] != null ? element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"] : null;
                let frmDate = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["lastRunDateTimeStr"];
                if (frmDate != null) {
                  element["lastRunDateTimeStr"] = moment(new Date(frmDate)).format("MM/DD/YYYY HH:MM:SS");
                  element["lastRunDateTimeStr"] = String(element["lastRunDateTimeStr"]);
                } else {
                  element["lastRunDateTimeStr"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"] != null) {
                  element["file"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"];
                } else {
                  element["file"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                  element["fileNameFormat"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                } else {
                  element["fileNameFormat"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["localFilePath"] != null) {
                  element["localFilePath"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["localFilePath"];
                } else {
                  element["localFilePath"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"] != null) {
                  element["customField"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"];
                } else {
                  element["customField"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"] != null) {
                  element["custassetClass"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"];
                } else {
                  element["custassetClass"] = " ";
                }
              }
              if (element['createdDate']) {
                element["createdDate"] = moment(new Date(element['createdDate'])).format("MM/DD/YYYY");
              } else {
                element["createdDate"] = null;
              }
              if (element['updatedDate']) {
                element["updatedDate"] = moment(new Date(element['updatedDate'])).format("MM/DD/YYYY");
              } else {
                element["updatedDate"] = null;
              }
            }
            if (this.componentName == "workflowStatus") {
              // Change execution date and time format for data import status
              if (element['createdDate']) {
                element["createdDate"] = moment(new Date(element['createdDate'])).format("MM/DD/YYYY HH:mm");
              } else {
                element["createdDate"] = null;
              }
              if (element['autoDownloadFile']) {
                var uniqueId;
                let type: string = 'Zip file';
                if (element['workflowTree']) {
                  var reqObj = {
                    uniqueId: element['workflowExecutionId'],
                    type: 'processWiseZip',
                  }
                  let url = environment.api_url + 'alteryxExecuteWorkflowV2/exportFile';

                  this.processScreenService.dataExport('alteryxExecuteWorkflow', reqObj, url);
                } else if (element['uniqueNumber']) {
                  uniqueId = element['uniqueNumber'];
                  let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId + '/' + type;
                  this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data');
                } else {
                  this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('invalid_data'), detail: this.translateService.instant('error') });
                  return;
                }
              }
            }
          })
          if (this.componentName == "workflowStatus") {
            var x = [];
            this.keepGoing = false;
            let finalVal = cbs.resultList;
            finalVal.forEach((element) => {
              if (element.status != null) {
                x.push(element);
              }

            });
            this.keepGoing = _.every(x, { 'status': 'Completed', 'fileDownloadStarted': false });
            this.processScreenService.setBtnStatus(this.keepGoing);
            if (!this.keepGoing) {
              if (!this.myVar) {
                this.myVar = setInterval(() => {
                  if (this.isTimerKill) {
                    clearInterval(this.myVar);
                    this.myVar = null;
                  } else {
                    this.getGridData()
                  }
                }, 180 * 1000);
              } else {
                clearInterval(this.myVar);
                this.myVar = null;
                this.myVar = setInterval(() => {
                  if (this.isTimerKill) {
                    clearInterval(this.myVar);
                    this.myVar = null;
                  } else {
                    this.getGridData()
                  }
                }, 180 * 1000);
              }
            } else {
              clearInterval(this.myVar);
              this.myVar = null;
            }
          }
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }
          if (this.gridApi != null || this.gridApi != undefined) {
            if (this.rowData != null || this.rowData != undefined) {
              this.gridApi.setRowData(this.rowData);
            }
          }
        }, (cbe) => {
        }, this.reqObj)
      } else if (this.componentName == "sourceConfiguration" || this.componentName == "jeSummary" || this.componentName == "inputConfiguration" || this.componentName == "customizationInputConfiguration") {
        if (this.componentName == "jeSummary") {
          this.gridDataUrl = this.gridDataUrl + this.page;
        } else {
          this.gridDataUrl = this.gridDataUrl
        }
        this.gridService.getGridDataPost(this.gridDataUrl, (cbs) => {
          if (this.componentName == "sourceConfiguration") {
            cbs.resultList.forEach((element: any) => {
              if (element.status) {
                element['scr_status'] = element['status'];
              }
              if (element.industry) {
                element['scr_industry'] = element.industry.fields[0].value
              }
              if (element.legalName) {
                element['sr_legalName'] = element.legalName.fields[0].value
              }
              //cust_Tag
              if (element.custodianTag || !element.custodianTag) {
                element['cust_Tag'] = (element.custodianTag == true ? "Yes" : "No");
              }
              if (element['sftpTypeName']) {
                element['sftpTypeName'] = atob(element.sftpTypeName);
              }
            });
          }
          this.rowData = cbs.resultList;
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }
          if (this.gridApi != null || this.gridApi != undefined) {
            if (this.rowData != null || this.rowData != undefined) {
              this.gridApi.setRowData(this.rowData);
            }
          }
        }, (cbe) => {
        }, this.reqObj)
      }
      else if (this.componentName == 'chartofAccountTaxMappingDetails' || this.componentName == 'K1Merging') {
        var configId = this.setuppercentage.getTaxGridData();
        var typeOfGrid = this.setuppercentage.getGridDataType();
        if (configId) {
          if (typeOfGrid == 'view') { this.getTaxMappingData(); }
          else {
            const page = Number(this.pageNumber) - 1
            let url = this.componentName == 'chartofAccountTaxMappingDetails' ? 'coaTaxClassification/mappingDetails/' : 'coaTaxClassification/taxInvestorMapping/'

            this.gridService.getMappingDeatils(configId, page, url).subscribe((cbs) => {
              if (this.componentName == 'K1Merging') {
                cbs?.resultList.forEach(element => {
                  if (element["k1Type"] == 'Primary') {
                    element["primaryInvestorNumber"] = null;
                  }
                });
                this.rowData = cbs?.resultList;
                this.gridApi.setRowData(this.rowData);
                this.mappingDetails['fundName'] = cbs?.fundName;
                this.mappingDetails['id'] = cbs?.fundId;
                this.mappingDetails['amendedReturnAndScheduleK1'] = cbs?.amendedReturnAndScheduleK1;
                this.mappingDetails['configTaxYear'] = cbs?.configTaxYear;
                this.setuppercentage.setMappingDataTrigger(this.mappingDetails);
                this.totalCount = cbs.totalCount;
                this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
                if (!this.isFryFilterAct) {
                  this.totalRecords = cbs.totalCount;
                }
              } else {
                this.rowData = cbs.resultList;
                this.gridApi.setRowData(this.rowData);
                this.mappingDetails['fundName'] = cbs?.fundName;
                this.mappingDetails['id'] = cbs?.id;
                this.mappingDetails['amendedReturnAndScheduleK1'] = cbs?.amendedReturnAndScheduleK1;
                this.mappingDetails['configTaxYear'] = cbs?.configTaxYear;
                this.setuppercentage.setMappingDataTrigger(this.mappingDetails);
                this.totalCount = cbs.totalCount;
                this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
                if (!this.isFryFilterAct) {
                  this.totalRecords = cbs.totalCount;
                }
              }
            }, (cbe) => {
            }, this.reqObj)
          }
        }
      }
      else if (this.componentName == 'taxAdjusted') {
        var reqObj = this.setuppercentage.getIncomeProcessPayload();
        this.fundata = this.setuppercentage.getTaxAdjustment();
        let obj = {
          "fundId": this.fundata.fundId,
          "fundName": this.fundata.fundName,
          "year": this.fundata.year
        }
        const page = Number(this.pageNumber) - 1
        let ISJEValue = this.setuppercentage.getIsJECreated();
        let data = ISJEValue ? reqObj : obj;
        let url = this.componentName == 'taxAdjusted' && ISJEValue ? 'taxationAdjustmentJE/setTaxationAdjustmentJE' : 'taxationAdjustmentJE/records/' + page
        this.gridService.getTaxAdjustmentDeatils(data, url).subscribe((cbs) => {

          this.rowData = cbs.resultList;
          this.gridApi.setRowData(this.rowData);
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }

        }, (cbe) => {
        }, this.reqObj)

      }
      else if (this.componentName == 'taxincomeAllocation') {
        this.fundata = this.setuppercentage.getTaxAllocation();
        if (this.fundata) {
          const { startOfYear, endOfYear } = this.getStartAndEndOfYear(this.fundata.year);
          this.startDate = startOfYear;
          this.endDate = endOfYear;
          let obj = {
            "fundList": [this.fundata.fundId],
            "endDate": this.endDate,
            "startDate": this.startDate,
          }
          const page = Number(this.pageNumber) - 1
          let url = 'taxIncomeAllocationRatio/viewTaxIncomeAllocation'
          this.gridService.getTaxAdjustmentDeatils(obj, url).subscribe((cbs) => {

            this.rowData = cbs.resultList;
            this.gridApi.setRowData(this.rowData);
            this.totalCount = cbs.totalCount;
            this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
            if (!this.isFryFilterAct) {
              this.totalRecords = cbs.totalCount;
            }

          }, (cbe) => {
          }, this.reqObj)
        }


      }

      else if (this.componentName == 'startupOrganizational') {
        this.fundata = this.setuppercentage.getStartOrganizational();
        // const { startOfYear, endOfYear } = this.getStartAndEndOfYear(this.fundata.year);
        // this.startDate = startOfYear;
        // this.endDate = endOfYear;
        let obj = {
          "fundId": this.fundata.fundId,
          "year": this.fundata.year
        }
        const page = Number(this.pageNumber) - 1
        let url = 'organizationalCostAndStartUp/getOrgCostCalculationRecords'
        this.gridService.getTaxAdjustmentDeatils(obj, url).subscribe((cbs) => {

          this.rowData = cbs.resultList;
          this.gridApi.setRowData(this.rowData);
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }

        }, (cbe) => {
        }, this.reqObj)

      }
      else if (this.componentName == 'chartOfAccounttaxClassification') {
        const page = Number(this.pageNumber) - 1
        let taxyear = this.setuppercentage.getTaxYear();
        // this.setuppercentage.getMappingData().subscribe(Data => {
        //   if (Data) {
        //     this.taxYear = Data.configTaxYear ? Data.configTaxYear : null;
        let url = this.isSystemAdmin ? 'taxClassificationV2/records/' : 'taxClassificationV2/fetchByTaxYear/';
        if (this.isSystemAdmin) {

          this.gridService.getchartofAccountTaxDeatils(url + page).subscribe((cbs) => {
            this.rowData = cbs?.resultList;
            this.gridApi.setRowData(this.rowData);
            this.totalCount = cbs.totalCount;
            this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
            if (!this.isFryFilterAct) {
              this.totalRecords = cbs.totalCount;
            }

          }, (cbe) => {
          }, this.reqObj)

        } else {
          this.gridService.getMappingDeatils(taxyear, page, url).subscribe((cbs) => {
            this.rowData = cbs?.resultList;
            this.gridApi.setRowData(this.rowData);
            this.totalCount = cbs.totalCount;
            this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
            if (!this.isFryFilterAct) {
              this.totalRecords = cbs.totalCount;
            }

          }, (cbe) => {
          }, this.reqObj)
        }






      }
      else {
        var currday = new Date();
        this.searchObj = {};
        this.searchObj['userType'] = 'user'
        this.searchObj['createdDate'] = currday + "LHRXJ10EWRdateZRHXJ10JFK" + this.changeDateToEOD(currday);
        let data = {
          "userType": 'user',
          "createdDate": currday + "LHRXJ10EWRdateZRHXJ10JFK" + this.changeDateToEOD(currday)
        }
        this.gridService.getGridHistoricalData(data).subscribe((cbs) => {
          this.rowData = cbs.resultList;
          this.totalCount = cbs.totalCount;
          this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.totalCount;
          }
        }, (cbe) => {
        }, this.reqObj)
      }
    }

    let gridUrl = "";
    if (this.componentName == "tradeSucessListHV" || this.componentName == 'rapidTradeSucessListHV') {
      let tradeUploadId = localStorage.getItem('tradeUploadId');
      gridUrl = this.gridDataUrl + this.page + "/" + tradeUploadId;
    } else if (this.componentName == "orderList") {
      gridUrl = this.gridDataUrl + this.page + "/" + this.isAllocated;
    } else if (this.componentName == "journalentry" || this.componentName == "RapidJournalentry") {
      gridUrl = this.gridDataUrl + this.page + "/moduleName=" + this.lookups;
    } else if (this.componentName == "alertThresholdLog") {
      gridUrl = this.gridDataUrl + this.page + "/" + this.currentUser.id;
    } else if (this.componentName == "omslist" || this.componentName == 'rapidOmslist' || this.componentName == "usermanagementlist" || this.componentName == "docManagement" || this.componentName == 'usermappinglist') {
      gridUrl = this.gridDataUrl;
    } else if (this.componentName == "investors" || this.componentName == 'rapidInvestors') {
      gridUrl = this.gridDataUrl + this.page + '/' + this.isEnterprise + "/" + this.isSystemAdmin
    } else if (this.componentName == "checkerPendingRequest" || this.componentName == 'customizationCheckerPendingRequest') {
      gridUrl = this.gridDataUrl;
    } else if (this.componentName == "reportNotificationStatusList" || this.componentName == 'customizationReportNotificationStatusList') {
      gridUrl = this.gridDataUrl + ((this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? "allworkFlow" : "enterprise/workFlow/")
    } else if (this.componentName == "doc-managementList" || this.componentName == 'customization-doc-managementList' || this.componentName == "docManagementForSetting") {
      gridUrl = this.gridDataUrl;
    } else if (this.componentName == "inputConfiguration" || this.componentName == "customizationInputConfiguration") {
      gridUrl = this.gridDataUrl + this.page;
    } else if (this.componentName == "investorsList" || this.componentName == "setupInvestorsList" || this.componentName == "taxInvestorsList") {
      gridUrl = this.gridDataUrl + this.page + "/" + this.isEnterprise + "/" + this.isSystemAdmin
    } else if (this.componentName == "reportNotificationStatus") {
      gridUrl = this.gridDataUrl
    } else if (this.componentName == "sourceConfiguration" && this.oppValue == true) {
      gridUrl = this.gridDataUrl + this.page;
    } else if (this.componentName == "executeWorkflow") {
      gridUrl = this.gridDataUrl + this.page + "?isExecutionLocked=" + this.isExecutionLocked;
    } else if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
      gridUrl = this.gridDataUrl + this.page + "?historicalData=" + this.isAllocated;
    } else if (this.componentName == "processLogUser") {
      gridUrl = this.gridDataUrl + "0";
    } else if (this.componentName == "sourceConfiguration") {
      gridUrl = this.getCustomTypeUrl;
    } else if (this.componentName == "documentManagement") {
      gridUrl = this.gridDataUrl;
    } else if (this.componentName == 'customizationEndPointScheduler') {
      gridUrl = this.gridDataUrl + '/' + this.currentUser['enterpriseId'];
    }
    else if (this.componentName == 'rapidendpointschdulerinput') {

      gridUrl = this.gridDataUrl + '/' + this.currentUser['enterpriseId'];
    }
    else if (this.componentName == "customizationReportsApproval" || this.componentName == 'customizationISDistribution' || this.componentName == 'enableInvestorReDistribution' || this.componentName == 'isdClientApprovedGroup' || this.componentName == 'isdClientApprovalGroupFund' || this.componentName == 'isdNotificationStatusGroup' || this.componentName == 'isdAdhocDistribution' || this.componentName == 'isdEnableInvAdhocReDistribution') {
      gridUrl = this.gridDataUrl;
    } else if (this.componentName == "batchLog" || this.componentName == "rapidBatchLog") {
      gridUrl = this.gridDataUrl + this.page + '/' + 100;
    } else if (this.componentName == "batch" || this.componentName == "rapidBatch") {
      gridUrl = this.gridDataUrl + this.page + '/' + 100;
    } else if (this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') {
      gridUrl = this.gridDataUrl + this.page + '/' + 100;
    }
    else if (this.componentName == "cashExpenseList" ) {
      gridUrl = this.gridDataUrl + this.page + "/" + "CashExpense"+ "/"+ "expense" ;
    }
    else if(this.componentName == "vendorCreditList"){
      gridUrl = this.gridDataUrl + this.page + "/" + "credit"+ "/"+ "credit" ;
    }
    else if (this.componentName == "bill" || this.componentName == "rapidBill") {
      gridUrl = this.gridDataUrl + this.page + "/" + "Bill"+"/" + "all";
    }
     else if (this.componentName == "unpaidBills" || this.componentName == "rapidUnpaidBills") {
      gridUrl = this.gridDataUrl + this.page + "/"  + "Bill"+"/" + "unpaid";
    }
    else if (this.componentName == "paidBills" || this.componentName == "rapidPaidBills") {
      gridUrl = this.gridDataUrl + this.page + "/"  + "Bill"+"/"+ "paid";
    }  else if (this.componentName == "rapidInvoice" ) {
      gridUrl = this.gridDataUrl + this.page + "/" + "Invoice"+"/" + "all";
    }else if (this.componentName == "rapidOutstandingInvoice" ) {
      gridUrl = this.gridDataUrl + this.page + "/" + "Invoice"+"/" + "unpaid";
    }else if (this.componentName == "paymentRecipt" ) {
      gridUrl = this.gridDataUrl + this.page + "/" + "Invoice"+"/" + "paid";
    }else if(this.componentName == "cashIncomeList"){
      gridUrl = this.gridDataUrl + this.page + "/" + "CashIncome" + "/" + "CashIncome"
    }
   
    else if (this.componentName == "reReportConfig") {
      gridUrl = this.gridDataUrl + this.page + "/" + this.isEnterprise;
    }
    else if (this.componentName == "midPeriod" && this.isValidMidPeriodInput) {
      gridUrl = this.gridDataUrl + this.selectedFundName?.id + "/" + this.selectedYear?.value + "/" + this.page;
    }
    else if (this.componentName == "midPeriod" && !this.isValidMidPeriodInput) {
      return;
    }
    else if (this.componentName == "startupOrganizational" && this.isValidMidPeriodInput) {
      gridUrl = this.gridDataUrl + this.selectedFundName?.id + "/" + this.selectedYear?.value;
    }
    else if (this.componentName == "startupOrganizational" && !this.isValidMidPeriodInput) {
      return;
    }
    else if (this.componentName == "taxAdjusted") {
      gridUrl = this.gridDataUrl;
    }

    else if (this.componentName == "viewDataCollection") {
      if (this.subComponent != this.subComponentTemp) {
        this.page = 0;
        this.pageNumber = 1;
        if (this.subComponentTemp != undefined || this.subComponentTemp != null) {
          this.formBasicInfo.form.reset();
        }
      }
      gridUrl = this.gridDataUrl + this.page + '/' + this.subComponent + "/?&enterpriseId=" + this.currentUser['enterpriseId'];
      this.subComponentTemp = this.subComponent;
    } else if (this.componentName == "dailyProcessingStatus" || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) {
      gridUrl = this.gridDataUrl + this.page + "/" + this.isSystemAdmin
    } else if (this.componentName == "workflowMapped") {
      gridUrl = this.gridDataUrl + this.page;
    } else if (this.componentName == 'batchSession') {
      let batchId = localStorage.getItem('batchID');
      gridUrl = this.gridDataUrl + batchId + '/' + this.page;
    } else if (this.componentName == "apiKeyModule") {
      if (this.roleAs === "User") {
        gridUrl = this.gridDataUrl + this.page + "/" + false
      } else {
        gridUrl = this.gridDataUrl + this.page + "/" + true
      }
    } else if (this.componentName == "fSInvestor" || this.componentName == 'fSManagementCompanyInvestor') {
      let fsInvestorData = this.gridService.getFSInvestorData();
      const { fundGroupName, fundId } = fsInvestorData;
      const baseUrl = `${this.gridDataUrl}${this.page}`;
      gridUrl =  `${baseUrl}/${fundId}/${fundGroupName}`;
    } else if (this.componentName == 'fschartOfAccountsRapid') {
      let fschartOfAccountsData = this.gridService.getFsChartOfAccounts();
      gridUrl = this.gridDataUrl + this.page + '/' + fschartOfAccountsData['configId'];
    } else if (this.componentName == 'fsCashEquivalents' || this.componentName == 'fsExposure') {
      let fsConfigData = this.gridService.getConfigData();
      gridUrl = this.gridDataUrl + this.page + '/' + fsConfigData['configId'];
    }
    else if (this.componentName == 'fsCashFlowUpdate') {
      let fsCashFlowData = this.gridService.getConfigData();
      const { startDate, endDate, configId, fundId } = fsCashFlowData;
      gridUrl = `${this.gridDataUrl}${this.page}/${fundId}/${configId}/${this.currentUser['enterpriseId']}/${startDate}/${endDate}/false/${configId}`;
    }
    else if (this.componentName === 'fSPortfolio') {
      const portfolioData = this.gridService.getFSPortFolioData();
      const {fundGroupName, endDate, configId, fundId } = portfolioData;
      const baseUrl = `${this.gridDataUrl}${this.page}`;
      gridUrl = `${baseUrl}/${endDate}/${configId}`;
    } else if (!this.oppValue && (this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus"
      || this.componentName == "workflowStatus" || this.componentName == "executeWorkflow")) {
      if (this.roleAs == "SystemManager") {
        gridUrl = this.getCustomTypeUrl + (Number(this.pageNumber) - 1);
      } else {
        if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
          gridUrl = this.getCustomTypeUrl + (Number(this.pageNumber) - 1) + "/enterprise";
        } else {
          gridUrl = this.getCustomTypeUrl + (Number(this.pageNumber) - 1) + "/user";
        }
      }
    } else {
      if (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) {
        this.isSystemAdmin = true;
        if ((this.componentName == "dailyProcessingStatus" || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) && this.UserRoles === 'SystemManager') {
          gridUrl = this.gridDataUrl + this.page + "/" + this.isAdmin
        } else if (!this.oppValue && (this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus" || this.componentName == "workflowStatus")) {
          gridUrl = this.gridDataUrl;
        } else if (this.componentName == "reportNotificationStatus" || this.componentName == "dailyProcessingView" || (this.componentName == "automate-process" && this.subComponent == "dailyProcessingView")) {
          gridUrl = this.gridDataUrl
        } else {
          gridUrl = this.gridDataUrl + this.page;
        }
      } else {
        if (this.componentName != "dailyProcessingView" && !(this.componentName == "automate-process" && this.subComponent == "dailyProcessingView") && this.componentName != "dailyProcessingStatus" && !(this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus") && this.componentName != "workflowStatus" && this.componentName != "dataImportStatus" && this.componentName != "customizationDataImportStatus" && this.componentName != "viewjesummary" && this.componentName != "jeSummary" && this.componentName != 'rapidjesummary' && this.componentName != "accountSummaryReconcile" && this.componentName != 'rapidAccountSummaryReconcile') {
          gridUrl = this.gridDataUrl + this.page;
        }
        if (this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus" || this.componentName == "workflowStatus") {
          gridUrl = this.getCustomTypeUrl;
        } else {
          if (this.componentName == 'batchSession') {
            let batchId = localStorage.getItem('batchID');
            gridUrl = this.gridDataUrl + batchId + '/' + this.page;
          }
          if (this.componentName == "dailyProcessingView" || (this.componentName == "automate-process" && this.subComponent == "dailyProcessingView")) {
            gridUrl = this.gridDataUrl
          }
          if ((this.componentName == "dailyProcessingStatus" || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) && this.UserRoles === 'User') {
            gridUrl = this.gridDataUrl + this.page + "/" + this.isSystemAdmin
          }
          if ((this.componentName == "dailyProcessingStatus" || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) && this.UserRoles === 'SystemManager') {
            gridUrl = this.gridDataUrl + this.page + "/" + this.isAdmin
          }
        }
      }
    }

    if (this.method == "get") {
      this.gridService.getGridData(gridUrl, (cbs) => {

        if (this.componentName == "customizationReportApproval") {
          cbs.resultList.forEach(element => {
            element.decimal = element.decimal?.toString();
          });
        }
        if (this.componentName == "setupInvestorsList" || this.componentName == "rapidInvestors") {
          this.isRowSelectable = function (rowNode) {
            return (rowNode.data?.isUserGroupingConfig == false) ? false : (rowNode.data?.csdEnterpriseId ? false : rowNode.data?.approverStatus ? false : rowNode.data?.coaStatus == 'Mapped' ? false : true)
          };
        }
        if (this.componentName == "midPeriod") {

          this.rowData = [];
          this.rowData = _.cloneDeep(cbs.resultList);
          if (cbs.resultList == undefined || cbs.content != undefined) {
            this.rowData = _.cloneDeep(cbs);
          }
          this.gridApi.setRowData(this.rowData);
          this.totalRecords = cbs.totalElements;
          this.totalPages = cbs.totalPages;
          this.totalCount = cbs.length;
          this.totalPages = Math.ceil(cbs.length / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.length;
          }
        }
        if (this.componentName == "FundList" || this.componentName == "setupFundList" || this.componentName == "taxFundList") {
          cbs.resultList.forEach((element: any) => {
            if (element["fundRanking"]) {
              element["fundRanking"] = element["fundRanking"] == 101 ? "" : element["fundRanking"];
            }
            if (element["isSidePocketApplicable"] == 'Yes') {
              element["iaForAutomateProcess"] = "Income Allocation Side-Pocket";
            } else if (element["isSeriesApplicable"] == 'Yes') {
              element["iaForAutomateProcess"] = "Income Allocation Series";
            } else if (element["isHurdleApplicable"] == 'Yes') {
              element["iaForAutomateProcess"] = "Income Allocation Hurdle";
            }
          })
        }

        // Set-up Start--Masking  field on grid and showing the first 4 digits and rest string by"X"
        if (this.componentName == "taxVendors" || this.componentName == "vendors" || this.componentName == "taxInvestorsList" || this.componentName == "taxFundList" || this.componentName == "setupInvestorsList" || this.componentName == "setupFundList" || this.componentName == "taxPartnershipPassthroughK1") {
          cbs.resultList.forEach(el => {
            if (el.taxId) {
              el.actualValueTaxId = el.taxId;
              el.taxId = "*********";
            }
            if (el.taxId1) {
              el.actualValueTaxId1 = el.taxId1;
              el.taxId1 = "*********";
            }
            if (el.taxId2) {
              el.actualValueTaxId2 = el.taxId2;
              el.taxId2 = "*********";
            }
            if (el.taxId3) {
              el.actualValueTaxId3 = el.taxId3;
              el.taxId3 = "*********";
            }
            if (el.disregardedTaxId) {
              el.actualValuedisregardedTaxId = el.disregardedTaxId;
              el.disregardedTaxId = "*********";
            }
            if (el.partnershipsTaxId) {
              el.actualValuePartnershipsTaxId = el.partnershipsTaxId;
              el.partnershipsTaxId = "*********";
            }
          })
        }
        // Set-up End--Masking Tax Id1 field on grid and showing the first 4 digits and rest string by"X"
        if (this.componentName == 'customizationISDistribution' || this.componentName == 'enableInvestorReDistribution' || this.componentName == 'isdClientApprovedGroup' || this.componentName == "isdAdhocDistribution" || this.componentName == 'isdEnableInvAdhocReDistribution') {
          cbs.forEach((element: any) => {
            if (element["createdOn"]) {
              element["createdOn"] = moment(new Date(element.createdOn)).format('YYYY-MM-DD');
            }
            if (element["updatedOn"]) {
              element["updatedOn"] = moment(new Date(element.updatedOn)).format('YYYY-MM-DD');
            }
          })
        }
        if (cbs.length) {
          if (this.componentName == 'dailyProcessingView' || (this.componentName == "automate-process" && this.subComponent == "dailyProcessingView")) {
            cbs.forEach((element: any) => {
              if (element["createdOn"]) {
                element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
              }
              if (element["updatedOn"]) {
                element["updatedOn"] = moment(new Date(element['updatedOn'])).format("MM-DD-YYYY");
              }
            });
          }
        }

        if (this.componentName == 'customizationReportsApproval' || this.componentName == 'isdClientApprovedGroup' || this.componentName == 'isdClientApprovalGroupFund' || this.componentName == 'isdNotificationStatusGroup') {
          cbs.forEach((element: any) => {
            if (element["approvedDateTime"]) {
              element["approvedDateTime"] = moment.utc(element['approvedDateTime']).local().format("MM-DD-YYYY HH:mm:ss A");
            }
          });
          this.rowClassRules = {
            'complete-green': function (params: any) {
              if (params.data.approverStatus == 'Approved') {
                return true;
              }
            }
          }
        }

        if (cbs.resultList != undefined || cbs.content != undefined) {
          if (this.componentName == "capitalActivities" || this.componentName == "rapidCapitalActivities") {
            cbs.resultList.forEach((element: any) => {
              if (element["createdOn"]) {
                element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
              }
              element["investorNumber"] = element.investor && element.investor.investorNumber ? element.investor.investorNumber : null;
              element["investorId"] = element.investor && element.investorId ? element.investorId : null;
            })
          }
          if (this.componentName == "isdEmailTemplateConfiguration") {
            cbs.resultList.forEach((element: any) => {
              if (element["createdOn"]) {
                element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
              }
              if (element["updatedOn"]) {
                element["updatedOn"] = moment(new Date(element['updatedOn'])).format("MM-DD-YYYY");
              }
            })
          }

          if (this.componentName == "setupFundList" || this.componentName == "FundList") {
            cbs.resultList.forEach((element: any) => {
              if (element["auaCode"]) {
                element["auaCode"] = element['auaCode'] == '1' ? 'Yes' : 'No';
              }
            })
          }

          if (this.componentName == 'sequentialProcessStatusReview' || this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == "paidBills" || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills" || (this.componentName == 'transferTradeStatus' && this.subComponent == 'transferProcessStatusDayWise' || this.componentName == 'paymentPayableData' || this.componentName == 'rapidPaymentPayableData' || this.componentName == 'cashExpenseList' || this.componentName == 'vendorCreditList' || this.componentName == "rapidInvoice" || this.componentName == "rapidOutstandingInvoice" || this.componentName == "paymentRecipt" || this.componentName == "cashIncomeList" || this.componentName == "rapidReceiptsData")) {
            cbs.resultList.forEach((element: any) => {
              if (element["transferDate"]) {
                element["transferDate"] = moment(new Date(element['transferDate'])).format("MM-DD-YYYY hh:mm");
              }
              if (element["transferProcessDate"]) {
                element["transferProcessDate"] = moment(new Date(element['transferProcessDate'])).format("MM-DD-YYYY hh:mm");
              }
              if (element["taxLotsClosingProcessDate"]) {
                element["taxLotsClosingProcessDate"] = moment(new Date(element['taxLotsClosingProcessDate'])).format("MM-DD-YYYY hh:mm");
              }
              if (element["openLotsCreationProcessDate"]) {
                element["openLotsCreationProcessDate"] = moment(new Date(element['openLotsCreationProcessDate'])).format("MM-DD-YYYY hh:mm");
              }
              if (element["billDate"]) {
                element["billDate"] = moment(new Date(element['billDate'])).format("MM-DD-YYYY");
              }
              if (element["invoiceDate"]) {
                element["invoiceDate"] = moment(new Date(element['invoiceDate'])).format("MM-DD-YYYY");
              }
              if (element["dueDate"]) {
                element["dueDate"] = moment(new Date(element['dueDate'])).format("MM-DD-YYYY");
              }
              if (element["postingDate"]) {
                element["postingDate"] = moment(new Date(element['postingDate'])).format("MM-DD-YYYY");
              }
              if (element["paymentDate"]) {
                element["paymentDate"] = moment(new Date(element['paymentDate'])).format("MM-DD-YYYY");
              }
              element["reversedEntry"] = (element["reversedEntry"] === true) ? 'Yes' : 'No';
              if (this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == "paidBills" || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills" || this.componentName == "rapidInvoice" || this.componentName == "cashIncomeList") {

                if (element['entityDetails'] && element['entityDetails'].length) {
                  const gridEntityDetails = element['entityDetails'];
                  const gridTransactionDetails = gridEntityDetails[0]['transactionTypeDetails']
                  element["exchangeRate"] = gridTransactionDetails[0]['exchangeRate']
                } else {
                  element["exchangeRate"] = 1
                }
              }
            });
          }
          if (this.componentName == "investorsaddresses" || this.componentName == "investorgroup" || this.componentName == "rapidInvestorgroup" || this.componentName == "investors" || this.componentName == 'rapidInvestors') {
            cbs.resultList.forEach((element: any) => {
              if (element["insertedOn"]) {
                element["insertedOn"] = moment(new Date(element['insertedOn'])).format("MM-DD-YYYY");
              }
              if (element["updatedOn"]) {
                element["updatedOn"] = moment(new Date(element['updatedOn'])).format("MM-DD-YYYY");
              }
            })
          }

          if (this.componentName == "batchWorkList" || this.componentName == 'batchSession') {
            cbs.resultList.forEach((element: any) => {
              if (element["createdTime"]) {
                element["createdTime"] = moment(new Date(element['createdTime'])).format("MM-DD-YYYY");
              }
              if (element["updatedTime"]) {
                element["updatedTime"] = moment(new Date(element['updatedTime'])).format("MM-DD-YYYY");

              }
            })
          }
          if (this.componentName == 'batchLog' || this.componentName == "rapidBatchLog") {
            this.rowData = cbs.content;
            this.rowData = cbs.content;
            cbs.content.forEach((element: any) => {
              element["executionDateTime"] = moment(new Date(element['executionDateTime'])).format("MM-DD-YYYY hh:mm:ss");
            });
            this.gridApi.setRowData(this.rowData);
            this.totalRecords = cbs.totalElements;
            this.totalPages = cbs.totalPages;
            return true;
          }
          if (this.componentName == 'batch' || this.componentName == 'rapidBatch') {
            this.rowData = cbs.content;
            cbs.content.forEach((element: any) => {
              element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY hh:mm:ss");
            });
            this.gridApi.setRowData(this.rowData);
            this.totalRecords = cbs.totalElements;
            this.totalPages = cbs.totalPages;
            return true;
          }
          if (this.componentName == 'scheduleTaskInfo' || this.componentName == 'rapidScheduleTaskInfo') {
            cbs.content.forEach((element: any) => {
              element["createdOn"] = moment.utc(element['createdOn']).local().format("MM-DD-YYYY HH:mm:ss");
              element["updatedOn"] = moment.utc(element['updatedOn']).local().format("MM-DD-YYYY HH:mm:ss");
              if (element['nextExecutionTime']) {
                element["nextExecutionTime"] = moment.utc(element['nextExecutionTime']).local().format("MM-DD-YYYY HH:mm:ss");
              }
            });
            this.rowData = cbs.content;
            this.gridApi.setRowData(this.rowData);
            this.totalRecords = cbs.totalElements;
            this.totalPages = cbs.totalPages;
            return true;
          }
          cbs.resultList.forEach((element: any) => {
            if (this.componentName == "taxAdditionK1Details") {
              if (element["partnerBeginningPercentageOf_Profit"]) {
                element["partnerBeginningPercentageOf_Profit"] = element['partnerBeginningPercentageOf_Profit'] + "%";
              }
              if (element["partnerBeginningPercentageOf_Loss"]) {
                element["partnerBeginningPercentageOf_Loss"] = element['partnerBeginningPercentageOf_Loss'] + "%";
              }
              if (element['partnerBeginningPercentageOf_Capital']) {
                element['partnerBeginningPercentageOf_Capital'] = element['partnerBeginningPercentageOf_Capital'] + "%";
              }
            }

            if (this.componentName == "TAXAutomation" || this.componentName == "calculateTaxAllocation") {
              if (element["taxYearBeginning"]) {
                element["taxYearBeginning"] = moment(new Date(element['taxYearBeginning'])).format("MM/DD/YYYY");
              }
              if (element["taxYearEnding"]) {
                element["taxYearEnding"] = moment(new Date(element['taxYearEnding'])).format("MM/DD/YYYY");
              }
              if (element['employerIdentificationNumber'] && this.componentName == "TAXAutomation") {
                element['actualValueemployerIdentificationNumber'] = element['employerIdentificationNumber'];
                element['employerIdentificationNumber2'] = "*********";
              }
            }

            if (this.componentName == "fSPortfolio") {
              if (element["date"]) {
                element["date"] = moment(new Date(element['date'])).format("MM-DD-YYYY");
              }
              if (element["maturityDate"]) {
                element["maturityDate"] = moment(new Date(element['maturityDate'])).format("MM-DD-YYYY");
              }
            }
            if (this.componentName == "calculateTaxAllocationStatus") {
              if (element["executionDateTime"]) {
                element["executionTime"] = element["executionDateTime"].split(" ")[1].split(":").slice(0, -1).join(":");
                element["executionDateTime"] = moment(new Date(element['executionDateTime'])).format("MM/DD/YYYY HH:mm");
              }
            }
            if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
              if (element['date']) {
                element['date'] = moment(new Date(element['date'])).format("MM/DD/YYYY");
              } else {
                element["date"] = null;
              }
            }

            if (this.componentName == 'capitalActivities' || this.componentName == "rapidCapitalActivities") {
              let shares = Number(element.shares);
              if (!Number.isNaN(shares) && shares > 0) {
                element.shares = element.shares ? element.shares : '';
              }
              if (element.transferActivity == true && element.transactionType == 'Subscription - First Time') {
                element.transactionType = 'Subscription - Transfer';
              }
              if (element.transferActivity == true && element.transactionType == 'Redemption - Partial by Amount') {
                element.transactionType = 'Redemption - Transfer';
              }
              element.effectiveAmount = element.effectiveAmount ? element.effectiveAmount.toFixed(2) : '';
            }
            if (this.componentName == 'omslist' || this.componentName == 'rapidOmslist') {
              element.executingBrokerCode = element?.executingBrokerLookup?.fields[1]?.value;
            }
            if (this.componentName == 'positionByTaxlot' && this.myFlagForSlideToggle) {
              element.currency.value = element.currency?.isoCode;
              element.symbol.value = element.symbol?.ticker;
              if (element.quantity != null) {
                element.quantity = parseInt(element.quantity);
              }
            }

            /*Auth : vg
            Upadte After Api Changes
             */
            if (this.componentName == 'dailyProcessingStatus' || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) {
              if (element["createdOn"]) {
                element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
              }
            }
            if (this.componentName == 'positionByTaxlot') {
              if (element["createdOn"]) {
                element["createdOn"] = moment(element['createdOn']).format("MM-DD-YYYY");
              }
            }
            if (this.componentName == 'rapidJeTransferlog' || this.componentName == 'notesList') {
              if (element["createdOn"]) {
                element["createdOn"] = moment(element['createdOn']).format("MM-DD-YYYY");
              }
              if (element["startDate"]) {
                element["startDate"] = moment(element['startDate']).format("MM-DD-YYYY");
              }
              if (element["endDate"]) {
                element["endDate"] = moment(element['endDate']).format("MM-DD-YYYY");
              }
            }
            if (this.componentName == 'ror-config-List' || this.componentName == 'customization-ror-config-List' || this.componentName == 'fundGroup') {
              if (element["insertedOn"]) {
                element["insertedOn"] = moment(new Date(element['insertedOn'])).format("MM-DD-YYYY");
              }
            }
            if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == 'connectionList' || this.componentName == "customization-connectionList" || this.componentName == "setupcustodianList" || this.componentName == 'tradeAdvisorList' || this.componentName != 'amcIMProgram' || this.componentName != 'setupamcIMProgram' || this.componentName != 'positionByTaxlot') {
              if (element["createdOn"]) {
                element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
              }
              if (element["updatedOn"]) {
                element["updatedOn"] = moment(new Date(element['updatedOn'])).format("MM-DD-YYYY");
              }

              if (element["isWhiteList"]) {
                element["isWhiteList"] = "Yes";
              }
              else if (element["isWhiteList"] == null) {
                element["isWhiteList"] = "";
              }
              else {
                element["isWhiteList"] = "No";
              }

              if (element["fundWorkflowSetup"]) {
                element["fundWorkflowSetup"] = "Yes";
              }
              else if (element["fundWorkflowSetup"] == null) {
                element["fundWorkflowSetup"] = "";
              }
              else {
                element["fundWorkflowSetup"] = "No";
              }
            }

            if (element.updatedOn && this.componentName != 'dailyProcessingView' && !(this.componentName == "automate-process" && this.subComponent == "dailyProcessingView") && this.componentName != 'journalentry' && this.componentName != 'RapidJournalentry' && this.componentName != 'custodianList' && this.componentName != 'custodianListLegacy' && this.componentName != "setupcustodianList" && this.componentName != 'tradeAdvisorList' && this.componentName != 'amcIMProgram' && this.componentName != 'setupamcIMProgram' && this.componentName != 'positionByTaxlot') {
              /*
              Auth:Vg
              Momet warning Fixed
              */
              let resMnt = moment(new Date(element.updatedOn)).isValid()
              if (resMnt) {
                element.updatedOn = moment(new Date(element.updatedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.updatedOn = element.updatedOn;
              }

              let resMnt1 = moment(new Date(element.createdOn)).isValid()
              if (resMnt1) {
                element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.createdOn = element.createdOn;
              }
            }

            if (element?.createdOn) {
              let resMnt1 = moment(new Date(element.createdOn)).isValid()
              if (resMnt1) {
                element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.createdOn = element.createdOn;
              }
            }
            if (element?.insertedOn) {
              let resMnt1 = moment(new Date(element.insertedOn)).isValid()
              if (resMnt1) {
                element.insertedOn = moment(new Date(element.insertedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.insertedOn = element.insertedOn;
              }
            }

            if (element["alteryxWorkflowMngt"] != null && (this.componentName == 'nonCustodianList' || this.componentName == 'customizationNonCustodianList')) {
              if (element["alteryxWorkflowMngt"][0]) {
                element["frequency"] = element["alteryxWorkflowMngt"][0]["frequency"] != null ? element["alteryxWorkflowMngt"][0]["frequency"] : null;
                element["executionPeriod"] = element["alteryxWorkflowMngt"][0]["executionPeriod"] != null ? element["alteryxWorkflowMngt"][0]["executionPeriod"] : null;
              }
            }

            if (this.componentName == 'tradeUploadEntryHV' || (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadEntry')) {
              if (element['aggregateDate']) {
                element["aggregateDate"] = moment(new Date(element['aggregateDate'])).format("MM/DD/YYYY");
              }
            }
            /*
            Componet: source config detail view fixed
            auth:vg            
            */
            if (this.componentName == "journalentry" || this.componentName == "RapidJournalentry") {
              if (element['investerACNumberDr1']) {
                element['investerACNumberDr1'] = element.investerACNumberDr1.investorNumber;
              }
              if (element['custodianACNumberCr1']) {
                element['custodianACNumberCr1'] = element.custodianACNumberCr1.accountNumber;
              }
              if (element['investerACNumberCr1']) {
                element['investerACNumberCr1'] = element.investerACNumberCr1.investorNumber;
              }
              if (element['custodianACNumberDr1']) {
                element['custodianACNumberDr1'] = element.custodianACNumberDr1.accountNumber;
              }
              if (element['bankACNumberDr1']) {
                element['bankACNumberDr1'] = element.bankACNumberDr1.vendor;
              }
              if (element['bankACNumberCr1']) {
                element['bankACNumberCr1'] = element.bankACNumberCr1.vendor;
              }
            }
            if (this.componentName != "accountSummary" && this.componentName != "rapidAccountSummary") {
              if (element['status']) {
                element['scr_status'] = element.status;
              }
              if (element.industry) {
                element['scr_industry'] = element.industry.fields[0].value
              }
              if (element.legalName) {
                element['sr_legalName'] = element.legalName.fields[0].value
              }
              if (element.custodianTag || !element.custodianTag) {
                element['cust_Tag'] = (element.custodianTag == true ? "Yes" : "No");
              }
            }
            if (this.componentName != "workflowStatus" && this.componentName != "accountSummary" && this.componentName != "rapidAccountSummary") {
              if (element.isPreviewData || !element.isPreviewData) {
                element['isPreviewData'] = (element.isPreviewData == true ? "Yes" : "No");
              }
              if (element.uploadData || !element.uploadData) {
                element['uploadData'] = (element.uploadData == true ? "Yes" : "No");
              }
              if (element.executionLocked || !element.executionLocked) {
                // Add for alteryx DN-88 do not merge in PROD
                if (element.custodianTag) {
                  element['executionLocked'] = (element.executionLocked == true ? "Yes" : "No");
                } else {
                  element['executionLocked'] = " ";
                }
              }
            }

            if (element['sftpTypeName']) {
              element['sftpTypeName'] = atob(element.sftpTypeName);
            }

            if (element['legalName']) {
              if (element['legalName']['fields'][0] != null) {
                if (element['legalName']['fields'][0]['value'] != null) {
                  element['sr_legalName'] = element['legalName']['fields'][0]['value'];
                } else {
                  element['sr_legalName'] = " ";
                }
              }
            }

            if (element['runDate']) {
              element["runDate"] = moment(new Date(element['runDate'])).format("MM/DD/YYYY ");
              element["runDate"] = String(element["runDate"]);
            }

            if (this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus" || this.componentName == "workflowStatus") {
              if (element['createdDateStr']) {
                element["createdDate"] = moment(new Date(element['createdDateStr'])).format("MM/DD/YYYY hh:mm:ss A");
              } else {
                element["createdDate"] = null;
              }
              if (element['endDateStr']) {
                element["endDate"] = moment(new Date(element['endDateStr'])).format("MM/DD/YYYY hh:mm:ss A");
              } else {
                element["endDate"] = null;
              }
            }

            // Change execution date and time format for data import status
            if (this.componentName == "workflowStatus") {
              if (element['createdDate']) {
                element["createdDate"] = moment(new Date(element['createdDate'])).format("MM/DD/YYYY HH:mm");
              } else {
                element["createdDate"] = null;
              }
              if (element['autoDownloadFile']) {
                var uniqueId;
                let type: string = 'Zip file';
                if (element['uniqueNumber']) {
                  uniqueId = element['uniqueNumber'];
                  let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId + '/' + type;
                  this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data');
                } else {
                  this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('invalid_data'), detail: this.translateService.instant('error') });
                  return;
                }
              }
            }
            if (this.componentName == "executeWorkflow" || this.componentName == 'MISFundList') {
              if (element['createdDate']) {
                element["createdDate"] = moment(new Date(element['createdDate'])).format("MM/DD/YYYY");
              } else {
                element["createdDate"] = null;
              }
              if (element['updatedDate']) {
                element["updatedDate"] = moment(new Date(element['updatedDate'])).format("MM/DD/YYYY");
              } else {
                element["updatedDate"] = null;
              }
              if (element['updatedOn']) {
                element["updatedOn"] = moment(new Date(element['updatedOn'])).format("MM/DD/YYYY");
              } else {
                element["updatedOn"] = null;
              }
            }

            /*Auth:VG*/
            if (this.componentName == "tradeAllocation") {
              if (element['commission']) {
                element['ta_commission'] = element.commission;
              }
            }

            /*Auth:VG*/
            if (this.componentName == "ror-config-List" || this.componentName == 'customization-ror-config-List') {
              if (element['status']) {
                element['ror_status'] = element.status;
              }
            }
            if (this.componentName == 'customizationReportsApproval') {
              this.rowClassRules = {
                'complete-gray': function (params: any) {
                  if (
                    !params.data.processStatus
                  ) {
                    return true;
                  }
                },
              }
            }
            /*
           Componet: Run Data Import style for error
           auth:vg            
           */
            if (element['alteryxWorkflowStatus']) {
              this.rowClassRules = {
                'complete-gray': function (params: any) {
                  if (
                    params.data.alteryxWorkflowStatus
                    && params.data.alteryxWorkflowStatus.isDataDumpInTemp
                    && !params.data.alteryxWorkflowStatus.processStatus
                  ) {
                    return true;
                  }
                },
                'complete-green': function (params: any) {
                  if (
                    params.data.alteryxWorkflowStatus
                    && params.data.alteryxWorkflowStatus.disposition
                    && (!params.data.alteryxWorkflowStatus.isDataDumpInTemp
                      || params.data.alteryxWorkflowStatus.processStatus)
                    && (params.data.alteryxWorkflowStatus.disposition.toLowerCase() == "success"
                      || params.data.alteryxWorkflowStatus.disposition.toLowerCase() == "no data")
                  ) {
                    return true;
                  }
                },
                'complete-red': function (params: any) {
                  if (
                    params.data.alteryxWorkflowStatus
                    && params.data.alteryxWorkflowStatus.disposition
                    && (!params.data.alteryxWorkflowStatus.isDataDumpInTemp
                      || params.data.alteryxWorkflowStatus.processStatus)
                    && params.data.alteryxWorkflowStatus.disposition.toLowerCase() == "error"
                  ) {
                    return true;
                  }
                }
              };
            }

            var dispositionVar = [];
            if (element.disposition) {
              if (element.disposition == 'Error') {
                dispositionVar.push(element.disposition);
                element["disposition"] = dispositionVar.join(" | ");
              } else {
                dispositionVar.push(element.disposition);
                element["disposition"] = dispositionVar.join(" | ");
              }
            }

            /*
            Componet: Run Data Import
            auth:vg            
            */
            if (element["alteryxWorkflowMngt"]) {
              if (this.componentName == "executeWorkflow") {
                element["frequency"] = element["alteryxWorkflowMngt"]["frequency"] != null ? element["alteryxWorkflowMngt"]["frequency"] : null;
                element["executionPeriod"] = element["alteryxWorkflowMngt"]["executionPeriod"] != null ? element["alteryxWorkflowMngt"]["executionPeriod"] : null;
              }
              if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"]) {
                let frmDate = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["lastRunDateTimeStr"];
                if (frmDate != null) {
                  element["lastRunDateTimeStr"] = moment(new Date(frmDate)).format("MM/DD/YYYY HH:MM:SS");
                  element["lastRunDateTimeStr"] = String(element["lastRunDateTimeStr"]);
                } else {
                  element["lastRunDateTimeStr"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"] != null) {
                  element["file"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"];
                } else {
                  element["file"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                  element["fileNameFormat"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                } else {
                  element["fileNameFormat"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["localFilePath"] != null) {
                  element["localFilePath"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["localFilePath"];
                } else {
                  element["localFilePath"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"] != null) {
                  element["customField"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"];
                } else {
                  element["customField"] = " ";
                }
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"] != null) {
                  element["custassetClass"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"];
                } else {
                  element["custassetClass"] = " ";
                }
              }
            }
            if (this.componentName == "customizationNonCustodianList" || this.componentName == "nonCustodianList") {
              if (element['workflowMngt']) {
                if (element["workflowMngt"]["alteryxWorkflowMngtInfos"]) {
                  if (element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                    element["filenameFormat"] = element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                  } else {
                    element["filenameFormat"] = " ";
                  }
                  if (element['workflowMngt']['inputModeType']?.length > 0) {
                    if (element['workflowMngt']['inputModeType'][0].toLowerCase() == 'database') {
                      element['sourcefilePath'] = element['workflowMngt']['inputModeType'][0]
                    } else {
                      element['sourcefilePath'] = element['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                    }
                    if (element['workflowMngt']['inputModeType']?.length > 1) {
                      if (element['workflowMngt']['inputModeType'][0].toLowerCase() == 'sftp' || element['workflowMngt']['inputModeType'][1].toLowerCase() == 'sftp') {
                        element['sourcefilePath'] = element['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                      }
                    }
                  }
                  if (element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["workflowName"] != null) {
                    element["workflowname"] = element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["workflowName"];
                  } else {
                    element["workflowname"] = " ";
                  }
                  if (element.workflowMngt.isSelected || !element.workflowMngt.isSelected) {
                    element['workflowMngt']['isSelected'] = (element.workflowMngt.isSelected == true ? "Yes" : "No");
                  }
                }
              }
            }
            if (this.componentName == "customization-connectionList" || this.componentName == "connectionList") {
              if (element['custodianSourceConfig']['workflowMngt']) {
                if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"]) {
                  if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                    element["filenameFormat"] = element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                  } else {
                    element["filenameFormat"] = " ";
                  }
                  if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"] != null) {
                    element["assetClass"] = element['custodianSourceConfig']['workflowMngt']['alteryxWorkflowMngtInfos'][0]["assetClass"];
                  } else {
                    element["assetClass"] = " ";
                  }
                  if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"] != null) {
                    element["customField"] = element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"];
                  } else {
                    element["customField"] = " ";
                  }
                  if (element['custodianSourceConfig']['workflowMngt']['inputModeType']?.length > 0) {
                    if (element['custodianSourceConfig']['workflowMngt']['inputModeType'][0].toLowerCase() == 'database') {
                      element['sourceFilePath'] = element['custodianSourceConfig']['workflowMngt']['inputModeType'][0]
                    } else {
                      element['sourceFilePath'] = element['custodianSourceConfig']['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                    }
                    if (element['custodianSourceConfig']['workflowMngt']['inputModeType']?.length > 1) {
                      if (element['custodianSourceConfig']['workflowMngt']['inputModeType'][0].toLowerCase() == 'sftp' || element['custodianSourceConfig']['workflowMngt']['inputModeType'][1].toLowerCase() == 'sftp') {
                        element['sourceFilePath'] = element['custodianSourceConfig']['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                      }
                    }
                  }
                  if (element.custodianSourceConfig.workflowMngt.isSelected || !element.custodianSourceConfig.workflowMngt.isSelected) {
                    element['custodianSourceConfig']['workflowMngt']['isSelected'] = (element.custodianSourceConfig.workflowMngt.isSelected == true ? "Yes" : "No");
                  }
                  if (element.custodianSourceConfig.workflowMngt.isPreviewData || !element.custodianSourceConfig.workflowMngt.isPreviewData) {
                    element['custodianSourceConfig']['workflowMngt']['isPreviewData'] = (element.custodianSourceConfig.workflowMngt.isPreviewData == true ? "Yes" : "No");
                  }
                  if (element.custodianSourceConfig.workflowMngt.uploadData || !element.custodianSourceConfig.workflowMngt.uploadData) {
                    element['custodianSourceConfig']['workflowMngt']['uploadData'] = (element.custodianSourceConfig.workflowMngt.uploadData == true ? "Yes" : "No");
                  }
                }
              }
            }
            if (this.componentName != 'accountSummary' && this.componentName != 'rapidAccountSummary') {
              if (element["custodianAccountNumber"] != null) {
                element["CustaccountNumber"] = element["custodianAccountNumber"];
              } else {
                element["CustaccountNumber"] = " ";
              }
              if (element["inputModeType"] != null) {
                element["inputMode"] = element['inputModeType'].join(" | ").toUpperCase();
              } else {
                element["inputMode"] = " ";
              }
              if (element["finalSequence"] != null && this.componentName != 'accountSummary' && this.componentName != 'rapidAccountSummary') {
                element["finalSequence"] = element['finalSequence'];
              } else {
                element["finalSequence"] = " ";
              }
            }
            if (element.accountNumberInSourceFile) {
              element.accountNumberInSourceFileEnc = element.accountNumberInSourceFile
              element.accountNumberInSourceFile = "*****";
            }

            /*
            Componet: Alteryx workflowManagement
            auth:vg            
            */
            if (element['alteryxWorkflowMngtInfos']) {
              if (element['alteryxWorkflowMngtInfos'] && element['alteryxWorkflowMngtInfos'].length) {
                var file = [];
                var ProcessName = [];
                var fileNameFormat = [];
                var localFilePath = [];
                var PAssetClass = [];
                var AssetClass = [];
                var comment = [];
                var sourceFilePath = [];
                var customField = [];
                var fileNameSrv = [];
                var fl, fldt;
                element['alteryxWorkflowMngtInfos'].forEach(function (value: any) {
                  if (value.primaryAssetClass && value.primaryAssetClass.fields && value.primaryAssetClass.fields.length) {
                    PAssetClass.push(value.primaryAssetClass.fields[0].value);
                  }
                  if (value.alteryxProcess && value.alteryxProcess.length) {
                    ProcessName.push(value.alteryxProcess[0].processName);
                  }
                  if (value.isSelected) {
                    file.push(value.file);
                    fileNameFormat.push(value.fileNameFormat);
                  }
                  if (value.alteryxMetaInfo) {
                    fl = value.alteryxMetaInfo.name;
                  }
                  if (value.workflowUploadDateStr) {
                    fldt = value.workflowUploadDateStr;
                  }
                  file.push(value.file);
                  fileNameFormat.push(value.fileNameFormat);
                  AssetClass.push(value.assetClass);
                  comment.push(value.comment);
                  sourceFilePath.push(value.sourceFilePath);
                  customField.push(value.customField);
                  fileNameSrv.push(fl, fldt);
                });
                element["file"] = file.join(" | ");
                element["ProcessName"] = ProcessName.join(" | ");
                element["PAssetClass"] = PAssetClass.join(" | ");
                element["AssetClass"] = AssetClass.join(" | ");
                element["comment"] = comment.join(" | ");
                element["sourceFilePath"] = sourceFilePath.join(" | ");
                element["fileNameFormat"] = fileNameFormat.join(" | ");
                element["customField"] = customField.join(" | ");
                element["fileNameSrv"] = fileNameSrv.join(" | ");
                element["primaryAssetClass"] = element["PAssetClass"]
              } else {
                element["file"] = '';
                element["fileNameFormat"] = '';
              }
              if (element['processDate']) {
                element['processDate'] = moment(new Date(element['processDate'])).format("MM/DD/YYYY");
              } else {
                element['processDate'] = null;
              }
              if (element['custodianAccountNumber'] && element['custodianAccountNumber'].length) {
                element['accountNumber'] = element['custodianAccountNumber'].join(" | ");
              } else {
                element['accountNumber'] = null;
              }
              if (element['inputModeType'] && element['inputModeType'].length) {
                element['inputMode'] = element['inputModeType'].join(" | ").toUpperCase();
              } else {
                element['inputMode'] = null;
              }
              if (element['alteryxWorkflowMngtInfos'].length) {
                if (element['alteryxWorkflowMngtInfos'][0]['lastRunDateTimeStr'] != null) {
                  element["lastRunDateTimeStr"] = moment(new Date(element['alteryxWorkflowMngtInfos'][0]['lastRunDateTimeStr'])).format("MM/DD/YYYY HH:MM:SS");
                  element["lastRunDateTimeStr"] = String(element["lastRunDateTimeStr"]);
                }
              }
            }
            /*
           Componet: Alteryx custodain
           auth: Vg          
           */
            var SourceInfo = [];
            var custodianAccount = [];
            let sourceName;
            if (this.componentName == "custodianList" || this.componentName == 'custodianListLegacy' || this.componentName == "connectionList" || this.componentName == "customization-connectionList" || this.componentName == "setupcustodianList") {
              if (element["custodianSourceConfig"] != null || undefined) {
                if (element["custodianSourceConfig"]["sourceId"] != null) {
                  if (element["custodianSourceConfig"]["sourceName"] || !element["custodianSourceConfig"]["sourceName"]) {
                    sourceName = element["custodianSourceConfig"]["sourceName"];
                    if (sourceName || !sourceName) {
                      element["custodianSourceConfig"]["sourceName"] = sourceName;
                    }
                  }
                } else {
                  if (element["custodianSourceConfig"]["sourceInfos"]) {
                    element["custodianSourceConfig"]["sourceInfos"].forEach((data) => {
                      SourceInfo.push(data.name);
                    })
                    element["custodianSourceConfig"]["sourceName"] = SourceInfo.join(" | ");
                  }
                }
                var custodianAccount = [];
                if (element["custodianSourceConfig"]["custodianAccountNumber"] || !element["custodianSourceConfig"]["custodianAccountNumber"]) {
                  if (element["custodianSourceConfig"]["custodianAccountNumber"] != null || undefined) {
                    element["custodianAccountNumberList"] = element["custodianSourceConfig"]["custodianAccountNumber"];
                    if (element["custodianSourceConfig"]["custodianAccountNumber"].length > 1) {
                      element["custodianSourceConfig"]["custodianAccountNumber"].forEach((data) => {
                        custodianAccount.push(data)
                      })
                      element["custodianSourceConfig"]["custodianAccountNumber"] = custodianAccount.join(",");
                    }
                    else {
                      element["custodianSourceConfig"]["custodianAccountNumber"] = element["custodianSourceConfig"]["custodianAccountNumber"]
                    }
                  }
                }
              }
            }

            /*
            Componet: Alteryx Noncustodain-custodian
            auth: Ashok           
            */
            var prmAsst = [];
            if (element['alteryxWorkflowMngt'] && element['alteryxWorkflowMngt'].length) {
              element["alteryxWorkflowMngt"].forEach((data) => {
                element["file"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.file;
                }).join(" | ");
                element["comment"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.comment;
                }).join(" | ");
                element["processName"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return _.map(value["alteryxProcess"], function (x, key) {
                    return x.processName;
                  });
                }).join(" | ");
                element["assetClass"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.assetClass;
                }).join(" | ");
                element["workflowName"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.workflowName;
                }).join(" | ");
                element["customField"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.customField;
                }).join(" | ");
                element["sourceFilePath"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.sourceFilePath;
                }).join(" | ");
                element["fileNameFormat"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                  return value.fileNameFormat;
                }).join(" | ");
                element["primaryAssetClass"] = _.map(data["alteryxWorkflowMngtInfos"], function (x, key) {
                  if (x["primaryAssetClass"] != null) {
                    prmAsst.push(x["primaryAssetClass"]["fields"][0].value);
                    return x["primaryAssetClass"]["fields"][0].value;
                  }
                }).join(" | ");
              });
            }
            if (prmAsst.length) {
              element["primaryAssetClass"] = prmAsst.join(" | ");
            }
          });
          if (this.componentName == "workflowStatus") {
            var x = [];
            this.keepGoing = false;
            let finalVal = cbs.resultList;
            finalVal.forEach((element) => {
              if (element.status != null) {
                x.push(element);
              }
            });
            this.keepGoing = _.every(x, { 'status': 'Completed', 'fileDownloadStarted': false });
            this.processScreenService.setBtnStatus(this.keepGoing);
            if (!this.keepGoing) {
              if (!this.myVar) {
                this.myVar = setInterval(() => {
                  if (this.isTimerKill) {
                    clearInterval(this.myVar);
                    this.myVar = null;
                  } else {
                    this.getGridData()
                  }
                }, 180 * 1000);
              } else {
                clearInterval(this.myVar);
                this.myVar = null;
                this.myVar = setInterval(() => {
                  if (this.isTimerKill) {
                    clearInterval(this.myVar);
                    this.myVar = null;
                  } else {
                    this.getGridData()
                  }
                }, 180 * 1000);
              }
            } else {
              clearInterval(this.myVar);
              this.myVar = null;
            }
          }
        } else {
          let loginMsg = "success"
          if (cbs && cbs.message != undefined) {
            loginMsg = cbs.message.replace(/\s/g, "").toLowerCase();
          }
          if (!loginMsg.includes('first')) {
            if (cbs) {
              cbs.forEach((element: any) => {
                if (element.updatedOn && this.componentName != 'dailyProcessingView' && (this.componentName == "automate-process" && this.subComponent == "dailyProcessingView")) {
                  let resMnt2 = moment(new Date(element.updatedOn)).isValid()
                  if (resMnt2) {
                    element.updatedOn = moment(element.updatedOn).format('YYYY-MM-DD hh:mm:ss:ssss');
                  } else {
                    element.updatedOn = element.updatedOn;
                  }
                }
              });
            }
          } else {
            this.router.navigateByUrl('/login');
          }
        }
        if (this.componentName == "sftpConfiguration") {
          cbs.resultList.forEach((element: any) => {
            if (element.awsConfig) {
              element.awsConfig = "AWS"
              element.sftpTypeName = element.configurationName;
            }
            else if (element.inputModes == 'CM') {
              element.awsConfig = element.inputModes
              element.sftpTypeName = element.configurationName;
            }
            else if (!element.awsConfig) {
              element.awsConfig = "SFTP"
            }
            if (element.password) {
              element.passwordView = "*****";
            }
            if (element.sftpTypeName) {
              element.sftpTypeName = element.sftpTypeName;
            }
            if (element.username) {
              element.username = atob(element.username);
            }
            if (element.host) {
              element.host = atob(element.host);
            }
          });
        }
        if (this.componentName == "sourceConfiguration") {
          cbs.resultList.forEach((element: any) => {
            if (element.sftpTypeName) {
              element.sftpTypeName = element.sftpTypeName;
            }
          });
        }
        if (this.componentName == "viewDataCollection") {
          cbs.resultList.forEach(element => {
            if (element.jsonData) {
              element.jsonData = JSON.stringify(element.jsonData);
            }
          });
        }
        if (this.componentName == 'activityClient') {
          this.activityUploadCompliance.emit(cbs);
        }
        if (this.componentName == 'activityUploadReview') {
          this.activityPostTOGL.emit(cbs);
        }
        this.rowData = [];
        this.rowData = _.cloneDeep(cbs.resultList);
        if (cbs.resultList == undefined || cbs.content != undefined) {
          this.rowData = _.cloneDeep(cbs);
        }
        if (cbs.resultList == undefined || cbs.content != undefined) {
          this.rowData = cbs;
        }
        if ((this.subComponent == 'Real Time Positions' || this.subComponent == 'approved_order') && this.rowData.length) {
          this.showExport = true;
        }
        this.totalCount = cbs.totalCount;
        this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
        if (!this.isFryFilterAct) {
          this.totalRecords = cbs.totalCount;
        }
        if (cbs.totalCount == undefined) {
          this.totalCount = cbs.length;
          this.totalPages = Math.ceil(cbs.length / 100) || 1;
          if (!this.isFryFilterAct) {
            this.totalRecords = cbs.length;
          }
        }
        if (this.componentName == "jeThirdParty") {
          this.totalRecords = cbs.resultList.length;
        }
        if (this.gridApi != null || this.gridApi != undefined) {
          if (this.rowData != null || this.rowData != undefined) {
            this.gridApi.setRowData(this.rowData);
          }
        }
        if (this.componentName == 'customization-connectionList') {
          this.checkedWorkflows()
        }
        this.displayForm = false;
        this.gridShow = true;
      }, (cbe) => { })
    }
    /*
    Auth:Vg
    Toggle For SmartView totalRecords Update
     */
    if (this.myFlagForSlideToggle == false) {
      if (this.SmartTotalRecords) {
        this.totalRecords = this.SmartTotalRecords;
      }
    }
  }

  addRow() {
    const newItem = { id: this.nextIndex++, jeSource: 'Manual', jePosted: false, isCreated: true }; // Define your default row data structure here
    const index = 0; // You can change this index to insert at different positions
    this.rowData.splice(index, 0, newItem); // Insert at the desired index
    this.isDeleteVisible = true;
    this.gridApi.setRowData(this.rowData);

  }
  deleteRow() {
    if (this.rowData.length > 0) {
      if (this.rowData.some(row => row.isCreated === true)) {
        this.rowData.shift(); // Remove the first row
        this.gridApi.setRowData(this.rowData);
      }
      // Update isDeleteVisible based on the absence of any object with isCreated
      this.isDeleteVisible = this.rowData.some(row => row.isCreated === true);
    } else {
      console.error('No rows to delete');
    }
  }

  getStartAndEndOfYear(year: string): { startOfYear: string, endOfYear: string } {
    const startOfYear = `${year}-01-01`;
    const endOfYear = `${year}-12-31`;
    return { startOfYear, endOfYear };
  }
  looksuponChange() {
    if (this.lookups.length > 0) {
      if (this.filteredData.length == 0) {
        this.getGridDataUrl();
      }
      this.gridApi.lookups = this.lookups;
      if ((this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry') && this.filteredData.length) {
        this.getFilteredData();
      }
    }
  }

  onChangeHistoricalApprovalList() {
    if (this.componentName == 'customizationReportsApproval' || this.componentName == 'isdClientApprovalGroupFund') {
      this.gridCrumb = this.isAllocated == true ? "allocated" : undefined;
      this.oppValue = !this.isAllocated;
      if (this.oppValue == false) {
        this.gridDataUrl = apiConstants[this.componentName]['gridHistoricalApprovalUrl'];
      } else {
        this.gridDataUrl = apiConstants[this.componentName]['gridDataUrl'];
      }
    }
    this.getGridData()
  }

  /* Name:onChangeAllocationForOrderlist
     param:null
     purpose: Change Grid when Allocation Value change for Order List
     author: Akshar
 */
  onChangeAllocationForOrderlist() {
    this.gridCrumb = this.isAllocated == true ? "allocated" : undefined;
    this.oppValue = !this.isAllocated;
    this.selectedRows = [];
    if (this.oppValue) {
      this.blobExport = false;
    }
    if ((this.componentName == "dataImportStatusManager" || this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus"
      || this.componentName == "workflowStatus" || this.componentName == "executeWorkflow")) {
      if (this.oppValue == false) {
        this.gridDataUrl = " ";
        this.gridDataUrl = apiConstants[this.componentName]['getCustomTypeUrl'];
        this.method = "get";
        if (this.roleAs == "SystemManager") {
          this.gridDataUrl = this.gridDataUrl + (Number(this.pageNumber) - 1);
        } else {
          if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
            this.gridDataUrl = this.gridDataUrl + (Number(this.pageNumber) - 1) + "/enterprise";
          } else {
            this.gridDataUrl = this.gridDataUrl + (Number(this.pageNumber) - 1) + "/user";
          }
        }
      } else {
        this.gridDataUrl = apiConstants[this.componentName]['gridDataUrl'];
        this.method = "post";
        if (this.gridApi != null || this.gridApi != undefined) {
          if (this.rowData != null || this.rowData != undefined) {
            this.gridApi.setRowData(this.rowData);
          }
        }
      }
    }
    if (this.componentName == "sourceConfiguration") {
      this.gridDataUrl = this.filterUrl;
      const filtredModel = this.gridApi.getFilterModel();
      let x = Object.entries(filtredModel);
      var reqSearchObj: any = {};
      reqSearchObj['page'] = 0;
      if (x.length) {
        x.forEach((element) => {
          var key = element[0];
          var value = element[1]["filter"];
          if (key == "sftpTypeName") {
            value = btoa(value);
          }
          reqSearchObj[key] = value;
        });
        if (this.oppValue == false) {
          reqSearchObj.status = "pending";
        }
        this.reqObj = reqSearchObj;
        this.method = "post";
      } else {
        if (this.oppValue == false) {
          reqSearchObj.status = "pending";
          this.reqObj = reqSearchObj;
          this.method = "post";
        } else {
          this.method = "get";
          this.gridDataUrl = "systemManager/alteryx/sourceConfigurationV2/records/";
        }
      }
    } else if (this.componentName == "inputConfiguration" || this.componentName == "customizationInputConfiguration") {
      this.gridDataUrl = this.filterUrl;
      const filtredModel = this.gridApi.getFilterModel();
      let x = Object.entries(filtredModel);
      var reqSearchObj: any = {};
      reqSearchObj['page'] = 0;
      if (x.length) {
        x.forEach((element) => {
          var key = element[0];
          var value = element[1]["filter"];
          reqSearchObj[key] = value;
        });
        if (this.oppValue == false) {
          reqSearchObj.disabled = true;
        }
        this.reqObj = reqSearchObj;
        this.method = "post";
      } else {
        if (this.oppValue == false) {
          reqSearchObj.disabled = true;
          this.reqObj = reqSearchObj;
          this.method = "post";
        } else {
          this.method = "get";
          this.gridDataUrl = "alteryxInputConfig/records/";
        }
      }
    } else if (this.componentName == "sftpConfiguration") {
      this.gridDataUrl = this.filterUrl;
      const filtredModel = this.gridApi.getFilterModel();
      let x = Object.entries(filtredModel);
      var reqSearchObj: any = {};
      if (x.length) {
        x.forEach((element) => {
          var key = element[0];
          var value = element[1]["filter"];
          if (key == "sftpTypeName") {
            value = btoa(value);
          }
          reqSearchObj[key] = value;
        });
        this.reqObj = reqSearchObj;
        this.method = "post";
      } else {
        this.method = "get";
        this.gridDataUrl = "sftpDetailV2/records/0";
      }
    } else {
      this.gridDataUrl = this.gridDataUrl.replace(this.oppValue.toString(), this.isAllocated.toString());
    }
    if (this.componentName == "workflowStatus") {
      clearInterval(this.myVar);
      this.myVar = null;
    }
    this.getGridData()
  }

  /* on grid checkbox selection */
  onRowSelected(event) {
    this.accountSummaryselectedTotal = 0
    let item = event.node.data
    if (this.componentName == "chartOfAccounttaxClassification" || this.componentName == "chartofAccountTaxMappingDetails" || this.componentName == "COAMapping") {

      this.setuppercentage.setTaxDataTrigger(event.node.data);
    }

    if ((this.componentName == "accountSummaryReconcile" || this.componentName == 'rapidAccountSummaryReconcile') && this.isReconciliationLoaded) {
      if (event.node.selected) {
        var result = this.getFundPeriodLockDate(this.accountSummaryData)
        if (result != "No locks found") {
          event.node.setSelected(false)
          this.reconcileLock = true
        }
        else {
          if (event.node.data['bankPostingDate']) {
            event.node.data['bankPostingDate'] = event.node.data['bankPostingDate']
          } else {
            event.node.data['bankPostingDate'] = event.node.data['jedate'] || event.node.data['bankPostingTempDate']
          }
          if (event.data.reconMonthYear && (event.data.reconMonthYear != this.accountSummaryData.monthYear)) {
            this.messageService.add({
              closable: true, sticky: true, severity: 'warn', summary: this.translateService.instant('Warning'),
              detail: 'Some of the entry(s) has already been reconciled in one of the future configurations. Please unreconcile them first from the future configuration.'
            });
          } else {
            this.accountSummaryselectedTotal += item.amount
            if (item.crOrDr == "DR" || item.isMemoEntry == true) {
              this.totalAdjustment = this.totalAdjustment - this.accountSummaryselectedTotal
            } else {
              this.totalAdjustment = this.totalAdjustment + this.accountSummaryselectedTotal
            };
          }
        }
      } else {
        event.node.data['bankPostingDate'] = ""
        if (event.data.reconMonthYear && (event.data.reconMonthYear != this.accountSummaryData.monthYear)) {
        } else {
          this.accountSummaryselectedTotal += item.amount
          if (item.crOrDr == "DR" || item.isMemoEntry == true) {
            this.totalAdjustment = this.totalAdjustment + this.accountSummaryselectedTotal;
          } else {
            this.totalAdjustment = this.totalAdjustment - this.accountSummaryselectedTotal;
          }
        }
      }
    }

    if (this.bankClosingBalance || this.accountSummaryselectedTotal) { //this.currentClosingBalance
      this.adjustedEndingBalance = this.bankClosingBalance + this.totalAdjustment;
      this.unReconciledDifference = this.adjustedEndingBalance - this.glClosingBalance
    }

    setTimeout(() => {
      const params = { force: true, columns: ["bankPostingDate"] };
      this.gridApi.refreshCells(params);
    }, 50)
  }

  /* Name:onSelectionChanged
      param:null
      purpose: Grid Row Selection
      author: Akshar
  */
  onSelectionChanged(evt) {
    this.selectedRows = evt.api.getSelectedRows();
    if (this.selectedRows && this.selectedRows.length) {
      this.selectedId = this.selectedRows[0].fundId;
    } else {
      this.selectedId = '';
    }
    // Add for alteryx DN-56 do not merge in PROD
    this.lastSelectedNode = evt.api.selectionController.lastSelectedNode;
    if (this.selectedRows) {
      this.runBtnFlag = true;
    }

    if (this.componentName == 'calculateTaxAllocation' || this.componentName == 'taxReturnPackageNew' || this.componentName == 'chartOfAccountTaxClassification' || this.componentName == 'taxPartnershipPassthroughK1') {
      this.setuppercentage.setAddPopupTrigger(this.selectedRows);
    }

    if (this.componentName == 'chartofAccountTaxMappingDetails' && this.selectedRows.length) {
      this.setuppercentage.setSelectAllMappingTrigger(true);
    }
    else if (this.componentName == 'chartofAccountTaxMappingDetails' && !this.selectedRows.length) {
      this.setuppercentage.setSelectAllMappingTrigger(false);
    }

    /*Auth  : Vg
    RunData Import Select same frequency type
    */
    if (this.componentName == "executeWorkflow") {
      if (!this.selectedRows[0]) {
        this.fstItmFrqy = null;
      }

      if (this.selectedRows && this.selectedRows.length > 1) {
        this.selectedRows.forEach(rows => {
          if (!rows['alteryxWorkflowMngt']['executionLocked']) {
            this.gridApi.forEachNode((node) => {
              if (node.selected) {
                let curtExeLck = node.data ? (node.data.alteryxWorkflowMngt ? node.data.alteryxWorkflowMngt.executionLocked : null) : null;
                if (!curtExeLck) {
                  if (this.selectedRows.length == 2) {
                    for (var i = 0; i < this.selectedRows.length - 1; i++) {
                      if (this.selectedRows[0].alteryxWorkflowMngt.executionLocked == this.selectedRows[1].alteryxWorkflowMngt.executionLocked) {
                        if (this.lastSelectedNode.data.id == node.data.id) {
                          node.setSelected(false);
                          let message = node.data.alteryxWorkflowMngt.sourceName + ' is not configured for sequence execution.You can configure this from Enterprise login.';
                          this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: message });
                        }
                      } else {
                        node.setSelected(false);
                        let message = node.data.alteryxWorkflowMngt.sourceName + ' is not configured for sequence execution.You can configure this from Enterprise login.';
                        this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: message });
                      }
                    }
                  } else {
                    node.setSelected(false);
                    let message = node.data.alteryxWorkflowMngt.sourceName + ' is not configured for sequence execution.You can configure this from Enterprise login.';
                    this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: message });
                  }
                } else {
                  node.setSelected(true);
                }
              }
            })
          }
        })
      }
    }

    if (this.componentName == "customizationReportsApproval") {
      this.gridApi.forEachNode((node) => {
        if (node.selected) {
          let curtType = node.data ? node.data.approverStatus : null;
          if (curtType == null) {
            /*Resolves Check Box Disappear Issue */
            node.setSelected(true);
          } else {
            this.messageService.add({
              sticky: true, severity: 'error', summary: 'Client Approval',
              detail: 'The client approval status has already been approved.'
            });
            node.setSelected(false);
          }
        }
      })
    }
    // Entry created by automated/manually.
    else if (this.componentName == "journalentry" || this.componentName == "RapidJournalentry") {
      this.rowSelection = 'multiple';
      this.gridApi.forEachNode((node) => {
        if (node.selected) {
          let curtType = node.data ? node.data.referenceModule : null;
          if (curtType == null) {
            /*Resolves Check Box Disappear Issue */
            node.setSelected(true);
          } else {
            node.setSelected(false);
          }
        }
      })
    }
    else if (this.componentName == "duplicateSetupInvestorsList" || this.componentName == "duplicaterapidInvestors") {
      this.setuppercentage.setAddPopupTrigger(this.selectedRows);
    }
  }

  saveAccountSummaryReconcile(type?) {
    let postBody = {}
    this.rowData.forEach(el => {
      if (!el.bankPostingDate) {
        el['bankPostingDate'] = el['jedate']
      }
    })
    var reduced = this.rowData.reduce(function (filtered, option) {
      if (option.isReconcile == true) {
        var someNewValue = option
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);

    let unCheckeditem = reduced.filter(el => {
      let matchItem = this.selectedRows.find(item =>
        item._id == el._id
      )
      return !matchItem
    })

    this.lastnodeSelected = unCheckeditem

    if (this.selectedRows.length || this.lastnodeSelected.length) {
      var bankConfig = {
        "id": this.accountSummaryData.id,
        "bankClosingBalance": this.accountSummaryData.bankClosingBalance,
      }
      var isReconcileData = {
        "chartOfAccountId": this.accountSummaryData.chartOfAccountId,
        "fundId": this.accountSummaryData.fundId,
        "monthYear": this.accountSummaryData.monthYear,
        "custodianId": this.accountSummaryData.custodianId
      }

      if (type == 'isReconcile') {
        var updatedcheckedList = this.selectedRows.reduce(function (filtered, option) {
          if (option.isReconcile != true) {
            var someNewValue = option
            filtered.push(someNewValue);
          }
          return filtered;
        }, [])

        Object.assign(bankConfig, isReconcileData);

        postBody = {
          checkedList: this.selectedRows.length ? updatedcheckedList : [],
          unCheckedList: this.lastnodeSelected.length ? this.lastnodeSelected : [],
          bankConfig: this.accountSummaryData,
          reconcileProcess: "doReconcile"
        }
      } else {
        var updatedcheckedList = this.selectedRows.reduce(function (filtered, option) {
          if (option.isReconcile != true) {
            var someNewValue = option
            filtered.push(someNewValue);
          }
          return filtered;
        }, [])

        postBody = {
          checkedList: this.selectedRows.length ? updatedcheckedList : [],
          bankConfig: this.accountSummaryData,
          unCheckedList: this.lastnodeSelected.length ? this.lastnodeSelected : [],
        }
      }
    } else if (type == 'isReconcile') {
      postBody = {
        checkedList: [],
        bankConfig: this.accountSummaryData,
        unCheckedList: [],
        reconcileProcess: "doReconcile"
      }
    } else {
      postBody = {
        checkedList: [],
        bankConfig: this.accountSummaryData,
        unCheckedList: [],
      }
    }

    this.gridService.saveAccountSummaryReconcileData(postBody).subscribe(res => {
      this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_created') });
      this.componentName == "accountSummaryReconcile" ? this.router.navigate(['/reconciliation/account-summary']) : this.router.navigate(['/input/view/account-summary'])
    }, error => {
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant(error.error.message) });
    })
  }

  /* Name: deleteAll
      param:null
      purpose: Delete All Grid Row 
      author: Akshar
  */
  deleteAll() {
    this.gridDeleteUrl = "deleteController/deleteAll"
    let data = {
      moduleName: this.moduleName,
    }
    if (this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == "paidBills"
      || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills" || this.componentName == "rapidInvoice" || this.componentName == "cashIncomeList") {
      this.gridDeleteUrl = "reBillDetails/deleteAll"
      this.gridService.deleteAllGridDataBill(this.gridDeleteUrl, (cbs) => {
        if (cbs && cbs.message) {
          let locMsg = cbs.message
          locMsg = locMsg.toLowerCase();
          if (locMsg.includes('failed') && locMsg.includes("can't") && locMsg.includes('not') && locMsg.includes('mapped')) {
            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.moduleName, detail: cbs.message });
          } else {
            this.getGridData();
            this.messageService.add({ sticky: true, severity: 'warn', summary: this.translateService.instant('Warning'), detail: this.translateService.instant(cbs.message) });
          }
        } else {
          this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('all_records_deleted') });
          this.rowData = [];
          if (this.gridApi != null || this.gridApi != undefined) {
            if (this.rowData != null || this.rowData != undefined) {
              this.gridApi.setRowData(this.rowData);
            }
          }
          this.totalRecords = 0;
        }
      }, (cbe) => { })
    } else {
      this.gridService.deleteAllGridData(this.gridDeleteUrl, data, (cbs) => {
        if (cbs && cbs.message) {
          let locMsg = cbs.message
          locMsg = locMsg.toLowerCase();
          if (locMsg.includes('failed') && locMsg.includes("can't") && locMsg.includes('not') && locMsg.includes('mapped')) {
            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.moduleName, detail: cbs.message });
          } else {
            this.getGridData();
            this.messageService.add({ sticky: true, severity: 'warn', summary: this.translateService.instant('Warning'), detail: this.translateService.instant(cbs.message) });
          }
        } else {
          this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('all_records_deleted') });
          this.rowData = [];
          if (this.gridApi != null || this.gridApi != undefined) {
            if (this.rowData != null || this.rowData != undefined) {
              this.gridApi.setRowData(this.rowData);
            }
          }
          this.totalRecords = 0;
        }
      }, (cbe) => { })
    }
  }

  /* Name: deleteSelected
      param:null
      purpose: Delete Selected Grid Row 
      author: Akshar
  */
  deleteSelected() {
    let response = true
    let focusedNode = this.gridApi.getSelectedRows();
    let selectedList = [];
    this.gridDeleteUrl = "deleteController/deleteSelected";

    if (this.moduleName == "capitalActivity") {
      this.selectedRows.forEach((element: any) => {
        selectedList.push(element.transactionID)
      });
    } else {
      this.selectedRows.forEach((element: any) => {
        if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
          selectedList.push(element.jeId)
        }
        else if (this.componentName == 'customizationNonCustodianList') {
          let id = element.id + "@@" + element.workflowMngt.id
          selectedList.push(id)
        }
        else if (this.componentName == 'FootNoteMaster') {
          selectedList.push(element._id)
        }
        else if (this.componentName == 'FSAutomation') {
          /**
           * delete request update for FS-config FSA-395
           * @author   Ashok Kumar
           */
          let fsScheduleConfigId = element.scheduleData ? element.scheduleData.id : '';
          selectedList.push({
            "fsConfigId": element.id,
            "fsScheduleConfigId": fsScheduleConfigId
          })
        } else {
          selectedList.push(element.id)
        }
      });
    }
    let deldata;
    if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      deldata = {
        moduleName: this.moduleName,
        id: selectedList
      }
    }
    else if ((this.componentName == 'vendorCreditList'||this.componentName == 'cashExpenseList' ||this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills")
      && this.componentName != "paidBills" && this.componentName != "rapidPaidBills") {
      this.gridDeleteUrl = "reBillDetails/deleteSelected";
      deldata = selectedList;
    }
    else if (( this.componentName == "rapidInvoice" ||this.componentName == "paymentRecipt" ||this.componentName == "rapidOutstandingInvoice" || this.componentName == "cashIncomeList")
) {
    this.gridDeleteUrl = "customerInvoice/deleteSelected";
    deldata = selectedList;
  }
    else if (this.componentName == "defaultChartOfAccounts") {
      this.gridDeleteUrl = "defaultChartOfAccounts/deleteSelected";
      deldata = selectedList;
    } else if (this.componentName == "FSAutomation") {
      this.gridDeleteUrl = "fSConfig/deleteByConfigId";
      deldata = selectedList[0];
    }
    else {
      deldata = {
        moduleName: this.moduleName,
        id: selectedList
      }
    }
    if (deldata.moduleName == 'investors') {
      deldata.moduleName = 'investor';
    }
    this.gridService.deleteSelectedGridData(this.gridDeleteUrl, deldata, (cbs) => {
      if (cbs.code) {
        let locMsg = cbs.message.toLowerCase();
        if (!locMsg.includes('not') && !locMsg.includes('mapped')
          && !locMsg.includes('failed') && !locMsg.includes("can't")) {
          focusedNode.forEach(selectedNode => {
            this.gridApi.updateRowData({ remove: [selectedNode] });
          });
        }
        // Configure Tax return details grid is not getting refreshed after deleting selected records ->TA-149
        if (this.componentName == "TAXAutomation" || this.componentName == "taxAdjusted") {
          this.setupPercentageService.setIsJECreated(false)
          this.getGridData();
        }
      }
      let message = cbs.message.includes('Record(s) deleted') || cbs.message.includes('Record deleted.') || cbs.message.includes('Record Deleted.') || cbs.message.includes('Record Deleted') || cbs.message.includes('Selected Record(s) deleted');
      if (message && cbs.code == 1) {
        if (this.componentName == "investors" || this.componentName == 'rapidInvestors') {
          this.messageService.add({ sticky: true, severity: 'success', detail: this.translateService.instant(cbs.message + " successfully") });
        } else
          this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(cbs.message) });

        if (!this.isFryFilterAct) {
          this.totalRecords = Number(this.totalRecords) - focusedNode.length;
          if (this.totalRecords == 0) {
            this.setuppercentage.setDeleteTrigger(true)
          }
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'warn', detail: cbs.message });
      }
    }, (cbe) => {
      response = false;
    });
    return response;
  }

  deleteFilter() {
    let selectedList = [];
    this.rowData.forEach((element: any) => {
      selectedList.push(element.id)
    });

    let data = {
      moduleName: this.moduleName,
      id: selectedList,
      filter: this.reqObj1,
      totalCount: this.totalRecords
    }
    if (data.moduleName == 'investors') {
      data.moduleName = 'investor';
    }
    Object.entries(this.reqObj1).forEach((key, value) => {
      if (key[1] != undefined) {
        data[key[0]] = key[1];
      }
    })
    if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      delete data['moduleName'];
      data['page'] = this.reqObj1['page'].toString();
      data['moduleName'] = this.moduleName;
    }
    if (this.componentName == "investorsList" || this.componentName == "setupInvestorsList" || this.componentName == "taxInvestorsList") {
      data['isEnterprise'] = this.isEnterprise
    }
    delete data['id'];
    delete data['filter'];
    this.filterDeleteUrl = "deleteController/deleteFilterByKeys"

    if (this.componentName == 'bill' || this.componentName == "unpaidBills" || this.componentName == "paidBills" || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills" || this.componentName == 'cashExpenseList' || this.componentName == 'vendorCreditList' ) {
      data['page'] = this.reqObj1['page'].toString();
      data['isFilter'] = "true";
      delete data['moduleName'];
      delete data['totalCount'];
      this.filterDeleteUrl = "reBillDetails/deleteFilterByKeys"
    }
    if (this.componentName == 'rapidInvoice'||this.componentName == "paymentRecipt" ||this.componentName == "rapidOutstandingInvoice" || this.componentName == "cashIncomeList" ) {
      data['page'] = this.reqObj1['page'].toString();

      data['isFilter'] = "true";
      delete data['moduleName'];
      delete data['totalCount'];
      this.filterDeleteUrl = "customerInvoice/deleteFilterByKeys"
    }
    if (this.componentName == "defaultChartOfAccounts") {
      this.filterDeleteUrl = "defaultChartOfAccounts/deleteFilterByKeys";
      delete data['moduleName'];
      delete data['totalCount'];
    }
    this.gridService.deleteFilterGridData(this.filterDeleteUrl, data, (cbs) => {
      if (cbs.code) {
        let locMsg = cbs.message.toLowerCase();
        if (!locMsg.includes('not') && !locMsg.includes('mapped')
          && !locMsg.includes('failed') && !locMsg.includes("can't")) {
          this.rowData = [];
          this.totalRecords = 0;
          this.totalFltRecords = 0
          if (this.gridApi != null || this.gridApi != undefined) {
            if (this.rowData != null || this.rowData != undefined) {
              this.gridApi.setRowData(this.rowData);
            }
          }
        }
      }
      if (this.componentName == 'userCoaList' || this.componentName == 'reviewChartOfAccounts' || this.componentName == 'reReportConfig' || this.componentName == 'rapidDividendBroker') {
        this.getFilteredData(this.filteredDataevt);
      }
      let message = cbs.message.includes('Record(s) deleted') || cbs.message.includes('Record deleted.') || cbs.message.includes('Record Deleted.') || cbs.message.includes('Record Deleted') || cbs.message.includes('Filtered records deleted');
      if (message && cbs.code == 1) {
        if (this.componentName === 'investors' || this.componentName == 'rapidInvestors') {
          this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(cbs.message + " successfully") });
        } else {
          this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(cbs.message) });
        }
      } else {
        if (this.componentName === 'investors' || this.componentName == 'rapidInvestors') {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: this.translateService.instant(cbs.message) });
        } else {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', detail: this.translateService.instant(cbs.message) });
        }
      }
    }, (cbe) => { });
  }

  deleteFilterAltryx() {
    let selectedList = [];
    if (this.componentName == 'FootNoteMaster') {
      this.rowData.forEach((element: any) => {
        selectedList.push(element._id)
      });
    } else {
      this.rowData.forEach((element: any) => {
        selectedList.push(element.id)
      });
    }

    let deldata = {
      moduleName: this.moduleName,
      id: selectedList,
    }
    this.gridDeleteUrl = "deleteController/deleteSelected";
    this.gridService.deleteSelectedGridData(this.gridDeleteUrl, deldata, (cbs) => {
      if (cbs.code) {
        let locMsg = cbs.message.toLowerCase();
        if (!locMsg.includes('not') && !locMsg.includes('mapped')
          && !locMsg.includes('failed') && !locMsg.includes("can't")) {
          this.rowData = [];
          this.totalRecords = 0;
          if (this.gridApi != null || this.gridApi != undefined) {
            if (this.rowData != null || this.rowData != undefined) {
              this.gridApi.setRowData(this.rowData);
            }
          }
        }
      }
      this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant(cbs.message) });
    }, (cbe) => { });
  }

  deleteModal(deleteType) {
    if (deleteType == "deleteFiltered" || deleteType == "deleteAll") {
      this.selectedRows = this.rowData;
    }
    if (this.componentName != 'grossCashFlowAdjustment' && this.componentName != 'contributionDistributionAdjustment') {
      this.result = this.commonService.getPeriodLockmessagefordelete(this.selectedRows, this.componentName, deleteType)
    }
    if (this.result != false) {
      this.display = false;
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        data: {
          message: deleteType == "deleteByCriteria" ? "Are you sure You want to delete all records for selected Custodian Account?" : deleteType == "deleteSelected" ? 'Are you sure you want to delete ' + this.selectedRows.length + " selected records?" : deleteType == "deleteFiltered" ? 'Are you sure you want to delete ' + this.totalRecords + " Filtered records?" : 'Are you sure you want to delete ' + this.totalCount + " records?",
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      });
      dialogRef.afterClosed().subscribe((confirmed: any) => {
        if (confirmed) {
          if (deleteType == "deleteSelected") {
            this.deleteSelected();
          } else if (deleteType == "deleteAll") {
            this.deleteAll();
          } else if (deleteType == "deleteByCriteria") {
            this.deleteByCriteria();
          } else if (deleteType == "deleteFiltered") {
            if (this.componentName == 'sourceConfiguration' || this.componentName == 'PartnershipPassthroughConfigurationList' || this.componentName == 'FootNoteMaster' || this.componentName == 'workflowManagement' || this.componentName == 'sftpConfiguration') {
              this.deleteFilterAltryx();
            } else {
              this.deleteFilter();
            }
          }
        }
      });
    }
  }

  downloadCSV(temp?) {
    this.totalCount = this.totalRecords;
    let zone_name: any;
    let zone_name1 = moment.tz.guess();
    if (this.componentName == 'workflowStatus') {
      zone_name = zone_name1;
    } else if (this.componentName == 'dataImportStatusManager') {
      zone_name = zone_name1;
    } else if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus") {
      zone_name = zone_name1;
    } else if (this.componentName == 'executeWorkflow') {
      zone_name = zone_name1;
    } else {
      zone_name = zone_name1.replace("/", "_");
    }
    var type = 'Csv';
    let link;
    if (this.componentName == 'reportNotificationStatusList' || this.componentName == 'customizationReportNotificationStatusList') {
      this.gridExportUrl = apiConstants[this.componentName]['gridExportUrl'].url + ((this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? "downloadRecords/" : "downloadEnterpriseRecords/")
    }
    link = this.isZoneName ? this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + zone_name : this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount));
    if (this.componentName == 'historicalInvestorCapitalSummary' || this.componentName == 'tradeArchiveList' || this.componentName == 'rapidTradeArchiveList') {
      console.log("historicalInvestorCapitalSummary");
      link = this.gridExportUrl;
    }
    if (this.componentName == 'omslist' || this.componentName == 'rapidOmslist' || (this.componentName == 'usermanagementlist' && this.subComponent != 'Seamless')|| (this.componentName == 'usermanagementlist' && this.subComponent != 'Troniq') || this.componentName == 'usermappinglist' || this.componentName == 'automate-process') {
      if (this.subComponent == 'approved_order') {
        if (temp == 'well') {
          link = apiConstants[this.componentName][this.subComponent]['gridExportWellUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + moment(this.customeDateRange[0]).format('YYYY-MM-DD') + '/' + moment(this.customeDateRange[1]).format('YYYY-MM-DD') + '/role';
        } else if (temp == 'wells') {
          link = apiConstants[this.componentName][this.subComponent]['gridExportWellForgoUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + moment(this.customeDateRange[0]).format('YYYY-MM-DD') + '/' + moment(this.customeDateRange[1]).format('YYYY-MM-DD') + '/role';
        } else if (temp == 'USBank') {
          link = apiConstants[this.componentName][this.subComponent]['gridExportUSBankUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + moment(this.customeDateRange[0]).format('YYYY-MM-DD') + '/' + moment(this.customeDateRange[1]).format('YYYY-MM-DD');
        } else {
          link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + moment(this.customeDateRange[0]).format('YYYY-MM-DD') + '/' + moment(this.customeDateRange[1]).format('YYYY-MM-DD');
        }
      } else if (this.subComponent == 'Real Time Positions') {
        link.href = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url;
      }
      else if (this.subComponent == 'Troniq' || this.subComponent == 'SRO' || this.subComponent == 'TRO'|| this.subComponent == 'Seamless'|| this.subComponent == 'SIO' || this.subComponent == 'connectCustodianInvestor') {
        link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount))
      } else if (this.subComponent == "dailyProcessingStatus") {
        this.isZoneName ? link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(JSON.stringify(this.isSystemAdmin)) + zone_name : link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(JSON.stringify(this.isSystemAdmin));
      }
    } else if (this.componentName == 'investors' || this.componentName == 'rapidInvestors') {
      this.isZoneName ? link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/false/' + zone_name : link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.isEnterprise + '/' + this.isSystemAdmin;
    } else if (this.componentName == 'cashBalanceBroker' || this.componentName == 'rapidCashBalanceBroker' || this.componentName == 'positionBroker' || this.componentName == 'rapidPositionBroker' || this.componentName == 'positionByTaxlot') {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + type;
    } else if (this.componentName == 'domainConfiguration' || this.componentName == 'alertThresholdLogManager') {
      this.isZoneName ? link = this.gridExportUrl : link = this.gridExportUrl;
    } else if (this.componentName == 'investorsList' || this.componentName == "setupInvestorsList") {
      this.isZoneName ? link = (this.isEnterprise ? this.gridExportUrl : apiConstants[this.componentName]['sysAdminGridExportUrl'].url) + encodeURI(JSON.stringify(this.totalCount)) + '/false/' + zone_name : link = (this.isEnterprise ? this.gridExportUrl : apiConstants[this.componentName]['sysAdminGridExportUrl'].url) + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.isEnterprise + '/' + this.isSystemAdmin;
    } else if (this.componentName == "taxInvestorsList") {
      let reqObj = { 'isEnterprise': this.isEnterprise };
      link = this.gridExportUrl + encodeURI(this.readObject(JSON.stringify(reqObj)));
    } else if (this.componentName == 'dailyProcessingStatus') {
      this.isZoneName ? link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(JSON.stringify(this.isSystemAdmin)) + zone_name : link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(JSON.stringify(this.isSystemAdmin));
    } else if (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory') {
      this.isZoneName ? link = this.gridExportUrl : link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(JSON.stringify(this.isSystemAdmin));
    } else if (this.componentName == 'alertThresholdLog') {
      this.isZoneName ? link = this.gridExportUrl + encodeURI(JSON.stringify(this.currentUser.id)) + '/false/' + zone_name : link = this.gridExportUrl + this.currentUser.id;
    } else if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.lookups;
    } else if (this.componentName == 'viewDataCollection') {
      link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.subComponent;

    } else if (this.componentName == 'chartofAccountTaxMappingDetails' || this.componentName == "K1Merging") {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.setuppercentage.getTaxGridData();
    }
    else if (this.componentName == 'taxAdjusted') {
      let fundata = this.setuppercentage.getTaxAdjustment();
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + fundata.fundId + '/' + fundata.year;
    }
    else if (this.componentName == 'workflowStatus') {
      if (this.oppValue) {
        var uriObj = { createdDate: this.reqObj.createdDate }
        link = link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + encodeURI(this.readObject(JSON.stringify(uriObj))) + '/' + zone_name + '/' + (((this.roleAs == "Manager" || this.roleAs == "enterprise") && this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP') > -1) ? "enterprise" : 'user');
      } else {
        link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.lookups + zone_name + '/' + (((this.roleAs == "Manager" || this.roleAs == "enterprise") && this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP') > -1) ? "enterprise" : 'user');
      }
    } else if (this.componentName == 'dataImportStatusManager') {
      if (this.oppValue) {
        var uriObj = { createdDate: this.reqObj.createdDate }
        link = link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + encodeURI(this.readObject(JSON.stringify(uriObj))) + '/' + zone_name;
      } else {
        link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.lookups + zone_name;
      }
    } else if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus") {
      if (this.oppValue) {
        var uriObj = { createdDate: this.reqObj.createdDate }
        link = link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + encodeURI(this.readObject(JSON.stringify(uriObj))) + '/' + zone_name + '/' + 'enterprise';
      } else {
        link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.lookups + zone_name + '/' + 'enterprise';
      }
    } else if (this.componentName == 'sourceConfiguration') {
      if (this.oppValue) {
        this.isZoneName ? link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalRecords)) + '/' + zone_name : link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalRecords));
      } else {
        this.blobExport = true;
        this.downloadsourceConfigurationPostExport();
      }
    } else if (this.componentName == 'workflowMapped') {
      link = this.gridExportUrl;
    } else if (this.componentName == 'executeWorkflow') {//executeWorkflow
      link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + zone_name + "?isExecutionLocked=" + this.isExecutionLocked;
    } else if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
      link = link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + "?historicalData=" + this.isAllocated;
    } else if (this.componentName == 'functions' || this.componentName == 'currencies') {
      link.href = link.href = this.totalCount == undefined ? this.gridExportUrl + 50 : this.gridExportUrl + this.totalCount;
    } else if (this.subComponent == 'Troniq' || this.subComponent == 'Seamless') {
      let req = {
        page: this.page,
        userType: "rapid"
      }
      link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalRecords)) + '/' + encodeURI(JSON.stringify(req))
    } else if (this.componentName != 'sourceConfiguration' && this.componentName != 'historicalInvestorCapitalSummary' && this.componentName != 'tradeArchiveList' && this.componentName != 'rapidTradeArchiveList') {
      this.isZoneName ? link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalRecords)) + '/' + zone_name : link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalRecords));
    }
    if (
      this.componentName == 'tradeList'
      || this.componentName == 'tradeArchiveList'
      || this.componentName == 'rapidTradeArchiveList'
      || this.componentName == 'viewclosedtaxLots'
      || this.componentName == 'viewtotalgl'
      || this.componentName == 'marketdata'
      || this.componentName == "rapidMarketdata"
      || this.componentName == 'viewjesummary'
      || this.componentName == "jeSummary"
      || this.componentName == 'rapidjesummary'
      || this.componentName == 'nonTradeActivity'
      || this.componentName == 'capitalActivities'
      || this.componentName == "rapidCapitalActivities"
      || this.componentName == 'tradehv'
      || (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadHV')
      || this.componentName == 'exchangerate'
      || this.componentName == 'rapidExchangerate'
      || this.componentName == 'mgtIncentiveFeeAdj'
      || this.componentName == 'rapidMgtIncentiveFeeAdj'
      || this.componentName == 'rapidItdSubscriptionAndRedemption'
      || this.componentName == 'itdSubscriptionAndRedemption'
      || this.componentName == 'MISFundList'
      || this.componentName == 'rapidDividendBroker'
    ) {
      link = this.gridExportUrl;
    }
    if ((this.componentName == 'investors' || this.componentName == 'rapidInvestors') || ((this.componentName == 'investorsList' && this.isEnterprise) || (this.componentName == "setupInvestorsList" && this.isEnterprise) || (this.componentName == "taxInvestorsList" && this.isEnterprise))) {
      let reqObj = { 'isEnterprise': this.isEnterprise };
      link = this.gridExportUrl + encodeURI(this.readObject(JSON.stringify(reqObj)));
    }
    if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      let reqObj = "moduleName=" + this.lookups;
      link = this.gridExportUrl + reqObj;
    }
    if (this.componentName == 'fSInvestor' || this.componentName == 'fSManagementCompanyInvestor') {
      let fsInvestorData = this.gridService.getFSInvestorData();
      const { fundGroupName, fundId } = fsInvestorData;
      link = `${this.gridExportUrl}${fundId}/${fundGroupName}/${encodeURI(JSON.stringify(this.totalRecords))}`;
    } else if (this.componentName == 'fschartOfAccountsRapid' || this.componentName == 'fsCashEquivalents' || this.componentName == 'fsExposure') {
      let fschartOfAccountsData = this.gridService.getFsChartOfAccounts();
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalRecords)) + '/' + fschartOfAccountsData['configId'];
    }
    else if (this.componentName == 'fsCashFlowUpdate') {
      let fsCashFlowData = this.gridService.getConfigData();
      const { startDate, endDate, configId, fundId } = fsCashFlowData;
      link = `${this.gridExportUrl}${encodeURI(JSON.stringify(this.totalRecords))}/${configId}/`;
    }
    else if (this.componentName == 'fSPortfolio') {
      const portfolioData = this.gridService.getFSPortFolioData();
      const {fundGroupName, endDate, configId, fundId } = portfolioData;
      link = `${this.gridExportUrl}${endDate}/${encodeURI(JSON.stringify(this.totalRecords))}/${configId}`;

    } else if (this.componentName == 'calculateTaxAllocationStatus') {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalRecords)) + '/' + encodeURI(JSON.stringify(this.showHistoricalDataTax));
    } else if (this.componentName == 'feeTemplateLinking') {
      link = this.gridExportUrl + "?moduleName=" + this.exportModuleName;
    }
    if (window.location.hostname == "localhost") {
      link = link.replace("4200", "8080");
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url)
  }

  downloadWellsCSV(types) {
    if (types == 'well') {
      this.downloadCSV('well');
      this.downloadCSV('wells');
    }
  }

  getFilteredData(evt?, gridfilter?) {
    if (this.componentName == "isdAdhocConfiguration") {
      return
    }
    if (this.subComponent != 'realState'  && (this.subComponent != 'Troniq' || evt)) {
      if (!this.filteredData.length) {
        if (this.filteredDataevt == null) {
          this.pageNumber = 1;
          this.page = 0;
        }
        this.filteredDataevt = evt;
        this.filteredData = evt.api.rowModel.rowsToDisplay;
        this.filterData = true;
        this.isfilter.emit(this.filterData);
      }
      if (this.componentName == 'marketdata' || this.componentName == "rapidMarketdata") {
        this.isFryFilterActmarketdata = true;
      }
      if (this.filteredData.length == 0 && (this.componentName == 'viewclosedtaxLots' || this.componentName == 'viewjesummary' || this.componentName == "jeSummary" || this.componentName == 'rapidjesummary')) {
        this.isFryFilterActmarketdata = true;
      }
      if (this.filteredData.length == 0 && (this.componentName == 'investors' || this.componentName == 'rapidInvestors')) {
        this.isFryFilterActmarketdata = true;
      }
      if (this.filteredData.length == 0 && (this.componentName == 'dataImportStatusManager')) {
        this.isFryFilterActmarketdata = true;
      }
      const filtredModel = evt.api.getFilterModel();
      if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
        evt == undefined ? evt = this.filteredDataevt : evt
        this.filteredData = [filtredModel];
      }
      if (this.componentName == 'tradehv' || (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadHV')) {
        evt == undefined ? evt = this.filteredDataevt : evt
        this.filteredData = [filtredModel];
      }
      if (this.componentName == 'itdSubscriptionAndRedemption') {
        evt == undefined ? evt = this.filteredDataevt : evt
        this.filteredData = [filtredModel];
      }
      if (this.componentName == 'rapidItdSubscriptionAndRedemption') {
        evt == undefined ? evt = this.filteredDataevt : evt
        this.filteredData = [filtredModel];
      }
      if (this.componentName == 'fSPortfolio') {
        this.filteredData = this.filteredData.length ? this.filteredData : [filtredModel];
      }
      let x = Object.entries(filtredModel);
      if (x.length) {
        var key = x[0][0];
        let value = x[0][1]['filter'];
        let gridOptions = this.gridApi;
        let searchObject = this.commonService.getDataToFilter(evt, gridOptions);
        let searchObject1 = this.commonService.getDataToFilter(evt, gridOptions);
        if (Object.keys(this.searchObjectTemp).length != Object.keys(searchObject1).length) {
          this.pageNumber = 1;
          this.page = 0;
        } this.searchObjectTemp = searchObject1;
        if (this.componentName == 'viewDataCollection') {
          searchObject['filterList'] = [];
        }
        Object.keys(searchObject).forEach(x => {
          switch (x) {
            case "brokerAccount.accountNumber": {
              searchObject["TransientBrokerAccount"] = searchObject[x];
              delete searchObject[x];
              x = "TransientBrokerAccount"
              break;
            }
            case "assetClass.name": {
              searchObject["TransientAssetClass"] = searchObject[x];
              delete searchObject[x];
              x = "TransientAssetClass"
              break;
            }
            case "transactionType": {
              if (this.componentName == 'tradingLevelChange' || this.componentName == 'rapidTradingLevelChange' || this.componentName == 'cashTransaction') {
                searchObject["transactionTypeValue"] = searchObject[x];
                delete searchObject[x];
                x = "transactionTypeValue"
                break;
              }
            }
            case "countryOfIncorporation.name": {
              if (this.componentName == 'tradeAdvisorList') {
                searchObject["countryOfIncorporation"] = searchObject[x];
                delete searchObject[x];
                x = "countryOfIncorporation"
                break;
              }
            }
            case "assetManagement.entityName": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["assetManagement"] = searchObject[x];
                delete searchObject[x];
                x = "assetManagement.entityName"
              }
              break;
            }
            case "countryOfTaxId1.name": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["countryOfTaxId1"] = searchObject[x];
                delete searchObject[x];
                x = "countryOfTaxId1"
              }
              break;
            }
            case "countryOfTaxId2.name": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["countryOfTaxId2"] = searchObject[x];
                delete searchObject[x];
                x = "countryOfTaxId2"
              }
              break;
            }
            case "countryOfTaxId3.name": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["countryOfTaxId3"] = searchObject[x];
                delete searchObject[x];
                x = "countryOfTaxId3"
              }
              break;
            }
            case "taxDocLookup.value": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["taxDocLookup"] = searchObject[x];
                delete searchObject[x];
                x = "taxDocLookup"
              }
              break;
            }
            case "baseCurrency.isoCode": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["baseCurrency"] = searchObject[x];
                delete searchObject[x];
                x = "baseCurrency"
              }
              break;
            }
            case "accountingMethodLookup.value": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["accountingMethodLookup"] = searchObject[x];
                delete searchObject[x];
                x = "accountingMethodLookup"
              }
              break;
            }
            case "fundStrategyLookup.value": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["fundStrategyLookup"] = searchObject[x];
                delete searchObject[x];
                x = "fundStrategyLookup"
              }
              break;
            }
            case "processISODate": {
              let date = this.gridService.getStartEndDate(searchObject.processISODate);
              searchObject['filterList'].push(date);
              delete searchObject[x];
              x = "processISODate"
              break;
            }
            case "entityList": {
              searchObject["entityList.fundName"] = searchObject[x];
              delete searchObject[x];

              break;
            }
            case "fundList": {
              searchObject["fundList.fundName"] = searchObject[x];
              delete searchObject[x];
              break;
            }
            case "exchangeName": {
              if (this.componentName == 'viewDataCollection') {
                searchObject['filterList'].push({ "exchangeName": searchObject.exchangeName });
                delete searchObject[x];
              }
              break;
            }
            case "clientRouteName": {
              if (this.componentName == 'viewDataCollection') {
                searchObject['filterList'].push({ "clientRouteName": searchObject.clientRouteName });
                delete searchObject[x];
              }
              break;
            }
            case "clientName": {
              if (this.componentName == 'viewDataCollection') {
                searchObject['filterList'].push({ "clientName": searchObject.clientName });
                delete searchObject[x];
              }
              break;
            }
            case "fundGroup.fundGroup": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["fundGroupName"] = searchObject[x];
                delete searchObject[x];
                x = "fundGroupName"
              }
              break;
            }
            case "ror_status": {
              if (this.componentName == 'ror-config-List' || this.componentName == 'customization-ror-config-List') {
                searchObject["status"] = searchObject[x];
                delete searchObject[x];
                x = "status"
              }
              break;
            }
            case "ta_commission": {
              if (this.componentName == 'tradeAllocation') {
                searchObject["commission"] = searchObject[x];
                delete searchObject[x];
                x = "commission"
              }
              break;
            }
            case "scr_status": {
              if (this.componentName == 'sourceConfiguration') {
                searchObject["status"] = searchObject[x];
                delete searchObject[x];
                x = "status"
              }
              break;
            }
            case "scr_industry": {
              if (this.componentName == 'sourceConfiguration') {
                searchObject["industry"] = searchObject[x];
                delete searchObject[x];
                x = "industry"
              }
              break;
            }
            case "sr_legalName": {
              if (this.componentName == 'sourceConfiguration') {
                searchObject["legalName"] = searchObject[x];
                delete searchObject[x];
                x = "legalName"
              }
              break;
            }
            case "cust_Tag": {
              if (this.componentName == 'sourceConfiguration') {
                searchObject["custodianTag"] = (searchObject[x] == ("yes" || "Yes") ? true : false);
                delete searchObject[x];
                x = "custodianTag"
              }
              break;
            }
            case "workflowName": {
              if (this.componentName == 'workflowMapped') {
                searchObject["alteryxWorkflowMngt.sourceName"] = searchObject[x];
                delete searchObject[x];
                x = "alteryxWorkflowMngt.sourceName"
              }
              break;
            }
            case "sourceName": {
              if (this.componentName == 'workflowMapped') {
                searchObject["alteryxWorkflowMngt.source"] = searchObject[x];
                delete searchObject[x];
                x = "alteryxWorkflowMngt.source"
              }
              break;
            }
            case "baseCurrency.value": {
              if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
                searchObject["baseCurrency"] = searchObject[x];
                delete searchObject[x];
                x = "baseCurrency"
              }
              break;
            }
            case "currency.value": {
              if (this.componentName == 'positionByTaxlot') {
                searchObject["currency"] = searchObject[x];
                delete searchObject[x];
                x = "currency"
              }
              break;
            } case "symbol.value": {
              if (this.componentName == 'positionByTaxlot') {
                searchObject["symbol"] = searchObject[x];
                delete searchObject[x];
                x = "symbol"
              }
              break;
            }
            case "height": {
              if (this.componentName == 'setupFundList') {
                searchObject["height"] = parseFloat(searchObject[x]);

              }

              break;
            }
            case "width": {
              if (this.componentName == 'setupFundList') {
                searchObject["width"] = parseFloat(searchObject[x]);

              }

              break;
            }
            case "clientLookup.value": {
              if (this.componentName == 'exchanges' || this.componentName == 'assetClass') {
                searchObject["clientLookup"] = "lookupselect##primaryAssetClass";
                searchObject["primaryAssetClass"] = { 'type': "PrimaryAssetClasses", "fields.name": "Primary Asset Class", "fields.value": searchObject[x] };
              } else {
                searchObject["clientLookup"] = "lookupselect##ratingAgency";
                searchObject["ratingAgency"] = { 'type': "RatingAgencies", "fields.name": "Name", "fields.value": searchObject[x] };
              }
              delete searchObject[x];
              x = "clientLookup.value"
              break;
            }
            case "fund.fundName": {
              if (this.componentName == 'grossCashFlowAdjustment' || this.componentName == 'contributionDistributionAdjustment' || this.componentName == 'nonTradeActivity' || this.componentName == 'incomeDistribution' || this.componentName == 'marketdata' || this.componentName == "rapidMarketdata" || this.componentName == 'viewCapitalCall' || this.componentName == 'rapidViewCapitalCall' || this.componentName == 'customRatio' || this.componentName == "rapidCustomRatio" || this.componentName == "taxCustomRatio" || this.componentName == 'viewDistribution' || this.componentName == 'rapidViewDistribution' || this.componentName == 'viewclosedtaxLots' || this.componentName == 'journalentry' || this.componentName == 'RapidJournalentry' || this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == "setupcustodianList" || this.componentName == "taxcustodianList") {
                searchObject["fund"] = searchObject[x];
                delete searchObject[x];
                x = "fund"
                break;
              } else if (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory' || this.componentName == 'historicalInvestorCapitalSummary') {
                searchObject["fundName"] = searchObject[x];
                delete searchObject[x];
                x = "fundName"
                break;
              } else if (this.componentName == 'hurdleRate') {
                searchObject["fund.fundName"] = searchObject[x];
                x = "fund.fundName"
                break;
              } else if (this.componentName != 'connectUserInvestor' && this.subComponent != 'connectUserInvestor' && this.componentName != 'hurdleRate' && this.componentName != 'dividendaccrual' && this.componentName != 'rapidDividend' && this.componentName !== 'tradeList') {
                if (this.subComponent && this.subComponent != 'Real Time Positions') {
                  searchObject["TransientFund"] = searchObject[x];
                  delete searchObject[x];
                  x = "TransientFund"
                }
                break;
              } else if (this.componentName == "tradeList") {
                searchObject["TransientFund"] = searchObject[x];
                delete searchObject[x];
                x = "TransientFund"
                break;
              }
            }
            case "currency.value": {
              if (this.componentName == 'positionByTaxlot') {
                searchObject["currency"] = searchObject[x];
                delete searchObject[x];
                x = "currency"
              }
              break;
            } case "symbol.value": {
              if (this.componentName == 'positionByTaxlot') {
                searchObject["symbol"] = searchObject[x];
                delete searchObject[x];
                x = "symbol"
              }
              break;
            }
            case "currency.id": {
              if (this.componentName == 'setupbondinterest' || this.componentName == 'rapidSetupbondinterest') {
                searchObject["currency"] = searchObject[x];
                delete searchObject[x];
                x = "currency"
                break;
              } else {
                searchObject["currency.id"] = searchObject[x];
                delete searchObject[x];
                x = "currency.id"
                break;
              }
            }
            case "enterprise.enterpriseName": {
              searchObject["enterprise"] = searchObject[x];
              delete searchObject[x];
              x = "enterprise"
              break;
            }
            case "source.isoCode": {
              searchObject["source"] = searchObject[x];
              delete searchObject[x];
              x = "source"
              break;
            }
            case "countryMaster.name": {
              if (this.componentName == 'regulatorsForSetting' || this.componentName == 'regulators') {
                searchObject["countryMaster"] = searchObject[x];
                delete searchObject[x];
                x = "countryMaster"
              }
              break;
            }
            case "exchanges.exchangeName": {
              searchObject["exchanges"] = searchObject[x];
              delete searchObject[x];
              x = "exchanges"
              break;
            }
            case "currency.isoCode": {
              searchObject["currency"] = searchObject[x];
              delete searchObject[x];
              x = "currency"
              break;
            }
            case "assetClasses.name": {
              searchObject["assetClasses"] = searchObject[x];
              delete searchObject[x];
              x = "assetClasses"
              break;
            }
            case "clientLookup.value": {
              searchObject["primaryAssetClass"] = searchObject[x];
              delete searchObject[x];
              x = "primaryAssetClass"
              break;
            }
            case "fundName": {
              if (this.componentName != 'viewtotalgl' && this.componentName != 'investorStatementFooterCustomization' && this.componentName != 'viewPnlDistribution' && this.componentName != 'rapidViewPnlDistribution' && this.componentName != 'benchmarkirr' && this.componentName != 'rapidBenchmarkirr' && this.componentName != 'reportApproval' && this.componentName != 'customizationReportApproval' && this.componentName != 'runViewOpenTaxlots' && this.componentName != 'viewclosedtaxLots' && this.componentName != 'dailyMargin' && this.componentName != 'rapidDailyMargin' && this.componentName != 'fsExposure'&& this.componentName !='fSPortfolio'
                && this.componentName != 'FundList' && this.componentName != 'setupFundList' && this.componentName != "taxFundList" && this.componentName != 'TLRegime' && this.componentName != 'rapidTLRegime' && this.componentName != 'Manager' && this.componentName != 'externalROR' && this.componentName != 'rapidExternalROR' && this.componentName != 'investorgroup' && this.componentName != "rapidInvestorgroup" && this.componentName != 'inputDataIntegritySummery' && this.componentName != 'MISFundList' && this.componentName != 'fundPropertyList') {
                searchObject["fund"] = searchObject[x];
                delete searchObject[x];
                x = "fund"
                break;
              } else {
                searchObject["fundName"] = searchObject[x];
                x = "fundName"
                break;
              }
            }
            case "enterpriseName": {
              if (this.componentName == 'MISFundList') {
                searchObject["transientEnterprise"] = searchObject[x];
                delete searchObject[x];
                x = "transientEnterprise"
              }
              break;
            }
            case "country.name": {
              if (this.componentName != 'exchanges') {
                searchObject["country"] = searchObject[x];
                delete searchObject[x];
                x = "country"
              }
              break;
            }
            case "assetBasedFee": {
              if (this.componentName == 'feeRateChange') {
                searchObject["assetBasedFeeValue"] = searchObject[x];
                delete searchObject[x];
                x = "assetBasedFeeValue"
              }
              break;
            }
            case "performanceFee": {
              if (this.componentName == 'feeRateChange') {
                searchObject["performanceFeeValue"] = searchObject[x];
                delete searchObject[x];
                x = "performanceFeeValue"
              }
              break;
            }
            case "market": {
              searchObject["marketValue"] = searchObject[x];
              delete searchObject[x];
              x = "marketValue"
              break;
            }
            case "PAssetClass": {
              if (this.componentName == 'workflowManagement') {
                searchObject["primaryAssetClass"] = searchObject[x];
                delete searchObject[x];
                x = "primaryAssetClass"
              }
              break;
            }
            case "ProcessName": {
              if (this.componentName == 'workflowManagement') {
                searchObject["processName"] = searchObject[x];
                delete searchObject[x];
                x = "processName"
              }
              break;
            }
            case "AssetClass": {
              if (this.componentName == 'workflowManagement') {
                searchObject["assetClass"] = searchObject[x];
                delete searchObject[x];
                x = "assetClass"
              }
              break;
            }
            case "inputMode": {
              if (this.componentName == 'workflowManagement') {
                searchObject["inputModeType"] = searchObject[x];
                delete searchObject[x];
                x = "inputModeType"
              }
              break;
            }
            case "fileNameSrv": {
              if (this.componentName == 'workflowManagement') {
                searchObject["workflowName"] = searchObject[x];
                delete searchObject[x];
                x = "workflowName"
              }
              break;
            }
            case "client_Ip": {
              searchObject["Client_Ip"] = searchObject[x];
              delete searchObject[x];
              x = "Client_Ip"
              break;
            }
            case "fundManager.firstName": {
              searchObject["fundManager"] = searchObject[x];
              delete searchObject[x];
              x = "fundManager"
              break;
            }
            case "brokerName.accountNumber": {
              if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
                searchObject["accountNumber"] = searchObject[x];
                delete searchObject[x];
                x = "accountNumber"
                break;
              }
            }
            case "brokerName.nameOfCustodian": {
              if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
                searchObject["brokerName"] = searchObject[x];
                delete searchObject[x];
                x = "brokerName"
                break;
              }
            }
            case "frequency": {
              if (this.componentName == 'executeWorkflow') {
                searchObject["alteryxWorkflowMngt.frequency"] = searchObject[x];
                delete searchObject[x];
                x = "alteryxWorkflowMngt.frequency"
                break;
              }
            }
            case "executionPeriod": {
              if (this.componentName == 'executeWorkflow') {
                searchObject["alteryxWorkflowMngt.executionPeriod"] = searchObject[x];
                delete searchObject[x];
                x = "alteryxWorkflowMngt.executionPeriod"
                break;
              }
            }
            default: {
              x = key
              break;
            }
          }
        });
        gridfilter != undefined ? this.page = 0 : this.page;
        searchObject['page'] = this.page;
        if (this.orderFilter) {
          searchObject['orderFilter'] = this.orderFilter
        }
        if (this.componentName == "enterprises" && searchObject['status']) {
          if (searchObject['status'].toLowerCase() == 'active') {
            searchObject['status'] = 'active';
          }
          if (searchObject['status'].toLowerCase() == 'deactive') {
            delete searchObject['status']
            searchObject['status'] = 'inactive';
          }
          if (searchObject['status'].toLowerCase() == 'pending') {
            searchObject['status'] = 'init';
          }
        }
        if (this.componentName == "bill" || this.componentName == "rapidBill") {
          searchObject['isFilter'] = true;
          searchObject['entryType'] = "Bill"
        }
        if (this.componentName == "rapidInvoice" ) {
          searchObject['isFilter'] = true;
          searchObject['entryType'] = "Invoice"
        }
    if (this.componentName == "vendorCreditList" ) {
      searchObject['isFilter'] = true;
      searchObject['entryType'] = "credit"
        }
        if (this.componentName == "cashExpenseList" ) {
          searchObject['isFilter'] = true;
          searchObject['entryType'] = "CashExpense"
        }
        if (this.componentName == "reReportConfig") {
          searchObject['isEnterprise'] = this.isEnterprise;
        }
        if(this.componentName == "cashIncomeList"){
          searchObject['isFilter'] = true;
          searchObject['entryType'] = "CashIncome"
        }
        if (this.componentName == "unpaidBills" || this.componentName == "rapidUnpaidBills") {
          searchObject['isFilter'] = true;
          searchObject['billStatus'] = "Unpaid";
          searchObject['entryType'] = "Bill"
        }
        if (this.componentName == "rapidOutstandingInvoice") {
          searchObject['isFilter'] = true;
          searchObject['billStatus'] = "Unpaid";
          searchObject['entryType'] = "Invoice"
        }
        if (this.componentName == "paidBills" || this.componentName == "rapidPaidBills") {
          searchObject['isFilter'] = true;
          searchObject['billStatus'] = "Paid";
          searchObject['entryType'] = "Bill"
        }
        if (this.componentName == "paymentRecipt" ) {
          searchObject['isFilter'] = true;
          searchObject['billStatus'] = "Paid";
          searchObject['entryType'] = "Invoice"
        }
        if (this.componentName == "dailyProcessingStatus" || (this.componentName == 'automate-process' && (this.subComponent == "dailyProcessingStatus" || this.subComponent == "dailyProcessingView"))) {
          if (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) {
            searchObject['isSystemAdmin'] = true;
          } else {
            if (this.gridApi != null || this.gridApi != undefined) {
              if (this.rowData != null || this.rowData != undefined) {
                this.gridApi.setRowData(this.rowData);
                this.filteredData = this.rowData
              }
            }
            searchObject['isSystemAdmin'] = false;
          }

          if (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingView") {
            searchObject['isRapid'] = true;
          }
        }
        if (this.componentName == "omslist" || this.componentName == 'rapidOmslist') {
          if (this.subComponent == 'unallocated_order') {
            searchObject['orderStatus'] = false
            searchObject['role'] = this.currentRole
          } else if (this.subComponent == 'allocated_order') {
            searchObject['orderStatus'] = true
            searchObject['role'] = this.currentRole
          } else if (this.subComponent == 'unapproved_order') {
            searchObject['orderStatus'] = true
            searchObject['role'] = this.currentRole
            searchObject['sendEmail'] = true
          } else if (this.subComponent == 'approved_order') {
            searchObject['toDate'] = moment(this.customeDateRange[1]).format('YYYY-MM-DD');
            searchObject['fromDate'] = moment(this.customeDateRange[0]).format('YYYY-MM-DD');
          }
        }

        if (this.componentName == "batchSession") {
          let batchId = localStorage.getItem('batchID');
          searchObject['batchId'] = batchId;
        }
        if (this.componentName == 'paymentPayableData' || this.componentName == 'rapidPaymentPayableData') {
          searchObject["isFilter"] = true
        }
if(this.componentName == 'rapidReceiptsData'){
searchObject["isFilter"] = true
}
        if (this.componentName == "tradeSucessListHV" || this.componentName == 'rapidTradeSucessListHV') {
          let tradeUploadId = localStorage.getItem('tradeUploadId');
          searchObject['tradeUploadId'] = tradeUploadId;
        }
        if (this.componentName == 'fSInvestor' || this.componentName == 'fSManagementCompanyInvestor') {
          let fsInvestorData = this.gridService.getFSInvestorData();
          const { fundGroupName, fundId } = fsInvestorData;
          if (fundGroupName) {
            searchObject['fundId'] = null;
            searchObject['fundGroupName'] = fundGroupName
          } else {
            searchObject['fundId'] = fundId;
          }
        }
        if (this.componentName == 'fschartOfAccountsRapid' || this.componentName == 'fsCashEquivalents' || this.componentName == 'fsExposure') {
          let fschartOfAccountsData = this.gridService.getFsChartOfAccounts();
          searchObject['configId'] = fschartOfAccountsData['configId']
        }
        if (this.componentName == 'fSPortfolio') {
          const portfolioData = this.gridService.getFSPortFolioData();
          const {fundGroupName, endDate, configId, fundId } = portfolioData;
          searchObject['endDate'] = endDate;
          searchObject['configId'] = configId;
          if (fundGroupName) {
             searchObject['fundId'] = null;
          } else {
            searchObject['fundId'] = fundId;
          }
        }
        if (searchObject['partnerShareOfLiabilities_IncludeLowerTierPartnerships'] && this.componentName == 'taxAdditionK1Details') {
          searchObject['partnerShareOfLiabilities_IncludeLowerTierPartnerships'] = JSON.parse(searchObject['partnerShareOfLiabilities_IncludeLowerTierPartnerships']);
        }
        if (searchObject['subjectToGuarantees_PaymentObligations'] && this.componentName == 'taxAdditionK1Details') {
          searchObject['subjectToGuarantees_PaymentObligations'] = JSON.parse(searchObject['subjectToGuarantees_PaymentObligations']);
        }
        if (searchObject['tradingVolume'] && this.componentName == 'marketdata') {
          searchObject['tradingVolume'] = searchObject['tradingVolume'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        }
        if (searchObject['tradingVolume'] && this.componentName == "rapidMarketdata") {
          searchObject['tradingVolume'] = searchObject['tradingVolume'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        }
        if (searchObject['executionSequence']) {
          searchObject['executionSequence'] = searchObject['executionSequence'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        }
        if (searchObject['dataCount']) {
          searchObject['dataCount'] = searchObject['dataCount'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        }
        if (searchObject['netReturn'] && (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory')) {
          searchObject['netReturn'] = searchObject['netReturn'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        } else if (searchObject['netReturn']) {
          searchObject['netReturn'] = parseInt(searchObject['netReturn']);
        }
        if (searchObject['rorMonth']) {
          searchObject['rorMonth'] = parseInt(searchObject['rorMonth']);
        }
        if (searchObject['vami'] && (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory')) {
          searchObject['vami'] = searchObject['vami'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        } else if (searchObject['vami'] && this.componentName == 'programHistoricalData') {
          searchObject['vami'] = searchObject['vami'];
        } else if (searchObject['vami'] && this.componentName == 'rapidProgramHistoricalData') {
          searchObject['vami'] = searchObject['vami'];
        } else if (searchObject['vami']) {
          searchObject['vami'] = parseInt(searchObject['vami']);
        }
        if ((this.componentName == "setupFundList" || this.componentName == "FundList" || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") && searchObject['auaCode']) {
          searchObject["auaCode"] = searchObject["auaCode"] == 'Yes' ? "1" : "0";
        }
        if (searchObject['symbol'] && this.componentName != 'positionByTaxlot' && this.componentName != 'tradeAllocation') {
          searchObject['symbolValue'] = searchObject['symbol'];
          delete searchObject['symbol'];
        }
        if (searchObject['successCount']) {
          searchObject['successCount'] = searchObject['successCount'] + "LHRXJ10EWRnumberZRHXJ10JFK";
        }
        if (this.componentName == "chartOfAccounttaxClassification") {
          let filteredData = evt.api.rowModel.rowsToDisplay;
          if (!filteredData || !filteredData.length) {
            this.gridApi.showNoRowsOverlay()
          } else {
            this.gridApi.hideOverlay();
          }
        }
        else if (this.componentName == "runViewOpenTaxlots") {
          if (searchObject['taxLotDate']) {
            searchObject['tradeEntry.taxLotDate'] = searchObject['taxLotDate']
            delete searchObject['taxLotDate'];
          }
        } else if (this.componentName == "dataImportStatus" || this.componentName == "customizationDataImportStatus") {
          searchObject['userType'] = "enterprise"
        } else if (this.componentName == "workflowStatus") {
          searchObject['userType'] = ((this.roleAs == "Manager" || this.roleAs == "enterprise") && this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP') > -1) ? 'enterprise' : "user"
        } else if (this.componentName == "investorsaddresses") {
          searchObject['investor'] = searchObject['investor.name']
          delete searchObject['investor.name'];
        } else if (this.componentName == "investors" || this.componentName == 'rapidInvestors') {
          searchObject['investorGroup'] = searchObject['investorGroup.toString()']
          delete searchObject['investorGroup.toString()'];
        } else if (this.componentName == "capitalActivities" || this.componentName == "rapidCapitalActivities") {
          searchObject['investor'] = searchObject['investor.name']
          delete searchObject['investor.name'];
          searchObject['transferor'] = searchObject['transferor.name']
          delete searchObject['transferor.name'];
          searchObject['transferee'] = searchObject['transferee.name']
          delete searchObject['transferee.name'];
        } else if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
          searchObject['moduleName'] = this.lookups
        } else if (this.componentName == "FundList" || this.componentName == 'setupFundList' || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") {
          searchObject['entityLookup'] = searchObject['entityLookup.lookupValues.TypeOfEntities']
          delete searchObject['entityLookup.lookupValues.TypeOfEntities'];
          searchObject['formPfClassificationLookup'] = searchObject['formPfClassificationLookup.value']
          delete searchObject['formPfClassificationLookup.value'];
        } else if (this.componentName == 'tradeList') {
          searchObject['ticketChargesCurrency'] = searchObject['ticketChargesCurrency.id']
        } else if (this.componentName == "workflowManagement" && searchObject['isPreviewData'] != null) {
          searchObject['isPreviewData'] = searchObject['isPreviewData'].toLowerCase() == 'yes' ? true : searchObject['isPreviewData'].toLowerCase() == 'no' ? false : searchObject['isPreviewData'];
        } else if (this.componentName == "workflowManagement" && searchObject['uploadData'] != null) {
          searchObject['isUploadData'] = searchObject['uploadData'].toLowerCase() == 'yes' ? true : searchObject['uploadData'].toLowerCase() == 'no' ? false : searchObject['uploadData'];
          delete searchObject['uploadData'];
        } else if (this.componentName == "workflowManagement" && searchObject['executionLocked'] != null) {
          searchObject['isExecutionLocked'] = searchObject['executionLocked'].toLowerCase() == 'yes' ? true : searchObject['executionLocked'].toLowerCase() == 'no' ? false : searchObject['executionLocked'];
          delete searchObject['executionLocked'];
        } else if ((this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") && searchObject['historicalData'] != null) {
          searchObject['historicalData'] = searchObject['historicalData'].toLowerCase() == 'yes' ? true : searchObject['historicalData'].toLowerCase() == 'no' ? false : searchObject['historicalData'];
          delete searchObject['historicalData'];
        } else if (this.componentName == "batch" && searchObject['batchName'] != null) {
          searchObject['search'] = searchObject['batchName']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['batchName'];
          delete searchObject['page'];
        } else if ((this.componentName == "batch" || this.componentName == "rapidBatch") && searchObject['createdOn'] != null) {
          searchObject['search'] = searchObject['createdOn']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['createdOn'];
          delete searchObject['page'];
        } else if ((this.componentName == "batchLog" || this.componentName == "rapidBatchLog") && searchObject['executionStatus'] != null) {
          searchObject['search'] = searchObject['executionStatus']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['executionStatus'];
          delete searchObject['page'];
        } else if ((this.componentName == "batchLog" || this.componentName == "rapidBatchLog") && searchObject['batchName'] != null) {
          searchObject['search'] = searchObject['batchName']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['batchName'];
          delete searchObject['page'];
        } else if ((this.componentName == "batchLog" || this.componentName == "rapidBatchLog") && searchObject['executionDateTime'] != null) {
          searchObject['search'] = searchObject['executionDateTime']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['executionDateTime'];
          delete searchObject['page'];
        } else if (this.componentName == "viewDataCollection") {
          searchObject['collectionName'] = this.subComponent;
          searchObject['enterpriseId'] = this.currentUser['enterpriseId'];
        } else if ((this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') && searchObject['createdOn'] != null) {
          searchObject['search'] = searchObject['createdOn']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['createdOn'];
          delete searchObject['page'];
        } else if ((this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') && searchObject['scheduleName'] != null) {
          searchObject['search'] = searchObject['scheduleName']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['scheduleName'];
          delete searchObject['page'];
        } else if ((this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') && searchObject['batchName'] != null) {
          searchObject['search'] = searchObject['batchName']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['batchName'];
          delete searchObject['page'];
        } else if ((this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') && searchObject['updatedOn'] != null) {
          searchObject['search'] = searchObject['updatedOn']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['updatedOn'];
          delete searchObject['page'];
        } else if ((this.componentName == "scheduleTaskInfo" || this.componentName == 'rapidScheduleTaskInfo') && searchObject['nextExecutionTime'] != null) {
          searchObject['search'] = searchObject['nextExecutionTime']
          searchObject['length'] = 100
          searchObject['start'] = searchObject['page']
          delete searchObject['nextExecutionTime'];
          delete searchObject['page'];
        } else if (this.componentName == 'tradeAdvisorList') {
          if (searchObject['taxDocLookup.value']) {
            delete searchObject['taxDocLookup.value'];
            searchObject['taxDocLookup'] = "lookupselect##typeOfTaxDoc"
            searchObject['typeOfTaxDoc'] = { 'type': "TypeOfTaxDoc", 'fields.name': "TypeOfTaxDoc", 'fields.value': searchObject['taxDocLookup.value'] }
          }
          if (searchObject['entityLookup.lookupValues.TypeOfEntities']) {
            delete searchObject['entityLookup.lookupValues.TypeOfEntities'];
            searchObject['entityLookup'] = "lookupselect##entityType"
            searchObject['entityType'] = { 'type': "TypeOfEntities", 'fields.name': "TypeOfEntities", 'fields.value': searchObject['entityLookup.lookupValues.TypeOfEntities'] }
          }
        } else if (this.componentName == 'sourceConfiguration') {
          if (this.isAllocated) {
            searchObject["status"] = "pending";
          }
          if (searchObject['sftpTypeName']) {
            searchObject["sftpTypeName"] = btoa(searchObject["sftpTypeName"]);
          }
        } else if (this.componentName == 'sftpConfiguration') {
          if (searchObject['username']) {
            searchObject["username"] = btoa(searchObject["username"]);
          }
          if (searchObject['awsConfig']) {
            searchObject["inputModes"] = searchObject["awsConfig"]
            delete searchObject["awsConfig"]

          }
          if (searchObject['sftpTypeName']) {
            searchObject["sftpTypeName"] = btoa(searchObject["sftpTypeName"]);
          }
          if (searchObject['host']) {
            searchObject["host"] = btoa(searchObject["host"]);
          }
        } else if ((this.componentName == "custodianList" || this.componentName == 'custodianListLegacy' || this.componentName == "setupcustodianList" || this.componentName == "taxcustodianList") && searchObject['accountNumber'] != undefined) {
          searchObject['accountNumberValue'] = searchObject['accountNumber']
          delete searchObject['accountNumber'];
        } else if ((this.componentName == 'inputConfiguration' || this.componentName == "customizationInputConfiguration") && searchObject['custassetClass'] != undefined) {
          if (this.isAllocated) {
            searchObject["disabled"] = true;
          }
          searchObject['assetClass'] = searchObject['custassetClass'];
          delete searchObject['custassetClass'];
        } else if ((this.componentName == 'inputConfiguration' || this.componentName == "customizationInputConfiguration") && searchObject['CustaccountNumber'] != undefined) {
          if (this.isAllocated) {
            searchObject["disabled"] = true;
          }
          searchObject['custodianAccountNumber'] = searchObject['CustaccountNumber'];
          delete searchObject['CustaccountNumber'];
        }
        else if ((this.componentName == "setupcustodianList" || this.componentName == "taxcustodianList") && searchObject['isWhiteList'] != undefined) {
          if (searchObject['isWhiteList']) {
            if (searchObject['isWhiteList'].toLowerCase() == 'no') {
              searchObject['isWhiteList'] = "false";
            } else if (searchObject['isWhiteList'].toLowerCase() == 'yes') {
              searchObject['isWhiteList'] = "true";
            }
          }
        }

        else if (this.componentName == 'nonCustodianList' || this.componentName == 'customizationNonCustodianList') {
          searchObject['alteryxworkFlowName'] = searchObject['workflowMngt.sourceName']
          delete searchObject['workflowMngt.sourceName'];
          if (searchObject['workflowMngt.isSelected'] != null && searchObject['workflowMngt.isSelected'] != undefined) {
            searchObject['checked'] = searchObject['workflowMngt.isSelected'].toLowerCase() == 'yes' ? true : searchObject['workflowMngt.isSelected'].toLowerCase() == 'no' ? false : searchObject['workflowMngt.isSelected'];
            delete searchObject['workflowMngt.isSelected'];
          }
          searchObject['sourceFilePath'] = searchObject['sourcefilePath']
          delete searchObject['sourcefilePath'];
          searchObject['fileNameFormat'] = searchObject['filenameFormat']
          delete searchObject['filenameFormat'];
          searchObject['sourceName'] = searchObject['workflowMngt.source']
          delete searchObject['workflowMngt.source'];
        } else if (this.componentName == "customization-connectionList" || this.componentName == "connectionList") {
          if (searchObject['custodianSourceConfig.workflowMngt.isSelected'] != null && searchObject['custodianSourceConfig.workflowMngt.isSelected'] != undefined) {
            searchObject['isSelected'] = searchObject['custodianSourceConfig.workflowMngt.isSelected'].toLowerCase() == 'yes' ? true : searchObject['custodianSourceConfig.workflowMngt.isSelected'].toLowerCase() == 'no' ? false : searchObject['custodianSourceConfig.workflowMngt.isSelected'];
            delete searchObject['custodianSourceConfig.workflowMngt.isSelected'];
          }
          searchObject['custodianAccountNumber'] = searchObject['custodianSourceConfig.custodianAccountNumber']
          delete searchObject['custodianSourceConfig.custodianAccountNumber'];
          searchObject['sourceLegalName'] = searchObject['custodianSourceConfig.workflowMngt.sourceLegalName']
          delete searchObject['custodianSourceConfig.workflowMngt.sourceLegalName'];
          searchObject['source'] = searchObject['custodianSourceConfig.workflowMngt.source']
          delete searchObject['custodianSourceConfig.workflowMngt.source'];
          searchObject['sourceName'] = searchObject['custodianSourceConfig.workflowMngt.sourceName']
          delete searchObject['custodianSourceConfig.workflowMngt.sourceName'];
          searchObject['fileNameFormat'] = searchObject['filenameFormat']
          delete searchObject['filenameFormat'];
          if (searchObject['custodianSourceConfig.workflowMngt.isPreviewData'] != null && searchObject['custodianSourceConfig.workflowMngt.isPreviewData'] != undefined) {
            searchObject['isPreviewData'] = searchObject['custodianSourceConfig.workflowMngt.isPreviewData'].toLowerCase() == 'yes' ? true : searchObject['custodianSourceConfig.workflowMngt.isPreviewData'].toLowerCase() == 'no' ? false : searchObject['custodianSourceConfig.workflowMngt.isPreviewData'];
            delete searchObject['custodianSourceConfig.workflowMngt.isPreviewData']
          }
          if (searchObject['custodianSourceConfig.workflowMngt.uploadData'] != null && searchObject['custodianSourceConfig.workflowMngt.uploadData'] != undefined) {
            searchObject['uploadData'] = searchObject['custodianSourceConfig.workflowMngt.uploadData'].toLowerCase() == 'yes' ? true : searchObject['custodianSourceConfig.workflowMngt.uploadData'].toLowerCase() == 'no' ? false : searchObject['custodianSourceConfig.workflowMngt.uploadData'];
            delete searchObject['custodianSourceConfig.workflowMngt.uploadData']
          }
          searchObject['frequency'] = searchObject['custodianSourceConfig.workflowMngt.frequency']
          delete searchObject['custodianSourceConfig.workflowMngt.frequency'];
          if (searchObject['custodianSourceConfig.workflowMngt.executionPeriod'] != null && searchObject['custodianSourceConfig.workflowMngt.executionPeriod'] != undefined) {
            searchObject['executionPeriod'] = parseInt(searchObject['custodianSourceConfig.workflowMngt.executionPeriod'])
            delete searchObject['custodianSourceConfig.workflowMngt.executionPeriod']
          }
        }
        var reqObj = searchObject;
        this.reqObj1 = reqObj;
        let reqObj2 = this.trimObjValues(reqObj);
        if (this.componentName == 'reportNotificationStatusList' || this.componentName == 'customizationReportNotificationStatusList') {
          let filterUrl = this.filterUrl.split('/');
          this.filterUrl = filterUrl[0] + ((this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? "/filterByKeys" : "/filterEnterpriseByKeys")
        }
        if (this.componentName == "workflowMapped") {
          reqObj2['enterpriseName'] = searchObject['country'];
          delete reqObj2['country'];
        }
        if (this.subComponent == 'Troniq'|| this.subComponent == 'Seamless') {
          reqObj2['userType'] = "rapid";
        }
        if (this.componentName == 'executeWorkflow') {
          reqObj2['isExecutionLocked'] = this.isExecutionLocked;
        }
        if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
          reqObj2['historicalData'] = this.isAllocated;
        }
        if (this.componentName == "isdClientApprovalGroupFund") {
          reqObj2['isHistorical'] = this.isAllocated;
        }
        if (this.componentName == "duplicateSetupInvestorsList" || this.componentName == "duplicaterapidInvestors") {
          reqObj2['fundId'] = this.duplicateinvFundId;
        }
        if (this.componentName == "calculateTaxAllocationStatus") {
          reqObj2['showHistoricalDataTax'] = this.showHistoricalDataTax
        }
        if (this.componentName == "chartofAccountTaxMappingDetails" || this.componentName == "K1Merging") {
          this.configIdMapping = this.setuppercentage.getTaxGridData();
          reqObj2['configId'] = this.configIdMapping;
        }
        if (this.componentName == 'taxAdjusted') {
          this.setupPercentageService.setIsJECreated(false)
          let fundata = this.setuppercentage.getTaxAdjustment();
          reqObj2['fundId'] = fundata.fundId;
          reqObj2['year'] = fundata.year;
        }
        if (this.componentName == 'FSAutomation' || this.componentName == 'archivedFSAutomation') {
          reqObj2['isDeleted'] = this.componentName == 'archivedFSAutomation' ? true : false;
        }
        this.gridService.getFilteredData(this.filterUrl, reqObj2, (cbs) => {
          this.setupPercentageService.setIsJECreated(false)
          if (cbs.resultList != null) {
            if (this.componentName == 'executeWorkflow' && !this.isFryFilterAct) {
              this.totalRecords = cbs.totalCount
            }
            if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
              this.isFryFilterAct = true;
              this.totalFltRecords = cbs.totalCount
            }
            if (this.componentName == 'positionBroker' || this.componentName == 'rapidPositionBroker') {
              this.filteredData = cbs.resultList
            }
            if (this.componentName == 'rapidDividendBroker') {
              this.filteredData = cbs.resultList
              this.rowData = _.cloneDeep(cbs.resultList);
              this.gridApi.setRowData(this.rowData);
              this.filteredData = this.rowData

            }
            if (this.moduleName == "dataImportStatusManager" || this.moduleName == "dataImportStatus"
              || this.moduleName == "workflowStatus" || this.moduleName == "executeWorkflow") {
              this.filteredData.length = cbs.totalCount;
            }

            cbs.resultList.forEach(element => {
              if (this.componentName == "customizationReportApproval") {
                element.decimal = element.decimal?.toString();
              }
              if (this.componentName == 'rapidDividendBroker') {
                if (element["exDate"]) {
                  element["exDate"] = moment(new Date(element['exDate'])).format("MM-DD-YYYY");
                }
                if (element["payDate"]) {
                  element["payDate"] = moment(new Date(element['payDate'])).format("MM-DD-YYYY");

                }
                if (element["reportDate"]) {
                  element["reportDate"] = moment(new Date(element['reportDate'])).format("MM-DD-YYYY");
                }
                this.filteredData = cbs.resultList

              }
              if (this.componentName == "fSPortfolio") {
                if (element["date"]) {
                  element["date"] = moment(new Date(element['date'])).format("MM-DD-YYYY");
                }
                if (element["maturityDate"]) {
                  element["maturityDate"] = moment(new Date(element['maturityDate'])).format("MM-DD-YYYY");
                }
              }
              if (this.componentName == "capitalActivities" || this.componentName == "rapidCapitalActivities") {
                element["investorNumber"] = element.investor && element.investor.investorNumber ? element.investor.investorNumber : null;
                element["investorId"] = element.investor && element.investorId ? element.investorId : null;
              }
              if (this.componentName == 'tradeUploadEntryHV' || (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadEntry')) {
                if (element['aggregateDate']) {
                  element["aggregateDate"] = moment(new Date(element['aggregateDate'])).format("MM/DD/YYYY");
                }
              }
              if (this.componentName == "setupFundList" || this.componentName == "FundList" || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") {
                cbs.resultList.forEach((element: any) => {
                  if (element["auaCode"] == "1") {
                    element["auaCode"] = "Yes"
                  }
                  else if (element["auaCode"] == "0") {
                    element["auaCode"] = "No"
                  }
                })
              }
              if (element.transferActivity == true && element.transactionType == 'Subscription - First Time') {
                element.transactionType = 'Subscription - Transfer';
              }
              if (this.componentName == "taxVendors" || this.componentName == "vendors" || this.componentName == "taxInvestorsList" || this.componentName == "taxFundList" || this.componentName == "setupInvestorsList" || this.componentName == "setupFundList" || this.componentName == "taxPartnershipPassthroughK1") {
                if (element['taxId']) {
                  element['actualValueTaxId'] = element['taxId'];
                  element['taxId'] = "*********";
                }
                if (element['taxId1']) {
                  element['actualValueTaxId1'] = element['taxId1'];
                  element['taxId1'] = "*********";
                }
                if (element['taxId2']) {
                  element['actualValueTaxId2'] = element['taxId2'];
                  element['taxId2'] = "*********";
                }
                if (element['taxId3']) {
                  element['actualValueTaxId3'] = element['taxId3'];
                  element['taxId3'] = "*********";
                }
                if (element['disregardedTaxId']) {
                  element['actualValuedisregardedTaxId'] = element['disregardedTaxId'];
                  element['disregardedTaxId'] = "*********";
                }
                if (element['partnershipsTaxId']) {
                  element['actualValuePartnershipsTaxId'] = element['partnershipsTaxId'];
                  element['partnershipsTaxId'] = "*********";
                }
              }
              if (element.transferActivity == true && element.transactionType == 'Redemption - Partial by Amount') {
                element.transactionType = 'Redemption - Transfer';
              }
              if (element["alteryxWorkflowMngt"] != null && (this.componentName == 'nonCustodianList' || this.componentName == 'customizationNonCustodianList')) {
                if (element["alteryxWorkflowMngt"][0]) {
                  element["frequency"] = element["alteryxWorkflowMngt"][0]["frequency"];
                  element["executionPeriod"] = element["alteryxWorkflowMngt"][0]["executionPeriod"] != null ? element["alteryxWorkflowMngt"][0]["executionPeriod"] : null;
                }
              }
              if (this.componentName == "omslist" || this.componentName == 'rapidOmslist') {
                if (this.subComponent == 'unallocated_order') {
                  element.initiatedDate = moment(new Date(element.initiatedDate)).format('YYYY-MM-DD HH:mm:ss');
                }
              }
              else element.initiatedDate = this.commonService.convertDate(element.initiatedDate);
              element.allocatedDate = this.commonService.convertDate(element.allocatedDate);
              element.sendEmailDate = this.commonService.convertDate(element.sendEmailDate);
              element.approvedDate = this.commonService.convertDate(element.approvedDate);
              if (element.updatedOn) {
                let resMnt = moment(new Date(element.updatedOn)).isValid()
                if (resMnt) {
                  element.updatedOn = moment(new Date(element.updatedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
                } else {
                  element.updatedOn = element.updatedOn;
                }
              }
              if (element?.createdDate) {
                let resMnt1 = moment(new Date(element.createdDate)).isValid()
                if (resMnt1) {
                  element.createdDate = moment(new Date(element.createdDate)).format('DD/MM/YYYY');
                } else {
                  element.createdDate = element.createdDate;
                }
              }
              if (element?.createdOn) {
                let resMnt1 = moment(new Date(element.createdOn)).isValid()
                if (resMnt1) {
                  element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
                } else {
                  element.createdOn = element.createdOn;
                }
              }
              if (element?.insertedOn) {
                let resMnt1 = moment(new Date(element.insertedOn)).isValid()
                if (resMnt1) {
                  element.insertedOn = moment(new Date(element.insertedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
                } else {
                  element.insertedOn = element.insertedOn;
                }
              }
              if (this.componentName == 'positionByTaxlot') {
                if (element.symbol) {
                  element['symbol'] = element['symbol'].ticker;
                }
                if (element.currency) {
                  element['currency'] = element['currency']?.id;
                }
              }
              if (this.componentName == "taxAdditionK1Details") {
                if (element["partnerBeginningPercentageOf_Profit"]) {
                  element["partnerBeginningPercentageOf_Profit"] = element['partnerBeginningPercentageOf_Profit'] + "%";
                }
                if (element["partnerBeginningPercentageOf_Loss"]) {
                  element["partnerBeginningPercentageOf_Loss"] = element['partnerBeginningPercentageOf_Loss'] + "%";
                }
                if (element['partnerBeginningPercentageOf_Capital']) {
                  element['partnerBeginningPercentageOf_Capital'] = element['partnerBeginningPercentageOf_Capital'] + "%";
                }
              }
              if (this.componentName == "TAXAutomation" || this.componentName == "calculateTaxAllocation") {
                if (element["taxYearBeginning"]) {
                  element["taxYearBeginning"] = moment(new Date(element['taxYearBeginning'])).format("MM/DD/YYYY");
                }
                if (element["taxYearEnding"]) {
                  element["taxYearEnding"] = moment(new Date(element['taxYearEnding'])).format("MM/DD/YYYY");
                }
                if (element["executionDateTime"]) {
                  element["executionDateTime"] = moment(new Date(element['executionDateTime'])).format("MM/DD/YYYY HH:mm");
                }
                if (element['employerIdentificationNumber'] && this.componentName == "TAXAutomation") {
                  element['actualValueemployerIdentificationNumber'] = element['employerIdentificationNumber'];
                  element['employerIdentificationNumber2'] = "*********";
                }
              }
              if (this.componentName == "calculateTaxAllocationStatus") {
                if (element["executionDateTime"]) {
                  element["executionTime"] = element["executionDateTime"].split(" ")[1].split(":").slice(0, -1).join(":");
                  element["executionDateTime"] = moment(new Date(element['executionDateTime'])).format("MM/DD/YYYY HH:mm");
                }
              }
              if (this.componentName == 'checkerPendingRequest' && element?.createdDate) {
                let resMnt1 = moment(new Date(element.createdDate)).isValid()
                if (resMnt1) {
                  element.createdDate = moment(new Date(element.createdDate)).format('YYYY-MM-DD');
                } else {
                  element.createdDate = element.createdDate;
                }
              }
              if (this.componentName == 'checkerPendingRequest' && element?.modifiedDate) {
                let resMnt1 = moment(new Date(element.modifiedDate)).isValid()
                if (resMnt1) {
                  element.modifiedDate = moment(new Date(element.modifiedDate)).format('YYYY-MM-DD');
                } else {
                  element.modifiedDate = element.modifiedDate;
                }
              }
              if (this.componentName == 'tradeUploadEntryHV' || (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadEntry')) {
                if (element['aggregateDate']) {
                  element["aggregateDate"] = moment(new Date(element['aggregateDate'])).format("MM/DD/YYYY");
                }
              }
              if (this.componentName == 'itdSubscriptionAndRedemption') {
                if (element['date']) {
                  element["date"] = moment(new Date(element['date'])).format("MM/DD/YYYY");
                }
              }
              if (this.componentName == 'rapidItdSubscriptionAndRedemption') {
                if (element['date']) {
                  element["date"] = moment(new Date(element['date'])).format("MM/DD/YYYY");
                }
              }
              if (this.componentName == 'bill' || this.componentName == 'unpaidBills' || this.componentName == 'paidBills'
                || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills"
                || this.componentName == 'paymentPayableData' || this.componentName == 'rapidPaymentPayableData' || this.componentName == 'cashExpenseList' || this.componentName == 'vendorCreditList' || this.componentName == "rapidInvoice" ||this.componentName == "paymentRecipt" ||this.componentName == "rapidOutstandingInvoice" || this.componentName == "cashIncomeList" || this.componentName == "rapidReceiptsData") {
                if (element["billDate"]) {
                  element["billDate"] = moment(new Date(element['billDate'])).format("MM-DD-YYYY");
                }
                if (element["dueDate"]) {
                  element["dueDate"] = moment(new Date(element['dueDate'])).format("MM-DD-YYYY");
                }
                if (element["invoiceDate"]) {
                  element["invoiceDate"] = moment(new Date(element['invoiceDate'])).format("MM-DD-YYYY");
                }
                if (element["postingDate"]) {
                  element["postingDate"] = moment(new Date(element['postingDate'])).format("MM-DD-YYYY");
                }
                if (element["paymentDate"]) {
                  element["paymentDate"] = moment(new Date(element['paymentDate'])).format("MM-DD-YYYY");
                }
                element["reversedEntry"] = element["reversedEntry"] === true ? 'Yes' : 'No';
                if (this.componentName == 'bill' ||this.componentName == 'vendorCreditList'|| this.componentName == 'cashExpenseList'|| this.componentName == "unpaidBills" || this.componentName == "paidBills" || this.componentName == 'rapidBill' || this.componentName == "rapidUnpaidBills" || this.componentName == "rapidPaidBills" || this.componentName == "rapidInvoice" || this.componentName == "cashIncomeList") {
                  if (element['entityDetails'] && element['entityDetails'].length) {
                    const gridEntityDetails = element['entityDetails'];
                    const gridTransactionDetails = gridEntityDetails[0]['transactionTypeDetails']
                    element["exchangeRate"] = gridTransactionDetails[0]['exchangeRate']
                  } else {
                    element["exchangeRate"] = 1
                  }
                }
              }
              if (this.componentName == "sourceConfiguration") {
                if (element.status) {
                  element['scr_status'] = element['status'];
                }
                if (element.industry) {
                  element['scr_industry'] = element.industry.fields[0].value
                }
                if (element.legalName) {
                  element['sr_legalName'] = element.legalName.fields[0].value
                }
                if (element.sftpTypeName) {
                  element.sftpTypeName = atob(element.sftpTypeName);
                }
                //cust_Tag
                if (element.custodianTag || !element.custodianTag) {
                  element['cust_Tag'] = (element.custodianTag == true ? "Yes" : "No");
                }
              }
              if (this.componentName == "dailyMargin" || this.componentName == 'rapidDailyMargin') {
                if (element.brokerName || element.brokerName?.nameOfCustodian != undefined) {
                  element.brokerName.nameOfCustodian = (element.brokerName?.nameOfCustodian);
                }
              }
              if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery" || this.componentName == "dailyMargin" || this.componentName == 'rapidDailyMargin') {
                if (element['date']) {
                  element['date'] = moment(new Date(element['date'])).format("MM/DD/YYYY");
                } else {
                  element["date"] = null;
                }
              }
              if (this.componentName == 'rapidJeTransferlog' || this.componentName == 'notesList') {
                if (element["createdOn"]) {
                  element["createdOn"] = moment(element['createdOn'], 'YYYY-MM-DD hh:mm:ss:ssss').format("MM-DD-YYYY");
                }
                if (element["startDate"]) {
                  element["startDate"] = moment(element['startDate']).format("MM-DD-YYYY");
                }
                if (element["endDate"]) {
                  element["endDate"] = moment(element['endDate']).format("MM-DD-YYYY");
                }
              }

              /*
              Componet: source config detail view fixed
              auth:vg            
              */
              if (this.componentName == "journalentry" || this.componentName == "RapidJournalentry") {
                if (element['investerACNumberDr1']) {
                  element['investerACNumberDr1'] = element.investerACNumberDr1.investorNumber;
                }
                if (element['custodianACNumberCr1']) {
                  element['custodianACNumberCr1'] = element.custodianACNumberCr1.accountNumber;
                }
                if (element['investerACNumberCr1']) {
                  element['investerACNumberCr1'] = element.investerACNumberCr1.investorNumber;
                }
                if (element['custodianACNumberDr1']) {
                  element['custodianACNumberDr1'] = element.custodianACNumberDr1.accountNumber;
                }
                if (element['bankACNumberDr1']) {
                  element['bankACNumberDr1'] = element.bankACNumberDr1.vendor;
                }
                if (element['bankACNumberCr1']) {
                  element['bankACNumberCr1'] = element.bankACNumberCr1.vendor;
                }
              }
              if (this.componentName != "workflowStatus") {
                if (element.isPreviewData || !element.isPreviewData) {
                  element['isPreviewData'] = (element.isPreviewData == true ? "Yes" : "No");
                }
                if (element.uploadData || !element.uploadData) {
                  element['uploadData'] = (element.uploadData == true ? "Yes" : "No");
                }
                if (element.executionLocked || !element.executionLocked) {
                  if (element.custodianTag) {
                    element['executionLocked'] = (element.executionLocked == true ? "Yes" : "No");
                  } else {
                    element['executionLocked'] = " ";
                  }
                }
              }

              if (element['legalName']) {
                if (element['legalName']['fields'][0] != null) {
                  if (element['legalName']['fields'][0]['value'] != null) {
                    element['sr_legalName'] = element['legalName']['fields'][0]['value'];
                  } else {
                    element['sr_legalName'] = " ";
                  }
                }
              }
              if (this.componentName == "viewDataCollection") {
                if (element['jsonData']) {
                  element['jsonData'] = JSON.stringify(element.jsonData);
                }
              }

              /*Auth:VG*/
              if (element['runDate']) {
                element["runDate"] = moment(new Date(element['runDate'])).format("MM/DD/YYYY");
                element["runDate"] = String(element["runDate"]);
              }

              /*Auth:VG*/
              if (this.componentName == "tradeAllocation") {
                if (element['commission']) {
                  element['ta_commission'] = element.commission;
                }
              }

              /*Auth:VG*/
              if (this.componentName == "ror-config-List" || this.componentName == 'customization-ror-config-List') {
                if (element['status']) {
                  element['ror_status'] = element.status;
                }
              }

              /*
              Componet: Run Data Import Color Code style 
              auth:vg            
           */
              this.rowClassRules = {
                'complete-gray': function (params: any) {
                  if (
                    params.data.alteryxWorkflowStatus
                    && params.data.alteryxWorkflowStatus.isDataDumpInTemp
                    && !params.data.alteryxWorkflowStatus.processStatus
                  ) {
                    return true;
                  }
                },
                'complete-green': function (params: any) {
                  if (
                    params.data.alteryxWorkflowStatus
                    && params.data.alteryxWorkflowStatus.disposition
                    && (!params.data.alteryxWorkflowStatus.isDataDumpInTemp
                      || params.data.alteryxWorkflowStatus.processStatus)
                    && (params.data.alteryxWorkflowStatus.disposition.toLowerCase() == "success"
                      || params.data.alteryxWorkflowStatus.disposition.toLowerCase() == "no data")
                  ) {
                    return true;
                  }
                },
                'complete-red': function (params: any) {
                  if (
                    params.data.alteryxWorkflowStatus
                    && params.data.alteryxWorkflowStatus.disposition
                    && (!params.data.alteryxWorkflowStatus.isDataDumpInTemp
                      || params.data.alteryxWorkflowStatus.processStatus)
                    && params.data.alteryxWorkflowStatus.disposition.toLowerCase() == "error"
                  ) {
                    return true;
                  }
                }
              };

              var disposition = [];
              if (element.disposition) {
                if (element.disposition == 'Error') {
                  disposition.push(element.disposition);
                  element["disposition"] = disposition.join(" | ");
                } else {
                  disposition.push(element.disposition);
                  element["disposition"] = disposition.join(" | ");
                }
              }

              /*
              Componet: Run Data Import
              auth:vg            
              */
              if (element["alteryxWorkflowMngt"]) {
                if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"]) {
                  let frmDate = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["lastRunDateTimeStr"];
                  if (frmDate != null) {
                    element["lastRunDateTimeStr"] = moment(new Date(frmDate)).format("MM/DD/YYYY HH:MM:SS");
                    element["lastRunDateTimeStr"] = String(element["lastRunDateTimeStr"]);
                  } else {
                    element["lastRunDateTimeStr"] = " ";
                  }
                  if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"] != null) {
                    element["file"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["file"];
                  } else {
                    element["file"] = " ";
                  }
                  if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                    element["fileNameFormat"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                  } else {
                    element["fileNameFormat"] = " ";
                  }
                  if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["localFilePath"] != null) {
                    element["localFilePath"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["localFilePath"];
                  } else {
                    element["localFilePath"] = " ";
                  }
                  if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"] != null) {
                    element["customField"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"];
                  } else {
                    element["customField"] = " ";
                  }
                  if (element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"] != null) {
                    element["custassetClass"] = element["alteryxWorkflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"];
                  } else {
                    element["custassetClass"] = " ";
                  }
                }
              }
              if (this.componentName == "customizationNonCustodianList" || this.componentName == "nonCustodianList") {
                if (element['workflowMngt']) {
                  if (element["workflowMngt"]["alteryxWorkflowMngtInfos"]) {
                    if (element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                      element["filenameFormat"] = element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                    } else {
                      element["filenameFormat"] = " ";
                    }
                    if (element['workflowMngt']['inputModeType']?.length > 0) {
                      if (element['workflowMngt']['inputModeType'][0].toLowerCase() == 'database') {
                        element['sourcefilePath'] = element['workflowMngt']['inputModeType'][0]
                      } else {
                        element['sourcefilePath'] = element['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                      }
                      if (element['workflowMngt']['inputModeType']?.length > 1) {
                        if (element['workflowMngt']['inputModeType'][0].toLowerCase() == 'sftp' || element['workflowMngt']['inputModeType'][1].toLowerCase() == 'sftp') {
                          element['sourcefilePath'] = element['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                        }
                      }
                    }
                    if (element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["workflowName"] != null) {
                      element["workflowname"] = element["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["workflowName"];
                    } else {
                      element["workflowname"] = " ";
                    }
                    if (element.workflowMngt.isSelected || !element.workflowMngt.isSelected) {
                      element['workflowMngt']['isSelected'] = (element.workflowMngt.isSelected == true ? "Yes" : "No");
                    }
                  }
                }
              }
              if (this.componentName == "customization-connectionList" || this.componentName == "connectionList") {
                if (element['custodianSourceConfig']['workflowMngt']) {
                  if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"]) {
                    if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"] != null) {
                      element["filenameFormat"] = element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["fileNameFormat"];
                    } else {
                      element["filenameFormat"] = " ";
                    }
                    if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["assetClass"] != null) {
                      element["assetClass"] = element['custodianSourceConfig']['workflowMngt']['alteryxWorkflowMngtInfos'][0]["assetClass"];
                    } else {
                      element["assetClass"] = " ";
                    }
                    if (element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"] != null) {
                      element["customField"] = element['custodianSourceConfig']["workflowMngt"]["alteryxWorkflowMngtInfos"][0]["customField"];
                    } else {
                      element["customField"] = " ";
                    }
                    if (element['custodianSourceConfig']['workflowMngt']['inputModeType']?.length > 0) {
                      if (element['custodianSourceConfig']['workflowMngt']['inputModeType'][0].toLowerCase() == 'database') {
                        element['sourceFilePath'] = element['custodianSourceConfig']['workflowMngt']['inputModeType'][0]
                      } else {
                        element['sourceFilePath'] = element['custodianSourceConfig']['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                      }
                      if (element['custodianSourceConfig']['workflowMngt']['inputModeType']?.length > 1) {
                        if (element['custodianSourceConfig']['workflowMngt']['inputModeType'][0].toLowerCase() == 'sftp' || element['custodianSourceConfig']['workflowMngt']['inputModeType'][1].toLowerCase() == 'sftp') {
                          element['sourceFilePath'] = element['custodianSourceConfig']['workflowMngt']['alteryxWorkflowMngtInfos'][0].sourceFilePath
                        }
                      }
                    }
                    if (element.custodianSourceConfig.workflowMngt.isSelected || !element.custodianSourceConfig.workflowMngt.isSelected) {
                      element['custodianSourceConfig']['workflowMngt']['isSelected'] = (element.custodianSourceConfig.workflowMngt.isSelected == true ? "Yes" : "No");
                    }
                    if (element.custodianSourceConfig.workflowMngt.isPreviewData || !element.custodianSourceConfig.workflowMngt.isPreviewData) {
                      element['custodianSourceConfig']['workflowMngt']['isPreviewData'] = (element.custodianSourceConfig.workflowMngt.isPreviewData == true ? "Yes" : "No");
                    }
                    if (element.custodianSourceConfig.workflowMngt.uploadData || !element.custodianSourceConfig.workflowMngt.uploadData) {
                      element['custodianSourceConfig']['workflowMngt']['uploadData'] = (element.custodianSourceConfig.workflowMngt.uploadData == true ? "Yes" : "No");
                    }
                  }
                }
              }
              if (element["alteryxWorkflowMngt"] != null) {
                element["frequency"] = element["alteryxWorkflowMngt"]["frequency"] != null ? element["alteryxWorkflowMngt"]["frequency"] : null;
                element["executionPeriod"] = element["alteryxWorkflowMngt"]["executionPeriod"] != null ? element["alteryxWorkflowMngt"]["executionPeriod"] : null;
              }
              if (this.componentName != 'accountSummary' && this.componentName != 'rapidAccountSummary') {
                if (element["custodianAccountNumber"] != null) {
                  element["CustaccountNumber"] = element["custodianAccountNumber"];
                } else {
                  element["CustaccountNumber"] = " ";
                }
                if (element["inputModeType"] != null) {
                  element["inputMode"] = element['inputModeType'].join(" | ").toUpperCase();
                } else {
                  element["inputMode"] = " ";
                }
                if (element["finalSequence"] != null) {
                  element["finalSequence"] = element['finalSequence'];
                } else {
                  element["finalSequence"] = " ";
                }
              }
              if (element.accountNumberInSourceFile) {
                element.accountNumberInSourceFileEnc = element.accountNumberInSourceFile
                element.accountNumberInSourceFile = "*****";
              }

              /*
              Componet: Alteryx workflowManagement
              auth:vg            
              */
              if (element['alteryxWorkflowMngtInfos']) {
                if (element['alteryxWorkflowMngtInfos'] && element['alteryxWorkflowMngtInfos'].length) {
                  var file = [];
                  var ProcessName = [];
                  var fileNameFormat = [];
                  var localFilePath = [];
                  var PAssetClass = [];
                  var AssetClass = [];
                  var comment = [];
                  var sourceFilePath = [];
                  var customField = [];
                  var fileNameSrv = [];
                  var fl, fldt;
                  element['alteryxWorkflowMngtInfos'].forEach(function (value: any) {
                    if (value.primaryAssetClass && value.primaryAssetClass.fields && value.primaryAssetClass.fields.length) {
                      PAssetClass.push(value.primaryAssetClass.fields[0].value);
                    }
                    if (value.alteryxProcess && value.alteryxProcess.length) {
                      ProcessName.push(value.alteryxProcess[0].processName);
                    }
                    if (value.isSelected) {
                      file.push(value.file);
                      fileNameFormat.push(value.fileNameFormat);
                    }
                    if (value.alteryxMetaInfo) {
                      fl = value.alteryxMetaInfo.name;
                    }
                    if (value.workflowUploadDateStr) {
                      fldt = value.workflowUploadDateStr;
                    }
                    file.push(value.file);
                    fileNameFormat.push(value.fileNameFormat);
                    AssetClass.push(value.assetClass);
                    comment.push(value.comment);
                    sourceFilePath.push(value.sourceFilePath);
                    customField.push(value.customField);
                    fileNameSrv.push(fl, fldt);
                  });
                  element["file"] = file.join(" | ");
                  element["ProcessName"] = ProcessName.join(" | ");
                  element["PAssetClass"] = PAssetClass.join(" | ");
                  element["AssetClass"] = AssetClass.join(" | ");
                  element["comment"] = comment.join(" | ");
                  element["sourceFilePath"] = sourceFilePath.join(" | ");
                  element["fileNameFormat"] = fileNameFormat.join(" | ");
                  element["customField"] = customField.join(" | ");
                  element["fileNameSrv"] = fileNameSrv.join(" | ");
                  element["primaryAssetClass"] = element["PAssetClass"]
                } else {
                  element["file"] = '';
                  element["fileNameFormat"] = '';
                }
                if (element['processDate']) {
                  element['processDate'] = moment(new Date(element['processDate'])).format("MM/DD/YYYY");
                } else {
                  element['processDate'] = null;
                }
                if (element['custodianAccountNumber'] && element['custodianAccountNumber'].length) {
                  element['accountNumber'] = element['custodianAccountNumber'].join(" | ");
                } else {
                  element['accountNumber'] = null;
                }
                if (element['inputModeType'] && element['inputModeType'].length) {
                  element['inputMode'] = element['inputModeType'].join(" | ").toUpperCase();
                } else {
                  element['inputMode'] = null;
                }
                if (element['alteryxWorkflowMngtInfos'].length) {
                  if (element['alteryxWorkflowMngtInfos'][0]['lastRunDateTimeStr'] != null) {
                    element["lastRunDateTimeStr"] = moment(new Date(element['alteryxWorkflowMngtInfos'][0]['lastRunDateTimeStr'])).format("MM/DD/YYYY HH:MM:SS");
                    element["lastRunDateTimeStr"] = String(element["lastRunDateTimeStr"])
                  }
                }
              }
              if (this.moduleName == "dataImportStatusManager" || this.moduleName == "dataImportStatus"
                || this.moduleName == "workflowStatus" || this.moduleName == "executeWorkflow") {
                if (element['createdDate']) {
                  let createdDate = element['createdDate'].toString() + "z";
                  element["createdDate"] = moment(new Date(createdDate)).format("MM/DD/YYYY hh:mm:ss A");
                }
                if (element['createdDateStr']) {
                  element["createdDate"] = moment(new Date(element['createdDateStr'])).format("MM/DD/YYYY hh:mm:ss A");
                }
                else {
                  element["createdDateStr"] = null;
                }
                if (element['endDate']) {
                  let endDate = element['endDate'].toString() + "z";
                  element["endDate"] = moment(new Date(endDate)).format("MM/DD/YYYY hh:mm:ss A");
                } else {
                  element["endDateStr"] = null;
                }
              }

              if (this.moduleName == "brokerAccount") {

                if (element["isWhiteList"]) {
                  element["isWhiteList"] = "Yes";
                }
                else if (element["isWhiteList"] == null) {
                  element["isWhiteList"] = "";
                }
                else {
                  element["isWhiteList"] = "No";
                }

                if (element["fundWorkflowSetup"]) {
                  element["fundWorkflowSetup"] = "Yes";
                }
                else if (element["fundWorkflowSetup"] == null) {
                  element["fundWorkflowSetup"] = "";
                }
                else {
                  element["fundWorkflowSetup"] = "No";
                }
              }
              // Change execution date and time format for data import status
              if (this.moduleName == "workflowStatus") {
                if (element['createdDate']) {
                  element["createdDate"] = moment(new Date(element['createdDate'])).format("MM/DD/YYYY HH:mm");
                } else {
                  element["createdDate"] = null;
                }
                if (element['autoDownloadFile']) {
                  var uniqueId;
                  let type: string = 'Zip file';
                  if (element['workflowTree']) {
                    var reqObj = {
                      uniqueId: element['workflowExecutionId'],
                      type: 'processWiseZip',
                    }
                    let url = environment.api_url + 'alteryxExecuteWorkflowV2/exportFile';
                    this.processScreenService.dataExport('alteryxExecuteWorkflow', reqObj, url);
                  } else if (element['uniqueNumber']) {
                    uniqueId = element['uniqueNumber'];
                    let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId + '/' + type;
                    this.commonService.GenericDownload(url, 'Failed Data', 'Failed Data');
                  } else {
                    this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('invalid_data'), detail: this.translateService.instant('error') });
                    return;
                  }
                }
              }

              /*
          Componet: Alteryx custodain
          auth: Vg          
          */
              var SourceInfo = [];
              var custodianAccount = [];
              let sourceName;
              if (this.componentName == "custodianList" || this.componentName == 'custodianListLegacy' || this.componentName == "connectionList" || this.componentName == "customization-connectionList" || this.componentName == "setupcustodianList" || this.componentName == "taxcustodianList") {
                if (element["custodianSourceConfig"] != null || undefined) {
                  if (element["custodianSourceConfig"]["sourceId"] != null) {
                    if (element["custodianSourceConfig"]["sourceName"] || !element["custodianSourceConfig"]["sourceName"]) {
                      sourceName = element["custodianSourceConfig"]["sourceName"];
                      if (sourceName || !sourceName) {
                        element["custodianSourceConfig"]["sourceName"] = sourceName;
                      }
                    }
                  } else {
                    if (element["custodianSourceConfig"]["sourceInfos"]) {
                      element["custodianSourceConfig"]["sourceInfos"].forEach((data) => {
                        SourceInfo.push(data.name);
                      })
                      element["custodianSourceConfig"]["sourceName"] = SourceInfo.join(" | ");
                    }
                  }
                  var custodianAccount = [];
                  if (element["custodianSourceConfig"]["custodianAccountNumber"] || !element["custodianSourceConfig"]["custodianAccountNumber"]) {
                    if (element["custodianSourceConfig"]["custodianAccountNumber"] != null || undefined) {
                      element["custodianAccountNumberList"] = element["custodianSourceConfig"]["custodianAccountNumber"];
                      if (element["custodianSourceConfig"]["custodianAccountNumber"].length > 1) {
                        element["custodianSourceConfig"]["custodianAccountNumber"].forEach((data) => {
                          custodianAccount.push(data)
                        })
                        element["custodianSourceConfig"]["custodianAccountNumber"] = custodianAccount.join(",");
                      } else {
                        element["custodianSourceConfig"]["custodianAccountNumber"] = element["custodianSourceConfig"]["custodianAccountNumber"]
                      }
                    }
                  }
                }
              }

              /*
              Componet: Alteryx Noncustodain-custodian
              auth: Ashok           
              */
              var prmAsst = [];
              if (element['alteryxWorkflowMngt']) {
                if (element['alteryxWorkflowMngt'].length != undefined) {
                  element["alteryxWorkflowMngt"].forEach((data) => {
                    element["file"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.file;
                    }).join(" | ");
                    element["comment"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.comment;
                    }).join(" | ");
                    element["processName"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return _.map(value["alteryxProcess"], function (x, key) {
                        return x.processName;
                      });
                    }).join(" | ");
                    element["assetClass"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.assetClass;
                    }).join(" | ");
                    element["workflowName"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.workflowName;
                    }).join(" | ");
                    element["customField"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.customField;
                    }).join(" | ");
                    element["sourceFilePath"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.sourceFilePath;
                    }).join(" | ");
                    element["fileNameFormat"] = _.map(data["alteryxWorkflowMngtInfos"], function (value, key) {
                      return value.fileNameFormat;
                    }).join(" | ");
                    element["primaryAssetClass"] = _.map(data["alteryxWorkflowMngtInfos"], function (x, key) {
                      if (x["primaryAssetClass"] != null) {
                        prmAsst.push(x["primaryAssetClass"]["fields"][0].value);
                        return x["primaryAssetClass"]["fields"][0].value;
                      }
                    }).join(" | ");

                  });
                  if (prmAsst.length) {
                    element["primaryAssetClass"] = prmAsst.join(" | ");
                  }
                } else {
                  if (element['alteryxWorkflowMngtInfos']) {
                    if (element['alteryxWorkflowMngtInfos'] && element['alteryxWorkflowMngtInfos'].length) {
                      var file = [];
                      var ProcessName = [];
                      var fileNameFormat = [];
                      var localFilePath = [];
                      var PAssetClass = [];
                      var AssetClass = [];
                      var comment = [];
                      var sourceFilePath = [];
                      var customField = [];
                      var fileNameSrv = [];
                      var fl, fldt;
                      element['alteryxWorkflowMngtInfos'].forEach(function (value: any) {
                        if (value.primaryAssetClass && value.primaryAssetClass.fields && value.primaryAssetClass.fields.length) {
                          PAssetClass.push(value.primaryAssetClass.fields[0].value);
                        }
                        if (value.alteryxProcess && value.alteryxProcess.length) {
                          ProcessName.push(value.alteryxProcess[0].processName);
                        }
                        if (value.isSelected) {
                          file.push(value.file);
                          fileNameFormat.push(value.fileNameFormat);
                        }
                        if (value.alteryxMetaInfo) {
                          fl = value.alteryxMetaInfo.name;
                        }
                        if (value.workflowUploadDateStr) {
                          fldt = value.workflowUploadDateStr;
                        }
                        file.push(value.file);
                        fileNameFormat.push(value.fileNameFormat);
                        AssetClass.push(value.assetClass);
                        comment.push(value.comment);
                        sourceFilePath.push(value.sourceFilePath);
                        customField.push(value.customField);
                        fileNameSrv.push(fl, fldt);
                      });
                      element["file"] = file.join(" | ");
                      element["ProcessName"] = ProcessName.join(" | ");
                      element["primaryAssetClass"] = PAssetClass.join(" | ");
                      element["assetClass"] = AssetClass.join(" | ");
                      element["comment"] = comment.join(" | ");
                      element["sourceFilePath"] = sourceFilePath.join(" | ");
                      element["fileNameFormat"] = fileNameFormat.join(" | ");
                      element["customField"] = customField.join(" | ");
                      element["fileNameSrv"] = fileNameSrv.join(" | ");
                    } else {
                      element["file"] = '';
                      element["fileNameFormat"] = '';
                    }
                    if (element['processDate']) {
                      element['processDate'] = moment(new Date(element['processDate'])).format("MM/DD/YYYY");
                    } else {
                      element['processDate'] = null;
                    }
                    if (element['custodianAccountNumber'] && element['custodianAccountNumber'].length) {
                      element['accountNumber'] = element['custodianAccountNumber'].join(" | ");
                    } else {
                      element['accountNumber'] = null;
                    }
                    if (element['inputModeType'] && element['inputModeType'].length) {
                      element['inputMode'] = element['inputModeType'].join(" | ").toUpperCase();
                    } else {
                      element['inputMode'] = null;
                    }
                    if (element['alteryxWorkflowMngtInfos'].length) {
                      if (element['alteryxWorkflowMngtInfos'][0]['lastRunDateTimeStr'] != null) {
                        element["lastRunDateTimeStr"] = moment(new Date(element['alteryxWorkflowMngtInfos'][0]['lastRunDateTimeStr'])).format("MM/DD/YYYY HH:MM:SS");
                        element["lastRunDateTimeStr"] = String(element["lastRunDateTimeStr"]);
                      }
                    }
                  }
                }
              }
              if (this.componentName == "sftpConfiguration") {
                if (element.awsConfig) {
                  element.awsConfig = "AWS"
                  element.sftpTypeName = btoa(element.configurationName)
                }
                else if (element.inputModes == "CM") {
                  element.awsConfig = element.inputModes
                  element.sftpTypeName = btoa(element.configurationName)
                }
                else if (!element.awsConfig) {
                  element.awsConfig = "SFTP"
                }
                if (element.password) {
                  element.passwordView = "*****";
                }
                if (element.sftpTypeName) {
                  element.sftpTypeName = atob(element.sftpTypeName);
                }
                if (element.username) {
                  element.username = atob(element.username);
                }
                if (element.host) {
                  element.host = atob(element.host);
                }
              }
            });

          }
          else
            this.rowData = [];

          if (cbs.content != null) {
            this.rowData = (cbs.content);
            this.totalRecords = cbs.totalElements;
            this.totalPages = cbs.totalPages;
            this.gridApi.setRowData(this.rowData);
            this.filteredData = this.rowData
          }
          if (cbs.resultList != null) {
            this.rowData = cbs.resultList;
            this.totalRecords = cbs.totalCount ? cbs.totalCount : (cbs.resultList ? cbs.resultList.length : 0);
            if (this.componentName == "dailyMargin" || this.componentName == 'rapidDailyMargin') {
              this.istotalFilterCount = true;
              this.totalFltRecords = this.totalRecords
            }
          }
          if (cbs.resultList == undefined && (this.componentName != 'batch' && this.componentName != 'rapidBatch' && this.componentName != 'batchLog' && this.componentName != "rapidBatchLog" && this.componentName != 'scheduleTaskInfo' && this.componentName != 'rapidScheduleTaskInfo')) {
            this.rowData = _.cloneDeep(cbs);
          }
          //*Auth : VG */
          if (this.gridApi != null || this.gridApi != undefined) {
            if ((this.rowData != null || this.rowData != undefined) && cbs.content == null) {
              if (this.componentName !== 'tradeAllocation' && this.componentName !== 'vendors'
                && this.componentName !== 'ror-config-List' && this.componentName !== 'customization-ror-config-List' && this.componentName !== 'nonCustodianList' && this.componentName != 'customizationNonCustodianList'
                && this.componentName !== 'workflowManagement')
                if (this.totalCount > cbs.totalCount) {
                  this.page = 0;
                  this.pageNumber = 1;
                }
              this.totalCount = cbs.totalCount;
              this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
              this.pageNumber > 1 ? this.pageNumber : this.pageNumber = 1;
              this.totalRecords = 0;
              if (!this.isFryFilterAct) {
                this.totalRecords = cbs.totalCount;
              }
              if (this.componentName == "amcIMProgram" || this.componentName == "setupamcIMProgram") {
                this.totalRecords = cbs.resultList.length;
                this.totalCount = cbs.resultList.length;
              }
              if (this.subComponent == "Real Time Positions") {
                this.totalRecords = cbs.length;
                this.totalCount = cbs.length;
              }
              if (this.componentName == "dailyProcessingStatus" || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) {
                if (cbs.resultList != null) {
                  cbs.resultList.forEach((element: any) => {
                    if (element["createdOn"]) {
                      element["createdOn"] = moment(new Date(element['createdOn'])).format("MM-DD-YYYY");
                    }
                  });
                  this.rowData = cbs.resultList;
                }
              }

              if (this.componentName == 'sequentialProcessStatusReview' || (this.componentName == 'transferTradeStatus' && this.subComponent == 'transferProcessStatusDayWise')) {
                cbs.resultList.forEach((element: any) => {
                  if (element["transferDate"]) {
                    element["transferDate"] = moment(new Date(element['transferDate'])).format("MM-DD-YYYY hh:mm");
                  }
                  if (element["transferProcessDate"]) {
                    element["transferProcessDate"] = moment(new Date(element['transferProcessDate'])).format("MM-DD-YYYY hh:mm");
                  }
                  if (element["taxLotsClosingProcessDate"]) {
                    element["taxLotsClosingProcessDate"] = moment(new Date(element['taxLotsClosingProcessDate'])).format("MM-DD-YYYY hh:mm");
                  }
                  if (element["openLotsCreationProcessDate"]) {
                    element["openLotsCreationProcessDate"] = moment(new Date(element['openLotsCreationProcessDate'])).format("MM-DD-YYYY hh:mm");
                  }
                });
              }
              if (this.componentName == "setupFundList" || this.componentName == "FundList" || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") {
                cbs.resultList.forEach((element: any) => {
                  if (element["fundRanking"]) {
                    element["fundRanking"] = element["fundRanking"] == 101 ? "" : element["fundRanking"];
                  }
                })
              }
              if (this.componentName == 'activityUploadReview') {
                this.activityPostTOGL.emit(cbs);
              }
              if (this.componentName == 'activityClient') {
                this.activityUploadCompliance.emit(cbs);
              }
              this.gridApi.setRowData(this.rowData);
              this.filteredData = this.rowData
            }
          }
        }, (cbe) => {
        })
      } else {
        this.page = 0;
        this.pageNumber = 1;
        this.filteredDataevt = null;
        this.filterData = false;
        this.filteredData = [];
        if (this.componentName == "dailyMargin" || this.componentName == 'rapidDailyMargin') {
          this.isFryFilterAct = false;
        }
        if (this.componentName == "workflowStatus") {
          clearInterval(this.myVar);
          this.myVar = null;
        }

        if (x.length == 0 && (this.componentName == 'calculateTaxAllocationStatus') && this.showHistoricalDataTax) {
          let url = 'taxReturnDetails/showHistoricalData/0'
          this.taxHistoricalData(url);
        } else if (this.componentName != 'chartofAccountTaxMappingDetails') {
          this.getGridData();
        }
        if (this.componentName == 'chartofAccountTaxMappingDetails' && x.length == 0) {
          this.getTaxMappingData();
        }

      }
    } else if (this.subComponent == 'realState') {
      let reqObj = evt
      let reqObj2 = this.trimObjValues(reqObj);
      this.gridService.getFilteredData(this.filterUrl, reqObj2, (cbs) => {
        if (cbs.resultList != null) {
          this.currentPage = this.currentPage + 1
          cbs.resultList.forEach(element => {
            element.initiatedDate = this.commonService.convertDate(element.initiatedDate);
            element.allocatedDate = this.commonService.convertDate(element.allocatedDate);
            element.sendEmailDate = this.commonService.convertDate(element.sendEmailDate);
            element.approvedDate = this.commonService.convertDate(element.approvedDate);
          });
        } else
          this.rowData = [];

        if (cbs.content != null) {
          this.rowData = (cbs.content);
          this.totalRecords = cbs.totalElements;
          this.totalPages = cbs.totalPages;
          this.gridApi.setRowData(this.rowData);
          this.filteredData = this.rowData
        }
        if (cbs.resultList != null) {
          this.rowData = cbs.resultList;
          this.totalRecords = cbs.totalCount ? cbs.totalCount : (cbs.resultList ? cbs.resultList.length : 0);
        }
        if (cbs.resultList == undefined) {
          this.rowData = _.cloneDeep(cbs);
        }
        this.gridApi.setRowData(this.rowData);
        this.filteredData = this.rowData
      }, (cbe) => {
      })
    }
    //  else if (this.componentName == "chartOfAccounttaxClassification") {
    //   let filteredData = evt.api.rowModel.rowsToDisplay;
    //   if (!filteredData || !filteredData.length) {
    //     this.gridApi.showNoRowsOverlay()
    //   } else {
    //     this.gridApi.hideOverlay();
    //   }
    // }
    else {
      let reqObj = {
        page: Number(this.pageNumber) - 1,
        userType: "rapid"
      }
      let reqObj2 = this.trimObjValues(reqObj);
      this.gridService.getFilteredData(this.filterUrl, reqObj2, (cbs) => {
        this.setupPercentageService.setIsJECreated(false)
        if (cbs.resultList != null) {
          this.currentPage = this.currentPage + 1
          cbs.resultList.forEach(element => {
            element.initiatedDate = this.commonService.convertDate(element.initiatedDate);
            element.allocatedDate = this.commonService.convertDate(element.allocatedDate);
            element.sendEmailDate = this.commonService.convertDate(element.sendEmailDate);
            element.approvedDate = this.commonService.convertDate(element.approvedDate);
            if (element.updatedOn) {
              let resMnt = moment(new Date(element.updatedOn)).isValid()
              if (resMnt) {
                element.updatedOn = moment(new Date(element.updatedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.updatedOn = element.updatedOn;
              }
            }
            if (element?.createdOn) {
              let resMnt1 = moment(new Date(element.createdOn)).isValid()
              if (resMnt1) {
                element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.createdOn = element.createdOn;
              }
            }
            if (element?.insertedOn) {
              let resMnt1 = moment(new Date(element.insertedOn)).isValid()
              if (resMnt1) {
                element.insertedOn = moment(new Date(element.insertedOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
              } else {
                element.insertedOn = element.insertedOn;
              }
            }
          });
        } else
          this.rowData = [];

        if (cbs.content != null) {
          this.rowData = (cbs.content);
          this.totalRecords = cbs.totalElements;
          this.totalPages = cbs.totalPages;
          this.gridApi.setRowData(this.rowData);
          this.filteredData = this.rowData
        }
        if (cbs.resultList != null) {
          this.rowData = cbs.resultList;
          this.totalRecords = cbs.totalCount ? cbs.totalCount : (cbs.resultList ? cbs.resultList.length : 0);
        }
        if (cbs.resultList == undefined && (this.componentName != 'batch' && this.componentName != 'rapidBatch' && this.componentName != 'batchLog' && this.componentName != "rapidBatchLog" && this.componentName != 'scheduleTaskInfo' && this.componentName != 'rapidScheduleTaskInfo')) {
          this.rowData = _.cloneDeep(cbs);
        }
        this.gridApi.setRowData(this.rowData);
        this.filteredData = this.rowData
      }, (cbe) => {
      })
    }
  }

  getDownloadCsvFilteredData(exportType) {
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "brokerAccount.accountNumber": {
          searchObject["TransientBrokerAccount"] = searchObject[x];
          delete searchObject[x];
          x = "TransientBrokerAccount"
          break;
        }
        case "assetClass.name": {
          searchObject["TransientAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "TransientAssetClass"
          break;
        }
        case "clientLookup.value": {
          if (this.componentName != 'exchanges' && this.componentName != 'assetClass') {
            searchObject["clientLookup"] = "lookupselect##ratingAgency";
            searchObject["ratingAgency"] = { 'type': "RatingAgencies", "fields.name": "Name", "fields.value": searchObject[x] };
            delete searchObject[x];
            x = "clientLookup.value"
          }
          else {
            searchObject["clientLookup"] = "lookupselect##primaryAssetClass";
            searchObject["primaryAssetClass"] = { 'type': "PrimaryAssetClasses", "fields.name": "Primary Asset Class", "fields.value": searchObject[x] };
            delete searchObject[x];
            x = "clientLookup.value"
          }
          break;
        }
        case "fund.fundName": {
          if (this.componentName == 'nonTradeActivity' || this.componentName == 'incomeDistribution' || this.componentName == 'viewCapitalCall' || this.componentName == 'rapidViewCapitalCall' || this.componentName == 'viewjesummary' || this.componentName == "jeSummary" || this.componentName == 'rapidjesummary' || this.componentName == 'customRatio' || this.componentName == "rapidCustomRatio" || this.componentName == "taxCustomRatio" || this.componentName == 'viewDistribution' || this.componentName == 'rapidViewDistribution' || this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == "setupcustodianList" || this.componentName == "taxcustodianList") {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          } else if (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory' || this.componentName == 'historicalInvestorCapitalSummary') {
            searchObject["fundName"] = searchObject[x];
            delete searchObject[x];
            x = "fundName"
            break;
          }
          else if (this.componentName == 'hurdleRate' || this.componentName == 'capitalActivities' || this.componentName == "rapidCapitalActivities" || this.componentName == 'crystalizedPerformanceFeeallocationList') {
            searchObject["fund.fundName"] = searchObject[x];
            // delete searchObject[x];
            x = "fund.fundName"
            break;
          }
          else {

            searchObject["TransientFund"] = searchObject[x];
            delete searchObject[x];
            x = "TransientFund"
            break;

          }
        }
        case "source.isoCode": {
          searchObject["source"] = searchObject[x];
          delete searchObject[x];
          x = "source"
          break;
        }
        case "transactionType": {
          if (this.componentName == 'tradingLevelChange' || this.componentName == 'rapidTradingLevelChange' || this.componentName == 'cashTransaction') {
            searchObject["transactionTypeValue"] = searchObject[x];
            delete searchObject[x];
            x = "transactionTypeValue"
            break;
          }
        }
        case "countryMaster.name": {
          if (this.componentName == 'regulatorsForSetting' || this.componentName == 'regulators') {
            searchObject["countryMaster"] = searchObject[x];
            delete searchObject[x];
            x = "countryMaster"
          }
          break;
        }
        case "PAssetClass": {
          if (this.componentName == 'workflowManagement') {
            searchObject["primaryAssetClass"] = searchObject[x];
            delete searchObject[x];
            x = "primaryAssetClass"
          }
          break;
        }
        case "uploadData": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isUploadData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isUploadData"
          }
          break;
        }
        case "isPreviewData": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isPreviewData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isPreviewData"
          }
          break;
        }
        case "executionLocked": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isExecutionLocked"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isExecutionLocked"
          }
          break;
        }
        case "historicalData": {
          if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
            searchObject["historicalData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "historicalData"
          }
          break;
        }
        case "ProcessName": {
          if (this.componentName == 'workflowManagement') {
            searchObject["processName"] = searchObject[x];
            delete searchObject[x];
            x = "processName"
          }
          break;
        }
        case "AssetClass": {
          if (this.componentName == 'workflowManagement') {
            searchObject["assetClass"] = searchObject[x];
            delete searchObject[x];
            x = "assetClass"
          }
          break;
        }
        case "inputMode": {
          if (this.componentName == 'workflowManagement') {
            searchObject["inputModeType"] = searchObject[x];
            delete searchObject[x];
            x = "inputModeType"
          }
          break;
        }
        case "exchanges.exchangeName": {
          searchObject["exchanges"] = searchObject[x];
          delete searchObject[x];
          x = "exchanges"
          break;
        }
        case "currency.isoCode": {
          searchObject["currency"] = searchObject[x];
          delete searchObject[x];
          x = "currency"
          break;
        }
        case "assetClasses.name": {
          searchObject["assetClasses"] = searchObject[x];
          delete searchObject[x];
          x = "assetClasses"
          break;
        }
        case "clientLookup.value": {
          searchObject["primaryAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "primaryAssetClass"
          break;
        }
        case "fundName": {
          //   if (this.componentName != 'Manager' && this.componentName != 'viewPnlDistribution') {
          //     searchObject["fund"] = searchObject[x];
          //     delete searchObject[x];
          //     x = "fund"
          //   }
          //   break;
          // }
          if (this.componentName != 'viewtotalgl' && this.componentName != 'viewPnlDistribution' && this.componentName != 'rapidViewPnlDistribution' && this.componentName != 'benchmarkirr' && this.componentName != 'rapidBenchmarkirr' && this.componentName != 'reportApproval' && this.componentName != 'customizationReportApproval' && this.componentName != 'runViewOpenTaxlots' && this.componentName != 'viewclosedtaxLots' && this.componentName != 'dailyMargin' && this.componentName != 'rapidDailyMargin'
            && this.componentName != 'FundList' && this.componentName != 'setupFundList' && this.componentName != "taxFundList" && this.componentName != 'TLRegime' && this.componentName != 'rapidTLRegime' && this.componentName != 'Manager' && this.componentName != 'externalROR' && this.componentName != 'rapidExternalROR' && this.componentName != 'investorgroup' && this.componentName != "rapidInvestorgroup" && this.componentName != 'inputDataIntegritySummery' && this.componentName != 'MISFundList' && this.componentName != "fundPropertyList" && this.componentName != 'fSInvestor' && this.componentName != 'fSManagementCompanyInvestor' && this.componentName != 'fSPortfolio' && this.componentName != 'fschartOfAccountsRapid' && this.componentName != 'fsCashEquivalents' && this.componentName != 'fsExposure') {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          }


          else {
            searchObject["fundName"] = searchObject[x];
            x = "fundName"
            break;
          }
        }
        case "enterpriseName": {
          if (this.componentName == 'MISFundList') {
            searchObject["transientEnterprise"] = searchObject[x];
            delete searchObject[x];
            x = "transientEnterprise"
            break;
          }
        }
        case "country.name": {
          if (this.componentName != 'exchanges') {
            searchObject["country"] = searchObject[x];
            delete searchObject[x];
            x = "country"
          }
          break;
        }
        case "client_Ip": {
          searchObject["Client_Ip"] = searchObject[x];
          delete searchObject[x];
          x = "country"
          break;
        }
        case "fundManager.firstName": {
          searchObject["fundManager"] = searchObject[x];
          delete searchObject[x];
          x = "fundManager"
          break;
        }
        case "fundGroup.fundGroup": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") {
            searchObject["fundGroupName"] = searchObject[x];
            delete searchObject[x];
            x = "fundGroupName"
          }
          break;
        }
        case "enterprise.enterpriseName": {
          searchObject["enterprise"] = searchObject[x];
          delete searchObject[x];
          x = "enterprise"
          break;
        }
        case "brokerName.accountNumber": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["accountNumber"] = searchObject[x];
            delete searchObject[x];
            x = "accountNumber"
            break;
          }
        }
        case "brokerName.nameOfCustodian": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["brokerName"] = searchObject[x];
            delete searchObject[x];
            x = "brokerName"
            break;
          }
        }
        case "brokerName.nameOfCustodian": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["brokerName"] = searchObject[x];
            delete searchObject[x];
            x = "brokerName"
            break;
          }
        }
        case "tradeAdvisor.nameOfAdvisor": {
          if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == "setupcustodianList") {
            searchObject["tradeAdvisor"] = searchObject[x];
            delete searchObject[x];
            x = "tradeAdvisor"
            break;
          }
        }
        case "accountNumber": {
          if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == "setupcustodianList") {
            searchObject["accountNumberValue"] = searchObject[x];
            delete searchObject[x];
            x = "accountNumberValue"
            break;
          }
        }
        case "dataCount": {
          if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus" || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            let srchObj = searchObject[x] + "LHRXJ10EWRnumberZRHXJ10JFK";
            searchObject["dataCount"] = srchObj;
            //delete searchObject[x];
            x = "dataCount"
            break;
          }
        }
        case "successCount": {
          if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus" || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            let srchObj = searchObject[x] + "LHRXJ10EWRnumberZRHXJ10JFK";
            searchObject["successCount"] = srchObj;
            //delete searchObject[x];
            x = "successCount"
            break;
          }
        }
        case "executionPeriod": {
          if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus" || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            searchObject["alteryxWorkflowMngt.executionPeriod"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.executionPeriod"
            break;
          }
        }
        case "frequency": {
          if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus" || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            searchObject["alteryxWorkflowMngt.frequency"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.frequency"
            break;
          }
        }
        case "countryOfTaxId1.name": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["countryOfTaxId1"] = searchObject[x];
            delete searchObject[x];
            x = "countryOfTaxId1"
          }
          break;
        }
        case "countryOfTaxId2.name": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["countryOfTaxId2"] = searchObject[x];
            delete searchObject[x];
            x = "countryOfTaxId2"
          }
          break;
        }
        case "countryOfTaxId3.name": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["countryOfTaxId3"] = searchObject[x];
            delete searchObject[x];
            x = "countryOfTaxId3"
          }
          break;
        }
        case "assetManagement.entityName": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["assetManagement"] = searchObject[x];
            delete searchObject[x];
            x = "assetManagement"
          }
          break;
        }
        case "taxDocLookup.value": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["taxDocLookup"] = searchObject[x];
            delete searchObject[x];
            x = "taxDocLookup"
          }
          break;
        }
        case "baseCurrency.isoCode": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["baseCurrency"] = searchObject[x];
            delete searchObject[x];
            x = "baseCurrency"
          }
          break;
        }
        case "accountingMethodLookup.value": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["accountingMethodLookup"] = searchObject[x];
            delete searchObject[x];
            x = "accountingMethodLookup"
          }
          break;
        }
        case "fundStrategyLookup.value": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["fundStrategyLookup"] = searchObject[x];
            delete searchObject[x];
            x = "fundStrategyLookup"
          }
          break;
        }
        case "baseCurrency.value": {
          if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == 'fundPropertyList' || this.componentName == "taxFundList") {
            searchObject["baseCurrency"] = searchObject[x];
            delete searchObject[x];
            x = "baseCurrency"
          }
          break;
        }
        default: {
          x = key
          break;
        }
      }
    });

    if (this.componentName != 'newIssues' && this.componentName != 'rapidNewIssues' && this.componentName != 'alertThreshold' && this.componentName != 'dataImportStatus' && this.componentName != "customizationDataImportStatus" && this.componentName != 'alertThresholdLogManager') {
      searchObject['page'] = this.page;
    }
    if ((this.componentName == "setupFundList" || this.componentName == "FundList" || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") && searchObject['auaCode']) {
      searchObject["auaCode"] = searchObject["auaCode"] == 'Yes' ? '1' : '0';
    }

    if (this.componentName == 'inputConfiguration' || this.componentName == "customizationInputConfiguration") {
      searchObject['count'] = this.page;
    }


    if (this.componentName == "calculateTaxAllocationStatus") {
      searchObject['showHistoricalDataTax'] = this.showHistoricalDataTax;
    }

    if (this.componentName == 'sourceConfiguration') {
      if (this.isAllocated) {
        searchObject["status"] = "pending";
      }
      searchObject['count'] = this.totalCount;
      delete searchObject['page'];
    }
    if (this.orderFilter) {
      searchObject['orderFilter'] = this.orderFilter
    }

    if (this.componentName == "investorsaddresses") {
      searchObject['investor'] = searchObject['investor.name']
      delete searchObject['investor.name'];
    }
    else if (this.componentName == "FundList" || this.componentName == 'setupFundList' || this.componentName == "fundPropertyList" || this.componentName == "taxFundList") {
      searchObject['entityLookup'] = searchObject['entityLookup.lookupValues.TypeOfEntities']
      delete searchObject['entityLookup.lookupValues.TypeOfEntities'];
      searchObject['formPfClassificationLookup'] = searchObject['formPfClassificationLookup.value']
      delete searchObject['formPfClassificationLookup.value'];
    }
    else if (this.componentName == "investors" || this.componentName == 'rapidInvestors') {
      searchObject['investorGroup'] = searchObject['investorGroup.toString()']
      delete searchObject['investorGroup.toString()'];
    } else if (this.componentName == "capitalActivities" || this.componentName == "rapidCapitalActivities") {
      searchObject['investor'] = searchObject['investor.name']
      delete searchObject['investor.name'];
      // searchObject['investorNumber'] = searchObject['investor.investorNumber']
      // delete searchObject['investor.investorNumber'];
      searchObject['transferor'] = searchObject['transferor.name']
      delete searchObject['transferor.name'];
      searchObject['transferee'] = searchObject['transferee.name']
      delete searchObject['transferee.name'];
      searchObject['shareClass'] = searchObject['shareClass.toString()']
      delete searchObject['shareClass.toString()'];
      searchObject['series'] = searchObject['series.toString()']
      delete searchObject['series.toString()'];
    }
    var reqObj = searchObject;
    if (this.componentName == 'casplit') {
      delete searchObject['page']
    }
    if (this.subComponent == 'Troniq' || this.subComponent == 'Seamless') {
      searchObject['userType'] = "rapid"
    }
    var type = 'Excel';
    let zone_name = moment.tz.guess();
    if (this.componentName != 'dataImportStatus' && this.componentName != "customizationDataImportStatus" && this.componentName != 'dataImportStatusManager'
      && this.componentName != 'workflowStatus' && this.componentName != 'executeWorkflow') {
      zone_name = zone_name.replace("/", "_");
    }
    let link;
    if (this.componentName == 'investors' || this.componentName == 'rapidInvestors') {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + this.isEnterprise + '/' + this.isSystemAdmin;
    } else if (this.componentName == 'cashBalanceBroker' || this.componentName == 'rapidCashBalanceBroker' || this.componentName == 'positionBroker' || this.componentName == 'rapidPositionBroker') {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + 'Csv' + "/" + encodeURI(this.readObject(JSON.stringify(reqObj)));
    } else if (this.componentName == 'investorsList' || this.componentName == "setupInvestorsList" || this.componentName == "taxInvestorsList") {
      link = (this.isEnterprise ? this.gridFilteredUrl : apiConstants[this.componentName]['SystemAdminGridFilteredUrl'].url) + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + this.isEnterprise + '/' + this.isSystemAdmin;
    } else if (this.componentName == 'workflowStatus') {
      if (this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP') > -1) {
        if (this.roleAs == "Manager" || this.roleAs == "enterprise") {
          link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name + '/' + 'enterprise'
        } else {
          link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name + '/' + 'user'
        }
      } else {
        link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name + '/' + 'user'
      }
    } else if (this.componentName == 'dataImportStatusManager') {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name
    } else if (this.componentName == 'dataImportStatus' || this.componentName == "customizationDataImportStatus") {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name + '/' + 'enterprise'
    } else if (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory') {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj)))
    } else if (this.componentName == 'casplit' || this.componentName == 'camerger' || this.componentName == 'caspecialdividend' || this.componentName == 'caspinoff') {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + btoa(JSON.stringify(reqObj));
    } else if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + btoa(JSON.stringify(reqObj)) + "?historicalData=" + this.isAllocated;
    } else if ((this.componentName == 'omslist' || this.componentName == 'rapidOmslist') && this.subComponent == "Real Time Positions") {
      link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url;
    } else if (this.componentName == 'usermanagementlist' || this.componentName == 'usermappinglist' || this.componentName == 'automate-process') {
      link = apiConstants[this.componentName][this.subComponent]['gridFilteredUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + encodeURI(this.readObject(JSON.stringify(reqObj)));
    }
    if ((this.componentName == 'omslist' || this.componentName == 'rapidOmslist') && this.subComponent != "Real Time Positions") {
      link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + moment(this.customeDateRange[0]).format('YYYY-MM-DD') + '/' + moment(this.customeDateRange[1]).format('YYYY-MM-DD');
    } else {
      if (this.componentName == 'dailyProcessingStatus' || (this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus")) {
        link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(JSON.stringify(reqObj)) + '/' + encodeURI(JSON.stringify(this.isSystemAdmin))
      }
      if (this.componentName == 'FundList' || this.componentName == 'setupFundList' || this.componentName == "taxFundList" || this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == "setupcustodianList" || this.componentName == "connectionList" || this.componentName == "customization-connectionList") {
        this.isSystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
        link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + this.isSystemAdmin + "/" + encodeURI(JSON.stringify(reqObj))
      }
      if (this.componentName == 'regulators' || this.componentName == 'casplit' || this.componentName == 'camerger' || this.componentName == 'caspinoff' || this.componentName == 'excludeComposite' || this.componentName == 'assetBasedFeeCalculation' || (this.componentName == 'rapidAssestBasedFee' && this.subComponent == 'assetBasedFeeCalculation') || this.componentName == 'assetBasedFeeList' || (this.componentName == 'rapidAssestBasedFee' && this.subComponent == 'assetBasedFeeList') || this.componentName == 'regulatorsForSetting' || this.componentName == 'exchanges' || this.componentName == 'identifiers' || this.componentName == 'countries' || this.componentName == 'calendar' || this.componentName == 'assetClass' || this.componentName == 'closingMethods' || this.componentName == 'riskMaster' || this.componentName == "notesList" || this.componentName == "copnotesList") {
        link.href = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + btoa(JSON.stringify(reqObj))

      }
      if (this.componentName == 'tradeArchiveList' || this.componentName == 'rapidTradeArchiveList') {
        link = apiConstants[this.componentName]['gridFilteredUrl'].url + encodeURI(this.readObject(JSON.stringify(reqObj)))
      }
      if (this.componentName == 'viewPerfBasedFees' || this.componentName == 'rapidViewPerfBasedFees') {
        link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + btoa(JSON.stringify(reqObj));
      } else if (this.componentName == 'positionByTaxlot') {
        link = apiConstants[this.componentName]['gridFilteredUrl'].url + this.totalCount + '/' + exportType + '/' + encodeURI(this.readObject(JSON.stringify(reqObj)))
      } else if (this.componentName == 'reportNotificationStatusList' || this.componentName == 'customizationReportNotificationStatusList') {
        link = apiConstants[this.componentName]['gridFilteredUrl'].url + ((this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? "downloadFilterRecords/" + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) : "downloadFilterEnterpriseRecords/" + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))))
      } else if (this.componentName == "inputDataIntegrityCheck" || this.componentName == "inputDataIntegritySummery") {
        link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + "?historicalData=" + this.isAllocated;
      } else {
        if (this.componentName != 'viewPerfBasedFees' && this.componentName != 'rapidViewPerfBasedFees' && this.componentName != 'usermanagementlist' && this.componentName != 'usermappinglist' && this.componentName != 'excludeComposite' && this.componentName != 'assetBasedFeeList' && !(this.componentName == 'rapidAssestBasedFee' && this.subComponent == 'assetBasedFeeList') && this.componentName != 'assetBasedFeeCalculation' && !(this.componentName == 'rapidAssestBasedFee' && this.subComponent == 'assetBasedFeeCalculation') && this.componentName != 'regulators' && this.componentName != 'exchanges' && this.componentName != 'calendar' && this.componentName != 'assetClass' && this.componentName != 'closingMethods' && this.componentName != 'casplit' && this.componentName != 'caspinoff' && this.componentName != 'camerger' && this.subComponent != "Real Time Positions" && this.componentName != 'FundList' && this.componentName != 'setupFundList' && this.componentName != "taxFundList" && this.componentName != 'investors' && this.componentName != 'rapidInvestors' && this.componentName != 'caspecialdividend' && this.componentName != 'dataImportStatus' && this.componentName != "customizationDataImportStatus" && this.componentName != 'investorsList' && this.componentName != "setupInvestorsList" && this.componentName != "taxInvestorsList" && this.componentName != 'dailyProcessingStatus' && !(this.componentName == 'automate-process' && this.subComponent == "dailyProcessingStatus") && this.componentName != 'workflowStatus' && this.componentName != 'cashBalanceBroker' && this.componentName != 'rapidCashBalanceBroker' && this.componentName != 'positionBroker' && this.componentName != 'rapidPositionBroker' && this.componentName != 'uploadallocationhistory' && this.componentName != 'rapidUploadallocationhistory') {
          link = this.isZoneName ? this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj))) + '/' + zone_name
            : (this.componentName != 'newIssues' && this.componentName != "rapidNewIssues") ? this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj)))
              : this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + btoa(JSON.stringify(reqObj));
        }
      }
    }
    if (
      this.componentName == 'tradeList'
      || this.componentName == 'tradeArchiveList'
      || this.componentName == 'rapidTradeArchiveList'
      || this.componentName == 'viewclosedtaxLots'
      || this.componentName == 'viewtotalgl'
      || this.componentName == 'marketdata'
      || this.componentName == "rapidMarketdata"
      || this.componentName == 'viewjesummary'
      || this.componentName == "jeSummary"
      || this.componentName == 'rapidjesummary'
      || this.componentName == 'nonTradeActivity'
      || this.componentName == 'capitalActivities'
      || this.componentName == "rapidCapitalActivities"
      || this.componentName == 'tradehv'
      || (this.componentName == 'rapidhighVolume' && this.subComponent == 'tradeUploadHV')
      || this.componentName == 'exchangerate'
      || this.componentName == 'rapidExchangerate'
      || this.componentName == 'rapidItdSubscriptionAndRedemption'
      || this.componentName == 'itdSubscriptionAndRedemption'
      || this.componentName == 'MISFundList'
      || this.componentName == 'feeTemplateLinking'
    ) {
      link = this.gridFilteredUrl + encodeURI(this.readObject(JSON.stringify(reqObj)));
    }

    if ((this.componentName == 'investors' || this.componentName == 'rapidInvestors') || ((this.componentName == 'investorsList' && this.isEnterprise) || (this.componentName == "setupInvestorsList" && this.isEnterprise) || (this.componentName == "taxInvestorsList" && this.isEnterprise))) {
      let filterObj = reqObj;
      filterObj['isEnterprise'] = this.isEnterprise;
      link = this.gridFilteredUrl + encodeURI(this.readObject(JSON.stringify(filterObj)));
    }
    if (this.componentName == "chartofAccountTaxMappingDetails" || this.componentName == "K1Merging") {
      this.configIdMapping = this.setuppercentage.getTaxGridData();
      reqObj['configId'] = this.configIdMapping;
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + "/" + encodeURI(this.readObject(JSON.stringify(reqObj)))

    }
    if (this.componentName == 'taxAdjusted') {
      //   let filterObj = reqObj;
      let fundata = this.setuppercentage.getTaxAdjustment();
      reqObj['fundId'] = fundata.fundId;
      reqObj['year'] = fundata.year;
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + encodeURI(this.readObject(JSON.stringify(reqObj)));
    }

    if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      reqObj['moduleName'] = this.lookups;
      let filterObj = {
        searchObj: reqObj,
        count: this.totalCount
      };
      link = this.gridFilteredUrl + encodeURI(this.readObject(JSON.stringify(filterObj)));
    }
    if (this.componentName == 'fSInvestor' || this.componentName == 'fSManagementCompanyInvestor') {
      let fsInvestorData = this.gridService.getFSInvestorData();
      const { fundGroupName, fundId } = fsInvestorData;
      link = `${this.gridFilteredUrl}${fundId}/${fundGroupName}/${encodeURI(JSON.stringify(this.totalCount))}/${encodeURI(this.readObject(JSON.stringify(reqObj)))}`;
    }
    if (this.componentName == 'fschartOfAccountsRapid' || this.componentName == 'fsCashEquivalents' || this.componentName == 'fsExposure') {
      let fschartOfAccountsData = this.gridService.getFsChartOfAccounts();
      link = this.gridFilteredUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + fschartOfAccountsData['configId'] + "/" + encodeURI(this.readObject(JSON.stringify(reqObj)))
    }
    if (this.componentName == 'fsCashFlowUpdate') {
      let fsCashFlowData = this.gridService.getConfigData();
      const { startDate, endDate, configId, fundId } = fsCashFlowData;
      link = `${this.gridFilteredUrl}${encodeURI(JSON.stringify(this.totalCount))}/${configId}/${encodeURI(this.readObject(JSON.stringify(reqObj)))}`;
    }
    if (this.componentName == 'fSPortfolio') {
      const portfolioData = this.gridService.getFSPortFolioData();
      const {fundGroupName, endDate, configId, fundId } = portfolioData;
      link = `${this.gridFilteredUrl}${endDate}/${encodeURI(JSON.stringify(this.totalCount))}/${configId}/${encodeURI(this.readObject(JSON.stringify(reqObj)))}`;
    }
    if (window.location.hostname == "localhost") {
      link = link.replace("4200", "8080");
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url)
  }

  downloadCsvFilteredData() {
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    if (this.componentName == 'viewDataCollection') {
      searchObject['filterList'] = [];
    }
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "brokerAccount.accountNumber": {
          searchObject["TransientBrokerAccount"] = searchObject[x];
          delete searchObject[x];
          x = "TransientBrokerAccount"
          break;
        }
        case "assetClass.name": {
          searchObject["TransientAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "TransientAssetClass"
          break;
        }
        case "clientLookup.value": {
          searchObject["clientLookup"] = "lookupselect##ratingAgency";
          searchObject["ratingAgency"] = { 'type': "RatingAgencies", "fields.name": "Name", "fields.value": searchObject[x] };
          delete searchObject[x];
          x = "clientLookup.value"
          break;
        }
        case "workflowName": {
          if (this.componentName == 'workflowMapped') {
            searchObject["alteryxWorkflowMngt.sourceName"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.sourceName"
          }
          break;
        }
        case "sourceName": {
          if (this.componentName == 'workflowMapped') {
            searchObject["alteryxWorkflowMngt.source"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.source"
          }
          break;
        }
        case "processISODate": {
          let date = this.gridService.getStartEndDate(searchObject.processISODate);
          searchObject['filterList'].push(date);
          delete searchObject[x];
          x = "processISODate"
          break;
        }
        case "exchangeName": {
          if (this.componentName == 'viewDataCollection') {
            searchObject['filterList'].push({ "exchangeName": searchObject.exchangeName });
            delete searchObject[x];
          }
          break;
        }
        case "clientRouteName": {
          if (this.componentName == 'viewDataCollection') {
            searchObject['filterList'].push({ "clientRouteName": searchObject.clientRouteName });
            delete searchObject[x];
          }
          break;
        }
        case "clientName": {
          if (this.componentName == 'viewDataCollection') {
            searchObject['filterList'].push({ "clientName": searchObject.clientName });
            delete searchObject[x];
          }
          break;
        }
        case "fund.fundName": {
          if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          } else {
            searchObject["TransientFund"] = searchObject[x];
            delete searchObject[x];
            x = "TransientFund"
            break;
          }
        }
        default: {
          x = key
          break;
        }
      }
    });

    if (this.componentName != 'accountSummary' && this.componentName != 'rapidAccountSummary' && this.componentName != 'newIssues' && this.componentName != "rapidNewIssues" && this.componentName != 'alertThreshold' && this.componentName != 'sourceConfiguration' && this.componentName != 'inputConfiguration' && this.componentName != "customizationInputConfiguration") {
      searchObject['page'] = this.page;
    }
    if (this.componentName == 'alertThresholdLogManager' && searchObject['ruleRunDateTimeFormatted'] != undefined) {
      searchObject['ruleRunDateTime'] = searchObject['ruleRunDateTimeFormatted'];
      delete searchObject['ruleRunDateTimeFormatted'];
    }
    if (this.componentName == 'nonCustodianList' || this.componentName == 'customizationNonCustodianList') {
      searchObject['alteryxworkFlowName'] = searchObject['workflowMngt.sourceName']
      delete searchObject['workflowMngt.sourceName'];
      if (searchObject['workflowMngt.isSelected'] != null && searchObject['workflowMngt.isSelected'] != undefined) {
        searchObject['checked'] = searchObject['workflowMngt.isSelected'].toLowerCase() == 'yes' ? true : searchObject['workflowMngt.isSelected'].toLowerCase() == 'no' ? false : searchObject['workflowMngt.isSelected'];
        delete searchObject['workflowMngt.isSelected'];
      }
      searchObject['sourceFilePath'] = searchObject['sourcefilePath']
      delete searchObject['sourcefilePath'];
      searchObject['fileNameFormat'] = searchObject['filenameFormat']
      delete searchObject['filenameFormat'];
      searchObject['sourceName'] = searchObject['workflowMngt.source']
      delete searchObject['workflowMngt.source'];
    }
    if (this.componentName == 'nonCustodianList' || this.componentName == 'customizationNonCustodianList') {
      this.gridFilteredUrl = apiConstants[this.componentName]['gridFilteredUrl'].url
    }
    if (this.componentName == 'inputConfiguration' || this.componentName == 'accountSummary' || this.componentName == 'rapidAccountSummary' || this.componentName == "customizationInputConfiguration" || this.componentName == 'domainConfiguration') {
      searchObject['count'] = this.totalCount;
    }
    if (this.orderFilter) {
      searchObject['orderFilter'] = this.orderFilter
    }
    if (this.componentName == "sourceConfiguration") {
      this.oppValue = !this.isAllocated;
      searchObject['count'] = this.totalCount;
      if (searchObject["sftpTypeName"]) {
        searchObject["sftpTypeName"] = btoa(searchObject["sftpTypeName"]);
      }
      if (this.oppValue == false) {
        searchObject["status"] = "pending";
      }
    }
    if (this.componentName == 'workflowMapped') {
      delete searchObject['page'];
    }
    if (this.componentName == "sftpConfiguration") {
      searchObject['count'] = this.totalCount;
      if (searchObject['username']) {
        searchObject["username"] = btoa(searchObject["username"]);
      }
      if (searchObject['awsConfig']) {
        searchObject["inputModes"] = searchObject["awsConfig"];
        delete searchObject["awsConfig"];
      }
      if (searchObject['sftpTypeName']) {
        searchObject["sftpTypeName"] = btoa(searchObject["sftpTypeName"]);
      }
      if (searchObject['host']) {
        searchObject["host"] = btoa(searchObject["host"]);
      }
    }
    if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      searchObject['moduleName'] = this.lookups
      searchObject = { searchObj: searchObject };
      searchObject['count'] = this.totalCount;
    }
    var res = searchObject;
    var request = new XMLHttpRequest();
    if (this.componentName == 'viewDataCollection') {
      this.gridFilteredUrl = apiConstants[this.componentName][this.subComponent]['gridFilteredUrl'].url + this.totalRecords + '/' + this.subComponent;
      searchObject['collectionName'] = this.subComponent;
      searchObject['enterpriseId'] = this.currentUser['enterpriseId'];
      this.moduleName = this.subComponent;
      this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('download_request_taken_soon') });
    } else {
      this.gridFilteredUrl = this.gridFilteredUrl;
    }
    let access_token = this.cookieService.get('access_token')
    request.open('POST', api_url + this.gridFilteredUrl, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          var title = this.moduleName + 'Error'
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant(title + 'went_wrong_fund') });
        } else {
          var fileName = (this.componentName == 'viewDataCollection') ? this.moduleName + "Filtered.zip" : this.moduleName + "Filtered.csv";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          var contentTypeHeader = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        var title = this.moduleName + 'Error'
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant(title + 'went_wrong_fund') });
      }
    })
    this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('confirmed'), detail: this.translateService.instant('download_request_taken_soon') });
    request.send(JSON.stringify(res));
  }

  /*
   Auth : Vg
   Params:null
   Close Grid SideBar for onmouseleave event
    */
  mouseOutOfGrid(evt) {
    let sideBarFlg;
    if (evt) {
      if (this.gridApi) {
        if (this.gridApi.isSideBarVisible()) {
          sideBarFlg = this.gridApi.isSideBarVisible()
        }
      }
      if (sideBarFlg) {
        this.gridApi.closeToolPanel();
      }
    }
  }

  downloadsourceConfigurationCsvFilteredData() {
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    if (this.componentName == 'sourceConfiguration') {
      if (this.isAllocated) {
        searchObject["status"] = "pending";
      }
      searchObject['count'] = this.totalCount;
      delete searchObject['page'];
    }
    if (this.orderFilter) {
      searchObject['orderFilter'] = this.orderFilter
    }
    var reqObj = searchObject;
    let zone_name = moment.tz.guess();
    zone_name = zone_name.replace("/", "-");
    let link;
    if (this.componentName == 'sourceConfiguration') {
      link.download = this.gridFilteredUrl;
    }
    if (window.location.hostname == "localhost") {
      link = link.download.replace("4400", "8080");
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url)
  }

  downloadsourceConfigurationPostExport() {
    let cnt = 0;
    let data = {
      "status": "pending",
      "count": "7"
    }
    let date3 = moment(new Date()).format('YYYYMD');
    this.gridService.getSourceConfigDownloadFilterData(data).subscribe((cbs) => {
      const a = document.createElement('a');
      const blob = new Blob([cbs], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.componentName + "" + this.totalRecords + " " + date3 + '-' + cnt + '.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, (cbe) => {
    });
    cnt = cnt + 1;
  }

  //OMS modules changes start
  onallocateOms(val) {
    this.newItemEvent.emit(val);
  }
  onSelectallocate() {
    this.newonSelect.emit();
  }
  orderSend(val) {
    this.newOrder.emit(this.selectedRows);
  }
  todayOrdList() {
    this.todayOrder.emit();
  }
  ordrallocate() {
    this.allocateOrder.emit(this.selectedRows);
  }
  UndoOrder() {
    this.undoCofirmOrder.emit(this.selectedRows);
  }
  approveOrder() {
    this.orderApprove.emit(this.selectedRows);
  }

  //OMS modules changes ends
  readObject(searchObj) {
    return searchObj.replaceAll("/", "ZZZZZZZZZZ");
  }

  // Run/View open Taxlot
  getBrokerAccountrList() {
    this.gridService.getBrokerAccountrList("openTaxLots/fetchAllDistinctCustodiansByEnterprise", (cbs) => {
      cbs.forEach((element: any) => {
        this.brokerList.push(element);
      });
    }, (cbe) => {
    })
  }

  deleteByCriteriaModal() {
    this.selectedBrokerDelete = null;
    this.display = true;
    this.getCustodianList();
  }

  exportDailyTradeModal() {
    this.display = true;
  }

  UndoOrderModel() {
    this.display1 = false;
    this.display2 = true;
  }

  orderAllocationFn() {
    this.orderAllocation.emit(this.selectedRows);
  }

  setupAutoUpload() {
    this.display = true;
  }
  postJE() {
    let DataToPostJE = [];
    this.selectedRows.forEach((element: any) => {
      DataToPostJE.push(element)
    });
    const allowedNullFields = ["fromTaxClassification", "toTaxClassification", "investorId", "investorName", "investorNameAndNumber", "investorNumber", "ratioId", "createdOn", "investorNameAndNumber", "ratioIdAndDescription", "ratioIdDescription"];

    for (const row of DataToPostJE) {
      for (const [key, value] of Object.entries(row)) {
        if (!allowedNullFields.includes(key) && (value === null || value === "")) {
          console.log("Invalid data: some values are empty or null");
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: this.translateService.instant('error'),
            detail: "Invalid data: some values are empty or null"
          });
          return;
        }
      }
    }
    const isAnyJePosted = DataToPostJE.some(entry => entry.jePosted);

    if (isAnyJePosted) {
      this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('failed'), detail: "One or more entries are already posted.Cannot proceed with the API call." });


    } else {
      this.gridService.updateData('taxationAdjustmentJE/postToJE', DataToPostJE, 'post').subscribe((response: any) => {
        if (response.message) {
          this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Success', detail: response.message });
        }
        this.setupPercentageService.setIsJECreated(false);
        this.getGridData();
      }, (error) => {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('failed'), detail: error });

      });
    }

  }
  revisedIncomeStatement() {
    const allowedNullFields = ["fromTaxClassification", "toTaxClassification", "investorId", "investorName", "investorNameAndNumber", "investorNumber", "ratioId", "createdOn", "investorNameAndNumber", "ratioIdAndDescription"];
    const mandatoryFields = ["jedate", "amount", "fromCOA", 'toCOA']; // Replace with your actual mandatory fields

    const validRows = this.rowData.filter(row => {
      for (const field of mandatoryFields) {
        if (!row.hasOwnProperty(field) || row[field] === null || row[field] === "") {
          return false;
        }
      }
      return true;
    });

    if (validRows.length === 0) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: this.translateService.instant('error'),
        detail: "Invalid data: some mandatory fields are empty or null"
      });
      return;
    }


    let obj = this.setuppercentage.getTaxAdjustment();
    let data = {
      fundId: obj.fundId,
      year: obj.year,
    }

    this.gridService.updateData('taxationAdjustmentJE/calculateRevisedIncomeStatement', data, 'post').subscribe((response: any) => {
      this.setuppercentage.setRevisedIncomeStatement(response);
      //  this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('failed'), detail: this.translateService.instant(response.message) });

    }, (error) => {

    });
    // Example API call:
    // callApi(rowData);


  }
  editBulkData() {

    this.openMemoModel.emit(this.selectedRows);

  }



  getCustodianList() {
    this.gridService.getCustodianList("tradeEntry/fetchAllDistinctCustodiansByEnterprise", (cbs) => {
      cbs.forEach((element: any) => {
        this.custodianList.push({ name: element });
      });
    }, (cbe) => {
    })
  }

  getTickerList(selectedCustodian) {
    this.gridService.getTickerList('tradeEntry/getDitinctTicker?accountNo=' + selectedCustodian, (cbs) => {
      cbs.forEach((element: any) => {
        this.tickerList.push({ name: element });
      });
    }, (cbe) => {
    })
  }

  onSelectDateRunLot() {
    if (this.customeDateRange[1]) { // If second date is selected
      this.startCalendar.overlayVisible = false;
      this.method = 'post';
      this.reqObj = {
        custodian: this.selectedBroker,
        openTaxLotDateRange: new Date(this.customeDateRange[0]) + 'LHRXJ10EWRdateZRHXJ10JFK' + new Date(this.customeDateRange[1])
      }
      this.gridDataUrl = "openTaxLots/refreshCurrentOpenTaxlotByGivenDateRange";
      if (this.componentName == "workflowStatus") {
        clearInterval(this.myVar);
        this.myVar = null;
      }
      this.getGridData();
    }
  }

  deleteByCriteria() {
    var reqObj = {
      "custodianBroker": this.selectedBrokerDelete['name'],
      "ticker": this.selectedTicker ? this.selectedTicker['name'] : null
    };
    let currentDate = moment(new Date()).format('YYYY-MM-DD h:mm:ss');
    this.gridService.deleteByCriteria("tradeEntryV2/deleteByCriteria/" + currentDate, reqObj, (cbs) => {
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Success', detail: 'Trade Entry delete by criteria successfully.' });
    }, (cbe) => {
      this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: 'Error', detail: 'Data cannot be deleted' });
    })
    this.selectedBrokerDelete = null;
    this.custodianList = [];
    this.selectedTicker = "";
    //   this.rowData = [];
    //       this.totalRecords = 0;
    // if (this.gridApi != null || this.gridApi != undefined) {
    // if (this.rowData != null || this.rowData != undefined) {
    //   this.gridApi.setRowData(this.rowData);
    //   }
    //     }
  }

  downloadByDateRange() {
    let startDate = moment(this.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.endDate).format('YYYY-MM-DD');
    this.data = {
      startDate: startDate,
      endDate: endDate,
    }
    let link;
    link = 'tradeEntryV2/getAllocatedTradesByDateRange/' + encodeURI(JSON.stringify(this.data));
    if (window.location.hostname == "localhost") {
      link = link.replace("4400", "8080");
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url)
  }

  onFilterTextBoxChanged(event) {
    if (event) {
      this.isFryFilterAct = true;
      this.istotalFilterCount = true;
      localStorage.removeItem('workflowTreeValue');
    }
    else {
      this.isFryFilterAct = false;
      this.istotalFilterCount = false;
      this.totalRecords = this.SmartTotalRecords;
      if (this.workflowTreeValue) {
        localStorage.removeItem('workflowTreeValue');
        this.method = "get";
        this.getGridDataUrl();
      }
    }
    var gridEvnt = event;
    gridEvnt = moment(event).format('YYYY-MM-DD');
    if (gridEvnt != "" && gridEvnt != "Invalid date") {
      if (this.myFlagForSlideToggle == true) {
        this.gridApi.setQuickFilter(gridEvnt);
        if (event && this.isToggled) {

          this.totalFltRecords = this.gridApi.getDisplayedRowCount();
        }
      }
    }
    if (this.myFlagForSlideToggle == true && gridEvnt == "Invalid date") {
      this.gridApi.setQuickFilter(event);
      if (this.componentName == "executeWorkflow" || this.componentName == "inputDataIntegrityCheck" || this.componentName == "workflowStatus"
        || this.componentName == "inputDataIntegritySummery" || this.componentName == 'batch' || this.componentName == 'rapidBatch' || this.componentName == 'batchLog' || this.componentName == "rapidBatchLog"
        || this.componentName == 'scheduleTaskInfo' || this.componentName == 'rapidScheduleTaskInfo') {
        if (event && !this.isToggled) {
          this.istotalFilterCount = true;
          this.totalFltRecords = this.gridApi.getDisplayedRowCount();

        }
      }
      else {
        if (event && this.isToggled) {
          this.totalFltRecords = this.gridApi.getDisplayedRowCount();

          ;
        }
      }
    }
    else {
      if (event) {
        // this.gridApi.setQuickFilter(event);
        // this.totalRecords =this.gridApi.getModel().getRowCount();
        // //console.log(this.gridApi.getDisplayedRowCount());
        // //console.log(this.gridApi.getModel().rootNode.childrenAfterFilter.length);
        // //console.log(this.gridApi.getModel().getRowCount());
      }
    }
  }

  setuploadByDateRange() {
    let startDate = moment(this.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.endDate).format('YYYY-MM-DD');
    this.data = {
      startDate: startDate,
      endDate: endDate,
    }
    let dataUrl = 'bondInterestAccrualV2/autoCreateBond';
    this.gridService.setupbondintrestauto(dataUrl, this.data, (cbs) => {
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'bondinterestList', detail: 'Auto Create bond Intrest Accrual is Successfully.' });
      this.getGridData();
    }, (cbe) => {
    })
  }
  undoOrderData() {
    for (let i = 0; i < this.selectedRows.length; i++) {
      this.ids.push(this.selectedRows[i].id);
    }
    let id = { orders: this.ids }
    this.gridService.ConfirmUndoOrderurlList("tradeEntryV2/unAllocateOrders", id, (cbs) => {
      if (cbs) {
        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Record Undo Successfully' });
        if (this.componentName == "workflowStatus") {
          clearInterval(this.myVar);
          this.myVar = null;
        }
        this.getGridData();
        this.display1 = false;
      }
    }, (cbe) => {
    })
  }

  dataExportInFile(type) {
    let zone_name = moment.tz.guess();
    zone_name = zone_name.replace("/", "-");
    let link;
    link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + type;
    let systemAdminView = ["custodianListLegacy", "custodianList", "setupcustodianList", "FundList", "setupFundList", "taxFundList", "investorsList", "setupInvestorsList", "taxInvestorsList", "amcIMProgram", "setupamcIMProgram"]
    let connectionView = ["connectionList", "customization-connectionList",]

    if (systemAdminView.includes(this.componentName)) {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + type + "/" + this.isSystemAdmin;
    }
    if (connectionView.includes(this.componentName)) {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + type;
    }
    if (this.componentName == 'fundPropertyList') {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount));
    }
    if (this.componentName == 'userCoaList') {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + false;
    }
    if (this.componentName == 'usermappinglist' && this.subComponent == 'connectUserInvestor') {
      link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + type;

    }
    if (window.location.hostname == "localhost") {
      link = link.replace("4200", "8080");
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url)

  }


  getFilteredDataExportInFile(type) {
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "fund.fundName": {

          {
            searchObject["TransientFund"] = searchObject[x];
            delete searchObject[x];
            x = "TransientFund"
            break;
          }
        }

        default: {
          x = key
          break;
        }
      }
    });
    if (this.componentName == "custodianList" || this.componentName == "custodianListLegacy" || this.componentName == "setupcustodianList") {
      searchObject['accountNumberValue'] = searchObject['accountNumber']
      delete searchObject['accountNumber'];
    }

    if (this.componentName == "setupcustodianList") {
      if (searchObject['isWhiteList']) {
        if (searchObject['isWhiteList'].toLowerCase() == 'no') {
          searchObject['isWhiteList'] = "false";
        } else if (searchObject['isWhiteList'].toLowerCase() == 'yes') {
          searchObject['isWhiteList'] = "true";
        }
      }

    }

    else if (this.componentName == 'tradeAdvisorList') {
      if (searchObject['taxDocLookup.value']) {
        delete searchObject['taxDocLookup.value'];
        searchObject['taxDocLookup'] = "lookupselect##typeOfTaxDoc"
        searchObject['typeOfTaxDoc'] = { 'type': "TypeOfTaxDoc", 'fields.name': "TypeOfTaxDoc", 'fields.value': searchObject['taxDocLookup.value'] }
      }
      if (searchObject['entityLookup.lookupValues.TypeOfEntities']) {
        delete searchObject['entityLookup.lookupValues.TypeOfEntities'];
        searchObject['entityLookup'] = "lookupselect##entityType"
        searchObject['entityType'] = { 'type': "TypeOfEntities", 'fields.name': "TypeOfEntities", 'fields.value': searchObject['entityLookup.lookupValues.TypeOfEntities'] }
      }
    }


    let systemAdminView = ["connectionList", "customization-connectionList", "custodianList", "custodianListLegacy", "setupcustodianList", "amcIMProgram", "setupamcIMProgram"];
    if (systemAdminView.includes(this.componentName)) {
      this.isSystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
      searchObject['isSystemAdmin'] = this.isSystemAdmin;
    }
    if (this.componentName == "customization-connectionList" || this.componentName == "connectionList") {
      if (searchObject['custodianSourceConfig.workflowMngt.isSelected'] != null && searchObject['custodianSourceConfig.workflowMngt.isSelected'] != undefined) {
        searchObject['isSelected'] = searchObject['custodianSourceConfig.workflowMngt.isSelected'].toLowerCase() == 'yes' ? true : searchObject['custodianSourceConfig.workflowMngt.isSelected'].toLowerCase() == 'no' ? false : searchObject['custodianSourceConfig.workflowMngt.isSelected'];
        delete searchObject['custodianSourceConfig.workflowMngt.isSelected'];
      }
      searchObject['custodianAccountNumber'] = searchObject['custodianSourceConfig.custodianAccountNumber']
      delete searchObject['custodianSourceConfig.custodianAccountNumber'];
      searchObject['sourceLegalName'] = searchObject['custodianSourceConfig.workflowMngt.sourceLegalName']
      delete searchObject['custodianSourceConfig.workflowMngt.sourceLegalName'];
      searchObject['source'] = searchObject['custodianSourceConfig.workflowMngt.source']
      delete searchObject['custodianSourceConfig.workflowMngt.source'];
      searchObject['sourceName'] = searchObject['custodianSourceConfig.workflowMngt.sourceName']
      delete searchObject['custodianSourceConfig.workflowMngt.sourceName'];
      // searchObject['custodianAccountNumber'] = searchObject['accountNumber']
      // delete searchObject['accountNumber'];
      searchObject['fileNameFormat'] = searchObject['filenameFormat']
      delete searchObject['filenameFormat'];
      if (searchObject['custodianSourceConfig.workflowMngt.isPreviewData'] != null && searchObject['custodianSourceConfig.workflowMngt.isPreviewData'] != undefined) {
        searchObject['isPreviewData'] = searchObject['custodianSourceConfig.workflowMngt.isPreviewData'].toLowerCase() == 'yes' ? true : searchObject['custodianSourceConfig.workflowMngt.isPreviewData'].toLowerCase() == 'no' ? false : searchObject['custodianSourceConfig.workflowMngt.isPreviewData'];
        delete searchObject['custodianSourceConfig.workflowMngt.isPreviewData']
      }
      if (searchObject['custodianSourceConfig.workflowMngt.uploadData'] != null && searchObject['custodianSourceConfig.workflowMngt.uploadData'] != undefined) {
        searchObject['uploadData'] = searchObject['custodianSourceConfig.workflowMngt.uploadData'].toLowerCase() == 'yes' ? true : searchObject['custodianSourceConfig.workflowMngt.uploadData'].toLowerCase() == 'no' ? false : searchObject['custodianSourceConfig.workflowMngt.uploadData'];
        delete searchObject['custodianSourceConfig.workflowMngt.uploadData']
      }
      searchObject['frequency'] = searchObject['custodianSourceConfig.workflowMngt.frequency']
      delete searchObject['custodianSourceConfig.workflowMngt.frequency'];
      if (searchObject['custodianSourceConfig.workflowMngt.executionPeriod'] != null && searchObject['custodianSourceConfig.workflowMngt.executionPeriod'] != undefined) {
        searchObject['executionPeriod'] = parseInt(searchObject['custodianSourceConfig.workflowMngt.executionPeriod'])
        delete searchObject['custodianSourceConfig.workflowMngt.executionPeriod']
      }
    }
    if (this.componentName == 'usermappinglist' && this.subComponent == 'connectUserInvestor') {
      this.gridFilteredUrl = apiConstants[this.componentName][this.subComponent]['gridFilteredUrl'].url
    }

    searchObject['page'] = this.page;
    searchObject['type'] = type;
    searchObject['count'] = this.totalCount;
    if (this.componentName == "userCoaList" || this.componentName == 'fundPropertyList') {
      delete searchObject['type'];
    }
    if (this.componentName == "userCoaList") {
      searchObject['check'] = false;
    }


    var reqObj = searchObject;
    let access_token = this.cookieService.get('access_token')
    var request = new XMLHttpRequest();
    request.open('POST', api_url + this.gridFilteredUrl, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('confirmed'), detail: this.translateService.instant('download_request_taken_soon') });
        } else {
          this.filterType = type == 'Csv' ? 'csv' : 'xlsx';
          var fileName = apiConstants[this.componentName]['fileName'] + '.' + this.filterType
          if (this.componentName == 'usermappinglist' && this.subComponent == 'connectUserInvestor') {
            fileName = apiConstants[this.componentName][this.subComponent]['fileName'] + '.' + this.filterType
          }
          var disposition = request.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement('a');
          var contentTypeHeader = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('export_incomplete_contact_support') });
      }
    })
    request.send(JSON.stringify(reqObj));
  }

  changeDateToEOD(actualDate) {
    var endOfDayDate = new Date(
      actualDate.getFullYear(),
      actualDate.getMonth(),
      actualDate.getDate(),
      23,
      59,
      59
    );
    return endOfDayDate;
  }

  changeDateToInit(actualDate) {
    var endOfDayDate = new Date(
      actualDate.getFullYear(),
      actualDate.getMonth(),
      actualDate.getDate(),
      0,
      0,
      0
    );
    return endOfDayDate;
  }

  timeStampPayLoad(curRole) {
    var currday = new Date();
    var wkpayload;
    currday.setHours(0, 0, 0, 0);
    wkpayload = ' "createdDate"' + ":" + '"' + currday + "LHRXJ10EWRdateZRHXJ10JFK" + this.changeDateToEOD(currday);
    if (this.roleAs != 'SystemManager') {
      if (this.roleAs == 'Manager') {
        this.roleAs = 'enterprise';
      }
      wkpayload = "{" + wkpayload + '"' + "," + '"userType"' + ":" + '"' + this.roleAs + '"' + "}";
    } else {
      wkpayload = "{" + wkpayload + '"' + "}";
    }
    wkpayload = JSON.parse(JSON.stringify(wkpayload));
    return wkpayload;
  }

  getSystemProcessingData(timer?) {
    this.rowData = [];
    if (this.componentName == "workflowStatus") {
      clearInterval(this.myVar);
      this.myVar = null;
    }
    if (timer) {
      setInterval(() => { this.getGridData() }, timer * 1000);
    } else {
      this.getGridData();
    }
  }

  toggleChange(event) {
    /*Auth VG
    Smart Count Reset On Toggle from Detail View
    */
    this.gridService.istoggleChange = _.cloneDeep(event == 'true');
    (event == 'true') ? this.setSmartDefinitions() : this.setDetailedDefinition();
    this.isToggled = true;
  }

  onChangeAllocationFordataImportlist() {
    this.oppValue = !this.isAllocated;
    this.selectedRows = [];

    if (this.componentName == 'workflowStatus') {
      clearInterval(this.myVar);
      this.myVar = null;
    }

    if (this.componentName == 'executeWorkflow' && this.oppValue == true) {
      this.getCustomTypeUrl = this.getCustomTypeUrl.replace(this.oppValue.toString(), this.isAllocated.toString());
      this.getGridData();
    }
    if ((this.componentName == "inputConfiguration" || this.componentName == "customizationInputConfiguration") && this.oppValue == false) {
      this.gridDataUrl = this.gridDataUrl.replace(this.oppValue.toString(), this.isAllocated.toString());
      this.getGridData()
    } else if ((this.componentName == "inputConfiguration" || this.componentName == "customizationInputConfiguration") && this.oppValue == false) {
      var reqinputConfiguration = {
        disabled: this.oppValue,
        page: 0,
      }
      this.gridService.getFilteredData(this.filterUrl, reqinputConfiguration, (cbs) => {
        this.rowData = [];
        this.rowData = _.cloneDeep(cbs.resultList);
        if (cbs.resultList == undefined) {
          this.rowData = _.cloneDeep(cbs);
        }
        if (this.gridApi != null || this.gridApi != undefined) {
          if (this.rowData != null || this.rowData != undefined) {
            this.gridApi.setRowData(this.rowData);
          }
        }
        this.totalCount = cbs.totalCount;
        this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
        if (!this.isFryFilterAct) {
          this.totalRecords = cbs.totalCount;
        }
      }, (cbe) => {
      })
    } else if (this.componentName == "dataImportStatusManager" || this.componentName != 'workflowStatus' && this.oppValue == false) {
      if (this.componentName == "dataImportStatusManager" && this.oppValue == true) {
        var myDate = new Date();
        var reqObjimport = {
          createdDate: myDate,
          userType: "enterprise",
        }
      } else {
        var currday = new Date();
        reqObjimport = {
          "createdDate": currday,
          "userType": "user",
        }
      }
      this.gridService.getFilteredData(this.filterUrl, reqObjimport, (cbs) => {
        this.rowData = [];
        this.rowData = _.cloneDeep(cbs.resultList);
        if (cbs.resultList == undefined) {
          this.rowData = _.cloneDeep(cbs);
        }
        if (this.gridApi != null || this.gridApi != undefined) {
          if (this.rowData != null || this.rowData != undefined) {
            this.gridApi.setRowData(this.rowData);
          }
        }
        this.totalCount = cbs.totalCount;
        this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
        if (!this.isFryFilterAct) {
          this.totalRecords = cbs.totalCount;
        }
      }, (cbe) => {
      })
    }
  }

  ngAfterViewInit() {
    // if (!this.myFlagForSlideToggle) {
    //   let x = Array.from(document.getElementsByClassName('content') as HTMLCollectionOf<HTMLElement>)
    //   x[2].style.overflowX = 'hidden'
    //   x[2].style.overflowY = 'hidden'
    // }
    if (this.componentName == 'runViewOpenTaxlots' && !this.myFlagForSlideToggle) {
      let x = Array.from(document.getElementsByClassName('content') as HTMLCollectionOf<HTMLElement>)
      x[2].style.overflowX = 'hidden'
      x[2].style.overflowY = 'auto'
    }
    // this.scrollDispatcher
    //   .ancestorScrolled(this.el)
    //   .subscribe(event => this.onWindowScroll());
  }

  onWindowScroll() {
    var rows = document.querySelector('.mat-header-row');
    if (rows) {
      if (rows.getBoundingClientRect().top == 63) {
        rows.classList.add("blue_bg");
      } else {
        rows.classList.remove("blue_bg");
      }
    }
  }
  trimObjValues(obj) {
    return Object.keys(obj).reduce((acc, curr) => {
      acc[curr] = typeof obj[curr] == 'string' ? obj[curr].trim() : obj[curr];
      return acc;
    }, {});
  }

  toast(severity: any, summary: any, detail: any, autoClear: any) {
    this.messageService.add({ closable: true, sticky: true, severity: severity, summary: summary, detail: detail });
  }
  formValidation(event: boolean) {
    this.formValid = !event;
    if (!this.formValid) {
      this.formValid = this.formBasicInfo.form.status === "INVALID" ? true : false;
    }
  }
  setOnChange(evt) {
    if (evt.id == 'exchangeName') {
      let clientId = this.formBasicInfo.form.value['exchangeName'].id;
      if (clientId != undefined && clientId != null) {
        this.gridService.getClientName(clientId, this.currentUser['enterpriseId']).subscribe((res) => {
          let userList = res.length > 0 ? res.map(element => ({ label: element.name, value: element.name })) : [];
          this.formBasicInfo.form.controls['clientName'].setValue();
          this.commonDropdownDetails[1] = { id: 'clientName', controlType: 2, label: 'client_name', name: 'clientName', viewName: 'clientName', order: 2, value: '', required: true, appearance: "fill", disabled: false, fill: userList, class: "col-lg-3 workflowheadinput custominput", style: "display:block;" };
        })
      }
    }
  }
  /**
   * Function that search based on form selection FSA-395
   * @author   Ashok Kumar
   * @return   {Void}
   */
  searchFSConfig() {
    let formData = {};
    Object.assign(formData, this.formBasicInfo.form.value);
    let isEmpty = Object.values(formData).every(x => x === null || x === '');
    if (isEmpty) {
      this.gridApi.setFilterModel(null);
      this.getGridData();
    } else {
      this.reqObj = this.gridService.getFilteredReq(formData);
      let tempReqObj = this.reqObj;
      delete tempReqObj['page'];
      let tempValueInstance = {};
      Object.entries(tempReqObj).forEach((value, index) => {
        let key = value[0];
        if (value[0] == 'startDate' || value[0] == 'endDate') {
          tempValueInstance[key] = this.gridService.createdateSet(value[1], value[0]);
        } else {
          tempValueInstance[key] = { type: 'contains', filter: value[1] }
        }
      });
      this.gridApi.setFilterModel(tempValueInstance);
    }
  }

  viewDataCollectionSubmit() {
    let tempValueInstance = {};
    let formData: any = {};
    Object.assign(formData, this.formBasicInfo.form.value)
    tempValueInstance['exchangeName'] = { type: 'contains', filter: formData['exchangeName'].name }
    tempValueInstance['clientName'] = { type: 'contains', filter: formData['clientName'] }
    let startDate = moment(formData['startDate']).format('YYYY-MM-DD');
    let endDate = moment(formData['endDate']).format('YYYY-MM-DD');
    if (startDate > endDate) {
      this.formBasicInfo.form.controls.endDate.setValue(null)
      this.messageService.add({ sticky: true, severity: "error", summary: "Error", detail: "End Date should be greater than start date" });
    }
    else {
      tempValueInstance['processISODate'] = { type: 'inRange', dateFrom: startDate, dateTo: endDate };
      this.gridApi.setFilterModel(tempValueInstance);
    }
  }
  downloadViewDataCollection() {
    var request = new XMLHttpRequest();
    this.gridFilteredUrl = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + this.subComponent + "/?enterpriseId=" + this.currentUser.enterpriseId
    let userState = JSON.parse(localStorage.getItem('userState'));
    var access_token = this.cookieService.get('access_token');

    request.open('GET', this.gridFilteredUrl, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response || !request.response.size) {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('something_wrong_please_try_again') });
        } else {
          this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('download_request_taken_soon') });
          var fileName = this.subComponent + ".zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("attachment") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          var contentTypeHeader = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('something_wrong_please_try_again') });
      }
    })
    request.send(null);
  }

  AllsmdownloadCSV() {
    this.totalCount = this.totalRecords;;
    let zone_name: any;
    let zone_name1 = moment.tz.guess();
    zone_name = zone_name1.replace("/", "_");
    let link;
    link = this.isZoneName ? this.newgridExportUrl + zone_name : this.newgridExportUrl;
    if (this.componentName != 'sourceConfiguration') {
      if (window.location.hostname == "localhost") {
        link = link.replace("4200", "8080");
      }
      let url = environment.api_url + link;
      this.commonService.GenericDownload(url);
    }
  }
  getSmDownloadCsvFilteredData() {
    let gridApi = this.gridApi;
    this.downloadSmCsvFilteredData(this.lookups, gridApi, this.page, this.totalFltRecords, this.heading);
  }
  getSearchObj(searchObject, key) {
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "brokerAccount.accountNumber": {
          searchObject["TransientBrokerAccount"] = searchObject[x];
          delete searchObject[x];
          x = "TransientBrokerAccount"
          break;
        }
        case "assetClass.name": {
          searchObject["TransientAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "TransientAssetClass"
          break;
        }
        case "clientLookup.value": {
          if (this.componentName != 'exchanges' && this.componentName != 'assetClass') {
            searchObject["clientLookup"] = "lookupselect##ratingAgency";
            searchObject["ratingAgency"] = { 'type': "RatingAgencies", "fields.name": "Name", "fields.value": searchObject[x] };
            delete searchObject[x];
            x = "clientLookup.value"
          }
          else {
            searchObject["clientLookup"] = "lookupselect##primaryAssetClass";
            searchObject["primaryAssetClass"] = { 'type': "PrimaryAssetClasses", "fields.name": "Primary Asset Class", "fields.value": searchObject[x] };
            delete searchObject[x];
            x = "clientLookup.value"
          }
          break;
        }
        case "fund.fundName": {
          if (this.componentName == 'nonTradeActivity' || this.componentName == 'journalentry' || this.componentName == "RapidJournalentry" || this.componentName == 'incomeDistribution' || this.componentName == 'viewCapitalCall' || this.componentName == 'rapidViewCapitalCall' || this.componentName == 'viewjesummary' || this.componentName == "jeSummary" || this.componentName == 'rapidjesummary' || this.componentName == 'customRatio' || this.componentName == "rapidCustomRatio" || this.componentName == "taxCustomRatio" || this.componentName == 'viewDistribution' || this.componentName == 'rapidViewDistribution' || this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy' || this.componentName == 'taxcustodianList') {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          } else if (this.componentName == 'uploadallocationhistory' || this.componentName == 'rapidUploadallocationhistory' || this.componentName == 'historicalInvestorCapitalSummary') {
            searchObject["fundName"] = searchObject[x];
            delete searchObject[x];
            x = "fundName"
            break;
          }
          else if (this.componentName == 'hurdleRate') {
            searchObject["fund.fundName"] = searchObject[x];
            // delete searchObject[x];
            x = "fund.fundName"
            break;
          }
          else {

            searchObject["TransientFund"] = searchObject[x];
            delete searchObject[x];
            x = "TransientFund"
            break;

          }
        }
        case "source.isoCode": {
          searchObject["source"] = searchObject[x];
          delete searchObject[x];
          x = "source"
          break;
        }
        case "transactionType": {
          if (this.componentName == 'tradingLevelChange' || this.componentName == 'rapidTradingLevelChange' || this.componentName == 'cashTransaction') {
            searchObject["transactionTypeValue"] = searchObject[x];
            delete searchObject[x];
            x = "transactionTypeValue"
            break;
          }
        }
        case "countryMaster.name": {
          if (this.componentName == 'regulatorsForSetting' || this.componentName == 'regulators') {
            searchObject["countryMaster"] = searchObject[x];
            delete searchObject[x];
            x = "countryMaster"
          }
          break;
        }
        case "PAssetClass": {
          if (this.componentName == 'workflowManagement') {
            searchObject["primaryAssetClass"] = searchObject[x];
            delete searchObject[x];
            x = "primaryAssetClass"
          }
          break;
        }
        case "uploadData": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isUploadData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isUploadData"
          }
          break;
        }
        case "isPreviewData": {
          if (this.componentName == 'workflowManagement') {
            searchObject["isPreviewData"] = searchObject[x] == 'yes' ? true : searchObject[x] == 'no' ? false : searchObject[x];
            delete searchObject[x];
            x = "isPreviewData"
          }
          break;
        }
        case "ProcessName": {
          if (this.componentName == 'workflowManagement') {
            searchObject["processName"] = searchObject[x];
            delete searchObject[x];
            x = "processName"
          }
          break;
        }
        case "AssetClass": {
          if (this.componentName == 'workflowManagement') {
            searchObject["assetClass"] = searchObject[x];
            delete searchObject[x];
            x = "assetClass"
          }
          break;
        }
        case "inputMode": {
          if (this.componentName == 'workflowManagement') {
            searchObject["inputModeType"] = searchObject[x];
            delete searchObject[x];
            x = "inputModeType"
          }
          break;
        }
        case "exchanges.exchangeName": {
          searchObject["exchanges"] = searchObject[x];
          delete searchObject[x];
          x = "exchanges"
          break;
        }
        case "currency.isoCode": {
          searchObject["currency"] = searchObject[x];
          delete searchObject[x];
          x = "currency"
          break;
        }
        case "assetClasses.name": {
          searchObject["assetClasses"] = searchObject[x];
          delete searchObject[x];
          x = "assetClasses"
          break;
        }
        case "clientLookup.value": {
          searchObject["primaryAssetClass"] = searchObject[x];
          delete searchObject[x];
          x = "primaryAssetClass"
          break;
        }
        case "fundName": {
          //   if (this.componentName != 'Manager' && this.componentName != 'viewPnlDistribution') {
          //     searchObject["fund"] = searchObject[x];
          //     delete searchObject[x];
          //     x = "fund"
          //   }
          //   break;
          // }
          if (this.componentName != 'viewtotalgl' && this.componentName != 'viewPnlDistribution' && this.componentName != 'rapidViewPnlDistribution' && this.componentName != 'benchmarkirr' && this.componentName != 'rapidBenchmarkirr' && this.componentName != 'reportApproval' && this.componentName != 'runViewOpenTaxlots' && this.componentName != 'viewclosedtaxLots' && this.componentName != 'dailyMargin' && this.componentName != 'rapidDailyMargin'
            && this.componentName != 'FundList' && this.componentName != 'TLRegime' && this.componentName != 'rapidTLRegime' && this.componentName != 'Manager' && this.componentName != 'externalROR' && this.componentName != 'rapidExternalROR' && this.componentName != 'investorgroup' && this.componentName != "rapidInvestorgroup" && this.componentName != 'inputDataIntegritySummery' && this.componentName != 'MISFundList') {
            searchObject["fund"] = searchObject[x];
            delete searchObject[x];
            x = "fund"
            break;
          }


          else {
            searchObject["fundName"] = searchObject[x];
            x = "fundName"
            break;
          }
        }
        case "enterpriseName": {
          if (this.componentName == 'MISFundList') {
            searchObject["transientEnterprise"] = searchObject[x];
            delete searchObject[x];
            x = "transientEnterprise"
          }
          break;
        }
        case "country.name": {
          if (this.componentName != 'exchanges') {
            searchObject["country"] = searchObject[x];
            delete searchObject[x];
            x = "country"
          }
          break;
        }
        case "client_Ip": {
          searchObject["Client_Ip"] = searchObject[x];
          delete searchObject[x];
          x = "country"
          break;
        }
        case "fundManager.firstName": {
          searchObject["fundManager"] = searchObject[x];
          delete searchObject[x];
          x = "fundManager"
          break;
        }
        case "fundGroup.fundGroup": {
          if (this.componentName == 'FundList') {
            searchObject["fundGroupName"] = searchObject[x];
            delete searchObject[x];
            x = "fundGroupName"
          }
          break;
        }
        case "enterprise.enterpriseName": {
          searchObject["enterprise"] = searchObject[x];
          delete searchObject[x];
          x = "enterprise"
          break;
        }
        case "brokerName.accountNumber": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["accountNumber"] = searchObject[x];
            delete searchObject[x];
            x = "accountNumber"
            break;
          }
        }
        case "brokerName.nameOfCustodian": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["brokerName"] = searchObject[x];
            delete searchObject[x];
            x = "brokerName"
            break;
          }
        }
        case "brokerName.nameOfCustodian": {
          if (this.componentName == 'dailyMargin' || this.componentName == 'rapidDailyMargin') {
            searchObject["brokerName"] = searchObject[x];
            delete searchObject[x];
            x = "brokerName"
            break;
          }
        }
        case "tradeAdvisor.nameOfAdvisor": {
          if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy') {
            searchObject["tradeAdvisor"] = searchObject[x];
            delete searchObject[x];
            x = "tradeAdvisor"
            break;
          }
        }
        case "accountNumber": {
          if (this.componentName == 'custodianList' || this.componentName == 'custodianListLegacy') {
            searchObject["accountNumberValue"] = searchObject[x];
            delete searchObject[x];
            x = "accountNumberValue"
            break;
          }
        }
        case "dataCount": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            let srchObj = searchObject[x] + "LHRXJ10EWRnumberZRHXJ10JFK";
            searchObject["dataCount"] = srchObj;
            //delete searchObject[x];
            x = "dataCount"
            break;
          }
        }
        case "successCount": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            let srchObj = searchObject[x] + "LHRXJ10EWRnumberZRHXJ10JFK";
            searchObject["successCount"] = srchObj;
            //delete searchObject[x];
            x = "successCount"
            break;
          }
        }
        case "executionPeriod": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            searchObject["alteryxWorkflowMngt.executionPeriod"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.executionPeriod"
            break;
          }
        }
        case "frequency": {
          if (this.componentName == 'dataImportStatus' || this.componentName == 'dataImportStatusManager'
            || this.componentName == 'workflowStatus' || this.componentName == 'executeWorkflow') {
            searchObject["alteryxWorkflowMngt.frequency"] = searchObject[x];
            delete searchObject[x];
            x = "alteryxWorkflowMngt.frequency"
            break;
          }
        }
        default: {
          x = key
          break;
        }
      }
    });
    return searchObject;
  }
  downloadSmCsvFilteredData(lookups, gridApi, page, totalFltRecords, heading) {
    if (heading) {
      this.translateService.get(heading).subscribe((traval) => {
        heading = traval;
      });
    }
    this.gridApi = gridApi;
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    searchObject = this.getSearchObj(searchObject, key);
    searchObject['page'] = page;
    if (this.orderFilter) {
      searchObject['orderFilter'] = this.orderFilter
    }
    if (this.componentName == 'journalentry' || this.componentName == "RapidJournalentry") {
      searchObject['moduleName'] = lookups
      searchObject = { searchObj: searchObject };
      searchObject['count'] = totalFltRecords;
    }
    var res = searchObject;
    let url = api_url + this.gridFilteredModuleUrl + "?moduleName=" + this.moduleName;
    var request = new XMLHttpRequest();
    var access_token = this.cookieService.get('access_token');
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/zip');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'arraybuffer';
    request.onload = function () { };
    request.addEventListener("loadend", () => {
      if (request.status === 200) {
        if (!request.response) {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: heading, detail: 'Something went wrong with download, please try again.' });
        } else {
          var fileName = this.moduleName + "Filtered.zip";
          var disposition = request.getResponseHeader("Content-Disposition");
          if (disposition && disposition.indexOf("filename") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: 'application/zip' }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: heading, detail: 'Something went wrong with download, please try again.' });
      }
    })
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: 'Confirmed', detail: 'Download Request taken. Your download will start soon.' });
    request.send(JSON.stringify(res));
  }

  resetStatus(evt) {
    if (evt) {
      this.custSearchKey = null;
      console.log(this.componentName);
      if (this.componentName == "workflowStatus") {
        this.workflowTreeValue = null;
        localStorage.removeItem('workflowTreeValue');
      }
      this.getGridData();
    }
  }
  sequenceExecutionVisible() {
    this.isExecutionLocked = this.isExecutionLocked ? true : false;
    this.getGridData();
  }

  dataExportInFileSMRealEstate(type) {
    let zone_name = moment.tz.guess();
    zone_name = zone_name.replace("/", "-");
    let link;
    link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount));
    let systemAdminView = ["connectionList", "customization-connectionList", "custodianList", "setupcustodianList", "FundList", "setupFundList", "taxFundList", "investorsList", "setupInvestorsList", "taxInvestorsList", "amcIMProgram", "setupamcIMProgram"]

    if (systemAdminView.includes(this.componentName)) {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + type + "/" + this.isSystemAdmin;
    }
    if (this.componentName == 'reReportConfig') {
      link = apiConstants[this.componentName]['gridExportUrl'].url + '/' + this.isEnterprise;
    }
    if (this.componentName == 'usermappinglist' && this.subComponent == 'connectUserInvestor') {
      link = apiConstants[this.componentName][this.subComponent]['gridExportUrl'].url + encodeURI(JSON.stringify(this.totalCount)) + '/' + type;

    }
    if (this.componentName == 'bill' || this.componentName == 'rapidBill') {
      type = "all";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "Bill"+ '/'+ type;
    }
    if (this.componentName == 'rapidInvoice') {
      type = "all";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "Invoice"+ '/'+ type;
    }
    if (this.componentName == 'rapidOutstandingInvoice') {
      type = "unpaid";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "Invoice"+ '/' + type;
    }
    if (this.componentName == 'unpaidBills' || this.componentName == "rapidUnpaidBills") {
      type = "unpaid";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "Bill"+ '/' + type;
    }
    if (this.componentName == 'paymentRecipt') {
      type = "paid";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "Invoice"+ '/'+ type;
    }
    if (this.componentName == 'paidBills' || this.componentName == "rapidPaidBills") {
      type = "paid";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "Bill"+ '/'+ type;
    }
    if (this.componentName == 'reviewChartOfAccounts') {
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + true;
    }if(this.componentName == 'cashExpenseList'){
      type = "Expense";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "CashExpense"+ '/' + type;
    }if(this.componentName == 'vendorCreditList'){
      type = "credit";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "credit"+ '/' + type;
    }if(this.componentName == 'cashIncomeList'){
      type = "CashIncome";
      link = this.gridExportUrl + encodeURI(JSON.stringify(this.totalCount)) + '/' + "CashIncome"+ '/' + type;
    }
    if (window.location.hostname == "localhost") {
      link = link.replace("4200", "8080");
    }
    let url = environment.api_url + link;
    this.commonService.GenericDownload(url);

  }

  getFilteredDataExportInFileSM(type) {
    const filtredModel = this.gridApi.getFilterModel();
    let x = Object.entries(filtredModel);
    var key = x[0][0];
    let value = x[0][1]['filter'];
    let gridOptions = { api: this.gridApi };
    let searchObject = this.commonService.getDataToFilter(gridOptions, this.gridApi);
    Object.keys(searchObject).forEach(x => {
      switch (x) {
        case "fund.fundName": {
          {
            if (this.componentName == 'crystalizedPerformanceFeeallocationList') {
              searchObject["fund.fundName"] = searchObject[x];
              x = "fund.fundName"
              break;
            } else {
              searchObject["TransientFund"] = searchObject[x];
              delete searchObject[x];
              x = "TransientFund"
              break;
            }
          }
        }
        case "entityList": {
          searchObject["entityList.fundName"] = searchObject[x];
          delete searchObject[x];

          break;
        }
        case "fundList": {
          searchObject["fundList.fundName"] = searchObject[x];
          delete searchObject[x];
          break;
        }

        default: {
          x = key
          break;
        }
      }
    });
    if (this.componentName == "custodianList" || this.componentName == "setupcustodianList") {
      searchObject['accountNumberValue'] = searchObject['accountNumber']
      delete searchObject['accountNumber'];
    }
    if (this.componentName == 'smRealestateList') {
      searchObject['fund'] = searchObject['fundName']
      delete searchObject['fundName'];
    }
    if (this.componentName == 'RapidSmRealestateList' || this.componentName == 'globalSmRealestateList') {
      searchObject['fund'] = searchObject['fundName']
      delete searchObject['fundName'];
    }
    else if (this.componentName == 'tradeAdvisorList') {
      if (searchObject['taxDocLookup.value']) {
        delete searchObject['taxDocLookup.value'];
        searchObject['taxDocLookup'] = "lookupselect##typeOfTaxDoc"
        searchObject['typeOfTaxDoc'] = { 'type': "TypeOfTaxDoc", 'fields.name': "TypeOfTaxDoc", 'fields.value': searchObject['taxDocLookup.value'] }
      }
      if (searchObject['entityLookup.lookupValues.TypeOfEntities']) {
        delete searchObject['entityLookup.lookupValues.TypeOfEntities'];
        searchObject['entityLookup'] = "lookupselect##entityType"
        searchObject['entityType'] = { 'type': "TypeOfEntities", 'fields.name': "TypeOfEntities", 'fields.value': searchObject['entityLookup.lookupValues.TypeOfEntities'] }
      }
    }


    let systemAdminView = ["connectionList", "customization-connectionList", "custodianList", "setupcustodianList", "amcIMProgram", "setupamcIMProgram"];
    if (systemAdminView.includes(this.componentName)) {
      this.isSystemAdmin = (this.currentUser.authList.indexOf('FUNCTION_CREATE') > -1 && this.currentUser.authList.indexOf('ENTERPRISE_CREATE') > -1) ? true : false;
      searchObject['isSystemAdmin'] = this.isSystemAdmin;
    }

    if (this.componentName == 'usermappinglist' && this.subComponent == 'connectUserInvestor') {
      this.gridFilteredUrl = apiConstants[this.componentName][this.subComponent]['gridFilteredUrl'].url
    }

    searchObject['page'] = this.page;
    //searchObject['type'] = type;
    searchObject['count'] = this.totalCount;
    if (this.componentName == 'reReportConfig') {
      searchObject['isEnterprise'] = this.isEnterprise;
      delete searchObject['count'];
    }
    if (this.componentName == "reviewChartOfAccounts") {
      delete searchObject['type'];
      searchObject['check'] = true;
    }
    if (this.componentName == "activityUploadReview") {
      delete searchObject['type'];
    }
    if (this.componentName == 'paymentPayableData' || this.componentName == 'rapidPaymentPayableData') {
      delete searchObject['page'];
    }
if(this.componentName == 'rapidReceiptsData'){
  delete searchObject['page'];
}
    if (this.componentName == 'bill' || this.componentName == "rapidBill") {
      searchObject['entryType'] = "Bill"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject = searchObject;
      this.gridFilteredUrl = "reBillDetails/downloadFilterRecords/";
    }
    if (this.componentName == 'rapidInvoice' ) {
      searchObject['entryType'] = "Invoice"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject = searchObject;
      this.gridFilteredUrl = "customerInvoice/downloadFilterRecords/";
    }
    if (this.componentName == 'cashIncomeList' ) {
      searchObject['entryType'] = "CashIncome"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject = searchObject;
      this.gridFilteredUrl = "customerInvoice/downloadFilterRecords/";
    }
    if (this.componentName == 'rapidOutstandingInvoice' ) {
      searchObject['entryType'] = "Invoice"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject['billStatus'] = "Unpaid";
      searchObject = searchObject;
      this.gridFilteredUrl = "customerInvoice/downloadFilterRecords/";
    }
    if (this.componentName == 'unpaidBills' || this.componentName == "rapidUnpaidBills") {
      searchObject['entryType'] = "Bill"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject['billStatus'] = "Unpaid";
      searchObject = searchObject;
      this.gridFilteredUrl = "reBillDetails/downloadFilterRecords/";
    }
    if (this.componentName == 'paidBills' || this.componentName == "rapidPaidBills") {
      searchObject['entryType'] = "Bill"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject['billStatus'] = "Paid";
      searchObject = searchObject;
      this.gridFilteredUrl = "reBillDetails/downloadFilterRecords/";
    } if (this.componentName == 'paymentRecipt') {
      searchObject['entryType'] = "Invoice"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject['billStatus'] = "Paid";
      searchObject = searchObject;
      this.gridFilteredUrl = "customerInvoice/downloadFilterRecords/";
    } 
    if (this.componentName == 'cashExpenseList' ) {
      searchObject['entryType'] = "CashExpense"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject = searchObject;
      this.gridFilteredUrl = "reBillDetails/downloadFilterRecords/";
    }if (this.componentName == 'vendorCreditList' ) {
      searchObject['entryType'] = "credit"
      delete searchObject['page']
      searchObject['count'] = this.totalCount;
      searchObject = searchObject;
      this.gridFilteredUrl = "reBillDetails/downloadFilterRecords/";
    }
    var reqObj = searchObject;
    let access_token = this.cookieService.get('access_token')

    var request = new XMLHttpRequest();
    request.open('POST', api_url + this.gridFilteredUrl, true);
    request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    request.setRequestHeader('Authorization', 'Bearer ' + access_token);
    request.responseType = 'blob';
    request.onload = function () { };

    request.addEventListener("loadend", () => {

      if (request.status === 200) {
        if (!request.response || !request.response.size) {


          this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('confirmed'), detail: this.translateService.instant('download_request_taken_soon') });
        } else {
          this.filterType = type == 'Csv' ? 'csv' : 'xlsx';
          var fileName = apiConstants[this.componentName]['fileName'] + '.' + this.filterType
          if (this.componentName == 'usermappinglist' && this.subComponent == 'connectUserInvestor') {
            fileName = apiConstants[this.componentName][this.subComponent]['fileName'] + '.' + this.filterType
          }
          var disposition = request.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          var blob = request.response;
          var downloadLink = window.document.createElement('a');
          var contentTypeHeader = request.getResponseHeader("Content-Type");
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('export_incomplete_contact_support') });
      }
    })
    request.send(JSON.stringify(reqObj));
  }
  checkedWorkflows() {

    let isWorkflowsUnchecked = this.isWorkflowsUnchecked ? 'yes' : 'no';
    let tempValueInstance = {};
    tempValueInstance['custodianSourceConfig.workflowMngt.isSelected'] = {
      "filterType": "text",
      "type": "contains",
      "filter": isWorkflowsUnchecked
    }
    this.gridApi.setFilterModel(tempValueInstance);

  }


  confirmISDApprovalData() {
    this.selectedISDList = [];
    this.selectedRows.forEach((element: any) => {
      this.selectedISDList.push(element.id)
    });
    this.getISDClientApprovalId.emit(this.selectedISDList);
  }


  clientNAData() {
    if (this.isDoubleClick) return;
    this.isDoubleClick = true;
    let selectedList = [];
    this.selectedRows.forEach((element: any) => {
      selectedList.push(element.id)
    });
    let requestData = {
      "workflowId": selectedList.toString(),
      "workflowStatus": 'NA',
      "investorsDetailRequired": null
    };
    this.gridService.updateWorkFlow(requestData).subscribe(res => {
      if (res) {
        this.isDoubleClick = false;
        this.getGridData();
        if (res.code == 1) {
          this.messageService.add({ sticky: true, severity: 'success', summary: 'Success', detail: res.message });
        }
        else {
          this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: res.message });
        }
      }
    })

  }

  setExpendView() {
    if (this.componentName == 'paymentsComponent' || this.componentName == 'rapidPaymentsComponent' || this.componentName == 'vendorCreditList' || this.componentName == 'rapidreciptsComponent' || this.componentName == 'taxAllocationAdjustmentRatios') {
      setTimeout(() => {
        this.setupPercentageService.setShowGridMsg(this.displayForm ? false : true);

      }, 50)

    }
  }

  downloadRowDoc(event, type?) {
    let url = [];
    let path = [];
    var selectedRowsChecked = this.gridApi.getSelectedRows();
    let Id = selectedRowsChecked.map(a => a.id)
    if (selectedRowsChecked) {
      if (Id) {
        if (this.moduleName == 'bill' || this.moduleName == 'unpaidBills' || this.moduleName == 'paidBills'
          || this.moduleName == 'rapidBill' || this.moduleName == "rapidUnpaidBills" || this.moduleName == "rapidPaidBills" || this.componentName == "rapidInvoice" || this.componentName == "cashIncomeList") {
          selectedRowsChecked.forEach(element => {
            if (element.fileURL?.includes("xlsx") || element.fileURL?.includes("csv") || element.fileURL?.includes("Csv")) {
              url.push(element.fileURL)
            }
            else {
              path.push(element.fileURL?.split('/'));
            }
          });
        }
      }
    }
    let filteredPath = path.filter(item => !!item)
    if (type == 'all') {
      let checkName;
      let checkNameArr = [];
      this.flUri = [];
      this.rowData.forEach(ele => {
        let exists = (ele.fileURL)?.includes("xlsx");
        let csv = (ele.fileURL)?.includes("csv");
        let Cvs = (ele.fileURL)?.includes("Csv");
        if (ele.fileURL == null || ele.fileURL == undefined || ele.fileURL == "" || exists || csv || Cvs) {
          checkName = true;
        }
        else {
          checkName = false;
        }
        checkNameArr.push(checkName);
      })
      if (checkNameArr.includes(false)) {
        this.downloadFileFromAWSAll(this.flUri).subscribe(res => {
          this.downloadAttachments(res, path)
        })
      }
      else {
        this.messageService.add({ sticky: true, severity: 'warn', summary: this.translateService.instant('warning'), detail: this.translateService.instant('attachment_not_found') });
      }
    }
    else {
      if (filteredPath.length) {
        this.downloadFileFromAWS(filteredPath).subscribe(res => {
          this.downloadAttachments(res, path)
        })
      }
      else {
        this.messageService.add({ sticky: true, severity: 'warn', summary: this.translateService.instant('warning'), detail: this.translateService.instant('attachment_not_found') });
      }

    }
  }

  downloadFileFromAWS(fileUrl): Observable<any> {
    let fileName = [];
    fileUrl?.forEach(el => {
      fileName.push((el[el?.length - 3] + "/" + el[el?.length - 2] + "/" + el[el?.length - 1]).toString());
    })
    return this.httpClient.post<any>(environment.api_url + 'reBillDetails/downloadFilterAttachment', fileName, { responseType: 'blob' as 'json' });

  }
  downloadFileFromAWSAll(fileUrl): Observable<any> {
    var fileName = [];
    return this.httpClient.post<any>(environment.api_url + 'reBillDetails/downloadFilterAttachment', fileName, { responseType: 'blob' as 'json' });
  }

  downloadAttachments(res, path) {
    var a = document.createElement('a');
    var blob = new Blob([res], { type: res.ContentType });
    a.href = URL.createObjectURL(blob);
    a.download = "BillAttachment.pdf";
    a.click();
    this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('file_download_successful') });
  }

  setMemoEntry() {
    this.openMemoModel.emit({ type: "create", check: true });
  }

  selectedBillReverse() {
    this.openMemoModel.emit(this.selectedRows);
  }

  selectedISDMultipleEmail() {

    this.openMemoModel.emit(this.selectedRows);
  }
  selectedBillReverse2() {
    this.selectedISDMultipleEmail();
  }
  rejectActivity(isFilter?) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: isFilter == 'true' ? 'Are you sure you want to delete ' + this.rowData.length + ' Filtered records?' : 'Are you sure You want to reject all records?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: any) => {

      if (confirmed) {
        let selectedList = [];
        this.rowData.forEach((element: any) => {
          selectedList.push(element.id)
        });
        let request1 = {
          moduleName: 'clientActivity',
          totalCount: this.totalRecords
        }
        let request2 = {
          moduleName: 'clientActivity',
        }
        if (this.reqObj1) {
          Object.entries(this.reqObj1).forEach((key, value) => {
            if (key[1] != undefined) {
              request1[key[0]] = key[1];
            }
          })
        }
        let request;
        isFilter == 'true' ? request = request1 : request = request2;
        this.gridDeleteUrl = isFilter == 'true' ? 'deleteController/deleteFilterByKeys' : 'deleteController/deleteAll';

        this.gridService.deleteAllGridData(this.gridDeleteUrl, request, (cbs) => {
          if (cbs && cbs.message) {
            let locMsg = cbs.message
            locMsg = locMsg.toLowerCase();
            if (locMsg.includes('failed') && locMsg.includes("can't") && locMsg.includes('not') && locMsg.includes('mapped')) {
              this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.moduleName, detail: cbs.message });
            }
            else {
              this.getGridData();
              this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: cbs.message });
            }
          }
          else {
            this.messageService.add({ sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: cbs.message });
            this.getGridData();
            this.rowData = [];
            if (this.gridApi != null || this.gridApi != undefined) {
              if (this.rowData != null || this.rowData != undefined) {
                this.gridApi.setRowData(this.rowData);
              }
            }
            this.totalRecords = 0;
          }
        }, (cbe) => {

        })
      }
    });
  }

  filterActivityLogByKeys(reqObj) {
    this.filteredData = [];
    this.gridService.getFilteredData('financialDataIntegration/filterActivityLogByKeys', reqObj, (cbs) => {
      cbs.resultList.forEach((element: any) => {
        if (element["createdOn"]) {

          let resMnt1 = moment(new Date(element.createdOn)).isValid()
          if (resMnt1) {
            element.createdOn = moment(new Date(element.createdOn)).format('YYYY-MM-DD hh:mm:ss:ssss');
          } else {
            element.createdOn = element.createdOn;
          }
        }

      });
      this.rowData = cbs.resultList

      if (this.componentName == 'activityClient') {
        this.activityUploadCompliance.emit(cbs);
      }
    }, (cbe) => {
    })
  }
  getFundPeriodLockDate(accountSummaryData) {

    let fundPeriodLockDate;
    for (var v = 0; v < this.fundListAll.length; v++) {
      if (this.fundListAll[v].id == accountSummaryData.fundId) {
        fundPeriodLockDate = this.fundListAll[v].fundPeriodLockDate;
      }
    }
    let newDateFundLock = (moment(new Date(fundPeriodLockDate))).format('YYYY-MMM-DD hh:mm:ss:ssss');
    const fundLockyear = (new Date(newDateFundLock)).getFullYear();
    const fundLockmonth = newDateFundLock.split("-")[1]
    const formattedFundDate = `${fundLockmonth}-${fundLockyear}`;


    let newDatePeriodLock = (moment(new Date(this.currentUser.periodLockDate))).format('YYYY-MMM-DD hh:mm:ss:ssss');
    const periodLockyear = (new Date(newDatePeriodLock)).getFullYear();
    const periodLockmonth = newDatePeriodLock.split("-")[1]

    const formattedPeriodLockDate = `${periodLockmonth}-${periodLockyear}`;
    const monthsIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Fund Lock Date
    const [monthStrF, yearStrF] = formattedFundDate.split('-');
    const monthIndexF = monthsIndex.indexOf(monthStrF);
    const yearF = parseInt(yearStrF, 10);

    // Period Lock Date
    const [monthStrP, yearStrP] = formattedPeriodLockDate.split('-');
    const monthIndexP = monthsIndex.indexOf(monthStrP);
    const yearP = parseInt(yearStrP, 10);

    // Configuration date 
    const [monthStrConfig, yearStrConfig] = accountSummaryData.monthYear.split('-');
    const monthIndexConfig = monthsIndex.indexOf(monthStrConfig);
    const yearConfig = parseInt(yearStrConfig, 10);

    const fundDate = new Date(yearF, monthIndexF, 1);
    const periodDate = new Date(yearP, monthIndexP, 1);
    const configDate = new Date(yearConfig, monthIndexConfig, 1);
    var result = this.getLockMsg(configDate, fundDate, periodDate, newDateFundLock, newDatePeriodLock);
    return result;
  }

  getLockMsg(configDate, compFundLockDate, UserLockDate, dateFundLock, datePeriodLock) {
    if ((compFundLockDate != null) && (UserLockDate != null)) {
      if (((new Date(compFundLockDate)) >= (new Date(UserLockDate))) && ((new Date(configDate)) <= (new Date(compFundLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('config_month_should_be_greater_than_fund_lock_date') + `(` + moment(dateFundLock).format('MM-DD-YYYY') + ` )`, });
        return "Fund Lock"
      }
      else if (((new Date(compFundLockDate)) <= (new Date(UserLockDate))) && ((new Date(configDate)) <= (new Date(UserLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('config_month_should_be_greater_than_lock_date') + `(` + moment(datePeriodLock).format('MM-DD-YYYY') + ` )`, });
        return "Period Lock"
      }
      else {
        return "No locks found";
      }

    } else if ((this.currentUser.periodLockDate != null)) {
      if (((new Date(configDate)) <= (new Date(UserLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('config_month_should_be_greater_than_lock_date') + `(` + moment(datePeriodLock).format('MM-DD-YYYY') + ` )`, });
        return "Period Lock"
      }
    } else if ((compFundLockDate != null)) {
      if (((new Date(configDate)) <= (new Date(compFundLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('config_month_should_be_greater_than_fund_lock_date') + `(` + moment(dateFundLock).format('MM-DD-YYYY') + ` )`, });
        return "Fund Lock"
      }
      else {
        return "No locks found";
      }

    }
    else {
      return "No locks found";
    }

  }

  confirmISDConsolidateApprovalData() {
    this.getISDClientApprovalId.emit(this.selectedRows);
  }

  confirmISDAdhocApprovalData() {
    this.getISDClientApprovalId.emit(this.selectedRows);
  }
  updateConsolidateWorkflow() {
    if (this.isDoubleClick) return;
    this.isDoubleClick = true;
    let selectedList = [];
    this.selectedRows.forEach((element: any) => {
      selectedList.push(element.id)
    });

    let requestData = {
      "workflowIdList": this.selectedRows[0].reportDeliveryWorkFlowIdList,
      "workflowGroupName": this.selectedRows[0].groupName,
      "workflowGroupId": this.selectedRows[0].groupId,
      "isdConsolidateEmailConfigurationId": this.selectedRows[0].isdConsolidateEmailConfigurationId,
      "workflowStatus": 'NA',
      "investorsDetailRequired": null
    };
    this.gridService.updateConsolidateWorkflow(requestData).subscribe(res => {
      if (res) {
        this.isDoubleClick = false;
        this.getGridData();
        if (res.code == 1) {
          this.messageService.add({ sticky: true, severity: 'success', summary: 'Success', detail: res.message });
        }
        else {
          this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: res.message });
        }
      }
    })

  }
  maskingTaxId1(input) {
    if (input && input.length > 4) {
      var maskedPart = 'X'.repeat(input.length - 4);
      return input.substr(0, 4) + maskedPart;
    }
    return input;
  }
  generateFS() {
    const dialogRef = this.dialog.open(FsGenerateComponent, {
      panelClass: 'no-padding',
      data: this.selectedRows
    });
    dialogRef.afterClosed().subscribe((confirmed: any) => {

    });
  }


  adhocMarkNaSelectedInvestors() {
    if (this.isDoubleClick) return;
    this.isDoubleClick = true;
    let selectedList = [];
    this.selectedRows.forEach((element: any) => {
      selectedList.push(element.id)
    });
    let requestData = {
      "adhocId": selectedList.toString(),
      "status": 'NA',
      "investorsDetailRequired": null
    };
    this.gridService.approveOrRejectAdhocConfiguration(requestData).subscribe(res => {
      if (res) {
        this.isDoubleClick = false;
        this.getGridData();
        if (res.code == 1) {
          this.messageService.add({ sticky: true, severity: 'success', summary: 'Success', detail: res.message });
        }
        else {
          this.messageService.add({ sticky: true, severity: 'error', summary: 'Error', detail: res.message });
        }
      }
    })

  }


  //editable grid start
  onRowEdit(params) {
    params.node.data.isEditingCancel = false;
    params.node.data.isEditingSave = false;
    params.api.isEditing = true;
    params.api['isEditingLoad'] = true;
    let data = {};
    if (this.componentName == 'taxAdjusted') {
      let obj = this.setuppercentage.getTaxAdjustment();
      // data = {
      //   fundId: obj.fundId,
      //   year: obj.year,
      // }
      this.afterEditableRowAPIList[0].data = {
        fundId: obj.fundId,
        year: obj.year,
      }
      this.afterEditableRowAPIList[2].data = {
        fundId: obj.fundId,
        year: obj.year,
      }
      // this.afterEditableRowAPIList[1].url = this.afterEditableRowAPIList[1].url + obj.fundId
      const fundId = obj.fundId;
      const url = this.afterEditableRowAPIList[1].url;

      if (!url.includes(fundId)) {
        this.afterEditableRowAPIList[1].url = url + fundId;
      }
    } else {
      data = params.node;
    }

    if (this.afterEditableRowAPIList.length) {
      this.gridService.getEditableAPI(this.afterEditableRowAPIList, data).subscribe((response: any) => {
        this.columnDefs.forEach(element => {
          if (element.dependetFields) {
            const dependentFields = element.dependetFields;
            const hasUpdateEditStatus = dependentFields.some(item => 'updateEditStatus' in item);
            if (hasUpdateEditStatus) {
              // Execute this code only when 'updateEditStatus' exists in 'dependentFields'
              this.isDisableValue = dependentFields.some(item =>
                !item.updateEditStatus.includes(params.data[element.field])
              );
            }
          }
          if (element.dependetDisableFields) {
            element.editable = this.isDisableValue;
          }
          if (element.disableFields && !params.data.jePosted && params.data.jeSource == "Income Statement") {
            element.editable = false;
          } else if (element.disableFields && params.data.jeSource == "Manual") {
            element.editable = true;
          }
          let tansactionGroup1 = [
            'Assignment - taxable',
            'Assignment - tax free',
            'Transfer - Taxable',
            'Transfer - Tax free'
          ];
          let tansactionGroup2 = [
            'Redemption - Full',
            'Redemption - Partial - by share or Unit',
            'Redemption - Partial by Amount',
            'Redemption - Partial - by % value of Account',
            'Notional Withdrawal',
            'Distribution - Income',
            'Distribution - capital',
            'Distribution - Principal',
            'Distribution - Interest',
            'Distribution - Return of Capital',
            'Distribution - Return of Capital (Recallable)',
            'Distributions - Others'
          ];
          let tansactionGroup3 = [
            'Capital Call',
            'Capital Call - Expense',
            'Capital Call - Others',
            'Commitment',
            'Notional Addition',
            'Reinvestment',
            'Subscription - First Time',
            'Subscription - Additional'
          ];
          if (this.componentName != 'taxAdjusted') {
            if (tansactionGroup1.includes(data['data']['transactionType'])) {
              element.editable = element.field == "investorNumber" ? false : element.editable;
              element.editable = element.field == "ratioId" ? false : element.editable;

              element.editable = element.field == "effectivedate" ? true : element.editable;
              element.editable = element.field == "timing" ? true : element.editable;
              element.editable = element.field == "dateOfEntry" ? true : element.editable;
              element.editable = element.field == "currency" ? true : element.editable;
              element.editable = element.field == "shares" ? true : element.editable;
              element.editable = element.field == "amount" ? true : element.editable;
              element.editable = element.field == "shareClass" ? true : element.editable;
              element.editable = element.field == "series" ? true : element.editable;

              element.editable = element.field == "fundingMethod" ? false : element.editable;
              element.editable = element.field == "assetBasedFee" ? false : element.editable;

              element.editable = element.field == "performanceFee" ? false : element.editable;

              element.editable = element.field == "reductionInLCF" ? false : element.editable;
              element.editable = element.field == "fundingFactor" ? false : element.editable;
              element.editable = element.field == "fundedCommitmentApplicable" ? false : element.editable;
            }
            if (tansactionGroup2.includes(data['data']['transactionType'])) {
              element.editable = element.field == "effectivedate" ? true : element.editable;
              element.editable = element.field == "timing" ? true : element.editable;
              element.editable = element.field == "dateOfEntry" ? true : element.editable;
              element.editable = element.field == "currency" ? true : element.editable;
              element.editable = element.field == "investorNumber" ? true : element.editable;
              element.editable = element.field == "ratioId" ? true : element.editable;
              element.editable = element.field == "amount" ? true : element.editable;
              element.editable = element.field == "shares" ? true : element.editable;
              element.editable = element.field == "shareClass" ? true : element.editable;
              element.editable = element.field == "series" ? true : element.editable;
              element.editable = element.field == "fundingMethod" ? true : element.editable;

              element.editable = element.field == "assetBasedFee" ? false : element.editable;
              element.editable = element.field == "performanceFee" ? false : element.editable;

              element.editable = element.field == "reductionInLCF" ? true : element.editable;
              element.editable = element.field == "fundingFactor" ? true : element.editable;

              if (data['data']['transactionType'] == "Distribution - Return of Capital (Recallable)") {
                element.editable = element.field == "fundedCommitmentApplicable" ? true : element.editable;
              } else {
                element.editable = element.field == "fundedCommitmentApplicable" ? false : element.editable;
              }
            }
            if (tansactionGroup3.includes(data['data']['transactionType'])) {
              //'Subscription - First Time'
              element.editable = element.field == "effectivedate" ? true : element.editable;
              element.editable = element.field == "timing" ? true : element.editable;
              element.editable = element.field == "dateOfEntry" ? true : element.editable;
              element.editable = element.field == "currency" ? true : element.editable;
              element.editable = element.field == "investorNumber" ? true : element.editable;
              element.editable = element.field == "ratioId" ? true : element.editable;

              element.editable = element.field == "amount" ? true : element.editable;
              element.editable = element.field == "shares" ? true : element.editable;
              element.editable = element.field == "shareClass" ? true : element.editable;
              element.editable = element.field == "series" ? true : element.editable;
              element.editable = element.field == "fundingMethod" ? true : element.editable;
              element.editable = element.field == "fundingFactor" ? true : element.editable;
              element.editable = element.field == "assetBasedFee" ? true : element.editable;
              element.editable = element.field == "performanceFee" ? true : element.editable;
              //'Subscription - First Time'
              element.editable = element.field == "reductionInLCF" ? false : element.editable;
              if (data['data']['transactionType'] == "Capital Call" || data['data']['transactionType'] == "Capital Call - Expense" || data['data']['transactionType'] == "Capital Call - Others") {
                element.editable = element.field == "fundedCommitmentApplicable" ? true : element.editable;
              } else {
                element.editable = element.field == "fundedCommitmentApplicable" ? false : element.editable;
              }
            }
          }
          if (element.editable && element.renderType == 'dropdown') {
            let apiDetails = this.afterEditableRowAPIList.filter(item => {
              return item.updateField.includes(element.updateField)
            })[0];
            if (apiDetails) {
              let index = apiDetails["key"];
              let label = apiDetails["label"];
              let value = apiDetails["value"];
              let fields = apiDetails["fields"];
              let legalName = apiDetails["legalName"];
              let additionalvalue = apiDetails["additionalvalue"];
              let additionalvalue2 = apiDetails["additionalvalue2"];
              let duplicateField = apiDetails["duplicateField"];
              let isAddOnLabel = apiDetails["isAddOnLabel"];
              let isLookup = apiDetails["isLookup"];


              let resultList = (response[index] && Array.isArray(response[index])) ? response[index] :
                (response[index] && response[index].resultList) ? response[index].resultList : (response[index] && response[index].data) ? response[index].data : [];
              let result = [];
              if (fields) {
                resultList.forEach(element => {
                  element.fields.forEach(item => {
                    result.push({ label: item.value, value: isLookup ? element.id : item.value })

                  })
                });
                element["cellEditorParams"].values = result;
              } else if (legalName) {
                resultList?.map(item => {
                  var legalFieldName = item.legalName &&
                    item.legalName['fields'] &&
                    item.legalName['fields'].length &&
                    item.legalName['fields'][0].value ? item.legalName['fields'][0].value : "";
                  var isExistLegalName = result.filter((list) => {
                    return list?.label == legalFieldName;
                  });

                  if (!isExistLegalName.length && legalFieldName) {
                    result.push({
                      label: legalFieldName,
                      value: legalFieldName
                    });
                  }
                })
                element["cellEditorParams"].values = result;
              }
              else if (additionalvalue && additionalvalue2) {
                result = resultList;
                element["cellEditorParams"].values = resultList.map(item => ({ label: isAddOnLabel ? item[label] + ' - ' + item[additionalvalue] : item[label], value: isAddOnLabel ? item[label] + ' - ' + item[additionalvalue] : item[label], id: item[value], name: item[additionalvalue], additionField: item[additionalvalue2] }));
              } else if (element["field"] == duplicateField) {
                result = resultList;
                element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: item[label], name: item[additionalvalue], additionField: item[additionalvalue2] }));
              }
              else if (additionalvalue) {
                result = resultList;
                element["cellEditorParams"].values = resultList.map(item => ({
                  label: item[label],
                  value: item[label],
                  name: item[additionalvalue]
                }));
              }


              else {
                result = resultList;
                element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: value ? item[value] : item.id, name: item["name"] }));
              }
            }
          }
        });
        this.gridApi.setColumnDefs(this.columnDefs);
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      });
    } else {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        // gets the first columnKey
        colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
      });
    }
  }

  onRowEditingSave(params, toogelMode?: boolean) {
    params.node.data.isEditingCancel = false;
    params.node.data.isEditingSave = true;
    params.node.data.skipCellCheck = toogelMode;
    params.api.stopEditing(false);

  }

  onRowEditingCancel(params) {
    params.node.data.isEditingCancel = true;
    params.node.data.isEditingSave = false;
    params.api.stopEditing(true);
  }

  onRowEditingStarted(params) {
    // this.gridFilterService.getEditableAPI(this.afterEditableRowAPIList, params).subscribe((response: any) => {
    //   params.api.refreshCells({
    //     columns: ["action"],
    //     rowNodes: [params.node],
    //     force: true
    //   });
    //   this.gridFilterService.getGridHeader(this.gridHeaders, this.genericdata, (cbs) => {
    //     this.columnDefs = cbs;
    //     this.columnDefs.forEach(element => {
    //       let heder = element.headerName;
    //       if (element.editable && element.renderType == 'dropdown') {
    //         // if (element.cellRenderer == 'actionCellRenderer') {
    //         //   element.cellRenderer = actionCellRenderer
    //         // }
    //         let apiDetails = this.afterEditableRowAPIList.filter(item => { return item.updateField == element.updateField })[0];
    //         if (apiDetails) {
    //           let index = apiDetails["key"];
    //           let label = apiDetails["label"];
    //           let resultList = response[index] && response[index].resultList ? response[index].resultList : response[index] && response[index].length ? response[index] : [];
    //           element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: item.id }));
    //           params.api.refreshCells({
    //             columns: [apiDetails["columns"]],
    //             rowNodes: [params.node],
    //             force: true
    //           });
    //         }
    //       }
    //       this.translateService.get([heder]).subscribe((traval) => {
    //         if (traval[heder] != undefined || traval[heder] != null) {
    //           element.headerName = traval[heder];
    //         }
    //       });
    //     });
    //   }, (cbe) => {
    //   });
    //   this.columnDefs[0]['cellRendererParams'] = {
    //     onRowEdit: this.onRowEdit.bind(this),
    //     onRowSave: this.onRowEditingSave.bind(this),
    //     onRowCancel: this.onRowEditingCancel.bind(this)
    //   }
    // });
    // this.gridApi.refreshCells({
    //   rowNodes: [params.node]
    // });
    params.node.data.isEditing = true;
    params.node.data.isEditingCancel = false;
    params.node.data.isEditingSave = false;
    params.api['isEditingLoad'] = false;
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  onRowEditingStopped(params: CellEditingStoppedEvent) {
    if (this.componentName == 'taxAllocationAdjustmentRatios') {
      setTimeout(function () {
        let data = params.rowIndex
        params.api.getDisplayedRowAtIndex(data).setExpanded(false);
      }, 0);
    }
    if (params.node.data.skipCellCheck) {
      return
    }
    //handle outside click on edit progress
    if (params.api['isEditingLoad']) {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: params.columnApi.getDisplayedCenterColumns()[0]["colId"]
      });
      return;
    }
    //make sure stop editing
    if (!params.node.data.isEditingCancel && !params.node.data.isEditingSave) {
      params.node.data.isEditing = false;
      params.api['isEditing'] = false;
      return;
    }
    //set data
    params.node.data.isEditing = false;
    let componentRequest = this.createReqObj(this.updateRequestArray, params);

    if (componentRequest) {
      let requestData = componentRequest?.["data"];
      if (componentRequest?.formValid && params.node.data.isEditingSave) {
        if (this.periodLockDateAccessor != "no") {
          let dateAccessor = this.periodLockDateAccessor || 'date';
          let date = requestData[dateAccessor];
          let dateArray = date.split("-");
          var effectiveDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
          let fundId: string;
          if (requestData['fund'] && requestData['fund'].id) {
            fundId = requestData['fund'].id
          } else if (requestData['fundName'] && requestData['fundName'].id) {
            fundId = requestData['fundName'].id
          }
          else if (requestData['fundId']) {
            fundId = requestData['fundId']
          }
          if (fundId) {
            var fundPeriodLockDate = this.commonService.getCellFundPeriodLockDate(fundId);
          }
          this.result = this.getEditableLockMsg(effectiveDate, fundPeriodLockDate, this.currentUser.periodLockDate);
        }
        if (this.result != false || this.periodLockDateAccessor == "no") {
          this.updateEditableRow(requestData);
        } else {
          params.api['isEditing'] = false;
          this.getGridData();
          this.gridApi.setFilterModel(null);
        }
      } else if (params.node.data.isEditingSave) {
        params.api['isEditing'] = false;
        if ((this.componentName == "rapidRecurringTransaction" || this.componentName == "recurringTransaction") && componentRequest.differenceAmount) {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('dr_amount_equal_cr_amount') });
        } else {
          this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: "Error", detail: componentRequest.errorMsg });
        }
      } else {
        params.api['isEditing'] = false;
      }
    } else {
      params.api['isEditing'] = false;
      return;
    }

    //update columns
    this.columnDefs.forEach(element => {
      if (element.editable || element.colId == "action") {
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: [element.field],
          suppressFlash: true,
          force: true
        });
      }
    });
  }

  onCellClicked(params) {
    // Handle click event for action cells
    // if (
    //   params.column.colId === "action" &&
    //   params.event.target.dataset.action
    // ) {
    //   let action = params.event.target.dataset.action;

    //   if (action === "edit") {
    //     params.api.startEditingCell({
    //       rowIndex: params.node.rowIndex,
    //       // gets the first columnKey
    //       colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
    //     });
    //   }

    //   if (action === "delete") {
    //     params.api.applyTransaction({
    //       remove: [params.node.data]
    //     });
    //   }

    //   if (action === "update") {
    //     params.api.stopEditing(false);
    //   }

    //   if (action === "cancel") {
    //     params.api.stopEditing(true);
    //   }
    // }

    if (params?.api?.isEditing && this.componentName == 'chartofAccountTaxMappingDetails' && params.column.colId === "seamlessTaxCode") {
      this.openMemoModel.emit({ action: 'chartofAccountTaxMappingDetails', data: params.data });
    } else if (this.componentName == 'chartOfAccounttaxClassification') {
      this.openMemoModel.emit({ action: 'chartOfAccounttaxClassification', data: params.data });
    }
  }

  onCellValueChanged(params: CellValueChangedEvent) {
    if (params.node.data.skipCellCheck) {
      return
    }
    var colId = params.column.getId();
    let userProvidedColDef = params.column['userProvidedColDef'];
    let updateField = userProvidedColDef.updateField;
    let dropdownField = userProvidedColDef.dropdownField;
    if (dropdownField) {
      this.updatedColumnData[updateField] = params.data[dropdownField];
    } else {
      this.updatedColumnData[updateField] = params.data[colId];
    }

    const dependentFields = userProvidedColDef.dependetFields || [];
    if (dependentFields.length) {
      this.onRowEditingSave(params, true);
      this.dependentCellUpdate(colId, params);
    }
  }

  dependentCellUpdate(colId, params) {
    // if (this.dependentAPIEditableRowAPIList[colId]) {
    if (this.dependentAPIEditableRowAPIList.length) {
      console.log("params.node:::", params.node);
      let data = params.node;
      let userProvidedColDef = params.column['userProvidedColDef'];
      const dependentFields = userProvidedColDef.dependetFields || [];

      this.gridService.getEditableAPI(this.dependentAPIEditableRowAPIList, data).subscribe((response: any) => {
        this.columnDefs.forEach(element => {
          if (dependentFields.length) {
            dependentFields.forEach(item => {
              if (element.dependetDisableFields) {
                element.editable = !item.updateEditStatus.includes(params.data[colId]);
                //  params.data[element.field] = !item.updateEditStatus.includes(params.data[colId]) ? params.data[element.field] : null;
              }
            });
          }
          if (element.editable && element.renderType == 'dropdown') {
            let apiDetails = this.dependentAPIEditableRowAPIList.filter(item => {
              return item.updateField.includes(element.updateField)
            })[0];
            if (apiDetails) {
              let index = apiDetails["key"];
              let label = apiDetails["label"];
              let value = apiDetails["value"];
              let fields = apiDetails["fields"];
              let additionalvalue = apiDetails["additionalvalue"];

              let resultList = (response[index] && Array.isArray(response[index])) ? response[index] :
                (response[index] && response[index].resultList) ? response[index].resultList : (response[index] && response[index].data) ? response[index].data : [];
              let result = [];
              if (fields) {
                resultList.forEach(element => {
                  element.fields.forEach(item => {
                    result.push({ label: item.value, value: item.value })
                  })
                });
                element["cellEditorParams"].values = result;
              }
              else if (additionalvalue) {
                result = resultList;
                element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: item[label], name: item[additionalvalue] }));
              }
              else {
                result = resultList;
                element["cellEditorParams"].values = resultList.map(item => ({ label: item[label], value: value ? item[value] : item.id, name: item["name"] }));
              }
            }
          }
        });
        this.gridApi.setColumnDefs(this.columnDefs);
        params.node.data.isEditingCancel = false;
        params.node.data.isEditingSave = false;
        params.node.data.skipCellCheck = false;
        params.api.isEditing = true;
        params.api['isEditingLoad'] = true;
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      });
    } else {
      this.gridApi.setColumnDefs(this.columnDefs);
      params.node.data.isEditingCancel = false;
      params.node.data.isEditingSave = false;
      params.node.data.skipCellCheck = false;
      params.api.isEditing = true;
      params.api['isEditingLoad'] = true;
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        // gets the first columnKey
        colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
      });
    }
  }

  updateByForm(data) {
    this.updateApiResponse = data;
    this.fetchGridData();
  }

  fetchGridData() {
    this.gridApi.forEachNode((rowNode, index) => {
      if (rowNode.data.id == this.updateApiResponse.id) {
        rowNode.updateData(this.updateApiResponse);
        rowNode.setData(this.updateApiResponse);
        this.gridApi.applyTransaction({ update: [this.updateApiResponse] });
        this.gridApi.refreshCells({ force: true });
      }
    });
    this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
    this.gridApi['isEditing'] = false;
  }

  updateRowData(data) {
    this.updateApiResponse = data;
    if (this.updateApiResponse?.id) {
      this.gridApi.forEachNode((rowNode, index) => {
        if (rowNode.data.id == this.updateApiResponse.id) {
          rowNode.updateData(this.updateApiResponse);
          rowNode.setData(this.updateApiResponse);
          this.gridApi.applyTransaction({ update: [this.updateApiResponse] });
          this.gridApi.refreshCells({ force: true });
        }
      });
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
      this.gridApi['isEditing'] = false;
    }
    else if (this.componentNamesForRowUpdate.includes('fschartOfAccountsRapid') || this.componentNamesForRowUpdate.includes('fsCashEquivalents')) {
      this.gridApi.forEachNode((rowNode, index) => {
        if (rowNode.data.id == this.updateApiResponse?.data?.id) {
          rowNode.updateData(this.updateApiResponse?.data);
          rowNode.setData(this.updateApiResponse?.data);
          this.gridApi.applyTransaction({ update: [this.updateApiResponse?.data] });
          this.gridApi.refreshCells({ force: true });
        }
      });
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
      this.gridApi['isEditing'] = false;
    }
    else {
      this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
      this.gridApi['isEditing'] = false;
      this.setupPercentageService.setIsJECreated(false)
      this.gridApi.setFilterModel(null);
      this.getGridData();
    }
  }

  updateEditableRow(requestData) {
    let updateUrl = requestData.isCreated ? apiConstants[this.componentName].createUrl.url : apiConstants[this.componentName].updateUrl;
    let method = requestData.isCreated ? apiConstants[this.componentName].createUrl.method : 'put';
    let securityMaster = ["rapidSmEquitiesList", 'RapidSmOptionsList', 'rapidSmFxForwardList', 'rapidSmFixedIncomeList', 'RapidSmFutureList', 'RapidSmPrivateFundList', 'rapidCrypto', 'RapidSmPools'];
    let dataToSave;
    if (securityMaster.includes(this.componentName)) {
      Object.keys(requestData).forEach((key) => {
        const dateFields = ["dataSourcePriorityName", "pricingSourceIdsList", "pricingSourceList", 'exDate', 'entryDate', 'payDate']
        if (!dateFields.includes(key)) {
          if (requestData[key] === "" || requestData[key] === null ||
            (typeof (requestData[key]) === 'object' && Object.keys(requestData[key]).length === 0)) {
            delete requestData[key];
          }
        }
      });
      requestData["createdDate"] = Date.parse(requestData['createdDate']);
      if (this.componentName == 'RapidSmPrivateFundList' || this.componentName == 'RapidSmPools') {
        dataToSave = JSON.stringify(requestData)
      }
      else if (this.componentName == 'rapidDividend' || this.componentName == "rapidSetupbondinterest") {
        dataToSave = requestData;
      }
      else {
        dataToSave = btoa(JSON.stringify(requestData))
      }
    }
    else if (this.componentName == "fSInvestor") {
      let fsConfigData = this.gridService.getConfigData();
      requestData["configId"] = fsConfigData['configId']
      dataToSave = requestData;
    }
    else {
      dataToSave = requestData;
    }

    this.gridService.updateData(updateUrl, dataToSave, method).subscribe((response: any) => {
      this.isJECreated = this.componentName == 'taxAdjusted' ? true : false;
      this.updateApiResponse = response;
      if ((this.componentName == 'taxK1BoxConfiguration' && response.message == "Combination of 'Tax Year' and 'K-1 Sub-Box Number & Item' already exists.") || (this.componentName == 'K1Merging' && response.message == "Investor can't be changed to 'Secondary' if already mapped. Please remove the mapping to change the investor 'K-1 Type'.")) {
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translateService.instant('failed'), detail: this.translateService.instant(response.message) });
        this.gridApi['isEditing'] = false;
        this.gridApi.setFilterModel(null);
        this.getGridData();
      }
      else if (this.componentName == 'rapidTradeList' || this.componentName == 'rapidExchangerate' || this.componentName == 'customizationReportApproval') {
        this.gridApi.forEachNode((rowNode, index) => {
          if (rowNode.data.id == this.updateApiResponse.id) {
            rowNode.updateData(this.updateApiResponse);
            rowNode.setData(this.updateApiResponse);
            this.gridApi.applyTransaction({ update: [this.updateApiResponse] });
            this.gridApi.refreshCells({ force: true });
          }
        });
        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
        this.gridApi['isEditing'] = false;
        // this.gridApi.setFilterModel(null);
      }
      else if (this.componentNamesForRowUpdate.includes(this.componentName)) {
        this.updateRowData(response);
      }
      // else if (this.componentName == "rapidCapitalActivities") {
      //   this.updateRowCapitalActivity(response);
      // }
      else {
        this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translateService.instant('success'), detail: this.translateService.instant('record_updated') });
        this.gridApi['isEditing'] = false;
        this.setupPercentageService.setIsJECreated(false)
        this.gridApi.setFilterModel(null);
        this.getGridData();
      }

    }, (error) => {
      this.gridApi['isEditing'] = false;
      this.isJECreated = this.componentName == 'taxAdjusted' ? true : false;
      this.setupPercentageService.setIsJECreated(false)
      this.gridApi.setFilterModel(null);
      this.getGridData();
    });
  }

  updateRowCapitalActivity(data) {
    this.updateApiResponse = data;
    if (this.updateApiResponse && this.updateApiResponse?.id) {
      this.gridApi.forEachNode((rowNode, index) => {
        if (rowNode.data.id == this.updateApiResponse?.id) {
          const {
            investor
          } = this.updateApiResponse;
          this.updateApiResponse = {
            ...this.updateApiResponse, investorNumber: investor?.investorNumber,
          }; rowNode.updateData(this.updateApiResponse);
          this.gridApi.applyTransaction({ update: [this.updateApiResponse] });
          this.filteredData = this.gridApi.rowModel.rowsToDisplay;
        }
      });
    } else {
      this.getGridData();
    }

  }

  createReqObj(updateRequestArray, params) {
    let nodeData = params.node.data;
    let editReqData = {};
    let gridReqData = {};
    let formValid = true;
    let differenceAmount = false;
    let errorMsg = "Please fill required fields";
    if (this.componentName == "K1Merging") {
      if (params.data.k1Type == 'Secondary') {
        nodeData['secondaryInvestorId'] = params.data.investorId;
        nodeData['secondaryInvestorNumber'] = params.data.investorNumber;

      } else if (params.data.k1Type == 'Primary') {
        nodeData['secondaryInvestorId'] = '';
        nodeData['secondaryInvestorNumber'] = '';
        nodeData['primaryInvestorId'] = params.data.investorId;
        nodeData['primaryInvestorNumber'] = params.data.investorNumber;
      }
    }
    if (this.componentName == "taxFundList") {
      if (params.data.typeOfTaxDocId) {
        nodeData['taxDocLookup'] = nodeData['taxDocLookup'] ?? {};
        nodeData['typeOfTaxDoc'] = nodeData['typeOfTaxDoc'] ?? {};
        nodeData['taxDocLookup'].id = params.data.typeOfTaxDocId;
        nodeData['taxDocLookup'] = { id: params.data.typeOfTaxDocId };
        nodeData['typeOfTaxDoc'] = { id: params.data.typeOfTaxDocId };
      }
      if (params.data.taxAccountingMethodId) {
        nodeData['accountingMethodLookup'] = nodeData['accountingMethodLookup'] ?? {};
        nodeData['taxAccountingMethod'] = nodeData['taxAccountingMethod'] ?? {};
        nodeData['accountingMethodLookup'] = { id: params.data.accountingMethodLookup.id };
        nodeData['taxAccountingMethod'] = { id: params.data.accountingMethodLookup.id };
      }
    }

    if (this.componentName == "chartofAccountTaxMappingDetails") {
      if (params.data['typeOfGLAccount'] === "Expense" || params.data['typeOfGLAccount'] === "Income" || params.data['typeOfGLAccount'] === "Expenses" || params.data['typeOfGLAccount'] === "Contra") {
        nodeData['bookToTax'] = "No"
      }
      nodeData['manualCOA'] = true
      nodeData['createdThrough'] = "Manually"
    }
    if (this.componentName == "taxchartOfAccounts") {
      if (params.data['typeOfGLAccount'] === "Expense" || params.data['typeOfGLAccount'] === "Income" || params.data['typeOfGLAccount'] === "Expenses" || params.data['typeOfGLAccount'] === "Contra") {
        nodeData['bookToTax'] = "No"
      } else {
        nodeData['bookToTax'] = params.data.bookToTax
      }
    }

    if (this.componentName == 'taxFundList') {
      const validateTaxId = (taxId: string) => {
        if (taxId && taxId.length !== 0 && taxId.length !== 9) {
          this.messageService.add({
            closable: true, sticky: true, severity: 'warn', summary: this.translateService.instant('Warning'),
            detail: 'Id should contain 9 digits. Please check again.'
          });
          return false;
        }
        return true;
      };

      if (!validateTaxId(params.data.taxId1) || !validateTaxId(params.data.taxId2) || !validateTaxId(params.data.taxId3)) {
        return;
      }
    }


    if (this.componentName == 'taxInvestorsList') {

      nodeData['inactiveFrom'] = new Date(0).toISOString();
      nodeData['inactiveTo'] = new Date('December 31, 2099 15:00:00').toISOString();
      const validateTaxId = (taxId: string) => {
        if (taxId && taxId.length !== 0 && taxId.length !== 9) {
          this.messageService.add({
            closable: true, sticky: true, severity: 'warn', summary: this.translateService.instant('Warning'),
            detail: 'Id should contain 9 digits. Please check again.'
          });
          return false;
        }
        return true;
      };

      if (!validateTaxId(params.data.taxId1) || !validateTaxId(params.data.taxId2)) {
        return;
      }
    }


    if (this.componentName == 'taxAllocationAdjustmentRatios') {
      let data = []
      params.data.investorAllocationList.forEach(element => {
        data.push(Number(element.allocationRatio))
      })
      let allocationRatioTotal = data.reduce((m, a) => m + a, 0);
let allocationRatioTotalvalue = allocationRatioTotal.toFixed(2);
      if (allocationRatioTotalvalue != 100.00) {
        this.messageService.add({ closable: true, sticky: true, severity: 'warn', detail: "Total of 'Allocation Ratio' should be equal to 100." });
        return
      }
      if (params.data['ratioIdDescription'].length > 100) {
        this.messageService.add({ closable: true, sticky: true, severity: 'warn', detail: "Length of Ratio ID Description can't exceed 100 characters." });
        return
      }
      params.data.investorAllocationList.forEach(element => {

        delete element['investorNameAndNumber']

      })
      delete nodeData['CustaccountNumber']
      delete nodeData['createdBy']
      delete nodeData['createdOn']
      delete nodeData['cust_Tag']
      delete nodeData['enterpriseId']
      delete nodeData['enterpriseName']
      delete nodeData['executionLocked']
      delete nodeData['finalSequence']
      delete nodeData['inputMode']
      delete nodeData['isPreviewData']
      delete nodeData['updatedBy']
      delete nodeData['updatedOn']
      delete nodeData['uploadData']

    }
    if (this.componentName == "taxAdjusted") {
      let fundata = this.setuppercentage.getTaxAdjustment();
      nodeData["fundId"] = fundata.fundId;
      nodeData["fundName"] = fundata.fundName;
      nodeData["year"] = fundata.year;
      nodeData["fromCOA"] = params.data.fromCOA;
      nodeData["ratioId"] = nodeData["investorNameAndNumber"] ? null : params?.data?.ratioIdAndDescription?.replace(/\s+/g, '').split('-')[0].trimStart(),
        nodeData["ratioIdDescription"] = (nodeData["investorNameAndNumber"] ? null : params?.data?.ratioIdAndDescription?.split('-')[1].trimStart()) || null,

        nodeData["toCOA"] = params.data.toCOA;
      nodeData["investorName"] = params.data.investorNameAndNumber?.includes('-')
        ? params.data.investorNameAndNumber.split('-')[1].trimStart()
        : params.data.investorNameAndNumber;
      //  errorMsg = requestObject.errorMsg;
    }
    if (this.componentName == "rapidCapitalActivities") {
      let tansactionGroup1 = ['Transfer - Taxable', 'Assignment - taxable', 'Assignment - tax free', 'Transfer - Tax free'];
      //pre define grid data as request data
      Object.keys(nodeData).forEach(x => {
        if (!nodeData[x]) {
          gridReqData[x] = "";
        } else if (Array.isArray(nodeData[x])) {
          if (nodeData[x].length) {
            // let data = [];
            // if (typeof nodeData[x][0] == 'object' && nodeData[x][0] != null && !Array.isArray(nodeData[x][0])) {
            //   nodeData[x].forEach(element => {
            //     data.push({ id: element.id });
            //   });
            // } else {
            //   nodeData[x].forEach(element => {
            //     data.push(element);
            //   });
            // }
            gridReqData[x] = nodeData[x];
          } else {
            gridReqData[x] = [];
          }
        } else if (typeof nodeData[x] === 'object') {
          let id = nodeData[x].id || ''
          gridReqData[x] = { id: id };
        } else {
          gridReqData[x] = nodeData[x];
        }
      });
      for (var key in gridReqData) {
        if (gridReqData.hasOwnProperty(key)) {
          if (!gridReqData[key])
            delete gridReqData[key];
        }
      }
      //get editable field request data 
      let gridExcludeParams: any;
      updateRequestArray.forEach(requestData => {
        if (requestData["type"] == "gridExclude") {
          gridExcludeParams = requestData["fields"];
          // requestData["fields"].forEach(element => {
          //   if (nodeData[element]) {
          //     editReqData[element] = nodeData[element] || "";
          //   }
          // });
        } else if (requestData["type"] == "array") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = Array.isArray(nodeData[requestData["dataAccessor"]]) ? nodeData[requestData["dataAccessor"]] : [nodeData[requestData["dataAccessor"]]];
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else if (requestData["type"] == "object") {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = { id: nodeData[requestData["dataAccessor"]] };
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
          if (requestData["dataAccessor"] == "fund" && nodeData.fund) {
            editReqData[requestData["updateField"]] = { id: nodeData.fund.id };
          }
          if (requestData["dataAccessor"] == "fundName" && nodeData.fundName) {
            editReqData[requestData["updateField"]] = { id: nodeData.fundName.id };
          }
        } else if (requestData["type"] == "file") {
          editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]] || null;
        } else if (requestData["type"] == "date") {
          if (nodeData[requestData["dataAccessor"]] && nodeData[requestData["dataAccessor"]] != "Invalid date") {
            let date = nodeData[requestData["dataAccessor"]];
            editReqData[requestData["updateField"]] = moment(date).format('YYYY-MM-DD');
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else if (requestData["type"] == "number") {
          if (nodeData[requestData["dataAccessor"]]) {
            let min = requestData["min"];
            if ((min || min === 0) && nodeData[requestData["dataAccessor"]] < min) {
              formValid = false;
              errorMsg = requestData["updateField"] + " value can't be less than " + min;
            } else {
              editReqData[requestData["updateField"]] = Number(nodeData[requestData["dataAccessor"]]);
            }
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else {
          if (nodeData[requestData["dataAccessor"]]) {
            editReqData[requestData["updateField"]] = nodeData[requestData["dataAccessor"]];
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
      });
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete gridReqData[gridExcludeParams[key]];
        }
      }
      //merge editable data and grid data
      Object.assign(gridReqData, editReqData);
      delete gridReqData['fetch'];
      delete gridReqData['otherInfoSubLabel'];
      delete gridReqData['transaactionLabel'];
      delete gridReqData['chooseInvestor'];
      delete gridReqData['supportFiles'];
      gridReqData['updatedBy'] = this.currentUser['id'];
      gridReqData['transferor'] = gridReqData['transferor'] ? { id: gridReqData['transferor'].id } : null;
      gridReqData['transferee'] = gridReqData['transferee'] ? { id: gridReqData['transferee'].id } : null;
      gridReqData['investor'] = gridReqData['investor'] || null;
      gridReqData['transactionType'] = gridReqData['transactionType'];
      gridReqData['fundingFactor'] = gridReqData['fundingFactor'] || 1;
      if (gridReqData['dateOfEntry']) {
        gridReqData['dateOfEntry'] = moment(gridReqData['dateOfEntry'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else {
        gridReqData['dateOfEntry'] = '';
      }
      if (gridReqData['effectivedate']) {
        gridReqData['effectivedate'] = moment(gridReqData['effectivedate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
      } else {
        gridReqData['effectivedate'] = "";
      }
      gridReqData['amount'] = gridReqData['amount'] || gridReqData['amountValue'];
      gridReqData['amount'] = Number(gridReqData['amount']);
      if (gridReqData['shares'] > 0 || gridReqData['transferShares'] > 0) {
        gridReqData['shares'] = gridReqData['shares'] || gridReqData['transferShares'];
      }
      gridReqData['transferstatus'] = gridReqData['transferstatus'] || "No";
      // for testing
      gridReqData['accountNumber'] = gridReqData['accountNumber'] || [];
      gridReqData['performanceFeeCrystalized'] = gridReqData['performanceFeeCrystalized'] || gridReqData['tperformanceFeeCrystalized'];
      if (!tansactionGroup1.includes(gridReqData['transactionType'])) {
        gridReqData['investorName'] = gridReqData['investorName'];
      }
      if (tansactionGroup1.includes(gridReqData['transactionType'])) {
        gridReqData['perValueOfAc'] = [];
      }
      delete gridReqData['transferSeries'];
      delete gridReqData['transfereeSeries'];
      delete gridReqData['transferShareClass'];
      delete gridReqData['transfereeShareClass'];
      delete gridReqData['transferShareClass'];
      delete gridReqData['transfereeShareClass'];
      delete gridReqData['investorNumber'];
      delete gridReqData['transferShares']
      delete gridReqData['transfereeAccountNumber']
      delete gridReqData['transfereeAmount']
      delete gridReqData['transfereeDistributionBase']
      delete gridReqData['transfereeHurdlebase']
      delete gridReqData['transfereeManagementFeeBase']
      delete gridReqData['transfereeNumber']
      delete gridReqData['transfereePerValueOfAc']
      delete gridReqData['transfereeRorinception']
      delete gridReqData['transfereeRormonth']
      delete gridReqData['transfereeRorquarter']
      delete gridReqData['transfereeRorweek']
      delete gridReqData['transfereeRoryear']
      delete gridReqData['transfereeShares']
      delete gridReqData['transfereeTransferHWM']
      delete gridReqData['transfereeperPriorDayPerformancefee']
      delete gridReqData['transfereeperformanceFeeBase']
      delete gridReqData['tperformanceFeeCrystalized']
      delete gridReqData['TransferorInvestorNumber']
      delete gridReqData['transferPerValueOfAc']
      if (!tansactionGroup1.includes(gridReqData['transactionType'])) {
        delete gridReqData['transferPrecentage']
      }
      delete gridReqData['amountValue'];
      delete gridReqData['fetchTransfer'];
      delete gridReqData['Transferee'];
      delete gridReqData['transfereeassetBaseFeeOnIncome'];
      delete gridReqData['transfereecumulativeIncome'];
      delete gridReqData['transfereehurdleAmount'];
      delete gridReqData['Transferor'];
      for (var key in gridReqData) {
        if (gridReqData.hasOwnProperty(key)) {
          if (!gridReqData[key])
            delete gridReqData[key];
        }
      }
      gridReqData['investor'] = gridReqData['investor'] || null;
      gridReqData['fundingMethod'] = gridReqData['fundingMethod'] || "";
      gridReqData['assetBasedFee'] = gridReqData['assetBasedFee'] || "";
      gridReqData['eitherORMethod'] = gridReqData['eitherORMethod'] || "";
      gridReqData['oldAccNumber'] = gridReqData['oldAccNumber'] || "";
      if (gridReqData['shares'] != 0) {
        gridReqData['shares'] = gridReqData['shares'] || null;
      }
      gridReqData['performanceFee'] = gridReqData['performanceFee'] || "";
      gridReqData['redemptionFee'] = gridReqData['redemptionFee'] || "";
      gridReqData['reductionInLCF'] = gridReqData['reductionInLCF'] || "";
      gridReqData['fundedCommitmentApplicable'] = gridReqData['fundedCommitmentApplicable'] || "No";
      gridReqData['custodianAccNumber'] = gridReqData['custodianAccNumber'] || null;
      gridReqData['custodianAccId'] = gridReqData['custodianAccId'] || null;
    }
    else if (this.componentName == "rapidSetupbondinterest") {
      //pre define grid data as request data
      gridReqData = this.getGridReqData(nodeData);
      //get editable field request data
      let requestObject = this.getEditReqData(updateRequestArray, nodeData);
      let gridExcludeParams = requestObject.gridExcludeParams;
      formValid = requestObject.formValid;
      editReqData = requestObject.editReqData;
      errorMsg = requestObject.errorMsg;
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete gridReqData[gridExcludeParams[key]];
        }
      }
      Object.keys(gridReqData).forEach((key) => {
        if (gridReqData[key] === "" || gridReqData[key] === null ||
          (typeof (gridReqData[key]) === 'object' && Object.keys(gridReqData[key]).length === 0)) {
          delete gridReqData[key];
        }
      });

    }
    else if (this.componentName == "rapidInvestors" || this.componentName == "setupInvestorsList") {
      //pre define grid data as request data
      gridReqData = this.getGridReqData(nodeData);
      //get editable field request data
      let requestObject = this.getEditReqData(updateRequestArray, nodeData);
      let gridExcludeParams = requestObject.gridExcludeParams;
      formValid = requestObject.formValid;
      editReqData = requestObject.editReqData;
      errorMsg = requestObject.errorMsg;
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete gridReqData[gridExcludeParams[key]];
        }
      }
      //merge editable data and grid data
      Object.assign(gridReqData, editReqData);
      gridReqData["swiftcode"] = gridReqData["swiftcode"] || "";
      gridReqData["wiringInstruction"] = gridReqData["wiringInstruction"] || "";
      gridReqData["taxScheduleDeliveryName"] = gridReqData["fund"] || "";
      gridReqData["passwordPreference"] = gridReqData["passwordPreference"] || "No";
    } else if (this.componentName == "rapidRecurringTransaction" || this.componentName == "recurringTransaction") {
      //pre define grid data as request data
      gridReqData = this.getGridReqData(nodeData);
      //get editable field request data
      let requestObject = this.getEditReqData(updateRequestArray, nodeData);
      let gridExcludeParams = requestObject.gridExcludeParams;
      formValid = requestObject.formValid;
      editReqData = requestObject.editReqData;
      errorMsg = requestObject.errorMsg;
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete gridReqData[gridExcludeParams[key]];
        }
      }
      if (this.componentName == "customizationReportApproval") {
        gridReqData = {};
      }
      //merge editable data and grid data
      Object.assign(gridReqData, editReqData);
      gridReqData["enterpriseId"] = this.currentUser['enterpriseId'];
      gridReqData["updatedBy"] = this.currentUser['id'];
      if (gridReqData['insertedOn']) {
        let strDate = gridReqData['insertedOn'].substring(0, gridReqData['insertedOn'].length - 1);
        gridReqData['insertedOn'] = Date.parse(strDate);
      }
      if (gridReqData['updatedOn']) {
        let strDate = gridReqData['updatedOn'].substring(0, gridReqData['updatedOn'].length - 1);
        gridReqData['updatedOn'] = Date.parse(strDate);
      }
      if (gridReqData["drAmount1"] != gridReqData["crAmount1"]) {
        differenceAmount = true;
        formValid = false;
      }
    } else {
      //pre define grid data as request data
      gridReqData = this.getGridReqData(nodeData);
      //get editable field request data
      let requestObject = this.getEditReqData(updateRequestArray, nodeData);
      let gridExcludeParams = requestObject.gridExcludeParams;
      formValid = requestObject.formValid;
      editReqData = requestObject.editReqData;
      errorMsg = requestObject.errorMsg;
      if (gridExcludeParams) {
        for (var key in gridExcludeParams) {
          delete gridReqData[gridExcludeParams[key]];
        }
      }
      if (this.componentName == "customizationReportApproval") {
        gridReqData = {};
      }
      //merge editable data and grid data
      Object.assign(gridReqData, editReqData);
      gridReqData["enterpriseId"] = this.currentUser['enterpriseId'];
      gridReqData["updatedBy"] = this.currentUser['userName'];
      // 1
      // if (this.gridData['updatedOn']) {
      //   let strDate = this.gridData['updatedOn'].substring(0, this.gridData['updatedOn'].length - 1);
      //   gridReqData['updatedOn'] = Date.parse(strDate);
      // }
      // 2
      if (gridReqData['createdOn']) {
        gridReqData['createdOn'] = Date.parse(gridReqData['createdOn']);
      }
      if (gridReqData['insertedOn']) {
        gridReqData['insertedOn'] = Date.parse(gridReqData['insertedOn']);
      }
      // 3
      if (gridReqData['updatedOn']) {
        gridReqData["updatedOn"] = Date.parse(moment(new Date()).format('MM-DD-YYYY'));
      }
      if (this.componentName == "fSInvestor") {
        if (gridReqData['investorForFIHI'] != "Y" && gridReqData['investorForFIHI'] != "N") {
          formValid = false;
          errorMsg = "Invalid values at Investor for FIHI."
        }
        if (nodeData['affiliatedInvestor'] != "Y" && nodeData['affiliatedInvestor'] != "N") {
          nodeData['affiliatedInvestor'] = "N"
          gridReqData['affiliatedInvestor'] = "N"
          formValid = true;
          // errorMsg = "Invalid Input.Please correct the value to Y or N"
        }
      }
      if (this.componentName == "fSPortfolio") {
        if (nodeData['interestRate'] <= 0) {
          nodeData['interestRate'] = ""
        }
      }
      // gridReqData["updatedOn"] = moment(new Date()).utc().format();
      // 5
      //gridReqData["updatedOn"] = moment(this.gridData["updatedOn"]).format('YYYY-MM-DD')
    }
    let requestData = {
      formValid: formValid,
      differenceAmount: differenceAmount,
      errorMsg: errorMsg,
      data: gridReqData //editReqData
    }
    return requestData;
  }

  getGridReqData(nodeData) {
    let gridReqData = {};
    //pre define grid data as request data
    Object.keys(nodeData).forEach(x => {
      if (Array.isArray(nodeData[x])) {
        if (nodeData[x].length) {
          gridReqData[x] = nodeData[x];
        } else {
          gridReqData[x] = [];
        }
      } else if (typeof nodeData[x] === 'object' && nodeData[x] !== null) {
        let id = nodeData[x].id || ''
        gridReqData[x] = { id: id };
      } else {
        gridReqData[x] = nodeData[x];
      }
    });

    return gridReqData;
  }

  getEditReqData(updateRequestArray, nodeData) {
    let editReqData = {};

    let gridExcludeParams: any;
    let formValid = true;
    let errorMsg = "Please fill required fields";
    if (this.componentName === "customizationReportApproval") {
      editReqData["fund"] = { "id": nodeData.fundId };
      editReqData["investorCapitalReportingType"] = nodeData.investorCapitalReportingType;
      editReqData["decimal"] = Number(nodeData.decimal);
      editReqData["reportApprovalDate"] = nodeData.reportApprovalDate;
      editReqData["servicetextcenter"] = null;
      editReqData["dtd"] = nodeData.dtd;
      editReqData["mtd"] = nodeData.mtd;
      editReqData["qtd"] = nodeData.qtd;
      editReqData["ytd"] = nodeData.ytd;
      editReqData["itd"] = nodeData.itd;
      editReqData["enterpriseId"] = nodeData;
      editReqData["updatedBy"] = nodeData;
      editReqData["fundId"] = nodeData.fundId;
      editReqData["fundName"] = nodeData.fundName;
      editReqData["id"] = nodeData.id
      if (!nodeData.investorCapitalReportingType || !nodeData.reportApprovalDate) {
        formValid = false;
      }
      return {
        editReqData: editReqData,
        gridExcludeParams: gridExcludeParams,
        formValid: formValid,
        errorMsg: errorMsg
      }
    }
    updateRequestArray.forEach(requestData => {
      if (requestData["type"] == "gridExclude") {
        gridExcludeParams = requestData["fields"];
      } else {
        let selectedNodeData = this.getValueByPath(nodeData, requestData["dataAccessor"]);
        if (requestData["type"] == "array") {
          if (selectedNodeData) {
            editReqData[requestData["updateField"]] = Array.isArray(selectedNodeData) ? selectedNodeData : [selectedNodeData];
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else if (requestData["type"] == "object") {
          if (selectedNodeData) {
            editReqData[requestData["updateField"]] = { id: selectedNodeData };
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
          if (requestData["dataAccessor"] == "fund" && nodeData.fund) {
            editReqData[requestData["updateField"]] = { id: nodeData.fund.id };
          }
          if (requestData["dataAccessor"] == "fundName" && nodeData.fundName) {
            editReqData[requestData["updateField"]] = { id: nodeData.fundName.id };
          }
        } else if (requestData["type"] == "file") {
          editReqData[requestData["updateField"]] = selectedNodeData || null;
        } else if (requestData["type"] == "date") {
          if (selectedNodeData && selectedNodeData != "Invalid date") {
            let date = selectedNodeData;
            let format = requestData["format"] || 'YYYY-MM-DD';
            editReqData[requestData["updateField"]] = moment(date).format(format);
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else if (requestData["type"] == "number") {
          // if (selectedNodeData) {
          //   editReqData[requestData["updateField"]] = Number(selectedNodeData);
          // } else {
          //   formValid = requestData["required"] ? false : formValid;
          //   if (requestData["emptyAccessor"] != "none")
          //     editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          // }
          if (selectedNodeData) {
            let min = requestData["min"];
            if ((min || min === 0) && selectedNodeData < min) {
              formValid = false;
              errorMsg = requestData["updateField"] + " value can't be less than " + min;
            } else {
              editReqData[requestData["updateField"]] = Number(selectedNodeData);
            }
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        } else {
          if (selectedNodeData) {
            editReqData[requestData["updateField"]] = selectedNodeData;
          } else {
            formValid = requestData["required"] ? false : formValid;
            if (requestData["emptyAccessor"] != "none")
              editReqData[requestData["updateField"]] = requestData["emptyAccessor"];
          }
        }
      }
    });

    return {
      editReqData: editReqData,
      gridExcludeParams: gridExcludeParams,
      formValid: formValid,
      errorMsg: errorMsg
    }
  }
  getTaxMappingData() {
    this.configIdMapping = this.setuppercentage.getTaxGridData();
    let Url = 'coaTaxClassification/coaRecords/' + this.configIdMapping + '/' + this.page;
    this.gridService.getGridData(Url, (cbs) => {
      this.rowData = cbs.resultList;
      this.gridApi.setRowData(this.rowData);
      this.mappingDetails['fundName'] = cbs?.fundName;
      this.mappingDetails['amendedReturnAndScheduleK1'] = cbs?.amendedReturnAndScheduleK1;
      this.mappingDetails['configTaxYear'] = cbs?.configTaxYear;
      this.setuppercentage.setMappingDataTrigger(this.mappingDetails);
      this.totalCount = cbs.totalCount;
      this.totalPages = Math.ceil(cbs.totalCount / 100) || 1;
      if (!this.isFryFilterAct) {
        this.totalRecords = cbs.totalCount;
      }
    }, (cbe) => {
    },)
  }
  getValueByPath(obj, path) {
    let current = obj;
    for (const key of path.split('.')) {
      if (current && current.hasOwnProperty(key)) {
        current = current[key];
      } else {
        return null;
      }
    }
    return current;
  }

  getEditableLockMsg(date, FundLockDate, UserLockDate) {
    if ((FundLockDate != null) && (UserLockDate != null)) {
      if (((new Date(FundLockDate)) >= (new Date(UserLockDate))) &&
        ((new Date(date)) <= (new Date(FundLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_fund_lock_date') + `(` + moment(FundLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      } else if (((new Date(FundLockDate)) <= (new Date(UserLockDate))) &&
        ((new Date(date)) <= (new Date(UserLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_lock_date') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    } else if ((this.currentUser.periodLockDate != null)) {
      if (((new Date(date)) <= (new Date(UserLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_lock_date') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    } else if ((FundLockDate != null)) {
      if (((new Date(date)) <= (new Date(FundLockDate)))) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('error'), detail: this.translateService.instant('date_should_be_greater_than_fund_lock_date') + `(` + moment(FundLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    }
  }
}
