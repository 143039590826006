/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
 
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// import { apiConstants } from 'src/app/constants/apiConstants';

@Injectable({
  providedIn: 'root'
})
export class UploadService {


  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(
    private http: HttpClient,
  ) { }

  fetchAllCustomTypes(url, cbs) {
    this.http.get(url,this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
      
      })
  }
  createCustom(data): Observable<any> {
    return this.http.post<any>('createDefaultConfigModulebase/vendorUpload', data);
    }
    
    createCustomDefault(data): Observable<any> {
      return this.http.post<any>('createDefaultConfigModulebase/setupJETransferFundLevel', data);
        }
        createCustomDefaults(data): Observable<any> {
          return this.http.post<any>('createDefaultConfigModulebase/reportApprovalPeriodRule', data);
            }
            createCustomDefaultJE(data): Observable<any> {
              return this.http.post<any>('ccreateDefaultConfigModulebase/compoundJe', data);
                }
    // createDefaultConfig(url, data, cbs, cbe) {
    //    this.http.post<any>(url, data, this.httpOptions)
    //     .pipe(res => res)
    //     .subscribe(result => {
    //       cbs(result);
    //     }, error => {
    //       cbe(error);
    //     })
  
    // }

    createDefaultConfig(url, data, cbs?, cbe?): Observable<any> {
      return this.http.post<any>(url, data, this.httpOptions)
        .pipe(
          map(res => res),
          catchError(error => {
            cbe(error);
            return EMPTY;
          })
        );
    }

    fileUpload(file): Observable<any> {
      return this.http.post<any>('document/addDocumentList', file);
    }

    validateAndUploadDocumentList(file): Observable<any> {
      return this.http.post<any>('isddocument/validateAndUploadDocumentList', file);
    }

        /* Consolidate Document    */
    ValidateAndUploadConsolidateDocument(file): Observable<any> {
      return this.http.post<any>('isdConsolidateDocument/validateAndUploadDocumentList', file);
    }

     getConsolidateDocumentList(file): Observable<any> {
      return this.http.post<any>('isdConsolidateDocument/getDocumentList', file);
    }

    /* Adhoc Document    */
    ValidateAndUploadAdhocDocument(file): Observable<any> {
      return this.http.post<any>('isdAdhocDocument/validateAndUploadDocumentList', file);
    }
    getAdhocDocumentList(file): Observable<any> {
      return this.http.post<any>('isdAdhocDocument/getDocumentList', file);
    }

    
}
