<ng-container *ngIf="colHeaders.length">
    <form [formGroup]="dynamicForm"  [ngClass]= "{'scroll':colHeaders.length> 5}">
        <table style="width: 100%;" class="t-reducedheader">
            <thead>
                 
                    <th>{{'pricing_source_primary' | translate}}</th>
                    <th>
                        <p style="font-weight: bold;">{{'pricing_source_id' | translate}}</p>
                    </th>
               
            </thead>
            <tbody>
                <tr *ngFor="let col of colHeaders" class="">
                    <td>{{col.header|translate}}</td>
                    <td>
                        <mat-form-field appearance="outline" class="filedCenter">
                            <input matInput class="form-control" placeholder={{col.header|translate}} autocomplete="off"
                                formControlName={{col.field}} type="text" (change)="onChangeEvent(col.field)">
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-container>