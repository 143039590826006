<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<button *ngIf="isShowDownload"  (click)="batchDownloadDetail($event)" style=" background-color: transparent 
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
align-content: center; color: blue; border-style: none;">
<!-- <a href="https://seamlessappdata.s3.amazonaws.com/ZmRfMjAyMS0xMC0wN1QxMF8xMV8wMV81MDM%3D?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211007T101107Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIATSYBAE4EB4YEWW5F%2F20211007%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=6786a61bb1242a6f90aaa0da61dd3a1b29b07dcf0756617af3e1e82e599b0454">
    Download
</a> -->
<span class="material-icons-outlined agvisibility"
matTooltip="{{'download'|translate}}" >cloud_download</span>
</button>
<div *ngIf="!isShowDownload">{{batchDownloadLink}}</div>