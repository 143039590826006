<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <div>Question</div>
    <button type="button" mat-icon-button (click)="close('No answer')" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <p>Do you like Pizza?</p>
  </mat-dialog-content>


  <mat-dialog-actions align="end">
    <button mat-button (click)="close('No')">No</button>
    <button mat-button color="primary" (click)="close('Yes')">Yes</button>
</mat-dialog-actions>