<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<mat-tab-group class="quickpanel" fxFlex>
  <mat-tab label="Overview">
    <fury-scrollbar class="overflow-container">
      <div class="text-padding">
        <p>TODAY</p>
        <p class="h1">
          <span>{{ todayDay }}</span> <br />
          <span>{{ todayDate }}</span><span style="font-size: 18px; vertical-align: top;">{{ todayDateSuffix
            }}</span><span>{{ todayMonth }}</span>
        </p>
      </div>

      <mat-divider></mat-divider>


    </fury-scrollbar>
  </mat-tab>
  <mat-tab label="Notifications">
    <fury-scrollbar class="overflow-container">

    </fury-scrollbar>
  </mat-tab>
</mat-tab-group>