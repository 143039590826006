/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { Injectable } from '@angular/core';
import SimpleBar from 'simplebar';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class LayoutService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  scrollbar: SimpleBar;

  constructor(private http: HttpClient) {
  }
  private subject = new Subject<any>();

  sendMessage(message: string) {
    this.subject.next(message);
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  fileUpload(file): Observable<any> {
    return this.http.post<any>('user/uploadProfilePic', file);
  }
  addToFav(data): Observable<any> {
    return this.http.post<any>('userPerferenceV2/create', data);
  }
  updateToFav(data): Observable<any> {
    return this.http.put<any>('userPerferenceV2/update', data);

  }
  
  changePassword(data, cbs, cbe) {
    this.http.post('user/resetPassword', data, this.httpOptions)
      .pipe(res => res)
      .subscribe(result => {
        cbs(result);
      }, error => {
        cbe(error);
      })
  }
}
