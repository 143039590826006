/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GlobalService } from '../../../../src/app/shared/services/commonServices/global.service';
import { ListColumn } from './list-column.model';
import { apiConstants } from '../../../../src/app/constants/apiConstants';
import { TranslateService } from '@ngx-translate/core';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { Router } from '@angular/router';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';

@Component({
  selector: 'fury-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class ListComponent implements AfterViewInit {
  public gridDataUrl; any;
  public searchValue: any;
  public workflowTreeValue: any;
  @Input() myFlagForSlideToggle: string = 'false';
  @Input() name: string;
  @Input() columns: ListColumn[];
  @Input() togglevalue: String;
  @Input() isSmartTable: boolean;
  @Input() hideHeader: boolean;
  @Input() componentName: string;
  @ViewChild('filter') filter: ElementRef;
  @Output() filterChange = new EventEmitter<string>();
  @Output() smartFilterChange = new EventEmitter<string>();
  @Output() toggleChangeGrid = new EventEmitter<string>();
  @Output() messageEvent = new EventEmitter<string>();
  themeToggle: boolean = true
  // componentName: any;
  modulename: string;
  selectedLanguage: any;
  isSetup: string;
  constructor(private localService: LocalStoreService, public globalService: GlobalService, public translate: TranslateService, private setupPercentageService: SetupPercentageService, private router: Router) {
    this.selectedLanguage = JSON.parse(this.localService.getItem('fss'))['switchLanguage']
    this.translate.onLangChange.subscribe((res) => {
      this.selectedLanguage = res.lang
      console.log("selectedLanguage,", this.selectedLanguage);
    })
  }
  ngOnInit(): void {
    this.isSetup = JSON.parse(this.localService.getItem('fss'))['isSetupDone']
    this.modulename = apiConstants['tradeList']['moduleName'];
    this.workflowTreeValue = JSON.parse(localStorage.getItem('workflowTreeValue'));
    if (this.workflowTreeValue) {
      if (this.workflowTreeValue.type) {
        this.searchValue = this.workflowTreeValue.title;
      }
    }

    if (this.componentName == 'custodianListLegacy') {
      this.themeToggle = false
    }
  }
  ngAfterViewInit() {
    if (!this.hideHeader && this.filter != undefined) {
      fromEvent(this.filter.nativeElement, 'keyup').pipe(
        distinctUntilChanged(),
        debounceTime(1000)
      ).subscribe(() => {
        localStorage.removeItem('workflowTreeValue');
        this.filterChange.emit(this.filter.nativeElement.value);
        this.smartFilterChange.emit(this.filter.nativeElement.value);
      });
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }


  sendMessage() {
    this.toggleChangeGrid.emit(this.myFlagForSlideToggle);
  }

  ngOnDestroy() {
    localStorage.removeItem('workflowTreeValue');
  }

  setActiveTheme(val) {
    console.log("vall>", val)
    if (val == 'seamlessLegacy') {
      this.router.navigate(["/setup/custodians-legacy-list"]);
    } else {
      this.router.navigate(["/setup/custodians-list"]);
    }
    localStorage.setItem('custodianScreenType', val);
    this.setupPercentageService.setCustodianType(val);

    // this.themeService.setTheme(theme);
    // this.commonService.userPerferenceData(this.username).subscribe((res1: any) => {
    //   if (res1) {
    //     this.pinedMenu = res1.ThemeSettings;
    //     if (this.pinedMenu && this.pinedMenu.length) {
    //       var reqBody = {
    //         "id": this.pinedMenu[0].id,
    //         "userName": this.username,
    //         "view": 'ThemeSettings',
    //         "settingName": theme,
    //         "setting": {
    //           "pivotMode": 'ThemeSettings',
    //           "theme_style": this.theme_style,
    //         "tollbar_position": this.tollbar_position,
    //         "navigation_bar": this.navigation_bar,
    //         "ext_options": 'this.ext_options'
    //         },
    //         status: true,
    //       }

    //       this.http.put('userPerferenceV2/update', reqBody).subscribe(res2 => {
    //         if (res2) {



    //         }
    //       })
    //     }
    //     else {
    //       let reqBody = {
    //         "userName": this.username,
    //         "view": 'ThemeSettings',
    //         "settingName": theme,
    //         "setting": {
    //           "pivotMode": 'ThemeSettings',
    //         },
    //         status: true,
    //       }



    //       this.http.post('userPerferenceV2/create', reqBody).subscribe(items => {
    //         this.Id = items['id']
    //       })
    //     }
    //   }

    // })







  }




}
