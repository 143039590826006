<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div *ngIf="isLogin" class="sidenav" fxLayout="column">

  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">

    <div class="sidenav-toolbar-icon">
      <img *ngIf="!showlight && cryptobackoffice!='cryptobackoffice'" width="50" class="defaulttheme" src="https://si.formidium.com/headerlogo/Collapse+menu+icons/{{'icon_'+troniqledger}}.png"alt="logo">
      <img *ngIf="showlight && cryptobackoffice!='cryptobackoffice'" width="50" class="lighttheme" src="https://si.formidium.com/headerlogo/Collapse+menu+icons/{{'light_icon_'+troniqledger}}.png"
      alt="logo">
    </div>
     <div class="sidenav-toolbar-logo">
      <img  style="width: 130px;" *ngIf="!showlight && cryptobackoffice=='cryptobackoffice'" width="50" class="defaulttheme" src="https://si.formidium.com/headerlogo/cryptobackoffice.png" alt="logo">
      <img style="width: 130px;" *ngIf="showlight && cryptobackoffice=='cryptobackoffice'" width="50" class="lighttheme" src="https://si.formidium.com/headerlogo/cryptobackoffice.png"
      alt="logo">
    </div>
    
    <div [routerLink]="['/']" class="sidenav-toolbar-logo">
      <!-- <img *ngIf=' domainName != "crypto" && domainName !=  "digitalasset"' class="defaulttheme" src="https://seamlessserver.s3.amazonaws.com/headerlogo/{{showlight ? 'dev-black-logo' : 'dev'}}.png"
        alt="logo"> -->
        <img *ngIf="!showlight && cryptobackoffice!='cryptobackoffice'" class="defaulttheme" src="https://si.formidium.com/headerlogo/{{troniqledger}}.png"
        alt="logo">
        <img *ngIf="showlight && cryptobackoffice!='cryptobackoffice'" class="lighttheme" src="https://si.formidium.com/headerlogo/{{'light_'+troniqledger}}.png"
        alt="logo">

    </div>

    <span fxFlex>
    </span>




    <div fxFlexAlign="center" fxHide fxShow.gt-md>

      
      <button (click)="toggleCollapsed()" class="sidenav-toolbar-collapse-toggle" mat-icon-button>

        <mat-icon style="transform: rotate(45deg);" *ngIf="!collapsed" class="material-symbols-outlined">push_pin</mat-icon>

        <!-- <mat-icon style="transform: rotate(45deg);" *ngIf="collapsed" class="sidenav-toolbar-collapse-icon"><img src="../assets/img/unpin.png"></mat-icon> -->
        <!-- <img src="../src/assets/img/unpin.png" alt="" width="" height=""> -->
        <img style="width: 24px;height: 24px;font-size: 24px;transform: rotate(45deg);" *ngIf="collapsed"  class="material-symbols-outlined" src="assets/img/pushpin.png">
      </button>

    </div>

  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">

    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">

      <img class="sidenav-user-image" [src]="imgUrl" onerror="src='assets/img/user.jpg';">




      <h4 class="sidenav-user-name">{{currentUser.lastName}} {{currentUser.firstName}}</h4>

      <h5 class="sidenav-user-email">{{userEmail}}</h5>




      <div *ngIf="cryptobackoffice!='cryptobackoffice'"  class="sidenav-user-icons" fxLayout="row">

        <!-- <a href="https://sio.formidium.com/#/auth/signin" target="_blank" mat-icon-button data-md-tooltip="Seamless Investor Online">

          <span class="sio">SIO</span>

        </a> -->
        <a href="https://reports.troniqledger.com/#/sessions/signin" style="left: 5px;" target="_blank" mat-icon-button
          data-md-tooltip="TRO">
          <span class="sro">TRO</span>
        </a>
        <a (click)="signout()" mat-icon-button data-md-tooltip="Signout">
          <mat-icon>logout</mat-icon>
        </a>

      </div>
      <div *ngIf="cryptobackoffice=='cryptobackoffice'" class="sidenav-user-icons" fxLayout="row">

        <a href="https://sio.cryptobackoffice.com/#/auth/signin" target="_blank" mat-icon-button data-md-tooltip="Seamless Investor Online">

          <span class="sio">SIO</span>

        </a>
        <a href="https://sro.cryptobackoffice.com/#/sessions/signin" style="left: 5px;" target="_blank" mat-icon-button
          data-md-tooltip="Seamless Reports Online">
          <span class="sro">SRO</span>
        </a>
        <a (click)="signout()" mat-icon-button data-md-tooltip="Signout">
          <mat-icon>logout</mat-icon>
        </a>

      </div>

    </div>
    <div>
      <ng-container *ngFor="let item of items$ | async">
        <div [ngClass]="(item.name!='setup' && item.name !='user-setup') && isprogrees?'not-allow':''">
          <div [ngClass]="(item.name!='setup' &&  item.name !='user-setup') && isprogrees?'percent-disable':''">
            <fury-sidenav-item *ngIf="item.class != 'settings'" [disabled]="true" [item]="item" [level]="0">
            </fury-sidenav-item> 
          </div>
        </div>
      </ng-container>


    </div>
    <div class="setting_faq" *ngIf="currentUser.authList.indexOf('SIDEMENU_SETTINGS') > -1"
      [ngClass]="isprogrees?'not-allow':''">
      <div [ngClass]="isprogrees?'percent-disable':''">
        <fury-toolbar-setting class="toolbar-button" (settingPanelValue)="getSettingPanleValue($event)"
          *ngIf="!settingButton"></fury-toolbar-setting>
        <fury-sidenav-item *ngFor="let item of itemsRemaining$ | async" [item]="item" [level]="0"></fury-sidenav-item>
      </div>
    </div>
  </fury-scrollbar>




</div>