/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import {Component, ElementRef, ViewChild} from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { ProcessScreenService } from 'src/app/shared/services/processService/process-screen.service';
@Component({
   selector: 'app-custom-header',
   template: `
       <span class="customTable">{{params.displayName}} &nbsp; 
       <span #menuButton *ngIf="params.displayName != 'Action' && params.displayName != '' "
   class="material-icons-outlined menu" (click)="onMenuClicked($event)">menu</span></span>

     <button color="primary" id='previous' *ngIf="params.displayName ==''"
     [disabled]="processScreenService.getBtnStatus()"
     [ngStyle]="processScreenService.getBtnStatus() && {cursor: 'not-allowed'} || {cursor: 'pointer'}"
     class="customHeaderMenuButton mousechange" (click)="runProceeAllCompliance('proceedAll')"><i class="fa {{params.menuIcon}}" ></i> Proceed All </button>

     <button color="primary" id='previous' *ngIf="params.displayName ==''"
     [disabled]="processScreenService.getBtnStatus()"
     [ngStyle]="processScreenService.getBtnStatus() && {cursor: 'not-allowed'} || {cursor: 'pointer'}"
     class="customHeaderMenuButton mousechange" (click)="runProceeAllCompliance('cancelAll')"><i class="fa {{params.menuIcon}}" ></i>Cancel All </button>
    <p id='previous' *ngIf="params.displayName ==''" style="color: #646668;font-size: 12px;font-weight: 500;" class="" >Upload </p>
    
    
      `,
   styles: [
       `
            
           .customHeaderMenuButton,
           .customHeaderLabel,
           .customSortDownLabel,
           .customSortUpLabel,
           .customSortRemoveLabel {
               float: left;
               margin: 0 0 0 3px;
           }

           .customSortUpLabel {
               margin: 0;
           }

           .customSortRemoveLabel {
               font-size: 11px;
           }

           .active {
               color: cornflowerblue;
           }
           .mousechange:hover {
            cursor: pointer;
          }
          .menu{
            font-size: 14px;
            color: #9b9b9b;
            font-style: normal;
            font-weight: normal;
            display: inline-flex;
            position: absolute;
            padding: 0.5em 4% 0.5em 96%;
            right: 0em;
            top: 0em;
            cursor: pointer;
        }   
        .customTable {
          color: #646668;
          font-size: 12px;
          font-weight: 500;
      }
       `
   ]
})
export class CustomHeader implements IHeaderAngularComp {
    display: boolean;
    public btnstatus:boolean = false;
   refresh(params: IHeaderParams): boolean {

       return;
    //    throw new Error('Method not implemented.');
   }
   afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    return;
    //    throw new Error('Method not implemented.');
   }
  public params: any;
   constructor(private processScreenService: ProcessScreenService){}
   @ViewChild('menuButton', {read: ElementRef}) public menuButton;

   agInit(params: IHeaderParams): void {

       this.params = params;
   }

   onMenuClicked(e) {
    this.params.showColumnMenu(this.menuButton.nativeElement);
  }

runProceeAllCompliance(evt) {
    let selectedNode = this.params.node;
    this.params.runProceeAllCompliance =this.params.column.userProvidedColDef.cellRendererParams.runProceeAllCompliance;
    if (this.params.runProceeAllCompliance instanceof Function) {
      const params = {
        event: evt,
        //rowData: selectedData
      }
      this.params.runProceeAllCompliance(params);
    }
  }

  
}