/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2017 - 2018 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef
} from "@angular/core";

import { ICellEditorAngularComp } from 'ag-grid-angular';
import moment from 'moment';

@Component({
    selector: 'fury-grid-cell-datepicker',
    templateUrl: './grid-cell-datepicker.component.html',
    styleUrls: ['./grid-cell-datepicker.component.scss']
})
export class GridCellDatepicker implements ICellEditorAngularComp {
    private params: any;
    public selectedDate = null;
    public isReadonly: boolean = true;
    agInit(params: any): void {
        this.params = params;
        this.isReadonly = this.params.node.selected == true ?  false : true;
        // console.log(this.params,"this.params");

    }

    getValue = () => {
        let dateString = null;
        if (this.selectedDate) {
            // dateString = moment(this.selectedDate, "dd/MM/yyyy");
            dateString = moment(this.selectedDate).format('YYYY-MM-DD');
        }
        return dateString;
    };

    afterGuiAttached = () => {
        if (!this.params.value) {
            return;
        }
        // const [_, day, month, year] = this.params.value.match(
        //     /^(\d{2})\/(\d{2})\/(\d{4})$/
        // );
        //let selectedDate = new Date();

        let selectedDate = this.params.value

        this.selectedDate = selectedDate;
    };

    onDateChanged = event => {
        let date = event.value;
        if (date) {
            date.setHours(0, 0, 0, 0);
        }
        this.selectedDate = date;
    }
}
