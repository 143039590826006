<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/   -->
<fury-page-layout mode="card">
  <fury-page-layout-header>
    <fury-breadcrumbs [@fadeInRight] [crumbs]="[moduleNameGrid]" current="{{heading|translate}}"></fury-breadcrumbs>
  </fury-page-layout-header>

  <fury-page-layout-content [@fadeInUp]>
    <fury-list name="{{heading|translate}}">

      <p-table [columns]="columnData" [value]="dataSource" [scrollable]="true" [rows]="100" scrollHeight="250px"
        [virtualScroll]="true" [virtualRowHeight]="34" [paginator]="true" [lazy]="true"
        (onLazyLoad)="loadCarsLazy($event)">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{col.name}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr style="height:34px">
            <td *ngFor="let col of columns">
              {{rowData[col.property]}}
            </td>
          </tr>
        </ng-template>
      </p-table>

    </fury-list>

  </fury-page-layout-content>
</fury-page-layout>