/*******************************************************************************
 * FORMIDIUM Corp. COPYRIGHT STATEMENT
 *  __________________
 *
 *  2022 - 2023 FORMIDIUM Corp.
 *  All Rights Reserved. 
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Corp.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Corp.
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
 * by visiting www.formidium.com.
 ******************************************************************************/

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../src/environments/environment";
import { Guid } from "guid-typescript";
import { retry } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';
import { LocalStoreService } from "../services/commonServices/local-store.service";
import { AuthService } from '../services/commonServices/auth.service';
import jwt_decode from "jwt-decode";
import { LocalCookieService } from 'src/app/shared/services/commonServices/localCookieService';

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  refreshToken: boolean = false;
  constructor(
    private localService: LocalStoreService, private localCookieService: LocalCookieService,
    private cookieService: CookieService, private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let queryId = Guid.create().toString();
    let api_url;
    // check mfa url condidtions here as well
    if (
      !request.url.includes("integrations") &&
      !request.url.includes("tpsourceapi") &&
      request.url.includes("loginFirst")
    ) {
      api_url = request.clone({
        url: environment.api_url + request.url,
        headers: new HttpHeaders({
          "Cache-Control": "no-cache",
          "Pragma": "no-cache",
          "QueryId": queryId,
        }),
      });
    } else {
      api_url =
        request.url.indexOf(".json") > -1
          ? request
          : JSON.parse(this.localService.getItem("fss"))
            ? request.clone({
              url: request.url.includes("integrations")
                ? request.url
                : request.url.includes("tpsourceapi")
                  ? request.url
                  : environment.api_url + request.url,
              headers: new HttpHeaders({
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "Authorization": 'Bearer ' + this.cookieService.get('access_token'),
             //   "x-seamless-api-key-token": JSON.parse(this.localService.getItem("fss")).id,
                "QueryId": queryId,
              }),
            })
            : request.clone({
              url: request.url.includes("integrations")
                ? request.url
                : environment.api_url + request.url,
              headers: new HttpHeaders({
                "Cache-Control": "no-cache",
                "Pragma": "no-cache",
                "QueryId": queryId,
                "Authorization": 'Bearer ' + this.cookieService.get('access_token')
              }),
            });
    }

    // check mfa condidtions here as well
    if (
      !request.url.includes('getAccessToken') &&
      !this.cookieService.get('access_token') &&
      !request.url.includes('getGeneralConfiguration') &&
      !request.url.includes('loginCall') &&
      !request.url.includes('English.json') &&
      !request.url.includes('generatePassword')
    ) {
      console.log("no token");
      this.authService.signout();
    }

    if (this.cookieService.get('access_token')) {
      let current_time = new Date().getTime();
      let token = jwt_decode(this.cookieService.get('access_token'));
      let expireTime = token["exp"] * 1000;
      if (expireTime - current_time <= 0) {
        console.log("expired token", expireTime, current_time, expireTime - current_time);
        this.authService.signout();
      }
    }
    // check mfa apis condidtions here as well
    if (this.cookieService.get('access_token') && this.cookieService.get('refresh_token') && !request.url.includes("logOut")) {
      //add refresh token logic here
      let access_token = jwt_decode(this.cookieService.get('access_token'));
      let expireTime = access_token["exp"] * 1000;
      let authTime = access_token["iat"] * 1000;
      let timeDiff = Math.floor((expireTime - authTime) / 6);
      expireTime = authTime + timeDiff;
      let current_time = new Date().getTime();
      if (!request.url.includes('getAccessToken') && !this.refreshToken && (expireTime < current_time)) {
        let refresh_token = this.cookieService.get('refresh_token');
        let url = environment['api_url'] + 'getNewAccessToken?refresh_token=' + refresh_token;
        var request1 = new XMLHttpRequest();
        request1.open('GET', url, true);
        request1.setRequestHeader('Authorization', 'Bearer ' + this.cookieService.get('access_token'));
        request1.addEventListener("loadend", () => {
          if (request1.status == 200 && request1.responseText) {
            let myArr = JSON.parse(request1.responseText);
            let access_token_new = myArr["access_token"];
            let token = jwt_decode(access_token_new);
            let expiryTime = new Date(token['exp'] * 1000);
            const [subdomain, domain] = this.localCookieService.getDomainAndSubdomain();
            let url = domain || '';
            this.cookieService.set('access_token', access_token_new, expiryTime, "/", url, true);
            let refresh_token = this.cookieService.get('refresh_token');
            this.cookieService.set('refresh_token', refresh_token, expiryTime, "/", url, true);
            localStorage.setItem("access_token", access_token_new);
            this.authService.setTokenRefresh(true);
          }
          setTimeout(() => {
            this.refreshToken = false;
            this.authService.setTokenRefresh(false);
          }, 2500);
        });
        request1.addEventListener("error", () => {
          this.refreshToken = false;
          this.authService.setTokenRefresh(false);
        });
        request1.addEventListener("abort", () => {
          this.refreshToken = false;
          this.authService.setTokenRefresh(false);
        });
        this.refreshToken = true;
        request1.send();
      }
    }
    return next.handle(api_url).pipe(retry(0));
  }
}
