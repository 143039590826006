/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { TranslateService } from '@ngx-translate/core';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

@Component({
  selector: 'fury-grdbtn-row-down',
  templateUrl: './grdbtn-row-err-down.component.html',
  styleUrls: ['./grdbtn-row-err-down.component.scss']
})
export class GrdbtnErrRowDownComponent {

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  public workflowID: any;
  public dataCount: any;
  public successCount: any;
  public disposition: any;
  public pstatus: any;
  public tempDataId: any;
  public omsCancel: boolean;
  public omsApproveList: boolean;
  public omsEditList: boolean;
  public colHeader: any;
  public previewData: any;
  public isCheckBox: boolean = false;
  public isButton: boolean = true;
  public isExecuted:any;
  currentUser: any;
  enterpriseId: any;
  moduleName: any;
  executionId: any;
  errorList: any;
  displayBasic: boolean = true;
  displayBasic1: boolean = true;
  displayBasic2: boolean = false;
  postId;
  errorMessage;
  alteryxWorkflowStatus: any;
  display: boolean;
  public tooltipTxt: any;
  public tooltipMsg: any;
  public failureCount:any;
public toolTipValue:any;
  constructor(private http: HttpClient,
    public translate: TranslateService,
    private messageService: GlobalMessageService) { }
  public overAllStatus: any;

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.overAllStatus = this.params.value;
    this.executionId = this.params.data.executionId;
    this.isExecuted = this.params.data.isExecuted;
    this.workflowID = this.params.data.id ? this.params.data.id : null;
    this.tempDataId = this.params.data.uniqueNumber ? this.params.data.uniqueNumber : null;
    this.previewData = this.params.data.alteryxWorkflowMngt ? this.params.data.alteryxWorkflowMngt.isPreviewData : null;
    this.dataCount = this.params.data.dataCount ? this.params.data.dataCount : null;
    this.successCount = this.params.data.successCount ? this.params.data.successCount : null;
    this.disposition = this.params.data.disposition ? this.params.data.disposition : null;
    this.pstatus = this.params.data.status ? this.params.data.status : null;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
    this.failureCount = this.params.data ? this.params.data.failureCount : null;
    if(this.moduleName=="executeWorkflow"){
    this.workflowID =null;
    this.failureCount = null;
    this.alteryxWorkflowStatus = this.params.data.alteryxWorkflowStatus;
    this.workflowID = this.params.data.alteryxWorkflowStatus ? this.params.data.alteryxWorkflowStatus.id : null;
    }

    if (this.moduleName) {
      this.tooltipMsg = this.moduleName;
      this.colHeader = this.params.colDef ? this.params.colDef.headerName : null;
    }
    if(this.isExecuted == true){
      this.toolTipValue = 'Uncheck to allow reprocessing';
    }
    if(this.isExecuted == false){
      this.toolTipValue = '';
    }
   
    if (this.params.colDef.furyCustm == "processStatus" && this.params.colDef.field == "action") {
      this.isCheckBox = true;
    }

    if (this.params.colDef.cellRenderer == "GrdbtnErrRowDownComponent") {
      //this.disposition = "Error";
     // if( (typeof this.dataCount == 'number') && (typeof this.successCount == 'number')){
        if(this.failureCount != 0 ){ 
        //   this.tooltipTxt = this.tooltipMsg + " : " + "Download" + " " + this.colHeader;
        //   this.displayBasic = false;
        // }else if(this.dataCount != 0){
        //   if ( this.dataCount > this.successCount ) {
        //     if (this.disposition != (null || undefined)) {
        //       this.tooltipTxt = this.tooltipMsg + " : " + "Download" + " " + this.colHeader;
        //       this.displayBasic = false;
        //     }
        this.displayBasic = false;
          }else{
            if(this.pstatus == 'Completed' && this.disposition == 'Success')
            this.displayBasic1 = false;
          }
      }
    //}
      if (this.params.colDef.field == "viewData") {
        if (this.previewData != null) {
          this.tooltipTxt = this.tooltipMsg + " : " + "Download" + " " + this.colHeader;
          this.displayBasic = false;
        }
      }
    }
  

  uploadErrorStatus(event, view) {
   // if (this.params.colDef.headerName == "error_file"){
      if (this.workflowID) {
        this.http.get<any>('configurations/getDataImportStatusFailedRecord/' + this.workflowID).subscribe(response => {
          if (response) {
          this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('fail_status_file'), detail: this.translate.instant('download_request_taken_soon') });
            alasql('SELECT * INTO XLSX("Upload failed Status.xlsx",{headers:true}) FROM ?', [response.data]);
          }else{
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('request_failed'), detail: this.translate.instant('something_went_wrong') });
          }
        })
     // }
    }
    
  }

  errorStatusDetails(executionId, view) {
    this.http.get<any>('configurationsV2/getWorkflowStatusById/' + executionId).subscribe(response => {
      if (response) {
        this.errorList = response.messages;
        this.displayBasic = true;
      }
    })
  }




  public usersActiveInactive(postData: Object) {
    let url = "user/update"
    this.http.put<any>(url, postData)
      .subscribe({
        next: data => {
          this.postId = data.id;
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('There was an error!', error);
        }
      });
  }

  sentMailToInvestor(data) {
    this.http.get<any>('reportDeliveryWorkflowV2/prepareInvestorDetail?workflowId=' + data.id).subscribe(response => {
    })
    this.display = true;
  }

  closeDataImport() {
    this.displayBasic = false;
    this.display = false;
  }

  downloadAttachment(data) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', data.attachmentURL[0]);
    link.setAttribute('download', `products.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  setExecute(evt){
    if(!evt.checked)
    if (this.workflowID) {
      this.http.get<any>('configurations/setExecutedById/' + this.workflowID).subscribe(response => {
        if (response) {
          this.displayBasic2 = true;
        }
      })
    }
    //this.displayBasic2 = true;
  }
}
