<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<fury-backdrop #backdrop="furyBackdrop"></fury-backdrop>
<!-- QUICKPANEL -->
<fury-sidebar #configPanel="furySidebar" [backdrop]="backdrop" [invisibleBackdrop]="true" position="right">
  <fury-config-panel></fury-config-panel>
</fury-sidebar>
<!-- END QUICKPANEL -->
<div class="toolbar-user toolbaruser_items" (furyClickOutside)="onClickOutside()" fxFlex="grow">
  <button class="button" mat-button (click)="toggleDropdown()" [class.open]="isOpen" fxFlex="grow">
    <span fxLayout="row" fxLayoutAlign="start center">

      <img class="avatar" [src]="imgUrl" onerror="src='assets/img/user.jpg';">


      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" matRipple (click)="openProfileDialog()">
          <mat-icon class="list-item-icon">account_circle</mat-icon>
          <span>{{'profile'|translate}}</span>
        </div>

        <div class="list-item" matRipple (click)="openProcessLog()"
          *ngIf="(currentUser.authList.indexOf('USER_PROCESSLOG')  > -1) ||(currentUser.authList.indexOf('ENT_PROCESSLOG')  > -1) ||((currentUser.authList.indexOf('FUNCTION_CREATE')  > -1)&&(currentUser.authList.indexOf('ENTERPRISE_CREATE')  > -1))">
          <mat-icon class="list-item-icon">history</mat-icon>
          <span>{{'process_log'|translate}}</span>
        </div>
        <div class="list-item" matRipple (click)="openProcessLogArchive()"
          *ngIf="(currentUser.authList.indexOf('USER_PROCESSLOG')  > -1) ||(currentUser.authList.indexOf('ENT_PROCESSLOG')  > -1) ||((currentUser.authList.indexOf('FUNCTION_CREATE')  > -1)&&(currentUser.authList.indexOf('ENTERPRISE_CREATE')  > -1))">
          <mat-icon class="list-item-icon">history</mat-icon>
          <span> {{'process_log_archive'|translate}}</span>
        </div>
        <!-- <div class="list-item" matRipple (click)="processbar();" *ngIf="(currentUser.authList.indexOf('USER_PROCESSBAR')  > -1) ||(currentUser.authList.indexOf('ENT_PROCESSLOG')  > -1) ||((currentUser.authList.indexOf('FUNCTION_CREATE')  > -1)&&(currentUser.authList.indexOf('ENTERPRISE_CREATE')  > -1))">
          <mat-icon class="list-item-icon">perm_data_setting</mat-icon>
          <span>{{'process_bar'|translate}}</span>
        </div> -->
        <div class="list-item" matRipple (click)="openchangePassword();">
          <mat-icon class="list-item-icon">lock_reset</mat-icon>
          <span>{{'changepass'|translate}}</span>
        </div>
        <div class="list-item" matRipple (click)="openConfigPanel()">
          <mat-icon class="list-item-icon">settings</mat-icon>
          <span>{{'theme_setting'|translate}}</span>
        </div>
        <div class="list-item" matRipple (click)="chooseMfaType()">
          <mat-icon class="list-item-icon">lock_reset</mat-icon>
          <span>{{'mfa_text'|translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="list-item" matRipple (click)="signout()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>{{'logout'|translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog class="delModal sessionmodal" [modal]="true" header="{{'update_profile_image' | translate}}"
  [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <div class="col-md-12"><br><br>

    <div class="browse_section">
      <p-button style="float: right; margin: 1.2em;" *ngIf="clearbuttonShow == true" type="button" [label]="cancelLabel"
        icon="pi pi-times" (click)="clear()"></p-button>
      <p-fileUpload #form class="customUpload" [auto]="true" accept="image/*" (uploadHandler)="myUploader($event, form)"
        mode="advanced" [showCancelButton]="false" [showUploadButton]="false" chooseLabel="{{'browse'|translate}}"
        customUpload="true" (onBeforeUpload)="clear()" dragDropSupport="true">
        <ng-template pTemplate="content">
          <h3 *ngIf="dragDrop==true" style="text-align: center;">{{'drag_drop_file'|translate}}</h3>
        </ng-template>
      </p-fileUpload>
    </div><br><br>
  </div>
  <p-footer>
    <button class="btn-logout" (click)="hideDialogBox()"> {{'close'|translate}} </button>
    <button class="btn-logout" (click)="uploadProfile()"> {{'update'|translate}} </button>
  </p-footer>
</p-dialog>


<p-dialog class="delModal sessionmodal" [modal]="true" header="{{'changepass' | translate}}"
  [(visible)]="displaychangePassword" [style]="{width: '60vw'}" [baseZIndex]="10000">
  <form autocomplete="off" style="display: contents !important;">
    <div class="padding toolbaruser_dialogbox" [formGroup]="changePasswordForm">
      <div class="toolbar_inputfield" fxFlex="auto" fxLayout="column">
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label>{{"current_password" | translate}}</mat-label>
          <input matInput formControlName="currentPassword" [type]="inputType" autocomplete="new-password">
          <button type="button" mat-icon-button matSuffix (click)="toggleVisiblity(1)">
            <mat-icon *ngIf="visible" matTooltip="{{'hide_password' | translate}}">visibility</mat-icon>
            <mat-icon *ngIf="!visible" matTooltip="{{'show_password' | translate}}">visibility_off</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label>{{"new_password" | translate}}</mat-label>
          <input matInput formControlName="newPassword" [type]="inputTypes"
            (input)="onChangeEvent($event,'newPassword')" (keydown.space)="$event.preventDefault()" minlength="8"
            maxlength="256" autocomplete="new-password">
          <button type="button" mat-icon-button matSuffix (click)="toggleVisiblity(2)">
            <mat-icon *ngIf="visibles" matTooltip="{{'hide_password' | translate}}">visibility</mat-icon>
            <mat-icon *ngIf="!visibles" matTooltip="{{'show_password' | translate}}">visibility_off</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label>{{"confirm_password" | translate}}</mat-label>
          <input matInput formControlName="confirmPassword" [type]="inputTypess"
            (input)="onChangeEvent($event,'confirmPassword')" (keydown.space)="$event.preventDefault()" minlength="8"
            maxlength="256" autocomplete="new-password">
          <button type="button" mat-icon-button matSuffix (click)="toggleVisiblity(3)">
            <mat-icon *ngIf="visibless" matTooltip="{{'hide_password' | translate}}">visibility</mat-icon>
            <mat-icon *ngIf="!visibless" matTooltip="{{'show_password' | translate}}">visibility_off</mat-icon>
          </button>
          <mat-error *ngIf="f?.confirmPassword?.errors?.MustMatch">
            {{'password_match' | translate}}
          </mat-error>
        </mat-form-field>
        <!-- <div class="error" *ngIf="f?.confirmPassword?.errors?.MustMatch">
          {{'password_match' | translate}}
        </div> -->
        <div class="error" *ngIf="showPasswordMatchText">
          <span> {{"password_character_should_not_contain_%_&_/" | translate}}</span>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between center">
        <div class="user-agreement gen-password">
          <p>{{'password_must_meet_the_following_requirements_:'| translate }} </p>
          <ul>
            <li [ngClass]="{'my_class': passwordStrength1 == true,'error':passwordStrength1 == false}">
              {{'atleast_one_small_letter' | translate}}
            </li>
            <li [ngClass]="{'my_class': passwordStrength == true}">
              {{'atleast_one_capital_letter' | translate}}
            </li>
            <li [ngClass]="{'my_class': passwordStrength2 == true}">
              {{'atleast_one_number' | translate}}
            </li>
            <li [ngClass]="{'my_class': passwordStrength3 == true}">
              {{'atleast_one_special_character' | translate}}
            </li>
            <li [ngClass]="{'my_class': passwordStrength4 == true}">
              {{'atleast_8_characters' | translate}}
            </li>
            <li [ngClass]="{'my_class': passwordStrength5 == true}">
              {{'only_special_character' | translate}}
            </li>
            <li [ngClass]="{'my_class': passwordStrength6 == true}">
              {{'space_not_allowed' | translate}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </form>
  <p-footer>
    <button class="btn-logout tp-zero top0" color="primary" mat-raised-button [disabled]="formValid"
      (click)="changePassword()"> {{'reset'|translate}} </button>
    <button class="btn-logout" (click)="hidechangeDialogBox()"> {{'cancel'|translate}} </button>
  </p-footer>
</p-dialog>