/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';


 @Component({
   selector: 'fury-send-mail-client-template',
   templateUrl: './send-mail-client-template.component.html',
   styleUrls: ['./send-mail-client-template.component.scss']
 })
 export class SendMailClientTemplate {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
   public record: any;
  public  transactionID: any;
   sendEmailToClientAndCC: any;

  constructor( private http: HttpClient,
    private messageService:GlobalMessageService) { }

  
  public overAllStatus: any;
  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.transactionID = this.params.data.id;
    this.sendEmailToClientAndCC=this.params.data.sendEmailToClientAndCC;
  }


  sendMailToClient(event)
  {
  
  this.http.get<any>('reportDeliveryWorkflow/sendEmailToClientAndCC?workflowId=' + event).subscribe(response =>{     
    if(response){
       this.messageService.add({sticky:true,  severity:'success', summary: 'success', detail: 'Email has been sent successfully'});    
    }
 })
  }


}