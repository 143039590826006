<div class="headerLogo" style="cursor: pointer;" *ngIf="!isUserLoggedIn">
    <div *ngIf="oneseamless!='oneseamless'" class="sudrania-logo default">
        <a target="_blank" href="{{'https://'+logo_domainName+'.com/'}}">
            <img src="https://si.formidium.com/signinlogo/{{'logo_'+logo_domainName+'_default'}}.png"
                alt="companylogo">
        </a>
    </div>
    <div *ngIf="oneseamless!='oneseamless'" class="sudrania-logo dark" style="display:none ;">
        <a target="_blank" href="{{'https://'+logo_domainName+'.com/'}}">
            <img src="https://si.formidium.com/signinlogo/{{'logo_'+logo_domainName+'_dark'}}.png"
                alt="companylogo">
        </a>
    </div>
    <div *ngIf="oneseamless=='oneseamless'" class="sudrania-logo default">
        <a target="_blank" href="https://www.oneseamless.com">
            <img src="https://si.formidium.com/signinlogo/{{'oneseamless'}}.png" alt="companylogo">
        </a>
    </div>
</div>
<div *ngIf="displayPasswordForm" fxLayout="column" fxLayoutAlign="start center">
    <div [@fadeInUp] class="card" id="mfaCard">
        <div class="mfaHeader">
            <img src="assets/img/gearhub_security_icon.svg">
            <p>Set Up Google Authenticator Verification</p>
        </div>
        <div class="padding MFAfontstyle" fxLayout="column" fxLayoutGap="16px" [formGroup]="smsForm">
            <p>
                {{'enter_ga_pwd'| translate}}
            </p>
            <mat-form-field fxFlex="grow">
                <mat-label>{{'Password' | translate}}</mat-label>
                <input matInput [type]="inputType" formControlName="password" (keydown)="loginOnEnter($event)"
                    class="inputpad10 inputColor" required>
                <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()">
                    <mat-icon *ngIf="visible" matTooltip="Hide Password">visibility</mat-icon>
                    <mat-icon *ngIf="!visible" matTooltip="Show Password">visibility_off</mat-icon>
                </button>
                <mat-error *ngIf="form.get('password').hasError('required')">
                    {{'Password Required' | translate}}
                </mat-error>
            </mat-form-field>
            <div class="MFAbutton">
                <button type="button" id="submitButton" class="login_blue_btns" mat-raised-button
                    (click)="redirectToSetup()">
                    {{'CANCEL' | translate}}
                </button>
                <button type="button" id="submitButton" [disabled]="!smsForm.valid || isSubmitDisabled"
                    class="login_blue_btns confirmButton" mat-raised-button (click)="submit('GoogleAuthenticator')">
                    {{'CONFIRM' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="login-footer1" *ngIf="!isUserLoggedIn">
        <div *ngIf="responseData" class="copyright1">
            {{copyrightText}} <a target="_blank" href="{{linkPoweredBy}}">{{textPoweredBy}}</a> |
            <a target="_blank" href="{{linkPrivacyPolicy}}">{{textPrivacyPolicy}}</a> |
            <span> {{'tel'|translate}}: </span><a>{{bodyTelephoneNo}}</a> | <span>{{'Email'|translate}}: </span> <a
                [href]="'mailto:'+bodyEmailAddress">{{bodyEmailAddress}}</a>
        </div>
    </div>
</div>

<div *ngIf="displayQRCode" fxLayout="column" fxLayoutAlign="start center">
    <div [@fadeInUp] class="card" id="gaCard">
        <div class="mfaHeader">
            <img src="assets/img/gearhub_security_icon.svg">
            <p>Set Up Google Authenticator Verification</p>
        </div>
        <div class="padding MFAfontstyle gaText">
            <div class="mb-5">
                <strong>1. Install a verification app on your device.</strong>
            </div>
            <div class="gaIcon pl-15">
                <p>
                    You'll need to use the
                    <img src="assets/img/google-authenticator.svg">
                    {{'ga_step_1'| translate}}
                </p>
            </div>
            <div class="mb-5">
                <strong>2. Scan this QR code with your verification app.</strong>
            </div>
            <div class="pl-15">
                <p>
                    {{'ga_step_2'| translate}}
                </p>
                <div class="QRCode">
                    <img [src]="urlSafe" alt="qrcode" />
                </div>
            </div>
            <div class="mb-5">
                <strong>3. Enter the 6-digit code here:</strong>
            </div>
            <div class="pl-15">
                <p>
                    {{'ga_step_5'| translate}}
                </p>
            </div>
            <div class="otpBox otpIcon otpBtn pl-15">
                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" (keydown)="submitOnEnter($event)"
                    (paste)="handlePaste($event)" [config]="config"></ng-otp-input>
                <div *ngIf="showIconsTick">
                    <img src="assets/img/check.png">
                </div>
            </div>
            <div class="mt-15 pl-15">
                <button type="button" color="primary" id="submitButton"
                    [disabled]="hasNull(ngOtpInput?.otpForm?.value) || apiCallMutex"
                    [ngStyle]="(hasNull(ngOtpInput?.otpForm?.value) || apiCallMutex) && {cursor: 'not-allowed'}"
                    class="login_blue_btns confirmButton ml-2" mat-raised-button (click)="submitBtn()">
                    Continue
                </button>
            </div>
        </div>
    </div>
    <div class="login-footer1" *ngIf="!isUserLoggedIn">
        <div *ngIf="responseData" class="copyright1">
            {{copyrightText}} <a target="_blank" href="{{linkPoweredBy}}">{{textPoweredBy}}</a> |
            <a target="_blank" href="{{linkPrivacyPolicy}}">{{textPrivacyPolicy}}</a> |
            <span> {{'tel'|translate}}: </span><a>{{bodyTelephoneNo}}</a> | <span>{{'Email'|translate}}: </span> <a
                [href]="'mailto:'+bodyEmailAddress">{{bodyEmailAddress}}</a>
        </div>
    </div>
</div>

<p-dialog class="relModal mfaModal Dialog_width_Small confirm_undo" [modal]="true" [(visible)]="wrongAuthentication"
    [style]="{width: '45vw'}" [baseZIndex]="10000">
    <div class="content mfaModalContent" fxLayout="column" fxLayoutGap="8px">
        <span class="material-icons-outlined wrongIcon">cancel</span>
        <h2><strong>Error!</strong></h2>
        <p>
            {{'wrong_authentication'| translate}}
        </p>
        <div class="MFAbutton">
            <button pButton type="button" label="{{'Dismiss'|translate}}" (click)="dismiss()"></button>
        </div>
    </div>
</p-dialog>

<p-dialog class="mfaModal relModal Dialog_width_Small confirm_undo" [modal]="true" [(visible)]="wrongPassword"
    [style]="{width: '45vw'}" [baseZIndex]="10000">
    <div class="content mfaModalContent" fxLayout="column" fxLayoutGap="8px">
        <span class="material-icons-outlined wrongIcon">cancel</span>
        <strong>Error!</strong>
        <p>
            {{'wrong_password'| translate}}
        </p>
        <div class="MFAbutton">
            <button pButton type="button" label="{{'Dismiss'|translate}}" (click)="dismissPopup()"></button>
        </div>
    </div>
</p-dialog>