/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
 
import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[furyBackdrop],fury-backdrop',
  host: {
    class: 'fury-backdrop'
  },
  exportAs: 'furyBackdrop'
})
export class BackdropDirective {

  @Input()
  @HostBinding('class.visible')
  visible: boolean;

  @HostBinding('class.invisible') invisible: boolean;
  @Output() closed = new EventEmitter();

  constructor() {}

  show() {
    if (!this.visible) {
      this.visible = true;
      this.invisible = false;
    }
  }

  @HostListener('click')
  hide() {
    if (this.visible) {
      this.visible = false;
      this.invisible = false;
      this.closed.emit();
    }
  }

  showInvisible() {
    if (!this.visible) {
      this.visible = true;
      this.invisible = true;
    }
  }
}
