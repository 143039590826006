<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div [class.collapsed]="isCollapsed$ | async" [class.dropdown-open]="dropdownOpen$ | async" [ngClass]="levelClass"
  class="sidenav-item sidenave-textbrake">
  <div *ngIf="item.type === 'subheading'" [ngClass]="item.customClass" class="subheading">{{ item.name }}
  </div>

  <ng-container *ngIf="item.type !== 'subheading'">
    <ng-container
      *ngIf="item.subItems?.length > 0 || isFunction(item.routeOrFunction); then functionOrSubItems; else link">
    </ng-container>
  </ng-container>

  <ng-template #functionOrSubItems>
    <a (click)="handleClick()" class="sidenav-item-link" fxLayout="row" fxLayoutAlign="start center" matRipple>
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-template>

  <ng-template #link>
    <a *ngIf="item.name =='seamless_reports1'" href="https://reports.sudrania.com/#/sessions/signin" target="_blank"
      [routerLinkActiveOptions]="{ exact: item.pathMatchExact || false }" class="sidenav-item-link" fxLayout="row"
      fxLayoutAlign="start center" matRipple routerLinkActive="active" (click)="setModuleName(item)">
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>

    <a *ngIf="item.name !='seamless_reports1'" [routerLinkActiveOptions]="{ exact: item.pathMatchExact || false }"
      [routerLink]="item.routeOrFunction" class="sidenav-item-link" fxLayout="row" fxLayoutAlign="start center"
      matRipple routerLinkActive="active" (click)="setModuleName(item)">
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-template>

  <ng-template #linkContent>

    <!-- <mat-icon>{{item.class}}</mat-icon> -->
    <mat-icon>{{item.class}}</mat-icon>
	  <!-- <img src="{{item.imgSrc}}"class="{{item.classImg}}" style="display: none;"> -->
    <div *ngIf="level > 0" class="text-icon" fxFlex="none">{{ getTextIcon(item) }}</div>
    <span class="name" *ngIf="item.name == 'accounting-dashboard' || item.name == 'user-setup'"> 3<sup>rd</sup> {{item.name|translate}}</span>
    <span class="name" *ngIf="item.name != 'accounting-dashboard' && item.name != 'user-setup'"> {{item.name|translate}}</span>
    <span fxFlex>

    </span>
    <span *ngIf="item.badge" [style.background-color]="item.badgeColor" class="badge">{{ item.badge }}</span>
    <mat-icon *ngIf="item.subItems && item.subItems.length > 0" [class.rotate]="dropdownOpen$ | async"
      class="expand-indicator">
      expand_more
    </mat-icon>
  </ng-template>


  <div [@dropdownOpen]="dropdownOpen$ | async" class="dropdown" fxLayout="column">
    <fury-sidenav-item *ngFor="let subItem of item.subItems" [item]="subItem" [level]="level+1"></fury-sidenav-item>
  </div>
</div>