/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common'
import { HttpClient, HttpHeaders } from "@angular/common/http";

import moment from 'moment';
import { Router } from '@angular/router';
import { fadeInUpAnimation } from '../../../../../../../src/@fury/animations/fade-in-up.animation';
import { fadeInRightAnimation } from '../../../../../../../src/@fury/animations/fade-in-right.animation';
import _ from 'lodash';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { NodeService } from '../../workflow-tree/nodeService';
import { WorkflowTreeComponent } from '../../workflow-tree/workflow-tree.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
@Component({
  selector: 'fury-execute-work-flow-run',
  templateUrl: './execute-work-flow-run.component.html',
  styleUrls: ['./execute-work-flow-run.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation]
})
export class ExecuteWorkFlowRunComponent implements OnInit {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  fileColumns: string[] = ['select', 'Workflow', 'InputMode', 'FileName'];
  detailColumns: string[] = ['Workflow', 'ErrorLog', 'Status'];
  connectionStatusColumns: string[] = ['Workflow', 'FileName', 'Status'];
  public rowData: any;
  public flag: boolean = false;
  public getCall: boolean = true;
  public prvChk: boolean = false;
  public isExecuted: boolean = false;
  public erunDate: any;
  public erunDateStart: any;
  public erunDateEnd: any;
  public ids = [];
  public prRundt = [];
  public selDt = [];
  public rangeDates = [];
  public rangeDates2 = [];
  public display: boolean = false;
  public display2: boolean = false;
  public display3: boolean = false;
  public dateRang: boolean = true;
  public dateRangOp: boolean = false;
  public display11: boolean = false;
  public fileName: any;
  public fileNameEX: any;
  public isShown: boolean = false; // hidden by default
  public isShownOp: boolean = false;
  public calFocus: boolean = true;
  public inputModeDB: boolean = false;
  public isMultiple: boolean = false;
  public multipleEligible: boolean = false;
  public isDailyFrequency: boolean = false;
  public isApiCall: boolean = false;
  public isRequestSend: boolean = false;
  public showDateField: boolean = false;
  public isSubmitDisable: boolean = false;
  public isFileInvalid: boolean = false;
  public btnSubmitRange: boolean = false;
  public btnSubmit: boolean = false;
  public isDataDumpInTemp: boolean = true;
  public PriodicDateMessage: string;
  public errorMessageStart: string;
  public errorMessageEnd: string;
  public errorMessage: string;
  public errMessage: string;
  public totalNumberOfDays: any = [];
  public availableFunds: any = [];
  public maxDays: any;
  public fileExt: any;
  public displayToast: boolean = false;

  public uriDyn: any;
  public dataSource = [];
  public dataSource2 = [];
  //table
  public executedData = [];
  public connectionStatus = [];
  public fileDetails = [];
  public wrkFlwIds = [];
  /*
  Auth : Vg
  Parms : From Tree
   */
  @Input() componentName: any;
  @Input() isDialog: any;
  @Input() nodeData: any;
  @Output() reCallGridApi: EventEmitter<any> = new EventEmitter();
  @Output() treeEmmiter: EventEmitter<any> = new EventEmitter();

  //public yesterday;
  public yesterday = new Date();
  public executeDate = new Date();
  public executeDateStart = new Date();
  public executeDateEnd = new Date();
  public executeDateMulti = new Date();
  public executeStartDateMulti = new Date();
  public executeEndDateMulti = new Date();
  public minDate = new Date();
  public maxDate = new Date();
  public canTestConnect: boolean = true;
  public excutebuttonDisable: boolean = false;
  public reqObjPeriodLockDate: string;
  public files: any;
  public myFiles: string[] = [];
  public fd: FormData;
  public singleEligible: boolean = false;
  public isRunComplianceData: boolean = false;
  public logStatus: boolean = false;
  public showDateRangeField: boolean = false;
  public isPreviewData: boolean = true;
  public checkConnection: boolean = false;
  public isUploadData: boolean = true;
  // public isDisable: boolean = false;
  public weekEnd: boolean = false;
  public trInputMode = [];
  public selection = new SelectionModel<any>(true, []);
  @ViewChild("fileUpload") fileUpload: any;
  public fileNameDrag = [];
  public fileDisplay: boolean = false;
  fileDragList: any = [];
  lastUploaded: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public router: Router, private messageService: GlobalMessageService, private Workflows: WorkflowTreeComponent,
    private dialogRef: MatDialogRef<ExecuteWorkFlowRunComponent>, private nodeService: NodeService,
    public datepipe: DatePipe, private http: HttpClient, public translate: TranslateService, private localService: LocalStoreService) {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    this.executeDate = this.yesterday;
    this.executeDateStart = this.yesterday;
    this.executeDateEnd = this.yesterday;
    this.executeDateMulti = this.yesterday;
    this.executeStartDateMulti = this.yesterday;
    this.executeEndDateMulti = this.yesterday;
  }

  dialogClose(resetRecord, messageService?): void {

    if (this.executedData != null || this.executedData != undefined) {
      this.fetchDataToExport(this.executedData);

    }
    if (this.dialogRef && this.dialogRef.close != undefined) {
      if (resetRecord) {
        this.dialogRef.close({ close: true, msg: messageService });
      } else {
        this.dialogRef.close({ close: false, msg: messageService });
      }

    } else if (this.componentName == 'alteryx-tree') {
      this.treeEmmiter.emit(true)
    }




    this.display = false;
  }

  ngOnInit(): void {

    this.display11 = true;
    this.flag = true;
    this.isShown = true;
    this.display = this.isDialog
    let addDays = 30;
    this.executeEndDateMulti = new Date(this.executeStartDateMulti);
    this.minDate = new Date(this.executeEndDateMulti);
    this.maxDate = new Date(moment(this.executeEndDateMulti).add(addDays, "days").toDate());
    if (this.data) {
      //For test connection start date and end date difference
      var daysDiffForTestConnectionInit = moment(this.executeEndDateMulti).diff(moment(this.executeStartDateMulti), 'days');
      if (daysDiffForTestConnectionInit >= 1) {
        this.canTestConnect = false;
      } else {
        this.canTestConnect = true;
      }
    }

    if (this.componentName != 'alteryx-tree') {
      if (this.data.selrowdata) {
        this.rowData = this.data.selrowdata;
        this.multipleEligible = this.rowData.length > 1 ? true : false;
        this.isMultiple = this.rowData.length > 1 ? true : false;
        if (this.rowData.length == 1) {
          if (this.data.selrowdata[0].frequency == "DAILY") {
            this.isDailyFrequency = true;
          } else {
            this.isDailyFrequency = false;
          }
          if (this.rowData[0].inputModeType && this.rowData[0].inputModeType.length) {
            var sftpExist = false;
            var dbExist = false;
            this.rowData[0].inputModeType.forEach(function (input) {
              if (input == "DATABASE") {
                dbExist = true;
              }
              if (input == "SFTP") {
                sftpExist = true;
              }
            });
            this.inputModeDB = !sftpExist && dbExist ? true : false;
          }
        }
      }
    } else {
      this.isMultiple = true;
      let triData = this.nodeData
      if (triData.frequency == "DAILY") {
        this.isDailyFrequency = true;
      } else {
        this.isDailyFrequency = false;
      }

      if (triData.workflow[0] && triData.workflow[0].inputMode) {
        let sftpExist = false;
        let dbExist = false;
        let seamlessExist = false;
        let x = triData.workflow[0].inputMode.split("\\|");
        this.trInputMode.push(x)
        if (this.trInputMode.length) {
          this.trInputMode.forEach(function (input) {
            if (input == "DATABASE") {
              dbExist = true;
            }
            if (input == "SFTP") {
              sftpExist = true;
            }
            if (input == "SEAMLESS IMPORT" || input == "seamless import") {
              seamlessExist = true;
            }
          });
        }
        if (sftpExist || seamlessExist) {
          this.inputModeDB = false;
        } else if ((sftpExist || seamlessExist) && dbExist) {
          this.inputModeDB = false;
        } else {
          this.inputModeDB = true;
        }
        // this.inputModeDB = !sftpExist && dbExist ? true : false;
      }
    }


    if (this.rowData != (null || undefined)) {
      this.rowData.forEach(element => {
        if (element != (null || undefined)) {
          this.ids.push(element.id);
          this.prRundt.push(element.alteryxWorkflowMngt.runDate);
        }
      });
    }
    this.getCall = true;
    this.flag = true;
    this.getFileData(true);
  }

  testConnection() {
    var configId = [];
    //if (!(this.isDailyFrequency && this.isMultiple)) {
    if (this.componentName != 'alteryx-tree') {
      this.rowData.forEach(function (item) {
        // Done By Vijay to check if there is inputModeType
        // if(item.inputModeType && item.inputModeType != null || item.inputModeType != undefined){
        item.inputModeType.forEach(function (mode) {
          if (mode.toLowerCase() == "sftp" || mode.toLowerCase() == "seamless import") {
            configId.push(item.id);
          }
        });
        // }
      });
    } else {
      let tmpData = this.nodeData;
      if (tmpData && tmpData.workflow && tmpData.workflow.length) {
        tmpData.workflow.forEach(function (item) {
          let x = item.inputMode.split("\\|");
          x.forEach(function (mode) {
            if (mode.toLowerCase() == "sftp" || mode.toLowerCase() == "seamless import") {
              configId.push(item.workflowId);
            }
          });
        });
      }
    }
    this.ids = configId
    var testReqObj = {
      ids: configId,
      startDate: moment(this.executeStartDateMulti).format("YYYY-MM-DD"),
      endDate: moment(this.executeEndDateMulti).format("YYYY-MM-DD")
      // frequency:this.isDailyFrequency ? "DAILY": "DATE RANGE"
    };
    this.http.post<any>('alteryxExecuteWorkflowV2/validateConnection?', testReqObj, this.httpOptions).subscribe(response => {
      if (response.length || response.success == true) {
        this.connectionStatus = [];
        this.checkConnection = true;
        this.connectionStatus = response;
      } else {
        this.displayToast = true;


        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
          detail: this.translate.instant('something_wrong_please_try_again.', 'Close')
        });
      }
    }, error => {
      this.displayToast = true;

      this.messageService.add({
        closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
        detail: this.translate.instant('something_wrong_please_try_again.', 'Close')
      });
    });
  };

  // getFileData(isFromView?, showDateRangeField?, executeDate?, executeStartDate?, executeEndDate?) {}

  getFileData(isFromView?, showDateRangeField?, executeDate?, executeStartDate?, executeEndDate?, flg?) {
    this.isApiCall = true;
    this.isRequestSend = true;
    this.checkConnection = false;
    this.fileDetails = [];
    this.isMultiple = false;
    this.excutebuttonDisable = false;
    this.canTestConnect = true;
    let addDays = 30;

    if (flg == 'ST') {
      this.executeEndDateMulti = new Date(executeStartDate);
      this.minDate = new Date(executeStartDate);
      this.maxDate = new Date(moment(executeStartDate).add(addDays, "days").toDate());
    }

    if (isFromView) {
      this.isPreviewData = true;
      this.executedData = [];
      if (this.componentName != 'alteryx-tree') {
        if (this.rowData[0].frequency == "DAILY") {
          this.isDailyFrequency = true;
        } else {
          this.isDailyFrequency = false;
        }
      } else {

      }
      // this.isDailyFrequency = false;
      let executeStartDateMulti = moment(this.yesterday).format('YYYY-MM-DD');
      let executeEndDateMulti = moment(this.yesterday).format('YYYY-MM-DD');
      if (moment(executeStartDateMulti).day() == 6) {
        let startDate = moment(executeStartDateMulti).weekday(5).format("YYYY-MM-DD");
        this.executeStartDateMulti = new Date(startDate);
      }
      if (moment(executeStartDateMulti).day() == 0) {
        let startDate = moment(executeStartDateMulti).weekday(-2).format("YYYY-MM-DD");
        this.executeStartDateMulti = new Date(startDate);
      }
      if (moment(executeEndDateMulti).day() == 6) {
        let endDate = moment(executeEndDateMulti).weekday(5).format("YYYY-MM-DD");
        this.executeEndDateMulti = new Date(endDate);
      }
      if (moment(executeEndDateMulti).day() == 0) {
        let endDate = moment(executeEndDateMulti).weekday(-2).format("YYYY-MM-DD");
        this.executeEndDateMulti = new Date(endDate);
      }
      this.PriodicDateMessage = null;
      // if (this.rowData.length > 1) {
      //   this.isMultiple = true;
      //   if (this.rowData[0].alteryxWorkflowMngt.frequency == 'DAILY') {
      //     this.isDailyFrequency = true;
      //   }
      // }
      // if (this.isMultiple && this.isDailyFrequency) {
      //   this.canTestConnect = false;
      // }
    }
    if (showDateRangeField) {
      this.showDateField = false;
    } else {
      this.showDateField = true;
    }
    this.isSubmitDisable = false;
    if (executeStartDate) {
      this.errorMessageStart = null;
      this.btnSubmitRange = false;
    } else {
      this.btnSubmitRange = true;
    }
    if (executeEndDate) {
      this.errorMessageEnd = null;
      this.btnSubmitRange = false;
    } else {
      this.btnSubmitRange = true;
    }
    if (executeDate) {
      this.btnSubmit = false;
    }

    if (executeStartDate && executeEndDate) {
      var checkExecuteStartDate = moment(executeStartDate);
      var checkExecuteEndDate = moment(executeEndDate);
      var daysDiffForTestConnection = checkExecuteEndDate.diff(checkExecuteStartDate, 'days');
      var daysDiff = moment(checkExecuteEndDate).diff(moment(checkExecuteStartDate), 'days') + 1;
      if (this.componentName != 'alteryx-tree') {
        this.rowData.forEach((element, i) => {
          if (element.alteryxWorkflowMngt.frequency == 'DAILY') {
            this.isDailyFrequency = true;
            this.totalNumberOfDays[i] = element.alteryxWorkflowMngt.executionPeriod * 7;
            if (this.rowData.length > 1) {
              this.totalNumberOfDays.push(this.totalNumberOfDays[i]);
            }
            if (this.totalNumberOfDays.length > 1 && this.rowData.length > 1) {
              if (this.totalNumberOfDays[i] > this.totalNumberOfDays[i - 1]) {
                this.maxDays = this.totalNumberOfDays[i];
              } else {
                this.maxDays = this.totalNumberOfDays[i - 1];
              }
            } else {
              this.maxDays = this.totalNumberOfDays[i];
            }
          } else {
            this.totalNumberOfDays = [];
            this.isDailyFrequency = false;
          }
        });
      } else {
        let tmpData = this.nodeData;
        if (tmpData && tmpData.workflow && tmpData.workflow.length) {
          tmpData.workflow.forEach((item, i) => {
            if (tmpData.frequency == 'DAILY') {
              this.isDailyFrequency = true;
              this.totalNumberOfDays[i] = item.executionPeriod * 7;
              if (tmpData.workflow.length > 1) {
                this.totalNumberOfDays.push(this.totalNumberOfDays[i]);
              }
              if (this.totalNumberOfDays.length > 1 && tmpData.workflow.length > 1) {
                if (this.totalNumberOfDays[i] > this.totalNumberOfDays[i - 1]) {
                  this.maxDays = this.totalNumberOfDays[i];
                } else {
                  this.maxDays = this.totalNumberOfDays[i - 1];
                }
              } else {
                this.maxDays = this.totalNumberOfDays[i];
              }
            } else {
              this.totalNumberOfDays = [];
              this.isDailyFrequency = false;
            }
          });
        }
      }
      // if (this.rowData.length > 1) {
      //   this.isMultiple = true;
      //   if (!this.isDailyFrequency) {
      //     this.canTestConnect = true;
      //   } else {
      //     this.canTestConnect = false;
      //   }
      // } else {
      //   if (!this.isDailyFrequency) {
      //     this.canTestConnect = true;
      //   } else {
      //     if (daysDiffForTestConnection >= 1) {
      //       this.canTestConnect = false;
      //     } else {
      //       this.canTestConnect = true;
      //     }
      //   }
      // }
      // if (daysDiff > this.maxDays && this.isDailyFrequency) {
      this.maxDays = 31;
      if (daysDiff > this.maxDays && this.isDailyFrequency) {
        // this.displayToast = true;
        // this.errorMessage = 'Start Date and End Date difference should not be greater than ' + this.maxDays + ' days.';

        // this.messageService.add({
        //   sticky: true, closable: true, severity: 'error', summary: this.translate.instant('error'),
        //   detail: this.errorMessage
        // });
        // this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
        // detail: this.errorMessage });
        this.excutebuttonDisable = true;
        this.isRequestSend = false;
        this.isApiCall = false;
        this.btnSubmitRange = true;
        this.canTestConnect = false;
      } else {
        this.errorMessage = null;
        this.isApiCall = true;
        this.btnSubmitRange = false;
      }
      if (executeStartDate > executeEndDate) {
        // this.errMessage = 'End Date should be greater than start date.';
        // this.errorMessage = 'End Date should be greater than start date.'
        // this.displayToast = true;

        // this.messageService.add({
        //   sticky: true, closable: true, severity: 'error', summary: this.translate.instant('error'),
        //   detail: this.errMessage
        // });
        // this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
        // detail: this.errorMessage });

        this.excutebuttonDisable = true;
        this.canTestConnect = false;
        this.isRequestSend = false;
        this.btnSubmitRange = true;
      } else {
        this.errMessage = null;
        this.btnSubmitRange = false;
      }
      if (this.errorMessage || this.errMessage || this.PriodicDateMessage) {
        this.isApiCall = false;
        this.btnSubmitRange = true;
      } else {
        this.isApiCall = true;
        this.isRequestSend = true;
      }
    } else {
      this.btnSubmit = false;
    }
    if (this.executeDateMulti && this.showDateField) {
      this.btnSubmitRange = false;
    }
    this.multipleEligible = false;
    this.singleEligible = false;
    this.logStatus = false;
    this.inputModeDB = false;
    this.isExecuted = false;
    var selectedId = null;
    var wrkfId = [];
    var sftpExist = false;
    var smImportExist = false;
    // if(this.componentName != 'alteryx-tree'){
    if (this.componentName != 'alteryx-tree') {
      if (this.isMultiple) {
        this.rowData.forEach(function (data) {
          if (data.inputModeType && data.inputModeType.length == 1) {
            data.inputModeType.forEach(function (inputmod) {
              if (inputmod != null || inputmod != undefined) {
                wrkfId.push(inputmod.workflowId);
              }
              if (inputmod.toLowerCase() == "sftp") {
                sftpExist = true;
              }
              if (inputmod.toLowerCase() == "seamless import") {
                smImportExist = true;
              }
            });
          }
        });
      } else {
        this.rowData.forEach(function (data) {
          if (data.inputModeType && data.inputModeType.length == 1) {
            data.inputModeType.forEach(function (inputmod) {
              if (inputmod.toLowerCase() == "seamless import") {
                smImportExist = true;
              }
            });
          }
        });
      }
      if (smImportExist) {
        this.messageService.add({
          closable: true, sticky: true, severity: 'success', summary: this.translate.instant('upload'),
          detail: this.translate.instant("Upload your file manually via 'Choose File' to execute the process", 'close')
        });
      }
    } else {
      let tmpData = this.nodeData;
      if (tmpData && tmpData.workflow && tmpData.workflow.length) {
        tmpData.workflow.forEach(function (item) {
          let x = item.inputMode.split("\\|");
          x.forEach(function (mode) {
            if (mode != null || mode != undefined) {
              wrkfId.push(item.workflowId);
            }
            if (mode.toLowerCase() == "sftp") {
              sftpExist = true;
            }
            if (mode.toLowerCase() == "seamless import") {
              smImportExist = true;
            }
          });
        });
      }
      this.inputModeDB = !sftpExist;
      if (smImportExist) {
        this.messageService.add({
          closable: true, sticky: true, severity: 'success', summary: this.translate.instant('upload'),
          detail: this.translate.instant("Upload your file manually via 'Choose File' to execute the process", 'close')
        });
      }
    }
    var eligible
    if (this.componentName != 'alteryx-tree') {
      this.rowData.forEach(element => {
        if (!element['alteryxWorkflowMngt']['isPreviewData']) {
          this.isDataDumpInTemp = false;
        }
        if (!element['alteryxWorkflowMngt']['uploadData']) {
          this.isUploadData = false;
        }
      });
      eligible = _.some(this.rowData, function (item) {
        return item.alteryxWorkflowMngt.processAlredayRun == true;
      });
      selectedId = this.rowData.map(function (data) {
        return data.id;
      });
    } else {
      if (wrkfId) {
        selectedId = wrkfId
      }
    }

    var reqObjPeriodLockDate = {
      ids: selectedId,
      startDate: moment(this.executeStartDateMulti).format('YYYY-MM-DD'),
    };
    showDateRangeField = true;
    this.excutebuttonDisable = true;
    this.http.post<any>('alteryxExecuteWorkflow/validatePeriodLock', reqObjPeriodLockDate, this.httpOptions).subscribe(response => {
      if (response && response.status == "Success") {
        this.PriodicDateMessage = null;
        this.display = true;
      } else {
        this.PriodicDateMessage = response.message;
        this.errorMessage = null;
        this.canTestConnect = false;
        this.isSubmitDisable = true;
        this.displayToast = true;

        // this.messageService.add({ sticky: true, closable: true, severity: "error", summary: '', detail: this.PriodicDateMessage });
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
          detail: this.PriodicDateMessage
        });
      }
      if (showDateRangeField && this.executeDateStart && this.executeDateEnd && this.PriodicDateMessage == null && this.errorMessage == null && this.errMessage == null) {
        this.isApiCall = true;
        this.fileDetails = [];
        if (this.isRequestSend) {
          var requestObj = {
            ids: selectedId,
            startDate: moment(this.executeStartDateMulti).format('YYYY-MM-DD'),
            endDate: moment(this.executeEndDateMulti).format('YYYY-MM-DD')

            // frequency:this.isDailyFrequency ? "DAILY": "DATE RANGE"
          }
          this.http.post<any>('alteryxExecuteWorkflowV2/getWorkflowFileName', requestObj, this.httpOptions).subscribe(response => {
            this.excutebuttonDisable = false;
            if (response && response.length > 0) {
              this.fileDetails = [];
              let files = [];
              this.selection.clear();
              response.forEach(fileDetailsObject => {
                if (fileDetailsObject
                  && fileDetailsObject.alteryxWorkflowMngt
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos.length
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].file) {
                  fileDetailsObject.file = fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].file;
                } else {
                  fileDetailsObject.file = "";
                }
                if (fileDetailsObject
                  && fileDetailsObject.alteryxWorkflowMngt
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos.length
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileName) {
                  fileDetailsObject.fileName = fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileName;
                  let fileExt = fileDetailsObject.fileName.split(".");
                  fileDetailsObject.fileExt = "." + fileExt[fileExt.length - 1];
                } else {
                  fileDetailsObject.fileName = "";
                }
                if (this.isMultiple) {
                  if (fileDetailsObject && fileDetailsObject.inputModeType && fileDetailsObject.inputModeType.length) {
                    let inputValidInputMode = fileDetailsObject.inputModeType.filter(input => {
                      return (input.toLowerCase() == 'sftp' || input.toLowerCase() == 'seamless import')
                    })

                    if (inputValidInputMode.length) {
                      fileDetailsObject.inputMode = inputValidInputMode.toString().toLowerCase();
                      this.isFileInvalid = (fileDetailsObject.inputMode.includes('seamless import') && fileDetailsObject.connectionStatus != "File Uploaded Successfully") ? true : false;
                      files.push(fileDetailsObject);
                      if (fileDetailsObject.inputMode.includes('seamless import')) {
                        this.selection.select(fileDetailsObject)
                      }
                    }

                    // fileDetailsObject.inputModeType.forEach(function (input) {
                    //   if (input.toLowerCase() == "sftp") {
                    //     files.push(fileDetailsObject);
                    //   }
                    // });
                  }
                } else {
                  if (fileDetailsObject && fileDetailsObject.inputModeType && fileDetailsObject.inputModeType.length) {
                    let inputValidInputMode = fileDetailsObject.inputModeType.filter(input => {
                      return (input.toLowerCase() == 'sftp' || input.toLowerCase() == 'seamless import' || input.toLowerCase() == 'database')
                    });
                    if (inputValidInputMode.length) {
                      fileDetailsObject.inputMode = inputValidInputMode.toString().toLowerCase();
                      this.isFileInvalid = (fileDetailsObject.inputMode.includes('seamless import') && fileDetailsObject.connectionStatus != "File Uploaded Successfully") ? true : false;
                      files.push(fileDetailsObject);
                      if (fileDetailsObject.inputMode.includes('seamless import')) {
                        this.selection.select(fileDetailsObject)
                      }
                    }
                  } else {
                    files.push(fileDetailsObject);
                  }
                }
                this.singleEligible = fileDetailsObject['alteryxWorkflowMngt']['processAlredayRun'];
                if (fileDetailsObject['alteryxWorkflowStatus'] != null && fileDetailsObject['alteryxWorkflowStatus']['isExecuted']) {
                  this.isExecuted = true;
                }
              });
              // if(this.rowData.length > 1){ 
              // let flag = false;
              // response.forEach(workflow => {
              //   if (workflow
              //     && workflow.alteryxWorkflowStatus
              //     && workflow.alteryxWorkflowStatus.dataUploadStatus
              //     && workflow.alteryxWorkflowStatus.dataUploadStatus == 'Pending') {
              //       flag = true;
              //       this.messageService.add({
              //         sticky: true, closable: true, severity: 'error', summary: 'Error',
              //         detail: 'Workflow already running.'
              //       });
              //   }
              // });
              // if(flag){
              //   this.isDisable = true;
              // }else{
              //   this.isDisable = false;
              // }
              // }
              this.fileDetails = files;
            } else if (response && response.length == 0 && this.isDailyFrequency && !this.weekEnd) {
              this.canTestConnect = false;
              this.excutebuttonDisable = true;
              this.isSubmitDisable = true;
              this.displayToast = true;

              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                detail: this.translate.instant('please_select_week_end')
              });
            } else {
              this.displayToast = true;

              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                detail: this.translate.instant('something_went_wrong', 'close',)
              });
            }
          }, error => {
            this.displayToast = true;

            this.messageService.add({
              closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
              detail: this.translate.instant('something_went_wrong', 'close',)
            });
          });
        } else {
          this.excutebuttonDisable = false;
          this.fileDetails = [];
        }
      } else if (!showDateRangeField) {
        this.isApiCall = true;
        this.http.get<any>('alteryxExecuteWorkflow/getWorkflowWithFileName/' + selectedId.toString() + '/' + this.executeDate, this.httpOptions).subscribe(response => {
          if (response) {
            this.fileDetails = response['alteryxWorkflowMngt']['alteryxWorkflowMngtInfos'];
            this.singleEligible = response['alteryxWorkflowMngt']['processAlredayRun'];
            if (response['alteryxWorkflowStatus'] != null && response['alteryxWorkflowStatus']['isExecuted']) {
              this.isExecuted = true;
            }
            if (showDateRangeField) {
              this.showDateField = false;
            }
            else {
              this.showDateField = true;
            }
          } else {
            this.displayToast = true;

            this.messageService.add({
              closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
              detail: this.translate.instant('something_went_wrong', 'close',)
            });
          }
        }, error => {
          this.displayToast = true;

          this.messageService.add({
            closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
            detail: this.translate.instant('something_went_wrong', 'close',)
          });
        });
      }
      else {
        this.excutebuttonDisable = false;
        this.isApiCall = false;
        this.fileDetails = [];
      }
    }, error => {
      this.displayToast = true;

      this.messageService.add({
        closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
        detail: this.translate.instant('something_went_wrong', 'close',)
      });
    });

    if (selectedId.length > 1) {
      if (eligible) {
        this.isMultiple = true;
        this.multipleEligible = true;
      } else {
        this.isMultiple = true;
      }
    } else {
      if (this.componentName != 'alteryx-tree') {
        if (this.rowData[0].inputModeType && this.rowData[0].inputModeType.length) {
          var sftpExist = false;
          var dbExist = false;
          this.rowData[0].inputModeType.forEach(function (input) {
            if (input.toLowerCase() == "database") {
              dbExist = true;
            }
            if (input.toLowerCase() == "sftp") {
              sftpExist = true;
            }
          });

        }
      } else {
        let tmpData = this.nodeData;
        if (tmpData && tmpData.workflow && tmpData.workflow.length) {
          tmpData.workflow.forEach(function (item) {
            let x = item.inputMode.split("\\|");
            x.forEach(function (mode) {
              if (mode.toLowerCase() == "database") {
                dbExist = true;
              }
              if (mode.toLowerCase() == "sftp") {
                sftpExist = true;
              }
            });
          });
        }
      }
      this.inputModeDB = !sftpExist && dbExist ? true : false;
    }
  };

  // runCompilance(evt) {}

  runCompilance(evt) {
    if (this.componentName == 'alteryx-tree') {
      // this.nodeData.ids = this.ids;
      this.nodeData.startDate = moment(this.executeStartDateMulti).format("YYYY-MM-DD");
      this.nodeData.endDate = moment(this.executeEndDateMulti).format("YYYY-MM-DD");
      this.nodeData.isDateRange = true
      // this.nodeData.weekEnd = this.weekEnd;
      this.nodeData.isDataDumpInTemp = this.isDataDumpInTemp
      this.nodeData.workflow.forEach(el => {
        if (el.inputMode) {
          delete el.inputMode;
        }
        if (el.previewData) {
          delete el.previewData;
        }
      })
      delete this.nodeData['runDate'];
      this.nodeService.runCompilance(this.nodeData).subscribe((res) => {
        this.isSubmitDisable = false;
        if (res) {
          this.isRunComplianceData = true;
        }
        if (res && res.length) {
          var executionFail = false;
          res.forEach(element => {
            if (element.failedReason != null || element.connectionStatus != null) {
              executionFail = true;
            }
          });
          if (this.isExecuted || (!this.isUploadData && !this.isDataDumpInTemp)) {
            this.showDateRangeField = false;
            let messageService;
            if (this.isExecuted) {
              this.displayToast = true;
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
                detail: this.translate.instant('process_already_executed', 'close',)
              });

            }
            if (!this.isUploadData && !this.isDataDumpInTemp) {
              this.displayToast = true;
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
                detail: this.translate.instant('Please_upload_from_enterprise', 'close',)
              });

            }
            this.isRunComplianceData = false;
            this.dialogClose(true, messageService);
          } else {
            let messageService;
            if (!executionFail) {
              this.displayToast = true;
              this.messageService.add({
                closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'),
                detail: this.translate.instant('process_started_successfully', 'close')
              });
              this.isRunComplianceData = false;
              this.showDateRangeField = false;
              this.dialogClose(true, messageService);
            } else {
              this.logStatus = true;
              this.checkConnection = false;
              this.executedData = res;
            }
          }
        } else {
          let messageService;
          this.showDateRangeField = false;
          this.isRunComplianceData = false;
          let errorMsg = res.message || 'Something Went Wrong!';
          this.messageService.add({
            closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
            detail: errorMsg
          });
          this.dialogClose(true, messageService);

          this.messageService.add({
            sticky: true, closable: true, severity: 'error', summary: this.translate.instant('error'),
            detail: this.translate.instant(errorMsg)
          });
        }
      }, error => {
        this.displayToast = true;
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
          detail: this.translate.instant('something_went_wrong!', 'close')
        });
      });
      // this.Workflows.resetTree();
      // this.dialogClose(true);
    }
    else {
      if (!this.executeStartDateMulti && !this.executeEndDateMulti) {
        this.errorMessageEnd = 'End date is required.';
        this.errorMessageStart = 'Start date is required.';
        return
      } else if (!this.executeStartDateMulti) {
        this.errorMessageStart = 'Start date is required.';
        return
      } else if (!this.executeEndDateMulti) {
        this.errorMessageStart = 'End date is required.';
        return
      } else if (this.btnSubmitRange || this.btnSubmit) {
        return
      }

      if (this.isMultiple) {
        var todayMulti = new Date();
        todayMulti.setHours(0, 0, 0, 0);
        var exMulti = new Date(this.executeDateMulti);
        exMulti.setHours(0, 0, 0, 0);
        if (exMulti >= todayMulti) {


          this.messageService.add({
            closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
            detail: this.translate.instant('selected_date_should_not_equal_greater_today_date', 'close')
          });
          return false;
        }
      }
      this.isSubmitDisable = true;

      var reqObj = {
        "ids": this.ids,
        "isDataDumpInTemp": this.isDataDumpInTemp,
        "isDateRange": true,
        "startDate": moment(this.executeStartDateMulti).format("YYYY-MM-DD"),
        "endDate": moment(this.executeEndDateMulti).format("YYYY-MM-DD")
        // "frequency":this.isDailyFrequency ? "DAILY": "DATE RANGE"
      }
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var ex = new Date(this.executeDate);
      ex.setHours(0, 0, 0, 0);
      if (ex >= today) {
        this.isSubmitDisable = false;
        this.displayToast = true;
        this.messageService.clear()
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
          detail: this.translate.instant('selected_date_should_not_equal_greater_today_date', 'close')
        });
      } else {
        this.availableFunds = [];
        this.http.post<any>('alteryxExecuteWorkflowV2/executeWorkflow?', reqObj, this.httpOptions).subscribe(res => {
          this.isSubmitDisable = false;
          if (res) {
            this.isRunComplianceData = true;
          }
          if (res && res.length) {
            var executionFail = false;
            res.forEach(element => {
              if (element.failedReason != null || element.connectionStatus != null) {
                executionFail = true;
              }
            });
            if (this.isExecuted || (!this.isUploadData && !this.isDataDumpInTemp)) {
              this.showDateRangeField = false;
              let messageService;
              if (this.isExecuted) {
                this.displayToast = true;

                this.messageService.add({
                  closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                  detail: this.translate.instant('process_already_executed', 'close')
                });

              }
              if (!this.isUploadData && !this.isDataDumpInTemp) {
                this.displayToast = true;
                this.messageService.add({
                  closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                  detail: this.translate.instant('please_setup_upload_from_enterprise_login_and_rerun_the_workflow', 'close')
                });

              }
              this.isRunComplianceData = false;
              this.dialogClose(true, messageService);
            } else {
              let messageService;
              if (!executionFail) {
                this.displayToast = true;
                this.messageService.add({
                  closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'),
                  detail: this.translate.instant('process_started_successfully', 'Close',)
                });

                this.isRunComplianceData = false;
                this.showDateRangeField = false;
                this.dialogClose(true, messageService);
              } else {
                this.logStatus = true;
                this.checkConnection = false;
                this.executedData = res;
              }
            }
          } else {
            let messageService;
            this.showDateRangeField = false;
            this.isRunComplianceData = false;
            let errorMsg = res.message || 'Something Went Wrong!';
            this.displayToast = true;
            this.messageService.add({
              closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
              detail: errorMsg
            });
            this.dialogClose(true, messageService);

            this.messageService.add({
              sticky: true, closable: true, severity: 'error', summary: this.translate.instant('error'),
              detail: this.translate.instant(errorMsg)
            });
          }
        }, error => {
          this.displayToast = true;

          this.messageService.add({
            closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
            detail: this.translate.instant('something_went_wrong')
          });
        });
      }
    }
  };

  downloadExportFile(res) {
    let usr = JSON.parse(this.localService.getItem('fss'))['enterpriseName'];
    let startDate = moment(this.executeStartDateMulti).format('YYYY-MM-DD');
    let endDate = moment(this.executeEndDateMulti).format('YYYY-MM-DD');
    var flNm = usr + "_compliance_status_" + startDate + "_" + endDate + ".csv";
    var a = document.createElement("a");
    var blob = new Blob([res], { type: res.ContentType });
    a.href = URL.createObjectURL(blob);
    a.download = flNm;
    a.click();

    this.messageService.add({
      sticky: true,
      severity: "success",
      summary: this.translate.instant("success"),
      detail: this.translate.instant("status_file_success"),
    });
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += this.translate.instant(headerList[index]) + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    this.downloadExportFile(str);
  }

  fetchDataToExport(data?: any) {
    var csvData = [];
    let workflow;
    let log;
    let sts;
    let isDownloadable;
    data.forEach(itm => {
      if (itm) {
        if (itm.alteryxWorkflowMngt && itm.alteryxWorkflowMngt.sourceName) {
          workflow = itm.alteryxWorkflowMngt.sourceName;
        }
        log = itm.failedReason != null ? itm.failedReason : itm.connectionStatus;
        sts = itm.failedReason != null || itm.connectionStatus != null ? "Failed" : "Connected";

        log = log == null ? " " : log;
        if (itm.failedReason != null || itm.connectionStatus != null) {
          isDownloadable = true;
        }

        if (workflow) {
          csvData.push({
            "workflow": workflow,
            "error_log": log,
            "status": sts
          })
        }
      }
    });
    if (isDownloadable) {
      this.ConvertToCSV(csvData, ['workflow', 'error_log', 'status'])
    }
  }

  onChange(event: any) {
    this.files = [];
    this.fileNameDrag = [];
    this.files = event.currentFiles;
    if (this.files != undefined) {
      var configId: any = [];
      this.fd = new FormData();
      let uploadedFiles = _.cloneDeep(this.files.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0)).reverse());
      this.fileDragList = [];
      this.fileDetails.forEach(file => {
        if (file.inputMode && !configId.includes(file.id)) {
          configId.push(file.id);
        }
        if (file.fileName.includes('?')) {
          if (file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileNamePrefixType == "variable") {
            let suffix = file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileNameSuffix;
            let filename = file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileName;
            for (i = 0; i < uploadedFiles.length; i++) {
              if (uploadedFiles[i].name.includes(filename) && uploadedFiles[i].name.includes(suffix)) {
                let pattern = uploadedFiles[i].name.split(filename).join().split(suffix)[0];
                let fileDetailPattern = file.fileName.split(filename).join().split(suffix)[0];
                if (pattern && fileDetailPattern && pattern.split(' ')[0] == fileDetailPattern.split(' ')[0]) {
                  this.fileDragList.push(uploadedFiles[i]);
                  uploadedFiles.splice(i);
                }
              }
            }
          } else if (file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileNameSuffixType == "variable") {
            let prefix = file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileNamePrefix;
            let filename = file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileName;
            for (i = 0; i < uploadedFiles.length; i++) {
              if (uploadedFiles[i].name.includes(filename) && uploadedFiles[i].name.includes(prefix)) {
                let pattern = uploadedFiles[i].name.split(filename).join().split(prefix)[0];
                let fileDetailPattern = file.fileName.split(filename).join().split(prefix)[0];
                if (pattern && fileDetailPattern && pattern.split(' ')[0] == fileDetailPattern.split(' ')[0]) {
                  this.fileDragList.push(uploadedFiles[i]);
                  uploadedFiles.splice(i);
                }
              }
            }
          } else {
            let suffix = file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileNameSuffix;
            let prefix = file.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileNamePattern.fileNamePrefix;

            for (i = 0; i < uploadedFiles.length; i++) {
              if (uploadedFiles[i].name.includes(prefix) && uploadedFiles[i].name.includes(suffix)) {
                let pattern = uploadedFiles[i].name.split(prefix).join().split(suffix)[0];
                let fileDetailPattern = file.fileName.split(prefix).join().split(suffix)[0];
                if (pattern && fileDetailPattern && pattern.split(' ')[0] == fileDetailPattern.split(' ')[0]) {
                  this.fileDragList.push(uploadedFiles[i]);
                  uploadedFiles.splice(i);
                }
              }
            }
          }
        } else {
          let uploadFile = this.files.filter(item1 => file.fileName === item1.name)[0];
          this.fileDragList.push(uploadFile)
        }
      })
      for (var i = 0; i < this.fileDragList.length; i++) {
        this.fd.append("files", this.fileDragList[i]);
      }
      for (var i = 0; i < this.files.length; i++) {
        this.fileNameDrag.push(this.files[i].name)
      }

      this.fd.append('ids', configId);
      this.fd.append('startDate', moment(this.executeStartDateMulti).format("YYYY-MM-DD"));
      this.fd.append('endDate', moment(this.executeEndDateMulti).format("YYYY-MM-DD"));


      if (this.fileDragList.length) {
        this.http.post<any>('alteryxExecuteWorkflowV2/sftpFileUpload', this.fd).subscribe((res) => {
          if (res.success) {
            if (res && res.data && res.data.length > 0) {
              this.selection.clear();
              let isFileInvalid: boolean = false;
              let fileDetails: any = this.fileDetails.map(object => { return { ...object } });
              res.data.forEach(fileDetailsObject => {
                if (fileDetailsObject
                  && fileDetailsObject.alteryxWorkflowMngt
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos.length
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].file) {
                  fileDetailsObject.file = fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].file;
                } else {
                  fileDetailsObject.file = "";
                }
                if (fileDetailsObject
                  && fileDetailsObject.alteryxWorkflowMngt
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos.length
                  && fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileName) {
                  fileDetailsObject.fileName = fileDetailsObject.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileName;
                  let fileExt = fileDetailsObject.fileName.split(".");
                  fileDetailsObject.fileExt = "." + fileExt[fileExt.length - 1];
                } else {
                  fileDetailsObject.fileName = "";
                }
                if (fileDetailsObject && fileDetailsObject.inputModeType && fileDetailsObject.inputModeType.length) {
                  let inputValidInputMode = fileDetailsObject.inputModeType.filter(input => {
                    return (input.toLowerCase() == 'sftp' || input.toLowerCase() == 'seamless import')
                  })
                  if (inputValidInputMode.length) {
                    fileDetailsObject.inputMode = inputValidInputMode.toString().toLowerCase();
                  }
                }
              });
              for (let i = 0; i < fileDetails.length; i++) {
                for (let j = 0; j < res.data.length; j++) {
                  if (fileDetails[i].id == res.data[j].id && fileDetails[i].fileName == res.data[j].fileName) {
                    fileDetails[i] = res.data[j];
                  }
                }
                if (fileDetails[i].inputMode.includes('seamless import')) {
                  this.selection.select(fileDetails[i])
                }
                
              }
              this.fileDetails = fileDetails;
              this.isFileInvalid = isFileInvalid;
            }
            this.displayToast = true;
            if (this.isFileInvalid) {
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
                detail: this.translate.instant('Please upload correct file', 'close')
              });
            } else {
              this.messageService.add({
                closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'),
                detail: this.translate.instant('Correct File uploaded successfully', 'close')
              });
            }
          } else {
            this.displayToast = true;
            this.messageService.add({
              closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
              detail: this.translate.instant('upload_failed', 'close')
            });
          }
        });
      } else {
        this.displayToast = true;
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
          detail: this.translate.instant('Please upload correct file', 'close')
        });
      }
    }
    
    this.fileUpload.clear();
  }

  selectionChange(row) {
    this.selection.toggle(row)
    let isFileInvalid = false;
    if (this.selection && this.selection.selected && this.selection.selected.length) {
      this.selection.selected.forEach(file => {
        if (file.inputMode.includes('seamless import') && file.connectionStatus != 'File Uploaded Successfully') {
          isFileInvalid = true;
        }
      });
      this.isFileInvalid = isFileInvalid;
    } else {
      this.isFileInvalid = isFileInvalid;
    }
  }
  /* File Upload On Sftp/Ftp Server */
  fileNameOpen() {
    this.fileDisplay = true;
  }
}
