/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { name } from 'aws-sdk/clients/importexport';
import { LazyLoadEvent } from 'primeng/api';
import { fadeInRightAnimation } from '../../../../../src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../../../../src/@fury/animations/fade-in-up.animation';
import { ListColumn } from '../../../../../src/@fury/shared/list/list-column.model';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { CommonService } from '../../services/commonServices/common.service';
import { GlobalService } from '../../services/commonServices/global.service';
import { GridService } from '../../services/gridServices/grid.service';

@Component({
  selector: 'fury-smart-view-table',
  templateUrl: './smart-view-table.component.html',
  styleUrls: ['./smart-view-table.component.scss'],
  animations: [fadeInRightAnimation, fadeInUpAnimation]

})
export class SmartViewTableComponent implements OnInit {
  public gridDataUrl: any;
  public frameworkComponents: any
  public moduleNameGrid: any;

  @Output() toggleChangeSmartTable = new EventEmitter<string>();
  @Input() componentName: any;
  @Input() columnData: any;
  @Input() columnName: any;
  @Input() columnProperty: any;
  @Input() columnVisible: any;
  @Input() columnModelProperty: any;
  @Input() heading: String;
  @Input() subComponent: any;
  pageSize = 10;
  dataSource: MatTableDataSource<any>;





  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  myFlagForSlideToggle: boolean;

  constructor(public gridService: GridService, public globalService: GlobalService, private scrollDispatcher: ScrollDispatcher, private el: ElementRef, private commonService: CommonService,
    private httpClient: HttpClient, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
    this.frameworkComponents = {
      componentName: this.componentName,
      subComponent: this.subComponent

    };
  }

  get visibleColumns() {
    return this.columnData.filter(column => column.visible).map(column => column.property);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.gridDataUrl = apiConstants['tradeList']['gridDataUrl']
    if (this.componentName == "orderList") {
      this.gridDataUrl = this.gridDataUrl + 0 + "/" + false;
    } else {
      this.gridDataUrl = this.gridDataUrl + '0';
    }
    this.gridService.getGridDataPost(this.gridDataUrl, (cbs) => {

      let columnsFromComponent = this.columnData;
      let smartTableData = [];
      cbs.resultList.map(element => {
        let formattedObj = new Object();
        columnsFromComponent.forEach(element1 => {
          let firstKey = element1.gridProperty[0];
          let secondKey = element1.gridProperty[1];
          if (typeof element[firstKey] === 'object' && element[firstKey] !== null) {
            element[element1.property] = element[firstKey] && element[firstKey][secondKey];
          }
        });
        smartTableData.push(formattedObj);
      });
      this.dataSource.data = cbs.resultList;
    }, (cbe) => {
    })
    this.myFlagForSlideToggle = false;
    this.globalService.getNewUserInfo().subscribe(info => {
      this.myFlagForSlideToggle = (info.toggleType === 'true');
    })

    this.commonService.moduleNameObs$.subscribe((moduleName) => {
      this.moduleNameGrid = moduleName;
    });

    this.dataSource.data = Array.from({ length: 10000 }).map(() => this.gridDataUrl);

    this.dataSource.data = Array.from({ length: 10000 });

  }

  loadCarsLazy(event: LazyLoadEvent) {
    //simulate remote connection with a timeout 
    setTimeout(() => {
      //load data of required page
      let loadedDataSource = this.dataSource.data.slice(event.first, (event.first + event.rows));

      //populate page of virtual cars
      Array.prototype.splice.apply(this.dataSource.data, [...[event.first, event.rows], ...loadedDataSource]);

      //trigger change detection
      this.dataSource.data = [...this.dataSource.data];
    }, Math.random() * 1000 + 250);
  }

  toggleChange(event) {
    this.toggleChangeSmartTable.emit(event);
  }

  ngAfterViewInit() {
    this.scrollDispatcher
      .ancestorScrolled(this.el)
      .subscribe(event => this.onWindowScroll());
  }

  onWindowScroll() {
    var rows = document.querySelector('.mat-header-row');
    if (rows && rows.getBoundingClientRect().top == 63) {
      rows.classList.add("blue_bg");
    }
  }
}