import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientGridService {

  public filterHeaders: any = [];

  constructor(private http: HttpClient) { }

  
  updateUserStatus(data: any): Observable<any> {
    return this.http.put<any>('userV2/updateUserStatus', data);
  }

  checkMultiAuth(auth, authList) {
    let isAuth = true
    if (auth) {
      let list = auth.split(',')
      isAuth = list.some(item => authList.includes(item))
    }
    return isAuth
  }

}
