<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div class="title">{{ current | translate }}</div>
<div class="crumbs" fxLayout="row" fxLayoutAlign="start center">

  <div *ngFor="let crumb of crumbs" class="crumb" fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="crumb.toLowerCase() == 'settings'" class="link">{{ crumb|translate}}</div>
    <div *ngIf="crumb.toLowerCase() != 'settings'" class="link" [routerLink]="[breadRoute]">
      {{crumb|translate}}
    </div>
  </div>
  <div *ngIf="!cardName.includes('reconcilation') &&  cardName.length && roleAs=='Manager' || roleAs=='User'">

    <div *ngFor="let cardName of cardName" class="crumb current" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="chevron">chevron_right</mat-icon>
      <div *ngIf="cardName == 'settings'" class="link">{{ cardName|translate}}</div>
      <div *ngIf="cardName != 'settings'" class="link" [routerLink]="[breadRoute]">{{ cardName|translate}}
      </div>
    </div>
  </div>
  <div *ngIf="!crumbNew.includes('reconcilation') &&  crumbNew.length && crumbNew[0]!=undefined">

    <div *ngFor="let crumbnew of crumbNew" class="crumb current" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="chevron">chevron_right</mat-icon>
      <div *ngIf="crumbnew.toLowerCase() == 'settings'" class="link">{{ crumbnew|translate}}</div>
      <div *ngIf="crumbnew.toLowerCase() != 'settings' && componentName == 'allsmupload'" class="link"
        [routerLink]="[breadRouteUpload]">{{ crumbnew|translate}}
      </div>
      <div *ngIf="crumbnew.toLowerCase() != 'settings' && componentName != 'allsmupload'" class="link"
        [routerLink]="[breadRouteNew]">{{ crumbnew|translate}}
      </div>
    </div>
  </div>

  <div *ngIf="parentHeading" class="crumb current" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ parentHeading | translate }}</div>
  </div>

  <div *ngIf="current != undefined" class="crumb current" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ current | translate }}</div>

  </div>
  <div *ngIf="currentNew == 'Allocated'" class="crumb current" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="chevron">chevron_right</mat-icon>
    <div class="link">{{ currentNew | translate }}</div>

  </div>




</div>

<div class="icobounce bouncebox tradeins" *ngIf="componentName!='contributionDistributionAdjustment' && componentName!='grossCashFlowAdjustment'">
  <mat-icon *ngIf="isShow" class="icon infoico" (click)="toggleDropdown1()" matTooltip="{{'instructions' | translate}}">

    help_outline
  </mat-icon>
</div>



<p-dialog class="delModal Dialog_width_Medium breadcrumbinfo" [modal]="true" [(visible)]="displayBasic"
  [baseZIndex]="10000"
  *ngIf="!(componentName=='sidePocket' || componentName=='reportDeliveryConfig' || componentName== 'customRatio' || componentName== 'incomeDistributionBase' || componentName == 'takeOverPerfBaseFee')">



  <div class="col-md-12 instructionmodal">
    <mat-icon class="icon infoico closex" (click)="hideDialogBox()"
      style="position: absolute;right: 1.6em;top: 1em; z-index: 2;"> close </mat-icon>
    <div [@fadeInUp] class="card" id="formstiky">
      <mat-vertical-stepper #verticalStepper="matVerticalStepper">
        <mat-step>
          <ng-template matStepLabel>{{current|translate}}{{'instructions' |translate}}

          </ng-template>

          <div class="content" fxLayout="column" fxLayoutGap="8px">
            <div class="instructioniframe" *ngIf="videoshow">
              <video controls controlsList="nodownload">
                <source [src]="videoUrls[0]" type="video/mp4">
              </video>
            </div>
            <div class="instructioniframe" *ngIf="componentName=='resetOpenTaxLots'">
              <video controls controlsList="nodownload">
                <source src="https://si.formidium.com/FAQ/Reset+Tax+Lot.mp4" type="video/mp4">
              </video>
            </div>
            <div class="instructioniframe" *ngIf="componentName=='runTaxLotsProcess'">
              <video controls controlsList="nodownload">
                <source src="https://si.formidium.com/FAQ/Tax+Lot+Process.mp4" type="video/mp4">
              </video>
            </div>
            <!-- <div class="instructioniframe" *ngIf="componentName=='predefinedJe'">
                <video controls controlsList="nodownload">
                  <source   src="https://seamlessserver.s3.amazonaws.com/FAQ/pre_defined.mp4" type="video/mp4">
                </video>
              </div> -->
            <!-- <div class="instructioniframe" *ngIf="componentName=='chartOfAccounts'">
                <video controls controlsList="nodownload">
                  <source   src="https://seamlessserver.s3.amazonaws.com/FAQ/Final_Chart_of_Accounts.mp4" type="video/mp4">
                </video>
              </div> -->
            <!-- <div class="instructioniframe" *ngIf="componentName=='journalentry'">
                <video controls controlsList="nodownload">
                  <source   src="https://seamlessserver.s3.amazonaws.com/FAQ/Journal_Entry.mp4" type="video/mp4">
                </video>
              </div> -->
            <!-- <div class="instructioniframe" *ngIf="componentName=='nonTradeActivity'">
                <video controls controlsList="nodownload">
                  <source   src="https://seamlessserver.s3.amazonaws.com/FAQ/Non_Trade_Activity.mp4" type="video/mp4">
                </video>
              </div> -->
            <!-- <div class="instructioniframe" *ngIf="componentName=='settingsassetbasedfeeentry'">
                <video controls controlsList="nodownload">
                  <source   src="https://seamlessserver.s3.amazonaws.com/FAQ/Asset+Based+Fee.mp4" type="video/mp4">
                </video>
              </div> -->
            <!-- <div class="instructioniframe" *ngIf="componentName=='createcapitalactivity'">
                <video controls controlsList="nodownload">
                  <source   src="https://s3://videotesting01/Video/Asset Based Fee-test.mp4" type="video/mp4">
                </video>
              </div> -->
            <!-- <div class="instructioniframe" *ngIf="componentName=='settingsperformancebasedfeeentry'">
                <video controls controlsList="nodownload">
                  <source   src="https://seamlessserver.s3.amazonaws.com/FAQ/Performance+Based+Fee.mp4" type="video/mp4">
                </video>
              </div> -->
          </div>
        </mat-step>
        <mat-step *ngIf="componentName=='TradeEntryComponent'">
          <ng-template matStepLabel>Basic Details</ng-template>
          <div class="instructiontable">
            <!-- <table mat-table [dataSource]="data" class="breadcrumbinfot first1">
              <ng-container matColumnDef="field">
                <th style="width: 30.1%;" mat-header-cell *matHeaderCellDef> Field </th>
                <td mat-cell *matCellDef="let element">  </td>
              </ng-container>
              <ng-container matColumnDef="desc">
                <th  mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"></td> 
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; "></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table> -->
            <table mat-table [dataSource]="data" class="breadcrumbinfot">
              <ng-container matColumnDef="field">
                <th style="width: 30.1%;" mat-header-cell *matHeaderCellDef> Fields </th>
                <td mat-cell *matCellDef="let element"> {{element.field}} </td>
              </ng-container>
              <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; "></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-step>
        <mat-step *ngIf="componentName=='TradeEntryComponent'">
          <ng-template matStepLabel>Fee Details</ng-template>
          <div class="instructiontable">
            <table mat-table [dataSource]="data1" class="breadcrumbinfot">
              <ng-container matColumnDef="field">
                <th style="width: 30.1%;" mat-header-cell *matHeaderCellDef>Fields </th>
                <td mat-cell *matCellDef="let element"> {{element.field}} </td>
              </ng-container>
              <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; "></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-step>
        <mat-step *ngIf="componentName=='TradeEntryComponent'">
          <ng-template matStepLabel>Other Details</ng-template>
          <div class="instructiontable">
            <table mat-table [dataSource]="data2" class="breadcrumbinfot">
              <ng-container matColumnDef="field">
                <th style="width: 30.1%;" mat-header-cell *matHeaderCellDef> Fields </th>
                <td mat-cell *matCellDef="let element"> {{element.field}} </td>
              </ng-container>
              <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; "></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>


  </div>

</p-dialog>

<p-dialog
  *ngIf="componentName=='sidePocket' || componentName=='reportDeliveryConfig' || componentName=='customRatio' || componentName=='incomeDistributionBase' || componentName == 'takeOverPerfBaseFee' "
  class="intromodal deliveryModal onlyPdf" width="500" height="200" [(visible)]="displayBasic" [modal]="true"
  [(header)]="modalHeading">
  <div class="mainintroc">
    <div class="introductionp pt-2" *ngIf="componentName=='reportDeliveryConfig'">
      <iframe width="100%" height="100vh"
        src="https://sudraniaoperations.s3.amazonaws.com/Report+Delivery+Feature+Users+Guide.pdf#toolbar=0"></iframe>
    </div>
    <div class="introductionp pt-2" *ngIf="componentName=='sidePocket'">
      <iframe width="100%" height="100vh"
        src="https://sudraniaoperations.s3.amazonaws.com/Side+Pocket+in+Seamless.pdf#toolbar=0"></iframe>
    </div>
    <div class="introductionp pt-2" *ngIf="componentName=='incomeDistributionBase' || componentName=='customRatio'">
      <iframe width="100%" height="100vh"
        src="https://sudraniaoperations.s3.amazonaws.com/Commitment%26Allocation.pdf#toolbar=0"></iframe>
    </div>

    <div class="introductionp pt-2" *ngIf="componentName=='takeOverPerfBaseFee'">
      <iframe width="100%" height="100vh"
        src="https://sudraniaoperations.s3.amazonaws.com/Takeover+fee+Doc-Draft+(1).pdf#toolbar=0"></iframe>
    </div>

  </div>
</p-dialog>