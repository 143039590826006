/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp..
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. may be obtained
* by visitng www.formidium.com.
******************************************************************************/

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ReportService } from '../../seamlessReports/reports.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
import { CommonService } from 'src/app/shared/services/commonServices/common.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GenerateFsService {

  public reportStartDate: any;
  public reportEndDate: any;
  public currentUser: any;
  public fundList = [];
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  assetManagementList: any = [];
  amcDetails: any;
  constructor(private http: HttpClient, private reportService: ReportService, private localService: LocalStoreService, private commonService: CommonService,) { }

  basicDetails() {
    return [
      { id: 'fund', controlType: 2, label: 'fund', viewname: 'fund', name: 'fund', order: 1, value: null, required: true, appearance: "fill", fill: this.fundList, readonly: false, class: "col-lg-12 mb-c", style: 'display: block' },
      { id: 'fundGroupName', controlType: 1, label: 'fund_group_name', viewname: 'fundGroupName', name: 'fundGroupName', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-12 mb-c", style: 'display: none' },
      { id: 'startDate', controlType: 3, label: 'Period From', viewname: 'startDate', name: 'startDate', order: 3, value: this.reportStartDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'endDate', controlType: 3, label: 'Audit report as of', viewname: 'endDate', name: 'endDate', order: 3, value: this.reportEndDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
    ];
  }

  getformControlsDetail() {
    return new Promise(resolve => {
      this.currentUser = JSON.parse(this.localService.getItem('fss'));
      this.reportStartDate = this.reportService.getFilterDateStart(this.currentUser.reportFrequency);
      this.reportEndDate = this.reportService.getFilterDate(this.currentUser.reportFrequency);
      this.fetchMultipleApiBasic().subscribe((response) => {
        let basicDetailsData = this.basicDetails();
        let result = response.resultList && response.resultList.length ? response.resultList : [];
        this.fundList = result.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
        basicDetailsData[0].fill = this.fundList;
        basicDetailsData[2].value = this.reportStartDate ? new Date(this.reportStartDate) : new Date();
        basicDetailsData[3].value = this.reportEndDate ? new Date(this.reportEndDate) : new Date();
        resolve(basicDetailsData);
      })
    })
  }

  fetchMultipleApiBasic() {
    return this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
  }

  getFsConfig(fundId, endDate, startDate, configId, fundGroupName) {
    if (fundId) {
      return this.http.get<any>('fSConfig/filterByFundId?id=' + fundId + '&fundGroupName=' + '&startDate=' + startDate + '&endDate=' + endDate + '&configId=' + configId, this.httpOptions);
    } else {
      return this.http.get<any>('fSConfig/filterByFundId?id=' + '&fundGroupName=' + fundGroupName + '&startDate=' + startDate + '&endDate=' + endDate + '&configId=' + configId, this.httpOptions);
    }
  }

  downloadCSV(data, file) {
    this.commonService.GenericDownload(data,file)
  }
  convertToPlain(html) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    const innerHTML = tempDivElement.innerText || "";
    tempDivElement.remove();
    return innerHTML
  }

  getFormatedContentArrays(tempContentArray) {
    let finalTitle
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = tempContentArray;

    const brElements = tempDiv.getElementsByTagName('br');
    const brString = Array.from(brElements).map(br => br.outerHTML).join('');

    if (!brString) {
      return tempContentArray;
    }
    if (this.validateContent(tempContentArray)) {
      return tempContentArray
    }

    let consecutiveBr = brElements.length > 1;
    for (var i = 1; i < brElements.length; i++) {
      if (brElements[i].previousSibling !== brElements[i - 1]) {
        consecutiveBr = false;
        break;
      }
    }
    const singleBr = brElements.length === 1;
    const mixedBr = brElements.length > 1 && !consecutiveBr;
    const brStatus = consecutiveBr ? "consecutive" : (singleBr ? "single" : "mixed");

    const CompareTags = this.extractAndCompareTags(tempContentArray)
    if (CompareTags.areTagsSame && CompareTags.openingString && CompareTags.closingString) {
      let openingTag = CompareTags.openingString
      let closingTag = CompareTags.closingString   //   /<\/\w+>/   

      const ConsecutiveBrTags = this.getMaxConsecutiveBrTags(tempContentArray)
      if (ConsecutiveBrTags) {
        const brTagRegex = new RegExp(ConsecutiveBrTags, "g");
        if (brTagRegex.test(tempContentArray)) {
          var replacedString = tempContentArray.replace(brTagRegex, '<br>');
          let lines = replacedString.split('<br>');
          let newHtmlString = lines.join(closingTag + openingTag);
          finalTitle = newHtmlString
        }
      }
      //  else if (/<br><br><br>/g.test(tempContentArray)) {
      //   var replacedString = tempDiv.innerHTML.replace(/<br><br><br>/g, '<br>');
      //   let lines = replacedString.split('<br>');
      //   let newHtmlString = lines.join(closingTag + openingTag);
      //   finalTitle = newHtmlString
      // }
      else {
        let lines = tempContentArray.split('<br>');
        let newHtmlString = lines.join(closingTag + openingTag);
        finalTitle = newHtmlString
      }
    } else {
      return tempContentArray;
    }
    tempDiv.remove();
    return finalTitle
  }
  extractAndCompareTags(content) {
    // const openingRegex = /<([a-z]+)\b[^>]*>/g;
    // const closingRegex = /<\/([a-z]+)\b([^>]*)>/g;

    let openingTags = [];
    let closingTags = [];

    openingTags = this.getOpeningTags(content)
    closingTags = this.getEndingTags(content)

    // let openingMatch;
    // while ((openingMatch = openingRegex.exec(content)) !== null) {
    //   const tagName = openingMatch[1].toLowerCase();
    //   if (tagName !== 'br') {
    //     openingTags.push({ tag: openingMatch[0], key: openingMatch[1] });
    //   }
    // }

    // let closingMatch;
    // while ((closingMatch = closingRegex.exec(content)) !== null) {
    //   if (!/<br\s*\/?>/.test(closingMatch[0])) {
    //     closingTags.push({ tag: closingMatch[0], key: closingMatch[1] });
    //   }
    // }

    const sortedOpeningTags = openingTags.slice().sort((a, b) => a.key.localeCompare(b.key));
    const sortedClosingTags = closingTags.slice().sort((a, b) => a.key.localeCompare(b.key));

    const areTagsSame = sortedOpeningTags.length > 0 && sortedClosingTags.length > 0 &&
      sortedOpeningTags.length === sortedClosingTags.length &&
      sortedOpeningTags.every((tag, index) => tag.key === sortedClosingTags[index].key);

    const closingString = closingTags.map(tagObj => tagObj.tag).join('');
    const openingString = openingTags.map(tagObj => tagObj.tag).join('');

    return { areTagsSame, openingString, closingString };
  }

  getEndingTags(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const tags = [];
    const nodes = doc.body.childNodes;

    function traverse(node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.childNodes.length == 1) {
          // node.childNodes.length > 0
          Array.from(node.childNodes).forEach(traverse);
        }
        if (node.tagName.toLowerCase() !== 'br') {
          tags.push({ tag: `</${node.tagName.toLowerCase()}>`, key: node.tagName.toLowerCase() });
        }
      }
    }

    nodes.forEach(traverse);
    return tags;
  }

  getOpeningTags(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const tags = [];
    const nodes = doc.body.childNodes;

    function traverse(node) {
      if (node.nodeType === Node.ELEMENT_NODE && node.tagName.toLowerCase() !== 'br') {
        const openingTag = node.outerHTML.match(/<[^>]+>/)[0];
        tags.push({ tag: openingTag, key: node.tagName.toLowerCase() });
        if (node.childNodes.length == 1) {
          // node.childNodes.length > 0
          Array.from(node.childNodes).forEach(traverse);
        }
      }
    }

    nodes.forEach(traverse);
    return tags;
  }



  validateContent(tempContentArray) {

    const openingTagRegex = /^<\w+[^>]*>/;  //    /^<\w+>/   //   /<(\w+)[^>]*>/
    const closingTagRegex = /<\/\w+>$/;   //  /<\/(\w+)>/
    const openingTagMatch = tempContentArray.match(openingTagRegex);
    const closingTagMatch = tempContentArray.match(closingTagRegex);

    if (!openingTagMatch || !closingTagMatch) {
      return true;
    }

    // const notValidContent = [
    //   '</em></strong><br><strong><em>',
    //   '<div data-empty="true" style="text-align: justify;"><br></div>'
    // ];
    // let isInclude = notValidContent.some(content => tempContentArray.includes(content));
    // return isInclude


    // /(<[^>]+>)<br>(<[^>]+>)/g;   Regular expression to match <br> with tags on both sides (AdjacentTags)
    // /(<[^>]+>)<br>(?!<br>)(<[^>]+>)/    Regular expression to match <br> with tags on both sides   but excluding adjacent muliple <br> tags
    const regex = /(<[^>]+>)<br>(?!<br>)(<[^>]+>)/g;
    const hasTagsAroundBr = regex.test(tempContentArray);
    if (hasTagsAroundBr) {
      return true;
    }
  }

  getMaxConsecutiveBrTags(str) {
    var regex = /(<br>)+/g;
    var matches = str.match(regex);
    var max = 0;
    if (matches) {
      matches.forEach((match) => {
        var count = (match.length / 4);
        if (count > max) {
          max = count;
        }
      });
    }
    if (max >= 2) {
      return '<br>'.repeat(max)
    }
  }



  // lastTag(tempContentArray) {
  //   var match = tempContentArray.match(/<(\w+)[^>]*>/g);
  //   return match ? match.pop() : null;
  // }

  getHtmlTags(htmlString) {
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlString, "text/html");
    let targetArray = [];
    let tags = parsedHtml.getElementsByTagName("*");
    for (let i = 0; i < tags.length; i++) {
      let tagName = tags[i].tagName.toLowerCase();
      let targetTags = ['br', 'strong', 'em', 'u', 's', 'sub', 'sup', 'ol', 'ul', 'li']
      if (targetTags.includes(tagName)) {
        switch (tagName) {
          case 'strong':
            targetArray.push('BOLD');
            break;
          case 'br':
            targetArray.push('BREAKLINE');
            break;
          case 'em':
            targetArray.push('ITALIC');
            break;
          case 'u':
            targetArray.push('UNDERLINE');
            break;
          case 's':
            targetArray.push('STRIKETHROUGH');
            break;
          case 'sub':
            targetArray.push('SUBSCRIPT');
            break;
          case 'sup':
            targetArray.push('SUPERSCRIPT');
            break;
          case 'ol':
            targetArray.push('ORDERED LIST');
            break;
          case 'ul':
            targetArray.push('UNORDERED LIST');
            break;
          case 'li':
            targetArray.push('LIST ITEM');
            break;
          default:
            targetArray.push('')
            break;
        }
      }
    }
    return targetArray;
  }

  getStylesFromHtmlString(htmlString) {
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlString, "text/html");
    let stylesArray = [];
    let allElements = Array.from(parsedHtml.getElementsByTagName("*"));
    for (let element of allElements) {
      let styles = this.getStyles(element);
      if (Object.keys(styles).length !== 0) {
        stylesArray.push(styles);
      }
    }
    return stylesArray;
  }

  getStyles(element) {
    let styleObject = {};
    if (element.style) {
      for (let style of element.style) {
        styleObject[style] = element.style[style];
      }
    }
    return styleObject;
  }

  dateDifference(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const startYear = start.year();
    const endYear = end.year();
    function isLeapYear(year) {
      return moment([year]).isLeapYear();
    }
    const leapYear = isLeapYear(endYear);
    const differenceDays = end.diff(start, 'days');
    if (leapYear) {
      if (differenceDays > 366 || differenceDays < 365) {
        return 'period ended';
      } else {
        return 'year ended';
      }
    } else {
      if (differenceDays === 364) {
        return 'year ended';
      } else {
        return 'period ended';
      }
    }
  }
  formatNumber(number) {
    if (typeof number === 'number' && Number.isFinite(number)) {
      let [integerPart, decimalPart] = number.toString().split('.');
      if (decimalPart === undefined || decimalPart.length === 1) {
        return number.toFixed(2);
      } else {
        return number;
      }
    } else {
      return number;
    }
  }
  calculateNearestBusinessPlanInterval(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    const remainder = diffMonths % 3;
    let nearestInterval;
    if (remainder === 0) {
      nearestInterval = diffMonths;
    } else if (remainder <= 1) {
      nearestInterval = diffMonths - remainder;
    } else {
      nearestInterval = diffMonths + (3 - remainder);
    }
    return nearestInterval + " month";
  }
  convertClassToInlineStyle(htmlString: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const elements = tempDiv.querySelectorAll('.fr-text-capitalize, .fr-text-lowercase, .fr-text-uppercase, .fr-text-none');
    elements.forEach((element: HTMLElement) => {
      let style = '';
      if (element.classList.contains('fr-text-capitalize')) {
        style += 'text-transform: capitalize;';
      }
      if (element.classList.contains('fr-text-lowercase')) {
        style += 'text-transform: lowercase;';
      }
      if (element.classList.contains('fr-text-uppercase')) {
        style += 'text-transform: uppercase;';
      }
      if (element.classList.contains('fr-text-none')) {
        style += 'text-transform: none;';
      }
      element.removeAttribute('class');
      element.setAttribute('style', style);
    });
    const modifiedHtml = tempDiv.innerHTML;
    tempDiv.remove();
    return modifiedHtml;
  }
  removeStyleAttributeFromHtml(htmlString): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    var elements = tempDiv.querySelectorAll('[id="isPasted"]');
    var tagNames = [];
    elements.forEach(function (element) {
      var tagName = element.tagName.toLowerCase();
      tagNames.push(tagName);
    });
    var regexPattern = '<(' + tagNames.join('|') + ')[^>]*\\s*style=(?:"[^"]*"|\'[^\']*\')\\s*id="isPasted"[^>]*>';
    var regex = new RegExp(regexPattern, 'g');
    tempDiv.remove();
    var modifiedHTML = htmlString.replace(regex, match => {
      return match.replace(/\s*style=(?:"[^"]*"|'[^']*')/, '');
    });
    return modifiedHTML;
  }

  toCamelCase(str) {
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
  }
  capitalizeEachWord(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }
  cleanHTML(html: string): string {
    const keysToRemove = ['color', 'background-color', 'text-decoration-color', 'background'];
    const divStyleRegex = /<div[^>]*style\s*=\s*(['"])\s*text-align\s*:\s*justify\s*;\s*['"][^>]*>(.*?)<\/div>/gi;
    return html
      .replace(/<br[^>]*>/g, '<br>')
      .replace(/&nbsp;/g, ' ')
      .replace(/\\\"/g, '"')
      .replace(/&quot;/g, '')
      .replace(new RegExp(`(?:${keysToRemove.join('|')})\\s*:\\s*[^;]+;`, 'gi'), '')
      .replace(divStyleRegex, '$2');
  }
}

