/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpBackend, HttpClient, HttpHandler } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { Spinkit } from 'ng-http-loader';
import { TreeNode } from 'primeng/api';
import { fadeInRightAnimation } from '../../../../../../src/@fury/animations/fade-in-right.animation';
import { fadeInUpAnimation } from '../../../../../../src/@fury/animations/fade-in-up.animation';
import { SvgSpinnerComponent } from '../../../../../../src/app/shared/custom-components/svg-spinner/svg-spinner.component';
import { NodeService } from './nodeService';
import { Router } from '@angular/router';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
@Component({
  selector: 'fury-workflow-tree',
  templateUrl: './workflow-tree.component.html',
  styleUrls: ['./workflow-tree.component.scss'],
  animations: [fadeInUpAnimation, fadeInRightAnimation]
})
export class WorkflowTreeComponent implements OnInit {
  public componentName: any = 'alteryx-tree'
  files1: TreeNode[];
  files: TreeNode[];
  public selectedNodeCount = 0;
  accountIds = [];
  startDate: any = new Date();
  endDate: any = new Date();
  minDate: any = new Date();
  maxDate: any = new Date();
  workflowList: any = [];
  isDataDumpInTemp: boolean = false;
  getSelectedNodes: any;
  selctedWorkflow: any;
  cols: any[];
  public formValid: boolean = true;
  rowdata: any[];
  subscription1: any;
  selectedNodes: TreeNode[];
  displayBasic: boolean = false;
  previewData: boolean = false;
  numSelected: any;
  public tabData: any = [];
  display: boolean;
  public cols2: { field: string; header: string; }[];
  public dataSource2 = [];
  public selectedFrequency: any = 'DAILY';
  public isLoading: boolean = false;
  spinnerStyle = Spinkit;
  public awesomeComponent = SvgSpinnerComponent;
  public nodeData: any = [];
  public dailogFlag: boolean = false;
  public rowSpinner: boolean = false;
  public sortLevel: any = 'source';
  public sourceToggle: string = 'false';
  public isNodeExpend: boolean;
  public divShow: boolean = true;
  public sortLevelList: any = ['source', 'fund']
  @Output() workStatus: EventEmitter<any> = new EventEmitter();
  public isNodeExpendtog: boolean;
  public excBtnFlg = false;
  exportFlg: boolean = true;
  expFull: boolean = false;
  public executionLocked: boolean = true;
  constructor(private messageService: GlobalMessageService, private transalteService: TranslateService, private nodeService: NodeService, private httpClient: HttpClient, private handler: HttpBackend, private route: Router, public translate: TranslateService, private localService: LocalStoreService) {
    this.httpClient = new HttpClient(handler)
  }

  ngOnInit(): void {
    let daySubMon = 3;
    let daySubSun = 2;
    let daySubOth = 1;
    let maxNfDay = 31;
    let addDays = 30;
    let minNfDay = 1;
    let dayNum = moment().day();

    if (dayNum == 1) {
      this.startDate = new Date(moment(this.startDate).subtract(daySubMon, "days").toDate());
      this.endDate = new Date(this.startDate);

    } else if (dayNum == 0) {
      this.startDate = new Date(moment(this.startDate).subtract(daySubSun, "days").toDate());
      this.endDate = new Date(this.startDate);
    } else {
      this.startDate = new Date(moment(this.startDate).subtract(daySubOth, "days").toDate());
      this.endDate = new Date(this.startDate);
    }
    this.minDate = this.startDate;
    this.maxDate = new Date(moment(this.startDate).add(addDays, "days").toDate());
    this.getAllRecords();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('wtree_body');
    this.sortLevel = 'source';
  }

  sequenceExecutionVisible() {
    this.isNodeExpend = false;
    this.executionLocked = this.executionLocked ? true : false;
    this.refreshTree();
    this.getAllRecords();
  }

  getAllRecords() {
    return new Promise((resolve) => {
      let strDate = moment(this.startDate).format('YYYY-MM-DD');
      let endDate = moment(this.endDate).format('YYYY-MM-DD');
      this.nodeService.getFilesystem(strDate, endDate, this.sortLevel, this.executionLocked).then((files) => {
        if (files && files.length) {
          //enterprises
          files.forEach((enterprises) => {
            if (enterprises.data) {
              enterprises.data.isPreviewData = enterprises.data.isPreviewData ? true : false;
              enterprises.data.disposition = enterprises.data.disposition ? enterprises.data.disposition.toLowerCase() : '';
              enterprises.data['isSelected'] = false;
              // enterprises.data['selectable'] = true
            }
            if (enterprises.children && enterprises.children.length) {
              //fund
              enterprises.children.forEach((funds) => {
                if (funds.data) {
                  funds.data.isPreviewData = funds.data.isPreviewData ? true : false;
                  funds.data.disposition = funds.data.disposition ? funds.data.disposition.toLowerCase() : '';
                  funds.data['isSelected'] = false;
                  // funds.data['selectable'] = true
                }
                if (funds.children && funds.children.length) {
                  //sourcelegal
                  funds.children.forEach((sourcelegal) => {
                    if (sourcelegal.data) {
                      sourcelegal.data.isPreviewData = sourcelegal.data.isPreviewData ? true : false;
                      sourcelegal.data.disposition = sourcelegal.data.disposition ? sourcelegal.data.disposition.toLowerCase() : '';
                      // funds.data['isSelected'] = false;
                      sourcelegal.data['isSelected'] = false;
                      // sourcelegal.data['selectable'] = sourcelegal.data.workflowMapping ? true : false;
                    }
                    if (sourcelegal.children && sourcelegal.children.length) {
                      //source
                      sourcelegal.children.forEach((sources) => {
                        if (sources.data) {
                          sources.data.isPreviewData = sources.data.isPreviewData ? true : false;
                          sources.data.disposition = sources.data.disposition ? sources.data.disposition.toLowerCase() : '';
                          sources.data['isSelected'] = false;
                          // sources.data['selectable'] = true
                        }
                        if (sources.children && sources.children.length) {
                          //workflows
                          sources.data.isPreviewData = sources.data.isPreviewData ? true : false;
                          sources.data.disposition = sources.data.disposition ? sources.data.disposition.toLowerCase() : '';
                          sources.children.forEach((workflows) => {
                            if (workflows.data) {
                              workflows.data.isPreviewData = workflows.data.isPreviewData ? true : false;
                              workflows.data.disposition = workflows.data.disposition ? workflows.data.disposition.toLowerCase() : '';
                              workflows.data['isSelected'] = false;
                              // workflows.data['selectable'] = workflows.data.workflowMapping;
                            }
                            if (workflows.children && workflows.children.length) {
                              //accounts
                              workflows.data.isPreviewData = workflows.data.isPreviewData ? true : false;
                              workflows.data.disposition = workflows.data.disposition ? workflows.data.disposition.toLowerCase() : '';
                              workflows.children.forEach((accounts) => {
                                if (accounts.data) {
                                  workflows.data.isPreviewData = workflows.data.isPreviewData ? true : false;
                                  accounts.data.disposition = accounts.data.disposition ? accounts.data.disposition.toLowerCase() : '';
                                  accounts.data['isSelected'] = false;
                                  accounts.data['uniqueId'] = accounts.data?.id + workflows.data?.id;
                                  // accounts.data['selectable'] = true
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
        this.files = files;
        resolve(true);
      });

      this.cols = [
        { field: 'title', header: '', id: 100 },
        { field: 'lastRunDate', header: 'last_run_date' },
        { field: 'processDate', header: 'process_date' },
        { field: 'workflowSuccessCount', header: 'success' },
        { field: 'workflowErrorCount', header: 'Error' },
        { field: 'workflowReferDownloadFile', header: 'down_file' },
        { field: 'workflowUploadManually', header: 'upld_man' },

      ];
      this.cols2 = [
        { field: 'Workflow', header: 'workflow' },
        { field: 'Error Logs', header: 'error_log' },
        { field: 'Status', header: 'status' },
      ];
    });
  }

  nodeSelect(event) {
    //Auth VG, SDI-34 Exec Button Disable
    //Lines : 169,190-206
    let excBtnFlgSt = localStorage.getItem('excBtnFlg');
    if (excBtnFlgSt && excBtnFlgSt == 'STOP') {
      this.excBtnFlg = true;
    } else {
      this.excBtnFlg = false;
    }
    this.setData(event, true);
    this.selectedNodeCount = 0;
    if (this.selectedNodes && this.selectedNodes.length) {
      let previewData = true;
      this.selectedNodes.forEach((el) => {
        if (!el.children) {
          this.selectedNodeCount = this.selectedNodeCount + 1;
        }
        if (previewData) {
          if (el.data.type == "accountNumber") {
            if (el.parent && el.parent.data && !el.parent.data.isPreviewData) {
              previewData = false;
            }
          } else if (el.data.type == "workflow") {
            if (!el.data.isPreviewData) {
              previewData = false;
            }
          }
        }
        //Auth VG, SDI-34 Exec Button Disable
        //Lines : 169,190-206
        if (el.parent && el.parent.data && el.data.type && el.data.type != "accountNumber") {
          let disp = el?.data?.status;
          disp = disp?.toLowerCase();
          if (disp) {
            if (disp?.includes("run")) {
              this.excBtnFlg = true;
            }
          }
        } else if (el.parent && el.parent.data && el.data.type && el.data.type == "accountNumber") {
          let disp = el?.parent?.data?.status;
          disp = disp?.toLowerCase();
          if (disp) {
            if (disp?.includes("run")) {
              this.excBtnFlg = true;
            }
          }
        }
        // if (el.parent && el.parent.data && el.data.executionLocked != ( undefined || null) && !el.data.executionLocked && el.data.type == "workflow") {
        //   if (el.data.type == "workflow") {
        //     this.showMsg(el.parent.data.title + " : " + "workflow not configured for sequence execution.You can configure this from Enterprise login.", 'low');
        //   } else {
        //     this.showMsg("Some workflow are not configured for sequence execution.You can configure this from Enterprise login.", "low");
        //   }
        //   // if(this.selectedNodes && this.selectedNodes.length){
        //     this.refreshTree();
        //   // }
        // }
        // else{
        //   if(!el.data.executionLocked && el.data.type == "ent"){
        //     this.showMsg("Some workflow are not configured for sequence execution.You can configure this from Enterprise login.", "low");
        //     this.refreshTree();
        //   }
        // }
        if (el.data && el.data.workflowMapping === false) {
          //  if (el.parent && el.parent.data && el.data.workflowMapping != (undefined || null)  && !el.data.workflowMapping && el.data.type == "workflow") {
          if (this.isNodeExpendtog === true && el.data.type == "workflow") {
            this.showMsg(el.data.title + " : " + "workflow is not mapped, please contact with system manager.", "high")
          } else {
            this.showMsg(el.data.title + " : " + "workflow is not mapped, please contact with system manager.", "high")

            this.refreshTree();
          }
          // if(this.selectedNodes && this.selectedNodes.length){
          // this.refreshTree();
          // }
        }
        else {
          if (el.data.workflowMapping != (undefined || null) && !el.data.workflowMapping && el.data.type == "ent") {
            this.showMsg("Some workflow are not mapped, please contact with system manager.", "high");
            //  this.refreshTree();
          }
        }
      });
      this.previewData = previewData;
    } else {
      this.previewData = false;
    }
  }

  nodeUnselect(event) {
    // this.selectedNodes = [];
    this.selectedNodeCount = 0;
    this.setData(event, false);
    if (!this.selectedNodes.length) {
      this.previewData = false;
      this.selectedNodes.forEach((el) => {
        if (!el.children) {
          this.selectedNodeCount = this.selectedNodeCount + 1;
        }
      });
    } else {
      let previewData = true;
      this.selectedNodes.forEach((el) => {
        if (!el.children) {
          this.selectedNodeCount = this.selectedNodeCount + 1;
        }
        if (previewData) {
          if (el.data.type == "accountNumber") {
            if (el.parent && el.parent.data && !el.parent.data.isPreviewData) {
              previewData = false;
            }
          } else if (el.data.type == "workflow") {
            if (!el.data.isPreviewData) {
              previewData = false;
            }
          }
        }
        //Auth VG, SDI-34 Exec Button Disable
        //Lines : 258-276
        if (el.parent && el.parent.data && el.data.type && el.data.type != "accountNumber") {
          let disp = el?.data?.status;
          disp = disp?.toLowerCase();
          if (disp) {
            if (!disp?.includes("run")) {
              this.excBtnFlg = false;
            }
          }
        } else if (el.parent && el.parent.data && el.data.type && el.data.type == "accountNumber") {
          let disp = el?.parent?.data?.status;
          disp = disp?.toLowerCase();
          if (disp) {
            if (!disp?.includes("run")) {
              this.excBtnFlg = false;
            }
          }
        }
      });
      this.previewData = previewData;
    }
  }

  setData(event, selectionType) {
    let originalEvent = event.originalEvent;
    let rowNode = originalEvent.rowNode;
    let level = rowNode.level;
    let data = event.node.data;
    let parent = event.node.parent;
    if (level == 5) {
      var parentnode = parent.data.id;
    }
    //enterprises
    this.files.forEach((enterprises) => {
      if (enterprises.children && enterprises.children.length) {
        //fund
        enterprises.data['isSelected'] = true;
        enterprises.children.forEach((funds) => {
          if (funds.children && funds.children.length) {
            //sourcelegal
            funds.data['isSelected'] = true;
            funds.children.forEach((sourcelegal) => {
              if (sourcelegal.children && sourcelegal.children.length) {
                //source
                sourcelegal.data['isSelected'] = true;
                sourcelegal.children.forEach((sources) => {
                  if (sources.children && sources.children.length) {
                    //workflows
                    sources.data['isSelected'] = true;
                    sources.children.forEach((workflows) => {
                      if (workflows.children && workflows.children.length) {
                        //accounts
                        workflows.data['isSelected'] = true;
                        workflows.children.forEach((accounts) => {
                          if (accounts.data && (level == 0
                            || (level == 1 && funds.data.id == data.id)
                            || (level == 2 && sourcelegal.data.id == data.id)
                            || (level == 3 && sources.data.id == data.id)
                            || (level == 4 && workflows.data.id == data.id)
                            || (level == 5 && accounts.data.id == data.id && parentnode == workflows.data.id))
                          ) {
                            accounts.data['isSelected'] = selectionType;
                          }
                          if (!accounts.data['isSelected']) {
                            workflows.data['isSelected'] = false;
                            sources.data['isSelected'] = false;
                            sourcelegal.data['isSelected'] = false;
                            funds.data['isSelected'] = false;
                            enterprises.data['isSelected'] = false;
                          }
                        });
                      }
                      //workflow selection
                      if (workflows.data && (level == 0
                        || (level == 1 && funds.data.id == data.id)
                        || (level == 2 && sourcelegal.data.id == data.id)
                        || (level == 3 && sources.data.id == data.id)
                        || (level == 4 && workflows.data.id == data.id))
                      ) {
                        workflows.data['isSelected'] = selectionType;
                      }
                      if (!workflows.data['isSelected']) {
                        sources.data['isSelected'] = false;
                        sourcelegal.data['isSelected'] = false;
                        funds.data['isSelected'] = false;
                        enterprises.data['isSelected'] = false;
                      }
                    });
                  }
                  //source selection
                  if (sources.data && (level == 0
                    || (level == 1 && funds.data.id == data.id)
                    || (level == 2 && sourcelegal.data.id == data.id)
                    || (level == 3 && sources.data.id == data.id))
                  ) {
                    sources.data['isSelected'] = selectionType;
                  }
                  if (!sources.data['isSelected']) {
                    sourcelegal.data['isSelected'] = false;
                    funds.data['isSelected'] = false;
                    enterprises.data['isSelected'] = false;
                  }
                });
              }
              //sourcelegal selection
              if (sourcelegal.data && (level == 0 || (level == 1 && funds.data.id == data.id) || (level == 2 && sourcelegal.data.id == data.id))) {
                sourcelegal.data['isSelected'] = selectionType;
              }
              if (!sourcelegal.data['isSelected']) {
                funds.data['isSelected'] = false;
                enterprises.data['isSelected'] = false;
              }
            });
          }
          //fund selection
          if (funds.data && (level == 0 || (level == 1 && funds.data.id == data.id))) {
            funds.data['isSelected'] = selectionType;
          }
          if (!funds.data['isSelected']) {
            enterprises.data['isSelected'] = false;
          }
        });
      }
      //set ent selection
      if (enterprises.data && level == 0) {
        enterprises.data['isSelected'] = selectionType;
      }
    });
  };

  setWorkflowData() {
    this.workflowList = [];
    if (this.files && this.files.length) {
      this.files.forEach((enterprises) => {
        //enterprise
        enterprises.children.forEach((fundItem) => {
          //fund
          if (fundItem.children && fundItem.children.length) {
            //sourcelegal
            fundItem.children.forEach((sourceLegalItem) => {
              if (sourceLegalItem && sourceLegalItem.data) {
                if (this.sortLevel == 'source') {
                  var selctedWorkflow: any = {};
                  selctedWorkflow = {
                    workflowId: sourceLegalItem.data.id,
                    previewData: this.previewData,
                    inputMode: sourceLegalItem.data.inputMode,
                    executionPeriod: sourceLegalItem.data.executionPeriod,
                  };
                  var accountIds: any = [];
                  //workflow selection
                  if (sourceLegalItem.data.isSelected) {
                    selctedWorkflow.accountId = "";
                    this.workflowList.push(selctedWorkflow);
                  } else if (sourceLegalItem.children && sourceLegalItem.children.length) {
                    //account
                    sourceLegalItem.children.forEach((account) => {
                      if (account.data.isSelected) {
                        accountIds.push(account.data.id);
                      }
                    });
                    if (accountIds.length) {
                      accountIds = accountIds.toString();
                      // selctedWorkflow.accountId = accountIds;
                      this.workflowList.push(selctedWorkflow);
                    }
                  }
                }
              }
              if (sourceLegalItem.children && sourceLegalItem.children.length) {
                //source               
                sourceLegalItem.children.forEach((source) => {
                  if (source.children && source.children.length) {
                    //workflow
                    source.children.forEach((workflow) => {
                      if (this.sortLevel == 'fund') {
                        var selctedWorkflow: any = {};
                        selctedWorkflow = {
                          workflowId: workflow.data.id,
                          previewData: this.previewData,
                          inputMode: workflow.data.inputMode,
                          executionPeriod: workflow.data.executionPeriod,
                        };
                        var accountIds: any = [];
                        //workflow selection
                        if (workflow.data.isSelected) {
                          selctedWorkflow.accountId = "";
                          this.workflowList.push(selctedWorkflow);
                        } else if (workflow.children && workflow.children.length) {
                          //account
                          workflow.children.forEach((account) => {
                            if (account.data.isSelected) {
                              accountIds.push(account.data.id);
                            }
                          });
                          if (accountIds.length) {
                            accountIds = accountIds.toString();
                            // selctedWorkflow.accountId = accountIds;
                            this.workflowList.push(selctedWorkflow);
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
          }
        });
      });
    }
    // if (previewTemp) { this.runCompilance(); }
    this.runCompilance();
  };

  redirectToSatus(e, workflow, data, key) {
    let selKey = "Success";

    switch (key) {
      case 'workflowSuccessCount':
        selKey = "Success";
        break;
      case 'workflowErrorCount':
        selKey = "Error";
        break;
      case 'workflowReferDownloadFile':
        selKey = "Refer Downloaded file";
        break;
      case 'workflowUploadManually':
        selKey = "Uploaded Manually";
        break;
    }


    var ids = [];
    var mngIds = [];
    if (data) {
      let chlidx = data?.children;
      chlidx?.forEach(el => {
        let x = el?.data
        ids.push(x.id);
        mngIds.push(x.workflowMngtId)
      });
    }

    let obj = {
      componentName: "workflowStatus",
      data: workflow,
      key: selKey,
      method: 'get',
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      wkIds: ids,
      wkNgtIds: mngIds
    }

    if (workflow.type == "accountNumber") {
      e.stopPropagation();
    } else {
      localStorage.setItem('workflowTreeValue', JSON.stringify(obj));
      this.workStatus.emit(2);
    }

  }

  redirectToRun(e, workflowType) {
    let obj = {
      componentName: "executeWorkflow",
      data: workflowType,
      key: null,
      method: 'post',
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD')
    }
    if (workflowType.type == "accountNumber") {
      e.stopPropagation();
    } else {
      localStorage.setItem('workflowTreeValue', JSON.stringify(obj));
      this.workStatus.emit(1);
    }

  }

  redirectTo(evt, data, nodes, key) {
    let data2 = nodes?.node;
    // if (!data?.type.includes("ent")) {
    if (key == "title") {
      this.redirectToRun(evt, data)
    } else {
      this.redirectToSatus(evt, data, data2, key)
      // }
    }
  }

  runCompilance() {
    this.dataSource2 = [];
    if (this.accountIds.length) {
      this.selctedWorkflow['previewData'] = this.previewData;
    }
    var formData = {
      ids: [],
      isDataDumpInTemp: this.previewData,
      // runDate: moment(this.startDate).format('YYYY-MM-DD'),
      startDate: moment(this.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.endDate).format('YYYY-MM-DD'),
      workflow: this.workflowList,
      "isDateRange": true,
    };

    this.nodeData = formData
    this.nodeData['frequency'] = this.selectedFrequency;
    if (this.nodeData) {
      this.display = false;
      this.dailogFlag = true
    }
    // this.excBtnFlg = true;
    this.isLoading = true;
    delete (formData['frequency']);

  };

  hideDialogBox() {
    this.displayBasic = false;
  }
  showModal() {
    this.setWorkflowData();
    this.displayBasic = true;
  }

  isShowOptions(event) {
    // this.onChangeCheck.emit(this.dataItem); 
    event.checked = this.previewData == true ? true : false;
  }

  resetTree() {
    this.selectedNodes = [];
    this.numSelected = [];
    this.getAllRecords().then((btnTgl: any) => {
      if (btnTgl) {
        this.divShow = true;
        this.isNodeExpend = false;
        this.isNodeExpendtog = !this.isNodeExpendtog
      }
    });
    this.isNodeExpendtog = false;
    this.isNodeExpend = false;
    this.expFull = false;
  }

  refreshTree() {
    this.selectedNodes = [];
    this.numSelected = [];
    this.isNodeExpendtog = false;
    this.isNodeExpend = false;
    this.expFull = false;
  }

  treeExpandAll(treeData) {
    this.expFull = true;
    if (!this.isNodeExpendtog) {
      this.divShow = true
    }
    this.isNodeExpend = true;
    treeData.forEach((node) => {
      this.treeExpandCollapse(node, true);
    });
    this.files = [...treeData];
  }

  treeCollapseAll(treeData) {
    this.expFull = false;
    this.divShow = true;
    this.isNodeExpend = false;
    treeData.forEach((node) => {
      this.treeExpandCollapse(node, false);
    });
    this.files = [...treeData];
  }

  treeExpandCollapse(node, isExpand) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        if (this.sortLevel == 'fund') {
          if (childNode.data.type == 'fund' || childNode.data.type == 'legalName' || childNode.data.type == 'Others') {
            this.treeExpandCollapse(childNode, isExpand);
          }
        }
        else {
          if (childNode.data.type == 'source' || childNode.data.type == 'workflow' || childNode.data.type == 'Others') {
            this.treeExpandCollapse(childNode, isExpand);
          }
        }
      });
    }
  }

  checkDoneStatus() {
    this.display = false;
    localStorage.removeItem('excBtnFlg');
    this.workStatus.emit(2);
    if (this.dataSource2 != null || this.dataSource2 != undefined) {
      this.fetchDataToExport(this.dataSource2);
    }
    this.resetTree();
    this.ngOnInit();
  }
  changeFrequency(evt) {
    this.selectedNodes = [];
    this.numSelected = [];
    this.getAllRecords();
  }
  runWorkflow() {
    this.display = true;
  }
  treeFeedback(eve) {
    if (true) {
      this.resetTree();
      this.workStatus.emit(2);
    }
  }
  showMsg(msg, prty) {
    let dMsg = msg;
    if (prty == "high") {
      this.messageService.add({
        closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
        detail: dMsg
      });
    }
    if (prty != "high") {
      if (prty == "low") {
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
          detail: dMsg
        });
      }
    }
  }

  onTreeRowClick(evt) {
    // console.log(evt);    
  }

  onRadioChange(evt) {
    if (evt && evt.value) {
      this.sortLevel = evt.value;
    }
    this.resetTree();
  }

  dateChangeEvt(evt?, flg?) {
    //Auth:Vg, Tree Satrt And End Date Max Diff 30 Days
    let MaxDiff = 31;
    let addDay = 30;
    let minNfDay = 1
    if (flg) {
      if (flg == 'str') {
        if (evt) {
          if (evt.value) {
            var newDate = moment(evt.value).toDate();
            this.endDate = new Date(this.startDate);
            this.minDate = new Date(newDate);
            this.maxDate = new Date(moment(this.minDate).add(addDay, "days").toDate());
            if (newDate) {
              var checkExecuteEndDate = moment(this.endDate).format('YYYY-MM-DD');
              var checkExecuteStartDate = moment(newDate).format('YYYY-MM-DD');
            }

            if (checkExecuteStartDate && checkExecuteEndDate) {
              var daysDiff = moment(checkExecuteEndDate).diff(moment(checkExecuteStartDate), 'days') + 1;
            }

            if (checkExecuteStartDate > checkExecuteEndDate) {
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                detail: this.translate.instant('end_date_should_be_greater_than_start_date')
              });
              this.endDate = moment(evt.value).add(addDay, "days").toDate();
            }

            if (daysDiff > MaxDiff) {
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                detail: this.translate.instant('end_start_date_maxdiff_31_err_msg')
              });
              this.endDate = moment(evt.value).add(addDay, "days").toDate();
            }

          }
        }

      }

      if (flg == 'end') {
        if (evt) {
          if (evt.value) {
            var newDate = moment(evt.value).toDate();

            if (newDate) {
              var checkExecuteStartDate = moment(this.startDate).format('YYYY-MM-DD');
              var checkExecuteEndDate = moment(newDate).format('YYYY-MM-DD');
            }

            if (checkExecuteStartDate && checkExecuteEndDate) {
              var daysDiff = moment(checkExecuteEndDate).diff(moment(checkExecuteStartDate), 'days') + 1;
            }

            if (checkExecuteStartDate > checkExecuteEndDate) {
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                detail: this.translate.instant('end_date_should_be_greater_than_start_date')
              });
              this.endDate = moment(this.startDate).add(addDay, "days").toDate();
            }

            if (daysDiff > MaxDiff) {
              this.messageService.add({
                closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
                detail: this.translate.instant('end_start_date_maxdiff_31_err_msg')
              });
              this.endDate = moment(this.startDate).add(addDay, "days").toDate();
            }

          }
        }
      }
    }
    this.resetTree();
  }



  nodeExp(evt) {
    if (evt) {
      if (evt.node) {
        //SDI-173 Auth:VG
        this.expFull = evt?.node?.expanded;
        if (evt.node.parent == null) {
          this.divShow = false;
          this.isNodeExpendtog = true;
        }
      }
    }
  }

  nodeColp(evt) {
    if (evt) {
      if (evt.node) {
        //SDI-173 Auth:VG
        this.expFull = evt?.node?.expanded;
        if (evt.node.parent == null) {
          this.divShow = false;
          this.isNodeExpendtog = false;
        }
      }
    }
  }

  downloadExportFile(res) {
    let usr = JSON.parse(this.localService.getItem('fss'))['enterpriseName'];
    let startDate = moment(this.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.endDate).format('YYYY-MM-DD');
    var flNm = usr + "_compliance_status_" + startDate + "_" + endDate + ".csv";
    var a = document.createElement("a");
    var blob = new Blob([res], { type: res.ContentType });
    a.href = URL.createObjectURL(blob);
    a.download = flNm;
    a.click();

    this.messageService.add({
      sticky: true,
      severity: "success",
      summary: this.transalteService.instant("success"),
      detail: this.transalteService.instant("status_file_success"),
    });

    this.workStatus.emit(2);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += this.transalteService.instant(headerList[index]) + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    this.downloadExportFile(str);
  }

  fetchDataToExport(data?: any) {
    var csvData = [];
    let workflow;
    let log;
    let sts;
    let isDownloadable;
    data.forEach(itm => {
      if (itm) {
        if (itm.alteryxWorkflowMngt && itm.alteryxWorkflowMngt.sourceName) {
          workflow = itm.alteryxWorkflowMngt.sourceName;
        }
        log = itm.failedReason != null ? itm.failedReason : itm.connectionStatus;
        sts = itm.failedReason != null || itm.connectionStatus != null ? "Failed" : "Connected";

        log = log == null ? " " : log;
        if (itm.failedReason != null || itm.connectionStatus != null) {
          isDownloadable = true;
        }
        if (workflow) {
          csvData.push({
            "workflow": workflow,
            "error_log": log,
            "status": sts
          })
        }
      }
    });
    if (isDownloadable) {
      this.ConvertToCSV(csvData, ['workflow', 'error_log', 'status'])
    }
  }

  recordFound(evt) {
    this.selectedNodes = [];
    if (evt && evt.filters && evt.filters.global) {
      this.exportFlg = false;
    } else {
      this.exportFlg = true;
    }
    if (evt.filteredValue.length == 0) {
      this.messageService.add({
        closable: true, sticky: true, severity: 'error', summary: this.translate.instant('Error'),
        detail: this.translate.instant('No Record Found')
      });
    }
  }
  exportFile(evt, node) {
    let reqObj;
    if (this.expFull) {
      reqObj = {
        treeExpand: true,
        startDate: moment(this.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.endDate).format('YYYY-MM-DD'),
        Ent: this.files[0]?.data?.title,
        lastRunDate: this.files[0]?.data?.lastRunDate,
        processDate: this.files[0]?.data?.processDate,
        workflowSuccessCount: this.files[0]?.data?.workflowSuccessCount,
        workflowErrorCount: this.files[0]?.data?.workflowErrorCount,
        workflowReferDownloadFile: this.files[0]?.data?.workflowReferDownloadFile,
        workflowUploadManually: this.files[0]?.data?.workflowUploadManually,
        isExecutionLocked: this.executionLocked
      }
    } else {
      reqObj = {
        treeExpand: false,
        Ent: this.files[0]?.data?.title,
        lastRunDate: this.files[0]?.data?.lastRunDate,
        processDate: this.files[0]?.data?.processDate,
        workflowSuccessCount: this.files[0]?.data?.workflowSuccessCount,
        workflowErrorCount: this.files[0]?.data?.workflowErrorCount,
        workflowReferDownloadFile: this.files[0]?.data?.workflowReferDownloadFile,
        workflowUploadManually: this.files[0]?.data?.workflowUploadManually,
        isExecutionLocked: this.executionLocked

      }
    }
    if (reqObj) {
      this.nodeService.treeDataExport(reqObj).subscribe((res) => {
        if (res) {
          let usr = JSON.parse(this.localService.getItem('fss'))['enterpriseName'];
          let startDate = moment(this.startDate).format('YYYY-MM-DD');
          let endDate = moment(this.endDate).format('YYYY-MM-DD');
          var flNm = usr + "_Workflow_" + startDate + "_" + endDate + ".csv";
          const a = document.createElement('a');
          const blob = new Blob([res], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = flNm;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
      })
    }
  }

}
