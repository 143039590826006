
<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->

    <div id="divshow" *ngIf="moduleName == 'reportDeliveryWorkflowV2'" >
      <p-button icon="pi pi-upload" label="{{'fund_specific_attachment' | translate}}"
       (click)="fileuploadConfirm($event);"
        class="p-button-text execute"></p-button>
    </div>

    <div id="divshow" *ngIf="moduleName == 'isdClientApprovalGroupFund'" >
      <p-button icon="pi pi-upload" label="{{'group_specific_attachment' | translate}}"
       (click)="fileuploadConfirm($event);"
        class="p-button-text execute"></p-button>
    </div>

    <div id="divshow" *ngIf="moduleName == 'isdAdhocConfiguration' && isdAdhocFundAttachment" >
      <p-button icon="pi pi-upload" label="{{'fund_specific_attachment' | translate}}" matTooltip="{{toolTipDescriptions}}"  [disabled]="documentLevel" 
       (click)="fileuploadConfirm($event);"
        class="p-button-text execute"></p-button>
    </div>