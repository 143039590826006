/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { NgModule } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import {  MessageService } from 'primeng/api';
import {SkeletonModule} from 'primeng/skeleton';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {CalendarModule} from 'primeng/calendar'; 

@NgModule({
    imports: [],
    providers: [
        {
            provide: DynamicDialogRef,
            useValue: {}
        },
        MessageService,
        DialogService],

    declarations: [],
    exports: [
        CalendarModule,
        DropdownModule,
        ButtonModule,
        MultiSelectModule,
        DialogModule,
        FileUploadModule,
        InputNumberModule,
        ConfirmDialogModule,
        ToastModule,
        SplitButtonModule,
        TabMenuModule,
        TableModule,
        SkeletonModule,
        DynamicDialogModule,
        ToggleButtonModule]


})
export class PrimeNgModule { }

