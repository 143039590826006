/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { ThemeService } from '../../../@fury/services/theme.service';
import { AuthService } from '../../../../src/app/shared/services/commonServices/auth.service';
import { LayoutService } from '../layout.service';
import { Subscription } from 'rxjs';
import { MessageService, PrimeNGConfig } from 'primeng/api';

import { DataService } from 'src/app/shared/services/commonServices/data.service';
import { SetupButtonService } from 'src/app/views/rapid/setup/setup-button.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
import { CommonService } from 'src/app/shared/services/commonServices/common.service';
@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;
  domainName = 'localhost';
  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;
  fss: any;
  userName: String;
  userEmail: String;
  imgUrl: String;
  settingButton: boolean = false;
  roleAs: String;
  public currentUser: any;
  isSettingPanelOpen: boolean = false;
  items$: Observable<SidenavItem[]>;
  itemsRemaining$: Observable<SidenavItem[]>;
  showSvg = true;
  subscription: Subscription;
  showlight: boolean = false;
  isprogrees: boolean;
  isSetup: boolean = false;
  isComplete: any;
  val: any;
  hubspot: boolean = false;
  public cryptobackoffice: any;
  public troniqledger :any
  isLogin: any;
  isUserLoggedIn: boolean;
  constructor(private setUPManagement: SetupButtonService, private commonService: CommonService, private setuppercentage: SetupPercentageService, private router: Router, private dataService: DataService, private layoutService: LayoutService,
    private sidenavService: SidenavService,
    private themeService: ThemeService,
    private messageService: MessageService,
    private auth: AuthService, private localService: LocalStoreService) {
    this.isUserLoggedIn = this.auth.isLoggedIn1();
    this.commonService.getIsLogin().subscribe((res) => {
      console.log(res, 'res');
      this.isLogin = res;
      if (res && this.isUserLoggedIn) {
        this.userEmail = JSON.parse(this.localService.getItem('fss'))['userName'];
        if (this.userEmail != null) {
          this.userName = this.userEmail.split('@')[0];
          // let imgurl = localStorage.getItem('photoUrl')
          let imgurl = JSON.parse(this.localService.getItem('fss'))['photoUrl'];
          if (imgurl != "null") {
            this.imgUrl = imgurl;
          }
        }
        else {
          this.signout();
        }
        var hostname = window.location.hostname;
        var subdomain = hostname.split('.')[0];
        this.cryptobackoffice = hostname.split('.')[1]
        this.troniqledger = hostname.split('.')[1]
        if (subdomain == 'localhost' || subdomain == 'betaseamless' || subdomain == 'devmig' || subdomain == 'uatmig') {
          this.showSvg = true;
        } else {
          this.domainName = subdomain;
          this.showSvg = false;

        }
        this.subscription = this.layoutService.getMessage().subscribe(message => { this.imgUrl = message; });
        let themeValue: any = JSON.parse(this.localService.getItem('fss'));
        themeValue.defaultTheme == 'fury-flat' || themeValue.defaultTheme == 'fury-light' ? this.showlight = true : this.showlight = false;

        this.dataService.getThemeData().subscribe(res => {
          res == 'fury-light' || res == 'fury-flat' ? this.showlight = true : this.showlight = false;
        })
      }
    })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // If you want to remove the class at the start of navigation
        this.removeBodyClass('financial-bg');
      } else if (event instanceof NavigationEnd) {
        // If you want to remove the class at the end of navigation
        this.removeBodyClass('financial-bg');
      }
    });

  }

  removeBodyClass(className) {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove(className);
  }
  ngOnInit() {
    if (JSON.parse(this.localService.getItem('fss'))) {
      this.roleAs = JSON.parse(this.localService.getItem('fss'))['ROLE']
      this.currentUser = JSON.parse(this.localService.getItem('fss'));
      this.hubspot = this.currentUser?.authList ? (this.currentUser.authList.indexOf('SEAMLESS_CONTACT_SUPPORT') > -1 ? true : false) : false;
      if (this.roleAs == 'SystemManager') {
        this.settingButton = true;
      }
      this.currentUser = JSON.parse(this.localService.getItem('fss'));
      if (this.currentUser.authList.indexOf('SEAMLESS_SIDEMENU_SETUP') > -1) {
        this.isSetup = true;
      }
      this.items$ = this.sidenavService.items$.pipe(
        map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
      );

      this.setuppercentage.getMessage().subscribe(message => {
        this.isComplete = message;
        if (this.isComplete) {
          this.isprogrees = false;
        } else {
          this.isprogrees = true;
        }

      });
      this.getFormData()
    }
  }
  getSettingPanleValue(data) {
    this.isSettingPanelOpen = data;
  }
  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }


  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    if (!this.isSettingPanelOpen) {
      this.sidenavService.setExpanded(true);
    }

  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // unsubscribe to ensure no memory leaks
  }

  signout() {
    this.auth.logout().subscribe(data => {
      this.auth.signout();
      this.messageService.clear();
    }, (error) => {
      console.error(error);
      this.auth.signout();
      this.messageService.clear();
    });
  }

  getFormData() {
    if (this.hubspot) {
      document.getElementsByClassName('hotspottalk')[0]['style'].display = 'block'
    } else { document.getElementsByClassName('hotspottalk')[0]['style'].display = 'none' }

  }
}
