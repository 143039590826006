<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div fxLayout="row" fxLayoutAlign="center stretch" fxFlex="grow">
  <button class="search-button" mat-button (click)="open()">
    <mat-icon>search</mat-icon>
  </button>
  <mat-form-field class="search" [class.search-open]="isOpen" fxFlex="auto">
    <mat-label>{{"Search" | translate}}&hellip;</mat-label>
    <input #input (blur)="close()" matInput>
  </mat-form-field>
</div>
