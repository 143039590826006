<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<div>
	<button (click)="gridSchRowEventCompliance($event)" style="background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: blue !important; border-style: none; width: 100%; padding-top: 14px;">
		<span style="background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
        align-content: center; color: blue !important; border-style: none; width: 100%; padding-top: 14px;"> <mat-icon>schedule</mat-icon> </span>
	</button>
</div>
