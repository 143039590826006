<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div *ngIf="moduleName=='executeWorkflow'" style=" color: #1976d2; border-style: none;">
	<button (click)="ProcessDetailsWorkflow($event)"
		[disabled]="!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done'|| alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp || alteryxWorkflowStatus.dataCountTemp === 0 || !alteryxWorkflowStatus.uploadData"
		[ngStyle]="(!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp || alteryxWorkflowStatus.dataCountTemp === 0 || !alteryxWorkflowStatus.uploadData) && {'color':'gray', cursor: 'not-allowed'} || {'color': '#1976d2'}"
		style="cursor: pointer; background-color: transparent !important; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;">
		<u style="text-decoration: none;"
			*ngIf="alteryxWorkflowStatus && !alteryxWorkflowStatus.uploadData && alteryxWorkflowStatus.isDataDumpInTemp"
			matTooltip="{{'Please setup upload from enterprise login and rerun the workflow.'|translate}}">Proceed</u>
		<u style="text-decoration: none;"
			*ngIf="!(alteryxWorkflowStatus && !alteryxWorkflowStatus.uploadData && alteryxWorkflowStatus.isDataDumpInTemp)">Proceed</u>
	</button>
	<button (click)="ProcessCancelDetailsWorkflow($event)"
		[disabled]="!alteryxWorkflowStatus ||alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp || alteryxWorkflowStatus.dataCountTemp === 0"
		[ngStyle]="(!alteryxWorkflowStatus || alteryxWorkflowStatus.status != 'Completed' || alteryxWorkflowStatus.processStatus == 'Done' || alteryxWorkflowStatus.processStatus == 'Cancel' || !alteryxWorkflowStatus.isDataDumpInTemp || alteryxWorkflowStatus.dataCountTemp === 0) && {'color':'gray', cursor: 'not-allowed'} || {'color': '#1976d2'}"
		style="cursor: pointer; background-color: transparent !important; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;">
		<u style="text-decoration: none;">Cancel</u>
	</button>
</div>


<!-- <p-dialog class="delModal Dialog_width_Small confirm_undo" header="Confirm Upload" [(visible)]="displayBasic"
    [baseZIndex]="10000">
    <div class="row delCriteria">
      
        <span>Are you sure you want to proceed ?</span>
    </div>

    <p-footer>
        <button mat-mini-fab color="primary" type="button" style="margin: 0px 2px 0px 0px;" (click)="getApproveWorkflow(alteryxWorkflowStatus)">
            <mat-icon data-md-tooltip="Yes">done</mat-icon>
        </button>
		<button mat-mini-fab color="primary" type="button" style="margin: 0px 2px 0px 0px;" (click)="cancel()">
            <mat-icon data-md-tooltip="No">done</mat-icon>
        </button>
    </p-footer>

</p-dialog> -->