<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<!-- <button *ngIf="!isCheckBox" [disabled]="displayBasic" mat-icon-button (click)="uploadErrorStatus($event,'view')" [matTooltip]="tooltipTxt"
style=" background-color: transparent !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
align-content: center; border-style: none;"> <mat-icon>cloud_download</mat-icon> </button> -->

<!-- <mat-checkbox *ngIf="isCheckBox" [checked]="!displayBasic1" (change)="setExecute($event)" [ngClass]="{'disable': displayBasic1,'disable1': displayBasic2 }"  style="align-items: center; text-align: center;
align-content: center; border-style: none;"></mat-checkbox> -->

<!-- <div *ngIf="attachment1">
	<button (click)="getPreviewData()"		
		style="cursor: pointer; background-color: transparent !important; text-align:center; position: relative; transform: translateY(15%); align-items: center; text-align: center; align-content: center;  border-style: none;">
		<span><img src="assets/img/download-csv.svg" width="25px" height="auto" alt=""></span>
	</button>
</div> -->

 <span *ngIf="attachment" class="material-icons-outlined agvisibility" 
    >cloud_download</span>

