/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'fury-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {

  todayDay: string;
  todayDate: string;
  todayDateSuffix: string;
  todayMonth: string;

  constructor() { }

  ngOnInit() {
    this.todayDay = moment().format('dddd');
    this.todayDate = moment().format('Do');
    this.todayDate = this.todayDate.replace(/\D/g, '');
    this.todayDateSuffix = moment().format('Do');
    this.todayDateSuffix = this.todayDateSuffix.replace(/[0-9]/g, '');
    this.todayMonth = moment().format('MMMM');
  }

}
