/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { Theme, ThemeService } from '../../../@fury/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { GridService } from '../../../../src/app/shared/services/gridServices/grid.service';
import moment from 'moment';
import { CommonService } from '../../../../src/app/shared/services/commonServices/common.service';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from '../../../../src/app/shared/services/commonServices/data.service';
import { SidenavService } from '../sidenav/sidenav.service';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
import { GoogleAuthenticatorService } from 'src/app/pages/authentication/MFA/google-authenticator/google-authenticator.service';
import { OptPasswordService } from 'src/app/pages/authentication/otp-password/otp-password.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input()
  @HostBinding('class.no-box-shadow')
  hasNavigation: boolean;
  lang: any;
  themeToggle: boolean = false;
  changeLanguage: any;
  @Output() openSidenav = new EventEmitter();
  @Output() openQuickPanel = new EventEmitter();
  displayBasic: boolean = false;
  displayPdfMerge: boolean = false;
  startDate: any;
  public roleAs: any = JSON.parse(this.localService.getItem('fss'))['ROLE']
  public currentUser;
  fundPeriodLockDate: any;
  result: any;
  startDate1 = new FormControl((new Date()).toISOString());
  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));
  pinedLang: any;
  pinedLang1: any;
  username: string;
  hostName: string;
  pinedMenu: any;
  lanDrop: string;
  userPerferenceList: any;
  activeTheme$ = this.themeService.activeTheme$;
  navigation$ = this.themeService.config$.pipe(map(config => config.navigation));
  sidenavOpen$ = this.sidenavService.open$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavUserVisible$ = this.themeService.config$.pipe(map(config => config.sidenavUserVisible));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));

  public theme_style: any;
  public tollbar_position: any;
  public navigation_bar: any = {};
  public ext_options: any = {};
  public gridViewData = {};
  isprogrees: boolean;
  isSetup: boolean = false;
  isComplete: any;
  defaultVideoData: any;
  showMfaEnable: boolean = true;

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  isMFASetup: boolean = false;

  constructor(
    private themeService: ThemeService, private messageService: GlobalMessageService,
    private sidenavService: SidenavService, public router: Router,
    public gridService: GridService, private commonService: CommonService,
    public translate: TranslateService, private http: HttpClient,
    private dataService: DataService, private setuppercentage: SetupPercentageService,
    private localService: LocalStoreService,
    private googleAuthenticatorService: GoogleAuthenticatorService,
    private optPasswordService: OptPasswordService
  ) { }


  setActiveTheme(theme: Theme) {
    this.themeService.setTheme(theme);
    this.commonService.userPerferenceData(this.username).subscribe((res1: any) => {
      if (res1) {
        this.pinedMenu = res1.ThemeSettings;
        if (this.pinedMenu && this.pinedMenu.length) {
          var reqBody = {
            "id": this.pinedMenu[0].id,
            "userName": this.username,
            "view": 'ThemeSettings',
            "settingName": theme,
            "setting": {
              "pivotMode": 'ThemeSettings',
              "theme_style": this.theme_style,
              "tollbar_position": this.tollbar_position,
              "navigation_bar": this.navigation_bar,
              "ext_options": this.ext_options
            },
            status: true,
          }
          this.http.put('userPerferenceV2/update', reqBody).subscribe(res2 => { });
        } else {
          let reqBody = {
            "userName": this.username,
            "view": 'ThemeSettings',
            "settingName": theme,
            "setting": {
              "pivotMode": 'ThemeSettings',
            },
            status: true,
          }
          this.http.post('userPerferenceV2/create', reqBody).subscribe(items => { });
        }
      }
    })
  }

  ngOnInit() {
    let themeValue: any = JSON.parse(this.localService.getItem('fss'));
    this.pinedLang1 = themeValue.defaultTheme;
    this.pinedLang1 == 'fury-dark' ? this.themeToggle = true : this.themeToggle = false;
    this.theme_style = this.activeTheme$['source']['source']['_value'][0];
    this.navigation_bar['positon'] = this.navigation$['source']['source']['_value']['navigation'];
    this.navigation_bar['show'] = this.sidenavOpen$['source']['_value'];
    this.tollbar_position = this.toolbarPosition$['source']['source']['_value']['toolbarPosition'];
    this.ext_options['collapsed'] = this.sidenavCollapsed$['source']['_value'];
    this.ext_options['show_usr_pan'] = this.sidenavUserVisible$['source']['source']['_value']['sidenavUserVisible'];

    this.hostName = window.location.hostname;
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.username = JSON.parse(this.localService.getItem('fss'))['userName'];
    this.startDate = this.currentUser.periodLockDate != null ? new Date(this.currentUser.periodLockDate) : null;
    this.dataService.data.subscribe(data => {
      if (data) {
        this.userPerferenceList = data;
        this.translate.addLangs(['English', 'French']);
        let res = this.userPerferenceList
        if (res && res?.languageSettings?.length && res?.languageSettings[0]?.settingName != null) {
          this.pinedLang = res.languageSettings[0].settingName;
          this.changeLanguage = res.languageSettings[0].settingName;
          this.translate.use(this.changeLanguage);
          this.translate.setDefaultLang(this.changeLanguage);
          let val = JSON.parse(this.localService.getItem('fss'));
          if (val) {
            val['switchLanguage'] = JSON.stringify(this.changeLanguage)
            this.localService.setItem('fss', JSON.stringify(val));
          }
        } else {
          let val = JSON.parse(this.localService.getItem('fss'));
          if (val) {
            val['switchLanguageEng'] = JSON.stringify('English')
            val['switchLanguage'] = JSON.stringify('English')
            this.localService.setItem('fss', JSON.stringify(val));
          }

          this.translate.use('English');
          this.translate.setDefaultLang('English');
          let reqBody = {
            "userName": this.username,
            "view": 'languageSettings',
            "settingName": JSON.parse(JSON.parse(this.localService.getItem('fss'))['switchLanguageEng']),
            "setting": {
              "pivotMode": 'languageSettings',
            },
            status: true,
          }
          this.http.post('userPerferenceV2/create', reqBody).subscribe(items => {
            this.getUserAllPrefernece();
          })
        }
        if (this.userPerferenceList?.ThemeSettings?.length > 0) {
          this.pinedLang1 = this.userPerferenceList.ThemeSettings[0].settingName;
          this.themeService.setTheme(this.pinedLang1);
          if (this.pinedLang1 == 'fury-dark') {
            this.themeToggle = true;
          } else {
            this.themeToggle = false;
          }
        } else {
          this.themeToggle = false;
        }
      }
    });

    this.setuppercentage.getMessage().subscribe(message => {
      this.isComplete = message;
      if (this.isComplete) {
        this.isprogrees = false;
      } else {
        this.isprogrees = true;
      }
    });

    this.googleAuthenticatorService.mfaValue().subscribe(res => {
      this.showMfaEnable = res;
    });

    this.http.post("userV2/getMfaStatus", this.httpOptions).subscribe((resulList: any) => {
      this.showMfaEnable = resulList.data.mfaEnable;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        var matches = url.match(/enableMfaSetup|chooseSetupMfaType|googleAuthenticatorSetup|smsVerificationSetup|twilioAuthySetup|emailSetup/g);
        if ((matches && matches.length)) {
          this.isMFASetup = true;
        } else {
          this.isMFASetup = false;
        }
      }
    });
  }

  getUserAllPrefernece() {
    this.dataService.getUserPerferenceData().subscribe(info => {
      if (info) {
        this.userPerferenceList = info;
      }
    });
  }

  switchLang(lang: string) {
    this.lang = lang;
    let val = JSON.parse(this.localService.getItem('fss'));
    if (val) {
      val['switchLanguage'] = JSON.stringify(this.lang)
      this.localService.setItem('fss', JSON.stringify(val));
    }
    this.ListData();
  }

  ListData() {
    if (this.userPerferenceList?.languageSettings?.length && this.userPerferenceList.languageSettings[0]?.settingName != null) {
      this.changeLanguage = this.userPerferenceList.languageSettings[0].settingName;
      let val = JSON.parse(this.localService.getItem('fss'));
      if (val) {
        val['switchLanguageEng'] = JSON.stringify(this.lang)
        this.localService.setItem('fss', JSON.stringify(val));
      }

      if (this.changeLanguage && this.changeLanguage) {
        var reqBody = {
          "id": this.userPerferenceList.languageSettings[0].id,
          "userName": this.username,
          "view": 'languageSettings',
          "settingName": this.lang,
          "setting": {
            "pivotMode": 'languageSettings',
          },
          status: true,
        }
        this.http.put('userPerferenceV2/update', reqBody).subscribe(res2 => {
          if (res2) {
            this.userPerferenceList.languageSettings[0] = res2
            this.dataService.setUserPerferenceData(this.userPerferenceList);
          }
        }, (error) => {
          this.translate.setDefaultLang(this.lang);
          this.translate.use(this.lang);
        })
      } else {
        this.translate.setDefaultLang(this.lang);
        this.translate.use(this.lang);
        let reqBody = {
          "userName": this.username,
          "view": 'languageSettings',
          "settingName": this.lang,
          "setting": {
            "pivotMode": 'languageSettings',
          },
          status: true,
        }
        this.http.post('userPerferenceV2/create', reqBody).subscribe(items => { });
      }
    }
  }

  renderDuplicateTab() {
    window.open(window.location.href, '_blank');
  }

  openNoteFund() {
    this.router.navigateByUrl('/notes-and-Comments-entry')
  }

  OpenMergePdf() {
    this.displayPdfMerge = true
  }

  toggleDropdown1() {
    this.displayBasic = true;
  }

  hideDialogBox() {
    this.displayBasic = false;
    this.startDate = this.currentUser.periodLockDate != null ? new Date(this.currentUser.periodLockDate) : null;
  }

  submitPeriodLock() {
    if (this.currentUser.periodLockDate == null && this.startDate == null) {
      this.messageService.add({ closable: true, sticky: true, severity: 'warn', summary: this.translate.instant('warning'), detail: this.translate.instant('Please_enter_period_lock_date') });
    } else {
      var effectiveDate = new Date(this.startDate);
      this.result = this.getPeriodLockmessage(effectiveDate, this.fundPeriodLockDate, this.currentUser.periodLockDate)
      if (this.result != false) {
        this.startDate = moment(this.startDate).format('YYYY-MM-DD');
        let url = "enterpriseV2/savePeriodLockDate/" + this.startDate + "/" + this.currentUser['enterpriseId'];
        this.gridService.submitPeriodicLockDate(url, (cbs) => {
          if (cbs.message == 'Done') {
            this.messageService.add({ closable: true, sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('Period_lock_updated_successfully') });
            this.displayBasic = false;
            this.currentUser.periodLockDate = this.startDate;
          } else {
            this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('Period_lock_updated_failed') });
          }
        }, (cbe) => {
        })
      }
    }
  }

  getPeriodLockmessage(date: any, FundLockDate: any, UserLockDate: any) {
    if ((UserLockDate != null)) {
      if (moment(date).format('YYYY-MM-DD') <= moment(UserLockDate).format('YYYY-MM-DD')) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('Error'), detail: this.translate.instant('date_must_greater_current_period_lock') + `(` + moment(UserLockDate).format('MM-DD-YYYY') + ` )`, });
        return false;
      }
    }
  }

  onClick(){
    this.router.navigate(["user-management/user-entry"])
  }
  getVideoData(event,val){
    this.setuppercentage.setVideoStatus(event);
    if (val) {
      this.setuppercentage.setIsPlay(true);
    }
  }

  autoplay(event) {
    if (event) {
      this.getVideoData(false, true);
    } else {
      this.getVideoData(false, false);
    }
  }

  gotToHomePage() {
    this.optPasswordService.setTabsFunction(this.currentUser)
  }
}