<fury-page-layout mode="card">
    <fury-page-layout-header>
        <fury-breadcrumbs [crumbs]="[moduleNameGrid]" [cardName]="[card]" parentHeading="{{parentHeading|translate}}"
            current="{{heading|translate}}" [isShow]="isShow">
        </fury-breadcrumbs>
    </fury-page-layout-header>
    <fury-page-layout-content class="grid-spcui all-grid-expand-collapseicon" [@fadeInUp]>
        <fury-list name="{{heading|translate}}" [componentName]="componentName"
            [isSmartTable]="isSmartTable">

            <div *ngIf="componentName!='enterprises'"class="actions oms-list-btns uploadbtn_alignment gridbtn-ui">
                <button class="export" type="button"
                mat-mini-fab color="primary" matTooltip="{{isFiltered==false?('export'|translate):('export_filtered'|translate)}}" matTooltipPosition="above"
                style="margin-right: 6px;" (click)="exportExcel()">
                <mat-icon>file_download</mat-icon>
            </button>
            </div>

            <div class="actions oms-list-btns uploadbtn_alignment gridbtn-ui">
                <button
                *ngIf="componentName=='enterprises'"
                class="create" matTooltip="{{'create'|translate}}" matTooltipPosition="above" type="button"
                mat-mini-fab color="primary" [routerLink]="['/systemmanager/enterprises-list/create']">
                <mat-icon>add</mat-icon>
            </button>
            </div>
           
            <ag-grid-angular  style="width: 100%; height: 100%;" class="ag-theme-alpine setGridHeight textAligncenter"
                [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
                [sideBar]="sideBar" [suppressRowClickSelection]="true" [groupSelectsChildren]="true" [rowSelection]="rowSelection"
                [gridOptions]="gridOptions" (filterModified)="getFilterData($event)"
                [pivotPanelShow]="pivotPanelShow" [pagination]="true" [rowData]="rowData"(selectionChanged)='onSelectionChanged($event)'
                (gridReady)="onGridReady($event)"></ag-grid-angular>
        </fury-list>

    </fury-page-layout-content>
</fury-page-layout>