/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlTypeBase } from './control-type-base';
import { ControlTypeService } from './control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() componentName: any;
  @Input() data: ControlTypeBase<string>[] = [];
  form: FormGroup;
  @Output() status: EventEmitter<boolean> = new EventEmitter();
  @Output() initFun: EventEmitter<any> = new EventEmitter();
  @Output() dataItemDetails: EventEmitter<any> = new EventEmitter();
  constructor(private qcs: ControlTypeService,
    private cd: ChangeDetectorRef) { }



  ngOnDestroy(): void {
    this.form.reset();
  }

  ngOnInit() {
    this.initFun.emit(this.form);
  }

  ngOnChanges(changes) {
    this.form = this.qcs.toFormGroup(changes.data.currentValue);
    this.form.statusChanges.subscribe((status) => {
      if (status === "VALID") {
        this.status.emit(true);
      } else {
        this.status.emit(false);
      }
    })

  }

  // sendAsset(assetId){
  // this.assetId.emit(assetId);
  // }

  getEvent(evt) {
    this.dataItemDetails.emit(evt);
  }


}