/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessButtonService {
  public fundsArr = [];
  constructor(private http: HttpClient,) { }

  //Processing Menue
  //Auth : vg

  admProcessTabs = [
    { id: 4, name: 'daily_processing_status', auth: 'SEAMLESS_INPUT_DIV_IMPORT', link: "/processingsystemmanager/dailyProcessingStatus", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 2, name: 'input_data_integrity_check', auth: 'USER_Input_Data_Integrity_Check', link: "/processingsystemmanager/inputDataIntegrityCheck", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORGROUP_VIEW' },
  ]

  admsProcessTabs = [
    { id: 2, name: 'input_data_integrity_check', auth: 'USER_Input_Data_Integrity_Check', link: "/managerprocessing/inputDataIntegrityCheck", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORGROUP_VIEW' },
  ]
  usrProcessTabs = [
    { id: 5, name: 'data_import', auth: 'SEAMLESS_INPUT_DIV_IMPORT', link: "/processing/executeWorkflow", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 6, name: 'configure_and_execute', auth: 'DAILYPROCESSING_VIEW', link: "/processing/dailyProcessingView", createRouterlink: 'configure-execute-entry', add: false, upload: false, authUpload: '', authCreate: 'DAILYPROCESSING_CREATE' },
    { id: 1, name: 'one_button_process_log', auth: 'DAILYPROCESSING_VIEW', link: "/processing/dailyProcessingStatus", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    // { id: 2, name: 'input_data_integrity_check', auth: 'USER_Input_Data_Integrity_Check', link: "/processing/inputDataIntegrityCheck", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: 'INVESTORGROUP_VIEW' },
    // { id: 3, name: 'Step By Step Workflow', auth: 'SIDEMENU_PROCESSING_WORKFLOW', link: "/processing/systemmanager/workflow", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    { id: 4, name: 'status', auth: 'SEAMLESS_INPUT_DIV_IMPORT', link: "/processing/workflowStatus", createRouterlink: '', uploadRouterlink: '', authUpload: '', authCreate: '' },
    // { id: 6, name: 'workflow', auth: 'SEAMLESS_INPUT_DIV_IMPORT', link: "/processing/workflow-tree", createRouterlink: '', add: false, upload: false },
  ]

  admProcessbuttonTabs = [
    { id: 1, name: 'process', auth: '', Children: this.admProcessTabs },
  ]
  admsProcessbuttonTabs = [
    { id: 1, name: 'process', auth: '', Children: this.admsProcessTabs },
  ]
  usrProcessbuttonTabs = [
    { id: 1, name: 'process', auth: '', Children: this.usrProcessTabs },
  ]

  initiate(data: any): Observable<any> {
    return this.http.post('dataProcessingV2/initiate', data);
  }

  getFunds() {
    this.http.get<any>('fund/fetchAllByEnterprise').subscribe(response => {
      this.fundsArr = response.resultList;

    })
  }

}
