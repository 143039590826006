/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2023 - 2024 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core'; import { HttpClient, HttpHeaders } from "@angular/common/http";
import { forkJoin, Observable } from 'rxjs';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class FetchCSDBankTransactionEntryService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  public fundList = [];
  public investorList = [];
  public vendorList = [];
  public date: Date;
  public custodianNumberList = [];
  public currencyList = [];
  public transactionTypeList = [];
  fundListAll: any;
  fetchCsd: any;

  constructor(private http: HttpClient) { }

  basicDetails() {
    return [
      { id: 'date', controlType: 3, label: 'date', name: 'date', viewName: 'date', order: 1, value: this.date, required: true, readonly: false, disabled: false, class: "col-lg-12" },
      { id: 'fund', controlType: 2, label: 'fund', name: 'fund', viewName: 'fundName', order: 2, value: null, required: true, fill: this.fundList, disabled: false, class: "col-lg-6 align-custodian" },
      { id: 'custodianNumber', controlType: 2, label: 'custodian_account', name: 'custodianNumber', viewName: 'custodianAccNo', order: 3, value: null, required: true, fill: this.custodianNumberList, disabled: false, class: "col-lg-6 align-custodian" },

    ];
  }

  investorDetails() {
    return [
      { id: 'amount', controlType: 5, label: 'amount', name: 'amount', viewName: 'amount', order: 2, required: true, style: "display:block;", disabled: false, class: "col-lg-6 pt-10 dropdown-bgcolors" },
      { id: 'transactionType', controlType: 2, label: 'transaction_type', name: 'transactionType', viewName: 'transactionTypeName', order: 7, value: null, required: true, fill: this.transactionTypeList, disabled: false, class: "col-lg-6 pt-10" },
      { id: 'accountDr1', controlType: 1, label: 'dr', name: 'accountDr1', viewName: 'prevAccountDr1', order: 9, value: '', required: false, disabled: true, style: "display:none;", class: "col-lg-6" },
      { id: 'accountCr1', controlType: 1, label: 'cr', name: 'accountCr1', viewName: 'prevAccountCr1', order: 10, value: '', required: false, disabled: true, style: "display:none;", class: "col-lg-6" },

      { id: 'investorNumber', controlType: 2, label: 'investor_number', name: 'investorNumber', viewName: 'investorNumber', order: 1, required: false, fill: this.investorList, disabled: false, class: "col-lg-6" },
      { id: 'currency', controlType: 2, label: 'currency', name: 'currency', viewName: 'currencyName', order: 3, value: { id: "USD" }, required: false, fill: this.currencyList, disabled: false, class: "col-lg-6" },
      { id: 'exchangeRate', controlType: 5, label: 'exchange_rate', name: 'exchangeRate', viewName: 'exchangeRate', order: 4, value: '1', readonly: false, disabled: false, class: "col-lg-6" },
      { id: 'vendor', controlType: 2, label: 'vendor', name: 'vendor', viewName: 'vendorName', order: 5, required: false, fill: this.vendorList, disabled: false, class: "col-lg-6" },
      { id: 'tickerSymbol', controlType: 1, label: 'symbol', name: 'tickerSymbol', viewName: 'tickerSymbol', order: 6, required: false, disabled: false, class: "col-lg-6" },
      { id: 'description', controlType: 15, label: 'description_notes', name: 'description', viewName: 'description', order: 11, value: '', required: false, disabled: false, class: "col-lg-6" },
      { id: 'attachment', controlType: 4, label: 'attachment', name: 'attachment', viewName: 'attachment', order: 8, value: '', required: false, disabled: false, class: "col-lg-6 left_attachment" },

    ];
  }


  getBasicDetails(flag, gridData) {
    return new Promise(resolve => {
      if (flag === "create" || flag === "edit") {
        this.fetchFundList().subscribe(res => {
          this.fundList = res.resultList.map(element => ({ label: element.fundName, value: { "id": element.id }, alias: element.fundAlias }));
          this.fundListAll = res.resultList;
          let basicTemp = this.basicDetails();
          if (flag == 'edit') {
            basicTemp.forEach(el => {
              el.disabled = true;
              if (el.id == 'custodianNumber') {
                el.value = { id: gridData['custodianId'] }
              } else if (el.id == 'date') {
                el.value = new Date(gridData['date']);
              } else
                el.value = gridData[el.id]

            })
            this.fetchCustodianList({ id: gridData['fund'].id }).subscribe(res => {
              this.custodianNumberList = res.map(element => ({ label: element.accountNumber, value: { "id": element.id } }));
              basicTemp[2].fill = this.custodianNumberList;
              resolve(basicTemp)
            })
          }
          else resolve(basicTemp)
        })

      } else {
        let basicTemp = this.basicDetails();
        basicTemp.forEach(el => {
          if (el.id == 'date') {
            el.value = new Date(gridData[el.id])
            el.value = moment(el.value).format('YYYY-MM-DD');
          } else el.value = gridData[el.viewName];
          el.controlType = 1;
          el.disabled = true;
        })
        resolve(basicTemp)
      }
    })
  }

  getTransactionDetails(flag, gridData) {

    return new Promise(resolve => {

      if (flag === "create" || flag === "edit") {
        this.fetchMultipleApi().subscribe(res => {
          this.currencyList = res[0].resultList.map(element => ({ label: element.value, value: { "id": element.id } }));
          this.vendorList = res[1].resultList.map(element => ({ label: element.vendor, value: { "id": element.id } }));
          this.transactionTypeList = res[2].resultList.map(element => ({ label: element.transactionType, value: { "id": element.id, "accountCr1": element.accountCr1, "accountDr1": element.accountDr1 } }));

          let transacTemp = this.investorDetails()
          if (flag == "edit") {

            transacTemp.forEach(el => {
              el.disabled = true;
              if (el.id == 'transactionType' && gridData['transactionId'] != null) {
                el.value = { id: gridData['transactionId'] }
              } else if (el.id == 'currency' && gridData['currencyId']) {
                el.value = { id: gridData['currencyId'] }
              } else if (el.id == 'vendor' && gridData['vendorId']) {
                el.value = { id: gridData['vendorId'] }
              } else if (el.id == 'investorNumber' && gridData['investorId']) {
                el.value = { id: gridData['investorId'] }
              } else if (el.id == 'accountDr1' || el.id == 'accountCr1') {
                el.style = 'display:block;'
              } else
                el.value = gridData[el.id]
              if (el.id == 'tickerSymbol' || el.id == 'exchangeRate' || el.id == 'transactionType' || el.id == 'vendor' || el.id == 'investorNumber') {
                el.disabled = false;
              }

            })
            this.transactionTypeList.forEach(el => {
              if (el.value.id == gridData['transactionId']) {
                transacTemp[2].value = el.value.accountDr1
                transacTemp[3].value = el.value.accountCr1
              }
            })
            this.fetchInvestorList({ id: gridData['fund'].id }).subscribe(res => {
              this.investorList = res.map(element => ({ label: element.investorNumber, value: { "id": element.id } }));
              transacTemp[4].fill = this.investorList
              resolve(transacTemp)
            })
          }
          else resolve(transacTemp)
        })

      } else {
        let transacTemp = this.investorDetails();

        let transactionId = gridData['transactionId'];
        let data = { transactionType: transactionId };
        this.fetchCrDr(data)?.subscribe(res => {
          let prevAccountCr1 = res?.accountCr1;
          let prevAccountDr1 = res?.accountDr1;
          transacTemp.forEach(el => {
            el.value = gridData[el.viewName];

            if (el.id == 'attachment' || el.id == "amount") {
              el.class = "col-lg-6"
            } else if (el.id == 'accountCr1') {
              el.style = 'display:block'
              el.value = prevAccountCr1;
            } else if (el.id == 'accountDr1') {
              el.style = 'display:block'
              el.value = prevAccountDr1;
            }

            else if (el.id == 'investorNumber' && gridData['investorId']) {
              el.value = gridData['investorNo'];
            }

            el.controlType = 1;
            el.disabled = true;
          })
          resolve(transacTemp)
        })



      }
    })
  }

  fetchMultipleApi(): Observable<any> {
    let response1 = this.http.get<any>('currencyV2/fetchAllRecords');
    let response2 = this.http.get<any>('vendorV2/fetchAllRecords');
    let response3 = this.http.get<any>('predefinedJournalEntryV2/fetchAllRecords');

    return forkJoin([response1, response2, response3]);
  }

  fetchFundList(): Observable<any> {
    return this.http.get<any>('fundV2/fetchAllByEnterprise');
  }

  saveData(data): Observable<any> {
    return this.http.post<any>('nonTradeTransactionsV2/create', data);

  }

  updateData(data): Observable<any> {
    return this.http.put<any>('nonTradeTransactionsV2/update', data);

  }

  deleteData(id): Observable<any> {
    return this.http.delete<any>("deleteController/delete?id=" + id + "&moduleName=nonTradeTransactions");

  }

  fetchInvestorList(id): Observable<any> {
    return this.http.get<any>('investorV2/filterByFundId', { params: id });

  }

  fetchCustodianList(id): Observable<any> {
    return this.http.get<any>('brokerAccountV2/filterByFundId', { params: id });

  }

  uploadFile(file): Observable<any> {
    return this.http.post<any>('documentManagementV2/uploadFile', file);
  }

  fetchCrDr(data): Observable<any> {
    return this.http.post<any>('predefinedJournalEntryV2/transactionTypeCrDr', data);
  }
  fetchMultipleApiBasic() {
    return this.http.get<any>('fundV2/fetchAllByEnterprise', this.httpOptions);
  }
  setFetchCsd(data: any) {
    this.fetchCsd = data;
  }
  getFetchCsd() {
    return this.fetchCsd;
  }
  download(data) {
    return this.http.post<any>('csdBankTransactionsController/downloadFailedFile/' + data.workFlowId + '/' + data.startDate + '/' + data.endDate, this.httpOptions);
  }
}

