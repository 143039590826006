/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { animate, sequence, style, transition, trigger } from '@angular/animations';


export const LIST_FADE_ANIMATION = [
  trigger('listFade', [
    transition(':leave', [
      style({ height: '*', opacity: '1', transform: 'translateX(0)' }),
      sequence([
        animate('250ms ease', style({ height: '*', opacity: 0, transform: 'translateX(50px)' })),
        animate('100ms ease', style({ height: '0', opacity: 0, transform: 'translateX(50px)' }))
      ])
    ]),
    transition(':enter', [
      style({ height: '0', opacity: '0', transform: 'translateX(50px)' }),
      animate('350ms 350ms ease', style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
    ])
  ])
];
