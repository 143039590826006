/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
 
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() {}
    
  private dataSource = new BehaviorSubject<any>(null);
  data = this.dataSource.asObservable();

  private accountingDashboardData = new BehaviorSubject<any>(null);
  acctingDashboardValue = this.accountingDashboardData.asObservable();
  
  private trainingVideoData = new BehaviorSubject<any>(null);
  trainingVideoValue = this.accountingDashboardData.asObservable();

  
  private trainingData = new BehaviorSubject<any>(null);
  activeTrainingValue = this.trainingData.asObservable();
  private themeData = new BehaviorSubject<any>(null);
  themeSetting = this.themeData.asObservable();
  private isdConsolidateReportData = new BehaviorSubject<any>(null);
  isdConsolidateReportValue = this.isdConsolidateReportData.asObservable();
  setUserPerferenceData(user: any) {
    this.dataSource.next(user);
}

setAccountingDashboardData(user: any) {
  this.accountingDashboardData.next(user);
}


setIsdConsolidateReportData(user: any) {
  this.isdConsolidateReportData.next(user);
}
getIsdConsolidateReportData() {
  return this.isdConsolidateReportData.asObservable();
}



getAccountingDashboardData() {
  return this.accountingDashboardData.asObservable();
}

  setTheme(theme: any) {
    this.themeData.next(theme);
  }

  getUserPerferenceData() {
    return this.dataSource.asObservable();
}

  getThemeData() {
    return this.themeData.asObservable();
  }

  setTrainingVideoData(user: any) {
    this.trainingVideoData.next(user);
  }
  
  getTrainingVideoData() {
    return this.trainingVideoData.asObservable();
  }
  

  setActiveTrainingData(user: any) {
    this.trainingData.next(user);
  }
  getActiveTrainingData() {
    return this.trainingData.asObservable();
  }
}
