import { Component } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";

@Component({
    selector: 'edit-cell-multi-select-dropdown',
    templateUrl: './edit-cell-multi-select-dropdown.component.html'
})
export class EditCellMultiSelectDropDownEditor implements ICellEditorAngularComp {
    public params: any;
    public value: any;
    public isCurrentRowEditing: boolean;
    public list: any = [];
    public data: any;
    public selectedValues: any[] = []; // Change to an array to hold multiple selected values
    oldSelectedValues: any[] = []; // Change to an array to hold multiple old selected values
    isLabelType: any;
    dropdownFieldValue: any;
    public formData: any;
    public columnDef: any;
    public isDisabled: boolean = false;
    public editableRowData: any;
    componentName: any;
    public searchText: any = '';
    filteredList: any[];
    public displayValue: string = ''; // Add displayValue property

    agInit(params: ICellEditorParams): void {
        this.params = params;
        //set columns dropdown values
        let colDef = this.params.colDef;
        let cellEditorParams = colDef.cellEditorParams;
        this.isLabelType = colDef.isLabelType;
        this.list = cellEditorParams.values || [];
        if (this.params.api.componentName == "fSPortfolio" || this.params.api.componentName == "fSInvestor" || this.params.api.componentName == "fschartOfAccountsRapid" || this.params.api.componentName == "taxAdjusted") {
            let firstOption = { 'label': 'Select ' + colDef.headerName, 'value': '' }
            this.list = [firstOption, ...cellEditorParams.values];
        }
        this.filteredList = this.list;

        if (this.params.api.componentName == "taxAdjusted" && colDef.field != "taxAdjustmentType") {
            this.filteredList = this.list;
        }
        this.componentName = this.params.api.componentName
        this.editableRowData = this.params.data;
        this.columnDef = this.params.colDef;
        this.formData = this.params.api['caFormData'];
        //set column values
        this.data = this.params.node.data;
        this.columnDef.isDisabled = false;
        this.value = this.getValueByPath(this.data, colDef.field);
        this.dropdownFieldValue = this.getValueByPath(this.data, colDef.dropdownField);
        if (this.isLabelType && this.value) {
            this.list.forEach(entry => {
                if (this.value.includes(entry.label)) {
                    if (!this.oldSelectedValues.includes(entry.value)) {
                        this.oldSelectedValues.push(entry.value);
                    }
                    if (entry.value && !this.selectedValues.includes(entry.value)) {
                        this.selectedValues.push(entry.value);
                    }
                }
            });
            this.setValueByPath(colDef.dropdownField, this.selectedValues);
        } else {
            if (this.dropdownFieldValue && Array.isArray(this.dropdownFieldValue)) {
                this.oldSelectedValues = this.dropdownFieldValue;

                this.selectedValues = this.dropdownFieldValue;
            } else {
                this.oldSelectedValues = [this.dropdownFieldValue];
                if (typeof this.dropdownFieldValue === 'boolean' && this.dropdownFieldValue.toString()) {
                    this.selectedValues = [this.dropdownFieldValue.toString()];
                } else if (this.dropdownFieldValue) {
                    this.selectedValues = [this.dropdownFieldValue];
                }
            }
        }
        this.updateDisplayValue(); // Update display value
    }

    getValueByPath(obj, path) {
        let current = obj;
        for (const key of path.split('.')) {
            if (current && current.hasOwnProperty(key)) {
                current = current[key];
            } else {
                return null;
            }
        }
        return current;
    }

    setValueByPath(path, value) {
        if (path.split('.').length > 1) {
            let keys = path.split('.');
            this.params.node.data[keys[0]] = this.params.node.data[keys[0]] ? this.params.node.data[keys[0]] : {};
            this.params.node.data[keys[0]][keys[1]] = value;
        } else {
            this.params.node.data[path] = value;
        }
    }

    afterGuiAttached = () => {
        if (!this.params.value) {
            return;
        } else {
            let colDef = this.params.colDef;
            this.value = this.getValueByPath(this.data, colDef.field);
        }
    };

    onSelect() {
        let colDef = this.params.colDef;
        // Filter out null values from selectedValues
        let validSelectedValues = this.selectedValues.filter(val => val !== null);
        let selectedOptions = this.list.filter(data => validSelectedValues.includes(data.value));
        if (selectedOptions.length) {
            let selectValues = validSelectedValues.map(val => val.value ? val.value : val);
            this.setValueByPath(colDef.dropdownField, selectValues);
            this.params.value = selectValues;
            this.setValue(selectValues);
        }
        this.updateDisplayValue(); // Update display value
    }

    getValue(): any {
        if (this.selectedValues.length === 0) {
            return [];
        }
        if (this.params.node.data.isEditingSave) {
            return this.params.value;
        } else {
            let colDef = this.params.colDef;
            this.setValueByPath(colDef.field, this.value);
            this.setValueByPath(colDef.dropdownField, this.oldSelectedValues);
            return this.value;
        }
    }

    setValue(labels): any {
        var colId = this.params.column.getId();
        this.params.node.setDataValue(colId, labels);
    }

    refresh(params?: any): boolean {
        return true;
    }

    ngOnChanges() {
        this.filterOptions();
    }

    filterOptions() {
        if (this.searchText) {
            this.filteredList = this.list.filter(element =>
                element.label.toLowerCase().includes(this.searchText.toLowerCase())
            );
        } else {
            this.filteredList = this.list;
        }
    }

    updateDisplayValue() {
        if (this.selectedValues.length > 0) {
            this.displayValue = this.selectedValues.join(', ');
        } else {
            this.displayValue = 'Select'; // Placeholder text when nothing is selected
        }
    }
}