/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from "@angular/router";
import { ControlTypeBase } from '../control-type-base';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import _moment from 'moment';
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service'

export const DateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD']
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MM-YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM-YYYY',
  },
};
import { default as _rollupMoment, Moment } from 'moment';
import { MultiSelect } from 'primeng/multiselect';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import _ from 'lodash';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';


const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-dynamic-template',
  templateUrl: './dynamic-form-template.component.html',
  styleUrls: ['./dynamic-form-template.component.scss'],
  providers: [{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: DateFormats }, MessageService
  ]

})

export class DynamicFormTemplateComponent {
  inputType = 'password';
  visible = false;
  maskVisible = true;
  @Input() dataItem: ControlTypeBase<string>;
  @Input() form: FormGroup;
  @Output() onChangeCheck: EventEmitter<any> = new EventEmitter();
  @ViewChild('multiselectforpopup') multiselectforpopup: MultiSelect;
  public filteredDropDownList = new ReplaySubject(1);
  protected _onDestroy = new Subject<void>();
  public DropDownFilterCtrl: FormControl = new FormControl();
  public inputVal: any;
  dropDownArray = [];
  public filteredDropDownitemList: any = []
  public selectedLegalValue;
  date = moment();
  @Input() componentName: any;
  actualValue: any;
  selectedDropDownToolTip: string;
  scrollHeight: string = '200px';
  compareCategoryObjects(object1: any, object2: any) {
    if (object1 != null && object2 != null) {
      if (object1.id && object2.id) {
        return object1 && object2 && object1.id == object2.id;
      } else {
        return object1 && object2 && object1 == object2
      }
    }
  }

  constructor(private router: Router, private scrollDispatcher: ScrollDispatcher,
    private el: ElementRef, private setuppercentage: SetupPercentageService,
    private primengConfig: PrimeNGConfig, private messageService: GlobalMessageService,
    public translate: TranslateService,) {

  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    if (this.dataItem.controlType == 2 || this.dataItem.controlType == 31 || this.dataItem.controlType == 45 || this.dataItem.controlType ==58 ) {
      let firstOption;
      if (this.dataItem.label.substr(0, 6) == 'Select' || this.dataItem.label.substr(0, 6) == 'Choose' || this.dataItem.label.substr(0, 6) == 'choose' || this.dataItem.label.substr(0, 6) == 'select') {
        firstOption = { 'label': this.dataItem.label, 'value': '' }
      } else firstOption = { 'label': 'select_' + this.dataItem.label, 'value': '' }

      if(this.componentName == 'RapidCustodiansEntryComponent' && this.form.controls[this.dataItem.id].value){
        if(this.dataItem.id == "legalNameListChecked" && this.dataItem.controlType == 2){
          this.selectedLegalValue = this.dataItem.fill.filter( el => el.value?.id == this.form.controls[this.dataItem.id].value.id)[0];
          
        }
      }

      if (this.dataItem.fill != null) {
        this.dataItem.fill = this.dataItem.fill;
      } else {
        this.dataItem.fill = [];
      }
      if (this.dataItem.label == "rapid_tax_lot_closing_ranking_method" || this.dataItem.id == "categoryRapid" || this.dataItem.id == "sub_categoryRapid" || this.dataItem.id == "rapidAccountType") {
        this.dropDownArray = this.dataItem.fill;
      }
      else {
        this.dropDownArray = [firstOption, ...this.dataItem.fill];
      }
      this.filteredDropDownList.next(this.dropDownArray.slice());
      if(this.componentName == 'rapidResetOpenTaxLots' && this.dataItem.id == 'tickersList'){
        this.scrollHeight = this.dropDownArray.slice().length <= 5 ? (this.dropDownArray.slice().length * 30 + 'px') : '200px';
      }
      else{
      this.scrollHeight = this.dropDownArray.slice().length <= 5 ? (this.dropDownArray.slice().length * 40 + 'px') : '200px';
      }
      this.DropDownFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterDropDown();
        });
        this.getNewBtn()
    }
    if (this.dataItem.controlType == 15 && this.dataItem['mention']) {
      this.dataItem['mentionConfig'] = { ...{ mentionSelect: this.onMentionSelect }, ...this.dataItem['mentionConfig'] }
    }

    if (this.dataItem.controlType == 7 && (this.componentName == 'RapidFundAccountsEntryComponent' || this.componentName == 'FundAccountsEntryComponent') && this.dataItem.id == 'excludeCOA') {
      this.selectedDropDownToolTip = 'Select COA’s to exclude from Rollup/Consolidation process.';
    }
    if (this.dataItem.id == "employerIdentificationNumber" || this.dataItem.id == "gpEINSSN" || this.dataItem.id == "taxId1" || this.dataItem.id == "taxId2" || this.dataItem.id == "taxId3" || this.dataItem.id == "disregardedTaxId" || this.dataItem.id == "partnershipsTaxId") {
      if (this.dataItem['isMasked']) {
        this.maskingPassword(this.dataItem.value);
      }
      this.form.get(this.dataItem.id).valueChanges.subscribe(val => {
        if (this.dataItem['subCount'] === 0 && this.form.controls[this.dataItem.id].value && this.form.controls[this.dataItem.id].value.indexOf('X') === -1) {
          this.dataItem['subCount']++;
          this.maskVisible = this.form.controls[this.dataItem.id].value && this.form.controls[this.dataItem.id].value.indexOf('X') > -1 ? false : true;
          this.maskingPassword(val);
        } else if (this.dataItem['resetMask'] && (this.dataItem.id == "employerIdentificationNumber" || this.dataItem.id == "gpEINSSN" || this.dataItem.id == "taxId1" || this.dataItem.id == "taxId2" || this.dataItem.id == "taxId3" || this.dataItem.id == "disregardedTaxId" || this.dataItem.id == "partnershipsTaxId")) {
          this.dataItem['resetMask'] = false;
          this.maskVisible = true;
          this.actualValue = '';
          this.form.controls[this.dataItem.id]['actualValue'] = '';
          this.dataItem.actualValue = '';
          if(this.dataItem.id != "taxId1"){
            this.form.controls[this.dataItem.id].enable()
          }
        }
      });
    }
  }

  /**
   * Function that autosuggests keywords we mayneed in FS-Notes config FSA-108
   * @author   Ashok Kumar
   * @param    {String} selection autocomplete selection
   * @return   {String} updated autocomplete selection
   */
  onMentionSelect(selection): string {
    if(selection.plainText) {
      return selection.name;
    } else {
      return '{' + selection.name + '}';
    }
  }

  /**
   * Function function to convert selection to input for new data entry FSA-119
   * @author   Ashok Kumar
   * @return   {Void}
   */
  addDataItem(): void {
    this.form.controls[this.dataItem.id].setValue(null);
    this.dataItem['addType'] = 'input';
  }

  /**
   * Function that cancel data item add dropdown FSA-119
   * @author   Ashok Kumar
   * @return   {Void}
   */
  cancelDataItem(): void {
    this.form.controls[this.dataItem.id].setValue(null);
    this.dataItem['addType'] = 'selection';
  }

  /**
   * Function that allow to save dynamic option to dropdown FSA-119
   * @author   Ashok Kumar
   * @return   {Void}
   */
  saveDataItem(): void {
    let newOption = this.form.controls[this.dataItem.id].value;
    let data: any = [{ label: newOption, value: { id: newOption } }];
    let isExits = this.dropDownArray.filter(element => (element.label == newOption))

    if (!isExits.length) {
      this.dataItem.fill = [...this.dataItem.fill, ...data];
      this.dropDownArray = [...this.dropDownArray, ...data];
      this.dataItem['addType'] = 'selection';
      this.filteredDropDownList.next(this.dropDownArray.slice());
    } else {
      this.messageService.add({
        closable: true, sticky: true, severity: 'warn', summary: this.translate.instant('warning'),
        detail: this.translate.instant('taxation_record_duplicacy')
      });
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date;
    ctrlValue.year(normalizedYear.year());
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date;
    ctrlValue.month(normalizedMonth.month());
    //update the picker year also
    ctrlValue.year(normalizedMonth.year());
    let selectedMonth = moment(new Date(JSON.parse(JSON.stringify(ctrlValue))), 'YYYY-MM-DD').format('YYYY-MM');
    this.form.controls[this.dataItem.id].setValue(selectedMonth)
    datepicker.close();
    this.isShowOptions();
  }

  chosenMonthYearHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date;
    ctrlValue.month(normalizedMonth.month());
    //update the picker year also
    ctrlValue.year(normalizedMonth.year());
    let selectedMonth = moment(new Date(JSON.parse(JSON.stringify(ctrlValue))), 'YYYY-MM-DD').format('YYYY-MM');
    this.form.controls[this.dataItem.id].setValue(selectedMonth)
    datepicker.close();
    this.isShowOptions();
  }

  onlyYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date;
    ctrlValue.year(normalizedYear.year());
    let selectedMonth = moment(new Date(JSON.parse(JSON.stringify(ctrlValue))), 'YYYY-MM-DD').format('YYYY');
    this.form.controls[this.dataItem.id].setValue(selectedMonth)
    datepicker.close();
    this.isShowOptions();
  }

  isShowOptions(evt?) {
    if(this.componentName == 'RapidCustodiansEntryComponent'){
      if(this.dataItem.id == "legalNameListChecked" && this.dataItem.controlType == 2){
        this.selectedLegalValue = this.dataItem.fill.filter( el => el.value?.id == evt.value?.id)[0];
        
      }
    }

    if (this.dataItem.id == "employerIdentificationNumber" || this.dataItem.id == "gpEINSSN" || this.dataItem.id == "taxId1" || this.dataItem.id == "taxId2" || this.dataItem.id == "taxId3" || this.dataItem.id == "disregardedTaxId" || this.dataItem.id == "partnershipsTaxId") {
      this.form.controls[this.dataItem.id]['actualValue'] = this.form.controls[this.dataItem.id].value.indexOf('X') > -1 ? this.actualValue : this.form.controls[this.dataItem.id].value;
    }
    this.onChangeCheck.emit(this.dataItem);
  }

  /*
    Auth:Vg
    Params : null
    SDI-95
     */
  isShowOptionsCst(evt) {
    var fills = [];
    var vals = [];
    var vals2 = [];
    fills = this.dataItem.fill;
    vals = evt.value;
    vals = _.sortBy(vals, ["inactive"]);
    vals.forEach((el, idx) => {
      var index = _.findIndex(fills, { id: el.id });
      vals2 = fills.splice(index, 1);
      if (el.inactive) {
        el.label = el["label"];
        el.id = el["id"];
        el.inactive = el["inactive"];
        el.index = idx + 1;
        fills.unshift(el);
      } else {
        el.label = el["label"];
        el.id = el["id"];
        el.inactive = el["inactive"];
        el.index = idx + 1;
        fills.unshift(el);
      }
    });
    let temp1 = [];
    let temp2 = [];
    this.dataItem.fill.forEach((el) => {
      if (el["index"] > evt.value.length + 1) {
        let obj = {
          label: el["label"],
          id: el["id"],
          inactive: el["inactive"],
          index: el["index"],
        };
        temp1.push(obj);
      } else {
        let obj2 = {
          label: el["label"],
          id: el["id"],
          inactive: el["inactive"],
          index: el["index"],
        };
        temp2.push(obj2);
      }
    });
    temp1.sort((a, b) =>
      a.label?.localeCompare(b.label, undefined, { sensitivity: "base" })
    );
    this.onChangeCheck.emit(this.dataItem);
  }

  onFileSelected(event) {
    if (this.dataItem.id == 'template') {
      let evt = {
        id: this.dataItem.id,
        event: event.currentTarget
      }
      this.onChangeCheck.emit(evt);
    } else {
      this.onChangeCheck.emit(event);
    }
  }

  onRadioBtnSelected(event) {
    this.onChangeCheck.emit(event);
  }

  onRadioBtnClick(value: any, label, event: MouseEvent) {

    const control = this.form.controls[this.dataItem.id];
    if (control.status == "DISABLED") {
      event.preventDefault(); // Prevent the default behavior
      return;
    } else {
      if (control.value === value) {
        control.setValue(null); // Unselect the radio button if it is already selected
        event.preventDefault(); // Prevent the default behavior
      } else {
        control.setValue(value); // Select the radio button
      }
      this.onChangeCheck.emit({ value: control.value, label: label });
    }

  }
  selectdate(event) {
    localStorage.setItem('Enddate', event.value);
  }

  filterDropDown() {
    if (!this.dropDownArray) {
      return;
    }
    // get the search keyword
    let search = this.DropDownFilterCtrl.value;
    if (!search) {
      this.filteredDropDownList.next(this.dropDownArray.slice());
      this.getNewBtn();
      return;
    }
    else if((this.componentName == 'CustodiansEntryComponent' || this.componentName == 'RapidCustodiansEntryComponent') && this.dataItem.id =='legalNameListChecked'){
      search = search.toLowerCase();
      this.filteredDropDownList.next(
        // TA-437 || Account configuration | Facing issue in search 
        this.dropDownArray.filter(item => {
          const legalFieldNameMatch = item.label && item.label.toLowerCase().includes(search);
          const aliasMatch = item.value.aliasOfCustodianExchangeWalletBank && item.value.aliasOfCustodianExchangeWalletBank.toLowerCase().includes(search);
          
          return legalFieldNameMatch || aliasMatch;
        })
      );
    }
    else if(this.componentName == 'mappingDefaultCoaTaxClassification' && this.dataItem.id =='nameOfGLAccount'){
      search = search.toLowerCase();
      this.filteredDropDownList.next(
        this.dropDownArray.filter(item => {
          const nameOfGLAccountMatch = item.label && item.label.toLowerCase().includes(search);
          return nameOfGLAccountMatch;
        })
      );
    }
     else {
      search = search.toLowerCase();
      this.filteredDropDownList.next(
        this.dropDownArray.filter(item => item.label?.toLowerCase().indexOf(search) > -1 || (item?.alias && item.alias.toLowerCase().indexOf(search) > -1) )
      );
    }
    this.getNewBtn();
    // filter the dropDown list
  }

  navigatePage() {
    if (this.dataItem.url == 'None') {
      this.setuppercentage.setAddPopupTrigger(this.dataItem.id)
      if (this.multiselectforpopup && this.multiselectforpopup.containerViewChild.nativeElement.classList.contains('p-multiselect-open')) {
        this.multiselectforpopup.hide();
      }
      return
    }
    this.router.navigate([this.dataItem.url]);
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
    } else {
      this.inputType = 'text';
      this.visible = true;
    }
  }

  ngAfterViewInit() {
    this.scrollDispatcher
      .ancestorScrolled(this.el)
      .subscribe(event => this.onWindowScroll());
  }

  onWindowScroll() {
    var rows = document.getElementsByTagName('mat-step-header');
    for (var i = 0; i < rows.length; i++) {
      var selected = rows[i].getAttribute('aria-selected')
      if (selected && rows[i].getBoundingClientRect().top == 64) {
        rows[i].classList.add("colorheader");
      }
      else {
        rows[i].classList.remove("colorheader");
      }
    }
  }

  /*
   Auth:Vg
   Params : null
   Remove White Space for Input Value SM-3042
    */
  onChangeEvent(evt) {
    let fmcnt = this.dataItem.id;
    if (fmcnt) {
      let res = this.form.get(fmcnt).value
      if (res) {
        res = res.trim()
        this.form.get(fmcnt).setValue(res)
      }
    }
  }
  ChangeEvent(event, field) {
    if (event.inputType == 'insertFromPaste') {
      let fmcnt = this.dataItem.id;
      let res = this.form.get(fmcnt).value
      if (res) {
        res = res.replace(/\s/g, "")
        this.form.get(fmcnt).setValue(res)

      }

    }
  }

  getNewBtn(){
    return this.filteredDropDownList.subscribe(item=>{
      this.filteredDropDownitemList = item      
    })    
  }
  filterdDataLength(){
    return this.filteredDropDownitemList.length
  }

  maskingPassword(value) {
    this.actualValue = value;
    this.form.controls[this.dataItem.id]['actualValue'] = value;
    this.dataItem.actualValue = value;

    if (value && value.length > 8) {
      this.form.controls[this.dataItem.id].setValue(value.replace(/\d(?=\d{4})/g, "X"));
      if (this.form.controls[this.dataItem.id].value.length > 8) {
        this.form.controls[this.dataItem.id].disable();
      }
      this.maskVisible = !this.maskVisible;
    } else {
      this.maskVisible = true
    }
  }

  unMaskPassword() {


    if (this.componentName == 'TAXAutomation') {
      let data = this.dataItem.value;
     this.actualValue = this.form.controls[this.dataItem.id]['actualValue'] == '' ? data : this.form.controls[this.dataItem.id]['actualValue'];
    } else {
      this.form.controls[this.dataItem.id]['actualValue'] = this.actualValue;
    }

    this.form.controls[this.dataItem.id].setValue(this.actualValue);
    if(!this.dataItem.disabled){
      this.form.controls[this.dataItem.id].enable();
    }
    this.maskVisible = !this.maskVisible;
  }

  redirectUrl(url){
    window.open(url, "_blank");
  }

  pDropFilter(evt) {
    setTimeout(() => {
      let elemen: any = document.getElementsByClassName("cdk-virtual-scroll-spacer");
      let offsetHeight: any = elemen[0].offsetHeight;
      this.scrollHeight = (offsetHeight + 25) > 200 ? '200px' : (offsetHeight + 25 + 'px');
    });
  }

  pDropOnShow(evt) {
    this.scrollHeight = this.dropDownArray.slice().length <= 5 ? (this.dropDownArray.slice().length * 40 + 'px') : '200px';
  }

  /**
   * Function that allow to update file status FS-554
   * @author   Ashok Kumar
   * @param    {String} type event selection
   * @return   {Void}
   */
  toggleFileEvent(type: string): void {
    let dataItem = this.dataItem;
    dataItem['eventType'] = type;
    this.onChangeCheck.emit(dataItem);
  }
}
