import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
    selector: 'edit-cell-multi-select-dropdown-renderer',
    template: `<span>{{displayValues}}</span>`
})
export class EditCellMultiSelectDropDownRenderer implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    public displayValues: string = '';

    agInit(params: ICellRendererParams) {
        this.params = params;
        this.setDisplayValues();
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        this.setDisplayValues();
        return true;
    }

    setDisplayValues() {
        if (Array.isArray(this.params.value)) {
            this.displayValues = this.params.value.join(', ');
        } else {
            this.displayValues = this.params.value;
        }
    }
}