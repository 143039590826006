/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { ConfirmationDialog } from '../../dialogs/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { environment } from '../../../../../src/environments/environment';
import { CommonService } from '../../services/commonServices/common.service';

@Component({
  selector: 'fury-approve-workflow-status-button',
  templateUrl: './approve-workflow-status-button.component.html',
  styleUrls: ['./approve-workflow-status-button.component.scss']
})
export class ApproveWorkflowStatusButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  private params: any;
  public rowData: any;
  public executionId: any;
  public uploadFlag:boolean = false;
  public heading: String = 'configure_upload_cash_balance_file';
  public upcomponentName: String = 'uploadWorkflow';
  public executionStatus:any;

  constructor(private http: HttpClient, private dialog: MatDialog,
    public translate: TranslateService,
    private messageService: GlobalMessageService,private processScreenService: ProcessScreenService,
    private commonService :CommonService) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.rowData = this.params.data;
    this.executionId = this.params.data ? this.params.data.uniqueNumber : "" ;
    this.executionStatus = this.rowData?.executionStatus.toLowerCase();
  }

  // ProcessDetailsWorkflow(event) {
  //   let selectedNode = this.params.node;
  //   let selectedData = selectedNode.data;
  //   if (this.params.ProcessDetailsWorkflow instanceof Function) {
  //     const params = {
  //       event: event,
  //       rowData: selectedData
  //     }
  //     this.params.ProcessDetailsWorkflow(params);
  //   }
  // }

  // ProcessCancelDetailsWorkflow(event) {
  //   let selectedNode = this.params.node;
  //   let selectedData = selectedNode.data;
  //   if (this.params.ProcessCancelDetailsWorkflow instanceof Function) {
  //     const params = {
  //       event: event,
  //       rowData: selectedData
  //     }
  //     this.params.ProcessCancelDetailsWorkflow(params);
  //   }
  // }

  /*
  Auth : Vg
  Proceed n Cancle Fixed
  Dc24
   */
  ProcessDetailsWorkflow(event) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        message: this.translate.instant('are_you_sure_you_want_to_proceed'),
        header:'Confirm Upload',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
    if(this.executionId){
      this.http.get<any>('alteryxV2/uploadTempData?uniqueId=' + this.executionId).subscribe(response => {
        if(response.success){
              this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('successfully_proceed') });
              this.params.refershGrid();
              if(response.data.autoDownloadFile){
                var uniqueId;
                let type:string = 'Zip file';
                  if (response.data.uniqueNumber) {
                    uniqueId = response.data.uniqueNumber;
                    let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId +'/' +type;
 	                // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
                   this.commonService.GenericDownload(url, 'Failed Data','Failed Data')

                  } else {
                    this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('invalid_data'), detail: this.translate.instant('error') });
                    return;
                  }
               /* if(response.data.autoDownloadFile == "preview"){
                  let url = environment.api_url + 'alteryxExecuteWorkflow/getTempData/' + uniqueId;
                  this.processScreenService.downloadCSV(url, 'Preview Data.csv','Preview Data');  
                }else if(response.data.autoDownloadFile == "failed data"){
                  let url = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
                  this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
                }else if(response.data.autoDownloadFile == "preview and failed data"){
                  //preview data
                  let url = environment.api_url + 'alteryxExecuteWorkflow/getTempData/' + uniqueId;
                  this.processScreenService.downloadCSV(url, 'Preview Data.csv','Preview Data');  
                  // failed data
                  let urlFailed = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
                  this.processScreenService.downloadCSV(urlFailed, 'Failed Data.xlsx','Failed Data');
                }*/
              }
        }
        else {
              this.params.refershGrid();
              this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: response.message });
              // this.messageService.add({sticky:true, severity: 'error', summary: this.translate.instant('error'), detail: 'Error occurred!' });
            }
          })
        }
      }
    });
  }


  ProcessCancelDetailsWorkflow(event) {
      if(this.executionId){
        this.http.get<any>('alteryx/cancelTempData?uniqueId=' + this.executionId).subscribe(response => {
          if(response.success){
          this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('workflow_process_cancelled') });
            this.params.refershGrid();
          }
          else {
          this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('error_occurred!') });
          }
        })
      }
    }

  uploadWorkflow(evt){
    this.uploadFlag = true;     
  }
  uploadReset(evt){
    if(evt == 'reset'){
      this.params.refershGrid();
    }        
  }
}
