<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<!-- <button *ngIf="moduleName=='executeWorkflow'" (click)="uploadErrorStatus($event,'view')"
    [disabled]="!(alteryxWorkflowStatus) || alteryxWorkflowStatus.failureCount === 0"
    [ngStyle]="(!alteryxWorkflowStatus || alteryxWorkflowStatus.failureCount === 0) && {'color':'gray', cursor: 'not-allowed'} || {'color': 'blue'}"
    style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: blue !important; border-style: none; ">Fail Status</button> -->


    <button *ngIf="moduleName=='executeWorkflow'" (click)="errorStatusDetailsWorkflow($event)"
    [disabled]="alteryxWorkflowStatus == null"
    [ngStyle]="(alteryxWorkflowStatus == null) && {'color':'gray', cursor: 'not-allowed'} || {'color': 'blue'}"
    style=" background-color: transparent
    !important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
    align-content: center; color: blue !important; border-style: none; ">{{'view_log'|translate}}</button>

<button
    *ngIf="moduleName=='workflowStatus' || moduleName=='dataImportStatusManager'  || moduleName=='dataImportStatus' "
    (click)="errorStatusDetailsWorkflow($event)" style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
align-content: center; color: blue; border-style: none;"><u>{{'view_log'|translate}}</u></button>


<button *ngIf="moduleName=='usersList'" (click)="usersActiveInactive(params.data)" style=" background-color: transparent
!important; text-align:center; position: relative; top: 50%; cursor: pointer; -webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%); transform: translateY(-50%); align-items: center; text-align: center;
align-content: center; color: blue; border-style: none;"><u>Active </u></button>
<div *ngIf="moduleName =='reportNotificationStatus'" class="material-icons-outlined"
    (click)="downloadAttachment(params.data)" style="color: green;
font-weight: 600;
cursor: pointer;
font-size: 20px;
margin-top: 14px;
padding: 0px 5px;">download</div>

<p-dialog [modal]="true" class="delModal Dialog_width_Small confirm_undo" header="Error Status Detail" [(visible)]="displayBasic"
    [baseZIndex]="10000">
    <div class="row delCriteria" *ngFor="let error of errorList;let indexOfelement=index;">
        <span>{{indexOfelement + 1}} </span>
        <span>{{error.text}}</span>
    </div>

    <p-footer>
        <button mat-mini-fab color="primary" type="button" style="margin: 0px 2px 0px 0px;" (click)="closeDataImport()">
            <mat-icon data-md-tooltip="OK">done</mat-icon>
        </button>
    </p-footer>

</p-dialog>