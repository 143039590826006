/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { apiConstants } from '../../../../../src/app/constants/apiConstants';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
@Component({
  selector: 'fury-attachment-download-button',
  templateUrl: './attachment-download-button.component.html',
  styleUrls: ['./attachment-download-button.component.scss']
})
export class AttachmentDownloadButton{
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  private rowData: any;
  moduleName: any;
  attachment: any;
  attachmentVendor: any;

  constructor(
    private messageService: GlobalMessageService,
    public translate: TranslateService,
      private http: HttpClient
  ) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.rowData = this.params.data;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
  console.log(this.moduleName);
    if(this.moduleName == 'feeSharing'){
      this.attachment=this.params.data.attachAgreement;
    }else if(this.moduleName == 'oathStatement') {
      
      this.attachment=this.params.data.signature;
    }else if(this.moduleName == 'vendor') {
      this.attachment=this.params.data.taxDocument;
      this.attachmentVendor = this.params.data.wiringInstruction

    } else if(this.moduleName == 'fund' || this.moduleName == 'footer'){
      this.attachment = this.params.data.logo;
    }
    else if(this.moduleName == 'bill' || this.moduleName=='unpaidBills' || this.moduleName=='paidBills'
    || this.moduleName == 'rapidBill' || this.moduleName == "rapidUnpaidBills" || this.moduleName == "rapidPaidBills" || this.moduleName == "Invoice" || this.moduleName == "OutstandingInvoice" || this.moduleName == "paymentRecipt" || this.moduleName == "CashIncome"){
      this.attachment = this.params.data.fileURL==null? this.params.data.fileName :this.params.data.fileURL;
    }
     else {
      this.attachment = this.params.data.attachment;
    }
  }

  downloadRowDoc(event,check) {
  
    if (this.params.data) {
      if (this.params.data.id) {
        var usrId = this.params.data.id;

        if (this.moduleName == 'feeSharing') {
          var flUri = this.params.data.attachAgreement;
        } else if (this.moduleName == 'oathStatement') {
          var flUri = this.params.data.signature;
        } else if (this.moduleName == 'vendor') {
          var flUri = this.params.data.taxDocument;
          this.params.column.colId == 'wiringInstruction' ? flUri = this.params.data.wiringInstruction : flUri = this.params.data.taxDocument;
        } 
       else if(this.moduleName == 'fund' || this.moduleName == 'footer'){
        var flUri = this.params.data.logo;
      }
      else if(this.moduleName == 'bill' || this.moduleName=='unpaidBills' || this.moduleName=='paidBills'
      || this.moduleName == 'rapidBill' || this.moduleName == "rapidUnpaidBills" || this.moduleName == "rapidPaidBills" || this.moduleName == "Invoice" || this.moduleName == "OutstandingInvoice" || this.moduleName == "paymentRecipt" || this.moduleName == "CashIncome"){
        var flUri = this.params.data.fileURL;
      }
        else {
          var flUri = this.params.data.attachment;
        }
        var filePath = flUri.split('/');
        var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];

      }
      if(this.params.data.jeId){
       var flUri = this.params.data.attachment;
        var filePath = flUri.split('/');
        var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];
      }
    
    }
    this.downloadFileFromAWS(flUri).subscribe(res => {
      var a = document.createElement('a');
      var blob = new Blob([res], { type: res.ContentType });
      a.href = URL.createObjectURL(blob);
      a.download = filePath[5];
      a.click();
       
      this.messageService.add({ sticky: true, severity: 'success',summary: this.translate.instant('success'), detail: this.translate.instant('file_download_successful') });
    })

  }

  downloadFileFromAWS(fileUrl): Observable<any> {
    var filePath = fileUrl.split('/');
    var fileName = filePath[filePath.length - 3] + "/" + filePath[filePath.length - 2] + "/" + filePath[filePath.length - 1];
    return this.http.post<any>('documentManagementV2/download', fileName, { responseType: 'blob' as 'json' });
  }
}

