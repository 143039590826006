/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as _screenfull from 'screenfull';

/** Screenfull typings are wrong, so we override them */
const screenfull = _screenfull as any;

@Component({
  selector: 'fury-toolbar-fullscreen-toggle',
  templateUrl: './toolbar-fullscreen-toggle.component.html',
  styleUrls: ['./toolbar-fullscreen-toggle.component.scss']
})
export class ToolbarFullscreenToggleComponent implements OnInit {

  isFullscreen = false;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    screenfull.on('change', () => this.setFullscreen(screenfull.isFullscreen));
  }

  toggleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
      this.setFullscreen(screenfull.isFullscreen);
    }
  }

  setFullscreen(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
    this.cd.markForCheck();
  }
}
