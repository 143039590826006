/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LIST_FADE_ANIMATION } from '../../../../@fury/shared/list.animation';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../../../shared/services/commonServices/common.service';
import { LocalStoreService } from '../../../shared/services/commonServices/local-store.service';
import { AuthService } from '../../../shared/services/commonServices/auth.service';
import { SidenavService } from '../../sidenav/sidenav.service';
import { LayoutService } from '../../layout.service';
import { DataService } from '../../../shared/services/commonServices/data.service';
import { ToolbarComponent } from 'src/app/layout/toolbar/toolbar.component';
import { GridService } from 'src/app/shared/services/gridServices/grid.service';
import { ToolbarTrainingVideosService } from 'src/app/layout/toolbar/toolbar-training-videos/toolbar-training-videos.service'
import { SetupPercentageService } from 'src/app/shared/services/commonServices/setup-percentage.service';


@Component({
  selector: 'fury-toolbar-training-videos',
  templateUrl: './toolbar-training-videos.component.html',
  styleUrls: ['./toolbar-training-videos.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarTrainingVideosComponent implements OnInit {
  @Output() autoplay = new EventEmitter();
  notifications: any[];
  isOpen: boolean;
  videoFlag: boolean = false;
  subscription1;
  public videoData: any;
  public sortedVideoData: any = {};
  public updatedVideoData: any = [];
  public currentCatName: any = 'empty';
  public currentSubCatName: any = 'empty';
  public currentVideoTile: any = 'empty';
  public category: any;
  public subCategory: any = {};
  public defaultData: any;
  public gridHeaders: any;
  public uploadData: {};
  public tradeData: [];
  public portfolioData: [];
  public AccountingData: [];
  public investorData: [];
  public activeTest: any;
  
  viewDataJsons: any[];
  viewDataJsonsArray = [];
  viewModuleJsons;
  favModuleJson;
  public favComponentJson = {};
  favSaveJsons;
  public favSaveJsonArray = {};
  public apiManagement: any;
  favSaveJsonArrayValue;
  viewModule;
  countTrade = 0;
  countPortfolio = 0;
  countAccounting = 0;
  countInvestor = 0;
  public currentUser: any;
  public modules: any = [];
  roleAs: string;
  username: any;
  userPerferenceList: any;
  httpbacknd;
  defaultDataIsValid: boolean = false;
  selectedIndex: any = 4;
  defaultVideoData: any;
  selectedSubCategory: any = 0;
  indexofCard: any;
  indexofElement: any;
  indexofVideo: any;
  hideshow: any;
  isDoubleClick: boolean=false;
  isDoubleVideoClick: boolean=false;
  constructor(private httpClient: HttpClient, handler: HttpBackend,
    private dataService: DataService, private gridService: GridService,
    private commonService: CommonService,
    private sidenavService: SidenavService,
    private toolbarTrainingVideosService: ToolbarTrainingVideosService,
    private localService: LocalStoreService,
    private setuppercentage: SetupPercentageService,
    private cd: ChangeDetectorRef) {
    this.httpbacknd = new HttpClient(handler);



    // this.setuppercentage.getIsPlay().subscribe(res => {
    //   if (res) {
    //     if (this.videoFlag == false && !this.hideshow) {
    //       this.videoFlag = true;

    //       this.defaultData = this.getVideoUrl(0, 0, this.defaultVideoData);
    //     }
    //   }
    // });

 
  }

  ngOnDestroy(): void {
    // Commenting below line because this subscription1 is no where 'subscribed' in the code.
    // this.subscription1.unsubscribe();
  }

  ngOnInit() {

    
    this.setuppercentage.getVideoStatus().subscribe(res => {
  this.hideshow=res;
      const videoPlayer = <HTMLVideoElement>document.querySelector('video');
      if (videoPlayer) { videoPlayer?.pause(); }
    });
   
    this.toolbarTrainingVideosService.getTrainingVideos().subscribe(res => {
      this.videoData = res.data;
      this.currentUser = JSON.parse(this.localService.getItem('fss'));
      this.category = Object.keys(this.videoData);

      Object.keys(this.videoData).forEach(key => {
        this.subCategory[key] = Object.keys(this.videoData[key]);
      });


      this.category.forEach(key => {
        this.subCategory[key].forEach(cat => {
        })
      });
      this.defaultData = "empty";
      this.activeTest = true;
      let categories = Object.entries(res.data);
      categories.forEach((el) => {
        let subCategoryy = [];
        subCategoryy = Object.keys(res.data[el[0]])
        subCategoryy.forEach((ele) => {
          let categoryy = new Object();
          categoryy['categoryName'] = el[0];
          categoryy['subCategoryName'] = ele;
          categoryy['videos'] = el[1][ele];
          if (this.currentCatName == 'empty') {
            this.currentCatName = el[0];
          }
          if (this.currentSubCatName == 'empty') {
            this.currentSubCatName = ele;
          }
          if (this.currentVideoTile == 'empty') {
            this.currentVideoTile = el[1][ele][0].title;
            this.defaultVideoData = el[1][ele][0];
          }
          categoryy['videos'].sort((a, b) => a.displayOrder - b.displayOrder);
          categoryy['videos'].forEach((v) => {
            v['isStart'] = false;
            v['videoUrl'] = null;
            v['logoUrl'] = v.logoUrl != null ? v.logoUrl : 'assets/img/formidium-training-videos.png';
          });
          this.updatedVideoData.push(categoryy);


        })
      });
    });

   
  }


  toggleDropdown(item?) {
    if (item && item.breadcrumbs) {
      this.commonService.moduleNameObs$.next(item.breadcrumbs);
    }
    this.isOpen = !this.isOpen;
    this.autoplay.emit(this.isOpen);
    if(this.isOpen){
        if(this.indexofCard){
          this.isDoubleClick =true;
           this.getVideoUrl(this.indexofCard, this.indexofElement, this.indexofVideo);
    }
    else{
      this.isDoubleClick =true;
      this.getVideoUrl(0, 0, this.defaultVideoData)
    }

    }
    setTimeout(() => {
      this.sidenavService.setCollapsed(this.isOpen);
    }, 0)
  }
  closeDialog(){
  this.isOpen = false;
  this.autoplay.emit(this.isOpen);
  setTimeout(() => {
    this.sidenavService.setCollapsed(this.isOpen);
  }, 0)
  }
  onClickOutside() {
    if (this.isOpen) {
      this.sidenavService.setCollapsed(false);
      this.isOpen = true;
    }
    if(this.hideshow){
    this.isOpen = true;
    this.autoplay.emit(this.isOpen);
    this.dataService.getTrainingVideoData().subscribe(res => {  
      
        let index; 
        let subCategoryIndex;  
        let videoLink;
        this.updatedVideoData.forEach( (el , i) => {
              if(el.subCategoryName == res[1]){
                    index = i;
                    el.videos.forEach((e2 , titleInx) => {
                      if(e2.displayOrder ==res[2] )
                      {
                        subCategoryIndex=titleInx
                       videoLink =e2;
                      }
                 }) 
              }
              
        })
 
      this.getCurrentSubCatName(res[0] , res[1], videoLink, index,res[3],subCategoryIndex )

    })
  
    
  }
  else{
    this.isOpen = false;
    this.autoplay.emit(this.isOpen);
  }
}


  getVideoUrl(indexofCard, indexofElement, element) {
    this.indexofCard = indexofCard;
    this.indexofElement = indexofElement;
    this.indexofVideo = element;
    this.defaultDataIsValid = false;
    this.cd.detectChanges();
    this.currentVideoTile = element.title
    this.selectedIndex = indexofElement;
    this.updatedVideoData.forEach(el => {
      el.videos.forEach((v) => {
        v.isStart = false;
        v.videoUrl = null;
      });
    });
    let vDetails = { id: element.id, fileUrl: element.filePath }
    let data = btoa(JSON.stringify(vDetails))

   this.startTrainingVideoData(indexofCard,indexofElement,data)
  }

  startTrainingVideoData(indexofCard,indexofElement,data) {
  
    this.toolbarTrainingVideosService.startTrainingVideo(data).subscribe(response => {
      if (response) {
        this.isDoubleClick =false;
        this.isDoubleVideoClick =false;
        this.updatedVideoData[indexofCard]['videos'][indexofElement]['videoUrl'] = response.data.fileUrl;
        this.updatedVideoData[indexofCard]['videos'][indexofElement]['viewCount'] = response.data.viewCount;
        this.updatedVideoData[indexofCard]['videos'][indexofElement]['isStart'] = true;
        this.defaultData = response.data.fileUrl;
        this.defaultDataIsValid = true;
        this.cd.detectChanges();
      } else {
        this.defaultDataIsValid = false;
      }

    })
  }
  getCurrentSubCatName(cat, sub, v, indexofCard,videoedit?,titleIdex?) {

    if (this.isDoubleClick && this.isDoubleVideoClick) return;
    this.isDoubleClick = true; 
    this.isDoubleVideoClick=true;
    this.currentCatName = cat
    this.currentSubCatName = sub;
    this.selectedSubCategory = indexofCard;
    this.defaultDataIsValid = false;
    if(videoedit){
      this.selectedIndex = titleIdex;
       this.currentVideoTile = v.title;
       this.getVideoUrl(indexofCard, titleIdex, v);
    }
    else {
      this.selectedIndex = 0;
      this.currentVideoTile = v[0].title;
      this.getVideoUrl(indexofCard, 0, v[0]);
    }
    setTimeout(() => {
    
      this.isDoubleVideoClick=false;
    }, 2000)
    this.cd.detectChanges();
  }



}
