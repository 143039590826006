/*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2021 - 2022 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";



@Component({
  selector: 'account-summary-date',
  templateUrl: './account-summary-date.component.html',
  styleUrls: ['./account-summary-date.component.scss'],
  providers: []
})
export class AccountSummaryDateComponent {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  public enterpriseId: string;
  public currentUser: any;
  postingDate:any;
  data:any={}
  public isReadonly: boolean = false;

  constructor( ) { }


  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.data = this.params.data;
    this.postingDate = this.params.value;
    this.isReadonly = this.params.node.selected == true ?  true : false;
  }

 


}