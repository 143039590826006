<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div class="mat-titleheader" *ngIf="header">
	<mat-dialog-title >{{header}}</mat-dialog-title>
</div>
<mat-dialog-content>
	<p>
		{{message}}
	</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
	<button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button mat-raised-button mat-dialog-close tabindex="-1"  *ngIf="!isCancelHide">{{cancelButtonText}}</button>
</mat-dialog-actions>
