/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from '../../services/commonServices/local-store.service';


@Component({
  selector: 'run-alert-threshold-button',
  templateUrl: './run-alert-threshold-button.html',
  styleUrls: ['./run-alert-threshold-button.scss'],
  providers: []
})
export class RunAlertThresholdButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  public enterpriseId: string;
  public currentUser: any;
  public runFormDetail: { fundList: any[]; startDate: string; endDate: string; };
  public display: boolean;

  constructor(private http: HttpClient,private messageService: GlobalMessageService,public translate: TranslateService, private localService : LocalStoreService) { }
  availableFunds: any[];


  agInit(params: ICellRendererAngularComp): void {
    this.params = params;

  }

  runCompliance(event) {

    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.enterpriseId = this.currentUser['enterpriseId'];
    this.availableFunds = [];

    this.http.post<any>('alertThresholdV2/getFunds/' + this.enterpriseId, null).subscribe(res => {

      if (typeof res.status && res.status === 422) {
         
        this.messageService.add({sticky:true,   severity: 'error', summary: this.translate.instant('alert_threshold_error'), detail: this.translate.instant('went_wrong_fund') });
      } else {
        this.availableFunds = res.data;
        let selectedNode = this.params.node;
        let selectedData = selectedNode.data;

        if (this.params.runCompliance instanceof Function) {
          const params = {
            event: event,
            fundlist: this.availableFunds,
            rowData: selectedData
          }
          this.params.runCompliance(params);
        }

      }

    }, error => {
       
      this.messageService.add({sticky:true,   severity: 'error', summary: this.translate.instant('alert_threshold_error'), detail: this.translate.instant('went_wrong_fund') });
    })


  }


}