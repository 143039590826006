<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<div (furyClickOutside)="onClickOutside()" class="toolbar-notifications" fxFlex="grow" fxLayout="row"
  fxLayoutAlign="start center">
  <button (click)="toggleDropdown()" [class.open]="isOpen" class="button" fxFlex="grow" mat-button type="button"
    matTooltip="{{'notification'|translate}}">
    <mat-icon [matBadgeHidden]="notifications.length === 0" [matBadge]="notifications.length" class="icon">
      notifications_active
    </mat-icon>
  </button>

  <div [class.open]="isOpen" class="dropdown dropnotify">
    <fury-card>
      <fury-card-header class="dropdown-header">
        <fury-card-header-heading>
          Notifications
        </fury-card-header-heading>
        <fury-card-header-subheading>
          You have {{ notifications.length }} new notifications.
        </fury-card-header-subheading>

        <fury-card-header-actions>
          <button mat-icon-button type="button">
            <mat-icon class="icon">settings</mat-icon>
          </button>
        </fury-card-header-actions>
      </fury-card-header>

      <fury-card-content class="dropdown-card-content">
        <fury-scrollbar class="dropdown-content">
          <div fxLayout="column">
            <ng-container *ngFor="let notification of notifications; let last = last">

              <div class="notifcatin">
                <div class="labels">Create Order Entry Form</div>
                <mat-progress-bar mode="determinate" value="25"></mat-progress-bar>
              </div>

              <div class="notifcatin">
                <div class="labels">Side Pocket Creation</div>
                <mat-progress-bar mode="determinate" value="80"></mat-progress-bar>
              </div>
              <div class="notifcatin">
                <div class="labels">Mid Period Reset </div>
                <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
              </div>
              <div class="notifcatin">
                <div class="labels">Investor Addresses </div>
                <mat-progress-bar mode="determinate" value="15"></mat-progress-bar>
              </div>
              <div class="notifcatin">
                <div class="labels">Investor Addresses</div>
                <mat-progress-bar mode="determinate" value="10"></mat-progress-bar>
              </div>

            </ng-container>
          </div>

        </fury-scrollbar>
      </fury-card-content>
    </fury-card>
  </div>
</div>