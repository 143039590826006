/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutDirective } from './page-layout.directive';
import { PageLayoutHeaderDirective } from './page-layout-header.directive';
import { PageLayoutContentDirective } from './page-layout-content.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PageLayoutDirective, PageLayoutHeaderDirective, PageLayoutContentDirective],
  exports: [PageLayoutDirective, PageLayoutHeaderDirective, PageLayoutContentDirective]
})
export class PageLayoutModule {
}
