<div class="mergePdfModel">
    <div style="width: 70%;">
        <p-fileUpload id="file" type="'file'" #fileUpload accept=".pdf" class="isdupload" [showCancelButton]="false"
            [showUploadButton]="false" chooseLabel="{{'browse_file'|translate}}" multiple="multiple" customUpload="true"
            (onBeforeUpload)="clear($event)" (uploadHandler)="onUpload($event)" (onSelect)="onFileSelected($event)"
            dragDropSupport="true">
            <ng-template pTemplate="content">
                <div for="myFile" class="isduplaod">
                    <img src="assets/img/input-upload-icon.png">
                    <h3>
                        {{'choose_file_drag_here'|translate}}</h3>
                </div>
            </ng-template>
        </p-fileUpload>
    </div>
    <div class="form-btns" style="width: 25%;display:flex;place-content: space-evenly;">
        <button class="download-btn" [disabled]="UploadDisabled || !uploadedData.length" (click)="uploadDocument()"
            color="primary" mat-raised-button>{{'download'|translate}}</button>
    </div>
    <div style="width: 5%;">
        <!-- <a style="cursor: pointer" (click)="clear()" class="addSelectOption-non addicon_right">
        <mat-icon>clear</mat-icon>
      </a> -->
    </div>
</div>
<div><span class="msg">
        Total File Size: {{totalSize}}MB
    </span></div>
<div class="warnig-msg">
    <span class="material-icons-outlined warning_icon">info</span>
    <span class="msg">
        All Uploaded Documents size can't exceed 9 MB.
    </span>
</div>

<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of uploadedData" cdkDrag>
        <div class="content">
            <span class="material-icons-outlined drag-indictor drag-icon" cdkDragHandle>
                drag_indicator
            </span><span class="file_name">{{item.name}}</span>
            <span class="material-icons-outlined delete_btn" (click)="deleteFile(item)" matTooltip="{{'delete'|translate}}">clear</span>
        </div>
    </div>
</div>