<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div class="process-cancel" style=" color:  #1976d2; border-style: none;">
	<button (click)="ProcessDetailsWorkflow($event)"
		[disabled]="rowData.status != 'Completed' || rowData.processStatus == 'Done'|| rowData.processStatus == 'Cancel' || !rowData.isDataDumpInTemp || rowData.dataCountTemp === 0 || !rowData.uploadData  || executionStatus == 'no data' || rowData.isExecuted || rowData.fileDownloadStarted"
		[ngStyle]="(rowData.status != 'Completed' || rowData.processStatus == 'Done' || rowData.processStatus == 'Cancel' || !rowData.isDataDumpInTemp || rowData.dataCountTemp === 0 || !rowData.uploadData || executionStatus == 'no data' || rowData.isExecuted || rowData.fileDownloadStarted) && {'color':'gray', cursor: 'not-allowed'} || {'color': ' #1a73f1'}"
		style="cursor: pointer; background-color: transparent !important; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;">
		<u style="text-decoration: none;" *ngIf="!rowData.uploadData && rowData.isDataDumpInTemp" matTooltip="{{'Please setup upload from enterprise login.'|translate}}" >Proceed</u>
		<u style="text-decoration: none;" *ngIf="!(!rowData.uploadData && rowData.isDataDumpInTemp)" >Proceed</u>
	</button>
	<button (click)="ProcessCancelDetailsWorkflow($event)"
		[disabled]="rowData.status != 'Completed' || rowData.processStatus == 'Done'|| rowData.processStatus == 'Cancel' || !rowData.isDataDumpInTemp || rowData.dataCountTemp === 0 || executionStatus == 'no data' || rowData.isExecuted || rowData.fileDownloadStarted"
		[ngStyle]="(rowData.status != 'Completed' || rowData.processStatus == 'Done' || rowData.processStatus == 'Cancel' || !rowData.isDataDumpInTemp || rowData.dataCountTemp === 0 || executionStatus == 'no data' || rowData.isExecuted || rowData.fileDownloadStarted) && {'color':'gray', cursor: 'not-allowed'} || {'color': ' #1a73f1'}"
		style="cursor: pointer; background-color: transparent !important; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;">
		<u style="text-decoration: none;">Cancel</u>
	</button>
	<button (click)="uploadWorkflow($event)" [disabled]="rowData.processStatus == 'Done'" style="cursor: pointer; background-color: transparent !important; text-align:center; position: relative; align-items: center; text-align: center; align-content: center;  border-style: none;" [ngStyle]="(rowData.processStatus == 'Done') && {'color':'gray', cursor: 'not-allowed'} || {'color': ' #1976d2'}">
		<mat-icon style="border-style: none;margin-top: 1rem;" >file_upload</mat-icon>
		<!-- color: rgb(148, 148, 151); -->
    </button>	
</div>

<file-dynamic-upload *ngIf="uploadFlag" [componentName]="upcomponentName" [heading]="heading" [isCloseBtn]="true" [isUploadBtn]="true"
[isSubmitBtn]="true" [isShowCustomLBtn]='true' [showSelectClient]="false" [isTemplateBtn]="true" [uniqueId]="executionId" (uploadResult)="uploadReset($event)"></file-dynamic-upload>




