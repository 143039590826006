/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  splashScreenElem: HTMLElement;

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private animationBuilder: AnimationBuilder) {
                // var domainName = window.location.hostname.split('.')[1];
                // if(domainName == 'cryptobackoffice')
                // {
                //   this.splashScreenElem = this.document.body.querySelector('#crypto-splash-screen'); 

                // }else{
                //   this.splashScreenElem = this.document.body.querySelector('#fury-splash-screen');

                // }
                this.splashScreenElem = this.document.body.querySelector('#fury-splash-screen');

    if (this.splashScreenElem) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        take(1)
      ).subscribe(() => this.hide());
    }
  }

  hide() {
    const player = this.animationBuilder.build([
      style({
        opacity: 1
      }),
      animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({
        opacity: 0
      }))
    ]).create(this.splashScreenElem);

    player.onDone(() => this.splashScreenElem.remove());
    player.play();
  }
}
