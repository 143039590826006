/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;
@Component({
  selector: 'run-data-import-error-file-button',
  templateUrl: './run-data-import-error-file-button.component.html',
  styleUrls: ['./run-data-import-error-file-button.component.scss'],
  providers: []
})
export class RunDataImportErrorFileButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  alteryxWorkflowStatus: any;
  public omsCancel: boolean;
  public omsApproveList: boolean;
  public omsEditList: boolean;
  currentUser: any;
  enterpriseId: any;
  moduleName: any;
  executionId: any;
  errorList: any;
  displayBasic: boolean = false;
  postId;
  errorMessage;
  display: boolean;
  constructor(private http: HttpClient,
    private messageService: GlobalMessageService) { }
  public overAllStatus: any;

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.overAllStatus = this.params.value;
    this.executionId = this.params.data.executionId;
    this.alteryxWorkflowStatus = this.params.data.alteryxWorkflowStatus;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;

  }

  uploadErrorStatus(event, view) {
    this.http.get<any>('configurationsV2/getDataImportStatusFailedRecord/' + this.alteryxWorkflowStatus.id).subscribe(response => {
      if (response) {
         this.messageService.add({sticky:true,   severity: 'success', summary: 'Fail Staus file', detail: 'Download Request taken. Your download will start soon.' });
        alasql('SELECT * INTO XLSX("Upload failed Status.xlsx",{headers:true}) FROM ?', [response.data]);
      }
    })
  }

  errorStatusDetails(executionId, view) {
    this.http.get<any>('configurationsV2/getWorkflowStatusById/' + executionId).subscribe(response => {
      if (response) {
        this.errorList = response.messages;
        this.displayBasic = true;
      }
    })
  }

  errorStatusDetailsWorkflow(event) {
    
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.errorStatusDetailsWorkflow instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.errorStatusDetailsWorkflow(params);
    }
  }
  errorStatusDetailsWorkflow1(event) {
    
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.errorStatusDetailsWorkflow1 instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.errorStatusDetailsWorkflow1(params);
    }
  }

  public usersActiveInactive(postData: Object) {
    let url = "user/update"
    this.http.put<any>(url, postData)
      .subscribe({
        next: data => {
          this.postId = data.id;
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('There was an error!', error);
        }
      });
  }

  sentMailToInvestor(data) {
    this.http.get<any>('reportDeliveryWorkflowV2/prepareInvestorDetail?workflowId=' + data.id).subscribe(response => {
    })
    this.display = true;
  }

  closeDataImport() {
    this.displayBasic = false;
    this.display = false;
  }

  downloadAttachment(data) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', data.attachmentURL[0]);
    link.setAttribute('download', `products.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
