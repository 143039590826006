/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { BatchTrackingEntryService } from '../batch-tracking-entry.service';

@Component({
  selector: 'fury-batch-schedule-button',
  templateUrl: './batch-schedule-button.component.html',
  styleUrls: ['./batch-schedule-button.component.scss']
})
export class BatchScheduleButtonComponent {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  }
  public params: any;
  alteryxWorkflowStatus: any;
  public omsCancel: boolean;
  public omsApproveList: boolean;
  public omsEditList: boolean;
  public moduleName: string;
  public wrkFlwSts:any;
  executionId: any;
  updatesctrue: boolean;
  isActive:boolean;
  constructor(private http: HttpClient, private messageService: GlobalMessageService,
    public batchTrackingEntryService: BatchTrackingEntryService) { }
  public status: any;

  agInit(params: ICellRendererAngularComp): void {
    
    this.params = params;
    this.isActive=params['data'].isActive;
  console.log('parms',params)
      this.moduleName = apiConstants[this.params.api.componentName]['moduleName'];
    
   
  }

 

  batchScduleDetail(event) {
    
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.batchScduleDetail instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.batchScduleDetail(params);
    }
  }
  updateSchdule(event) {
      
      this.updatesctrue=true
     var id='b0931850-a821-313c-a1de-725dae3bbab7'
      //var reqObj = { "id": this.workflowID, "comments": this.comment }
      this.batchTrackingEntryService.updateSchedules(this.params.node.data.id,this.isActive).subscribe(response => {
     // this.http.put<any>('batch/schedule'+ '/'+ this.params.node.data.batchId,this.httpOptions).subscribe(response => {
        if (response!=null) {
          const status = this.isActive ? 'started' : 'stopped';
           this.messageService.add({sticky:true, closable: true, severity: 'success', summary: response.status, detail: response.message });
        }else{
           this.messageService.add({sticky:true, closable: true, severity: 'error', summary: 'Request Failed!', detail: 'Something went wrong.' });
        }
      })
   
 
}
}
