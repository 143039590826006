<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<div mat-dialog-title>Customer</div>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>

    <div class="person">
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
        <mat-form-field fxFlex>
          <mat-label>First Name</mat-label>
          <input type="text" formControlName="firstName" matInput>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>Last Name</mat-label>
          <input type="text" formControlName="lastName" matInput>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
      <mat-form-field fxFlex>
        <mat-label>Street</mat-label>
        <input type="text" formControlName="street" matInput>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
      <mat-form-field fxFlex>
        <mat-label>Zipcode</mat-label>
        <input type="text" formControlName="zipcode" matInput>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>City</mat-label>
        <input type="text" formControlName="city" matInput>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px">
      <mat-form-field fxFlex>
        <mat-label>Phone Number</mat-label>
        <input type="text" formControlName="phoneNumber" matInput>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="isCreateMode()" mat-button [disabled]="!form.valid">CREATE CUSTOMER</button>
    <button *ngIf="isUpdateMode()" mat-button [disabled]="!form.valid">UPDATE CUSTOMER</button>
  </mat-dialog-actions>
</form>
