<!-- 
 /*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2017 - 2018 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/ -->
 
 <div style="position: relative; width: 100%; height: 100%; display: flex; align-items: center; border: 1px solid lightgray;">
  <input [type]="inputType" [formControl]="inputControl" numbersOnly [disabled]="isDisabled" minlength="9" maxlength="9" 
         style="width: calc(100% - 30px); height: 100%; padding-left: 17px; border: none; outline: none;" 
         [ngClass]="{'invalid': inputControl.invalid && (inputControl.dirty || inputControl.touched)}" 
         autocomplete="new-password" (input)="filterNonNumeric($event)">
  <button type="button" (click)="toggleVisibility()" 
          style="position: absolute; right: 0; height: 100%; width: 30px; border: none; background: none; cursor: pointer;">
    <mat-icon *ngIf="visible" style="font-weight: normal;">visibility</mat-icon>
    <mat-icon *ngIf="!visible" style="font-weight: normal;">visibility_off</mat-icon>
  </button>
</div>