/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from 'src/app/constants/apiConstants';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { environment } from '../../../../../src/environments/environment';

import { Observable } from 'rxjs';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;
@Component({
  selector: 'fury-grdbtn-row-down',
  templateUrl: './grd-schd-btn.component.html',
  styleUrls: ['./grd-schd-btn.component.scss']
})
export class GrdSchdBtnComponent {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  private rowData: any;
  moduleName: any;
  alteryxWorkflowStatus: any;
  attachment: any;
  public dailogFlag:boolean = false;

  constructor(
    private processScreenService: ProcessScreenService,
    private messageService: GlobalMessageService, private http: HttpClient
  ) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.rowData = this.params.data;
    this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
    if (this.moduleName == "executeWorkflow" && this.rowData && this.rowData.alteryxWorkflowStatus) {
      this.alteryxWorkflowStatus = this.rowData.alteryxWorkflowStatus;
    } else if (this.moduleName == "workflowStatus" && this.rowData) {
      this.alteryxWorkflowStatus = this.rowData;
    }
    this.attachment=this.params.data.attachment;
  }

  gridSchRowEventCompliance(event) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.gridSchRowEventCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.gridSchRowEventCompliance(params);
    }
  }

  
}
