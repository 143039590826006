/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { GridService } from '../../services/gridServices/grid.service';

@Component({
  selector: 'market-data-button',
  templateUrl: './market-data-button.html',
  styleUrls: ['./market-data-button.scss'],
  providers: []
})
export class MarketDataBtnCellRenderer {
  public params: any;
  public checkboxSelection1: any;
  public checkboxSelection2: any;
  public Inputboxfundname: any;
  public Inputboxmanualprice: any;
  public brokerCheck = false;
  public previousCheck = false;
  public rowData: any;
  filterText: string = '';
  public checkboxAllbrokercheck: any;
  public checkboxAllpreviouscheck: any;
  public manualPrice:any;
  public gridOption: GridOptions;
  constructor(private gridService: GridService, public dialogService: DialogService) {

  }

  agInit(params: any): void {
    this.params = params;
    this.rowData = this.params.data
    this.brokerCheck = this.params.data == undefined ? false : this.rowData.brokerCheck;
    this.previousCheck = this.params.data == undefined ? false : this.rowData.previousCheck;
    this.checkboxSelection1 = this.params.colDef.field == "brokerCheck" && (this.params.value != undefined || this.params.value == null) ? true : false;
    this.checkboxSelection2 = this.params.colDef.field == "previousCheck" && (this.params.value != undefined || this.params.value == null) ? true : false;
    this.Inputboxfundname = this.params.colDef.field ? this.params.colDef.field == "fundName" : false;
    this.Inputboxmanualprice = this.params.colDef.field ? this.params.colDef.field == "manualPrice" : false;
    this.checkboxAllbrokercheck = this.params.data == undefined && this.params.colDef.field == "brokerCheck" ? true : false;
    this.checkboxAllpreviouscheck = this.params.data == undefined && this.params.colDef.field == "previousCheck" ? true : false;
  }

  onCheckChange(evt, selectedData, check) {
    if (selectedData == 'brokerCheck') {
      //console.log(this.params.node);
      if (check) {
        this.rowData.manualPrice=null;
        this.params.node.setDataValue('brokerCheck', true)
        this.params.node.setDataValue('priceoverridingSource', 'Broker')
        this.params.node.setDataValue('priceOverriding', this.rowData.brokerPrice)
        this.params.node.setDataValue('previousCheck', false)
        this.params.node.setDataValue('manualPrice', this.rowData.manualPrice)
      }
      else {
        this.rowData.brokerCheck = false;
        this.params.node.setDataValue('priceoverridingSource', '')
        this.params.node.setDataValue('priceOverriding', null)
        this.params.node.setDataValue('previousCheck', false)
      }
    }
    else if (selectedData == 'previousCheck'){
      if (check) {
        this.manualPrice=null;
        this.params.node.setDataValue('previousCheck', true)
        this.params.node.setDataValue('priceoverridingSource', 'PreviousDay')
        this.params.node.setDataValue('priceOverriding', this.rowData.lastTradePrice)
        this.params.node.setDataValue('brokerCheck', false)
        this.params.node.setDataValue('manualPrice', this.manualPrice)
      }
      else {
        this.rowData.previousCheck = false;
        this.params.node.setDataValue('priceoverridingSource', '')
        this.params.node.setDataValue('priceOverriding', null)
        this.params.node.setDataValue('brokerCheck', false)
      }
    }
    else {
        this.params.node.setDataValue('priceoverridingSource', 'Manual')
        this.params.node.setDataValue('priceOverriding', check)
        this.params.node.setDataValue('brokerCheck', false)
        this.params.node.setDataValue('previousCheck', false)
    }
    return
  }
  onInputChanged(evt, selectedData, check) {
    //console.log('select all')
    this.params.updateCount(check, selectedData);
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }
}