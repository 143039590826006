
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';


@Component({
  selector: 'fury-grid-heder-btn',
  templateUrl: './grid-heder-btn.component.html',
  styleUrls: ['./grid-heder-btn.component.scss']
})
export class GridHederBtnComponent {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  public isChecked: boolean = false;
  public fstItmFrqy = null;
  selectedRows: any;

  constructor(private http: HttpClient, private messageService: GlobalMessageService) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.isChecked = false;
  }

  selctAllChange(eve){
  //   if(eve.checked){
  //     this.params.api.forEachNode((node) => {
  //        node.setSelected(true);
  //     })
  //  }else{
  //   this.params.api.forEachNode((node) => {
  //        node.setSelected(false);
  //     })
  //  }
    let flg = true;
    let flg2 = true;
    if(eve.checked){
       this.params.api.forEachNode((node) => {
          let curtSts = node.data ? (node.data.alteryxWorkflowMngt ? node.data.alteryxWorkflowMngt.workflowMapping : null) : null;
          if (curtSts) {
            if(flg2){
              this.params.api.forEachNode((node) => {
                node.setSelected(true);
              })
            }
          } else {
            flg2 = false;
            this.params.api.forEachNode((node) => {
              node.setSelected(false);
            })
        
          }
      })
      // this.params.api.forEachNode((node) => {
      //   if (this.fstItmFrqy == null && node.rowIndex == 0 ) {
      //       this.fstItmFrqy = node.data ? (node.data.alteryxWorkflowMngt ? node.data.alteryxWorkflowMngt.frequency : null) : null;
      //     } else {
      //       let curtFrqy = node.data ? (node.data.alteryxWorkflowMngt ? node.data.alteryxWorkflowMngt.frequency : null) : null;
      //       if (curtFrqy == this.fstItmFrqy) {
      //         if(flg){
      //           this.params.api.forEachNode((node) => {
      //             node.setSelected(true);
      //           })
      //         }
      //         else{
      //           this.params.api.forEachNode((node) => {
      //             node.setSelected(false);
      //           })
      //         }
      //       } else {
      //         flg = false;
      //         this.params.api.forEachNode((node) => {
      //           node.setSelected(false);
      //         })
      //       }
      //   }
      // })
      if(!flg2){
        this.params.api.forEachNode((node) => {
          node.setSelected(false);
        })
        // this.messageService.clear();
        this.messageService.add({ closable: true, sticky: true, severity: 'error', summary: 'Error', detail: 'Some workflow are not mapped, Please select workflow individually.' });
      }
      // if(!flg){
      //   this.params.api.forEachNode((node) => {
      //     node.setSelected(false);
      //   })
      //   // this.messageService.clear();
      //   this.messageService.add({sticky:true,severity: 'error', summary: 'Error', detail: 'Please select the workflows with similar frequencies.You can configure this from Enterprise login.',closable: true });
      // }
    }else{
      this.params.api.forEachNode((node) => {
        node.setSelected(false);
      })
      //this.messageService.clear();
    }
  }
}
