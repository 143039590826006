/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { ChangeDetectionStrategy, Component, Directive, Input, ViewEncapsulation } from '@angular/core';

// noinspection TsLint
@Component({
  selector: 'fury-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  host: { 'class': 'fury-card' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuryCard {
}

// noinspection TsLint
@Component({
  selector: 'fury-card-header',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'class': 'fury-card-header' },
  template: `
    <div class="fury-card-header-heading-group">
      <ng-content select="fury-card-header-heading"></ng-content>
      <ng-content select="fury-card-header-subheading"></ng-content>
    </div>
    <ng-content></ng-content>
    <ng-content select="fury-card-header-actions"></ng-content>
  `
})
export class FuryCardHeader {
}

// noinspection TsLint
@Component({
  selector: 'fury-card-content',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { 'class': 'fury-card-content' },
  template: `
    <ng-content></ng-content>`
})
export class FuryCardContent {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-header-heading',
  host: { 'class': 'fury-card-header-heading' }
})
export class FuryCardHeaderTitle {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-header-subheading',
  host: { 'class': 'fury-card-header-subheading' }
})
export class FuryCardHeaderSubTitle {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-header-actions',
  host: { 'class': 'fury-card-header-actions' }
})
export class FuryCardHeaderActions {
}

// noinspection TsLint
@Directive({
  selector: 'fury-card-actions',
  host: {
    'class': 'fury-card-actions',
    '[class.fury-card-actions-align-end]': 'align === "end"',
  }
})
export class FuryCardActions {
  /** Position of the actions inside the card. */
  @Input() align: 'start' | 'end' = 'start';
}
