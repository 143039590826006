<!-- 
 /*******************************************************************************
 * FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
 *  __________________
 *
 *  2017 - 2018 FORMIDIUM Pvt. Ltd
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of FORMIDIUM Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to FORMIDIUM Pvt. Ltd
 * and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret and/or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
 * by visitng www.formidium.com.
 ******************************************************************************/ -->
<mat-form-field class="example-full-width workflow-input-align input-width-cell-date" appearance="outline">
  <mat-label appRemoveAriaOwns>{{ "bank_posting_date" | translate}} </mat-label>
  <input matInput [(ngModel)]="selectedDate" [matDatepicker]="startDatePicker1" [disabled]="isReadonly" (dateChange)="onDateChanged($event)">
  <mat-datepicker-toggle matSuffix [for]="startDatePicker1"></mat-datepicker-toggle>
  <mat-datepicker touchUi #startDatePicker1></mat-datepicker>
</mat-form-field>