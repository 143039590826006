/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';

@Component({
  selector: 'fury-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  private params: any;
  executionId: any;
  moduleName: any;
  executionStatus:any;
  constructor() { }

  ngOnInit(): void {
  }
  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.executionId = this.params.data.executionId;
    this.executionStatus = this.params.data.executionStatus
    this.moduleName = apiConstants[this.params.api.componentName]['moduleName'];
  }
  batchDetail(event) {
    
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.batchDetail instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.batchDetail(params);
    }
  }

}
