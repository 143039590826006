import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';

@Injectable()
export class NodeService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };
  constructor(private httpClient: HttpClient, private http: HttpClient, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler)
  }
  
  getFilesystem(strDate,endDate,srchType,executionLocked) {
    let urlStr = "alteryxExecuteWorkflowV2/getAllRecords"+"?startDate="+ strDate+'&'+'sortLevel='+srchType+
    "&endDate="+ endDate+"&isExecutionLocked="+ executionLocked;
    return this.http.get<any>(urlStr)
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }

  getFilesystem1() {
    return this.httpClient.get<any>('assets/headers/accounting/testfile.JSON')
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }

  getLazyFilesystem() {
    return this.httpClient.get<any>('assets/filesystem-lazy.json')
      .toPromise()
      .then(res => <TreeNode[]>res.data);
  }

  runCompilance(data): Observable<any> {
    return this.http.post<any>('alteryxExecuteWorkflowV2/consolidatedWorkflowExecution', data);
  }
  treeDataExport(data): Observable<any> {
    let header = this.httpOptions;
    header['responseType'] = 'arraybuffer';
    return this.http.post<any>('alteryxExecuteWorkflowV2/downloadWorkflowRecords', data, header);
    this.httpOptionsReset(true);
  }
  httpOptionsReset(flg){
    if(flg){
      if(this.httpOptions){
        delete this.httpOptions['responseType']
      }
    }   
  }
}