/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ControlTypeBase } from './control-type-base';


@Injectable({providedIn: 'root'})
export class ControlTypeService {
  allInputControl = [];
  constructor() { }
  toFormGroup(data: ControlTypeBase<string>[]) {
    const group: any = {};
    data.forEach(dataItem => {
      if(dataItem.required){
         if(dataItem.type =="email"){
          group[dataItem.id] = dataItem.disabled ? new FormControl({value: dataItem.value, disabled: true}, [Validators.required, Validators.email])
          : new FormControl(dataItem.value, [Validators.required, Validators.email]); 
         }else {
          group[dataItem.id] = dataItem.disabled ? new FormControl({value: dataItem.value, disabled: true}, Validators.required)
          : new FormControl(dataItem.value, Validators.required ); 
         }
         
      }else {
        if(dataItem.type =="email"){
          group[dataItem.id] = dataItem.disabled ? new FormControl({value: dataItem.value, disabled: true},Validators.email)
          : new FormControl(dataItem.value, Validators.email);
         }else {
          group[dataItem.id] = dataItem.disabled ? new FormControl({value: dataItem.value, disabled: true})
          : new FormControl(dataItem.value);
         }
        
      }
      // group[dataItem.id] = dataItem.required ? new FormControl(dataItem.value, Validators.required)
      //   : new FormControl(dataItem.value);
      this.allInputControl.push(dataItem);
    });
    return new FormGroup(group);
  }
}
