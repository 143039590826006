<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->

<input *ngIf='checkboxSelection1' type="checkbox" [(ngModel)]="brokerCheck" (change)="onCheckChange($event,'brokerCheck',brokerCheck)">
<input *ngIf='checkboxSelection2' type="checkbox" [(ngModel)]="previousCheck" (change)="onCheckChange($event,'previousCheck',previousCheck)">
<div>
    <input  *ngIf='checkboxAllbrokercheck'
    type="checkbox" [(ngModel)]="brokerCheck" (change)="onInputChanged($event,'brokerCheck',brokerCheck)" />
</div>
  <div>
    <input  *ngIf='checkboxAllpreviouscheck'
      style="margin: 4px 0 4px 0;"
      type="checkbox" [(ngModel)]="previousCheck" (change)="onInputChanged($event,'previousCheck',previousCheck)"/>
  </div>
<div class="market-gridview">
<input *ngIf='Inputboxfundname' [(ngModel)]="rowData.fundName" type="text"  pInputText class="p-inputtext-sm" placeholder="Fund Name">  
<input *ngIf='Inputboxmanualprice' [(ngModel)]="rowData.manualPrice" type="text" (blur)="onCheckChange($event,'Manual',rowData.manualPrice)" pInputText class="p-inputtext-sm" placeholder="Manual Value">
</div>  