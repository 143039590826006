
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { duplicateConfigComponent } from 'src/app/views/finance-statement/fs-config/fs-duplicate-config/fs-duplicate-config.component';
import { apiConstants } from 'src/app/constants/apiConstants';

@Component({
  selector: 'fury-duplicate-button',
  templateUrl: './duplicate-button.component.html',
  styleUrls: ['./duplicate-button.component.scss']
})
export class DuplicateButton {
  
  duplicateConfigComponent
  public params: any;
  public display: boolean = false
  currentUser: any;
  isdViewBtnCheck: boolean;
  isdEnableViewBtnCheck: boolean;
  public moduleName: string;
  public fundList = []
  public basicDetailData: any = []

  constructor( private dialog: MatDialog) { }
  public status: any;

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.moduleName = apiConstants[this.params.api.componentName][this.params.api.subComponent] ? apiConstants[this.params.api.componentName][this.params.api.subComponent]['moduleName'] : (apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName)

  }
  

  OpenduplicatePopup() {    
    const dialogRef = this.dialog.open(duplicateConfigComponent, {
      panelClass: 'no-padding',
      data: this.params.data
    });
    dialogRef.afterClosed().subscribe((confirmed: any) => {
      if (confirmed && this.params.refershGrid instanceof Function) {
        const params = {
          event: event
        }
        this.params.refershGrid();

      }
    });
  }

  

 

}
