<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ -->
<app-grid style="height:calc(85vh - 5em - 23px)" [componentName]="componentName" [heading]="'users'"
                [isNewBtn]="true" [isUploadBtn]="false" [isExportBtn]="true" [isDeleteBtn]="false"                                                          [gridOptions]="gridOptions"
                [enableRangeSelection]="enableRangeSelection" [enableCharts]="enableCharts" 
                [groupSelectsChildren]="groupSelectsChildren" [suppressRowClickSelection]="suppressRowClickSelection"
                [animateRows]="animateRows" [rowSelection]="rowSelection" toolPanelSuppressGroups 
                [isSmartTable]="true" [columnDataforSmartTable]="columns" suppressRowClickSelection (allUsersRowEventCompliance)="allUsersRowEventCompliance($event)">
</app-grid>



<p-dialog [modal]="true" header="{{'confirmation'|translate}}" class="Dialog_width_Small confirm_undo" [(visible)]="display"  [baseZIndex]="10000">

    <div class="modal-body">
      <p>{{'allusers_deactive'|translate}}</p>
    </div>
  
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="{{'yes'|translate}}" class="p-button-text" (click)=" confirmationAllUsersStatus('inactive')">
      </p-button>
      <p-button icon="pi pi-times" (click)="display=false" label="{{'no'|translate}}"></p-button>
  
    </ng-template>
  
  </p-dialog>

  <p-dialog [modal]="true" header="{{'confirmation'|translate}}" class="Dialog_width_Small confirm_undo" [(visible)]="displayStatus"  [baseZIndex]="10000">

  <div class="modal-body">
    <p>{{'allusers_active'|translate}}</p>
  </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="{{'yes'|translate}}" class="p-button-text"
      (click)="confirmationAllUsersStatus('active')"></p-button>
    <p-button icon="pi pi-times" (click)="displayStatus=false" label="{{'no'|translate}}"></p-button>

  </ng-template>
</p-dialog>