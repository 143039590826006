<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/  -->
<router-outlet></router-outlet>
<nav class="workflowtree_design" mat-tab-nav-bar style="height: 64px; margin-bottom: 0px">
  <div class="fury-list-table">
    <div class="fury-list-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
      fxLayoutGap.gt-xs="24px">
      <div class="fury-list-name input-button-align"
        style="margin-right: 24px; border-right: 1px solid rgba(0, 0, 0, 0.12)">
        {{ "group_import" | translate }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <mat-icon class="fury-filter-input-icon">search</mat-icon>
        <input fxFlex class="fury-filter-input" type="text" pInputText placeholder="Search&hellip;"
          (input)="tt.filterGlobal($event.target.value, 'contains')" style="width: 6em" />
        <mat-form-field class="example-full-width workflow-input-align" appearance="outline">
          <mat-label>{{ "start_date" | translate
            }}<span style="color: red">*</span></mat-label>
          <input matInput (dateChange)="dateChangeEvt($event,'str');" [(ngModel)]="startDate" [value]="startDate"
            [matDatepicker]="startDatePicker" (click)="startDatePicker.open()" />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker">
          </mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <!-- <mat-datepicker touchUi #startDatePicker></mat-datepicker> -->
        </mat-form-field>

        <mat-form-field class="example-full-width workflow-input-align" appearance="outline"
          style="margin-left: 0.5rem;">
          <mat-label>{{ "end_date" | translate
            }}<span style="color: red">*</span></mat-label>
          <input matInput (dateChange)="dateChangeEvt($event,'end');" [(ngModel)]="endDate" [value]="endDate"
            [matDatepicker]="endDatePicker"  [min]="minDate" [max]="maxDate" (click)="endDatePicker.open()" />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker">
          </mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <!-- <mat-datepicker touchUi #endDatePicker></mat-datepicker> -->
        </mat-form-field>

        <div class="example-button-row select_executebtn">
          <div class="SelectClass">
            <span *ngIf="selectedNodes && selectedNodes.length">
              Selected: {{ selectedNodeCount }}
            </span>
          </div>
          <button *ngIf="selectedNodes && selectedNodes.length && excBtnFlg"
            class="btn-logout mat-button workflow_button" type="button" 
            style="margin-left: 8px; background: #495057; cursor: not-allowed;"  matTooltip="{{ !isLoading ? ('workflow_disabled' | translate) : null }}" 
            matTooltipPosition="above" [disabled]="!selectedNodes ||!selectedNodes.length ||!startDate">{{ "execute" | translate }}
            <mat-icon style="display: block; margin-right: 8px">play_circle_filled
            </mat-icon>
          </button>
          <button *ngIf="selectedNodes && selectedNodes.length && !excBtnFlg" 
            class="btn-logout mat-button workflow_button" type="button" style="margin-left: 8px"
             matTooltip="{{
              'run_selcted_workflow' | translate }}" matTooltipPosition="above"
            [disabled]="!selectedNodes ||!selectedNodes.length ||!startDate" (click)="setWorkflowData()">{{ "execute" |
            translate }}
            <mat-icon style="display: block; margin-right: 8px">play_circle_filled
            </mat-icon>
          </button>
        </div>
        <div style="margin-left: 10px;margin-right: 5px;">
        <mat-slide-toggle color="accent" (change)="sequenceExecutionVisible()" [checked]="executionLocked" [(ngModel)]="executionLocked">Show Sequenced Workflows
        </mat-slide-toggle>
        </div>
        <div class="workflow-option-btn">
          <button  class="export" type="button"
            mat-mini-fab color="primary" matTooltip="{{'export' | translate}}" matTooltipPosition="above"
            style="margin-right: 6px;" (click)="exportFile($event,tt)">
            <mat-icon>file_download</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<p-treeTable class="treetable_text" #tt [value]="files" [columns]="cols" selectionMode="checkbox"
  [(selection)]="selectedNodes" (onNodeSelect)="nodeSelect($event)" (onNodeExpand)="nodeExp($event)"
  (onNodeCollapse)="nodeColp($event)" (onFilter)="recordFound($event)" (onNodeUnselect)="nodeUnselect($event)">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>
        <!-- <div>
          <mat-radio-group [(ngModel)]="sortLevel" class="mt-2">
            <mat-radio-button *ngFor="let el of sortLevelList" [value]="el" (change)="onRadioChange($event)">
              {{ el + "_lvl" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div> -->
      </th>
      <th *ngFor="let col of columns" [ngStyle]="{ display: col.field == 'title' ? 'none' : '' }">
        {{ col.header | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" class="workflowtree" let-rowNode let-rowData="rowData" let-columns="columns">
    <tr class="cellwidth" [ttRow]="rowNode" [ttSelectableRow]="rowNode" (click)="onTreeRowClick($event)">
      <td *ngFor="let col of columns; let i = index">
        <p-treeTableToggler [rowNode]="rowNode" *ngIf="rowData && i == 0">
        </p-treeTableToggler>
        <div *ngIf="divShow">
          <button class="treebutton" *ngIf="!isNodeExpend && files && rowData && i == 0" pButton type="button"
            (click)="treeExpandAll(files)" matTooltip="{{ 'expand_all' | translate }}">
            <i class="pi pi-plus"></i>
          </button>
          <button class="treebutton" *ngIf="isNodeExpend && files && rowData && i == 0" pButton type="button"
            (click)="treeCollapseAll(files)" matTooltip="{{ 'collapse_all' | translate }}">
            <i class="pi pi-minus"></i>
          </button>
        </div>
        <div *ngIf="!divShow">
          <div *ngIf="isNodeExpendtog">
            <button class="treebutton" *ngIf="files && rowData && i == 0" pButton type="button" (click)="
                treeCollapseAll(files); isNodeExpendtog = !isNodeExpendtog
              " matTooltip="{{ 'collapse_all' | translate }}">
              <i class="pi pi-minus"></i>
            </button>
          </div>
          <div *ngIf="!isNodeExpendtog">
            <button class="treebutton" *ngIf="files && rowData && i == 0" pButton type="button"
              (click)="treeExpandAll(files); isNodeExpendtog = !isNodeExpendtog"
              matTooltip="{{ 'expand_all' | translate }}">
              <i class="pi pi-plus"></i>
            </button>
          </div>
        </div>

        <p-treeTableCheckbox [value]="rowNode" *ngIf="rowData && i == 0">  <!-- [ngClass]="rowData['selectable'] ? '' : '' " -->
        </p-treeTableCheckbox>
        <span *ngIf="rowData" style="display: flex" [ngStyle]="{
          cursor : rowData['type'] && rowData['type'] == 'accountNumber' ? 'default' : 'pointer'
          }" [ngClass]="{
            'successDisposition': col.field == 'workflowSuccessCount',
            'errorDisposition': col.field == 'workflowErrorCount' ,
            'uploadDisposition': col.field == 'workflowReferDownloadFile',
            'downDisposition': col.field == 'workflowUploadManually' 
          }">
          <span *ngIf="col.field" (click)="redirectTo($event, rowData , rowNode , col.field)">{{ rowData[col.field] }}</span>
        </span>
      </td>
    </tr>
  </ng-template>
</p-treeTable>

<p-dialog class="delModal Dialog_width_Medium confirm_undo workflow_run-c-files" [modal]="true"
  header="{{ 'run_compliance_files' | translate }}" [(visible)]="display" [baseZIndex]="10000"
  (onHide)="checkDoneStatus()">
  <p-table [value]="dataSource2" [columns]="cols2" styleClass="p-datatable-gridlines">
    <ng-template pTemplate="header" let-cols2>
      <tr>
        <th *ngFor="let col of cols2">
          {{ col.header | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
      <tr>
        <td style="text-align: justify">
          {{ item.alteryxWorkflowMngt.sourceName }}
        </td>
        <td style="text-align: justify; word-break: break-all">
          {{
          item.failedReason != null
          ? item.failedReason
          : item.connectionStatus
          }}
        </td>
        <td style="text-align: justify">
          {{
          item.failedReason != null || item.connectionStatus != null
          ? "Failed"
          : "Connected"
          }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button mat-raised-button mat-button-base mat-primary color="primary" type="button" style="margin: 0px 2px 0px 0px"
      (click)="checkDoneStatus()">
      {{ "ok" | translate }}
    </button>
  </p-footer>
</p-dialog>

<fury-execute-work-flow-run *ngIf="nodeData && dailogFlag" [componentName]="componentName" [isDialog]="dailogFlag"
  [nodeData]="nodeData" (treeEmmiter)="treeFeedback($event)"></fury-execute-work-flow-run>
