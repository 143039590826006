
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiConstants } from 'src/app/constants/apiConstants';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GlobalMessageService } from '../../services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { GridService } from '../../services/gridServices/grid.service';

@Component({
  selector: 'fury-re-client-upload',
  templateUrl: './re-client-upload.component.html',
  styleUrls: ['./re-client-upload.component.scss']
})
export class ReClientUploadComponent {

  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  moduleName: any;
  pdfMsg: any;
  data: any;
  status: any;
  constructor(private http: HttpClient,
    public translate: TranslateService,
    private messageService: GlobalMessageService,
    private gridService: GridService
  ) { }
  public emailTemplateView: any;

  agInit(params: ICellRendererAngularComp): void {

    this.params = params;
    this.data = this.params.data
    this.status = params['data'].status;


    this.moduleName = apiConstants[this.params.api.componentName]['moduleName'];
  }


  viewClientUploadRowData(evt)
  {

    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.viewClientUploadRowData instanceof Function) {
      console.log("viewClientUploadRowData btn 1");

      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.viewClientUploadRowData(params);
    }
  }

}

