/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { TranslateService } from '@ngx-translate/core';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;

@Component({
  selector: 'fury-download-temp-button',
  templateUrl: './download-temp-button.component.html',
  styleUrls: ['./download-temp-button.component.scss']
})
export class DownloadTempButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  alteryxWorkflowStatus: any;
  public omsCancel: boolean;
  public omsApproveList: boolean;
  public omsEditList: boolean;
  moduleName: any;
  executionId: any;
  constructor(private http: HttpClient,
    public translate: TranslateService,
    private messageService: GlobalMessageService) { }
  public overAllStatus: any;

  agInit(params: ICellRendererAngularComp): void {
    
    this.params = params;
    this.alteryxWorkflowStatus = this.params.data.alteryxWorkflowStatus;
    this.overAllStatus = this.params.value;
    this.executionId = this.params.data.executionId;
    this.moduleName = apiConstants[this.params.api.componentName]['moduleName'];
  }

  getPreviewData() {
    var uniqueId;
    if (this.params.data && this.alteryxWorkflowStatus && this.alteryxWorkflowStatus.uniqueNumber) {
       uniqueId = this.alteryxWorkflowStatus.uniqueNumber;
    }
    else{
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('invalid_data'), detail: this.translate.instant('error.') });
      return; 
    } 

    this.http.get<any>('alteryxExecuteWorkflowV2/getTempData/' + uniqueId).subscribe(response => {
      if (response) {
        this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('fail_status_file'), detail: this.translate.instant('download_request_taken_soon') });
        alasql('SELECT * INTO XLSX("Upload failed Status.xlsx",{headers:true}) FROM ?', [response.data]);
      }
    })
  }

  uploadErrorStatus(event, view) {
    this.http.get<any>('configurationsV2/getDataImportStatusFailedRecord/' + this.params.data.executionId).subscribe(response => {
      if (response) {
        this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('fail_status_file'), detail: this.translate.instant('download_request_taken_soon') });
        alasql('SELECT * INTO XLSX("Upload failed Status.xlsx",{headers:true}) FROM ?', [response.data]);

      }
    })

  }
}
