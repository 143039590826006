/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import alasql from '../../../../../node_modules/alasql';
import * as XLSX from '../../../../../node_modules/xlsx';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { environment } from '../../../../../src/environments/environment';

import { Observable } from 'rxjs';
alasql['utils'].isBrowserify = false;
alasql['utils'].global.XLSX = XLSX;
@Component({
  selector: 'fury-ca-download-file-button',
  templateUrl: './Capital-Activity-download-button.component.html',
  styleUrls: ['./Capital-Activity-download-button.component.scss']
})
export class CaDownloadFileButton  {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  private params: any;
  private rowData: any;
  attachment1: any;
  alteryxWorkflowStatus: any;
  attachment: any;

  constructor(
    private processScreenService: ProcessScreenService,
    private messageService: GlobalMessageService, private http: HttpClient
  ) { }

  agInit(params: ICellRendererAngularComp): void {
    this.params = params;
    this.rowData = this.params.data;
    if (this.params.column?.colId == "attachment1") {
      this.attachment = this.params.data.attachment1
    } else if (this.params.column?.colId == "attachment2") {
      this.attachment = this.params.data.attachment2

    } else if (this.params.column?.colId == "attachment3") {
      this.attachment = this.params.data.attachment3

    }
    
   
  }

  
}
