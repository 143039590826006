<router-outlet></router-outlet>
<ng-http-loader [entryComponent]="awesomeComponent" [backdrop]="false" [backgroundColor]="'#1976d2'"
    [debounceDelay]="100" [extraDuration]="300" [minDuration]="300" [opacity]="1" [spinner]="spinnerStyle">
</ng-http-loader>
<ng-container *ngIf="setCookies">
    <iframe *ngFor="let item of sessionList" class="silent_signin" name="silent_signin" [src]="item.urlSafe"
        style="display: none;" (load)="iframeLoaded()" (error)="iframeLoadedError()"></iframe>
</ng-container>
<p-dialog class="delModal sessionmodal Dialog_width_Small" [modal]="true" header="You've Timed Out!"
    [(visible)]="displayBasic" [baseZIndex]="10000">
    <div class="row delCriteria">
        <span class="whitetextcolor" style="text-align: center !important;
     width: 100%;
     display: block;
     padding: 10% 0;
     font-size: 16px;
     font-weight: 600;">You were idle too long. Refresh the page & login again.</span>

    </div>
    <p-footer>
        <button class="btn-logout" (click)="logout()">
            Logout
        </button>
        <button class="btn-stay" (click)="stay()">
            Stay
        </button>
    </p-footer>
</p-dialog>
<p-toast position="bottom-right" (onClose)="onclosemessage($event)"></p-toast>