import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("CompanyName=Sudrania Fund Services,LicensedApplication=seamless,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-008773,ExpiryDate=2_July_2021_[v2]_MTYyNTE4MDQwMDAwMA==e3efb432227b0964dccfa4a941bbeacb");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log());
