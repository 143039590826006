/*******************************************************************************
* FORMIDIUM Pvt. Ltd COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Pvt. Ltd
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Pvt. Ltd.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Pvt. Ltd
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Pvt. Ltd. Contact information for FORMIDIUM Pvt. Ltd may be obtained
* by visitng www.formidium.com.
******************************************************************************/
export const environment = {
  production: true,
  googleMapsApiKey: '',
  froalaKey: 'BWC6D-16D3C3E1G2C1A5A4wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B4C6E2D1F4G1G1==',
  api_url: '/formidium/', // Put your backend here
};
