/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from "jwt-decode";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocalCookieService {

  constructor(private cookieService: CookieService, private http: HttpClient) { }


  setCookies(data) {
    var token = jwt_decode(data.access_token);
    let expiryTime = new Date(token['exp'] * 1000);
    let expireDate = new Date().getTime() + (1000 * data.expires_in);
    const [subdomain, domain] = this.getDomainAndSubdomain();
    let url = domain || '';
    this.cookieService.set('access_token', data["access_token"], expiryTime, "/", url, true);
    this.cookieService.set('refresh_token', data["refresh_token"], expiryTime, "/", url, true);
    localStorage.setItem("access_token_expireDate", expireDate.toString());
  }

  clearAllCookies() {
    const [subdomain, domain] = this.getDomainAndSubdomain();
    let url = domain || '';
    this.cookieService.deleteAll("/", url);
  }

  clearSSOCookies() {
    const [subdomain, domain] = this.getDomainAndSubdomain();
    let url = domain || '';
    this.cookieService.delete("access_token", "/", url, true, "None");
    this.cookieService.delete("refresh_token", "/", url, true, "None");
  }

  getDomainAndSubdomain() {
    let domain = window.location.hostname;
    let subdomain = null;
    // Split the hostname by dots
    const parts = domain.split('.');
    // If the URL is IP address-based or localhost, return null for both domain and subdomain
    if (parts.length === 1 && window.location.hostname == 'localhost') {
      return [null, 'localhost'];
    }
    if (parts.length === 1 && window.location.hostname !== 'localhost') {
      return [null, null];
    }
    // If there's no subdomain, return null for subdomain and the first part as domain
    if (parts.length === 2) {
      domain = parts[0] + '.' + parts[1];
      return [null, domain];
    }
    // Otherwise, the subdomain is the first part, and the domain is the rest
    subdomain = parts[0];
    domain = parts.slice(1).join('.');

    return [subdomain, domain];
  }
}
