/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserSearchService } from '../../../../../src/app/shared/services/commonServices/searchbar.service';
import { SidenavItem } from '../../sidenav/sidenav-item/sidenav-item.interface';
import { SidenavService } from '../../sidenav/sidenav.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';

@Component({
  selector: 'fury-toolbar-search-bar',
  templateUrl: './toolbar-search-bar.component.html',
  styleUrls: ['./toolbar-search-bar.component.scss']
})
export class ToolbarSearchBarComponent implements OnInit {
  input: string;
  focused: boolean;
  userTabs = [];
  entTabs = [];
  systemAdminTabs = [];
  userTabsX = [];
  entTabsX = [];
  systemAdminTabsX = [];
  misTabs = [];
  misTabsX = [];
  recentlyVisited: SidenavItem[] = [];
  roleAs: string;
  @ViewChild('isFocused') isFocused: ElementRef;
  currentUser: any;
  sidenavVisible$ = this.sidenavService.visible$;
  public fss: any = {};

  constructor(
    private localStoreService: LocalStoreService, private router: Router,
    private sidenavService: SidenavService, private translateService: TranslateService,
    public userSearchService: UserSearchService, private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.isFocused && (e.target !== this.isFocused.nativeElement)) {
        this.userTabsX = [];
        this.input = '';
        this.focused = false;
      } else if (!this.isFocused) {
        this.userTabsX = [];
        this.input = '';
        this.focused = false;
      }
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(this.localStoreService.getItem('fss'));
    this.setupDemoData();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const item = this.sidenavService.getItemByRoute(event.urlAfterRedirects);
        if (item) {
          const index = this.recentlyVisited.indexOf(item);
          if (index > -1) {
            this.recentlyVisited.splice(index, 1);
          }
          this.recentlyVisited.unshift(item);
          if (this.recentlyVisited.length > 5) {
            this.recentlyVisited.pop();
          }
        }
      }
    });
    let sideLinks = [];
    let sideLinksUserEnt = [];
    this.userSearchService.userTabs.forEach(element => {
      if (this.currentUser && this.currentUser.authList) {
        if (this.currentUser && this.currentUser.authList.includes(element['authsibebar'])) {
          sideLinks = sideLinks.concat(element.Button);
          if (this.currentUser.authList.includes('SEAMLESS_SIDEMENU_SETUP') && element['authsibebar'] != 'SIDEMENU_SETTINGS') {
            sideLinksUserEnt = sideLinksUserEnt.concat(element.Button)
          }
        }
      }
    });
    this.userTabs = sideLinks;
    this.entTabs = this.currentUser.authList.includes('SEAMLESS_SIDEMENU_SETUP') ? [...this.userSearchService.entTabsRapid, ...sideLinksUserEnt] : this.userSearchService.entTabs;
    this.systemAdminTabs = this.userSearchService.systemAdminTabs;
    this.misTabs = this.userSearchService.misTabs;
    this.roleAs = JSON.parse(this.localStoreService.getItem('fss'))['ROLE']
  }

  setupDemoData() {
    const formWizard = this.sidenavService.getItemByRoute('/forms/form-wizard');
    if (formWizard) this.recentlyVisited.push(formWizard);

    const inbox = this.sidenavService.getItemByRoute('/apps/inbox');
    if (inbox) this.recentlyVisited.push(inbox);

    const allInOneTable = this.sidenavService.getItemByRoute('/tables/all-in-one-table');
    if (allInOneTable) this.recentlyVisited.push(allInOneTable);

    const editor = this.sidenavService.getItemByRoute('/editor');
    if (editor) this.recentlyVisited.push(editor);

    const googleMaps = this.sidenavService.getItemByRoute('/maps/google-maps');
    if (googleMaps) this.recentlyVisited.push(googleMaps);
  }

  openDropdown() {
    this.focused = true;
  }

  closeDropdown() {
    this.focused = false;
  }

  Search() {
    if (this.input != "") {
      let temp;
      if (this.roleAs == "User") {
        this.focused = true;
        let demo = JSON.parse(JSON.stringify(this.userTabs))
        this.userTabsX = demo.filter(res1 => {
          this.translateService.get(res1.name).subscribe((res) => {
            temp = res;
          });
          if (res1.catCreatAuth || res1.catViewAuth) {
            return (temp.toLowerCase().includes(this.input.toLowerCase()) && (this.currentUser.authList.includes(res1.catCreatAuth) || this.currentUser.authList.includes(res1.catViewAuth)));
          }
          return temp.toLowerCase().includes(this.input.toLowerCase());
        })
      } else if (this.roleAs == "Manager") {
        this.focused = true;
        let demo = JSON.parse(JSON.stringify(this.entTabs))
        this.entTabsX = demo.filter(res1 => {
          this.translateService.get(res1.name).subscribe((res) => {
            temp = res;
          });
          if (res1.catCreatAuth || res1.catViewAuth) {
            return (temp.toLowerCase().includes(this.input.toLowerCase()) && (this.currentUser.authList.includes(res1.catCreatAuth) || this.currentUser.authList.includes(res1.catViewAuth)));
          }
          return (temp.toLowerCase().includes(this.input.toLowerCase()));
        })
      } else {
        if (this.currentUser.authList.includes('SEAMLESS_SIDEMENU_SYSADMIN')) {
          this.focused = true;
          let demo = this.currentUser.authList.includes('SEAMLESS_SIDEMENU_MIS') ? [...JSON.parse(JSON.stringify(this.systemAdminTabs)), ...JSON.parse(JSON.stringify(this.misTabs))] : JSON.parse(JSON.stringify(this.systemAdminTabs));
          this.systemAdminTabsX = demo.filter(res => {
            this.translateService.get(res.name).subscribe((res) => {
              temp = res;
            });
            return temp.toLowerCase().includes(this.input.toLowerCase());
          })
        } else if (this.currentUser.authList.includes('SEAMLESS_SIDEMENU_MIS')) {
          this.focused = true;
          let test = JSON.parse(JSON.stringify(this.misTabs))
          this.misTabsX = test.filter(res1 => {
            this.translateService.get(res1.name).subscribe((res) => {
              temp = res;
            });
            if ((res1.catCreatAuth || res1.catViewAuth) && res1.cardAuthName) {
              return (temp.toLowerCase().includes(this.input.toLowerCase()) && (this.currentUser.authList.includes(res1.catCreatAuth) || this.currentUser.authList.includes(res1.catViewAuth)) && (this.currentUser.authList.includes(res1.cardAuthName)));
            }
            return (temp.toLowerCase().includes(this.input.toLowerCase())) && this.currentUser.authList.includes(res1.cardAuthName);
          })
        }
      }
    } else {
      this.focused = false;
      this.userTabs = [];
      this.systemAdminTabs = [];
      this.misTabs = [];
      this.entTabs = [];
      this.ngOnInit();
    }
  }
}
