
<p-dialog [modal]="true" class="executework-flowrun fulwithmodal modalbackdrop-view" header="{{'run_compliance_files' | translate}}"
[(visible)]="display" [baseZIndex]="10000" (onHide)="dialogClose(false)">
<div class="modal-left-part " fxFlex="noshrink" fxLayout="row" fxLayoutGap="15" fxLayoutAlign.gt-sm="space-between flex-end">
  <div>
    <mat-label>{{'start_date' | translate}}</mat-label>
    <p-calendar appendTo="body" #myCalender2 [showOnFocus]="calFocus" yearRange="2008:2040" [showIcon]="true"
      [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true" [readonlyInput]="false"
      (onSelect)="getFileData(false, showDateRangeField, executeDateMulti, executeStartDateMulti, executeEndDateMulti,'ST')"
      [(ngModel)]="executeStartDateMulti"></p-calendar>
  </div>
  <!-- this is use for sunday or sat date disabled => [disabledDays]="[0,6]" -->
  <div>
    <mat-label>{{'end_date' | translate}}</mat-label>
    <p-calendar appendTo="body" #myCalender3 [showOnFocus]="calFocus" yearRange="2008:2040" [showIcon]="true"
      [monthNavigator]="true" [yearNavigator]="true" [showButtonBar]="true" [minDate]="minDate" [maxDate]="maxDate"
      [readonlyInput]="false"
      (onSelect)="getFileData(false, showDateRangeField, executeDateMulti, executeStartDateMulti, executeEndDateMulti,'ED')"
      [(ngModel)]="executeEndDateMulti"></p-calendar>
  </div>
  <!-- <div style="padding: 10px 0px;" *ngIf="!inputModeDB  && fileDetails.length">
    <input type="file" style="max-width: 100px;" name="filefield" multiple="multiple" (change)="onChange($event)">
  </div> -->
  <div style="padding: 6px 0px;">
    <mat-checkbox [(ngModel)]="isDataDumpInTemp" class="checkbox" style="width: 100%; display: flex;">
      {{'preview_data' | translate}}
    </mat-checkbox>
  </div>

  <div class="browse_section inputupload-brose-section file_multi-uploadbtn" *ngIf="!inputModeDB  && fileDetails.length && !inputModeAWS">
               
    <p-fileUpload class="customUpload" #fileUpload mode="advanced" [showCancelButton]="false"
      [showUploadButton]="false" chooseLabel="{{'browse_file'|translate}}" customUpload="true"
      dragDropSupport="true" multiple="multiple" (onSelect)="onChange($event)">
      <ng-template pTemplate="content">
        <!-- <input type="file" id="myFile" name="filename">
        <img src="assets/img/input-upload-icon.png"> -->
        
        <label for="myFile">
            <img src="assets/img/input-upload-icon.png">
          <h3  style="text-align: center;">
            {{'choose_file_drag_here'|translate}} <br>
            {{'or'|translate}}</h3> 
            <!-- <input type="file" name="filename" id="myFile myfileupload"  class="fileupload-ui"> -->
            <div class="file_name" >
              <span *ngFor="let fileName of fileNameDrag;let i = index" class="upload-filename" [ngStyle]="{'display': i<2 ? 'block' : 'none'}">
                <span>{{fileName}}</span> <br>
              </span>
              <a style="color:blue ;" *ngIf="fileNameDrag.length>2" class="navigation-item" (click)="fileNameOpen()">
                more...
              </a>
            </div>
          </label>                  
      </ng-template>
    </p-fileUpload>

  </div>


  <div class="info-msg1" [ngStyle]="{'margin-top': componentName == 'alteryx-tree' ? '5px !important' : '' }" *ngIf="!inputModeDB  && fileDetails.length && !inputModeAWS">
    <span class="material-icons-outlined">info</span>
    <span class="msg">
      Please upload your input files for the required workflows.
    </span>
  </div>
</div>
<div class="modal_right execute-modal-inputmode">
<div class="mat-elevation-z8" style="max-height: 271px; overflow-y: auto;" 
  *ngIf="!inputModeDB  && fileDetails.length && isApiCall">
  <table mat-table [dataSource]="fileDetails" style="margin-bottom: 20px;">
    <!-- Name Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox *ngIf="row.inputMode.includes('seamless import')" (click)="$event.stopPropagation();"
          (change)="$event ? selectionChange(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="Workflow">
      <th mat-header-cell *matHeaderCellDef> {{'workflow' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.alteryxWorkflowMngt.sourceName}} </td>
    </ng-container>
    <ng-container matColumnDef="InputMode">
      <th mat-header-cell *matHeaderCellDef> {{'input_mode' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.inputMode | uppercase}} </td>
    </ng-container>
    <ng-container matColumnDef="FileName">
      <th mat-header-cell *matHeaderCellDef> {{'file_name' | translate}} </th>
      <td mat-cell *matCellDef="let element"
        [ngClass]="element.inputMode.includes('seamless import') ? ((element.connectionStatus != 'File Uploaded Successfully') ?'c-red':'c-green') : ''">
        {{element.fileName}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="fileColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: fileColumns;"></tr>
  </table>
</div>
<div class="mat-elevation-z8" style="max-height: 250px; overflow-y: auto;" *ngIf="logStatus && isMultiple">
  <table mat-table [dataSource]="executedData" style="margin-bottom: 20px;">
    <!-- Name Column -->
    <ng-container matColumnDef="Workflow">
      <th mat-header-cell *matHeaderCellDef> {{'workflow' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.alteryxWorkflowMngt.sourceName}} </td>
    </ng-container>
    <ng-container matColumnDef="ErrorLog">
      <th mat-header-cell *matHeaderCellDef>{{'error_log' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{element.failedReason!=null ? element.failedReason : element.connectionStatus}}
      </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>{{'status' | translate}}</th>

      <td mat-cell *matCellDef="let element"
        [ngStyle]="{'color': element.failedReason!=null || element.connectionStatus!=null ? 'red' : '#1d2125' }">
        {{(element.failedReason!=null || element.connectionStatus!=null) ? 'Failed' : 'Connected' }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="detailColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: detailColumns;"></tr>
  </table>
</div>
<div class="mat-elevation-z8" style="max-height: 350px; overflow-y: auto;" *ngIf="checkConnection && connectionStatus">
  <table mat-table [dataSource]="connectionStatus" style="margin-bottom: 50px;">
    <!-- Name Column -->
    <ng-container matColumnDef="Workflow">
      <th mat-header-cell *matHeaderCellDef> {{'workflow' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.alteryxWorkflowMngt.sourceName}} </td>
    </ng-container>
    <ng-container matColumnDef="FileName">
      <th mat-header-cell *matHeaderCellDef> {{'file_name' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.alteryxWorkflowMngt.alteryxWorkflowMngtInfos[0].fileName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>{{'status' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{element.connectionStatus }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="connectionStatusColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: connectionStatusColumns;"></tr>
  </table>
</div>
<div class="mat-elevation-z8" style="max-height: 250px; overflow-y: auto;" *ngIf="logStatus && !isMultiple">
  <table mat-table [dataSource]="executedData" style="margin-bottom: 20px;">
    <!-- Name Column -->
    <ng-container matColumnDef="Workflow">
      <th mat-header-cell *matHeaderCellDef> {{'workflow' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.alteryxWorkflowMngt.sourceName}} </td>
    </ng-container>
    <ng-container matColumnDef="ErrorLog">
      <th mat-header-cell *matHeaderCellDef>{{'error_log' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{element.failedReason!=null ? element.failedReason : element.connectionStatus}}
      </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>{{'status' | translate}}</th>
      <td mat-cell *matCellDef="let element"
        [ngStyle]="{'color': element.failedReason!=null || element.connectionStatus!=null ? 'red' : '#1d2125' }">
        {{(element.failedReason!=null || element.connectionStatus!=null) ? 'Failed' : 'Connected' }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="detailColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: detailColumns;"></tr>
  </table>
</div>
<div class="mat-elevation-z8" *ngIf="isExecuted && !isMultiple" class="col-xs-12 col-sm-12 col-md-12 pl-0"
  style="color: red; font-size: 12px; margin-top: 10px; word-break: break-word;">
  <b>
    *Note: Please note selected workflows for this date has already been executed.
    Are you sure, you want to execute again?
  </b>
</div>
</div>
<ng-template pTemplate="footer">
  <div *ngIf="!logStatus && !inputModeDB && !showDateRangeField " id="divshow">
    <p-button icon="pi pi-check" label="{{'validate_connection' | translate}}"
      [ngClass]="{'disable':excutebuttonDisable}" (click)="testConnection();" class="p-button-text execute">
    </p-button>
  </div>
  <div *ngIf="!logStatus" id="divshow">
    <p-button icon="pi pi-play" label="{{'execute' | translate}}"
      [ngClass]="{'disable':excutebuttonDisable || isSubmitDisable || !isApiCall || isFileInvalid}" (click)="runCompilance($event);"
      class="p-button-text execute"></p-button>
  </div>
</ng-template>
</p-dialog>
<p-dialog [modal]="true" class="delModal Dialog_width_Small confirm_undo run_c_uploadpopup" header="Files" [(visible)]="fileDisplay"
    [baseZIndex]="10000">

    <table >
      <tr class="row delCriteria" *ngFor="let fileName of fileNameDrag">
        <td >
          <span>{{fileName}} </span>
          </td>
      </tr>
    </table>
    <!-- <p-footer>
        <button mat-mini-fab color="primary" type="button" style="margin: 0px 2px 0px 0px;" (click)="closeDataImport()">
            <mat-icon data-md-tooltip="OK">done</mat-icon>
        </button>
    </p-footer> -->

</p-dialog>



